<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :class="[`${variant}-variant`]"
    viewBox="0 0 24 24"
    style="enable-background:new 0 0 24 24;"
    xml:space="preserve">
    <path
      class="st0"
      d="M17.6,1.8H6.4L0.8,12l5.6,10.2h11.2L23.2,12L17.6,1.8z M11.3,11.5H6.3l2.4-4.5L11.3,11.5z M9.5,6.6h4.9L12,11.1
      L9.5,6.6z M11.3,12.5l-2.4,4.5l-2.4-4.5C6.4,12.5,11.3,12.5,11.3,12.5z M12,12.9l2.5,4.5H9.5L12,12.9z M12.7,12.5h4.9l-2.4,4.5
      L12.7,12.5z M12.7,11.5l2.4-4.5l2.4,4.5H12.7z M15,5.7H9l-1.6-3h9.2L15,5.7z M8.3,6.1l-2.9,5.4H2.1l4.6-8.4L8.3,6.1z M5.4,12.5
      l3,5.4l-1.6,3l-4.6-8.4H5.4z M9,18.3h5.9l1.6,3H7.4L9,18.3z M15.7,17.9l3-5.4h3.2l-4.6,8.4L15.7,17.9z M18.6,11.5l-2.9-5.4l1.6-3
      l4.6,8.4L18.6,11.5L18.6,11.5z" />
  </svg>
</template>

<script>

  export default {
    name: 'RadarIcon',
    props: {
      variant: { type: String, required: true, },
    },
  }

</script>

<style lang="scss" scoped>

.slate-variant {
  .st0 {
    fill: #494D51;
  }
}

.green-variant {
  .st0 {
    fill: #98CABF;
  }
}

</style>
