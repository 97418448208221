<template>
  <div>
    <template v-if="long">
      <div class="subtitle sentence-case centered">
        {{ dimension.name }}
      </div>
      <ul>
        <li
          v-for="value in filter.args.values"
          :key="value.key">
          {{ value.name }}
        </li>
      </ul>
    </template>
    <template
      v-else>
      <template
        v-if="filter.args.values.length == 0">
        {{ dimension.name }} <b>(None selected)</b>
      </template>
      <template v-else>
        {{ shortSummary }}
      </template>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'EntityFilterSummary',
    props: {
      filter: { type: Object, default: null, },
      long: { type: Boolean, default: false, },
      customDimension: { type: Object, default: null, },
    },
    computed: {
      dimension () {
        return this.customDimension || this.$store.getters['metadata/dimensions'][this.filter.dimension]
      },
      shortSummary () {
        let chars = 0
        let result = ''
        let isFirst = true
        let maxLength = 25
        for (let entity of this.filter.args.values) {
          chars += entity.name.length
          if (!isFirst) {
            result += ', '
          } else {
            isFirst = false
          }
          if (chars > maxLength) {
            result += `${entity.name.substring(0, chars - maxLength)}...`
            break
          } else {
            result += `${entity.name}`
          }
        }
        return result
      },
    },
  }
</script>

<style lang="scss" scoped>
.entity-filter-summary {
}
</style>
