<template>
  <div class="ingest-tooltip">
    <transition name="vertical-slide">
      <div
        class="wrapper flex-column"
        :style="{
          'position': 'fixed',
          'top': `${top}px`,
          'left': `${left}px`,
          'z-index': '10',
          'transform': 'translate(-50%, -100%)',
        }">
        <div class="content">
          <slot />
        </div>
        <svg
          class="triangle"
          viewBox="0 0 100 100"
          preserveAspectRatio="none">
          <polygon points="0,0 50,100 100,0" />
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>

  export default {
    name: 'IngestTooltip',
    props: {
      enabled: Boolean,
      top: { type: Number, default: 0, },
      left: { type: Number, default: 0, },
    },
  }

</script>

<style lang="scss" scoped>

.wrapper {
  pointer-events: none;
  align-items: center;
}

.vertical-slide-enter-active, .vertical-slide-leave-active {
  &.dropdown-body {
    transition: all .3s ease;
  }
}
.vertical-slide-enter, .vertical-slide-leave-to {
  &.dropdown-body {
    transform: scaleY(0);
  }
}

.content {
  background: $secondary-01;
  @include shadow;
  border-radius: 2 * $gridBase;
  z-index: 1;
}

.triangle {
  height: 3 * $gridBase;
  width: 4 * $gridBase;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, .1));
  fill: $secondary-01;
  z-index: 2;
  transform: translateY(-2px);
}

</style>

