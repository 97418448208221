<template>
  <div class="dimension-filter">
    <div class="field-container orange-background secondary-06-text">
      <b-button
        v-if="removable"
        class="left-button"
        variant="none"
        @click="$emit('delete')">
        <v-icon name="x-circle" />
      </b-button>
      <ingest-dropdown
        v-if="readOnly && !!dimension && !!filter && dimension.type == 'entity' && !!filter.args"
        class="left-button"
        align="left">
        <v-icon
          slot="button-content"
          name="minus-circle" />
        <div
          slot="body-content"
          class="long-summary secondary-01-background">
          <ul
            v-if="readOnly && !!dimension && !!filter && dimension.type == 'entity'">
            <li
              v-for="value in filter.args.values"
              :key="value.key">
              {{ value.name }}
            </li>
          </ul>
        </div>
      </ingest-dropdown>
      <div class="field-label flex-row">
        <dimension-filter-summary
          v-if="!!filter && !!dimension && !!filter.args"
          :filter="filter"
          :dimension="dimension" />
      </div>
      <ingest-dropdown
        v-if="!readOnly"
        :force-close="!isEdit"
        :start-open="isNew"
        class="edit-button"
        align="right"
        @open="onOpenChange">
        <v-icon
          slot="button-content"
          name="edit" />
        <b-card
          slot="body-content"
          class="editor secondary-01-background">
          <dimension-filter-select
            class="select"
            :filter.sync="editedFilter"
            :dimension="dimension"
            :other-filters="otherFilters"
            :opens="opens" />
          <div class="finish-buttons">
            <b-button
              variant="none"
              class="ingest-dark-button cancel-button"
              @click="resetEditedFilter()">
              cancel
            </b-button>
            <b-button
              variant="none"
              class="ingest-light-button"
              @click="saveEditedFilter()">
              done
            </b-button>
          </div>
        </b-card>
      </ingest-dropdown>
    </div>
  </div>
</template>

<script>

  import DimensionFilterSelect from '@/components/controls/filter/DimensionFilterSelect'
  import DimensionFilterSummary from '@/components/controls/filter/DimensionFilterSummary'
  import IngestDropdown from '@/components/common/IngestDropdown'
  import Vue from 'vue'

  export default {
    name: 'DimensionFilter',
    components: {
      DimensionFilterSelect,
      DimensionFilterSummary,
      IngestDropdown,
    },
    props: {
      readOnly: { type: Boolean, default: false, },
      filter: { type: Object, default: null, },
      startEdit: Boolean,
      removable: { type: Boolean, default: true, },
      otherFilters: { type: Array, default: Array, },
      opens: { type: String, default: 'center', },
    },
    data () {
      return {
        isEdit: false,
        editedFilter: null,
      }
    },
    computed: {
      isNew () {
        return !this.filter.type
      },
      dimension () {
        return this.$store.getters['metadata/dimensions'][this.filter.dimension]
      },
    },
    watch: {
      filter () {
        this.resetEditedFilter()
      },
    },
    mounted () {
      this.isEdit = !!this.startEdit
      if (!this.startEdit) {
        this.resetEditedFilter()
      }
    },
    methods: {
      onOpenChange (open) {
        this.isEdit = open
        if (!open) {
          this.saveEditedFilter()
        }
      },
      resetEditedFilter () {
        this.editedFilter = this.filter.type ? { ...this.filter, } : null
        Vue.nextTick(() => {
          this.isEdit = false
        })
      },
      saveEditedFilter () {
        if (this.removable || this.editedFilter.type) {
          this.$emit('update:filter', this.editedFilter)
          this.isEdit = false
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

.dimension-filter {
  position: relative;
}

.field-label {
  padding: 2 * $remGrid;
  align-self: center;
  flex-grow: 1;
}

.left-button {
  align-self: center;
  border-right: solid 1px #4A4D50;
  padding: 2 * $remGrid;
}
.edit-button {
  align-self: center;
  border-left: solid 1px #4A4D50;
  padding: 2 * $remGrid;
}

.field-container {
  display: flex;
  box-shadow: 0px 3px 6px 0px rgba(249, 169, 107, .5);
  border-top-left-radius: 4 * $remGrid !important;
  border-bottom-left-radius: 4 * $remGrid !important;
  border-top-right-radius: 2 * $remGrid !important;
  border-bottom-right-radius: 2 * $remGrid !important;
  margin: 2 * $remGrid;
  height: 8 * $remGrid;
  border: none;
  overflow: visible;

  * {
    color: #4A4D50;
  }
}

.card-body {
  padding: 0 !important;
}

.editor {
  padding: 0;
  right: 0;
}

.ingest-dark-button.cancel-button {
  box-shadow: none;
  margin-right: 8px;
}
.icon {
  width: 4 * $remGrid;
  height: 4 * $remGrid;
}

.select {
  height: 300px;
  width: 212px;
}

.dimension-filter-summary {
  margin-left: $remGrid;
}
.long-summary {
  max-width: 212px;
  padding: 2 * $gridBase;
}

</style>
