<template>
  <div class="table-view-v2">
    <chart-title-bar
      :name.sync="nameModel"
      :is-edit="isEdit">
      <template #toolbarButtons>
        <slot name="toolbarButtons" />
      </template>
    </chart-title-bar>
    <template v-if="error">
      <error-view
        :name="name"
        @reload="$emit('reload')" />
    </template>
    <template v-else>
      <slot name="moduleControls" />
      <ingest-table-v2
        :fields="tableFields"
        :dimension-keys="dimensionKeys"
        :rows="tableData"
        @headerClick="onFieldClick($event)"
        @dataClick="$emit('click', $event.row)" />
    </template>
  </div>
</template>

<script>
  import { uniqBy } from 'lodash'
  import ChartTitleBar from '@/components/charting/ChartTitleBar'
  import ErrorView from '@/components/module/view/ErrorView.vue'
  import IngestTableV2 from '@/components/common/table/IngestTableV2'

  export default {
    name: 'TableViewV2',
    components: {
      ChartTitleBar,
      ErrorView,
      IngestTableV2,
    },
    props: {
      loading: Boolean,
      columns: { type: Array, required: true, },
      data: { type: Array, required: true, },
      comparison: { type: Object, default: null, },
      sortBy: { type: Array, default: Array, },
      name: { type: String, default: '', },
      isEdit: { type: Boolean, default: false, },
      error: { type: Boolean, default: false, },
    },
    data () {
      return {
        perPage: 10,
        currentPage: 1,
      }
    },
    computed: {
      nameModel: {
        get () {
          return this.name
        },
        set (name) {
          this.$emit('update:name', name)
        },
      },
      dimensionKeys () {
        return this.columns.filter(c => [
          'primary_dimension_key',
          'secondary_dimension_key',
        ]
          .includes(c.role)
        ).map(column => ({
          name: column.name,
          key: column.key,
          id: column.id,
          role: column.role,
          colorSpec: column.colorSpec,
          expression: column.expression,
          data_type: column.data_type,
          sortDir: this.fieldSortDir(column),
          clickable: column.drillDown,
        }))
      },
      tableFields () {
        return this.columns.filter(c => ![
          'primary_dimension_key',
          'secondary_dimension_key',
          'hidden',
        ]
          .includes(c.role)
          && !c.hidden).map(column => ({
          name: column.name,
          key: column.key,
          id: column.id,
          role: column.role,
          colorSpec: column.colorSpec,
          expression: column.expression,
          data_type: column.data_type,
          sortDir: this.fieldSortDir(column),
          clickable: column.drillDown,
        }))
      },
      tableData () {
        return this.data || []
      },
      varianceMeasure () {
        let candidates = this.columns.filter(column => column.role == 'variance_measure')
        if (candidates.length > 0) {
          return candidates[0]
        }
        return null
      },
    },
    methods: {
      fieldSortDir (field) {
        if (this.sortBy.length > 0 && this.sortBy[0].series_id === field.id) {
          if (this.sortBy[0].ascending) {
            return 'asc'
          } else {
            return 'desc'
          }
        }
        return null
      },
      isCurrentSort (field, asc) {
        return (
          this.sortBy.length > 0 && this.sortBy[0].series_id === field.id && this.sortBy[0].ascending
            === asc
        )
      },
      onFieldClick (field) {
        this.$emit('update:sortBy', [
          {
            series_id: field.id,
            ascending: !this.isCurrentSort(field, true),
          },
          ...uniqBy(this.sortBy.filter(order => order.series_id !== field.id),
                    order => order.series_id),
        ])
      },
    },
  }
</script>

<style lang="scss" scoped>

.table-view-v2 {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  padding: 8 * $gridBase;
  padding-top: 0;
}

.buttons {
  display: flex;
  align-items: center;
  padding: 2 * $gridBase;

  font-size: 1.8rem;
}

.toolbar {
  display: flex;
  width: 100%;
}

.name {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5 * $gridBase 8 * $gridBase;;

  div {
    padding-top: 6px;
    font-size: $h1;
    font-weight: $emp;
  }

  input {
    text-align: center;
  }
}

</style>
