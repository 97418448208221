import axios from 'axios'

import { uniqBy, isEqual, maxBy, minBy } from 'lodash'

import { wrapFilters } from '@/models/reporting/expressionHelpers'
import { extractDate, parseDate } from '@/utils/Date.js'

const SelectionType = {
  DATES_BETWEEN: 'DATES_BETWEEN',
  DATES_IN: 'DATES_IN',
  DROPDOWN_VALUES: 'DROPDOWN_SELECTIONS',
}

function offset_date (date, offset) {
  if (offset) {
    return extractDate(new Date(parseDate(date).setDate(parseDate(date).getUTCDate() - offset)))
  } else {
    return date
  }
}

export const datePickerModel = {
  namespaced: true,
  state () {
    return {
      dimensions: {},
      measures: {},
      allDates: [],
      loading: false,
      loadedCompanyId: null,
      initialized: false,
      dirty: false,
      selectionType: null,
      selection: null,
      pendingSelectionType: null,
      pendingSelection: null,
      includeToday: true,
    }
  },
  getters: {
    lastDay (_, getters) {
      return getters.appliedEffectiveDates.slice(-1).shift()
    },
    firstDay (_, getters) {
      return [...getters.appliedEffectiveDates,].shift()
    },
    availableExpressions (state, getters) {
      return {
        default_date_selection: {
          args: {},
          getExpression: () => ({
            type: 'const',
            data_type: { key: 'list', item_type: { key: 'date', }, },
            value: getters.appliedEffectiveDates.map((d) => d.date_key),
          }),
        },
        date_selection_start: {
          args: {},
          getExpression: () => ({
            type: 'const',
            data_type: { key: 'date', },
            value: getters.firstDay.date_key,
          }),
        },
        date_selection_end: {
          args: {},
          getExpression: () => ({
            type: 'const',
            data_type: { key: 'date', },
            value: getters.lastDay.date_key,
          }),
        },
        default_date_filter: {
          args: { expression: 'expression', },
          getExpression: (args) =>
            !getters.lastDay
              ? null
              : wrapFilters(args.expression, [
                  {
                    type: 'in',
                    left: {
                      type: 'field',
                      field: {
                        key: 'date',
                      },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'list', item_type: { key: 'date', }, },
                      value: getters.appliedEffectiveDates.map(
                        (d) => d.date_key
                      ),
                    },
                  },
                ]),
        },
        relative_date_filter_n_days: {
          args: {
            expression: 'expression',
            duration: 'string',
            offset: 'string',
          },
          getExpression: (args) => {
            return !getters.lastDay
              ? null
              : wrapFilters(args.expression, [
                  {
                    type: 'is_less_or_equal',
                    left: {
                      type: 'field',
                      field: {
                        key: 'date',
                      },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'date', },
                      value: offset_date(getters.lastDay.date, args.offset),
                    },
                  },

                  {
                    type: 'is_more_or_equal',
                    left: {
                      type: 'field',
                      field: {
                        key: 'date',
                      },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'date', },
                      value: extractDate(
                        new Date(
                          parseDate(getters.lastDay.date).setDate(
                            parseDate(getters.lastDay.date).getUTCDate() -
                              (args.duration - 1 + (args.offset || 0))
                          )
                        )
                      ),
                    },
                  },
                ])
          },
        },
        relative_date_filter_wtd: {
          args: { expression: 'expression', },
          getExpression: (args) =>
            !getters.lastDay
              ? null
              : wrapFilters(args.expression, [
                  {
                    type: 'is_less_or_equal',
                    left: {
                      type: 'field',
                      field: {
                        key: 'date',
                      },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'date', },
                      value: getters.lastDay.date,
                    },
                  },
                  {
                    type: 'in',
                    left: {
                      type: 'field',
                      field: { key: 'date', },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'list', item_type: { key: 'date', }, },
                      value: getters.availableDates
                        .filter(
                          (d) =>
                            d.year_key === getters.lastDay.year_key &&
                            d.week_key === getters.lastDay.week_key
                        )
                        .map((d) => d.date_key),
                    },
                  },
                ]),
        },
        relative_date_filter_ptd: {
          args: { expression: 'expression', },
          getExpression: (args) =>
            !getters.lastDay
              ? null
              : wrapFilters(args.expression, [
                  {
                    type: 'is_less_or_equal',
                    left: {
                      type: 'field',
                      field: {
                        key: 'date',
                      },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'date', },
                      value: getters.lastDay.date,
                    },
                  },
                  {
                    type: 'in',
                    left: {
                      type: 'field',
                      field: { key: 'date', },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'list', item_type: { key: 'date', }, },
                      value: getters.availableDates
                        .filter(
                          (d) =>
                            d.year_key === getters.lastDay.year_key &&
                            d.period_key === getters.lastDay.period_key
                        )
                        .map((d) => d.date_key),
                    },
                  },
                ]),
        },
        relative_date_filter_qtd: {
          args: { expression: 'expression', },
          getExpression: (args) =>
            !getters.lastDay
              ? null
              : wrapFilters(
                  args.expression,

                  [
                    {
                      type: 'is_less_or_equal',
                      left: {
                        type: 'field',
                        field: {
                          key: 'date',
                        },
                      },
                      right: {
                        type: 'const',
                        data_type: { key: 'date', },
                        value: getters.lastDay.date,
                      },
                    },
                    {
                      type: 'in',
                      left: {
                        type: 'field',
                        field: { key: 'date', },
                      },
                      right: {
                        type: 'const',
                        data_type: { key: 'list', item_type: { key: 'date', }, },
                        value: getters.availableDates
                          .filter(
                            (d) =>
                              d.year_key === getters.lastDay.year_key &&
                              d.quarter_key === getters.lastDay.quarter_key
                          )
                          .map((d) => d.date_key),
                      },
                    },
                  ]
                ),
        },
        relative_date_filter_ytd: {
          args: { expression: 'expression', },
          getExpression: (args) => !getters.lastDay ? null : wrapFilters(args.expression,
        [

          { type: 'is_less_or_equal',
            left: {
              type: 'field',
              field: {
                key: 'date',
              },
            },
            right: {
              type: 'const',
              data_type: { key: 'date', },
              value: getters.lastDay.date,
            },
          },
          {
            type: 'in',
            left: {
              type: 'field',
              field: { key: 'date', },
            },
            right: {
              type: 'const',
              data_type: { key: 'list', item_type: { key: 'date', }, },
              value: getters.availableDates
                  .filter(d => d.year_key === getters.lastDay.year_key)
                  .map(d => d.date_key),
              },
          },
        ]),
      },
        relative_date_filter_offset_wtd: {
          args: { expression: 'expression', offset: 'string', },
          getExpression: (args) => !getters.lastDay ? null : wrapFilters(args.expression, [

          { type: 'is_less_or_equal',
            left: {
              type: 'field',
              field: {
                key: 'date',
              },
            },
            right: {
              type: 'const',
              data_type: { key: 'date', },
              value: offset_date(getters.lastDay.date, args.offset),
            },
          },
          {
            type: 'in',
            left: {
              type: 'field',
              field: { key: 'date', },
            },
            right: {
              type: 'const',
              data_type: { key: 'list', item_type: { key: 'date', }, },
              value: getters.availableDates
                  .filter(d =>
                    d.year_key === getters.lastDay.year_key
                        && d.week_key === getters.lastDay.week_key)
                  .map(d => offset_date(d.date, args.offset)),
              },
          },
        ]),
      },
      relative_date_filter_offset_ptd: {
        args: { expression: 'expression', offset: 'string', },
        getExpression: (args) => !getters.lastDay ? null : wrapFilters (args.expression,
        [

          { type: 'is_less_or_equal',
            left: {
              type: 'field',
              field: {
                key: 'date',
              },
            },
            right: {
              type: 'const',
              data_type: { key: 'date', },
              value: offset_date(getters.lastDay.date, args.offset),
            },
          },
          {
            type: 'in',
            left: {
              type: 'field',
              field: { key: 'date', },
            },
            right: {
              type: 'const',
              data_type: { key: 'list', item_type: { key: 'date', }, },
              value: getters.availableDates
                  .filter(d =>
                    d.year_key === getters.lastDay.year_key
                        && d.period_key === getters.lastDay.period_key)
                  .map(d => offset_date(d.date, args.offset)),
              },
          },
        ]),
      },
        relative_date_filter_offset_qtd: {
          args: { expression: 'expression', offset: 'string', },
          getExpression: (args) => !getters.lastDay ? null : wrapFilters(args.expression,

        [

          { type: 'is_less_or_equal',
            left: {
              type: 'field',
              field: {
                key: 'date',
              },
            },
            right: {
              type: 'const',
              data_type: { key: 'date', },
              value: offset_date(getters.lastDay.date, args.offset),
            },
          },
          {
            type: 'in',
            left: {
              type: 'field',
              field: { key: 'date', },
            },
            right: {
              type: 'const',
              data_type: { key: 'list', item_type: { key: 'date', }, },
              value: getters.availableDates
                  .filter(d =>
                    d.year_key === getters.lastDay.year_key
                        && d.quarter_key === getters.lastDay.quarter_key)
                  .map(d => offset_date(d.date, args.offset)),
              },
          },
        ]),
      },
        relative_date_filter_offset_ytd: {
          args: { expression: 'expression', offset: 'string', },
          getExpression: (args) => !getters.lastDay ? null : wrapFilters(args.expression,
        [

          { type: 'is_less_or_equal',
            left: {
              type: 'field',
              field: {
                key: 'date',
              },
            },
            right: {
              type: 'const',
              data_type: { key: 'date', },
              value: offset_date(getters.lastDay.date, args.offset),
            },
          },
          {
            type: 'in',
            left: {
              type: 'field',
              field: { key: 'date', },
            },
            right: {
              type: 'const',
              data_type: { key: 'list', item_type: { key: 'date', }, },
              value: getters.availableDates
                  .filter(d => d.year_key === getters.lastDay.year_key)
                  .map(d => offset_date(d.date, args.offset)),
              },
          },
        ]),
      },
    }
    },
    appliedFilters (state, getters) {
      return [
        {
          type: 'in',
          left: {
            type: 'field',
            field: {
              key: 'date',
            },
          },
          right: {
            type: 'const',
            data_type: { key: 'list', item_type: { key: 'date', }, },
            value: getters.appliedEffectiveDates.map((d) => d.date_key),
          },
        },
      ]
    },
    isDirty (state) {
      return state.dirty
    },
    appliedEffectiveDates (state, getters) {
      let results = []
      if (state.selectionType === SelectionType.DATES_IN) {
        results = state.selection
      } else if (state.selectionType === SelectionType.DATES_BETWEEN) {
        results = getters.availableDates.filter(
          (d) =>
            d.date >= state.selection.start && d.date <= state.selection.end
        )
      } else if (state.selectionType === SelectionType.DROPDOWN_VALUES) {
        results = getters.availableDates.filter(
          (d) =>
            state.selection.years.map((y) => y.key).includes(d.year_key) &&
            state.selection.quarters
              .map((y) => y.key)
              .includes(d.quarter_key) &&
            state.selection.periods.map((y) => y.key).includes(d.period_key) &&
            state.selection.weeks.map((y) => y.key).includes(d.week_key) &&
            state.selection.days.map((y) => y.key).includes(d.day_key)
        )
      }
      if (!state.includeToday) {
        results = results.filter((d) => d.date_key != getters.today)
      }
      return results
    },
    pendingEffectiveDates (state, getters) {
      if (state.pendingSelectionType === SelectionType.DATES_IN) {
        return state.pendingSelection
      } else if (state.pendingSelectionType === SelectionType.DATES_BETWEEN) {
        return getters.availableDates.filter(
          (d) =>
            d.date >= state.pendingSelection.start &&
            d.date <= state.pendingSelection.end
        )
      } else if (state.pendingSelectionType === SelectionType.DROPDOWN_VALUES) {
        return getters.availableDates.filter(
          (d) =>
            state.pendingSelection.years
              .map((y) => y.key)
              .includes(d.year_key) &&
            state.pendingSelection.quarters
              .map((y) => y.key)
              .includes(d.quarter_key) &&
            state.pendingSelection.periods
              .map((y) => y.key)
              .includes(d.period_key) &&
            state.pendingSelection.weeks
              .map((y) => y.key)
              .includes(d.week_key) &&
            state.pendingSelection.days.map((y) => y.key).includes(d.day_key)
        )
      } else {
        return []
      }
    },
    appliedEffectiveRange (state, getters) {
      if (state.selectionType == SelectionType.DATES_BETWEEN) {
        return state.selection
      }
      let end = extractDate(new Date(0))
      let start = extractDate(new Date())
      getters.appliedEffectiveDates.forEach((d) => {
        let date = d.date
        if (start >= date) {
          start = date
        }
        if (end <= date) {
          end = date
        }
      })
      return {
        start: start,
        end: end,
      }
    },
    appliedEffectiveRangeStart (state, getters) {
      return getters.appliedEffectiveRange.start
    },
    appliedEffectiveRangeEnd (state, getters) {
      return getters.appliedEffectiveRange.end
    },
    isAppliedContiguous (state, getters) {
      return (
        getters.appliedEffectiveDates.length ===
        Math.round(
          (parseDate(getters.appliedEffectiveRangeEnd).getTime() -
            parseDate(getters.appliedEffectiveRangeStart).getTime()) /
            (1000 * 60 * 60 * 24) +
            1
        )
      )
    },
    pendingEffectiveRange (state, getters) {
      if (state.pendingSelectionType == SelectionType.DATES_BETWEEN) {
        return state.pendingSelection
      }
      let end = extractDate(new Date(0))
      let start = extractDate(new Date())
      getters.pendingEffectiveDates.forEach((d) => {
        let date = d.date
        if (start >= date) {
          start = date
        }
        if (end <= date) {
          end = date
        }
      })
      return {
        start: start,
        end: end,
      }
    },
    pendingEffectiveRangeStart (state, getters) {
      return getters.pendingEffectiveRange.start
    },
    pendingEffectiveRangeEnd (state, getters) {
      return getters.pendingEffectiveRange.end
    },
    availableYears (state, getters) {
      return uniqBy(
        getters.availableDates.map((d) => ({ value: d.year, key: d.year_key, })),
        (y) => y.key
      )
    },
    pendingEffectiveYears (state, getters) {
      if (state.pendingSelectionType === SelectionType.DROPDOWN_VALUES) {
        return state.pendingSelection.years
      } else {
        return uniqBy(
          getters.pendingEffectiveDates.map((d) => ({
            value: d.year,
            key: d.year_key,
          })),
          (y) => y.key
        )
      }
    },
    isYTDSelection (state, getters) {
      if (!getters.availableDates.length > 0) {
        return false
      }
      let lastYearKey = getters.availableDates.slice(-1).shift().year_key
      return isEqual(
        getters.availableDates
          .filter((d) => d.year_key === lastYearKey)
          .map((d) => d.year_key),
        getters.pendingEffectiveDates.map((d) => d.year_key)
      )
    },
    availableQuarters (state, getters) {
      return uniqBy(
        getters.availableDates
          .filter((d) =>
            getters.pendingEffectiveYears.map((y) => y.key).includes(d.year_key)
          )
          .map((d) => ({ value: d.quarter, key: d.quarter_key, })),
        (q) => q.key
      )
    },
    pendingEffectiveQuarters (state, getters) {
      if (state.pendingSelectionType === SelectionType.DROPDOWN_VALUES) {
        return state.pendingSelection.quarters
      } else {
        return uniqBy(
          getters.pendingEffectiveDates.map((d) => ({
            value: d.quarter,
            key: d.quarter_key,
          })),
          (q) => q.key
        )
      }
    },
    isQTDSelection (state, getters) {
      if (!getters.availableDates.length > 0) {
        return false
      }
      let lastYearKey = getters.availableDates.slice(-1).shift().year_key
      let lastQuarterKey = getters.availableDates.slice(-1).shift().quarter_key
      return isEqual(
        getters.availableDates
          .filter((d) => d.year_key === lastYearKey)
          .filter((d) => d.quarter_key === lastQuarterKey)
          .map((d) => d.quarter_key),
        getters.pendingEffectiveDates.map((d) => d.quarter_key)
      )
    },
    availablePeriods (state, getters) {
      return uniqBy(
        getters.availableDates
          .filter(
            (d) =>
              getters.pendingEffectiveYears
                .map((y) => y.key)
                .includes(d.year_key) &&
              getters.pendingEffectiveQuarters
                .map((q) => q.key)
                .includes(d.quarter_key)
          )
          .map((d) => ({ value: d.period, key: d.period_key, })),
        (p) => p.key
      )
    },
    pendingEffectivePeriods (state, getters) {
      if (state.pendingSelectionType === SelectionType.DROPDOWN_VALUES) {
        return state.pendingSelection.periods
      } else {
        return uniqBy(
          getters.pendingEffectiveDates.map((d) => ({
            value: d.period,
            key: d.period_key,
          })),
          (p) => p.key
        )
      }
    },
    isPTDSelection (state, getters) {
      if (!getters.availableDates.length > 0) {
        return false
      }
      let lastYearKey = getters.availableDates.slice(-1).shift().year_key
      let lastQuarterKey = getters.availableDates.slice(-1).shift().quarter_key
      let lastPeriodKey = getters.availableDates.slice(-1).shift().period_key
      return isEqual(
        getters.availableDates
          .filter((d) => d.year_key === lastYearKey)
          .filter((d) => d.quarter_key === lastQuarterKey)
          .filter((d) => d.period_key === lastPeriodKey)
          .map((d) => d.period_key),
        getters.pendingEffectiveDates.map((d) => d.period_key)
      )
    },
    availableWeeks (state, getters) {
      return uniqBy(
        getters.availableDates
          .filter(
            (d) =>
              getters.pendingEffectiveYears
                .map((y) => y.key)
                .includes(d.year_key) &&
              getters.pendingEffectiveQuarters
                .map((q) => q.key)
                .includes(d.quarter_key) &&
              getters.pendingEffectivePeriods
                .map((p) => p.key)
                .includes(d.period_key)
          )
          .map((d) => ({ value: d.week, key: d.week_key, })),
        (w) => w.key
      )
    },
    pendingEffectiveWeeks (state, getters) {
      if (state.pendingSelectionType === SelectionType.DROPDOWN_VALUES) {
        return state.pendingSelection.weeks
      } else {
        return uniqBy(
          getters.pendingEffectiveDates.map((d) => ({
            value: d.week,
            key: d.week_key,
          })),
          (w) => w.key
        )
      }
    },
    isWTDSelection (state, getters) {
      if (!getters.availableDates.length > 0) {
        return false
      }
      let lastYearKey = getters.availableDates.slice(-1).shift().year_key
      let lastQuarterKey = getters.availableDates.slice(-1).shift().quarter_key
      let lastPeriodKey = getters.availableDates.slice(-1).shift().period_key
      let lastWeekKey = getters.availableDates.slice(-1).shift().week_key
      return isEqual(
        getters.availableDates
          .filter((d) => d.year_key === lastYearKey)
          .filter((d) => d.quarter_key === lastQuarterKey)
          .filter((d) => d.period_key === lastPeriodKey)
          .filter((d) => d.week_key === lastWeekKey)
          .map((d) => d.week_key),
        getters.pendingEffectiveDates.map((d) => d.week_key)
      )
    },
    availableDays (state, getters) {
      return uniqBy(
        getters.availableDates
          .filter(
            (d) =>
              getters.pendingEffectiveYears
                .map((y) => y.key)
                .includes(d.year_key) &&
              getters.pendingEffectiveQuarters
                .map((q) => q.key)
                .includes(d.quarter_key) &&
              getters.pendingEffectivePeriods
                .map((p) => p.key)
                .includes(d.period_key) &&
              getters.pendingEffectiveWeeks
                .map((w) => w.key)
                .includes(d.week_key)
          )
          .map((d) => ({ value: d.day, key: d.day_key, })),
        (d) => d.key
      )
    },
    pendingEffectiveDays (state, getters) {
      if (state.pendingSelectionType === SelectionType.DROPDOWN_VALUES) {
        return state.pendingSelection.days
      } else {
        return uniqBy(
          getters.pendingEffectiveDates.map((d) => ({
            value: d.day,
            key: d.day_key,
          })),
          (d) => d.key
        )
      }
    },
    initialized (state) {
      return state.initialized
    },
    today () {
      return extractDate(new Date())
    },
    yesterday () {
      let yesterDay = new Date()
      yesterDay.setDate(yesterDay.getDate() - 1)
      return extractDate(yesterDay)
    },
    last7Days (state, getters) {
      let startDate = parseDate(getters.latestAvailableDate.date)
      startDate.setDate(startDate.getDate() - 7)
      let start = extractDate(startDate)
      let end = getters.latestAvailableDate.date
      return getters.availableDates.filter(
        (d) => d.date > start && d.date <= end
      )
    },
    last30Days (state, getters) {
      let startDate = parseDate(getters.latestAvailableDate.date)
      startDate.setDate(startDate.getDate() - 30)
      let start = extractDate(startDate)
      let end = getters.latestAvailableDate.date
      return getters.availableDates.filter(
        (d) => d.date > start && d.date <= end
      )
    },
    last90Days (state, getters) {
      let startDate = parseDate(getters.latestAvailableDate.date)
      startDate.setDate(startDate.getDate() - 90)
      let start = extractDate(startDate)
      let end = getters.latestAvailableDate.date
      return getters.availableDates.filter(
        (d) => d.date > start && d.date <= end
      )
    },
    last5Weeks (state, getters) {
      let startDate = parseDate(getters.latestAvailableDate.date)
      startDate.setDate(startDate.getDate() - 35)
      let start = extractDate(startDate)
      let end = getters.latestAvailableDate.date
      return getters.availableDates.filter(
        (d) => d.date > start && d.date <= end
      )
    },
    availableDates (state, getters) {
      return state.allDates.filter((d) => d.date <= getters.today)
    },
    earliestAvailableDate (state, getters) {
      return minBy(getters.availableDates, (d) => d.date)
    },
    latestAvailableDate (state, getters) {
      return maxBy(getters.availableDates, (d) => d.date)
    },
    isTodaySelection (state, getters) {
      return (
        getters.pendingEffectiveDates.length == 1 &&
        getters.pendingEffectiveDates[0].date == getters.today
      )
    },
    hasTodaySelection (state, getters) {
      return (
        getters.availableDates.filter((d) => d.date == getters.today).length > 0
      )
    },
    isYesterdaySelection (state, getters) {
      return (
        getters.pendingEffectiveDates.length == 1 &&
        getters.pendingEffectiveDates[0].date == getters.yesterday
      )
    },
    hasYesterdaySelection (state, getters) {
      return (
        getters.availableDates.filter((d) => d.date == getters.yesterday)
          .length > 0
      )
    },
  },
  mutations: {
    setLoadedCompany (state, companyId) {
      state.loadedCompanyId = companyId
    },
    setInitialized (state, initialized) {
      state.initialized = initialized
    },
    setIncludeToday (state, includeToday) {
      state.includeToday = includeToday
      state.dirty = true
    },
    setRangeSelection (state, range) {
      state.pendingSelectionType = SelectionType.DATES_BETWEEN
      state.pendingSelection = range
      state.dirty = true
    },
    setDropdownSelection (state, dropdownSelection) {
      state.pendingSelectionType = SelectionType.DROPDOWN_VALUES
      state.pendingSelection = dropdownSelection
      state.dirty = true
    },
    setStaticSelection (state, dates) {
      state.pendingSelectionType = SelectionType.DATES_IN
      state.pendingSelection = dates
      state.dirty = true
    },
    resetPending (state) {
      state.pendingSelection = state.selection
      state.pendingSelectionType = state.selectionType
      state.dirty = false
    },
    applyPending (state) {
      state.selection = state.pendingSelection
      state.selectionType = state.pendingSelectionType
      state.dirty = false
    },
    setAllDates (state, dates) {
      state.allDates = dates
    },
    setLoading (state, loading) {
      state.loading = loading
    },
  },
  actions: {
    apply (context) {
      context.commit('applyPending')
    },
    reset (context) {
      context.commit('resetPending')
    },
    selectRangeStart (context, start) {
      context.commit('setRangeSelection', {
        start,
        end: context.getters.pendingEffectiveRangeEnd,
      })
    },
    selectRangeEnd (context, end) {
      context.commit('setRangeSelection', {
        start: context.getters.pendingEffectiveRangeStart,
        end,
      })
    },
    selectIncludeToday (context, includeToday) {
      context.commit('setIncludeToday', includeToday)
    },
    selectYears (context, years) {
      context.commit('setDropdownSelection', { years, })
      context.dispatch('selectQuarters', context.getters.availableQuarters)
    },
    selectQuarters (context, quarters) {
      context.commit('setDropdownSelection', {
        years: context.getters.pendingEffectiveYears,
        quarters,
      })
      context.dispatch('selectPeriods', context.getters.availablePeriods)
    },
    selectPeriods (context, periods) {
      context.commit('setDropdownSelection', {
        years: context.getters.pendingEffectiveYears,
        quarters: context.getters.pendingEffectiveQuarters,
        periods,
      })
      context.dispatch('selectWeeks', context.getters.availableWeeks)
    },
    selectWeeks (context, weeks) {
      context.commit('setDropdownSelection', {
        years: context.getters.pendingEffectiveYears,
        quarters: context.getters.pendingEffectiveQuarters,
        periods: context.getters.pendingEffectivePeriods,
        weeks,
      })
      context.dispatch('selectDays', context.getters.availableDays)
    },
    selectDays (context, days) {
      context.commit('setDropdownSelection', {
        years: context.getters.pendingEffectiveYears,
        quarters: context.getters.pendingEffectiveQuarters,
        periods: context.getters.pendingEffectivePeriods,
        weeks: context.getters.pendingEffectiveWeeks,
        days,
      })
    },
    selectYTD (context) {
      context.dispatch('selectYears', context.getters.availableYears.slice(-1))
    },
    selectQTD (context) {
      context.dispatch('selectYTD')
      context.dispatch(
        'selectQuarters',
        context.getters.availableQuarters.slice(-1)
      )
    },
    selectPTD (context) {
      context.dispatch('selectYTD')
      context.dispatch('selectQTD')
      context.dispatch(
        'selectPeriods',
        context.getters.availablePeriods.slice(-1)
      )
    },
    selectWTD (context) {
      context.dispatch('selectYTD')
      context.dispatch('selectQTD')
      context.dispatch('selectPTD')
      context.dispatch('selectWeeks', context.getters.availableWeeks.slice(-1))
    },
    selectLast7Days (context) {
      context.commit('setStaticSelection', context.getters.last7Days)
    },
    selectLast30Days (context) {
      context.commit('setStaticSelection', context.getters.last30Days)
    },
    selectLast90Days (context) {
      context.commit('setStaticSelection', context.getters.last90Days)
    },
    selectAll (context) {
      context.commit('setStaticSelection', context.getters.availableDates)
    },
    selectLast5Weeks (context) {
      context.commit('setStaticSelection', context.getters.last5Weeks)
    },
    selectToday (context) {
      context.dispatch('selectDate', context.getters.today)
    },
    selectYesterday (context) {
      context.dispatch('selectDate', context.getters.yesterday)
    },
    selectAccuracyMode (context, accurateSelections) {
      let dates = new Set()
      accurateSelections.forEach((accurateSelection) => {
        context.getters.availableDates
          .filter(
            (d) =>
              (d.year_key == accurateSelection.year ||
                accurateSelection.year == undefined) &&
              (d.quarter_key == accurateSelection.quarter ||
                accurateSelection.quarter == undefined) &&
              (d.period_key == accurateSelection.period ||
                accurateSelection.period == undefined) &&
              (d.week_key == accurateSelection.week ||
                accurateSelection.week == undefined) &&
              (d.date_key == accurateSelection.date ||
                accurateSelection.date == undefined)
          )
          .forEach((d) => {
            dates.add(d.date)
          })
      })

      context.commit(
        'setStaticSelection',
        context.getters.availableDates.filter((d) => dates.has(d.date))
      )
    },
    selectDates (context, dates) {
      dates = new Set(dates)
      context.commit(
        'setStaticSelection',
        context.getters.availableDates.filter((d) => dates.has(d.date))
      )
    },
    selectDate (context, date) {
      context.commit(
        'setStaticSelection',
        context.getters.availableDates.filter((d) => d.date == date)
      )
    },
    async applyCompanyId (context, companyId) {
      if (
        !context.rootGetters['auth/isLoggedIn'] ||
        companyId === context.state.loadedCompanyId
      ) {
        return
      }
      context.commit('setLoadedCompany', companyId)
      context.commit('setLoading', true)
      try {
        let datesResponse = await axios.get(
          `${process.env.VUE_APP_API_URL}/company/${companyId}/availableDates`,
          { headers: { Authorization: context.rootGetters['auth/authToken'], }, }
        )

        if (companyId === context.state.loadedCompanyId) {
          context.commit('setAllDates', datesResponse.data)
          context.commit('setLoading', false)
        }
      } catch (e) {
        console.log(e)
        if (companyId === context.state.loadedCompanyId) {
          context.commit('setLoading', false)
        }
      }
    },
    async initialize (context) {
      if (
        context.state.loading ||
        context.getters.initialized ||
        !context.rootGetters['auth/isLoggedIn']
      ) {
        return
      }
      await context.dispatch(
        'applyCompanyId',
        context.rootGetters['companyId']
      )
      context.commit('setInitialized', true)
    },
  },
  modules: {},
}
