import posthog from 'posthog-js'

export default {
  install (Vue) {
    Vue.prototype.$tracker = posthog.init(
      'phc_6J4IwnZgu4eo2YDrR9jB5ieoIJkkfMP0A09gegRFlxV',
      {
        api_host: 'https://us.i.posthog.com',
      }
    )
  },
}
