<template>
  <RecycleScroller
    v-slot="{ item }"
    class="multi-select"
    :class="{ rounded }"
    :items="options"
    :item-size="itemSize"
    :key-field="keyField">
    <slot
      :option="item"
      name="option">
      <multi-select-item
        :selected="inverted ? !isSelected(item) : isSelected(item)"
        :rounded="roundItems"
        :round-top="rounded && false"
        :round-bottom="rounded && false"
        :disabled="disabled || !!item.disabled"
        :color="color"
        :size="size"
        @update:selected="onSelect(item, $event)">
        <template #label>
          <slot
            name="label"
            :item="item">
            {{ item.label }}
          </slot>
        </template>
        <template #icon>
          <slot
            name="icon"
            :item="item">
            <i
              v-if="!!item.icon"
              class="material-icons"> {{ item.icon }} </i>
          </slot>
        </template>
      </multi-select-item>
    </slot>
  </RecycleScroller>
</template>

<script>

  export default {
    name: 'MultiSelectOptimized',
    props: {
      options: { type: Array, default: Array, },
      value: { type: Array, default: null, },
      keyField: { type: String, default: null, },
      color: { type: String, default: 'ui-neutral', },
      size: { type: String, default: 'h3', },
      itemSize: { type: Number, default: 45, },
      rounded: { type: Boolean, default: true, },
      roundItems: { type: Boolean, default: false, },
      disabled: { type: Boolean, default: false, },
      inverted: { type: Boolean, default: false, },
    },
    methods: {
      isSelected (item) {
        if (this.value == null) {
          return false
        } else if (this.keyField == null) {
          return this.value.includes(item)
        } else {
          return this.value.map(selection => selection[this.keyField]).includes(item[this.keyField])
        }
      },
      onSelect (value, selected) {
        if (!this.disabled) {
          this.$emit('input', this.value.filter(o => o[this.keyField] != value[this.keyField]).concat(selected ^ this.inverted ? [value,] : []))
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

.multi-select {
  display: flex;
  flex-direction: column;

  &.rounded {
    border-radius: 2 * $gridBase;
  }
}

</style>

