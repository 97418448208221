<template>
  <div class="use-bulma">
    <div class="expression-graph-container">
      <div
        v-if="!isSubTree"
        class="expression-name">
        <span v-if="currentExpression.id">{{ currentExpression.name }}</span>
        <span v-else>Select an expression to view</span>
      </div>
      <div class="view">
        <div ref="panzoomElement">
          <div
            v-if="!loading && expressionHasNodes"
            class="tree">
            <ul :class="{ 'hide-start-lines': isSubTree }">
              <li>
                <div
                  v-if="!isSubTree"
                  class="node">
                  <img
                    class="breads"
                    src="@/assets/images/bread.svg" />
                </div>

                <ul :class="{ 'hide-start-lines': isSubTree }">
                  <li>
                    <div class="node">
                      <ul :class="{ 'hide-start-lines': isSubTree }">
                        <tree-node :data="fromattedJson" />
                      </ul>
                    </div>

                    <ul
                      v-if="!isSubTree"
                      class="final">
                      <li>
                        <div class="node">
                          <img src="@/assets/images/sandwitch.svg" />
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Panzoom from '@panzoom/panzoom'
  export default {
    name: 'ExpressionGraph',
    components: {},
    props: {
      expressionId: {
        type: Number,
        default: 0,
      },
      expressionSpec: {
        type: Object,
        default: () => {},
      },
      isSubTree: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        aggregates: [
          'sum',
          'max',
          'min',
          'count',
          'extract_year',
          'extract_week',
          'extract_month',
        ],
        arithmetic: ['add', 'sub', 'mult', 'div', 'cast',],
        comparisons: [
          'in',
          'is',
          'or',
          'not',
          'is_less',
          'is_less_or_equal',
          'is_more',
          'is_more_or_equal',
        ],
        skip: ['cast',],
        selectedExpression: null,
        fromattedJson: {},
        loading: true,
        expressionHasNodes: false,
        currentExpression: {},
        searchInput: '',
      }
    },
    computed: {
      expressionsObject () {
        return this.$store.getters['expressions/companyExpressions']
      },
    },
    watch: {
      expressionId (newVal) {
        if (newVal) {
          const newExpression = this.fetchCompanyExpression(newVal)
          this.selectExpression(newExpression)
        }
      },
    },
    beforeCreate: function () {
      this.$options.components.treeNode =
        require('@/components/expression_viewer/TreeNode').default
    },
    async mounted () {
      if (this.expressionSpec) {
        this.selectExpression(this.expressionSpec)
      } else if (this.expressionId) {
        const newExpression = this.fetchCompanyExpression(this.expressionId)
        this.selectExpression(newExpression)
      }
      if (!this.isSubTree) {
        await this.$nextTick()
        this.panzoom = Panzoom(this.$refs.panzoomElement, {
          maxScale: 3,
          minScale: 0.3,
        })
        this.$refs.panzoomElement.parentElement.addEventListener(
          'wheel',
          this.panzoom.zoomWithWheel
        )
      }
    },
    methods: {
      selectExpression (expression) {
        if (!expression) {
          return
        }
        this.loading = true
        this.fromattedJson = {}
        this.currentExpression = expression
        this.convertJSON(expression.spec, this.fromattedJson)
        this.expressionHasNodes = !!(
          this.fromattedJson && this.fromattedJson.node.type
        ) //checks if expression has atleast one node
        this.loading = false
      },
      isArithmetic (type) {
        return this.arithmetic.includes(type)
      },
      isAggregate (type) {
        return this.aggregates.includes(type)
      },
      isComparison (type) {
        return this.comparisons.includes(type)
      },
      isSkipNode (type) {
        return this.skip.includes(type)
      },

      convertJSON (data, newJson) {
        newJson.node = {}
        if (data.by && data.by.length) {
          newJson.node.type = 'filter'
          newJson.node.by = this.convertFilter(data.by)

          if (data.expression) {
            newJson.child = {}
            this.convertJSON(data.expression, newJson.child)
          }
        }

        if (this.isArithmetic(data.type)) {
          newJson.node.type = 'arithmetic'
          newJson.node.values = this.convertArithmetic(data)
        } else if (this.isComparison(data.type)) {
          newJson.node.type = 'comparison'
          newJson.node.values = this.convertBlockComparison(data)
        } else if (this.isAggregate(data.type)) {
          newJson.node = this.convertAggregate(data)
        } else if (data.type === 'company_expression') {
          newJson.node = this.convertCompanyExpression(data)
        } else if (data.type === 'entity_attribute') {
          newJson.node = this.convertEntityAttibute(data.entity_attributes)
        } else if (data.type === 'field') {
          newJson.node = {
            type: 'inline-expression',
            on: 'field',
            target: data.field.key,
          }
        } else if (data.type === 'const') {
          newJson.node = {
            type: 'inline-expression',
            on: 'value',
            valueType: 'const',
            valueDataType: data.data_type.key,
            value: data.value,
          }
        } else if (this.isSkipNode(data.type)) {
          this.convertJSON(data.expression, newJson)
        }
      },

      convertFilter (data) {
        try {
          const filters = data.map((item) => this.processInlineComparison(item))
          return filters
        } catch (e) {
          return { type: 'error', errorData: data, nodeType: 'filter', }
        }
      },

      processInlineComparison (item, addNegation = false) {
        if (item.type === 'not') {
          return this.processInlineComparison(item.expression, true)
        }

        if (item.right || item.left) {
          return this.processLeftAndRightEquations(item, addNegation)
        } else {
          return {
            left: this.processEquationSide(item),

            condition: 'is',

            right: {
              type: 'inline-expression',
              on: 'value',
              value: !addNegation,
              valueType: 'Bool',
            },
          }
        }
      },
      processLeftAndRightEquations (item, addNegation = false) {
        let left, right, condition

        condition = `${addNegation ? 'not' : ''} ${item.type}`

        left = this.processEquationSide(item.left)
        right = this.processEquationSide(item.right)

        if (item.right && item.right.type === 'field') {
          //swap left and right(type of field should alwarys be on the left)

          return {
            left: right,
            right: left,
            condition,
          }
        }
        return {
          left,
          right,
          condition,
        }
      },

      processEquationSide (data) {
        let result
        if (data.field) {
          result = {
            type: 'inline-expression',
            on: 'field',
            target: data.field.key,
          }
        } else if (data.value) {
          result = {
            type: 'inline-expression',
            on: 'value',
            value: data.value,
            valueType: data.type,
            valueDataType: data.data_type.key,
          }
        } else if (data.type === 'company_expression') {
          result = this.convertCompanyExpression(data)
        } else if (data.type === 'entity_attribute') {
          result = this.convertEntityAttibute(data.entity_attribute)
        }
        return result
      },

      convertArithmetic (data) {
        try {
          if (this.isSkipNode(data.type)) {
            const nextNode = data.expression
            data = nextNode
          }
          const operation = data.type

          const values = [{}, {},]

          this.convertJSON(data.left, values[0])
          this.convertJSON(data.right, values[1])

          return {
            operation,
            values,
          }
        } catch (e) {
          return { type: 'error', errorData: data, nodeType: 'arithmetic', }
        }
      },

      convertBlockComparison (data, addNegation = false) {
        try {
          if (this.isSkipNode(data.type)) {
            const nextNode = data.expression
            data = nextNode
          }

          let operation
          const values = [{}, {},]
          const comparisonOperator = data.type

          if (comparisonOperator === 'not') {
            return this.convertBlockComparison(data.expression, true)
          } else if (data.left || data.right) {
            this.convertJSON(data.left, values[0])
            this.convertJSON(data.right, values[1])
            operation = addNegation
              ? 'not ' + comparisonOperator
              : comparisonOperator
          } else {
            const result = this.processInlineComparison(data)
            values[0].node = result.left
            values[1].node = result.right
            operation = addNegation ? 'is not ' : 'is'
          }
          return {
            operation,
            values,
          }
        } catch (e) {
          return { type: 'error', errorData: data, nodeType: 'comparison', }
        }
      },
      convertAggregate (data) {
        try {
          if (this.isSkipNode(data.expression.type)) {
            const nextNode = data.expression.expression
            data.expression = nextNode
          }

          if (data.expression.type === 'company_expression') {
            return {
              ...this.convertCompanyExpression(data.expression),
              operation: data.type,
            }
          }

          return {
            type: 'inline-expression',
            operation: data.type,
            on: 'field',
            target: data.expression.field.key,
          }
        } catch (e) {
          return { type: 'error', errorData: data, nodeType: 'aggregate', }
        }
      },

      convertEntityAttibute (data) {
        try {
          return {
            type: 'inline-expression',
            on: 'entity_attribute',
            attibuteName: data.name,
            entityType: data.entity_type.name,
            dataType: data.data_type.key,
          }
        } catch (e) {
          return { type: 'error', errorData: data, nodeType: 'entity attribute', }
        }
      },
      convertCompanyExpression (data) {
        try {
          const expression = this.fetchCompanyExpression(data.expression_id)
          return {
            type: 'inline-expression',
            on: 'company_expression',
            expressionId: data.expression_id,
            name: expression.name,
          }
        } catch (e) {
          return {
            type: 'error',
            errorData: data,
            nodeType: 'company expression',
          }
        }
      },
      fetchCompanyExpression (id) {
        return this.expressionsObject[id]
      },
    },
  }
</script>

<style lang="scss" scoped>
.use-bulma {
  position: relative;
  width: 100%;
  display: flex;
  font-size: 16px;
}

.expression-graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  .expression-name {
    padding: 0 20px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    height: 50px;
    border: $border-1;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    z-index: 5;
    position: relative;
  }
}
.view {
  width: 100%;
  height: 100%;
}

.final {
  position: relative;
  top: -20px;
  padding-top: 3em !important;

  &::before {
    height: 4em;
  }
}
</style>

<style lang="scss">
.expression-graph-container {
  $tree-connector-border-width: 2px;

  .flexing-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  .tree {
    &.reverse {
      transform: rotate(180deg);
      transform-origin: 50%;
    }
  }

  .tree ul {
    position: relative;
    padding: 1em 0 !important;
    white-space: nowrap;
    margin: 0 auto;
    text-align: center;

    &.hide-start-lines {
      padding: 0 !important;
      &::before,
      &::after {
        border: none !important;
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .tree li {
    display: inline-block; // need white-space fix
    vertical-align: top;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 1em 0.5em 0 0.5em !important;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 50%;
      border-top: $tree-connector-border-width dashed #bfbfbf;
      width: 50%;
      height: 1em;
    }

    &::after {
      right: auto;
      left: 50%;
      border-left: $tree-connector-border-width dashed #bfbfbf;
    }

    &:only-child::after,
    &:only-child::before {
      display: none;
    }

    &:only-child {
      padding-top: 0 !important;
    }

    &:first-child::before,
    &:last-child::after {
      border: 0 none;
    }

    &:last-child::before {
      border-right: $tree-connector-border-width dashed #bfbfbf;
      border-radius: 0 5px 0 0;
    }

    &:first-child::after {
      border-radius: 5px 0 0 0;
    }
  }

  .tree ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: $tree-connector-border-width dashed #bfbfbf;
    width: 0;
    height: 1em;
  }

  .tree li .node {
    padding: 0.5em 0.75em !important;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    position: relative;
    top: $tree-connector-border-width;

    &.reverse {
      transform: rotate(180deg);
    }
  }
}
</style>
