<template>
  <div class="row-item">
    <div class="item-header">
      <button class="button is-text drag">
        <img
          src="@/assets/icons/drag.svg"
          alt="" />
      </button>

      <button
        type="button"
        class="delete"
        @click="$emit('delete')" />
    </div>

    <b-field
      label="Title"
      class="input-field"
      message="">
      <b-input
        v-model="inputs.title"
        type="" />
    </b-field>

    <b-field
      label="Sub Title"
      class="input-field"
      message="">
      <b-input
        v-model="inputs.sub_title"
        type="" />
    </b-field>
  </div>
</template>

<script>
  export default {
    name: 'TrackerTotalHeader',
    components: {},
    props: {
      value: {
        type: Object,
        default: () => { },
      },
    },
    data () {
      return {}
    },
    computed: {
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
    mounted () {},

    beforeDestroy () {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
.row-item {
  margin: 10px 0;
  border: $border-1;
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px;

  .item-header {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .drag {
      width: 25px;
      height: 25px;
      padding: 0;
      margin-right: auto;
      img {
        width: 20px;
      }
    }
  }
}
</style>
