<template>
  <div class="number-filter secondary-01-background">
    <number-input v-model="start" />
    &nbsp;-&nbsp;
    <number-input v-model="end" />
  </div>
</template>

<script>

  import NumberInput from '@/components/common/input/NumberInput'

  export default {
    name: 'NumberFilter',
    components: {
      NumberInput,
    },
    props: {
      dimension: { type: Object, required: true, },
      filter: { type: Object, default: null, },
    },
    computed: {
      start: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.start
          }
          return null
        },
        set (start) {
          this.updateFilter(parseInt(start), this.end)
        },
      },
      end: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.end
          }
          return null
        },
        set (end) {
          this.updateFilter(this.start, parseInt(end))
        },
      },
    },
    methods: {
      updateFilter (start, end) {
        this.$emit('update:filter', {
          type: 'between',
          dimension: this.dimension.key,
          args: { start, end, },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.number-filter {
  display: flex;
  align-items: center;
}
</style>
