<template>
  <div class="sub-section">
    <b-field
      class="input-field"
      label="Dimension">
      <CompanyDimensionRefSelect v-model="inputs" />
    </b-field>
  </div>
</template>

<script>

  import CompanyDimensionRefSelect from '@/components/reports/config/dimensions/CompanyDimensionRefSelect'

  export default {
    name: 'DimensionTab',
    components: {
      CompanyDimensionRefSelect,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    computed: {

      inputs: {
        get () {
          return this.value || {}
        },
        set (value) {
          this.$emit('input', value ? value : null)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
