<template>
  <div
    class="group-item"
    @mouseover.stop.prevent="">
    <div class="row-item">
      <div class="toggle-group">
        <button
          class="button open-button"
          @click="open = !open">
          <i class="material-icons dropdown-icon">
            {{ open ? "arrow_drop_up" : "arrow_drop_down" }}
          </i>
        </button>
        <b-tooltip
          style="width: 100%"
          position="is-right"
          type="is-light"
          :append-to-body="true"
          multilined>
          <template #content>
            <div
              v-if="isPivotGroup"
              class="data-tooltip">
              <div>
                <h2>Pivot Dimension:</h2>
                {{
                  module?.table_pivot?.dimension?.name ||
                    module?.table_pivot?.dimension?.key ||
                    "Null"
                }}
              </div>
              <div v-if="module?.table_pivot?.table_totals?.length">
                <hr />
                <h2>Total Columns:</h2>
                <div>
                  <div
                    v-for="column in module?.table_pivot?.table_totals"
                    :key="column.key"
                    class="ml-4">
                    <div>
                      <h2>Measure Timeframe:</h2>
                      {{ column?.time_range?.name || "Null" }}
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <b-tooltip
            type="is-light"
            style="width: 100%"
            position="is-bottom"
            :triggers="['contextmenu']"
            size="is-small"
            multilined
            :auto-close="['outside', 'escape', 'inside']">
            <div
              class="row mini-view-item mb-2 w-full"
              :class="{ selected: currentSelectedKey === module.key }"
              @contextmenu.prevent=""
              @click.prevent.stop="
                openModule(module, isPivotGroup ? 'pivot-group' : 'measure-group', context)
              ">
              <i class="material-icons mr-2">
                {{ isPivotGroup ? "alt_route" : "folder" }}
              </i>
              <div>
                {{ module.name || "Module" }}
              </div>
            </div>

            <template #content>
              <div class="context-menu-actions">
                <button
                  class="button is-light"
                  @click="copyCode">
                  Copy
                </button>
                <button
                  class="button is-light"
                  @click="pasteOnGroup">
                  Paste
                </button>
                <button
                  class="button is-light"
                  @click="cutCode">
                  Cut
                </button>
                <button
                  class="button is-danger is-light"
                  @click="$emit('delete')">
                  Delete
                </button>
              </div>
            </template>
          </b-tooltip>
        </b-tooltip>
      </div>
      <div
        v-if="open"
        class="sub-items">
        <vuedraggable
          group="measureGroups"
          style="min-height: 6px;"
          ghost-class="ghost"
          :list="module.measure_groups">
          <div
            v-for="(subItem, i) in module.measure_groups"
            :key="subItem.key">
            <LayoutGroupItem
              :key="subItem.key"
              v-model="module.measure_groups[i]"
              :current-selected-key="currentSelectedKey"
              :context="subContext"
              @paste="pasteCode(i, 'group')"
              @delete="deleteCode(subItem, 'group')" />
          </div>
        </vuedraggable>
        <vuedraggable
          group="measures"
          style="min-height: 6px;"
          ghost-class="ghost"
          :list="module.table_measures">
          <LayoutMeasureItem
            v-for="(subItem, i) in module.table_measures"
            :key="subItem.key"
            v-model="module.table_measures[i]"
            :current-selected-key="currentSelectedKey"
            :context="subContext"
            @paste="pasteCode(i, 'measure-item')"
            @delete="deleteCode(subItem, 'measure-item')" />
        </vuedraggable>
        <div class="add-button-container">
          <button
            class="mini-view-item button is-outline mb-1"
            @click="addNewItem">
            <img
              src="@/assets/icons/add-row.svg"
              alt="" />

            <div>Add item</div>
          </button>
        </div>
      </div>
    </div>

    <b-tooltip
      type="is-light"
      position="is-bottom"
      style="width: 100%"
      :triggers="['contextmenu']"
      size="is-small"
      multilined
      :auto-close="['outside', 'escape', 'inside']">
      <div
        class="add-module-below-section" />

      <template #content>
        <div class="context-menu-actions">
          <button
            class="button is-light"
            @click="$emit('paste')">
            Paste
          </button>
        </div>
      </template>
    </b-tooltip>
    <b-modal
      v-model="modulePickerModal"
      :can-cancel="['x', 'escape']"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      close-button-aria-label="Close"
      aria-modal>
      <form action="">
        <div class="modal-card select-module-modal">
          <header class="modal-header">
            <h2>Add Object</h2>
            <button
              type="button"
              class="delete"
              @click="modulePickerModal = false" />
          </header>
          <div class="modal-body">
            <div
              v-for="item in moduleOptions"
              :key="item.type">
              <div
                class="miniview-item"
                @click="addModuleBelow(item)">
                <i class="material-icons mr-3"> {{ item.icon }} </i>

                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import vuedraggable from 'vuedraggable'
  import LayoutMeasureItem from './LayoutMeasureItem.vue'

  import {
    measureGroupObject,
    pivotGroupObject,
    measureObject
  } from '@/components/pdf_report/table_module/modules.js'

  import uniqid from 'uniqid'
  export default {
    name: 'LayoutGroupItem',
    components: {
      vuedraggable,
      LayoutMeasureItem,
    },

    inject: {
      openModule: {
        default: null,
      },
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      context: {
        type: Object,
        default: () => {},
      },
      currentSelectedKey: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        open: true,
        modulePickerModal: false,
        moduleOptions: [
          {
            name: 'Measure Group',
            type: 'MeasureGroup',
            icon: 'folder',
          },

          {
            name: 'Pivot Group',
            type: 'PivotGroup',
            icon: 'alt_route',
          },

          {
            name: 'Measure',
            type: 'Measure',
            icon: 'functions',
          },
        ],
      }
    },

    computed: {
      isPivotGroup () {
        return this.module.table_pivot
      },
      subContext () {
        if (this.isPivotGroup && this.module.table_pivot.dimension) {
          return {
            ...this.context,
            reportDimensions: [
              ...this.context.reportDimensions,
              this.module.table_pivot.dimension,
            ],
          }
        }
        return this.context
      },
      module: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    beforeCreate: function () {
      this.$options.components.LayoutGroupItem =
        require('@/components/pdf_report/table_module/table_cols/LayoutGroupItem.vue').default
    },

    mounted () {
      if (!this.module.key) {
        this.module.key = uniqid()
      }
    },

    methods: {
      addNewItem () {
        this.modulePickerModal = true
      // this.module.push({ ...tableRow, key: uniqid(), })
      },

      async pasteOnGroup () {
        const clipboard = await navigator.clipboard.readText()
        const replacedClipboard = clipboard.replace(/"key":"([^"]*)"/g, (match, key) => `"key":"${key}_1"`)

        try {
          const data = JSON.parse(replacedClipboard)
          if (data.type === 'measure-item') {
            if (!this.module.table_measures) {
              this.module.table_measures = []
            }

            this.module.table_measures.push({ ...data.code, key: uniqid(), })
          } else if (data.type === 'group') {
            if (!this.module.measure_groups) {
              this.module.measure_groups = []
            }
            this.module.measure_groups.push({ ...data.code, key: uniqid(), })
          } else {
            this.$buefy.toast.open({
              message: 'This module cannot be pasted here.',
              position: 'is-bottom',
              type: 'is-warning',
            })
          }
        } catch (e) {
          console.log(e)
          this.$buefy.toast.open({
            message: 'Error',
            position: 'is-bottom',
            type: 'is-warning',
          })
        }
      },
      async pasteCode (index, type) {
        const clipboard = await navigator.clipboard.readText()
        const replacedClipboard = clipboard.replace(/"key":"([^"]*)"/g, (match, key) => `"key":"${key}_1"`)
        try {
          const data = JSON.parse(replacedClipboard)
          if (type === 'measure-item' && data.type === 'measure-item') {
            this.module.table_measures.splice(index + 1, 0, {
              ...data.code,
              key: uniqid(),
            })
          } else if (type === 'group' && data.type === 'group') {
            this.module.measure_groups.splice(index + 1, 0, {
              ...data.code,
              key: uniqid(),
            })
          } else {
            this.$buefy.toast.open({
              message: 'This module cannot be pasted here.',
              position: 'is-bottom',
              type: 'is-warning',
            })
          }
        } catch (e) {
          console.log(e)
          this.$buefy.toast.open({
            message: 'Error',
            position: 'is-bottom',
            type: 'is-warning',
          })
        }
      },
      async copyCode () {
        const data = {
          type: 'group',
          code: this.module,
        }
        try {
          await this.$copyText(JSON.stringify(data))
          this.$buefy.toast.open({
            message: 'Copied',
            position: 'is-bottom',
            type: 'is-success',
          })
        } catch (e) {
          console.log(e)
          this.$buefy.toast.open({
            message: 'Error',
            position: 'is-bottom',
            type: 'is-warning',
          })
        }
      },

      async cutCode () {
        this.copyCode()
        this.$emit('delete')
      },

      deleteCode (code, type) {
        if (type === 'measure-item') {
          this.module.table_measures = this.module.table_measures.filter(
            (item) => item.key !== code.key
          )
        } else if (type === 'group') {
          this.module.measure_groups = this.module.measure_groups.filter(
            (item) => item.key !== code.key
          )
        }
      },

      addModuleBelow (item) {
        if (!this.module.measure_groups) {
          this.module.measure_groups = []
        }

        if (!this.module.table_measures) {
          this.module.table_measures = []
        }

        if (item.type === 'MeasureGroup') {
          this.module.measure_groups.push({
            ...measureGroupObject(),
            key: uniqid(),
          })
        } else if (item.type === 'PivotGroup') {
          this.module.measure_groups.push({
            ...pivotGroupObject(),
            key: uniqid(),
          })
        } else if (item.type === 'Measure') {
          this.module.table_measures.push({
            ...measureObject(),
            key: uniqid(),
          })
        }
        this.modulePickerModal = false
      },
      openEditor (childIndex) {
        return this.$emit('open', childIndex)
      },
    },
  }
</script>

<style lang="scss" scoped>
.data-tooltip {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  hr {
    margin: 5px 0;
    border: 0;
    border-top: 1px solid black;
  }
  h2 {
    font-size: 12px;
    font-weight: 600;
  }
}
.group-item {
  min-width: 250px;
  .add-row-button {
  }

  .row-item {
    display: flex;
    flex-direction: column;

    .toggle-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
    .button.open-button {
      display: flex;
      align-items: flex-start;
      color: $primary;
      width: 30px;
      padding: 0;
      outline: none;
      border: none;
      box-shadow: none;

      background: transparent;
      justify-content: flex-start;
    }

    .sub-items {
      width: 100%;
      border-left: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-bottom-left-radius: 20px;
      border-color: #BCDFDD;

      padding-left: 30px;
      margin-left: 10px;
      padding-top: 10px;
      margin-bottom: 24px;
      position: relative;
      padding-bottom: 20px;

      .add-button-container{
        position: absolute;
        width: 100%;
        bottom: -20px;
        left: 16px;
        background-color: #fbfbfb;
        padding: 0 14px;
        .mini-view-item {

        background-color: #F2F9F9
      }
      }

    }
  }

  button {
    display: flex;
    align-items: flex-start;
    color: $primary;
    border: 1px solid rgba(0, 140, 132, 0.1);
    background: rgba(0, 140, 132, 0.05);
    justify-content: flex-start;

    &:hover {
      color: $primary;
      border: 1px solid rgba(0, 140, 132, 0.1);
    }
  }

  .add-module-below-section {
    height: 8px;
    margin: 1px 0;
    width: 100%;
    cursor: pointer;
    background-color: $primary;
    border-radius: 5px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
}

.mini-view-item {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s;
  width: 100%;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  background-color: white;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
    0px 1px 2px 0px rgba(40, 39, 43, 0.06);

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    background-color: hsla(177, 100%, 27%, 0.1);
    transition: background-color 0.1s;
    border: $border-1;
  }
  &.selected {
    background-color: $primary;
    color: white;
    img {
      filter: brightness(0) invert(1);
    }
  }
}

.select-module-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;

  .modal-header {
    display: flex;
    h2 {
      font-size: 18px;
      width: 100%;
    }
  }

  .modal-body {
    margin-top: 30px;

    .miniview-item {
      cursor: pointer;
      margin-bottom: 10px;
      min-width: 160px;
      display: flex;
      border-radius: 4px;
      padding: 10px;
      align-items: center;
      font-size: 15px;
      background-color: white;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
        0px 1px 2px 0px rgba(40, 39, 43, 0.06);
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      margin-right: 0;

      &:hover {
        background-color: #f2f9f9;
      }
    }
  }
}

.context-menu-actions {
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 4px;
    font-size: 12px;
  }
}

</style>
