import axios from 'axios'
import { getAuthToken } from '@/service/auth/authService'

export const roleService = {
  async loadRoles (companyId) {
    return (await axios.get(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${companyId}/roles`,
      { headers: { authorization: getAuthToken(), }, }))
      .data
  },
  async loadPermissions (companyId) {
    return (await axios.get(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${companyId}/permissions`,
      { headers: { authorization: getAuthToken(), }, }))
      .data
  },
  async loadReports (companyId) {
    return (await axios.get(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${companyId}/reports`,
      { headers: { authorization: getAuthToken(), }, }))
      .data
  },
  async loadDashboardReports (companyId) {
    return (await axios.get(
      `${process.env.VUE_APP_EXPORTER_API_URL}/company/${companyId}/dashboard_reports`,
      { headers: { authorization: 'Bearer ' + getAuthToken(), }, }))
      .data
  },
  async deleteRole (company_id, role_id) {
    try {
      let response = await axios.delete(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${company_id}/role/${role_id}`,
        { headers: { authorization: getAuthToken(), }, })
      return response.data
    } catch (e) {
      if (e.response.status == 409) {
        window.alert('Cannot delete role with existing users!')
        return null
      } else {
        throw e
      }
    }
  },
  async updateRole (company_id, role) {
    let response = await axios.post(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${company_id}/role/${role.id}`,
      role,
      { headers: { authorization: getAuthToken(), }, })
    return response.data
  },
  async reassignUsers (company_id, users) {
    let response = await axios.post(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${company_id}/reassignUsers`,
      users,
      { headers: { authorization: getAuthToken(), }, })
    return response.data
  },
  async createRole (company_id, role) {
    let response = await axios.post(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${company_id}/role`,
      role,
      { headers: { authorization: getAuthToken(), }, })
    return response.data
  },
}
