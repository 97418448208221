<template>
  <div class="dimension-order-editor">
    <CompanyDimensionRefSelect v-model="dimension"
                               v-bind="subObjectBinds"/>
    <b-switch
      v-model="ascending"
      type="is-info">
      Ascending
    </b-switch>
    <ValidationErrors :errors="errors" />
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyDimensionRefSelect from '@/components/reports/config/dimensions/CompanyDimensionRefSelect'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'CompanyDimensionRefEditor',
    mixins: [
      ObjectEditorMixin,
    ],
    components: {
      CompanyDimensionRefSelect,
    },
    computed: {
      ...mapChildren({
        dimension: 'dimension',
      }),
      ascending: {
        get () {
          return this.vModel.ascending != false
        },
        set (ascending) {
          this.vModel.ascending = ascending
        },
      },
    },
  }

</script>

<style lang="scss" scoped>
.company-dimension-ref-editor {
  display: flex;
  flex-direction: column;
}
</style>

