<template>
  <div class="dimension-hierarchy">
    <b-button
      v-b-toggle="`dimension-collapse-${hierarchy.name}-${$options._scopeId}`"
      variant="None">
      <div class="toggle-button-label">
        {{ hierarchy.name }}
      </div>
      <div v-if="isOpen">
        -
      </div>
      <div v-else>
        +
      </div>
    </b-button>
    <b-collapse
      :id="`dimension-collapse-${hierarchy.name}-${$options._scopeId}`"
      v-model="isOpen"
      accordion="dimension-collapse">
      <dimension
        v-for="dimension in effectiveDimensions"
        :key="dimension.name"
        :dimension="dimension"
        :module-filters="moduleFilters"
        :dashboard-filters="dashboardFilters"
        @start="$emit('startDimension', $event)"
        @end="$emit('end')"
        @update:moduleFilters="$emit('update:moduleFilters', $event)" />
    </b-collapse>
  </div>
</template>

<script>

  import Dimension from '@/components/module/edit/Dimension'

  export default {
    name: 'DimensionHierarchy',
    components: {
      Dimension,
    },
    props: {
      hierarchy: { type: Object, required: true, },
      moduleFilters: { type: Array, required: true, },
      dashboardFilters: { type: Array, required: true, },
    },
    data () {
      return {
        isOpen: false,
      }
    },
    computed: {
      effectiveDimensions () {
        return this.hierarchy.dimensions.filter(dimension => dimension.groupable || dimension.countable)
      },
    },
  }

</script>

<style lang="scss" scoped>

button {
  display: flex;
  width: 100%;
}

.toggle-button-label {
  flex-grow: 1;
  text-align: left;

  &::first-letter {
    text-transform: uppercase;
  }
}

</style>
