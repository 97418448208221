<template>
  <div class="series-editor">
    <br />
    <label>
      name: <input v-model="seriesLabel" />
    </label>
    <label>
      id: <input v-model="seriesId" />
    </label>
    <label>
      role:
      <select v-model="seriesRole">
        <option value="hidden"> hidden</option>
        <option value="primary_dimension_key"> Primary Dimension Key</option>
        <option value="primary_dimension"> Primary Dimension</option>
        <option value="secondary_dimension_key"> Secondary Dimension Key</option>
        <option value="secondary_dimension"> Secondary Dimension</option>
        <option value="pivot_dimension_key"> Pivot Dimension Key</option>
        <option value="pivot_dimension"> Pivot Dimension</option>
        <option value="primary_measure"> Primary Measure</option>
        <option value="secondary_measure"> Secondary Measure</option>
        <option value="variance_measure"> Variance Measure</option>
        <option value="primary_pivot_total"> Primary Pivot Total</option>
        <option value="secondary_pivot_total"> Secondary Pivot Total</option>
        <option value="variance_pivot_total"> Variance Pivot Total</option>
      </select>
    </label>
    <div>
      colorSpec: <color-spec-editor :color-spec.sync="seriesColorSpec" />
    </div>
    <div>
      expression:
      <expression-editor
        :expression.sync="expression" />
    </div>
  </div>
</template>

<script>

  import ExpressionEditor from '@/components/expressions/ExpressionEditor'
  import ColorSpecEditor from '@/components/expressions/ColorSpecEditor.vue'

  export default {
    name: 'SeriesEditor',
    components: {
      ExpressionEditor,
      ColorSpecEditor,
    },
    props: {
      series: { type: Object, required: true, },
    },
    computed: {
      seriesLabel: {
        get () {
          return this.series.name
        },
        set (name) {
          this.$emit('update:series', { ...this.series,  name, })
        },
      },
      seriesId: {
        get () {
          return this.series.id
        },
        set (id) {
          this.$emit('update:series', { ...this.series, id, })
        },
      },
      seriesRole: {
        get () {
          return this.series.role
        },
        set (role) {
          this.$emit('update:series', { ...this.series, role, })
        },
      },
      seriesColorSpec: {
        get () {
          return this.series.colorSpec
        },
        set (colorSpec) {
          this.$emit('update:series', { ...this.series, colorSpec, })
        },
      },
      expression:  {
        get () {
          return this.series.expression
        },
        set (expression) {
          this.$emit('update:series', { ...this.series, expression: expression || {}, })
        },
      },
    },
  }

</script>

<style lang="scss" scoped>

.series-editor {
  padding: 2 * $gridBase;
  display: flex;
  flex-direction: column;
}

.expression-editor {
  padding-left: 4 * $gridBase;
}

.color-spec-editor {
  padding-left: 4 * $gridBase;
}

</style>

