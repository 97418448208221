const NOTIFICATION_TIME = 3000

export const notificationsModel = {
  namespaced: true,
  state () {
    return {
      notifications: [],
      lastId: 0,
    }
  },
  getters: {
    notifications (state) {
      return state.notifications
    },
  },
  mutations: {
    addNotification (state, notification) {
      state.notifications = [ ...state.notifications, { id: state.lastId, notification, }, ]
      state.lastId = state.lastId + 1
    },
    removeNotification (state, notification) {
      state.notifications = state.notifications.filter(n => n.notification != notification)
    },
  },
  actions: {
    push (context, notification) {
      context.commit('addNotification', notification)
      setTimeout(() => {
        context.commit('removeNotification', notification)
      }, NOTIFICATION_TIME)
    },
  },
}
