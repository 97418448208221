<template>
  <div class="dimension-filter-summary">
    <entity-filter-summary
      v-if="dimension.type == 'entity'"
      :custom-dimension="dimension"
      :filter="filter"
      :long="long" />
    <number-filter-summary
      v-else-if="dimension.type == 'number'"
      :custom-dimension="dimension"
      :filter="filter"
      :long="long" />
    <money-filter-summary
      v-else-if="dimension.type == 'money'"
      :custom-dimension="dimension"
      :filter="filter"
      :long="long" />
    <boolean-filter-summary
      v-else-if="dimension.type == 'boolean'"
      :custom-dimension="dimension"
      :filter="filter"
      :long="long" />
    <date-filter-summary
      v-else-if="dimension.type == 'date'"
      :custom-dimension="dimension"
      :filter="filter"
      :long="long" />
    <date-time-filter-summary
      v-else-if="dimension.type == 'datetime'"
      :custom-dimension="dimension"
      :filter="filter"
      :long="long" />
  </div>
</template>

<script>

  import NumberFilterSummary from '@/components/controls/filter/NumberFilterSummary'
  import MoneyFilterSummary from '@/components/controls/filter/MoneyFilterSummary'
  import BooleanFilterSummary from '@/components/controls/filter/BooleanFilterSummary'
  import EntityFilterSummary from '@/components/controls/filter/EntityFilterSummary'
  import DateFilterSummary from '@/components/controls/filter/DateFilterSummary'
  import DateTimeFilterSummary from '@/components/controls/filter/DateTimeFilterSummary'

  export default {
    name: 'DimensionFilterSummary',
    components: {
      NumberFilterSummary,
      MoneyFilterSummary,
      BooleanFilterSummary,
      EntityFilterSummary,
      DateFilterSummary,
      DateTimeFilterSummary,
    },
    props: {
      filter: { type: Object, default: null, },
      dimension: { type: Object, default: null, },
      long: { type: Boolean, default: false, },
    },
  }

</script>

<style lang="scss" scoped>

.dimension-filter-summary {
}

</style>
