<template>
  <div class="dashboard-filter">
    {{ dashboardFilter }}
    <b-button @click.native="loadOptions">Load Options</b-button>
    Options: {{ options }}
  </div>
</template>

<script>

  export default {
    name: 'DashboardFilter',
    props: {
      dashboardKey: {
        type: String,
        required: true,
      },
      dashboardFilter: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        options: [],
      }
    },
    methods: {
      async loadOptions () {
        this.options = await this.$apis.companyConfigs.getDashboardFilterOptions(
          this.dashboardKey,
          this.dashboardFilter.key,
          this.dashboardDataContext
        )
      },
    },
  }

</script>

<style scoped lang="scss">

.dashboard-filter {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

</style>
