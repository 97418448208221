<template>
  <div class="expression-prototyper">
    <pre>
    It's not what you look like,
    when you're doin' what you're doin'.
    It's what you're doin' when you're doin',
    what you look like you do.

    Express yo self!
    </pre>
    <div class="datepicker">
      <time-range-filter
        :default="timeRangeDefault"
        @submit="updateTimeRange" />
    </div>
    <div class="jinja-variables">
      JINJA Variables
      <div class="add-variable">
        <h2>Add variable</h2>
        <div class="is-flex">
          <b-field message="">
            <b-input
              v-model="newVariableName"
              type="" />
          </b-field>
          <b-button @click="addVariable">
            New variable
          </b-button>
        </div>
      </div>
      <label
        v-for="variable of Object.keys(jinjaVariables)"
        :key="variable">
        {{ variable }}:
        <input
          class="input"
          :value="jinjaVariables[variable]"
          @input="updateJinjaVariable(variable, $event)" />
      </label>
    </div>
    <div class="header-controls">
      <option-select
        v-for="control in optionControls"
        :key="`control-${control.id}`"
        :control="control" />
      <offset-select
        v-for="control in offsetControls"
        :key="`control-${control.id}`"
        :control="control" />
    </div>
    <div class="json-specs">
      <label>
        drilldown: <input
          v-model="drilldownJson"
          class="input" />
      </label>
      <label>
        injectedDimensions:
        <input
          v-model="injectedDimensionsJson"
          class="input" />
      </label>
      <label> controls: <input
        v-model="controlsJson"
        class="input" /> </label>
      <label> columns: <input
        v-model="columnsJson"
        class="input" /> </label>
      <label> sortBy: <input
        v-model="sortByJson"
        class="input" /> </label>
      <label>
        view type:
        <b-select v-model="selectedType">
          <option
            v-for="typeOption in viewTypes"
            :key="typeOption"
            :value="typeOption">
            {{ ingestViews[typeOption].name }}
          </option>
        </b-select>
      </label>
    </div>
    <div class="mb-5">
      <b-button @click="addSeries">
        Add series
      </b-button>
      <b-button @click="updateData">
        Update data
      </b-button>
      <b-button @click="loadQueries">
        View Queries
      </b-button>
    </div>
    <div class="main">
      <!-- <div>
        Beta Spec:
        <beta-spec-editor :beta-spec.sync="betaSpec" />
      </div> -->
      <div class="controls">
        <dimension-select-accordian :controls="controls" />
      </div>
      <div class="series-container">
        <div
          v-for="series in serieses"
          :key="series.id"
          class="series-column">
          <b-button @click="deleteSeries(series.id)">
            delete
          </b-button>
          <series-editor
            class="expression-prototyper-series-editor"
            :series="series"
            @update:series="updateSeries(series.id, $event)" />
        </div>
      </div>
    </div>
    <div v-if="!!queries">
      Queries:
      <div
        v-for="(query, idx) in queries"
        :key="idx">
        {{ query.sqla_label }}
        <b-button @click="runQuery(query)">
          run
        </b-button>
        <b-button @click="showSql(query)">
          SQL
        </b-button>
      </div>
    </div>
    <div v-if="!!sqlText">
      <pre>
      {{ sqlText }}
      </pre>
      <br />
      <b-button @click="hideSql()">
        HIDE
      </b-button>
    </div>
    <component
      :is="selectedType"
      class="visualization"
      v-bind="{
        columns: reportColumns,
        data: (results || {}).data || [],
        betaSpec,
        loading,
        error,
        sortBy: sortBy,
      }"
      name="Example Visualization"
      @update:name="nameModel = $event"
      @update:sortBy="sortBy = $event" />
  </div>
</template>

<script>
  import DimensionSelectAccordian from '@/components/controls/DimensionSelectAccordian'
  import OffsetSelect from '@/components/controls/OffsetSelect'
  import OptionSelect from '@/components/controls/OptionSelect'
  import SeriesEditor from '@/components/expressions/SeriesEditor'
  import BetaSpecEditor from '@/components/expressions/BetaSpecEditor'
  import TimeRangeFilter from '@/components/controls/filter/TimeRangeFilter.vue'

  import { ingestViews } from '@/components/module/view/viewDefs'

  import axios from 'axios'
  import { expressionProcessorMixin } from '@/components/reporting/expressionProcessorMixin'

  export default {
    name: 'ExpressionPrototyper',
    components: {
      BetaSpecEditor,
      DimensionSelectAccordian,
      OffsetSelect,
      OptionSelect,
      SeriesEditor,
      TimeRangeFilter,
    },
    mixins: [expressionProcessorMixin,],
    data () {
      return {
        newVariableName: '',
        jinjaVariables: {},
        betaSpec: {},
        queries: [],
        loading: false,
        error: false,
        serieses: [],
        results: { series: [], data: [], },
        controls: [],
        drilldown: {},
        injectedDimensionsData: [],
        selectedType: 'table-view',
        sortBy: [],
        sqlText: null,

        timeRangeDefault: {
          mode: 'calendar',
          calendarPreset: 'yesterday',
        },
      }
    },
    computed: {
      injectedDimensions: {
        get () {
          return this.injectedDimensionsData
        },
        set (injectedDimensions) {
          this.injectedDimensionsData = injectedDimensions
        },
      },
      drilldownFilters () {
        return []
      },
      reportColumns () {
        return (this.results.series || []).map((s) => {
          return {
            ...this.serieses.filter((s2) => s2.id === s.id).shift(),
            ...s,
          }
        })
      },
      ingestViews () {
        return ingestViews
      },
      viewTypes () {
        return Object.keys(ingestViews)
      },
      nextSeriesId () {
        return Math.max(...this.serieses.map((s) => s.id), -1) + 1
      },
      optionControls () {
        return this.controls.filter((c) => c.type === 'option-select')
      },
      offsetControls () {
        return this.controls.filter((c) => c.type === 'date-offset-select')
      },
      sortByJson: {
        get () {
          return JSON.stringify(this.sortBy)
        },
        set (sortByJson) {
          this.sortBy = JSON.parse(sortByJson)
        },
      },
      columnsJson: {
        get () {
          return JSON.stringify(this.serieses)
        },
        set (columnsJson) {
          this.serieses = JSON.parse(columnsJson)
        },
      },
      injectedDimensionsJson: {
        get () {
          return JSON.stringify(this.injectedDimensions)
        },
        set (injectedDimensionsJson) {
          this.injectedDimensions = JSON.parse(injectedDimensionsJson)
        },
      },
      drilldownJson: {
        get () {
          return JSON.stringify(this.drilldown)
        },
        set (drilldownJson) {
          this.drilldown = JSON.parse(drilldownJson)
        },
      },
      drilldownTargetOptions () {
        return this.drilldown.options
      },
      controlsJson: {
        get () {
          return JSON.stringify(this.controls)
        },
        set (controlsJson) {
          this.controls = JSON.parse(controlsJson)
        },
      },
    },
    beforeDestroy () {
      window.removeEventListener('keydown', this.shortcutHandler)
    },
    mounted () {
      this.$store.dispatch('expressions/initialize')
      window.addEventListener('keydown', this.shortcutHandler)
    },
    methods: {
      shortcutHandler (e) {
        if (e.ctrlKey && e.key === 'n') {
          this.addSeries()
        } else if (e.ctrlKey && e.key === 'u') {
          this.updateData()
        } else if (e.ctrlKey && e.key === 'q') {
          this.loadQueries()
        }
      },

      updateTimeRange (data) {
        if (data.mode === 'RANGE') {
          this.$store.dispatch('datePicker/selectRangeStart', data.dates[0])
          this.$store.dispatch('datePicker/selectRangeEnd', data.dates[1])
        } else {
          this.$store.dispatch('datePicker/selectDates', data.dates)
        }
        this.$store.dispatch('datePicker/apply')
      },
      showSql (query) {
        this.sqlText = query.query_sql
      },
      hideSql () {
        this.sqlText = null
      },
      updateJinjaVariable (variable, event) {
        this.jinjaVariables[variable] = event.target.value
      },
      addVariable () {
        if (this.newVariableName) {
          this.jinjaVariables[this.newVariableName] = ''
          this.newVariableName = ''
        }
      },
      applyJSONSubstitutions (string) {
        return string.replace(/{{\s*[a-z,A-Z,0-9,_]+\s*}}/g, (match) => {
          let noMustache = match.substring(2, match.length - 2).trim()
          if (Object.keys(this.jinjaVariables).includes(noMustache)) {
            return this.jinjaVariables[noMustache]
          } else {
            throw `Unknown jinja variable: ${noMustache}`
          }
        })
      },
      async updateData () {
        this.loading = true
        this.error = false
        try {
          this.results = (
            await axios.post(
              `${process.env.VUE_APP_API_URL}/company/${this.$route.params.company_id}/report`,
              JSON.parse(
                this.applyJSONSubstitutions(
                  JSON.stringify({
                    dashboard_id: 'expression_prototyper',
                    sort_by: this.sortBy,
                    series: this.serieses.map((s) => ({
                      ...s,
                      expression: this.preprocessExpression(s.expression),
                    })),
                  })
                )
              ),
              {
                headers: { authorization: this.$store.getters['auth/authToken'], },
              }
            )
          ).data
        } catch (e) {
          console.log(e)
          this.error = true
        }
        this.loading = false
      },
      async loadQueries () {
        this.loading = true
        this.error = false
        try {
          this.queries = (
            await axios.post(
              `${process.env.VUE_APP_API_URL}/company/${this.$route.params.company_id}/queries`,
              JSON.parse(
                this.applyJSONSubstitutions(
                  JSON.stringify({
                    dashboard_id: 'expression_prototyper',
                    sort_by: this.sortBy,
                    series: this.serieses.map((s) => ({
                      ...s,
                      expression: this.preprocessExpression(s.expression),
                    })),
                  })
                )
              ),
              {
                headers: { authorization: this.$store.getters['auth/authToken'], },
              }
            )
          ).data
        } catch (e) {
          console.log(e)
          this.error = true
        }
        this.loading = false
      },
      async runQuery (query) {
        this.loading = true
        this.error = false
        try {
          await axios.post(
            `${process.env.VUE_APP_API_URL}/company/${this.$route.params.company_id}/query`,
            query,
            { headers: { authorization: this.$store.getters['auth/authToken'], }, }
          )
        } catch (e) {
          console.log(e)
          this.error = true
        }
        this.loading = false
      },
      addSeries () {
        this.serieses.push({
          expression: {},
          id: this.nextSeriesId,
          colorSpec: {},
        })
      },
      updateSeries (id, newSeries) {
        this.serieses.splice(
          this.serieses.findIndex((s) => s.id == id),
          1,
          { ...newSeries, }
        )
      },
      deleteSeries (id) {
        this.serieses = [...this.serieses.filter((s) => s.id != id),]
      },
    },
  }
</script>

<style lang="scss" scoped>
.expression-prototyper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 20px;
}

.controls {
  max-width: 256px;
}

.calendar-filter {
  flex-grow: 0;
}

.b-button {
  border: 1px solid $ui-01;
}

.series-container {
  display: flex;
  overflow: auto;
}

.series-column {
  display: flex;
  flex-direction: column;
}

.series-editor {
  height: 600px;
  overflow: auto;
  margin-bottom: 10px;
}

.main {
  display: flex;
  flex-grow: 1;
}

.json-specs {
  display: flex;
  margin: 20px 0;
}

.visualization {
  min-height: 512px;
}

.jinja-variables {
  display: flex;
  flex-direction: column;
}

.add-variable {
  // display: flex;
  // flex-direction: row;
}

.beta-spec-editor {
  padding: 4 * $gridBase;
}

.datepicker {
  width: 400px;
  margin: 30px 0;
}
.controls {
  min-height: 500px;
}
</style>

<style lang="scss">
.expression-prototyper-series-editor {
  *, button {
    font-size: 12px !important
  }
  .sub-expression {
    padding: 4px !important;
  }
  .input-item {

  }
}
</style>
