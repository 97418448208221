<template>
  <div
    class="accordian"
    :class="{ isExpanded }"
    :style="{'flex-basis': `${toggleHeight}px`}"
    @focusin="isExpanded = true">
    <div
      class="toggle"
      @click="isExpanded = !isExpanded">
      <slot
        name="button"
        v-bind="{ isExpanded }" />
      <resize-sensor @resize="onResize" />
    </div>
    <div
      class="slide-container"
      :class="{ isExpanded }">
      <slot
        name="body" />
    </div>
  </div>
</template>
<script>

  export default {
    name: 'AccordianSection',
    props: {
      value: { type: String, default: null, },
      startOpen: { type: Boolean, default: false, },
    },
    data () {
      return {
        toggleHeight: 0,
      }
    },
    computed: {
      isExpanded: {
        get () {
          return this.uid == this.value
        },
        set (val) {
          if (val) {
            this.$emit('input', this.uid)
          } else if (this.isExpanded) {
            this.$emit('input', null)
          }
        },
      },
    },
    mounted () {
      if (this.startOpen) {
        this.isExpanded = true
      }
    },
    methods: {
      onResize (rect) {
        this.toggleHeight = rect.height
      },
    },
  }

</script>

<style lang="scss" scoped>
.accordian {
  display: flex;
  flex-direction: column;
  flex-grow: .0001;
  transition: all .5s;

  &.isExpanded {
    flex-grow: 1;
  }
  height: 0;
  min-height: 0;
  width: 100%;
}

.toggle {
  cursor: pointer;
  margin: 2*$gridBase 0;
}

.slide-container {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

</style>

