import { render, staticRenderFns } from "./TrackerTotalHeaders.vue?vue&type=template&id=1f08e7c8&scoped=true"
import script from "./TrackerTotalHeaders.vue?vue&type=script&lang=js"
export * from "./TrackerTotalHeaders.vue?vue&type=script&lang=js"
import style0 from "./TrackerTotalHeaders.vue?vue&type=style&index=0&id=1f08e7c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f08e7c8",
  null
  
)

export default component.exports