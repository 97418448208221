<template>
  <div class="dimension-in-condition-editor">
    <div v-if="dimensionInvalid">
      Please select a valid dimension!
    </div>
    <div class="ref-select">
      <MultiSelect
        v-model="keyValues"
        :loading="loadingValues"
        :disabled="dimensionInvalid"
        :options="dimensionValues || []"
        label="value"
        track-by="key"
        class="input-item" />
    </div>
    <ValidationErrors :errors="keyValuesErrors" />
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import MultiSelect from '@/components/ui/multiselect'
  import { childErrors } from '@/components/reports/config/common/validation'

  export default {
    name: 'DimensionInConditionEditor',
    components: {
      MultiSelect,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    props: {
      context: {
        type: Object,
        default: Object,
      },
    },
    computed: {
      dimensionValues () {
        return this.context.dimensionValues || []
      },
      dimensionValuesByKey () {
        const map = {}
        this.dimensionValues.forEach(dv => {
          map[dv.key] = dv
        })
        return map
      },
      loadingValues () {
        return this.context.dimensionLoading || false
      },
      keyValues: {
        get () {
          return (this.vModel.key_values.map(
            k => this.dimensionValuesByKey[k]
          ) || [])
        },
        set (keyValues) {
          this.vModel = {
            ...this.vModel,
            key_values: keyValues.map(v => v.key),
          }
        },
      },
      keyValuesErrors () {
        return childErrors('key_values', this.errors)
      },
      dimensionInvalid () {
        return !this.dimensionValues && !this.loading
      },
    },
  }

</script>

<style lang="scss" scoped>
.dimension-in-condition-editor {
  display: flex;
  flex-direction: column;
}
</style>
