<template>
  <div class="other-filter">
    <div class="quick-actions">
      <div class="button-label">
        {{ filterMeta?.name }}
      </div>

      <b-button
        class="quick-action-button"
        type="is-text"
        :disabled="loading"
        @click="filterChange(dropdownOptions)">
        All
      </b-button>
      <b-button
        class="quick-action-button"
        type="is-text"
        :disabled="loading"
        @click="filterChange([])">
        None
      </b-button>

      <!-- <button class="button remove-button">
        <i class="material-icons icon">close</i>
      </button> -->
    </div>
    <div class="dropdown-filter">
      <filter-multiselect
        v-model="selectionModel"
        track-by="key"
        label="label"
        :disabled="loading"
        :loading="loading"
        :options="dropdownOptions"
        @input="filterChange" />
    </div>
  </div>
</template>

<script>
  import filterMultiselect from '@/components/ui/multiselect'

  export default {
    name: 'CustomFilter',
    components: {
      filterMultiselect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    watch: {},
    async mounted () {
      this.dropdownOptions =
        await this.$apis.companyConfigs.getDashboardFilterOptions(
          this.dashboardKey,
          this.filterMeta.key,
          this.dataContext
        )

      this.selectionModel = this.dropdownOptions
    },

    methods: {
      filterChange (values) {
        this.selectionModel = values
        this.$set(this.dataContext.filter_selections,  this.filterMeta.key, values.map((item) => item.key))
      },
    },
  }
</script>
<style lang="scss" scoped>
.other-filter {
  background-color: white;
  border: 1px solid $gray-1;
  border-radius: 4px;
  width: 100%;
}
.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  border-radius: 4px !important;
}

.quick-actions {
  display: flex;
  align-items: center;
  // border: 1px solid $gray-1;
  // border-bottom: none;
  border-top: none;
  background-color: #fbfbfb;
  height: 30px;
  overflow: hidden;

  .button-label {
    font-size: 13px;
    margin-right: auto;
    color: #504f54;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $gray-1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quick-action-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
  }

  .remove-button {
    border: none;
    color: #504f54;
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border-left: 1px solid $gray-1;

    outline: none;

    &:focus {
      outline: none;
    }

    .icon {
      font-size: 17px;
    }
  }
}
</style>
