import { budgetsApi } from '@/vuex/api/budgets/budgetsApi'

export const apiStore = {
  namespaced: true,
  state () {
    return {
    }
  },
  getters: {
    // TODO: migrate auth code out of root store and into api path.
    //
    // Alternatively, could the auth module be a static const module that gets
    // added to each sub store of the api store?
    authToken (state, rootState, getters, rootGetters) {
      return rootGetters['auth/authToken']
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    budgets: budgetsApi,
  },
}
