<template>
  <CompanyObjectRefSelect
    v-model="vModel"
    :options="companyFilterOptions"
    :mode="mode"
    object-type="CompanyFilterRef"
    placeholder="Select a Filter..."
    external-route="reports_config"
    :external-query="{
      tab: 'companyFilter',
    }" />
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyObjectRefSelect from '@/components/reports/config/common/CompanyObjectRefSelect'

  export default {
    name: 'CompanyFilterRefSelect',
    components: {
      CompanyObjectRefSelect,
    },
    mixins: [
      ObjectEditorMixin,
    ],
  }
</script>

<style lang="scss" scoped>

</style>
