<template>
  <div class="filter restaurant-filter">
    <b-dropdown
      ref="main-dropdown"
      aria-role="menu"
      min-height="auto"
      @active-change="openMultiselect">
      <template #trigger="{ active }">
        <div
          class="filter-button"
          role="button">
          <b-icon
            class="icon"
            icon="map-marker-outline" />
          <div class="button-content">
            <div class="button-label">
              Restaurant
            </div>

            <div class="button-value">
              {{ selectionModel ? selectionModel.value : "None Selected" }}
            </div>
          </div>
          <b-icon
            class="icon right"
            :icon="active ? 'menu-up' : 'menu-down'" />
        </div>
      </template>

      <b-dropdown-item
        aria-role="menu-item"
        custom
        paddingless>
        <form>
          <div class="dropdown-content">
            <section class="">
              <multiselect
                id="key"
                ref="dropdown"
                v-model="selectionModel"
                track-by="key"
                :options="options"
                tag-placeholder=""
                select-label=""
                selected-label=""
                deselect-label=""
                deselect-group-label=""
                :taggable="false"
                :multiple="false"
                :allow-empty="false"
                :searchable="true"
                :options-limit="300"
                :max-height="500"
                placeholder="Search"
                label="value"
                @close="closeDropdown">
                <template slot="caret">
                  <span />
                </template>
                <template slot="tag">
                  <div class="dropdown-tag" />
                </template>
                <template
                  slot="option"
                  slot-scope="props">
                  <div class="dropdown-option">
                    <div class="image-container">
                      <img
                        v-if="props.option.brand_logo"
                        class="option-image"
                        :src="props.option.brand_logo_full"
                        alt="Restaurant logo" />

                      <img
                        v-else
                        class="option-image"
                        src="@/assets/images/ingest_logo.svg"
                        alt="Restaurant logo" />
                    </div>
                    <div class="option-value">
                      {{ props.option.value }}
                    </div>
                  </div>
                </template>
              </multiselect>
            </section>
          </div>
        </form>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import { debounce, uniq, uniqBy } from 'lodash'
  import axios from 'axios'
  import Vue from 'vue'
  import { getReport } from '@/service/data/reportService'
  import { expressionProcessorMixin } from '@/components/reporting/expressionProcessorMixin'

  export default {
    name: 'RestaurantFilter',
    components: {},
    mixins: [expressionProcessorMixin,],
    props: {
      control: { type: Object, required: true, },
      allControls: { type: Array, required: true, },
      useBigquery: { type: Boolean, default: false, },
    },
    data () {
      return {
        unwatch: () => {},
        columns: [],
        options: [],
        internalSelection: null,
        controlIsSearch: false,
        controlSearchTerm: '',
        requestCanceler: null,
        isInitialized: false,
      }
    },
    computed: {
      refreshOptions () {
        return debounce(this.trueRefreshOptions, 300)
      },
      selection: {
        get () {
          return this.allSelections[this.control.id]
        },
        set (newSelection) {
          let newSelections = { ...this.allSelections, }
          newSelections[this.control.id] = newSelection
          this.allSelections = newSelections
        },
      },
      allSelections: {
        get () {
          return this.$store.getters['controls/controlSelections']
        },
        set (selections) {
          this.$store.dispatch('controls/setSelections', selections)
        },
      },
      loading: {
        get () {
          return !!this.$store.getters['controls/loadingControls'][
            this.control.id
          ]
        },
        set (loading) {
          this.$store.dispatch(
            loading ? 'controls/startLoading' : 'controls/endLoading',
            this.control.id
          )
        },
      },
      allowMulti () {
        return !!this.control.spec.allowMulti
      },
      dateSelection () {
        return this.$store.getters['datePicker/appliedEffectiveDates']
      },
      offsetSelections () {
        return this.$store.getters['controls/offsetDates']
      },
      dateFilters () {
        let dates = this.dateSelection.map((d) => d.date_key)
        if (!dates) {
          return null
        }
        for (let control_dates of Object.values(this.offsetSelections)) {
          if (!control_dates) {
            return null
          }
          dates = [...dates, ...control_dates,]
        }
        dates = uniq(dates)
        return [
          {
            type: 'in',
            left: {
              type: 'field',
              field: {
                key: 'date',
              },
            },
            right: {
              type: 'const',
              data_type: { key: 'list', item_type: { key: 'date', }, },
              value: dates,
            },
          },
        ]
      },
      hasExclusion () {
        return (
          this.control.spec.controls.filter(
            (control) => control.type == 'dimension-exclude'
          ).length > 0
        )
      },
      exclusionControl () {
        if (this.hasExclusion) {
          return this.control.spec.controls.filter(
            (control) => control.type == 'dimension-exclude'
          )[0]
        }
        return null
      },
      excluded: {
        get () {
          if (this.allSelections[this.exclusionControl.id]) {
            return uniqBy(
              this.filteredOptions.filter((o) =>
                this.allSelections[this.exclusionControl.id]
                  .map((s) => s?.key)
                  .includes(o?.key)
              ),
              (s) => s?.key
            )
          }
          return []
        },
        set (values) {
          let newSelects = {
            ...this.allSelections,
          }
          newSelects[this.exclusionControl.id] = values
          this.allSelections = newSelects
        },
      },
      indexedColumns () {
        return []
          .concat(
            this.control.spec.columns,
            this.control.spec.controls
              .filter((c) => c.type === 'dimension-select-filter')
              .map((control) => ({
                ...this.allControls
                  .filter((c) => control.id == c.id)
                  .slice(-1)
                  .shift()
                  .spec.columns.filter((c) => c.role == 'option_key')
                  .slice(-1)
                  .shift(),
                role: `control-${control.id}`,
                expression:
                  control.key_expression ||
                  this.allControls
                    .filter((c) => control.id == c.id)
                    .slice(-1)
                    .shift()
                    .control.spec.columns.filter((c) => c.role == 'option_key')
                    .slice(-1)
                    .shift().expression,
              }))
          )
          .map((s) => ({
            ...s,
            expression: this.preprocessExpression({
              type: 'filter',
              expression: s.expression,
              by: this.dateFilters,
            }),
          }))
          .map((s, idx) => ({ ...s, id: idx, }))
      },
      reportSpec () {
        return {
          use_bq: this.useBigquery,
          dashboard_id: this.$store.getters['dashboards/selectedDashboardId'],
          control_id: this.control.id,
          sort_by: this.control.spec.sort_by,
          series: this.indexedColumns,
        }
      },
      dimensionKeyColumns () {
        return this.columns.filter((c) => c.role === 'option_key')
      },
      dimensionColumns () {
        return this.columns.filter((c) => c.role === 'option_label')
      },
      internalKeys () {
        return (this.internalSelection || []).map((s) => s.key)
      },
      selectionModel: {
        get () {
          if (this.selection && this.selection.length) {
            return this.selection[0]
          }

          if (this.options) {
            return this.options[0]
          }

          return null
        },
        set (values) {
          if (!values) {
            return
          }
          if (this.allowMulti) {
            let availableKeys = this.enabledOptions.map((o) => o?.key)
            this.internalSelection = [
              ...values,
              ...(this.internalSelection || []).filter(
                (v) => !availableKeys.includes(v?.key)
              ),
            ]
            this.selection = values
          } else {
            let newVals = [values,].filter(
              (v) => !this.internalKeys.includes(v?.key)
            )
            if (newVals.length > 0) {
              this.internalSelection = [values,]
              this.selection = [values,]
            }
          }
        },
      },
      filteredOptions () {
        var options = this.options
        this.control.spec.controls
          .filter((control) => control.type === 'dimension-select-filter')
          .map((c) =>
            this.allControls
              .filter((a) => a.id == c.id)
              .slice(-1)
              .shift()
          )
          .forEach((control) => {
            if (this.$store.getters['controls/loadingControls'][control.id]) {
              options = []
            } else {
              var selection = new Set(
                (this.allSelections[control.id] || []).map((s) => s.key)
              )
              var column = this.columns.filter(
                (col) => col.role == `control-${control.id}`
              )
              options = options.filter((o) =>
                selection.has(this.getDimensionVal(column, o))
              )
            }
          })

        return uniqBy(options, (o) => o.key)
      },
      enabledOptions () {
        return this.filteredOptions.filter((o) => !o.disabled)
      },
      searchedOptions () {
        return uniqBy(
          this.filteredOptions.filter((option) =>
            `${option.value}`
              .toLowerCase()
              .includes(this.controlSearchTerm.toLowerCase())
          ),
          (o) => o.key
        )
      },
      enabledSearchedOptions () {
        return this.searchedOptions.filter((o) => !o.disabled)
      },
    },
    watch: {
      control () {
        this.registerSpec()
      },
      allSelections (selections, oldSelections) {
        let needsUpdate = false
        Object.keys(selections).forEach((key) => {
          if (
            (this.control.spec.controls || []).filter((c) => c.id == key).length >
            0
          ) {
            if (oldSelections[key] != selections[key]) {
              needsUpdate = true
            }
          }
        })
        if (needsUpdate) {
          this.$nextTick(() => {
            this.selectAfterChange()
          })
        }
      },
      otherControls () {
        return this.$store.controlSelections
      },
      dateSelection (filters) {
        if (filters) {
          this.loading = true
          this.refreshOptions()
        }
      },
      offsetSelections (filters, oldFilters) {
        if (filters && JSON.stringify(filters) != JSON.stringify(oldFilters)) {
          this.loading = true
          this.refreshOptions()
        }
      },
      spec (spec) {
        if (spec && !!this.dateFilters) {
          this.loading = true
          this.refreshOptions()
        }
      },
    },
    mounted () {
      this.registerSpec()
      this.unwatch = this.$store.watch(
        (_, getters) => getters['expressions/initialized'],
        () => {
          this.loading = true
          this.refreshOptions(true)
        }
      )
      this.selection = []
      this.loading = true
      this.refreshOptions(true)
    },
    destroyed () {
      this.unregisterSpec()
      this.unwatch()
    },

    methods: {
      openMultiselect () {
        this.$refs.dropdown.isOpen = true
        this.$refs.dropdown.$el.focus()
      },

      closeDropdown () {
        this.$refs['main-dropdown'].toggle()
      },
      registerSpec () {
        let newSpecs = { ...this.$store.getters['controls/controlSpecs'], }
        newSpecs[this.control.id] = this.control
        this.$store.commit('controls/setSpecs', newSpecs)
      },
      unregisterSpec () {
        let newSpecs = { ...this.$store.getters['controls/controlSpecs'], }
        delete newSpecs[this.control.id]
        this.$store.commit('controls/setSpecs', newSpecs)
      },
      onDimensionSearch () {
        this.controlIsSearch = !this.controlIsSearch
        Vue.nextTick(() => {
          if (this.$refs.controlSearch) {
            this.$refs.controlSearch.focus()
          }
        })
      },
      selectAll () {
        this.selectionModel = this.enabledSearchedOptions
      },
      deselectAll () {
        this.selectionModel = []
      },
      isExcluded (item) {
        return (
          this.hasExclusion &&
          this.excluded.filter((i) => i?.key === item.key).length > 0
        )
      },
      processReport (rep) {
        this.columns = Object.freeze(
          rep.series.map((s) => ({
            ...this.indexedColumns
              .filter((c) => c.id === s.id)
              .slice(0, 1)
              .shift(),
            ...s,
          }))
        )

        this.options = Object.freeze(
          rep.data
            .map((o) => ({
              ...o,
              key: this.getDimensionVal(this.dimensionKeyColumns, o),
              value: this.getDimensionVal(this.dimensionColumns, o),
              disabled: this.columns.filter((c) => c.role === 'disabler').shift()
                ? !(
                  o[
                    this.columns.filter((c) => c.role === 'disabler').shift()?.key
                  ] === false
                )
                : false,
              brand_logo: this.columns
                .filter((c) => c.role == 'brand_logo')
                .map((c) => o[c?.key])
                .slice(-1)
                .shift(),
              brand_logo_full: `${process.env.VUE_APP_S3_BASE_URL}/${this.columns
                .filter((c) => c.role == 'brand_logo')
                .map((c) => o[c?.key])
                .slice(-1)
                .shift()}`,
            }))
            .filter((item) => !!item.value)
        )

        if (!this.isInitialized) {
          this.selectionModel = this.options[0]
          this.isInitialized = true
        }

        this.$nextTick(() => {
          this.loading = false
        })

        this.selectAfterChange()
      },
      async trueRefreshOptions () {
        this.loading = true
        if (!this.dateFilters) {
          return
        }
        if (!this.$store.getters['expressions/initialized']) {
          return
        }
        let spec = this.reportSpec
        let companyId = this.$route.params.company_id
        try {
          if (this.requestCanceler) {
            this.requestCanceler.cancel('Canceled stale report')
          }
          this.requestCanceler = axios.CancelToken.source()

          //load new value
          const newData = await getReport(
            spec,
            companyId,
            this.requestCanceler.token
          )

          this.processReport(newData)

        } catch (e) {
          if (!!e.message && e.message != 'Canceled stale report') {
            throw e
          }
        }
      },
      getDimensionVal (dimensions, datum) {
        return dimensions.map((d) => datum[d?.key]).join('–')
      },
      selectAfterChange () {
        if (this.allowMulti) {
          if (this.internalSelection == null) {
            this.selection = this.enabledOptions
          } else {
            this.selection = this.enabledOptions.filter((o) =>
              this.internalKeys.includes(o?.key)
            )
          }
        } else {
          let candidates = [
            ...this.enabledOptions.filter((o) =>
              this.internalKeys.includes(o?.key)
            ),
            ...this.enabledOptions,
          ].filter((o) => !o.disabled)
          if (candidates.length > 0) {
            let selected = candidates[0]
            this.selection = [selected,]
            this.internalSelection = [selected,]
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  height: 100%;

  .icon {
    color: $gray-3;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 33px;
    align-items: center;

    .button-label {
      font-size: 10px;
      color: $gray-4;
      text-transform: capitalize;
      margin-bottom: 2px;
    }

    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  width: 350px !important;
}
</style>

<style lang="scss">
.restaurant-filter {
  .dropdown {
    display: flex;
    width: 100%;
  }

  .multiselect__input {
    height: 40px;
  }

  .dropdown-option,
  .dropdown-tag {
    display: flex;
    align-items: center;
    padding-left: 10px;

    .image-container {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .option-image {
      max-height: 30px;
      max-width: 30px;
      width: 100%;
      height: auto;
    }

    .option-value {
      margin-left: 15px;
      font-size: 15px;
    }
  }

  .dropdown-tag {
    .image-container {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
