var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary"},[_c('div',{staticClass:"filters"},_vm._l((_vm.dashboardSection.controls),function(filter){return _c('div',{key:filter.key,staticClass:"filter"},[_c('DashboardControlFilters',{attrs:{"filter-meta":filter},model:{value:(_vm.sectionDataContext),callback:function ($$v) {_vm.sectionDataContext=$$v},expression:"sectionDataContext"}})],1)}),0),_vm._l((_vm.dashboardSection?.sub_sections),function(section){return _c('section',{key:section.id},[_c('h2',[_vm._v(_vm._s(section.name))]),_c('div',{staticClass:"metrics"},[_c('div',{staticClass:"columns is-multiline"},[_vm._l((section?.modules),function(mod){return [_c('div',{key:mod?.id,class:[
              'column',
              {
                // 'is-full': true,
                'is-full': ['TableModuleConfig', 'CategoryLineChart', 'PerformanceBarChart'].includes(mod.type),
                'is-4': mod.type === 'KpiModuleConfig',
              },
            ]},[(mod.type === 'Kpi')?_c('kpiModule',{attrs:{"dashboard-module":mod,"dashboard-data-context":_vm.mergedDataContext,"dashboard-key":_vm.dashboardKey}}):_vm._e(),(mod.type === 'TableModuleConfig')?_c('tableModule',{attrs:{"dashboard-module":mod,"dashboard-data-context":_vm.mergedDataContext,"dashboard-key":_vm.dashboardKey}}):_vm._e(),(['CategoryLineChart', 'PerformanceBarChart'].includes(mod.type))?_c('graphModule',{attrs:{"dashboard-module":mod,"dashboard-data-context":_vm.mergedDataContext,"dashboard-key":_vm.dashboardKey,"type":mod.type === 'PerformanceBarChart' ? 'bar' : 'line'}}):_vm._e()],1)]})],2)])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }