<template>
  <div class="sub-section">
    <b-field class="input-field">
      <b-switch
        v-model="inputs.show_header"
        class="mt-4"
        type="is-info">
        Show Header
      </b-switch>
    </b-field>
  </div>
</template>

<script>
  export default {
    name: 'TrackerTableStyle',
    components: {},
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {}
    },
    computed: {
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
    mounted () {},

    beforeDestroy () {},

    methods: {},
  }
</script>

<style lang="scss" scoped></style>
