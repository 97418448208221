<template>
  <div class="container pt-6">
    <div class="columns">
      <div class="mr-6">
        <time-range-filter
          :default="timeRangeFilterDefaultForCalendar"
          @submit="onTimeRangeFilterSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
  import TimeRangeFilter from '@/components/controls/filter/TimeRangeFilter.vue'

  export default {
    name: 'ComponentLibrary',
    components: {
      TimeRangeFilter,
    },
    data () {
      return {
        timeRangeFilterDefaultForCalendar: {
          calendarPreset: 'yesterday',
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: #F5F5F5;
  width: 100%;
  min-height: 1000px;

  h2 {
    font-size: 17px;
  }

}
</style>
