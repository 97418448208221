import moment from 'moment'
import numbro from 'numbro'

export function formatDate (dateTime, format = 'll') {
  if (moment(dateTime).isValid()) {
    return moment(dateTime).format(format)
  }
  return 'None'
}

export function formatDateRange (start, end, format = 'YYYY-MM-DD') {
  if (moment(start).isValid() && moment(end).isValid()) {
    if (moment(start).isSame(moment(end))) {
      return moment(start).format(format)
    } else {
      return `${moment(start).format(format)} to ${moment(end).format(format)}`
    }
  }
  return 'None'
}

export function getWeekDay (dateTime) {
  if (moment(dateTime).isValid()) {
    return moment(dateTime).format('dddd')
  }
  return 'None'
}

export function formatTimeFromDateTime (dateTime) {
  if (moment(dateTime).isValid()) {
    return moment(dateTime).format('LT')
  } else {
    return 'None'
  }
}

export function formatTime (time) {
  const t = moment(time, 'hh:mm:ss')
  if (t.isValid()) {
    return t.format('hh:mm A')
  }
  return 'None'
}

export function formatDateTime (dateTime) {
  if (moment(dateTime).isValid()) {
    return moment(dateTime).format('lll')
  }
  return 'None'
}

export function formatDifference (dateTime1, dateTime2) {
  return moment.duration(moment(dateTime1).diff(moment(dateTime2))).humanize()
}

export function formatHours (hours) {
  if (hours === '' || isNaN(hours)) {
    return ''
  }
  let absoluteTime = numbro(3600 * Math.abs(hours)).format({ output: 'time', })
  return `${hours < 0 ? '-' : ''}${absoluteTime}`
}

export function extractDate (date) {
  return `${('0000' + date.getFullYear()).slice(-4)}-${('00' + (date.getMonth() + 1)).slice(-2)}-${('00' + date.getDate()).slice(-2)}`
}

export function parseDate (date) {
  let split = date.split('-')
  let year = parseInt(split[0])
  let month = parseInt(split[1]) - 1
  let day = parseInt(split[2])
  return new Date(year, month, day)
}

