<template>
  <div class="column-description">
    <div class="header">
      {{ column.column_header }}
    </div>
    <template v-if="column.type == 'entity'">
      <div class="description-label">
        Potential Values:
      </div>
      <div class="examples">
        <div
          v-for="(example, idx) in column.examples"
          :key="idx"
          class="example-value">
          {{ example }}
        </div>
      </div>
    </template>
    <template v-if="column.type == 'hours'">
      <div class="description-label">
        Required Format:
      </div>
      <div class="examples">
        <div class="example-value">
          HHH.hhh
        </div>
        <div class="example-value">
          HHH:MM:SS.sss
        </div>
      </div>
    </template>
    <template v-if="column.type == 'date'">
      <div class="description-label">
        Required Format:
      </div>
      <div class="examples">
        <div class="example-value">
          YYYY-MM-DD
        </div>
        <div class="example-value">
          YYYY/MM/DD
        </div>
        <div class="example-value">
          MM-DD-YYYY
        </div>
        <div class="example-value">
          MM/DD/YYYY
        </div>
      </div>
    </template>
    <template v-if="column.type == 'number'">
      <div class="description-label">
        Required Format:
      </div>
      <div class="examples">
        <div class="example-value">
          1234
        </div>
        <div class="example-details">
          No commas <br />
          No decimals
        </div>
      </div>
    </template>
    <template v-if="column.type == 'money'">
      <div class="description-label">
        Required Format:
      </div>
      <div class="examples">
        <div class="example-value">
          1234.00
        </div>
        <div class="example-details">
          No currency symbol <br />
          No commas <br />
          Cents included
        </div>
      </div>
    </template>
  </div>
</template>

<script>

  export default {
    name: 'ColumnDescription',
    props: {
      column: { type: Object, required: true, },
    },
  }

</script>

<style lang="scss" scoped>

.column-description {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
}

.header {
  padding: 0 2 * $gridBase;
  padding-bottom: 2 * $gridBase;
  margin-bottom: 3 * $gridBase;
  border-bottom: 1px solid $ui-07;
  font: $h2-dw-sl;
  color: $ui-02;
}

.description-label {
  padding: 0 2 * $gridBase;
  margin-bottom: 5 * $gridBase;
  color: $ui-04;
  font: $h4-lw-sl;
  white-space: nowrap;
}

.example-value {
  padding: 0 2 * $gridBase;
  margin-bottom: 4 * $gridBase;
  color: $ui-03;
  font: $h3-lw-sl;
  white-space: nowrap;
}

.example-details {
  color: $ui-04;
  font: $h4-lw-ns;
  white-space: nowrap;
}

.examples {
  padding: 3 * $gridBase 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
}

</style>

