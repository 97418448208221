<template>
  <div class="container-fluid">
    <div class="container">
      <div class="header">
        <h2>Integrations</h2>
        <p>Connect with all your Accounting, POS systems, and more</p>
      </div>
      <div>
        <div
          v-for="category in integrationTypes"
          :key="category.category_name"
          class="category">
          <div
            v-for="integration in category.integration_types"
            :key="integration.display_name"
            class="integration">
            <div class="columns is-mobile">
              <div>
                <img
                  :src="integration.logo_url"
                  alt=""
                  class="integration-logo" />
              </div>
              <div class="column m-0 p-0">
                <div class="columns m-0 p-0 is-mobile">
                  <div class="column m-0 p-0">
                    <h3>{{ integration.display_name }}</h3>
                    <p>
                      {{ integration.description }}
                    </p>
                  </div>
                  <div>
                    <permission-control requires="MANAGE_INTEGRATIONS">
                      <b-button
                        type="is-primary"
                        class="connect-btn"
                        icon-right="connection"
                        @click="startOAuth(integration)" />
                    </permission-control>
                  </div>
                </div>
                <div class="columns is-multiline active-integrations">
                  <div
                    v-for="activeIntegration in myIntegrations[integration.id]"
                    :key="activeIntegration.id"
                    class="column is-3-tablet is-12-mobile">
                    <div class="active-integration">
                      <h2>{{ activeIntegration.name }}</h2>
                      <p v-if="activeIntegration.config.expiration_date">
                        Expires in
                        {{
                          formatDifference(
                            new Date(),
                            new Date(activeIntegration.config.expiration_date)
                          )
                        }}
                      </p>
                      <permission-control requires="MANAGE_INTEGRATIONS">
                        <b-button
                          type="is-light"
                          size="is-small"
                          class="delete-btn"
                          icon-left="close"
                          @click="deleteIntegrations(activeIntegration.id)" />
                      </permission-control>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="accountLabelModal"
      :can-cancel="false"
      has-modal-card
      trap-focus
      :destroy-on-hide="false">
      <form
        v-if="savedData"
        class="account-label-form"
        @submit.prevent="completeAccountIntegration">
        <div class="modal-card">
          <section class="modal-card-body">
            <div class="top-section">
              <img
                :src="savedData.logo_url"
                alt="" />
              <h2>Almost Done!</h2>
            </div>

            <b-field label="Add a label for this account">
              <b-input
                v-model="accountLabelInput"
                required />
            </b-field>

            <b-button
              expanded
              native-type="submit"
              label="Submit"
              type="is-primary" />
          </section>
        </div>
      </form>
    </b-modal>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="true" />
  </div>
</template>

<script>
  import { formatDifference } from '@/utils/Date'
  import permissionControl from '@/components/permissions/permissionControl.vue'
  export default {
    name: 'IntegrationsPage',
    components: {
      permissionControl,
    },
    data () {
      return {
        isLoading: false,
        accountLabelModal: false,
        savedData: null,
        accountLabelInput: '',
        myIntegrations: [],
        integrationTypes: [],
      }
    },

    async mounted () {
      this.loadMyIntegrations()

      if (this.$route.query.code) {
        this.completeOAuth()
      }
    },
    methods: {
      formatDifference,
      async deleteIntegrations (id) {
        this.isLoading = true
        await this.$apis.integrations.removeIntegation(id)
        await this.loadMyIntegrations()
        this.isLoading = false
      },
      async loadMyIntegrations () {
        this.integrationTypes =
          await this.$apis.integrations.getIntergatrionTypes()
        const integrations = await this.$apis.integrations.getUserIntegations()
        if (integrations) {
          let myIntegrations = {}
          integrations.forEach((category) => {
            category.integration_types.forEach((integration) => {
              myIntegrations[integration.id] = integration.integrations
            })
          })
          this.myIntegrations = myIntegrations
        }
      },

      startOAuth (integration) {
        this.isLoading = true
        localStorage.setItem('companyID', this.$store.getters['companyId'])
        localStorage.setItem(
          'active_integration_data',
          JSON.stringify(integration)
        )
        window.location.href = integration.authorization_url
      },

      completeOAuth () {
        this.savedData = JSON.parse(
          localStorage.getItem('active_integration_data')
        )
        if (this.savedData && this.$route.query) {
          this.accountLabelModal = true
        } else {
          this.$router.replace({ query: null, })
        }
      },
      async completeAccountIntegration () {
        let queries = this.$route.query
        this.isLoading = true
        await this.$apis.integrations.addIntegration({
          integration_type_id: this.savedData.id,
          integration_name: this.accountLabelInput,
          callback_params: queries,
        })

        this.accountLabelModal = false
        localStorage.removeItem('active_integration_data')
        await this.loadMyIntegrations()
        this.$router.replace({ query: null, })
        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  width: 100%;
  padding: 20px 14px;

  .header {
    border-bottom: $border-1;
    margin-top: 30px;

    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    p {
      font-size: 17px;
      color: $grayscale-3;
      font-weight: 400;
      font-style: normal;
      margin-top: 10px;
    }
  }

  .account-label-form {
    .modal-card {
      max-width: 340px;
    }

    .top-section {
      text-align: center;

      img {
        height: 60px;
        margin-top: 20px;
      }

      h2 {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }

  .category {
    .integration {
      padding: 24px 10px;
      border-bottom: $border-1;

      h3 {
        font-weight: 500;
        font-size: 17px;
      }

      p {
        font-size: 14px;
        margin-top: 4px;
        color: $grayscale-3;
      }

      .integration-logo {
        margin-right: 15px;
        width: 42px;
      }

      .connect-btn {
        border-radius: 100%;
      }
    }
  }

  .active-integrations {
    display: flex;
    margin-top: 20px;

    .active-integration {
      position: relative;
      border: $border-1;
      padding: 10px;
      border-radius: 4px;

      h2 {
        text-transform: capitalize;
        font-size: 16px;
      }
      p {
        font-size: 13px;
      }

      .delete-btn {
        position: absolute;
        top: 6px;
        font-size: 15px;
        right: 6px;
        border-radius: 100%;
        padding: 14px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
