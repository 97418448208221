<template>
  <div
    class="measure-legend">
    <template v-for="(measure, idx) in allMeasures.filter(m => m.role !== 'variance_measure')">
      <div
        :key="`legend-${idx}`"
        class="legend-row"
        max-width="128px">
        <div
          class="legend-item"
          :style="{
            'background-color': measureDatumColor(measure, null),
            'box-shadow': `0 0 3px ${measureDatumColor(measure, null)}`,
          }" />
        {{ measure.name }}
      </div>
    </template>
  </div>
</template>

<script>

  import chartComponentMixin from '@/components/charting/chart_component_mixin'

  export default {
    name: 'MeasureLegend',
    mixins: [
      chartComponentMixin,
    ],
  }

</script>

<style lang="scss" scoped>

.measure-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6 * $gridBase;
  padding-bottom: 6 * $gridBase;
  font-size: 1.6rem;
}

.legend-item {
  display: inline-block;
  min-width: 4 * $gridBase;
  width: 4 * $gridBase;
  height: 4 * $gridBase;
  margin: 2*$gridBase;
  border-radius: 50%;
}

.legend-row {
  align-items: center;
  display: flex;
  margin-left: 4*$gridBase;
}

</style>

