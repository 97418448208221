<template>
  <div class="grid">
    <resize-sensor @resize="onResize" />
    <slot />
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'GridComponent',
    computed: {
      ...mapGetters('dashboard', [
        'cellHeight',
      ]),
    },
    methods: {
      onResize (cellSize) {
        this.$store.commit('dashboards/setGridWidthPx', cellSize.width)
      },
    },
  }

</script>

<style lang="scss" scoped>

.grid {
  flex-grow: 1;
  padding: 0;
  margin: 0;
}

</style>
