import { dashboardModel } from '@/models/dashboards/dashboardModel'
import axios from 'axios'

export const dashboardsModel = {
  namespaced: true,
  state () {
    return {
      gridWidthPx: 0,
      columns: 4,
      cellAspect: 1 / 4,
      myDashboards: [],
      recent: [],
      sharedWithMe: [],
      loading: false,
      loadedCompanyId: null,
    }
  },
  modules: {
    selectedDashboard: dashboardModel,
  },
  getters: {
    myDashboards (state) {
      return state.myDashboards
    },
    recent (state) {
      return state.recent
    },
    sharedWithMe (state) {
      return state.sharedWithMe
    },
    cellWidth (state) {
      return state.gridWidthPx / state.columns
    },
    cellHeight (state, getters) {
      return getters.cellWidth * state.cellAspect
    },
    selectedDashboardId (state, getters, rootState, rootGetters) {

      if (!rootGetters['router/params'].dashboard_id) {
        return undefined
      }
      return `${rootGetters['router/params'].dashboard_id}`
    },
    loading (state) {
      return state.loading
    },
    defaultDashboardId (state, getters) {
      if (getters.recent.length > 0) {
        return getters.recent[0].id
      } else if (getters.myDashboards.length > 0) {
        return getters.myDashboards[0].id
      } else if (getters.sharedWithMe.length > 0) {
        return getters.sharedWithMe[0].id
      } else {
        return null
      }
    },
    defaultReportId (state, getters, rootState, rootGetters) {
      if (rootGetters['auth/user/reports'].length > 0) {
        return rootGetters['auth/user/reports'][0].dashboard_id
      } else {
        return null
      }
    },
  },
  mutations: {
    setGridWidthPx (state, width) {
      state.gridWidthPx = width
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoadedCompany (state, companyId) {
      state.loadedCompanyId = companyId
    },
    setMyDashboards (state, myDashboards) {
      state.myDashboards = myDashboards
    },
    setRecent (state, recent) {
      state.recent = recent
    },
    setSharedWithMe (state, sharedWithMe) {
      state.sharedWithMe = sharedWithMe
    },
  },
  actions: {
    async selectDashboard (context, dashboardId) {
      if (`${dashboardId}` !== `${context.getters.selectedDashboardId}`) {
        await context.dispatch(
          'router/replace',
          `/company/${context.rootGetters['companyId']}/dashboard/${dashboardId}`,
          { root: true, })
      }
      await context.dispatch('selectedDashboard/checkStale', dashboardId)
    },
    async loadDashboards (context) {
      let companyId = context.rootGetters['companyId']
      if (companyId === context.state.loadedCompanyId) {
        return
      }
      if (!context.rootGetters['auth/isLoggedIn']) {
        return
      }
      if (!context.rootGetters['auth/user/canViewDashboards']) {
        return
      }
      context.commit('setLoadedCompany', companyId)
      context.commit('setLoading', true)
      let allDashboards = (await axios.get(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${companyId}/dashboards`,
      { headers: { authorization: context.rootGetters['auth/authToken'], }, }))
      .data
      if (context.state.loadedCompanyId === companyId) {
        context.commit(
          'setMyDashboards', allDashboards.my_dashboards)
        context.commit(
          'setRecent', allDashboards.recent)
        context.commit(
          'setSharedWithMe', allDashboards.shared_with_me)
        context.commit('setLoading', false)
        if (context.getters['selectedDashboardId']) {
          await context.dispatch('selectedDashboard/checkStale', context.getters['selectedDashboardId'])
        }
      }
    },
  },
}

