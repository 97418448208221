<template>
  <div class="sub-section">
    <b-field
      class="input-field"
      label="Data type">
      <b-select
        v-model="inputs.data_type"
        placeholder="Select a measure type"
        expanded
        style="width: 100%"
        @input="updateDataFormatType">
        <option
          v-for="option in dataFormatTypeOptions"
          :key="option"
          :value="option">
          {{ option }}
        </option>
      </b-select>
    </b-field>

    <b-field
      v-if="showDecimalPlaceInput"
      class="input-field"
      label="Decimal places">
      <b-numberinput
        v-model="inputs.decimal_places"
        min="0" />
    </b-field>

    <b-field class="input-field">
      <b-switch
        v-model="inputs.show_commas"
        class="mt-5"
        type="is-info">
        show commas
      </b-switch>
    </b-field>

    <b-field class="">
      <b-switch
        v-model="inputs.parenthesize_negatives"
        class="mt-0"
        type="is-info">
        parenthesize negatives
      </b-switch>
    </b-field>

    <b-field class="">
      <b-switch
        v-model="showCurrency"
        class="mt-0"
        type="is-info">
        show currency
      </b-switch>
    </b-field>
  </div>
</template>

<script>
  export default {
    name: 'DataFormatEditor',
    components: {},
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        dataFormatTypeOptions: [
          'label',
          'money',
          'number',
          'percentage',
          'time',
          'datetime',
          'duration',
        ],

        decimalPlaces: {
          label: undefined,
          money: 2,
          number: 0,
          percentage: 1,
          time: undefined,
          datetime: undefined,
          duration: undefined,
        },
      }
    },
    computed: {
      showCurrency: {
        get () {
          return (
            this.inputs.show_currency ||
            [null, undefined,].includes(this.inputs.show_currency)
          )
        },
        set (showCurrency) {
          this.inputs.show_currency = showCurrency
        },
      },
      inputs: {
        get () {
          return this.value || {}
        },
        set (value) {
          this.$emit('input', value ? value : null)
        },
      },
      showDecimalPlaceInput () {
        return ['money', 'number', 'percentage',].includes(
          this.inputs.data_type
        )
      },
    },
    mounted () {},
    methods: {
      updateDataFormatType (value) {
        this.inputs.decimal_places = this.decimalPlaces[value]
      },
    },
  }
</script>

<style lang="scss" scoped>
.benchmark-inputs {
  margin-top: 30px;
}
</style>
