<template>
  <div class="container">
    <validation-observer
      ref="form"
      v-slot="{ invalid }">
      <div class="header columns is-vcentered">
        <router-link :to="{ name: 'email_report_library' }">
          <b-button
            type="is-light"
            class="back-btn mr-3"
            icon-right="arrow-left" />
        </router-link>
        <h2>{{ isCreateMode ? "Create" : "Edit" }} Email</h2>
        <div class="control-bar">
          <a-button
            hotkeys="Ctrl+s"
            type="is-primary"
            :disabled="!isCreateMode && (!isDirty || invalid)"
            @click="saveEdits">
            {{ isCreateMode ? "Create" : "Save" }}
          </a-button>
          <a-button
            v-if="!isCreateMode && isDirty"
            class="ml-5"
            @click="discardChanges">
            Discard changes
          </a-button>
          <permission-control requires="SEND_EMAIL">
            <b-tooltip
              :label="
                isSendEmailButtonDisabled
                  ? 'You can send again in a few mins'
                  : 'You have unsaved changes'
              "
              position="is-bottom"
              type="is-light"
              :active="isDirty || isSendEmailButtonDisabled">
              <a-button
                v-if="!isCreateMode"
                hotkeys="Ctrl+s"
                class="ml-5"
                :disabled="isDirty || isSendEmailButtonDisabled"
                @click="sendEmailModal = true">
                Send Email
              </a-button>
            </b-tooltip>
          </permission-control>
        </div>
      </div>
      <div class="columns m-0 mt-6">
        <div class="column is-3 p-0 pr-6">
          <h2 class="header-with-underline">
            Info
          </h2>
          <div class="input-form">
            <validation-provider
              v-slot="{ errors }"
              rules="required">
              <b-field
                label="Email Name"
                :type="errors.length ? 'is-danger' : ''"
                :message="errors[0]"
                class="mt-5">
                <b-input
                  v-model="displayNameInput"
                  @input="makeInputsDirty" />
              </b-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required">
              <b-field
                label="Email Subject Line"
                :type="errors.length ? 'is-danger' : ''"
                :message="errors[0]"
                class="mt-5">
                <b-input
                  v-model="subjectInput"
                  @input="makeInputsDirty" />
              </b-field>
            </validation-provider>
            <!-- <validation-provider v-slot="{ errors }"
                                   rules="required">
                <b-field
                  label="Email Body Message"
                  :type="errors.length ? 'is-danger' : ''"
                  :message="errors[0]"
                  class="mt-5">
                  <b-input
                    v-model="emailBodyInput"
                    @input="makeInputsDirty" />
                </b-field>
              </validation-provider> -->

            <validation-provider
              v-slot="{ errors }"
              rules="required">
              <b-field
                label="Sender Alias"
                :type="errors.length ? 'is-danger' : ''"
                :message="errors[0]"
                class="mt-5">
                <b-input
                  v-model="senderAliasInput"
                  @input="makeInputsDirty" />
              </b-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required">
              <b-field
                label="Version"
                :type="errors.length ? 'is-danger' : ''"
                :message="errors[0]"
                :disabled="!isCreateMode"
                class="mt-5">
                <b-input
                  v-model="versionInput"
                  :disabled="!isCreateMode" />
              </b-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required">
              <b-field
                label="Internal Name (Internal Only)"
                :type="errors.length ? 'is-danger' : ''"
                :message="errors[0]"
                class="mt-5">
                <b-input
                  v-model="nameInput"
                  role="presentation"
                  :disabled="!isCreateMode" />
              </b-field>
            </validation-provider>

            <permission-control requires="MANAGE_EMAIL_CONFIG">
              <b-field
                label="Status"
                class="mt-6">
                <b-switch
                  v-model="isActiveInput"
                  :value="true"
                  type="is-success"
                  @input="makeInputsDirty">
                  {{ isActiveInput ? "Active" : "Inactive" }}
                </b-switch>
              </b-field>
            </permission-control>

            <permission-control requires="EDIT_EMAIL">
              <template v-if="companyScopes.length > 0">
                <div class="scoping">
                  <div
                    v-for="companyScope in companyScopes"
                    :key="companyScope.key"
                    class="scope-selector">
                    <b-switch
                      :value="isScopeActive(companyScope)"
                      type="is-success"
                      @input="togleScopeActive(companyScope)">
                      {{ companyScope.name }}
                    </b-switch>
                    <CompanyScopeSelect
                      v-if="isScopeActive(companyScope)"
                      class="scope-selector-select"
                      :value="scopeSelections[companyScope.key]"
                      :company-scope="companyScope"
                      @input="updateScopeSelect(companyScope, $event)" />
                  </div>
                </div>
              </template>
            </permission-control>
          </div>
        </div>

        <div class="column is-3 p-0 pr-6 pl-6">
          <h2 class="header-with-underline">
            Tags
          </h2>

          <div class="tags-container">
            <h3>Location(s)</h3>
            <div class="items-container">
              <div
                v-for="locationId in allLocationsSelected"
                :key="locationId"
                class="item">
                {{ allLocations[locationId] }}
              </div>
            </div>
          </div>
        </div>

        <div class="column is-6 p-0 pl-6">
          <h2 class="header-with-underline">
            Recipients
          </h2>

          <b-field
            label="Recipients"
            class="mt-6">
            <b-taginput
              v-model="recipientsInput"
              :create-tag="addEmailTag"
              :before-adding="validateEmail"
              :on-paste-separators="[',', ' ']"
              icon="mail"
              placeholder="Add an Email"
              aria-close-label="Remove this Email"
              @input="makeInputsDirty" />
          </b-field>

          <b-field
            label="cc Recipients"
            class="mt-5">
            <b-taginput
              v-model="ccRecipientsInput"
              :create-tag="addEmailTag"
              :before-adding="validateEmail"
              :on-paste-separators="[',', ' ']"
              icon="mail"
              placeholder="Add an Email"
              aria-close-label="Remove this Email"
              @input="makeInputsDirty" />
          </b-field>

          <b-field
            label="bcc Recipients"
            class="mt-5">
            <b-taginput
              v-model="bccRecipientsInput"
              :create-tag="addEmailTag"
              :on-paste-separators="[',', ' ']"
              :before-adding="validateEmail"
              icon="mail"
              placeholder="Add an Email"
              aria-close-label="Remove this Email"
              @input="makeInputsDirty" />
          </b-field>
          <permission-control requires="MANAGE_DASHBOARDS">
            <email-cadence-editor
              v-model="cadence"
              @input="makeInputsDirty" />
          </permission-control>
        </div>
      </div>

      <permission-control requires="EDIT_EMAIL">
        <div class="attachments">
          <h2 class="header-with-underline">
            Attachments
          </h2>

          <div class="columns is-vcentered report-table-filters">
            <div class="column report-search-input m-0 p-0 mr-4">
              <b-input
                v-model="reportSearchInput"
                custom-class="no-border"
                placeholder="Search..."
                type="search"
                icon="magnify" />
            </div>

            <div>
              <b-button
                :type="reportTableTabs === 0 ? 'is-light' : 'is-white'"
                class="mr-2"
                @click="reportTableTabs = 0">
                All
                <b-tag
                  class="data-tags is-white"
                  rounded>
                  {{ emailReports.length }}
                </b-tag>
              </b-button>
              <b-button
                :type="reportTableTabs === 1 ? 'is-light' : 'is-white'"
                @click="reportTableTabs = 1">
                Selected
                <b-tag
                  class="data-tags is-white"
                  rounded>
                  {{ emailReportsSelected.length }}
                </b-tag>
              </b-button>
            </div>
          </div>

          <b-table
            scrollable
            :height="570"
            :sticky-header="true"
            :data="resultsToShow"
            class="email-reports-table"
            hoverable
            @click="toggleReportRow">
            <b-table-column
              width="20"
              field="">
              <template #header>
                <b-tooltip append-to-body>
                  <b-checkbox
                    :value="selectAllReportsValue"
                    @input="selectAll" />
                </b-tooltip>
              </template>
              <template #default="props">
                <b-checkbox
                  v-model="emailReportsSelected"
                  :native-value="props.row.id"
                  style="pointer-events: none" />
              </template>
            </b-table-column>
            <b-table-column
              v-slot="props"
              sortable
              field="displayName"
              label="Name">
              {{ props.row.displayName || props.row.name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              width="180"
              label="Locations(s)">
              <b-dropdown
                v-if="
                  props.row.locationsScope &&
                    props.row.locationsScope.length > 1
                "
                :triggers="['hover']"
                aria-role="list">
                <template #trigger>
                  <b-button
                    class="location-dropdown-button unclickable"
                    type="is-light"
                    icon-right="menu-down">
                    <div style="width: 130px; text-align: left">
                      Multiple
                    </div>
                  </b-button>
                </template>

                <b-dropdown-item
                  v-for="(locationId, i) in props.row.locationsScope"
                  :key="i"
                  disabled
                  :focusable="false"
                  class="unclickable"
                  aria-role="listitem">
                  {{ allLocations[locationId] }}
                </b-dropdown-item>
              </b-dropdown>

              <div
                v-else-if="
                  props.row.locationsScope &&
                    props.row.locationsScope.length === 1
                ">
                {{ allLocations[props.row.locationsScope[0]] }}
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              width="100"
              field="version"
              label="Filetype">
              <b-tag class="data-tags">
                {{ props.row.fileType || "PDF" }}
              </b-tag>
            </b-table-column>

            <b-table-column
              v-slot="props"
              width="80"
              field="version"
              label="Version">
              <b-tag class="data-tags">
                {{ props.row.version }}
              </b-tag>
            </b-table-column>

            <template #empty>
              <div
                v-if="!isLoading"
                class="has-text-centered mt-6">
                <b-tag
                  class="data-tags"
                  rounded
                  size="is-medium">
                  No Reports Found 🥥
                </b-tag>
              </div>
            </template>
          </b-table>
          <div class="report-table-footer">
            {{ emailReportsSelected.length }} Selected
          </div>
        </div>
      </permission-control>

      <b-modal
        v-model="sendEmailModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Send Email Modal"
        close-button-aria-label="Close"
        aria-modal>
        <form
          action=""
          @submit.prevent="sendTestEmail">
          <div class="modal-card send-email-modal">
            <header class="modal-card-head">
              <p class="modal-card-title">
                Send Test Email
              </p>
              <button
                type="button"
                class="delete"
                @click="sendEmailModal = false" />
            </header>
            <section class="modal-card-body">
              <b-field label="Test Recipient Email">
                <b-taginput
                  v-model="recipientOverride"
                  :disabled="useRealRecipients"
                  :create-tag="addEmailTag"
                  :on-paste-separators="[',', ' ']"
                  :before-adding="validateEmail"
                  icon="mail"
                  placeholder="Add an Email"
                  aria-close-label="Remove this Email" />
              </b-field>
              <div class="or-divider">
                or
              </div>
              <b-field>
                <b-switch
                  v-model="useRealRecipients"
                  type="is-success">
                  Use Real Recipients
                </b-switch>
              </b-field>

              <hr />
              <b-field label="Report Date">
                <b-datepicker
                  v-model="testEmailDate"
                  placeholder="Click to select..."
                  inline
                  icon="calendar-today" />
              </b-field>
              <div class="email-date-display">
                {{ testEmailDateFormatted }}
              </div>
            </section>
            <footer class="modal-card-foot">
              <b-button
                label="Cancel"
                @click="sendEmailModal = false" />
              <b-button
                label="Send"
                type="is-primary"
                native-type="submit" />
            </footer>
          </div>
        </form>
      </b-modal>
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="true" />
    </validation-observer>
  </div>
</template>

<script>
  import { validateEmail } from '@/utils/Validation'
  import moment from 'moment'
  import { formatDate } from '@/utils/Date.js'
  import FuzzySearch from 'fuzzy-search'
  import permissionControl from '@/components/permissions/permissionControl.vue'
  import CompanyScopeSelect from '@/views/tools/email_reports/CompanyScopeSelect'
  import EmailCadenceEditor from '@/views/tools/email_reports/EmailCadenceEditor'

  export default {
    name: 'EmailReportEditor',
    components: {
      permissionControl,
      CompanyScopeSelect,
      EmailCadenceEditor,
    },
    props: {},
    data () {
      return {
        emailReports: [],
        emailReportID: null,
        isLoading: false,
        isCreateMode: true,
        sendEmailModal: false,
        recipientOverride: [],
        testEmailDate: moment().subtract(1, 'days').toDate() , //yesterdays date
        useRealRecipients: false,
        reportSearchInput: '',
        reportTableTabs: 0,
        allLocations: {},
        companyScopes: [],
        scopeSelections: {},

        nameInput: '',
        displayNameInput: '',
        versionInput: '',
        subjectInput: '',
        senderAliasInput: '',
        emailBodyInput: '',
        recipientsInput: [],
        ccRecipientsInput: [],
        bccRecipientsInput: [],
        cadence: null,
        emailReportsSelected: [],
        isActiveInput: false,

        isDirty: false,
        lastSentAt: null,

        isEmailSent: false,
        lastEmailSentInterval: 2 * 60 * 1000, // 2 min
      }
    },
    computed: {
      allLocationsSelected () {
        // computed by taking all locations from selected reports

        let locationSelected = []

        this.emailReportsSelected.forEach((reportId) => {
          const report = this.emailReports.find((item) => item.id === reportId)

          if (report && report.locationsScope) {
            locationSelected = [...locationSelected, ...report.locationsScope,]
          }
        })
        return locationSelected
      },
      isSendEmailButtonDisabled () {
        if (this.isEmailSent) {
          return true
        }
        if (!this.lastSentAt) {
          return false
        } else {
          return (
            new Date() - new Date(this.lastSentAt + 'Z') <
            this.lastEmailSentInterval
          )
        }
      },
      selectAllReportsValue () {
        return (
          this.emailReportsSelected.length > 0 &&
          this.emailReportsSelected.length === this.resultsToShow.length
        )
      },
      testEmailDateFormatted () {
        return formatDate(this.testEmailDate)
      },
      reportTableData () {
        if (this.reportTableTabs === 0) {
          return this.emailReports
        }
        return this.emailReports.filter((row) =>
          this.emailReportsSelected.includes(row.id)
        )
      },

      searcher () {
        const searcher = new FuzzySearch(this.reportTableData, [
          'name',
          'displayName',
          'version',
        ])
        return searcher
      },

      resultsToShow () {
        if (this.reportSearchInput) {
          return this.searcher.search(this.reportSearchInput)
        }
        return this.reportTableData
      },
    },
    watch: {
      async '$route.params.report_id' () {
        await this.onLoad()
      },
    },

    async mounted () {
      await this.onLoad()
    },
    methods: {
      selectAll (value) {
        if (value) {
          this.emailReportsSelected = this.resultsToShow.map((item) => item.id)
        } else {
          this.emailReportsSelected = []
        }
      },
      async onLoad () {
        this.emailReportID = this.$route.params.report_id
        if (this.emailReportID && this.emailReportID !== 'new') {
          this.isCreateMode = false
          await this.loadEmailReportData()
        } else if (this.$route.params.copyFromReportID) {
          await this.loadEmailReportData(this.$route.params.copyFromReportID)
          this.nameInput = this.nameInput
            ? this.nameInput + '_copy'
            : this.nameInput
        }

        const futures = [
          this.getCompanyLocations(),
          this.getEmailReports(),
          this.getCompanyScopes(),
        ]
        for (const f in futures) {
          await f
        }
      },

      async getCompanyLocations () {
        const locationsResponse = await this.$apis.app.getAllLocations()
        locationsResponse.forEach((location) => {
          this.allLocations[location.id] = location.display_name
        })
      },
      async getCompanyScopes () {
        const scopesResponse = await this.$apis.companyConfigs.listCompanyScopes()
        this.companyScopes = scopesResponse || []
      },
      isScopeActive (companyScope) {
        return this.scopeSelections[companyScope.key] !== undefined
      },
      togleScopeActive (companyScope) {
        this.makeInputsDirty()
        if (this.isScopeActive(companyScope)) {
          this.$set(this.scopeSelections, companyScope.key, undefined)
        } else {
          this.$set(this.scopeSelections, companyScope.key, [])
        }
      },
      updateScopeSelect (companyScope, value) {
        const newVal = { ...this.scopeSelections, }
        newVal[companyScope.key] = value
        this.scopeSelections = newVal
        this.makeInputsDirty()
      },
      makeInputsDirty () {
        this.isDirty = true
      },
      toggleReportRow (row) {
        const indexOfItem = this.emailReportsSelected.indexOf(row.id)
        if (indexOfItem > -1) {
          this.emailReportsSelected.splice(indexOfItem, 1)
        } else {
          this.emailReportsSelected.push(row.id)
        }
        this.isDirty = true
      },

      async sendTestEmail () {
        this.sendEmailModal = false
        this.isEmailSent = true

        this.$buefy.toast.open({
          message: `Email is in Queue`,
          type: 'is-info',
        })
        const response = await this.$apis.emailReports.sendEmailReportAsync(
          this.emailReportID,
          {
            recipientOverride: this.recipientOverride,
            date: formatDate(this.testEmailDate, 'YYYY-MM-DD'),
            useRealRecipients: this.useRealRecipients,
          }
        )

        if (response) {
          this.$buefy.toast.open({
            message: `Email Sent 💌`,
            type: 'is-success',
          })
        } else {
          this.isEmailSent = false
          this.$buefy.toast.open({
            message: `Error Sending Email`,
            type: 'is-warning',
          })
        }
      },
      addEmailTag (email) {
        return email.match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        )[0]
      },
      validateEmail (email) {
        return validateEmail(email)
      },

      async loadEmailReportData (reportID = this.emailReportID) {
        this.isLoading = true
        const response = await this.$apis.emailReports.getEmailReportData(
          reportID
        )
        if (response) {
          this.nameInput = response.name
          this.displayNameInput = response.displayName
          this.versionInput = response.version
          this.subjectInput = response.subject
          this.senderAliasInput = response.senderAlias
          this.recipientsInput = response.recipients
          this.ccRecipientsInput = response.ccRecipients
          this.bccRecipientsInput = response.bccRecipients
          this.cadence = response.cadence
          this.emailReportsSelected = response.reportPdfs.map(
            (report) => report.id
          )
          this.isActiveInput = response.isActive
          this.lastSentAt = response.lastSentAt
          response.emailReportScopes.forEach(scope => {
            this.scopeSelections[scope.companyScope.key] = scope.valuesJson.map(v => ({ key: v, }))
          })
        }
        this.isDirty = false
        this.isLoading = false
      },
      async getEmailReports () {
        const response = await this.$apis.emailReports.getReports()

        if (response) {
          this.emailReports = response.filter(
            (report) => {
              return report.isActive
            }
          )
        }
      },

      async discardChanges () {
        await this.loadEmailReportData()
      },
      async saveEdits () {
        const isFormValidatied = await this.$refs.form.validate()
        if (!isFormValidatied) return

        this.isLoading = true
        const requestBody = {
          subject: this.subjectInput,
          senderAlias: this.senderAliasInput,
          recipients: this.recipientsInput,
          ccRecipients: this.ccRecipientsInput,
          bccRecipients: this.bccRecipientsInput,
          cadence: this.cadence,
          name: this.nameInput,
          version: this.versionInput,
          isActive: this.isActiveInput,
          displayName: this.displayNameInput,
          reportPdfIds: this.emailReportsSelected,
          emailReportScopes: Object.keys(this.scopeSelections).filter(
            k => this.scopeSelections[k] !== undefined
          ).map(k => ({
            companyScope: {
              key: k,
            },
            valuesJson: this.scopeSelections[k].map(s => s.key),
          })),
        }

        if (this.isCreateMode) {
          const response = await this.$apis.emailReports.createEmailReport(
            requestBody
          )
          if (response) {
            this.$buefy.toast.open({
              message: `Created`,
              type: 'is-success',
            })
            this.$router.push({
              name: 'email_editor',
              params: { report_id: response.id, },
            })
          }
        } else {
          requestBody.name = this.nameInput
          requestBody.version = this.versionInput
          const response = await this.$apis.emailReports.updateEmailReport(
            this.emailReportID,
            requestBody
          )
          if (!response) {
            this.$buefy.toast.open({
              message: `Error Saving Data`,
              type: 'is-warning',
            })
          } else {
            this.$buefy.toast.open({
              message: `Saved`,
              type: 'is-success',
            })
            this.isDirty = false
          }
        }
        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  overflow-y: auto;
  position: relative;
  padding-bottom: 50px;
  padding: 20px;

  .header {
    border-bottom: $border-1;
    margin-top: 30px;

    padding-bottom: 20px;

    .back-btn {
      font-size: 24px;
      border-radius: 100%;
    }

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .input-form {
    max-width: 400px;
  }

  .tags-container {
    border: $border-1;
    border-radius: 10px;
    h3 {
      font-size: 16px;
      border-bottom: $border-1;
      padding: 10px 15px;
      color: $ui-03;
    }

    .items-container {
      max-height: 400px;
      overflow: auto;
      .item {
        padding: 0 15px;
        margin: 8px 0;
        font-size: 15px;
      }
    }
  }
  .report-table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .attachments {
    margin-top: 50px;
  }

  .scoping {
    margin-top: 50px;
  }

  .scope-selector {
    display: flex;
    flex-direction: column;
  }

  .email-reports-table {
    font-size: 14px;
    border-radius: 4px;
    border: $border-1;
    margin-top: 5px;
  }

  .report-table-footer {
    border: $border-1;
    text-align: center;
    padding: 8px;
    font-size: 13px;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .test-email-report {
    border-top: $border-1;
    margin-top: 30px;
    padding-top: 20px;
  }

  .data-tags {
    font-size: 12px;
    margin: 4px;
  }

  .email-date-display {
    text-align: center;
    font-size: 13px;
  }

  .send-email-modal {
    max-width: 360px;
  }

  .or-divider {
    text-align: center;
    margin: 5px 0;
    font-size: 14px;
  }

  .scope-selector-select {
    margin-top: 10px;
  }
}
</style>
