<template>
  <div class="config-visualizer">
    <busy-overlay
      v-if="loading"
      :enabled="loading"
      :loading="loading"
      :message="'loading'" />
    {{ analysis }}
  </div>
</template>

<script>

  import BusyOverlay from '@/components/common/BusyOverlay'

  export default {
    name: 'ConfigVisualizer',
    components: {
      BusyOverlay,
    },
    data () {
      return {
        loading: true,
        analysis: null,
      }
    },
    methods: {
      async loadAnalysis () {
        this.loading = true
        this.analysis = await this.$apis.companyConfigs.analyzeConfig()
        this.loading = false
      },
    },
    mounted () {
      this.loadAnalysis()
    },
  }

</script>
