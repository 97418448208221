<template>
  <div class="table-editor-container">
    <div class="editor-header">
      <div class="widget-type">
        <img
          :src="moduleConfigs.icon"
          alt="" />
        <div>{{ moduleConfigs.name }}</div>
      </div>
      <Tabs
        v-model="inputSectionTab"
        :tabs="tabs"
        :is-objects="true" />
    </div>

    <div
      v-if="!!module"
      class="editor">
      <div
        v-if="inputSectionTab === 'label'"
        class="editor-inputs">
        <b-field
          label="Title"
          class="input-field"
          message="">
          <b-input
            v-model="module.title"
            type="" />
        </b-field>
      </div>
      <TableStyle
        v-if="inputSectionTab === 'table-styles'"
        v-model="module.table_style"
        class="editor-inputs" />

      <TableTimeRange
        v-if="inputSectionTab === 'time-dimension'"
        v-model="module.default_time_range"
        class="editor-inputs" />

      <FiltersTab
        v-if="inputSectionTab === 'filters'"
        v-model="module.filters"
        class="editor-inputs" />
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/pdf_report/Tabs.vue'
  import TableStyle from './tabs/TableStyle.vue'
  import TableTimeRange from './tabs/TableTimeRange.vue'
  import FiltersTab from '@/components/pdf_report/table_module/table_rows/FiltersTab'

  export default {
    name: 'TableSettings',
    components: {
      TableStyle,
      TableTimeRange,
      FiltersTab,
      Tabs,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        moduleConfigs: {
          name: 'Table',
          icon: require('@/assets/icons/table.svg'),
        },
        inputSectionTab: 'label',
      }
    },

    computed: {
      module: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },

      tabs () {
        return [{
                  label: 'Label',
                  id: 'label',
                  hasErrors: false,
                },
                {
                  label: 'Table Styles',
                  id: 'table-styles',
                  hasErrors: this.module?.table_style?.errors,
                },
                {
                  label: 'Time Dimension',
                  id: 'time-dimension',
                  hasErrors: this.module?.default_time_range?.errors,
                },
                {
                  label: 'Filters',
                  id: 'filters',
                  hasErrors: this.module?.filters.some(item => item.errors),
                },
        ]
      },
    },

    mounted () {

    },

    beforeDestroy () {},

    methods: {
      removeReport (key) {
        this.$emit('remove', key)
      },

      tabSelection (tab) {
        this.inputSectionTab = tab
      },

      showModulePicker () {},

      makeInputsDirty () {},

      selectModule () {},

      openTrackerTableModule () {},

    // showModulePicker () {

    // },
    },
  }
</script>

<style lang="scss" scoped>
.table-editor-container {
  width: 100%;
  height: 100%;
  margin: auto !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: $border-1;
  border-radius: 3px;
  height: 100%;
}

.widget-type {
  display: flex;
  font-weight: 400;
  font-size: 15px;
  align-items: center;
  img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border: $border-1;
    border-radius: 8px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.editor {
  padding: 20px;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .editor-inputs {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

.editor-header {
  border-bottom: $border-1;
  padding: 20px;

  .widget-type {
    display: flex;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    margin-bottom: 15px;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border: $border-1;
      border-radius: 8px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
