<template>
  <div class="filters-container">
    <div class="filter-title">
      Filter by
    </div>
    <div
      v-for="(filter, i) in data"
      :key="i"
      class="filter-container flexing-row">
      <inline-expression :data="filter.left" />
      <template v-if="filter.condition">
        <div class="filter-condition">
          {{ filter.condition }}
        </div>
        <inline-expression :data="filter.right" />
      </template>
    </div>
  </div>
</template>

<script>
  import InlineExpression from '@/components/expression_viewer/InlineExpression'
  export default {
    name: 'ExpressionFilters',
    components: {
      InlineExpression,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
        required: true,
      },
    },
    data () {
      return {}
    },

    async mounted () {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
.filters-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 9px;
  border: 2px dashed #bfbfbf;
  padding: 20px;
}

.filter-title {
  color: #be7e48;
  background: #ffe8bc;
  border: 1px solid rgba(190, 126, 72, 0.3);
  border-radius: 9px;
  font-weight: 300;
  font-size: 16px;
  padding: 5px 10px;
}

.filter-container {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  padding: 8px;
  font-size: 17px;
  margin: 6px 0;
  background-color: white;

  .filter-condition {
    padding: 10px 14px;
  }
}
</style>
