<template>
  <div class="row-editor-container">
    <div class="editor-header">
      <div class="widget-type">
        <i class="material-icons mr-2"> alt_route </i>
        <div>{{ inputs.name || moduleConfigs.name }}</div>
      </div>
      <Tabs
        v-model="inputSectionTab"
        :tabs="tabs"
        :is-objects="true"
        @input="scrollToSection" />
    </div>

    <div
      class="editor"
      @scroll="throttleScroll">
      <div
        id="display-label"
        class="editor-section">
        <h2>display label</h2>
        <div class="body">
          <b-field
            label="Display Label"
            class="input-field"
            message="">
            <b-input
              v-model="inputs.name"
              type="" />
          </b-field>
        </div>
      </div>

      <div
        id="dimension"
        class="editor-section">
        <h2>dimension</h2>
        <div class="body">
          <PivotEditorDimensionTab v-model="inputs.table_pivot" />
        </div>
      </div>

      <div
        id="order"
        class="editor-section">
        <h2>Order</h2>
        <div class="body">
          <OrderConfigEditor v-model="inputs.table_pivot.order" />
        </div>
      </div>

      <div
        id="filters"
        class="editor-section">
        <h2>filters</h2>
        <div class="body">
          <FiltersTab v-model="inputs.table_pivot.filters" />
        </div>
      </div>

      <div
        id="total-columns"
        class="editor-section">
        <h2>Total Columns</h2>
        <div class="body">
          <PivotTotalsTab v-model="inputs.table_pivot.table_totals" />
        </div>
      </div>

      <div
        id="header-styles"
        class="editor-section">
        <h2>header style</h2>
        <div class="body">
          <StyleTab v-model="inputs.header_style" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/pdf_report/Tabs.vue'
  import { throttle } from 'lodash'
  import PivotEditorDimensionTab from './PivotEditorDimensionTab.vue'
  import OrderConfigEditor from '@/components/reports/config/modules/common/OrderConfigEditor'
  import PivotTotalsTab from './PivotTotalsTab.vue'
  import StyleTab from './StyleTab.vue'
  import FiltersTab from '@/components/pdf_report/table_module/table_rows/FiltersTab'

  export default {
    name: 'PivotGroupEditor',
    components: {
      FiltersTab,
      Tabs,
      PivotEditorDimensionTab,
      OrderConfigEditor,
      PivotTotalsTab,
      StyleTab,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        moduleConfigs: {
          name: 'Measure Group',
          hideInLibrary: true,
        },

        inputSectionTab: 'display-label',
      }
    },

    computed: {
      tabs () {
        return [
          {
            label: 'Display Label',
            id: 'display-label',
            hasErrors: false,
          },
          {
            label: 'Dimension',
            id: 'dimension',
            hasErrors: this.inputs?.table_pivot?.dimension?.errors,
          },
          {
            label: 'Order',
            id: 'order',
            hasErrors: this.inputs?.order?.errors,
          },
          {
            label: 'Filters',
            id: 'filters',
            hasErrors: this.inputs?.table_pivot?.filter?.some((f) => f.errors),
          },
          {
            label: 'Total Columns',
            id: 'total-columns',
            hasErrors: this.inputs?.table_pivot?.table_totals?.some(
              (f) => f.errors
            ),
          },
          {
            label: 'Header Styles',
            id: 'header-styles',
            hasErrors: this.inputs?.header_style?.errors,
          },
        ]
      },
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      throttleScroll () {
        return throttle(this.onScroll, 100)
      },
    },

    async mounted () {},

    beforeDestroy () {},

    methods: {
      onScroll (event) {
        const sectionsByDistanceFromTop = this.tabs.map((section) => {
          const el = document.getElementById(section.id)
          if (!el) return
          const rect = el.getBoundingClientRect()
          const scrollableDivRect = event.target.getBoundingClientRect()
          return {
            section: section.id,
            top: Math.abs(rect.top - scrollableDivRect.top),
          }
        })

        const sortedSections = sectionsByDistanceFromTop.sort(
          (a, b) => a.top - b.top
        )
        this.inputSectionTab = sortedSections[0].section
      },
      scrollToSection (sectionId) {
        const element = document.getElementById(sectionId)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.editor-header {
  border-bottom: $border-1;
  padding: 20px;

  .widget-type {
    display: flex;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    margin-bottom: 15px;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border: $border-1;
      border-radius: 8px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.row-editor-container {
  width: 100%;
  height: 100%;
  margin: auto !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.editor {
  padding: 20px;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-direction: column;

  .editor-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    h2 {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    .body {
      border: $border-1;
      border-radius: 10px;
      padding: 18px;
    }
  }
}
// .card {
//   border: $border-1;

//   .card-header-title {
//     margin-bottom: 0 !important;
//   }
// }
// .input-field {
//   max-width: 400px;
// }

// h3.editor-section-title {
//   margin-bottom: 12px;
//   font-weight: 500;
//   font-size: 17px;
//   // margin-left: -50px;
// }
</style>
