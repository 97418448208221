import { apis } from '@/plugins/apis'

export const dashboardReportsStore = {
  namespaced: true,
  state () {
    return {
      dashboardReports: [],
      loading: false,
    }
  },
  getters: {
    loading (state) {
      return state.loading
    },
    dashboardReports (state) {
      return state.dashboardReports
    },
  },
  mutations: {
    async setLoading (state, loading) {
      state.loading = loading
    },
    async setDashboardReports (state, dashbaordReports) {
      state.dashboardReports = dashbaordReports
    },
  },
  actions: {
    async refresh (context) {
      context.commit('setLoading', true)
      const dashboardReportsFuture = apis.companyConfigs.listDashboardReports()

      const dashboardReports = await dashboardReportsFuture
      context.commit('setDashboardReports', dashboardReports || [])

      context.commit('setLoading', false)
    },
  },
}
