<template>
  <droppable-field
    :enabled="enableDrag"
    class="dimension"
    target-selector="dimension"
    @start="$emit('start', dimension)"
    @end="$emit('end')">
    <div class="field">
      <div class="field-container">
        <div class="dimension-label">
          {{ dimension.name }}
        </div>
      </div>
      <ingest-dropdown
        v-if="enableFilters && dimension.filters.length > 0"
        class="filter-button dark-green-text"
        :force-close="!showFilterMenu"
        align="right"
        opens="down"
        @open="openFilterMenu()">
        <v-icon
          slot="button-content"
          :class="{ 'has-filter': hasFilter }"
          name="filter" />
        <b-card
          slot="body-content"
          class="filter-card">
          <dimension-filter-select
            :dimension="dimension"
            :filter.sync="newFilter"
            :other-filters="[...dashboardFilters, ...moduleFilters.filter(otherFilter => otherFilter.dimension != dimension.key)]" />
          <b-button
            variant="none"
            class="ingest-dark-button cancel-button"
            @click="deleteFilter()">
            {{ hasFilter ? 'remove' : 'cancel' }}
          </b-button>
          <b-button
            variant="none"
            class="ingest-light-button"
            @click="addOrUpdateFilter()">
            {{ hasFilter ? 'update' : 'add' }}
          </b-button>
        </b-card>
      </ingest-dropdown>
    </div>
  </droppable-field>
</template>

<script>

  import DroppableField from '@/components/module/edit/DroppableField'
  import DimensionFilterSelect from '@/components/controls/filter/DimensionFilterSelect'
  import IngestDropdown from '@/components/common/IngestDropdown'

  export default {
    name: 'DimensionComponent',
    components: {
      DroppableField,
      DimensionFilterSelect,
      IngestDropdown,
    },
    props: {
      dimension: { type: Object, required: true, },
      moduleFilters: { type: Array, required: true, },
      dashboardFilters: { type: Array, required: true, },
      enableFilters: {
        type: Boolean,
        default: true,
      },
      enableDrag: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        showFilterMenu: false,
        newFilter: null,
      }
    },
    computed: {
      hasFilter () {
        return this.enableFilters && this.moduleFilters
          .filter(filter => filter.dimension == this.dimension.key)
          .length > 0
      },
      filter () {
        return this.moduleFilters
          .filter(filter => filter.dimension == this.dimension.key)[0]
      },
    },
    methods: {
      openFilterMenu () {
        this.newFilter = this.hasFilter
          ? this.filter
          : null
        this.showFilterMenu = true
      },
      deleteFilter () {
        let newFilters = [...this.moduleFilters
          .filter(filter => filter.dimension != this.dimension.key), ]
        this.$emit('update:moduleFilters', newFilters)
        this.showFilterMenu = false
      },
      cancelOrUpdateFilter () {
        if (this.hasFilter) {
          this.addOrUpdateFilter()
        } else {
          this.deleteFilter()
        }
      },
      addOrUpdateFilter () {
        let newFilters = [...this.moduleFilters
          .filter(filter => filter.dimension != this.dimension.key), ]
        if (this.newFilter != null) {
          newFilters.push(this.newFilter)
        }
        this.$emit('update:moduleFilters', newFilters)
        this.showFilterMenu = false
      },
    },
  }

</script>

<style lang="scss" scoped>

.dimension {
  margin: 8px;
  width: 144px;

  &:hover, &.open {
    min-width: 144px;
  }
}

.filter-button {
  border-left: solid 1px #4A4D50;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 8 * $remGrid;
}

.field {
  background: #98CABF !important;
  box-shadow: 0px 3px 6px 0px rgba(152, 202, 191, .5);
  border-radius: 8px !important;
  width: 144px;
  flex-grow: 1;
  display: flex;

  * {
    color: #4A4D50;
  }
}

.field-container {
  flex-grow: 1;
  display: flex;
}

.dimension-label {
  flex-grow: 1;
  padding: 8px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.filter-card {
  width: 100%;
}

.filter-edit {
  z-index: 10;
  position: absolute;
  background: $backGround0;
  padding: 0;
  top: 100%;
  right: 0;
  width: 100%;

  .card-body {
    padding: 0;
    overflow: auto;
  }
}

.icon {
  ::v-deep * {
    color: $darkGreen;
    fill: $darkGreen;
  }
}

.icon.has-filter {
  ::v-deep * {
    fill: $ingestGreen;
  }
}

.ingest-dark-button.cancel-button {
  box-shadow: none;
  margin-right: 8px;
}

</style>
