<template>
  <div class="table-editor-container">
    <div class="editor-header">
      <div class="widget-type">
        <img
          :src="moduleConfigs.icon"
          alt="" />
        <div>{{ moduleConfigs.name }}</div>
      </div>
      <Tabs
        v-model="inputSectionTab"
        :tabs="tabs"
        :is-objects="true"
        @input="scrollToSection" />
    </div>

    <div
      class="editor"
      @scroll="throttleScroll">
      <div
        id="layout-label"
        class="editor-section">
        <h2>layout label</h2>
        <div class="body">
          <b-field
            label="Title"
            class="input-field"
            message="">
            <b-input
              v-model="module.title"
              type="" />
          </b-field>
        </div>
      </div>

      <div
        id="filters"
        class="editor-section">
        <h2>filters</h2>
        <div class="body">
          <FilterConfigListEditor
            v-model="module.filters"
            class="editor-inputs" />
        </div>
      </div>

      <div
        id="table-styles"
        class="editor-section">
        <h2>table styles</h2>
        <div class="body">
          <TrackerTableStyle
            v-model="module.tracker_table_style"
            class="editor-inputs" />
        </div>
      </div>

      <div
        id="time-dimension"
        class="editor-section">
        <h2>time dimension</h2>
        <div class="body">
          <TrackerTableTimeDimension
            v-model="module.time_dimension"
            class="editor-inputs" />
        </div>
      </div>

      <div
        id="pivot-rows"
        class="editor-section">
        <h2>pivot rows</h2>
        <div class="body">
          <TrackerPivotRows
            v-model="module.tracker_pivot"
            class="editor-inputs" />
        </div>
      </div>

      <div
        id="total-column-headers"
        class="editor-section">
        <h2>Total column header</h2>
        <div class="body">
          <TrackerTotalHeaders
            v-model="module.tracker_total_headers"
            class="editor-inputs" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/pdf_report/Tabs.vue'
  import { throttle } from 'lodash'
  import TrackerTableStyle from '@/components/pdf_report/tracker_table_module/TrackerTableStyle.vue'
  import TrackerTotalHeaders from '@/components/pdf_report/tracker_table_module/TrackerTotalHeaders.vue'
  import TrackerTableTimeDimension from '@/components/pdf_report/tracker_table_module/TrackerTableTimeDimension.vue'
  import TrackerPivotRows from '@/components/pdf_report/tracker_table_module/TrackerPivotRows.vue'
  import FilterConfigListEditor from '@/components/reports/config/modules/common/FilterConfigListEditor'

  export default {
    name: 'TrackerTableEditorComp',
    components: {
      TrackerTableStyle,
      TrackerTotalHeaders,
      TrackerTableTimeDimension,
      TrackerPivotRows,
      Tabs,
      FilterConfigListEditor,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        moduleConfigs: {
          name: 'Tracker Table',
          type: 'TrackerTableModule',
          icon: require('@/assets/icons/tracker-table.svg'),
        },
        inputSectionTab: 'layout-label',
      }
    },

    computed: {
      module: {
        get () {
          return this.value || {}
        },
        set (value) {
          this.$emit('input', value)
        },
      },

      throttleScroll () {
        return throttle(this.onScroll, 100)
      },

      tabs () {
        return [
          {
            label: 'Layout Label',
            id: 'layout-label',
            hasErrors: false,
          },
          {
            label: 'Filters',
            id: 'filters',
            hasErrors: this.module?.filters?.some((f) => f.errors),
          },
          {
            label: 'Table Styles',
            id: 'table-styles',
            hasErrors: this.module?.tracker_table_style?.errors,
          },
          {
            label: 'Time Dimension',
            id: 'time-dimension',
            hasErrors: this.module?.time_dimension?.errors,
          },
          {
            label: 'Pivot Rows',
            id: 'pivot-rows',
            hasErrors: this.module?.tracker_pivot?.errors,
          },
          {
            label: 'Total Column Headers',
            id: 'total-column-headers',
            hasErrors: this.module?.tracker_total_headers?.errors,
          },
        ]
      },
    },
    mounted () {},

    beforeDestroy () {},

    methods: {
      onScroll (event) {
        const sectionsByDistanceFromTop = this.tabs.map((section) => {
          const el = document.getElementById(section.id)
          if (!el) return
          const rect = el.getBoundingClientRect()
          const scrollableDivRect = event.target.getBoundingClientRect()
          return {
            section: section.id,
            top: Math.abs(rect.top - scrollableDivRect.top),
          }
        })

        const sortedSections = sectionsByDistanceFromTop.sort(
          (a, b) => a.top - b.top
        )
        this.inputSectionTab = sortedSections[0].section
      },
      scrollToSection (sectionId) {
        const element = document.getElementById(sectionId)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', })
        }
      },
      removeReport (key) {
        this.$emit('remove', key)
      },

      tabSelection (tab) {
        this.inputSectionTab = tab
      },

      showModulePicker () {},

      makeInputsDirty () {},

      selectModule () {},

      openTrackerTableModule () {},

      // showModulePicker () {

      // },
    },
  }
</script>

<style lang="scss" scoped>
.table-editor-container {
  width: 100%;
  height: 100%;
  margin: auto !important;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.widget-type {
  display: flex;
  font-weight: 400;
  font-size: 15px;
  align-items: center;
  img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border: $border-1;
    border-radius: 8px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.editor {
  padding: 20px;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-direction: column;

  .editor-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    h2 {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    .body {
      border: $border-1;
      border-radius: 10px;
      padding: 18px;
    }
  }
}

.editor-header {
  border-bottom: $border-1;
  padding: 20px;

  .widget-type {
    display: flex;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    margin-bottom: 15px;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border: $border-1;
      border-radius: 8px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
