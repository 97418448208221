<template>
  <div>
    <div v-if="long">
      {{ range }}
    </div>
    <div v-else>
      {{ dimension.name }} ({{ range }})
    </div>
  </div>
</template>

<script>

  import { formatDateTime, formatDifference } from '@/utils/Date'

  export default {
    name: 'DateTimeFilterSummary',
    props: {
      filter: { type: Object, default: null, },
      long: { type: Boolean, default: false, },
      customDimension: { type: Object, default: null, },
    },
    computed: {
      dimension () {
        return this.customDimension || this.$store.getters['metadata/dimensions'][this.filter.dimension]
      },
      range () {
        if (this.long) {
          return `${formatDateTime(this.filter.args.start)} – ${formatDateTime(this.filter.args.end)}`
        } else {
          return `${formatDifference(this.filter.args.start, this.filter.args.end)}`
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
