import Vue from 'vue'
import Vuex from 'vuex'
import { dashboardsModel } from '@/models/dashboards/dashboardsModel'
import { metadataModel } from '@/models/data/metadataModel'
import { notificationsModel } from '@/models/notifications/notificationsModel'
import { datePickerModel } from '@/models/dates/datePickerModel'
import { authModel } from '@/models/auth/authModel'
import { routerModel } from '@/models/router/routerModel'
import { expressionsModel } from '@/models/reporting/expressionsModel'
import { entitiesModel } from '@/models/entities/entitiesModel'
import { controlsModel } from '@/models/controls/controlsModel'
import { apiStore } from '@/vuex/api/apiStore'
import { appStore } from '@/vuex/app/appStore'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
      initialized: false,
      isLoading: false,

      temp: {},
    },
    modules: {
      dashboards: dashboardsModel,
      metadata: metadataModel,
      datePicker: datePickerModel,
      auth: authModel,
      router: routerModel,
      expressions: expressionsModel,
      entities: entitiesModel,
      controls: controlsModel,
      notifications: notificationsModel,
      api: apiStore,
      app: appStore,
    },
  getters: {
    companyId (state, getters) {
      return getters['router/params'].company_id
    },
    routeQuery (state, getters) {
      return getters['router/route'].query
    },
    initialized (state) {
      return state.initialized
    },
    isLoading (state) {
      return state.isLoading
    },
  },
  mutations: {
    setInitialized (state, initialized) {
      state.initialized = initialized
    },
    setLoading (state, status) {
      state.isLoading = status
    },
  },
  actions: {
    async updateCompanyId (context, companyId) {
      let datesPromise = context.dispatch('datePicker/applyCompanyId', companyId)
      await datesPromise
    },
    async initialize (context, companyId) {
      context.commit('setInitialized', false)
      let authInit = context.dispatch('auth/initialize')
      await authInit
      let metadata_init = context.dispatch('metadata/loadMetadata', companyId)
      let datePickerInit = context.dispatch('datePicker/initialize')
      let expressionTypesInit = context.dispatch('metadata/loadExpressionTypes')
      let dashboardsInit = context.dispatch('dashboards/loadDashboards')
      let dashboardReportsInit = context.dispatch('app/dashboardReports/refresh')
      await expressionTypesInit
      await metadata_init
      await datePickerInit
      await dashboardsInit
      await dashboardReportsInit
      context.commit('setInitialized', true)
    },
  },
})
