<template>
  <div class="company-expression-editor">
    <CompanyObjectEditor
      v-model="vModel"
      object-type="Expression">
      <template #header-actions>
        <slot name="header-actions" />
      </template>
      <template #object-editor>
        <ExpressionEditor
          v-model="expression"
          v-bind="subObjectBinds" />
      </template>
    </CompanyObjectEditor>
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyObjectEditor from '@/components/reports/config/common/CompanyObjectEditor'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'CompanyExpressionEditor',
    components: {
      CompanyObjectEditor,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    computed: {
      ...mapChildren({
        expression: 'expression',
      }),
    },
  }

</script>

<style lang="scss" scoped>

.company-expression-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}

</style>
