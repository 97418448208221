import { shortFormatAmount, formatAmount, shortFormatNumber, formatNumber } from '@/utils/Format.js'
import { formatHours, formatDate, formatDateTime, formatTime } from '@/utils/Date.js'

export default {
  shortFormatAggVal (aggregate, val) {
    if (!aggregate) {
      return ''
    }
    if (aggregate.data_type.key == 'money') {
      return shortFormatAmount(val)
    }
    if (aggregate.data_type.key == 'percent') {
      if (val === '' || val === null || val === undefined) {
        return ''
      }
      return `${shortFormatNumber(val * 100)}%`
    } else if (aggregate.data_type.key == 'hours') {
      return formatHours(val)
    } else if (aggregate.data_type.key == 'number') {
      return shortFormatNumber(val)
    } else {
      return this.formatAggVal(aggregate, val)
    }
  },
  formatAggVal (aggregate, val) {
    if (!aggregate) {
      return ''
    }
    else if (aggregate.data_type.key == 'money') {
      return formatAmount(val)
    }
    else if (aggregate.data_type.key == 'percent') {
      if (val === '' || val === null || val === undefined) {
        return ''
      }
      return `${formatNumber(val * 100)}%`
    } else if (aggregate.data_type.key == 'hours') {
      return formatHours(val)
    } else if (aggregate.data_type.key == 'date') {
      return formatDate(val)
    } else if (aggregate.data_type.key == 'time') {
      return formatTime(val)
    } else if (aggregate.data_type.key == 'datetime') {
      return formatDateTime(val)
    } else if (aggregate.data_type.key == 'entity') {
      return val.name
    } else if (aggregate.data_type.key == 'id') {
      return val
    } else if (aggregate.data_type.key == 'string') {
      return val
    } else if (aggregate.data_type.key == 'boolean') {
      return val
    } else if (aggregate.data_type.key == 'number') {
      return formatNumber(val)
    } else {
      throw `Unknown Data Type: ${aggregate.data_type.key}`
    }
  },
  getDimensionKey (dimensions, datum) {
    return dimensions.map(d => datum[`${d.idx}`])
  },
  getDimensionOrder (dimensions, datum) {
    return dimensions.map(d => datum[`${d.key}`])
  },
  getDimensionVal (dimensions, datum) {
    return dimensions.map(d => this.shortFormatAggVal(d, datum[d.key])).join('–')
  },
}
