<template>
  <div class="inline-expression">
    <div
      v-if="data.on === 'field'"
      class="flexing-row">
      <div
        v-if="data.operation"
        class="arrigate-type-label cells">
        {{ data.operation.replaceAll("_", " ") }} of
      </div>
      <div
        class="inline-field cells"
        :class="{ curved: !data.operation }">
        Field
      </div>
      <div class="cells">
        {{ data.target }}
      </div>
    </div>

    <div
      v-else-if="data.on === 'value'"
      class="flexing-row">
      <div class="inline-field-value cells">
        {{ data.valueDataType ? data.valueDataType : data.valueType }}
      </div>
      <div class="cells">
        {{ data.value }}
      </div>
    </div>

    <CompanyExpressionNode
      v-else-if="data.on === 'company_expression'"
      :data="data" />
    <EntityAttributeNode
      v-else-if="data.on === 'entity_attribute'"
      :data="data" />

    <div
      v-if="data.type === 'error'"
      class="error-node has-text-danger-dark">
      ☠️ Invalid Sub-Expression
    </div>
  </div>
</template>

<script>
  import EntityAttributeNode from '@/components/expression_viewer/EntityAttributeNode'
  import CompanyExpressionNode from '@/components/expression_viewer/CompanyExpressionNode'
  export default {
    name: 'ExpressionFilters',
    components: {
      EntityAttributeNode,
      CompanyExpressionNode,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data () {
      return {}
    },

    async mounted () {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
.flexing-row {
  border: 1px solid $grayscale-6;
  border-radius: 7px;
  background-color: white;
}
.arrigate-type-label {
  text-transform: capitalize;
  color: #5298ad;
  border-right: 1px solid $grayscale-6;
  background: #eff6f9;
  border-radius: 7px 0 0 7px;
}

.cells {
  padding: 9px 14px;
}
.inline-field {
  background: #f0eff6;
  border-right: 1px solid $grayscale-6;
  color: #5c5487;
  text-transform: capitalize;

  .curved {
    border-radius: 7px 0 0 7px;
  }
}

.inline-field-value {
  background: #f0eff6;
  color: #5c5487;
  border-right: 1px solid $grayscale-6;
  text-transform: capitalize;
  border-radius: 7px 0 0 7px;
}
</style>
