<template>
  <div class="summary">
    <div class="filters">
      <div
        v-for="filter in dashboardSection.controls"
        :key="filter.key"
        class="filter">
        <DashboardControlFilters
          v-model="sectionDataContext"
          :filter-meta="filter" />
      </div>
    </div>

    <section
      v-for="section in dashboardSection?.sub_sections"
      :key="section.id">
      <h2>{{ section.name }}</h2>
      <div class="metrics">
        <div class="columns is-multiline">
          <template v-for="mod in section?.modules">
            <div
              :key="mod?.id"
              :class="[
                'column',
                {
                  // 'is-full': true,
                  'is-full': ['TableModuleConfig', 'CategoryLineChart', 'PerformanceBarChart'].includes(mod.type),
                  'is-4': mod.type === 'KpiModuleConfig',
                },
              ]">
              <!-- <tableModule
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey" /> -->
              <kpiModule
                v-if="mod.type === 'Kpi'"
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey" />

              <tableModule
                v-if="mod.type === 'TableModuleConfig'"
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey" />

              <graphModule
                v-if="['CategoryLineChart', 'PerformanceBarChart'].includes(mod.type)"
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey"
                :type="mod.type === 'PerformanceBarChart' ? 'bar' : 'line'" />
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import kpiModule from './modules/kpi/KPIModule.vue'
  import tableModule from './modules/table/TableModule.vue'
  import DashboardControlFilters from './filters/DashboardControlFilters.vue'
  import graphModule from './modules/graphs/GraphModule.vue'
  export default {
    components: {
      kpiModule,
      tableModule,
      DashboardControlFilters,
      graphModule,
    },
    props: {
      dashboardSection: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        sectionDataContext: {
          control_selections: {},
        },
      }
    },

    computed: {
      mergedDataContext () {
        const newDataContext = { ...this.dashboardDataContext, }
        newDataContext.control_selections = {
          ...this.dashboardDataContext.control_selections,
          ...this.sectionDataContext.control_selections,
        }
        return newDataContext
      },
    },
  }
</script>
<style scoped>
section {
  border-bottom: 1px solid #d2d2d4;
  padding: 20px 16px;
  padding-bottom: 32px;
  &:last-child {
    border-bottom: none;
  }
}

.summary {
  h2 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #7e7d84;
  }

  .metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
  }
}

.filters {
  display: flex;
  border-bottom: 1px solid #d2d2d4;

  .filter {
    flex: 1;
    border-right: 1px solid #d2d2d4;
    &:last-child {
      border-right: none;
    }
  }
}
</style>
