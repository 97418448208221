<template>
  <div class="dimension-filter-select">
    <entity-filter
      v-if="dimension.type == 'entity'"
      :dimension="dimension"
      :options="internalOptions"
      :loading="loading"
      :filter.sync="filterModel" />
    <number-filter
      v-else-if="dimension.type == 'number'"
      :dimension="dimension"
      :loading="loading"
      :filter.sync="filterModel" />
    <money-filter
      v-else-if="dimension.type == 'money'"
      :dimension="dimension"
      :loading="loading"
      :filter.sync="filterModel" />
    <boolean-filter
      v-else-if="dimension.type == 'boolean'"
      :dimension="dimension"
      :loading="loading"
      :filter.sync="filterModel" />
    <date-filter
      v-else-if="dimension.type == 'date'"
      :dimension="dimension"
      :loading="loading"
      :filter.sync="filterModel" />
    <date-time-filter
      v-else-if="dimension.type == 'datetime'"
      :dimension="dimension"
      :loading="loading"
      :filter.sync="filterModel"
      :opens="opens" />
  </div>
</template>

<script>
  import { filterService } from '@/service/data/filterService'

  import NumberFilter from '@/components/controls/filter/NumberFilter'
  import MoneyFilter from '@/components/controls/filter/MoneyFilter'
  import BooleanFilter from '@/components/controls/filter/BooleanFilter'
  import EntityFilter from '@/components/controls/filter/EntityFilter'
  import DateFilter from '@/components/controls/filter/DateFilter'
  import DateTimeFilter from '@/components/controls/filter/DateTimeFilter'

  export default {
    name: 'DimensionFilterSelect',
    components: {
      NumberFilter,
      MoneyFilter,
      BooleanFilter,
      EntityFilter,
      DateFilter,
      DateTimeFilter,
    },
    props: {
      dimension: { type: Object, required: true, },
      filter: { type: Object, default: null, },
      otherFilters: { type: Array, default: null, },
      opens: { type: String, default: 'center', },
      options: { type: Array, default: null, },
      customFilterService: { type: Object, default: null, },
    },
    data () {
      return {
        loading: false,
        internalOptions: [],
      }
    },
    computed: {
      filterModel: {
        get () {
          return this.filter
        },
        set (filter) {
          this.$emit('update:filter', filter)
        },
      },
    },
    watch: {
      otherFilters (otherFilters, old) {
        if (JSON.stringify(old) != JSON.stringify(otherFilters)) {
          this.init(true)
        }
      },
    },
    mounted () {
      this.init()
    },
    methods: {
      async init (refresh) {
        this.loading = true
        if (this.dimension.type == 'entity') {
          await this.loadOptions()
        }
        if (!refresh && !!this.filter && !!this.filter.type) {
          return
        }
        let start = new Date()
        let end = new Date()
        switch (this.dimension.type) {
          case 'entity':
            this.filterModel = {
              dimension: this.dimension.key,
              type: 'in',
              args: { values: this.internalOptions, },
            }
            break
          case 'number':
            this.filterModel = {
              dimension: this.dimension.key,
              type: 'between',
              args: { start: 0, end: 1000000, },
            }
            break
          case 'money':
            this.filterModel = {
              dimension: this.dimension.key,
              type: 'between',
              args: { start: 0, end: 1000000, },
            }
            break
          case 'boolean':
            this.filterModel = {
              dimension: this.dimension.key,
              type: 'in',
              args: { values: [true, false,], },
            }
            break
          case 'date':
            start.setDate(start.getDate() - 7)
            this.filterModel = {
              dimension: this.dimension.key,
              type: 'between',
              args: { start, end: new Date(), },
            }
            break
          case 'datetime':
            start.setHours(0, 0, 0, 0)
            start.setDate(end.getDate() - 1)
            this.filterModel = {
              dimension: this.dimension.key,
              type: 'between',
              args: { start, end: new Date(), },
            }
            break
        }
        this.loading = false
      },
      async loadOptions (trys = 0) {
        try {

          this.internalOptions =
            this.options ||
            (await (this.customFilterService || filterService).loadOptions(
              this.dimension,
              this.otherFilters || [],
              this.$route.params.company_id
            ))
        }
        catch (e) {
          //retry api
          if (trys < 2) {
            await this.loadOptions(trys + 1)
          }
          else {
            this.$buefy.toast.open({
              message: `Error Fetching Options for ${this.dimension.name}, Please reload the page`,
              type: 'is-warning',
            })
          }

        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.dimension-filter-select {
  position: relative;
}

.dropdown-items {
  max-height: 30vh;
  padding: 0 8px;
}

.select-buttons {
  display: flex;
  justify-content: flex-start;
  background: none;

  & * {
    white-space: nowrap;
  }
}

.dropdown-item {
  box-sizing: border-box;
}

.finish-buttons {
  display: flex;
  justify-content: flex-end;
}

.search-container {
  padding: 8px;
}
</style>
