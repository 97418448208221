<template>
  <div
    class="dropdown-filter"
    tabindex="1"
    @keydown.enter="() => $refs['filter'].toggle()">
    <b-dropdown
      ref="filter"
      aria-role="menu"
      expanded
      min-height="auto"
      :loading="loading"
      :disabled="disabled">
      <template #trigger="{ active }">
        <div
          class="filter-button"
          :class="{ 'no-border': noBorder }"
          role="button">
          <i
            v-if="icon"
            class="material-icons icon">
            {{ icon }}
          </i>
          <div class="button-content">
            <div
              v-if="loading"
              class="loading-message">
              Loading
            </div>
            <div
              v-else
              class="button-value">
              {{ selection ? selection[label] : "Select an option" }}
            </div>
          </div>
          <b-icon
            class="icon right"
            :icon="active ? 'menu-up' : 'menu-down'" />
        </div>
      </template>

      <b-dropdown-item
        aria-role="menu-item"
        custom
        paddingless>
        <form>
          <input
            v-model="searchInput"
            v-if="enableSearch"
            type="text"
            class="input search-input"
            placeholder="Search"
            @keydown.enter.prevent />
          <div
            class="dropdown-content"
            :class="{ 'is-loading': loading }">
            <template v-if="hasOptionGroups">
              <div
                v-for="(group, j) in groupOptions"
                :key="j"
                class="grouped-options">
                <h4 class="group-label">
                  {{ group.group }}
                </h4>
                <div
                  v-for="(option, i) in group.items"
                  :key="i"
                  tabindex="0"
                  class="dropdown-option"
                  :class="{
                    'is-selected':
                      selection && selection[trackBy] == option[trackBy],
                  }"
                  @keydown.enter.stop="selectOption(option)"
                  @click="selectOption(option)">
                  {{ option[label] }}
                </div>
              </div>
            </template>

            <template v-else>
              <div
                v-for="(option, i) in resultsToShow"
                :key="i"
                tabindex="0"
                class="dropdown-option"
                :class="{
                  'is-selected':
                    selection && selection[trackBy] == option[trackBy],
                }"
                @keydown.enter.stop="selectOption(option)"
                @click="selectOption(option)">
                {{ option[label] }}
              </div>
            </template>

            <div
              v-if="searchInput && resultsToShow.length === 0"
              class="no-results">
              No results
            </div>
          </div>
          <slot name="reload" />
        </form>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'

  export default {
    name: 'SingleSelect',
    props: {
      loading: { type: Boolean, default: false, },
      disabled: { type: Boolean, default: false, },
      options: { type: Array, required: true, default: () => [], },
      hasOptionGroups: { type: Boolean, default: false, },
      returnAsObject: { type: Boolean, default: true, }, // this prop lets you return the whole object instead of just the id of the selected option
      enableSearch: { type: Boolean, default: true, },
      value: {
        type: [Object, String, Number,],
        default: null,
      },

      icon: { type: String, default: '', },

      label: { type: String, default: 'name', },
      trackBy: { type: String, default: 'id', },
      noBorder: { type: Boolean, default: false, },
    },
    data () {
      return {
        searchInput: '',
      }
    },

    computed: {
      searcher () {
        const searcher = new FuzzySearch(this.options, [this.label,])
        return searcher
      },

      groupOptions () {
        if (this.hasOptionGroups) {
          return this.resultsToShow.reduce((acc, item) => {
            // If the group doesn't exist in the accumulator, create it
            if (!acc[item.group]) {
              acc[item.group] = {
                group: item.group,
                items: [],
              }
            }
            // Add the item to the group
            acc[item.group].items.push(item)
            return acc
          }, {})
        }
        return this.resultsToShow
      },

      resultsToShow () {
        if (this.searchInput) {
          return this.searcher.search(this.searchInput)
        }
        return this.options
      },
      selection () {
        if (this.returnAsObject && typeof this.value === 'object') {
          return this.value
        } else {
          return this.options?.find(
            (option) => option[this.trackBy] == this.value
          )
        }
      },
    },

    methods: {
      selectOption (option) {
        if (this.returnAsObject) {
          this.$emit('input', option)
        } else {
          this.$emit('input', option[this.trackBy])
        }
        this.$refs['filter'].toggle()
      },
    },
  }
</script>

<style lang="scss" scoped>
.dropdown-filter {
  background-color: white;
}
.dropdown-option {
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: white;

  &:hover {
    background-color: $grayscale-7;
    cursor: pointer;
  }

  &.is-selected {
    background-color: $grayscale-6;
  }
}
.footer-actions {
  display: flex;
  padding: 4px;
  button {
    font-size: 12px;
    margin: 2px;
    width: 100%;
  }
}
.selected-checkbox {
  pointer-events: none;
}
.search-input {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;

  border-bottom: $border-1 !important;
  border-radius: 0;

  &:focus {
    box-shadow: none !important;
  }
}

.dropdown-content {
  max-height: 400px;
  overflow-y: auto;

  &.is-loading {
    opacity: 0.5;
  }
}

.no-results {
  text-align: center;
  padding: 10px;
  opacity: 0.8;
}

.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &

  .icon {
    color: $gray-3;
    font-size: 18px !important;
    margin-right: 10px;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.grouped-options {
  border-bottom: $border-1;

  .dropdown-option {
    padding-left: 25px;
  }
  .group-label {
    font-weight: 600;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

form {
  border: 1px solid $gray-1;
}
</style>
