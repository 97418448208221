var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-item"},[_c('div',{staticClass:"item-header"},[_vm._m(0),_c('h3',[_vm._v(_vm._s(_vm.inputs.name))])]),_c('div',{staticClass:"tabs"},[_c('Tabs',{attrs:{"tabs":_vm.tabs},model:{value:(_vm.inputSectionTab),callback:function ($$v) {_vm.inputSectionTab=$$v},expression:"inputSectionTab"}})],1),(_vm.inputSectionTab === 'layout-label')?_c('div',{},[_c('b-field',{staticClass:"input-field",attrs:{"label":"Display Label","message":""}},[_c('b-input',{attrs:{"type":""},model:{value:(_vm.inputs.name),callback:function ($$v) {_vm.$set(_vm.inputs, "name", $$v)},expression:"inputs.name"}})],1)],1):_vm._e(),(_vm.inputSectionTab === 'measure')?_c('div',[_c('MeasureConfigEditor',{attrs:{"context":{
        reportDimensions: _vm.context.pivotDimension ? [ _vm.context.pivotDimension, ] : [],
      }},model:{value:(_vm.inputs.measure_config),callback:function ($$v) {_vm.$set(_vm.inputs, "measure_config", $$v)},expression:"inputs.measure_config"}})],1):_vm._e(),(_vm.inputSectionTab === 'format')?_c('div',[_c('DataFormatEditor',{model:{value:(_vm.inputs.data_format),callback:function ($$v) {_vm.$set(_vm.inputs, "data_format", $$v)},expression:"inputs.data_format"}})],1):_vm._e(),(_vm.inputSectionTab === 'benchmark')?_c('div',[_c('BenchmarkConfigEditor',{attrs:{"context":{
        parentMeasure: _vm.inputs.measure_config?.measure,
        reportDimensions: _vm.context.pivotDimension ? [ _vm.context.pivotDimension, ] : [],
      }},model:{value:(_vm.inputs.comparison_config),callback:function ($$v) {_vm.$set(_vm.inputs, "comparison_config", $$v)},expression:"inputs.comparison_config"}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button is-text drag"},[_c('img',{attrs:{"src":require("@/assets/icons/drag.svg"),"alt":""}})])
}]

export { render, staticRenderFns }