<template>
  <div class="p-3">
    hello
    {{ data }}
  </div>
</template>

  <script>

  export default {
    name: 'CompanyObjectVisualizer',
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        showVisualizer: false,
      }
    },
  }
  </script>

  <style lang="scss" scoped>

  </style>
