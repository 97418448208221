<template>
  <div class="">
    <div
      v-if="totalRows.length"
      class="items-container">
      <vuedraggable
        group="moduleCards"
        ghost-class="ghost"
        class="dropzone"
        :list="totalRows">
        <div
          v-for="(row, i) in totalRows"
          :key="i"
          class="tracker-row-item">
          <TotalRow
            v-model="totalRows[i]"
            @delete="removeRow(i)" />
        </div>
      </vuedraggable>
    </div>
    <button
      class="button add-row-button"
      @click.stop="addRow">
      <img
        src="@/assets/icons/add-row.svg"
        alt="" />

      <div>Add Pivot Total</div>
    </button>
  </div>
</template>

<script>
  import TotalRow from './TotalRow.vue'
  import vuedraggable from 'vuedraggable'
  import uniqid from 'uniqid'
  import {
    totalRowObject
  } from '@/components/pdf_report/table_module/modules.js'
  export default {
    name: 'TotalRows',
    components: {
      TotalRow,
      vuedraggable,
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {}
    },
    computed: {
      totalRows: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {},

    beforeDestroy () {},
    methods: {
      removeRow (index) {
        this.totalRows.splice(index, 1)
      },
      addRow () {
        this.totalRows.push({
          key: uniqid(),
          ...totalRowObject(),
        })
      },
    },
  }
</script>

<style lang="scss" scoped>

.items-container {
  background-color: #fbfbfb;
  padding: 5px 15px;

  border: $border-1;
  border-radius: 10px;

  height: 100%;
  display: flex;
  flex-direction: column;

  overflow: auto;
}

.add-row-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  justify-content: center;
  color: $primary !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 140, 132, 0.1) !important;
  background: rgba(25, 207, 198, 0.05) !important;
  justify-content: flex-start;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &:hover {
    color: $primary;
    border: 1px solid rgba(0, 140, 132, 0.1);
  }
}
</style>
