<template>
  <div class="validation-errors">
    <ul>
      <li
        v-for="error, idx in errors"
        :key="idx">
        {{ error.msg }}
      </li>
    </ul>
  </div>
</template>

<script>

  // TODO: Deprecate, use common component from @/components/ui/ValidationErrors

  export default {
    name: 'ValidationErrors',
    props: {
      errors: {
        type: Array,
        default: Array,
      },
    },
  }

</script>

<style lang="scss" scoped>
.validation-errors {
  display: flex;
  flex-direction: column;
  color: $ui-negative;
}
</style>
