<template>
  <b-select
    v-model="inputs"
    expanded
    aria-role="list">
    <option
      v-if="optional"
      :key="null"
      :value="null">
      Use report default
    </option>
    <option
      v-for="option in timeRangeOptions"
      :key="option.key"
      :value="option"
      aria-role="listitem">
      {{ option.name }}
    </option>
  </b-select>
</template>

<script>

  import ModuleConfigMixin from '@/components/reports/config/modules/common/ModuleConfigMixin'

  export default {
    name: 'TimeRangeEditor',
    mixins: [
      ModuleConfigMixin,
    ],
    props: {
      optional: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        defaultValue: null,
      }
    },
  }

</script>
