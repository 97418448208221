import { budgetsApi } from '@/vuex/api/budgets/budgetsApi'

export const forecastUploaderStore = {
  namespaced: true,
  state () {
    return {
      initialized: false,
      selectedUploadTypeOption: null,
      submitting: false,
      failedSubmit: false,
      failedUpload: false,
      uploading: false,
      file: null,
      submitResponse: null,
      validationResponse: null,
    }
  },
  getters: {
    initialized (state) {
      return state.initialized
    },
    uploadTypes (state, getters, rootState, rootGetters) {
      return rootGetters['api/budgets/uploadTypes'] || []
    },
    uploadTypeOptions (state, getters) {
      return getters.uploadTypes.map(uploadType => ({
          uploadType,
          label: uploadType.name, })
      )
    },
    selectedUploadTypeOption (state) {
      return state.selectedUploadTypeOption || {}
    },
    selectedUploadType (state, getters) {
      return getters.selectedUploadTypeOption.uploadType
    },
    uploading (state) {
      return state.uploading
    },
    file (state) {
      return state.file
    },
    validationResponse (state) {
      return state.validationResponse
    },
    submitResponse (state) {
      return state.submitResponse
    },
    submitting (state) {
      return state.submitting
    },
    failedSubmit (state) {
      return state.failedSubmit
    },
    failedUpload (state) {
      return state.failedUpload
    },
  },
  mutations: {
    setInitialized (state, initialized) {
      state.initialized = initialized
    },
    setSelectedUploadTypeOption (state, selectedUploadTypeOption) {
      state.selectedUploadTypeOption = selectedUploadTypeOption
    },
    setUploading (state, uploading) {
      state.uploading = uploading
    },
    setFile (state, file) {
      state.file = file
    },
    setValidationResponse (state, validationResponse) {
      state.validationResponse = validationResponse
    },
    setSubmitResponse (state, submitResponse) {
      state.submitResponse = submitResponse
    },
    setSubmitting (state, submitting) {
      state.submitting = submitting
    },
    setFailedSubmit (state, failedSubmit) {
      state.failedSubmit = failedSubmit
    },
    setFailedUpload (state, failedUpload) {
      state.failedUpload = failedUpload
    },
  },
  actions: {
    async initialize (context) {
      await context.dispatch('api/initialize')
      context.dispatch(
        'selectUploadTypeOption',
        context.getters.uploadTypeOptions.slice(0,1).shift())
      context.commit('setInitialized', true)
    },
    async submitFile (context) {
      context.commit('setSubmitting', true)
      context.commit('setFailedSubmit', false)
      try {
        let submitResponse = await context.dispatch(
          'api/submitUpload',
          {
            file: context.getters.file,
            uploadTypeKey: context.getters.selectedUploadType.key,
          }
        )
        context.commit('setSubmitResponse', submitResponse)
      } catch (e) {
        context.commit('setFailedSubmit', true)
      }
      context.commit('setSubmitting', false)
    },
    async uploadFile (context, file) {
      if (!file) {
        return
      }
      context.commit('setUploading', true)
      context.commit('setFailedUpload', false)
      try {
        let validationResponse = await context.dispatch(
          'api/validateUpload',
          {
            file,
            uploadTypeKey: context.getters.selectedUploadType.key,
          }
        )
        context.commit('setValidationResponse', validationResponse)
      } catch (e) {
        console.log(e)
        context.commit('setFailedUpload', true)
      }
      context.commit('setUploading', false)
      context.commit('setFile', file)
    },
    clearFile (context) {
      context.commit('setFailedUpload', false)
      context.commit('setValidationResponse', null)
      context.commit('setFile', null)
    },
    selectUploadTypeOption (context, uploadTypeOption) {
      context.dispatch('clearFile')
      context.commit('setSelectedUploadTypeOption', uploadTypeOption)
    },
    retry (context) {
      context.commit('setSubmitting', false)
      context.commit('setSubmitResponse', null)
      context.commit('setFailedSubmit', false)
    },
    reset (context) {
      context.commit('setSubmitting', false)
      context.commit('setSubmitResponse', null)
      context.commit('setValidationResponse', null)
      context.commit('setFailedSubmit', false)
      context.commit('setFile', null)
    },
  },
  modules: {
    api: budgetsApi,
  },
}
