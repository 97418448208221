<template>
  <ul class="node reverse arithmetic-container">
    <li>
      <div class="node reverse aritmetic-title">
        {{
          replaceActionNames[data.operation]
            ? replaceActionNames[data.operation]
            : data.operation.replaceAll("_", " ")
        }}
      </div>
      <ul>
        <li
          v-for="(item, i) in data.values"
          :key="i">
          <div class="node reverse">
            <tree-node :data="item" />
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'TreeArithmeticNode',

    props: {
      data: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data () {
      return {
        replaceActionNames: { sub: 'subtract', mult: 'multiply', div: 'divide', },
      }
    },
    beforeCreate: function () {
      this.$options.components.treeNode =
        require('@/components/expression_viewer/TreeNode').default
    },
  }
</script>

<style lang="scss" scoped>
.arithmetic-container {
  background-color: white;
  border: 2px dashed #bfbfbf;
  border-radius: 9px;
}

.aritmetic-title {
  text-transform: capitalize;
  color: #3873cd;
  background: #dff0ff;
  border: 1px solid #9cc3fc;
  border-radius: 12px;
  font-weight: 300;
  font-size: 16px;
  padding: 9px 14px;
  margin: 8px 0;
  min-width: 80px;
}
</style>
