<template>
  <div class="user-settings">
    <div class="container">
      <div class="page-title">
        <h2>My Profile</h2>

        <div
          v-if="isDirty"
          class="actions">
          <button
            class="button is-danger mr-3"
            @click="realoadUser">
            Discard Changes
          </button>

          <button
            class="button is-primary"
            @click="saveUserEdit">
            Save Changes
          </button>
        </div>
      </div>

      <div class="settings">
        <h3 class="section-name">
          Basic Info
        </h3>

        <div class="columns mx-0 is-multiline">
          <div class="column px-0 is-3">
            <b-field
              label="Name"
              class="field">
              <b-input v-model="nameModel" />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              label="Job Title"
              class="field">
              <b-input v-model="titleModel" />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              label="Email"
              class="field">
              <b-input
                :value="userEmail"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              label="Role"
              class="field">
              <b-input
                :value="userRole"
                disabled />
            </b-field>
          </div>
        </div>
      </div>
      <div class="settings">
        <h3 class="section-name">
          Actions
        </h3>

        <button class="button">
          <b-icon
            icon="lock-reset"
            class="mr-3" />
          Send Password Reset Link
        </button>
      </div>
      <div class="settings">
        <h3 class="section-name">
          Access
        </h3>

        <div class="columns mx-0 is-multiline">
          <div class="column px-0">
            <div class="permission-container">
              <h3>Permissions</h3>
              <template v-if="myRoles && myRoles.length > 0">
                <div
                  v-for="permission in myRoles"
                  :key="permission.id"
                  class="permission-item">
                  <div class="item-title">
                    {{ permission.name }}
                  </div>

                  <b-icon
                    class="item-icon"
                    :type="permission.active ? 'is-success' : ''"
                    :icon="
                      permission.active
                        ? 'checkbox-marked-circle'
                        : 'checkbox-blank-circle-outline'
                    " />
                </div>
              </template>
              <template v-else>
                <div class="permission-item">
                  No Reports
                </div>
              </template>
            </div>
          </div>
          <div class="column px-0 ml-4">
            <div class="permission-container">
              <h3>Reports</h3>
              <template v-if="myReports && myReports.length > 0">
                <div
                  v-for="permission in myReports"
                  :key="permission.id"
                  class="permission-item">
                  <div class="item-title">
                    {{ permission.name }}
                  </div>

                  <b-icon
                    class="item-icon"
                    type="is-success"
                    icon="checkbox-marked-circle
                  " />
                </div>
              </template>
              <template v-else>
                <div class="permission-item">
                  No Reports
                </div>
              </template>
            </div>
          </div>
          <div class="column px-0 ml-4">
            <div class="permission-container">
              <h3>Locations</h3>
              <template
                v-if="companyUser.locations && companyUser.locations.length > 0">
                <div
                  v-for="location of companyUser.locations"
                  :key="location.id"
                  class="permission-item">
                  <div class="item-title">
                    {{ location.display_name }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="permission-item">
                  No Locations
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false" />
  </div>
</template>

<script>
  import { userService } from '@/service/admin/userService'
  import { roleService } from '@/service/admin/roleService'

  export default {
    name: 'UserSettings',
    components: {},
    beforeRouteLeave (from, to, next) {
      if (this.isDirty) {
        this.$buefy.dialog.confirm({
          title: 'Unsaved Changes',
          container: '.user-settings',
          message: 'Are you sure you want to discard changes and leave page?',
          onConfirm: () => next(),
        })
      } else {
        next()
      }
    },
    data () {
      return {
        isLoading: false,
        isDirty: false,
        user: {},
        companyUser: {},
        permissions: [],
        reports: [],
        confirmingReset: false,
        confirmingLeave: false,
      }
    },
    computed: {
      userRole () {
        return (
          (this.companyUser &&
            this.companyUser.role &&
            this.companyUser.role.name) ||
          ''
        )
      },

      userEmail () {
        return this.user && this.user.email
      },
      myRoles () {
        const role = this.companyUser.role
        return this.permissions.filter((permission) => {
          return (
            role &&
            role.permission_groups.some((item) => item.id === permission.id)
          )
        })
      },

      myReports () {
        const role = this.companyUser.role
        return this.reports.filter((report) => {
          return role && role.reports.some((item) => item.id === report.id)
        })
      },

      nameModel: {
        get () {
          return this.user && this.user.name
        },
        set (name) {
          this.isDirty = true
          this.user = { ...this.user, name, }
        },
      },
      titleModel: {
        get () {
          return this.companyUser.job_title || ''
        },
        set (job_title) {
          this.isDirty = true
          this.companyUser = { ...this.companyUser, job_title, }
        },
      },
    },
    mounted () {
      this.$store.dispatch(
        'metadata/loadMetadata',
        this.$route.params.company_id
      )
      this.loadMeta()
    },
    methods: {
      realoadUser () {
        this.isLoading = true
        this.user = { ...this.$store.getters['auth/loggedInUser'], }
        this.companyUser = { ...this.$store.getters['auth/user/companyUser'], }
        this.isDirty = false
        this.isLoading = false
      },
      async saveUserEdit () {
        if (!this.user.name || this.user.name == '') {
        // add validation
        }
        this.isLoading = true
        await this.updateUser({
          ...this.user,
          company_users: [this.companyUser,],
        })
        await this.loadMeta()
        this.isDirty = false
      },
      confirmReset () {
        this.confirmingReset = true
      },
      cancelReset () {
        this.confirmingReset = false
      },
      async sendReset () {
        this.confirmingReset = false
        await this.$store.dispatch('auth/requestResetEmail', this.user.email)
        this.$store.dispatch('notifications/push', {
          icon: 'done',
          class: 'positive',
          label: 'Reset link sent',
        })
      },
      async updateUser (newUser) {
        await userService.updateUser(this.user.id, newUser)
      },
      async loadMeta () {
        this.isLoading = true
        let userPromise = this.$store.dispatch('auth/user/loadUser')

        let companyId = this.$route.params.company_id

        let permissionsPromise = roleService.loadPermissions(companyId)
        let reportsPromise = roleService.loadReports(companyId)

        this.permissions = await permissionsPromise
        this.reports = await reportsPromise
        await userPromise
        this.user = { ...this.$store.getters['auth/loggedInUser'], }
        this.companyUser = { ...this.$store.getters['auth/user/companyUser'], }

        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.page-title {
  margin-top: 50px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;

  h2 {
    font-weight: 400;
    font-size: 22px;
    height: 50px;
  }

  .actions {
    margin-left: auto;
  }
}

.permission-container {
  border: $border-1;
  border-radius: 5px;

  h3 {
    font-size: 15px;
    padding: 10px;
    font-weight: 500;
    background-color: $grayscale-8;
  }
  .permission-item {
    display: flex;
    font-size: 14px;
    border-top: $border-1;
    padding: 10px;
    align-items: center;
    .item-title {
      margin-right: auto;
    }

    .item-icon {
      margin: 0;
      opacity: 0.8;
    }
  }
}

.settings {
  border-bottom: $border-1;
  padding: 40px 0;
  .field {
    margin-right: 20px;
  }

  .section-name {
    font-size: 19px;
    margin-bottom: 20px;
  }
}
</style>
