import { apis } from '@/plugins/apis'

export const reportsConfigStore = {
  namespaced: true,
  state () {
    return {
      companyMeasures: [],
      companyDimensions: [],
      companyFilters: [],
      companyExpressions: [],
      configAnalysis: {},
      loading: false,
    }
  },
  getters: {
    loading (state) {
      return state.loading
    },
    companyMeasures (state) {
      return state.companyMeasures
    },
    companyDimensions (state) {
      return state.companyDimensions
    },
    companyExpressions (state) {
      return state.companyExpressions
    },
    companyFilters (state) {
      return state.companyFilters
    },
    companyMeasureOptions (state) {
      const options = {}
      for (const companyMeasure of state.companyMeasures) {
        options[companyMeasure.key] = companyMeasure
      }
      return options
    },
    companyDimensionOptions (state) {
      const options = {}
      for (const companyDimension of state.companyDimensions) {
        options[companyDimension.key] = companyDimension
      }
      return options
    },
    companyFilterOptions (state) {
      const options = {}
      for (const companyFilter of state.companyFilters) {
        options[companyFilter.key] = companyFilter
      }
      return options
    },
    companyExpressionOptions (state) {
      const options = {}
      for (const companyExpression of state.companyExpressions) {
        options[companyExpression.key] = companyExpression
      }
      return options
    },
  },
  mutations: {
    async setLoading (state, loading) {
      state.loading = loading
    },
    async setCompanyMeasures (state, companyMeasures) {
      state.companyMeasures = companyMeasures
    },
    async setCompanyDimensions (state, companyDimensions) {
      state.companyDimensions = companyDimensions
    },
    async setCompanyExpressions (state, companyExpressions) {
      state.companyExpressions = companyExpressions
    },
    async setCompanyFilters (state, companyFilters) {
      state.companyFilters = companyFilters
    },
    async setAnalysis (state, analysis) {
      state.configAnalysis = analysis
    },
  },
  actions: {
    async refreshOptions (context) {
      context.commit('setLoading', true)
      const measuresFuture = apis.companyConfigs.companyMeasure.list()
      const dimensionsFuture = apis.companyConfigs.companyDimension.list()
      const expressionsFuture = apis.companyConfigs.companyExpression.list()
      const filtersFuture = apis.companyConfigs.companyFilter.list()

      // TODO: handle refresh errors
      const companyMeasures = await measuresFuture
      const companyDimensions = await dimensionsFuture
      const companyExpressions = await expressionsFuture
      const companyFilters = await filtersFuture

      context.commit('setCompanyMeasures', companyMeasures)
      context.commit('setCompanyDimensions', companyDimensions)
      context.commit('setCompanyExpressions', companyExpressions)
      context.commit('setCompanyFilters', companyFilters)

      context.commit('setLoading', false)
    },
  },
}
