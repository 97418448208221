<template>
  <div
    v-if="cellData"
    class="table-cell"
    :class="{
      red: cellData?.quality === 'bad',
      green: cellData?.quality === 'good',
    }">
    {{ cellData.value }}

    <i
      v-if="cellData.direction !== 'none'"
      class="material-icons arrow-icon">
      {{
        cellData.direction === "up"
          ? "arrow_upward"
          : "arrow_downward"
      }}
    </i>
  </div>
</template>

<script>
  export default {
    name: 'TableCell',
    inject: ['getRowCell',],
    props: {
      column: {
        type: Object,
        required: true,
      },
      row: {
        type: Number,
        required: true,
      },
    },
    computed: {
      cellData () {
        return this.getRowCell(this.row, this.column.row_idx) || {}
      },

      isIncrease () {
        return this.cellData.value > 0
      },
    },
  }
</script>

<style scoped>
.table-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border-right: 1px solid #e9e9ea;
  border-bottom: 1px solid #e9e9ea;
  text-align: center;
  padding: 0 10px;
  background-color: #fff;

  &.green {
    color: #008c84;
    background-color: #f2f9f9;
  }
  &.red {
    color: #ff0000;
    background-color: #f9f2f2;
  }

  .arrow-icon {
    font-size: 16px;
    margin-left: auto;
  }
}
</style>
