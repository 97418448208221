<template>
  <div class="section-divider-view">
    <div class="name">
      <div
        v-if="!!betaSpec && !!betaSpec.title_icon"
        class="icon">
        <span
          class="material-icons"
          style="font-size: 17px"> {{ betaSpec.title_icon }} </span>
      </div>
      <input
        v-if="isEdit"
        v-model="nameModel"
        placeholder="Module name" />
      <div v-else>
        {{ nameModel }}
      </div>
    </div>
    <div class="controls">
      <slot name="moduleControls" />
    </div>
  </div>
</template>

<script>

  export default {
    name: 'SectionDividerView',
    props: {
      betaSpec: { type: Object, default: null, },
      name: { type: String, default: '', },
      isEdit: { type: Boolean, default: false, },
    },
    computed: {
      nameModel: {
        get () {
          return this.name
        },
        set (name) {
          this.$emit('update:name', name)
        },
      },
    },
  }

</script>

<style lang="scss" scoped>
.section-divider-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  width: 100%;

  margin-top: 10px;
  border-top: solid $grayscale-6 1px;
  padding-left: 0;
  // padding-top: 50px !important;
  overflow: visible;
}

.icon {
  display: flex;
  align-items: center;
  margin: 0 2 * $gridBase;
}

.name {
  margin-left: -6 * $gridBase;
  color: $secondary-05;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div {
    font-size: $h1;
    font-weight: $emp;
  }

  input {
    text-align: center;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-left: 6 * $gridBase;
}

</style>

