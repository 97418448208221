<template>
  <div class="create-user">
    <template v-if="!loading">
      <reset-form
        :closeable="!success"
        @close="cancel">
        <template #header>
          Get Started
        </template>
        <template #body>
          <template v-if="success">
            <p class="success-body">
              Success!
            </p>
            Your password has been set. Please log in again.
            <div class="flex-row-center-right">
              <b-button
                class="green return-button"
                @click="cancel">
                <template #label>
                  Return to Login
                </template>
              </b-button>
            </div>
          </template>
          <template v-else>
            <new-password-form
              :busy="busy"
              :email="tokenEmail"
              @submit="onSubmit($event)">
              <template #body>
                <div class="password-body">
                  <p class="welcome">
                    Welcome to Ingest!
                  </p>
                  To activate your account, please create a password:
                </div>
              </template>
            </new-password-form>
          </template>
        </template>
      </reset-form>
    </template>
  </div>
</template>

<script>

  import axios from 'axios'
  import ResetForm from '@/components/authorization/ResetForm'
  import NewPasswordForm from '@/components/authorization/NewPasswordForm'
  import { validateEmail } from '@/utils/Validation'
  import { jwtDecode } from 'jwt-decode'

  export default {
    name: 'CreateUser',
    components: {
      ResetForm,
      NewPasswordForm,
    },
    data () {
      return {
        email: '',
        requested: false,
        busy: false,
        success: false,
        loading: true,
      }
    },
    computed: {
      tokenEmail () {
        return jwtDecode(this.resetToken).user.email
      },
      emailValid () {
        return validateEmail(this.email)
      },
      resetToken () {
        return this.$store.getters['auth/resetToken']
      },
    },
    mounted () {
      this.checkToken()
    },
    methods: {
      async checkToken () {
        let response = await axios.get(
          `${process.env.VUE_APP_ADMIN_API_URL}/confirm_password`,
          { headers: { authorization: this.resetToken, }, })
        if (!response.data.valid) {
          this.cancel()
        }
        this.loading = false
      },
      cancel () {
        this.$router.push({ name: 'branded_login', query: { ...this.$route.query, t: undefined, }, })
      },
      async onSubmit (newPass) {
        this.busy = true
        await axios.post(
          `${process.env.VUE_APP_ADMIN_API_URL}/confirm_password`,
          { password: newPass, },
          { headers: { authorization: this.resetToken, }, })
        this.success = true
        this.busy = false
      },
    },
  }

</script>

<style lang="scss" scoped>

.create-user {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $ui-brand-02;
  width: 100%;
  height: 100%;
}

.reset-form {
  width: 116 * $gridBase;
}

.email-input {
  margin-top: 12 * $gridBase;
  margin-bottom: 8 * $gridBase;
}

.return-button {
  margin-top: 12 * $gridBase;
}

.negative {
  color: $ui-negative;
}

.rerequest-button, .change-button {
  margin-top: 12 * $gridBase;
}

.password-body {
  margin-bottom: 12 * $gridBase;
}

.success-body {
  color: $ui-positive;
  margin-bottom: 8 * $gridBase;
}

.expiration-note {
  display: flex;
  align-items: center;
  margin-top: 4 * $gridBase;
}

.welcome {
  color: $ui-01;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 12 * $gridBase;
}

</style>
