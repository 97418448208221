<template>
  <div class="consolidate-value-input">
    <div class="dropdown-container">
      <multiselect
        v-model="selection"
        :allow-empty="false"
        :options="options"
        :searchable="true"
        label="label"
        deselect-label=""
        selected-label=""
        select-label=""
        track-by="id"
        placeholder="Select Mapped Entity"
        open-direction="bottom">
        <template
          slot="singleLabel"
          slot-scope="{ option }">
          {{ option.label }}
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'AttributeInput',
    components: {

    },
    props: {
      value: {
        type: [String, Number, null,],
        default: null,
        required: false,
      },
      options: {
        type: Array,
        required: true,
      },
      dirtyValue: {
        type: [String, Number,  null,],
        required: false,
        default: null,
      },
    },
    data () {
      return {

      }
    },
    computed: {
      selection: {
        get () {
          const value = this.dirtyValue || this.value
          return (
            this.options.find((option) => option.id === value)
          )
        },
        set (value) {
          this.$emit('input', value?.id)
        },
      },
    },

    mounted () {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
.consolidate-value-input {
  position: relative;
  width: 100%;
  height: 40px;

  .dropdown-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
}
</style>
