<template>
  <div class="users-library">
    <div class="container">
      <div class="container">
        <div class="header">
          <h2>Users</h2>
          <p>Manage Ingest users</p>
          <div class="control-bar">
            <div class="left">
              <router-link :to="{ name: 'create_user' }">
                <b-button
                  type="is-primary"
                  class="action-btn"
                  icon-left="plus">
                  Create User
                </b-button>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <div class="columns is-vcentered table-filters">
            <div class="column m-0 p-0 mr-4">
              <b-input
                v-model="reportSearchInput"
                custom-class="no-border"
                placeholder="Search..."
                type="search"
                icon="magnify" />
            </div>
          </div>
          <b-table
            :data="resultsToShow"
            :loading="isLoading"
            class="users-table">
            <b-table-column
              v-slot="props"
              sortable
              field="name"
              label="Name">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              sortable
              field="job_title"
              label="Job Title">
              {{ props.row.job_title }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              sortable
              field="email"
              label="Email">
              {{ props.row.email }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="locations"
              label="Locations">
              <b-dropdown
                :triggers="['hover']"
                aria-role="list">
                <template #trigger>
                  <b-button
                    class="location-dropdown-button unclickable"
                    type="is-light"
                    icon-right="menu-down">
                    <div>
                      {{ props.row.locations.length || "No" }} Locations
                    </div>
                  </b-button>
                </template>

                <b-dropdown-item
                  v-for="(location, i) in props.row.locations"
                  :key="i"
                  disabled
                  :focusable="false"
                  class="unclickable"
                  aria-role="listitem">
                  {{ location.display_name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>

            <b-table-column
              v-slot="props"
              sortable
              field="role.name"
              label="Role">
              {{ props.row.role.name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              sortable
              field="created_at"
              label="Created">
              {{ formatDate(props.row.created_at) }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              sortable
              field="last_logged_in"
              label="Last Seen">
              <div v-if="props.row.last_logged_in">
                {{
                  formatDifference(
                    new Date(),
                    new Date(props.row.last_logged_in)
                  )
                }}
                ago
              </div>

              <div v-else>
                Never
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="Actions"
              centered
              width="100"
              label="Actions">
              <div class="actions">
                <b-tooltip
                  label="Delete User"
                  position="is-bottom"
                  type="is-light">
                  <b-button
                    type="is-light"
                    class="action-btn"
                    icon-right="delete"
                    @click="deleteUser(props.row)" />
                </b-tooltip>
                <b-tooltip
                  label="Edit User"
                  position="is-bottom"
                  type="is-light">
                  <router-link
                    :to="{
                      name: 'edit_user',
                      params: { user_id: props.row.id },
                    }">
                    <b-button
                      type="is-light"
                      class="action-btn"
                      icon-right="lead-pencil" />
                  </router-link>
                </b-tooltip>
              </div>
            </b-table-column>

            <template #empty>
              <div class="has-text-centered mt-5 mb-5">
                <b-tag
                  v-if="!isLoading"
                  class="data-tags"
                  rounded
                  size="is-medium">
                  No Users Found 🥥
                </b-tag>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { userService } from '@/service/admin/userService'
  import { roleService } from '@/service/admin/roleService'
  import axios from 'axios'
  import { formatDifference, formatDate } from '@/utils/Date'

  import FuzzySearch from 'fuzzy-search'

  export default {
    name: 'UsersEditor',
    components: {},

    data () {
      return {
        selectedUser: null,
        addingUser: false,
        newUser: {},
        editingUser: false,
        editedUser: {},
        deletingUser: false,
        sendingInvite: false,
        confirmingInvite: false,
        confirmingDiscard: false,
        discardingEdit: false,
        dirty: false,
        searchTerm: '',
        order: { field: 'name', ascending: true, },

        isLoading: false,
        permissions: [],
        roles: [],
        reports: [],
        users: [],
        locations: [],

        reportSearchInput: '',
      }
    },

    computed: {
      searcher () {
        const searcher = new FuzzySearch(this.users, ['name', 'email',])
        return searcher
      },
      resultsToShow () {
        if (this.reportSearchInput) {
          return this.searcher.search(this.reportSearchInput)
        }
        return this.users
      },
    },
    mounted () {
      this.$store.dispatch(
        'metadata/loadMetadata',
        this.$route.params.company_id
      )
      this.loadMeta()
    },
    methods: {
      formatDifference,
      formatDate,
      async loadMeta () {
        this.isLoading = true
        let companyId = this.$route.params.company_id

        let locationsPromise = axios.get(
          `${process.env.VUE_APP_API_URL}/company/${companyId}/locations`,
          { headers: { authorization: this.$store.getters['auth/authToken'], }, }
        )

        this.permissions = await roleService.loadPermissions(companyId)
        this.roles = await roleService.loadPermissions(companyId)
        this.reports = await roleService.loadRoles(companyId)
        this.users = await userService.loadUsers(this.$route.params.company_id)
        this.locations = (await locationsPromise).data
        this.isLoading = false
      },

      async deleteUser (user) {
        this.$buefy.dialog.confirm({
          title: 'Delete User',
          container: '.users-library',
          message: `Are you sure you want to delete ${user.name}`,
          confirmText: 'Delete User',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true
            await userService.deleteUser(this.$route.params.company_id, user.id)
            this.users = await userService.loadUsers(
              this.$route.params.company_id
            )

            this.isLoading = false
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 20px 14px;

  .header {
    margin-top: 30px;
    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    p {
      color: $ui-03;
      font-size: 16px;
      margin-top: 14px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
      margin-top: 50px;
    }

    .right {
      margin-left: auto;
    }
  }

  .users-table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
  }

  .table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .data-tags {
    font-size: 12px;
    margin: 2px;
  }

  .location-dropdown-button {
    font-size: 14px;
  }

  .actions {
    display: flex;
    justify-content: center;

    .action-btn {
      margin: 0 5px;
    }
  }
}
</style>
