<template>
  <div class="cheque-search-filters">
    <div class="scrollable">
      <dimension-filter-select
        key="1"
        class="filter-input"
        :loading="loading"
        :custom-filter-service="chequeService"
        :dimension="restaurantDimension"
        :filter.sync="restaurantFilter" />

      <dimension-filter-select
        key="2"
        class="filter-input"
        :loading="loading"
        :custom-filter-service="chequeService"
        :dimension="dateDimension"
        :filter.sync="dateFilter" />

      <dimension-filter-select
        key="3"
        class="filter-input"
        :loading="loading"
        :custom-filter-service="chequeService"
        :dimension="mealPeriodDimension"
        :filter.sync="mealPeriodFilter" />

      <number-in-filter
        key="4"
        class="filter-input"
        :dimension="chequeNumberDimension"
        :filter.sync="chequeNumberFilter" />

      <dimension-filter-select
        key="5"
        class="filter-input"
        :loading="loading"
        :custom-filter-service="chequeService"
        :dimension="paymentMethodDimension"
        :filter.sync="paymentTypeFilter"
        :other-filters="[restaurantFilter].filter((filter) => filter != null)" />

      <number-in-filter
        key="6"
        class="filter-input"
        :loading="loading"
        :dimension="last4Dimension"
        :filter.sync="last4Filter" />

      <number-in-filter
        key="7"
        class="filter-input"
        :loading="loading"
        :custom-filter-service="chequeService"
        :dimension="paymentCommentDimension"
        :filter.sync="paymentCommentFilter" />

      <dimension-filter-select
        key="8"
        class="filter-input"
        :loading="loading"
        :custom-filter-service="chequeService"
        :dimension="paymentAmountDimension"
        :filter.sync="paymentAmountFilter" />

      <dimension-filter-select
        key="9"
        class="filter-input"
        :loading="loading"
        :custom-filter-service="chequeService"
        :dimension="revenueCenterDimension"
        :other-filters="restaurantFilter == null ? [] : [restaurantFilter]"
        :filter.sync="revenueCenterFilter" />
    </div>
    <div class="controls">
      <button
        class="button mr-2"
        @click="resetFilters()">
        Reset
      </button>
      <button
        class="button is-primary"
        :disabled="!dirty"
        @click="$emit('search')">
        Search
      </button>
    </div>
  </div>
</template>

<script>
  import DimensionFilterSelect from '@/components/controls/filter/DimensionFilterSelect'
  import NumberInFilter from '@/components/controls/filter/NumberInFilter'
  // import MoneyFilterSummary from '@/components/controls/filter/MoneyFilterSummary'
  // import NumberInFilterSummary from '@/components/controls/filter/NumberInFilterSummary'
  // import EntityFilterSummary from '@/components/controls/filter/EntityFilterSummary'
  // import DateFilterSummary from '@/components/controls/filter/DateFilterSummary'
  import { chequeService } from '@/service/cheque_search/chequeService'

  export default {
    name: 'ChequeSearchFilters',
    components: {
      DimensionFilterSelect,
      NumberInFilter,
    // EntityFilterSummary,
    // NumberInFilterSummary,
    // MoneyFilterSummary,
    // DateFilterSummary,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      dirty: {
        type: Boolean,
        default: false,
      },
      query: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        dateDimension: { type: 'date', name: 'Date', key: 'date', },
        mealPeriodDimension: {
          type: 'entity',
          name: 'Meal period',
          key: 'meal_period',
        },
        restaurantDimension: {
          type: 'entity',
          name: 'restaurant',
          key: 'restaurant',
        },
        chequeNumberDimension: {
          type: 'number',
          name: 'check number',
          key: 'cheque_number',
        },
        paymentMethodDimension: {
          type: 'entity',
          name: 'payment method',
          key: 'payment_method',
        },
        last4Dimension: { type: 'entity', name: 'cc last 4', key: 'cc_last_4', },
        paymentCommentDimension: {
          type: 'entity',
          name: 'payment comment',
          key: 'payment_comment',
        },
        paymentAmountDimension: {
          type: 'money',
          name: 'payment amount',
          key: 'payment_amount',
        },
        revenueCenterDimension: {
          type: 'entity',
          name: 'revenue center',
          key: 'revenue_center',
        },
        openFilter: 'restaurant',
      }
    },
    computed: {
      chequeService () {
        return chequeService
      },
      dateFilter: {
        get () {
          return this.query.dateFilter
        },
        set (dateFilter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, dateFilter, })
        },
      },
      mealPeriodFilter: {
        get () {
          return this.query.mealPeriodFilter
        },
        set (mealPeriodFilter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, mealPeriodFilter, })
        },
      },
      chequeNumberFilter: {
        get () {
          return this.query.chequeNumberFilter
        },
        set (chequeNumberFilter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, chequeNumberFilter, })
        },
      },
      restaurantFilter: {
        get () {
          return this.query.restaurantFilter
        },
        set (restaurantFilter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, restaurantFilter, })
        },
      },
      paymentTypeFilter: {
        get () {
          return this.query.paymentTypeFilter
        },
        set (paymentTypeFilter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, paymentTypeFilter, })
        },
      },
      last4Filter: {
        get () {
          return this.query.last4Filter
        },
        set (last4Filter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, last4Filter, })
        },
      },
      paymentCommentFilter: {
        get () {
          return this.query.paymentCommentFilter
        },
        set (paymentCommentFilter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, paymentCommentFilter, })
        },
      },
      paymentAmountFilter: {
        get () {
          return this.query.paymentAmountFilter
        },
        set (paymentAmountFilter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, paymentAmountFilter, })
        },
      },
      revenueCenterFilter: {
        get () {
          return this.query.revenueCenterFilter
        },
        set (revenueCenterFilter) {
          this.$emit('update:dirty', true)
          this.$emit('update:query', { ...this.query, revenueCenterFilter, })
        },
      },
    },
    methods: {
      resetFilters () {
        this.openFilter = 'restaurant'
        this.$emit('reset')
      },
    },
  }
</script>

<style lang="scss" scoped>
.filter-input {
  margin-bottom: 24px;
}
.cheque-search-filters {
  z-index: 1;
  .scrollable {
    overflow: auto;
    padding: 12px;
    padding-bottom: 0;
  }
  padding: 0px;
}
.b-button {
  padding: 4 * $gridBase;
  box-shadow: inset 0px 3px 3px -3px rgba($secondary-06, 0.5);
}
.dimension-filter-select {
  width: 100%;
}

.controls {
  display: flex !important;
  flex-direction: row;
  border-top: $border-1;
  background-color: #f5f5f5;
  padding: 10px;
  margin-top: auto;
  .button {
    width: 100%;
    display: inline;
  }
}
</style>
