export const routerModel = {
  namespaced: true,
  state () {
    return {
      route: null,
      router: null,
    }
  },
  getters: {
    route (state) {
      return state.route
    },
    params (state, getters) {
      return getters.route.params
    },
  },
  mutations: {
    setRouter (state, router) {
      state.router = router
    },
    setRoute (state, route) {
      state.route = route
    },
  },
  actions: {
    replace (context, path) {
      context.state.router.replace(path)
    },
    push (context, path) {
      context.state.router.push(path)
    },
    register (context, router) {
      context.commit('setRouter', router)
      router.beforeEach(
        async function (to, from, next) {
          context.commit('setRoute', to)

          const routeName = to.meta.displayName? to.meta.displayName + ' | Ingest' : 'Ingest'

          document.title = routeName
          next()
      })
    },
  },
}
