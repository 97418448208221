<template>
  <input
    key="input"
    v-model.lazy="model"
    type="time"
    class="time-input" />
</template>

<script>

  import inputMixin from '@/components/common/input/inputMixin.js'

  export default {
    name: 'TimeInput',
    mixins: [
      inputMixin,
    ],
  }

</script>

<style lang="scss" scoped>

.time-input {
  width: 32 * $remGrid;
}

</style>

