import axios from 'axios'

export const budgetsApi = {
  namespaced: true,
  state: {
    initialized: false,
    uploadTypes: null,
  },
  getters: {
    initialized (state) {
      return state.initialized
    },
    uploadTypes (state) {
      return state.uploadTypes
    },
  },
  mutations: {
    setInitialized (state, initialized) {
      state.initialized = initialized
    },
    setUploadTypes (state, uploadTypes) {
      state.uploadTypes = uploadTypes
    },
  },
  actions: {
    async initialize (context) {
      if (!context.getters.initialized) {
        let response = await axios.get(
          // TODO: should the API url be wrapped in a common getter?
          `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/budgets/upload_types`,
          {
          headers: { 'Content-Type': 'multipart/form-data',
              Authorization: context.rootGetters['api/authToken'],
            },
          }
        )
        context.commit('setUploadTypes', response.data)
        context.commit('setInitialized', true)
      }
    },
    async submitUpload (context, { file, uploadTypeKey, }) {
      let formData = new FormData()
      formData.append('upload', file)
      return (await axios.post(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/budgets/upload_types/${uploadTypeKey}/submit`,
         formData, {
           headers: {
             'Content-Type': 'multipart/form-data',
             Authorization: context.rootGetters['api/authToken'],
           },
         }
      )).data
    },
    async validateUpload (context, { file, uploadTypeKey, }) {
      let formData = new FormData()
      formData.append('upload', file)
      return (await axios.post(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/budgets/upload_types/${uploadTypeKey}/validate`,
         formData, {
           headers: {
             'Content-Type': 'multipart/form-data',
             Authorization: context.rootGetters['api/authToken'],
           },
         }
      )).data
    },
  },
  modules: {
  },
}
