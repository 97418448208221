<template>
  <div class="measure-filter-editor">
    <div class="ref-select">
      <MeasureEditor
        v-model="measure"
        v-bind="subObjectBinds" />
    </div>
    <b-select
      v-model="comparison"
      class="input-item">
      <option
        v-for="comparisonOption in comparisonOptions"
        :key="comparisonOption.key"
        :value="comparisonOption.key">
        {{ comparisonOption.label }}
      </option>
    </b-select>
    <validationErrors :errors="comparisonErrors" />
    <b-input
      v-model="filterValue"
      type="number" />
    <validationErrors :errors="filterValueErrors" />
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'MeasureFilterEditor',
    mixins: [
      ObjectEditorMixin,
    ],
    data () {
      return {
        comparisonOptions: [
          {
            key: 'is_more',
            label: '>',
          },
          {
            key: 'is_more_or_equal',
            label: '≥',
          },
          {
            key: 'is_less',
            label: '<',
          },
          {
            key: 'is_less_or_equal',
            label: '≤',
          },
          {
            key: 'is',
            label: '=',
          },
        ],
      }
    },
    computed: {
      ...mapChildren({
        measure: 'measure',
        comparison: 'comparison',
        filterValue: 'value',
      }),
    },
  }
</script>

<style lang="scss" scoped>
.measure-filter-editor {
  display: flex;
  flex-direction: column;
}
.ref-select {
  display: flex;
  flex-direction: row;
}
</style>
