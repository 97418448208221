<template>
  <div class="offset-comparison-options flex-column">
    <div class="flex-row">
      <input
        v-model="numDays"
        placholder="Enter amt"
        type="number" />
      days
    </div>
    <b-button
      v-if="numDays != ''"
      label="remove"
      class="secondary-06-text"
      @click="$emit('update:comparison', null)" />
  </div>
</template>

<script>

  export default {
    name: 'OffsetComparisonOptions',
    components: {

    },
    props: {
      comparison: {
        type: Object,
        default: null,
      },
    },
    computed: {
      numDays: {
        get () {
          if (this.comparison && this.comparison.type == 'offset') {
            return this.comparison.amount
          } else {
            return 364
          }
        },
        set (by) {
          if (by == '') {
            this.$emit('update:comparison', null)
          }
          this.$emit('update:comparison', { type: 'offset', dimension: 'date', amount: parseInt(by), })
        },
      },
    },
  }

</script>

<style lang="scss" scoped>

.offset-comparison-options {
}

</style>
