<template>
  <div
    class="error-node">
    <div class="has-text-danger-dark">
      ☠️ Invalid Sub-Expression <span>({{ data.nodeType }}) </span>
    </div>
    <div
      class="button is-text"
      @click="copyToCipboard">
      Copy Node Specs
      <b-icon
        v-if="errorNodeCopied"
        icon="check ml-5"
        size="is-small" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ExpressionErrorNode',
    props: {
      data: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data () {
      return {
        errorNodeCopied: false,
      }
    },

    async mounted () {},
    methods: {
      async copyToCipboard () {
        try {
          await navigator.clipboard.writeText(JSON.stringify(this.data.errorData))
          this.errorNodeCopied = true
        } catch (e) {
          console.log('error copying to clipboard')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.error-node {
  border: 1px solid $grayscale-6;
  border-radius: 7px;
  padding: 10px;
  background-color: white;
  .button.is-text {
    text-decoration: underline !important;
    font-size: 14px;
  }
}
</style>
