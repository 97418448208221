import axios from 'axios'
import { uniq } from 'lodash'

export const metadataModel = {
  namespaced: true,
  state () {
    return {
      loading: true,
      expressionTypes: {},
      companyMetadata: {},
      facts: [],
      sources: [],
    }
  },
  getters: {
    expressionTypes (state) {
      return state.expressionTypes
    },
    companyMetadata (state) {
      return state.companyMetadata
    },
    sources (state) {
      return state.sources
    },
    facts (state) {
      return state.facts
    },
    tables (state) {
      return uniq(state.facts.map((f) => f.table))
    },
    measures () {
      return {}
    },
    dimensions () {
      return {}
    },
  },
  mutations: {
    setAvailableDates (state, dates) {
      state.availableDates = dates
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setExpressionTypes (state, types) {
      state.expressionTypes = types
    },
    setFacts (state, facts) {
      state.facts = facts
    },
    setSources (state, sources) {
      state.sources = sources
    },
    setCompanyMetadata (state, metadata) {
      state.companyMetadata = metadata
    },
  },
  actions: {
    async loadExpressionTypes (context) {
      if (!context.rootGetters['auth/isLoggedIn']) {
        return
      }
      let expressionsFuture = axios.get(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/expressions`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      let schemaFuture = axios.get(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/reporting_schema`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('setExpressionTypes', (await expressionsFuture).data)
      let reporting_schema = (await schemaFuture).data
      context.commit('setFacts', reporting_schema.facts)
      context.commit('setSources', reporting_schema.sources)
    },
    async loadMetadata (context) {
      if (!context.state.loading || !context.rootGetters['auth/isLoggedIn']) {
        return
      }

      let companyMetadata = axios.get(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )

      let companyMetadataResponse = (await companyMetadata).data
      context.commit('setCompanyMetadata', companyMetadataResponse)

      context.commit('setLoading', false)
    },
  },
  modules: {},
}
