import { render, staticRenderFns } from "./OtherFilters.vue?vue&type=template&id=2425c8ed&scoped=true"
import script from "./OtherFilters.vue?vue&type=script&lang=js"
export * from "./OtherFilters.vue?vue&type=script&lang=js"
import style0 from "./OtherFilters.vue?vue&type=style&index=0&id=2425c8ed&prod&lang=scss&scoped=true"
import style1 from "./OtherFilters.vue?vue&type=style&index=1&id=2425c8ed&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2425c8ed",
  null
  
)

export default component.exports