import { getReport } from '@/service/data/reportService'

export const filterService = {
  async loadOptions (dimension, otherFilters, company_id) {
    let filters = [...otherFilters,]
    let response = await getReport(
      {
        columns: [{ name: dimension.key, expression: { type: 'group', field: dimension.key, }, }, ],
        order: [{ expression: { type: 'group', field: dimension.key, }, ascending: true, },],
        filters,
      }, company_id)
    return response.data
      .map(row => ({
        key: row[`${response.columns[0].idx}_key`],
        name: row[response.columns[0].idx],
      }))
      .filter(option => option != undefined)
  },
}
