<template>
  <div class="dimension-eq-condition-editor">
    <div v-if="loadingValues">
      Loading...
    </div>
    <template v-else>
      <div
        v-if="dimensionValues"
        class="ref-select">
        <b-select
          v-model="keyValue"
          placeholder="Select a Value..."
          class="input-item">
          <option
            v-for="dimensionValue in dimensionValues"
            :key="dimensionValue.key"
            :value="dimensionValue.key">
            {{ dimensionValue.value }}
          </option>
        </b-select>
        <ValidationErrors :errors="keyValueErrors" />
      </div>
      <div v-else>
        Please select a valid dimension!
      </div>
    </template>
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'DimensionEqConditionEditor',
    mixins: [
      ObjectEditorMixin,
    ],
    props: {
      context: {
        type: Object,
        default: Object,
      },
    },
    computed: {
      dimensionValues () {
        return this.context.dimensionValues || []
      },
      loadingValues () {
        return this.context.dimensionLoading || false
      },
      ...mapChildren({
        keyValue: 'key_value',
      }),
    },
  }

</script>

<style lang="scss" scoped>
.dimension-eq-condition-editor {
  display: flex;
  flex-direction: column;
}
</style>
