import { render, staticRenderFns } from "./LocationSettings.vue?vue&type=template&id=361ae05d&scoped=true"
import script from "./LocationSettings.vue?vue&type=script&lang=js"
export * from "./LocationSettings.vue?vue&type=script&lang=js"
import style0 from "./LocationSettings.vue?vue&type=style&index=0&id=361ae05d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361ae05d",
  null
  
)

export default component.exports