<template>
  <b-field
    class="attribute-input"
    :class="{ 'is-default-value': isDefaultValue }">
    <b-dropdown
      v-if="dataType === 'boolean'"
      v-model="value"
      expanded
      placeholder="Select a value">
      <template #trigger="{ active }">
        <button
          expaneded
          class="button dropdown-button is-fullwidth"
          type="is-light">
          <span class="button-label mr-auto">
            {{ value }}
          </span>
          <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
        </button>
      </template>
      <template v-for="option in booleanOptions">
        <b-dropdown-item
          :key="option"
          :value="option"
          class="is-capitalized"
          aria-role="listitem">
          {{ option }}
        </b-dropdown-item>
      </template>
    </b-dropdown>
    <b-numberinput
      v-else-if="dataType === 'percent'"
      v-model="value"
      :min-step="0.0000000000001"
      icon-right="percent"
      :controls="false"
      @focus="onInputFocus" />
    <b-numberinput
      v-else-if="dataType === 'number'"
      v-model="value"
      :min-step="0.0000000000001"
      icon="numeric"
      :controls="false"
      @focus="onInputFocus" />

    <b-numberinput
      v-else-if="dataType === 'money'"
      ref="moneyInput"
      v-model="value"
      :min-step="0.01"
      icon="currency-usd"
      :controls="false"
      @focus="onInputFocus" />

    <b-input
      v-else-if="dataType === 'string'"
      v-model="value"
      icon="alphabetical-variant"
      :controls="false"
      @focus="onInputFocus" />

    <b-timepicker
      v-else-if="dataType === 'time'"
      v-model="value"
      icon="clock"
      editable
      :enable-seconds="false" />

    <b-datepicker
      v-else-if="dataType === 'date'"
      v-model="value"
      position="is-bottom-left"
      icon="calendar-today"
      icon-right-clickable
      editable
      trap-focus />
  </b-field>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {
    convertToBackend,
    convertToFrontend
  } from './AttributeValueConverterHelpers'

  export default {
    name: 'AttributeInput',
    components: {},
    props: {
      field: {
        type: Object,
        required: true,
      },
      row: {
        type: Object,
        required: true,
      },
      dirtyValue: {
        type: [String, Number, Boolean, Date, null,],
        required: false,
        default: null,
      },
    },
    data () {
      return {
        attributeMeta: {},

        booleanOptions: ['TRUE', 'FALSE',],

        isDefaultValue: false,

        isInputValid: true,
      }
    },
    computed: {
      ...mapGetters({
        attributes: 'entities/entityAttributes',
      }),

      value: {
        get () {
          return this.getValue()
        },
        set (value) {
          this.setValue(value)
        },
      },

      dataType () {
        return this.attributeMeta?.data_type?.key
      },
    },

    mounted () {
      this.attributeMeta = this.attributes.find(
        (item) => item.key === this.field.attribute.key
      )
    },
    methods: {
      onInputFocus (evt) {
        evt.target.select()
      },

      isValid () {
        if (
          this.dataType === 'money' &&
          !this.$refs.moneyInput?.checkHtml5Validity()
        ) {
          return false
        }
        return true
      },

      checkIfDefault (value) {
        if (value == this.field?.attribute?.default_value) {
          this.isDefaultValue = true
        } else {
          this.isDefaultValue = false
        }
      },

      getValue () {
        let value = null
        if (this.dirtyValue) {
          value = this.dirtyValue
        } else {
          value = this.row[this.field.key]
          if (value === undefined || value === null) {
            value = this.field?.attribute?.default_value || null
            this.isDefaultValue = true
          }
        }
        this.checkIfDefault(value)
        return convertToFrontend(value, this.dataType)
      },

      setValue (value) {
        if (this.isValid()) {
          const newValue = convertToBackend(value, this.dataType)
          this.$emit('input', {
            key: this.field.attribute.key,
            value: newValue,
          })

        } else {
          this.$emit('input', {
            key: this.field.attribute.key,
            invalid: true,
          })
        }
      },
    },
  }
</script>

<style lang="scss">
.attribute-input {
  &.is-default-value {
    .input,
    .dropdown-button {
      color: $grayscale-5;
      font-style: italic;
    }
  }

  input,
  select {
    border: $border-1 !important;

    &:focus:not(.is-danger) {
      box-shadow: rgba(0, 140, 132, 0.25) 0px 0px 0px 2px !important;
      border: 1px solid rgb(0, 140, 132) !important;
    }

    &.is-danger {
      border: 1px solid rgb(211, 27, 63) !important;

      &:focus {
        box-shadow: rgba(211, 27, 63, 0.25) 0px 0px 0px 2px !important;
        border: 1px solid rgb(211, 27, 63) !important;
      }
    }

    ::after {
      border-color: black !important;
    }

    &[type="number"] {
      text-align: right !important;
    }
  }
  .select::after {
    border-color: $grayscale-3 !important;
  }
}
</style>
