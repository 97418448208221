<template>
  <div class="section-edit">
    <slot />
  </div>
</template>

<script>

  export default {
    name: 'SectionEdit',
  }

</script>

<style lang="scss" scoped>

.section-edit {
  height: inherit;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

</style>
