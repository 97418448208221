<template>
  <multiselect
    v-model="vModel"
    class="company-scope-select"
    :loading="isLoading"
    label="label"
    track-by="key"
    :options="options" />
</template>

<script>

  import multiselect from '@/components/ui/multiselect'

  export default {
    name: 'CompanyScopeSelect',
    components: {
      multiselect,
    },
    props: {
      companyScope: {
        type: Object,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        isLoading: true,
        options: [],
      }
    },
    computed: {
      vModel: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
    mounted () {
      this.loadOptions()
    },
    methods: {
      async loadOptions () {
        this.isLoading = true
        try {
          this.options = (await this.$apis.companyConfigs.listCompanyScopeOptions(this.companyScope)) || []
        } finally {
          this.isLoading = false
        }
      },
    },
  }

</script>

<style scoped lang="scss">

.company-scope-select {
  display: flex;
  width: 100%;
}

</style>
