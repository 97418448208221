<template>
  <form
    @submit.prevent="onSubmit">
    <slot name="body" />
    <input
      :value="email"
      class="email-input hidden"
      type="email"
      autocomplete="username"
      placeholder="Email Address" />
    <input
      v-model="newPassword"
      class="input password-field"
      :show-validation="!isEmpty"
      :valid="passwordValid || isEmpty"
      type="password"
      autocomplete="new-password"
      placeholder="New password"
      @blur.native.capture="isEmpty = newPassword.length == 0" />
    <input
      ref="confirmPassword"
      v-model="confirmPassword"
      class="input password-field"
      :show-validation="!isEmpty"
      :valid="confirmPasswordValid || newPassword.length == 0"
      type="password"
      autocomplete="new-password"
      placeholder="Confirm password" />
    <div class="password-summary">
      To protect your account, passwords must:
      <div class="validation-point">
        <i
          v-if="isEmpty"
          class="validation-icon material-icons indeterminate">
          indeterminate_check_box
        </i>
        <template v-else>
          <i
            v-if="lengthValid"
            class="validation-icon material-icons positive">
            check_circle
          </i>
          <i
            v-else
            class="validation-icon material-icons negative">
            error
          </i>
        </template>
        Be at least 8 characters in length
      </div>
      <div class="validation-point">
        <i
          v-if="isEmpty"
          class="validation-icon material-icons indeterminate">
          indeterminate_check_box
        </i>
        <template v-else>
          <i
            v-if="uppercaseValid"
            class="validation-icon material-icons positive">
            check_circle
          </i>
          <i
            v-else
            class="validation-icon material-icons negative">
            error
          </i>
        </template>
        Include at least one uppercase letter
      </div>
      <div class="validation-point">
        <i
          v-if="isEmpty"
          class="validation-icon material-icons indeterminate">
          indeterminate_check_box
        </i>
        <template v-else>
          <i
            v-if="lowercaseValid"
            class="validation-icon material-icons positive">
            check_circle
          </i>
          <i
            v-else
            class="validation-icon material-icons negative">
            error
          </i>
        </template>
        Include at least one lowercase letter
      </div>
      <div class="validation-point">
        <i
          v-if="isEmpty"
          class="validation-icon material-icons indeterminate">
          indeterminate_check_box
        </i>
        <template v-else>
          <i
            v-if="specialValid"
            class="validation-icon material-icons positive">
            check_circle
          </i>
          <i
            v-else
            class="validation-icon material-icons negative">
            error
          </i>
        </template>
        Include at least one number or special character
      </div>
    </div>
    <div class="flex-row-center-right">
      <button
        class="button is-black is-fullwidth change-button"
        native-type="submit"
        :busy="busy"
        :disabled="!confirmPasswordValid || !passwordValid">
        Set Password
      </button>
    </div>
  </form>
</template>

<script>

  export default {
    name: 'NewPasswordForm',
    props: {
      email: { type: String, required: true, },
      busy: { type: Boolean, default: false, },
    },
    data () {
      return {
        newPassword: '',
        confirmPassword: '',
      }
    },
    computed: {
      lengthValid () {
        return this.newPassword.length >= 8
      },
      uppercaseValid () {
        return /[A-Z]/.test(this.newPassword)
      },
      lowercaseValid () {
        return /[a-z]/.test(this.newPassword)
      },
      specialValid () {
        return /[^A-Za-z]/.test(this.newPassword)
      },
      passwordValid () {
        return this.lengthValid && this.lowercaseValid && this.uppercaseValid && this.specialValid
      },
      confirmPasswordValid () {
        return this.passwordValid && this.newPassword == this.confirmPassword
      },
      isEmpty () {
        return this.newPassword.length === 0
      },
    },
    watch: {
      newPassword () {
        if (this.passwordValid && this.confirmPasswordValid) {
          this.$emit('update:newPassword', this.newPassword)
        }
      },
    },
    methods: {
      onSubmit () {
        this.$emit('submit', this.newPassword)
      },
    },
  }

</script>

<style lang="scss" scoped>

.password-field {
  margin-bottom: 4 * $gridBase;
}

.password-summary {
  margin-top: 3 * $gridBase;
}

.validation-icon {
  font-size: 4 * $gridBase;
  margin-right: 2 * $gridBase;

  &.indeterminate {
    color: $ui-05;
  }

  &.negative {
    color: $ui-negative;
  }

  &.positive {
    color: $ui-positive;
  }
}

.validation-point {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4 * $gridBase;
}

input.hidden {
  position: absolute;
  top: -1000vh;
  visibility: hidden;
}

.change-button {
  margin-top: 12 * $gridBase;
}

</style>

