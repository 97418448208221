<template>
  <div class="layout">
    <vuedraggable
      group="moduleCards"
      ghost-class="ghost"
      class="dropzone"
      :list="module">
      <div
        v-for="(dimension, i) in module"
        :key="dimension.key">
        <LayoutDimensionItem
          v-model="module[i]"
          :current-selected-key="currentSelectedKey"
          @delete="deleteItem(i)" />

        <b-tooltip
          type="is-light"
          position="is-bottom"
          style="width: 100%"
          :triggers="['contextmenu']"
          size="is-small"
          multilined
          :auto-close="['outside', 'escape', 'inside']">
          <div class="add-module-below-section" />

          <template #content>
            <div class="context-menu-actions">
              <button
                class="button is-light"
                @click="pasteModule(i)">
                Paste
              </button>

              <button
                class="button is-light"
                @click="addNewItem(i)">
                Add new row below
              </button>
            </div>
          </template>
        </b-tooltip>
      </div>
    </vuedraggable>
    <button
      class="mini-view-item add-row-button"
      @click="addNewItem">
      <img
        src="@/assets/icons/add-row.svg"
        alt="" />

      <div>Add Row</div>
    </button>
  </div>
</template>

<script>
  import { tableRowObject } from '@/components/pdf_report/table_module/modules.js'
  import LayoutDimensionItem from './LayoutDimensionItem.vue'
  import vuedraggable from 'vuedraggable'
  import uniqid from 'uniqid'
  export default {
    name: 'RowLayout',
    components: {
      LayoutDimensionItem,
      vuedraggable,
    },
    provide () {
      return {
        openModule: this.openModule,
      }
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        currentSelectedKey: null,
      }
    },
    computed: {
      module: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {
      if (!this.module.key) {
        this.module.key = uniqid()
      }
    },

    beforeDestroy () {},

    methods: {
      openModule (elementObject, type) {
        this.currentSelectedKey = elementObject.key
        return this.$emit('open', elementObject, type)
      },
      deleteItem (index) {
        this.module.splice(index, 1)
      },
      async pasteModule (index) {
        const clipboard = await navigator.clipboard.readText()

        try {
          const data = JSON.parse(clipboard)
          if ( data.type === 'table_row') {
            this.module.splice(index + 1, 0, {
              ...data.code,
              key: uniqid(),
              name: (data.code.name || 'Breakdown') + ' (copy)',
            })
          } else {
            this.$buefy.toast.open({
              message: 'This module cannot be pasted here.',
              position: 'is-bottom',
              type: 'is-warning',
            })
          }
        } catch (e) {
          this.$buefy.toast.open({
            message: 'Error',
            position: 'is-bottom',
            type: 'is-warning',
          })
        }
      },
      addNewItem (i) {

        const newRow = { ...tableRowObject(), key: uniqid(), }

        if (i !== undefined) {
          this.module.splice(i + 1, 0, newRow)
          return
        }

        this.module.push(newRow)
      },
    },
  }
</script>

<style lang="scss" scoped>
.rows {
  button {
    display: flex;
    align-items: flex-start;
    color: $primary;
    border: 1px solid rgba(0, 140, 132, 0.1);
    background: rgba(0, 140, 132, 0.05);
    justify-content: flex-start;

    &:hover {
      color: $primary;
      border: 1px solid rgba(0, 140, 132, 0.1);
    }
  }

  .add-module-below-section {
    height: 8px;
    cursor: copy;

    &:hover {
      background-color: #f2f9f9;
    }
  }
}

.layout {
  overflow: auto;
  height: 100%;
}
.mini-view-item {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s;
  width: 100%;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  background-color: white;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
    0px 1px 2px 0px rgba(40, 39, 43, 0.06);

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    background-color: hsla(177, 100%, 27%, 0.1);
    transition: background-color 0.1s;
    border: $border-1;
  }
  &.selected {
    background-color: $primary;
    color: white;
    img {
      filter: brightness(0) invert(1);
    }
  }
}

.add-module-below-section {
  height: 8px;
  margin: 1px 0;
  width: 100%;
  cursor: pointer;
  background-color: $primary;
  border-radius: 5px;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}
.context-menu-actions {
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 4px;
    font-size: 12px;
    justify-content: start;
  }
}
</style>
