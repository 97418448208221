<template>
  <div class="user-setting">
    <div class="container">
      <validation-observer ref="form">
        <div class="page-title">
          <router-link :to="{ name: 'users' }">
            <b-button
              type="is-light"
              class="back-btn mr-3"
              icon-right="arrow-left" />
          </router-link>
          <h2>{{ isCreateMode ? "Create User" : "Edit User" }}</h2>

          <div
            v-if="isDirty"
            class="actions">
            <button
              v-if="!isCreateMode"
              class="button is-danger mr-3"
              @click="cancelUserEdit">
              Discard Changes
            </button>
            <button
              v-if="isCreateMode"
              class="button is-primary"
              @click="createUser">
              Send Invite
            </button>
            <button
              v-else
              class="button is-primary"
              @click="saveEdits">
              Save Changes
            </button>
          </div>
        </div>

        <div class="settings">
          <h3 class="section-name">
            Basic Info
          </h3>

          <div class="columns mx-0 is-multiline">
            <div class="column px-0 is-3">
              <validation-provider
                v-slot="{ errors }"
                rules="required">
                <b-field
                  label="Name"
                  :type="errors.length ? 'is-danger' : ''"
                  class="field">
                  <b-input
                    v-model="nameInput"
                    :disabled="!isCreateMode"
                    @input="makeInputsDirty" />
                </b-field>
              </validation-provider>
            </div>

            <div class="column px-0 is-3">
              <validation-provider
                v-slot="{ errors }"
                rules="required|email">
                <b-field
                  label="Email"
                  :type="errors.length ? 'is-danger' : ''"
                  class="field">
                  <b-input
                    v-model="emailInput"
                    :disabled="!isCreateMode"
                    @input="makeInputsDirty" />
                </b-field>
              </validation-provider>
            </div>
            <div class="column px-0 is-3">
              <b-field
                label="Job Title"
                class="field">
                <b-input
                  v-model="titleInput"
                  @input="makeInputsDirty" />
              </b-field>
            </div>

            <div class="column px-0 is-3">
              <validation-provider
                v-slot="{ errors }"
                rules="required">
                <b-field
                  label="Role"
                  :type="errors.length ? 'is-danger' : ''"
                  class="field">
                  <div
                    style="position: relative; height: 40px;"
                    :class="{'field-invalid': errors.length }">
                    <multiselect
                      v-model="roleInput"
                      deselect-label=""
                      :allow-empty="false"
                      :options="roleOptions"
                      label="name"
                      track-by="id"
                      @input="makeInputsDirty" />
                  </div>
                </b-field>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="settings">
          <h3 class="section-name">
            Access
          </h3>

          <div class="columns mx-0 is-multiline">
            <div class="column px-0">
              <div class="permission-container">
                <div class="header">
                  <h3>Permissions</h3>
                </div>
                <div
                  v-for="permission in computedPermissions"
                  :key="permission.id"
                  class="permission-item">
                  <div class="item-title">
                    {{ permission.name }}
                  </div>

                  <b-icon
                    class="item-icon"
                    :type="permission.active ? 'is-success' : ''"
                    :icon="
                      permission.active
                        ? 'checkbox-marked-circle'
                        : 'checkbox-blank-circle-outline'
                    " />
                </div>
              </div>
            </div>
            <div class="column px-0 ml-4">
              <div class="permission-container">
                <div class="header">
                  <h3>Reports</h3>
                </div>
                <div
                  v-for="report in computedReports"
                  :key="report.id"
                  class="permission-item">
                  <div class="item-title">
                    {{ report.name }}
                  </div>

                  <b-icon
                    class="item-icon"
                    :type="report.active ? 'is-success' : ''"
                    :icon="
                      report.active
                        ? 'checkbox-marked-circle'
                        : 'checkbox-blank-circle-outline'
                    " />
                </div>
              </div>
            </div>
            <div class="column px-0 ml-4">
              <div class="permission-container">
                <div class="header">
                  <h3>Locations</h3>

                  <div class="controls">
                    <button
                      class="button is-text"
                      @click="selectAllLocations">
                      Select All
                    </button>

                    <button
                      class="button is-text"
                      @click="unselectAllLocations">
                      Deselect All
                    </button>
                  </div>
                </div>
                <div
                  v-for="(location, i) in locationInput"
                  :key="location.id"
                  class="permission-item">
                  <div class="item-title">
                    {{ location.display_name }}
                  </div>

                  <b-checkbox
                    v-model="locationInput[i].active"
                    size="is-medium"
                    @input="makeInputsDirty" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false" />
  </div>
</template>

<script>
  import { userService } from '@/service/admin/userService'
  import { roleService } from '@/service/admin/roleService'
  import axios from 'axios'

  export default {
    name: 'UserEditor',
    components: {},
    beforeRouteLeave (from, to, next) {
      if (this.isDirty) {
        this.$buefy.dialog.confirm({
          title: 'Unsaved Changes',
          container: '.user-setting',
          message: 'Are you sure you want to discard changes and leave page?',
          onConfirm: () => next(),
        })
      } else {
        next()
      }
    },
    data () {
      return {
        isLoading: false,
        isDirty: false,

        user: null,

        nameInput: null,
        titleInput: null,
        emailInput: null,
        roleInput: null,
        locationInput: [],

        permissionOptions: [],
        roleOptions: [],
        reportOptions: [],
        dashboardReportOptions: [],
        locationOptions: [],
      }
    },
    computed: {
      currentReports () {
        return []
      },
      userId () {
        return this.$route.params.user_id
      },

      isCreateMode () {
        return this.$route.name === 'create_user'
      },

      computedPermissions () {
        if (!this.permissionOptions) return []

        return this.permissionOptions.map((item) => {
          return {
            ...item,
            active: this.roleInput
              ? this.roleInput.permission_groups.some((permission) => {
                return permission.id === item.id
              })
              : false,
          }
        })
      },

      computedReports () {
        if (!this.reportOptions) return []

        return [].concat(
          (this.reportOptions || []).map((item) => {
            return {
              ...item,
              active: this.roleInput
                ? this.roleInput.reports.some((report) => {
                  return report.id === item.id
                })
                : false,
            }
          }),
          (this.dashboardReportOptions || []).map((item) => {
            return {
              ...item,
              active: this.roleInput
                ? this.roleInput.dashboard_reports.some((dashboardReport) => {
                  return dashboardReport.key === item.key
                })
                : false,
            }
          })
        )
      },
    },
    async mounted () {
      await this.loadOptions()

      if (!this.isCreateMode) {
        this.loadUserData()
      }
    },
    methods: {
      selectAllLocations () {
        this.locationInput.forEach((item) => (item.active = true))
        this.isDirty = true
      },

      unselectAllLocations () {
        this.locationInput.forEach((item) => (item.active = false))
        this.isDirty = true
      },

      cancelUserEdit () {
        this.loadUserData()
        this.isDirty = false
      },

      async saveEdits () {
        this.isLoading = true

        const editedUser = {
          ...this.user,
          name: this.nameInput,
          job_title: this.titleInput,
          role: this.roleInput,
          locations: this.locationInput.filter((item) => item.active),
        }
        await userService.updateCompanyUser(
          this.$route.params.company_id,
          this.user.id,
          editedUser
        )

        this.isDirty = false
        this.isLoading = false
      },

      async createUser () {
        const isFormValidatied = await this.$refs.form.validate()
        if (!isFormValidatied) return

        this.isLoading = true
        const newUser = {
          email: this.emailInput,
          name: this.nameInput,
          job_title: this.titleInput,
          role: this.roleInput,
          locations: this.locationInput.filter((item) => item.active),
        }
        await userService.createUser(this.$route.params.company_id, newUser)
        // this.$buefy.toast.open({
        //   container: '.user-setting',
        //   message: `Invite Sent 💌`,
        //   position: 'is-bottom',
        //   type: 'is-success',
        // })
        this.isDirty = false
        this.isLoading = false

        this.$router.push({ name: 'users', })
      },

      makeInputsDirty () {
        this.isDirty = true
      },

      async loadOptions () {
        this.isLoading = true
        let companyId = this.$route.params.company_id

        try {
          this.roleOptions = await roleService.loadRoles(companyId)
          this.permissionOptions = await roleService.loadPermissions(companyId)
          this.reportOptions = await roleService.loadReports(companyId)
          this.dashboardReportOptions = await roleService.loadDashboardReports(companyId)
          this.locationOptions = (
            await axios.get(
              `${process.env.VUE_APP_API_URL}/company/${companyId}/locations`,
              {
                headers: { authorization: this.$store.getters['auth/authToken'], },
              }
            )
          ).data

          this.locationInput = this.locationOptions.map((item) => {
            return {
              ...item,
              active: false,
            }
          })
        } catch (e) {
          console.log('error')
        }
        this.isLoading = false
      },
      async loadUserData () {
        this.isLoading = true
        let companyId = this.$route.params.company_id
        const usersData = await userService.loadUsers(companyId)
        const userData = usersData.find((user) => user.id == this.userId)
        this.user = userData

        this.nameInput = userData.name
        this.titleInput = userData.job_title
        this.emailInput = userData.email
        this.roleInput = userData.role

        this.locationInput = this.locationOptions.map((item) => {
          return {
            ...item,
            active: userData.locations
              ? userData.locations.some((location) => {
                return location.id === item.id
              })
              : false,
          }
        })

        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.page-title {
  margin-top: 50px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  height: 50px;

  h2 {
    font-weight: 400;
    font-size: 22px;
  }

  .back-btn {
    font-size: 24px;
    border-radius: 100%;
  }

  .actions {
    margin-left: auto;
  }
}

.permission-container {
  border: $border-1;
  border-radius: 5px;

  .header {
    font-size: 15px;
    height: 40px;
    font-weight: 500;
    background-color: $grayscale-8;
    padding: 0 10px;

    display: flex;
    align-items: center;

    .controls {
      margin-left: auto;
      .button {
        font-size: 11px;
      }
    }
  }

  .permission-item {
    display: flex;
    font-size: 14px;
    border-top: $border-1;
    padding: 10px;
    align-items: center;
    .item-title {
      margin-right: auto;
    }

    .item-icon {
      margin: 0;
      opacity: 0.8;
    }
  }
}

.field-invalid{
  border: 1px solid $warning;
  border-radius: 5px;
}
.settings {
  border-bottom: $border-1;
  padding: 40px 0;
  .field {
    margin-right: 20px;
  }

  .section-name {
    font-size: 19px;
    margin-bottom: 20px;
  }
}
</style>
