<template>
  <div class="radar-view">
    <canvas ref="canvas" />
  </div>
</template>
<script>

  import simpleChartMixin from './simpleChartMixin'

  export default {
    name: 'BarView',
    mixins: [ simpleChartMixin, ],
    data () {
      return {
        chartType: 'radar',
        hasAxes: false,
      }
    },
  }

</script>

<style lang="scss" scoped>

.radar-view {
  width: 100%;
  height: 100%;
}

</style>
