<template>
  <div class="block preview">
    <div class="block-header">
      <h4 class="mr-auto">
        Preview
      </h4>

      <a-button
        v-if="!showCode"
        class="mr-2 is-small"
        hotkeys="Ctrl+p"
        :loading="loadingFullPreview"
        @click="getPreview(true)">
        Preview
      </a-button>

      <a-button
        v-if="showCode && type ==='module'"
        class="mr-2 is-small"
        :loading="loadingDebug"
        @click="getDebug(false)">
        Debug
      </a-button>

      <div
        v-if="!showCode"
        class="preview-button-group">
        <a-button
          class="is-small preview-button"
          hotkeys="Ctrl+P"
          :loading="loadingFullPreview"
          @click="getPreview(false)">
          Preview with Data
        </a-button>
        <b-datepicker
          v-model="previewDate"
          position="is-bottom-left"
          :mobile-native="false">
          <template #trigger>
            <b-button
              icon-right="chevron-down"
              class="is-white is-small">
              {{ previewDateFormatted }}
            </b-button>
          </template>
        </b-datepicker>
      </div>

      <button
        class="button output-switcher is-small"
        @click="switchOutputMode">
        {{ showCode ? "View PDF" : "View Code" }}
      </button>
    </div>
    <div class="block-body">
      <div class="output">
        <div
          v-if="!showCode"
          class="preview-output">
          <a
            v-if="!showCode && reportPreviewURL"
            class="button pdf-download-btn is-small"
            :href="reportPreviewURL"
            target="_blank">Download</a>

          <pdf
            v-if="reportPreviewURL"
            :src="reportPreviewURL" />
        </div>

        <div
          v-else
          class="output p-0">
          <v-jsoneditor
            v-model="module"
            :options="{ mode: 'code' }"
            :plus="false"
            height="100%"
            @error="onError" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import VJsoneditor from 'v-jsoneditor'
  import pdf from 'vue-pdf'

  import { formatDate } from '@/utils/Date'
  import moment from 'moment'

  export default {
    name: 'PreviewAndJsonEditor',
    components: {
      pdf,
      VJsoneditor,
    },
    props: {
      value: {
        type: [Object, Array,],
        default: () => {},
      },
      type: {
        type: String,
        required: true,
      },
      fullReport: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        showCode: false,

        modulePreviewURL: null,
        reportPreviewURL: null,
        loadingFullPreview: false,
        loadingDebug: false,
        previewDate: moment().subtract(1, 'days').toDate(),
      }
    },

    computed: {
      previewDateFormatted () {
        return formatDate(this.previewDate, 'll')
      },

      module: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    async mounted () {
      window.addEventListener('keydown', this.shortcutHandler)
    },
    beforeDestroy () {
      window.removeEventListener('keydown', this.shortcutHandler)
    },
    methods: {
      switchOutputMode () {
        this.showCode = !this.showCode
      },
      async getDebug (fakeData = true) {
        this.loadingDebug = true
        try {
          let response = null
          if (this.type === 'report') {
            // Not Supported
          } else if (this.type === 'module') {
            response = await this.$apis.pdfReports.getPdfReportModuleDebug({
              report_module: this.module,
              run_date: formatDate(this.previewDate, 'YYYY-MM-DD'),
              use_fake_data: fakeData,
            })

            if (response?.errors) {
              this.$buefy.toast.open({
                message: 'Error Loading Debug, Fix the errors and try again',
                type: 'is-warning',
              })

              this.module = {}
              await this.$nextTick()
              await this.$nextTick()
              await this.$nextTick()
              this.module = response.report_module
            }

            else if (!response) {
              this.$buefy.toast.open({
                message: 'Backend Error, Report this to the devs',
                type: 'is-warning',
              })
            }
          }

        } catch (e) {
          console.log('error loading full debug')
          console.log(e)
        }

        this.loadingDebug = false
      },
      async getPreview (fakeData = true) {
        this.loadingFullPreview = true
        try {
          let response = null
          if (this.type === 'report') {
            response = await this.$apis.pdfReports.getPdfReportFullPreview({
              report: {
                ...this.fullReport,
                modules: this.module,
                style: { is_a11y: false, },
              },
              run_date: formatDate(this.previewDate, 'YYYY-MM-DD'),
              use_fake_data: fakeData,
            })
          } else if (this.type === 'module') {
            response = await this.$apis.pdfReports.getPdfReportModulePreview({
              report_module: this.module,
              run_date: formatDate(this.previewDate, 'YYYY-MM-DD'),
              use_fake_data: fakeData,
            })

            if (response?.errors) {
              this.$buefy.toast.open({
                message: 'Error Loading Preview, Fix the errors and try again',
                type: 'is-warning',
              })

              this.module = {}
              await this.$nextTick()
              await this.$nextTick()
              await this.$nextTick()
              this.module = response.report_module
            }

            else if (!response) {
              this.$buefy.toast.open({
                message: 'Backend Error, Report this to the devs',
                type: 'is-warning',
              })
            }
          }

          this.reportPreviewURL = this.getPDFfromBinary(response)
        } catch (e) {
          console.log('error loading full preview')
        }

        this.loadingFullPreview = false
      },

      getPDFfromBinary (data) {
        var blob = new Blob([data,], { type: 'application/pdf', })

        // create an object URL from the Blob
        var URL = window.URL || window.webkitURL
        URL.createObjectURL(blob)

        return URL.createObjectURL(blob)
      },

      onError (e) {
        console.log('error', e)
      },
    },
  }
</script>
<style lang="scss">
.block {
  margin-bottom: 6px;
  border: $border-1;
  border-radius: 3px;
  background-color: white;
  height: 100%;
  flex-direction: column;
  display: flex;

  .block-header {
    min-height: 40px;
    align-items: center;
    padding: 0 5px;
    border-bottom: $border-1;
    background-color: white;
    display: flex;

    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      padding: 0 10px;
      text-transform: capitalize;
      margin: 0;
      color: black;
    }
  }
  .block-body {
    padding: 0 10px;
    background-color: #fbfbfb;
    height: 100%;
    flex-direction: column;
    display: flex;
  }

  &.library {
    border: $border-1;
    .library-items {
      display: flex;
      flex-direction: column;
    }
    .block-body {
      padding-bottom: 0;
    }
  }

  &.layout {
    max-height: 100%;
    overflow: hidden;

    .block-body {
      height: calc(100% - 40px);
      padding: 0;
    }
  }

  &.preview {
    height: 100%;

    .preview-output {
      position: relative;
      .pdf-download-btn {
        margin: 10px;
      }
    }

    .preview-button-group {
      display: flex;
      align-items: center;
      margin-right: 10px;
      border: $border-1;
      border-color: hsl(0, 0%, 86%);

      .preview-button {
        border-right: 1px solid hsl(0, 0%, 86%);
      }
    }
    .block-header {
      width: 100%;
    }

    .block-body {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
    }

    .output {
      background-color: white;
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
