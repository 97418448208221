<template>
  <g class="x-axis">
    <path
      class="axis-line"
      :d="`M0,${chartHeight} L${chartWidth},${chartHeight}`" />
    <foreignObject
      v-for="(datum, idx) of ticks"
      :key="`xTick-${idx}`"
      :x="
        xScale(getDimensionVal(primaryDimensionKeys, datum)) +
          xScale.bandwidth() / 2 -
          96
      "
      :y="chartHeight + 8"
      :transform="`rotate(-45 ${
        xScale(getDimensionVal(primaryDimensionKeys, datum)) +
        xScale.bandwidth() / 2
      } ${chartHeight + 16})`"
      width="96"
      height="24">
      <p
        class="tick-label"
        :class="{
          highlighted:
            highlightedData.filter(
              (d) =>
                getDimensionVal(primaryDimensionKeys, d) ===
                getDimensionVal(primaryDimensionKeys, datum)
            ).length > 0,
          clickable: hasClickableDimensions,
        }"
        @click="$emit('click', datum)">
        {{ getDimensionVal(primaryDimensions, datum) }}
      </p>
    </foreignObject>
  </g>
</template>

<script>
  import { uniqBy } from 'lodash'

  import chartComponentMixin from '@/components/charting/chart_component_mixin'

  export default {
    name: 'XAxis',
    mixins: [chartComponentMixin,],
    computed: {
      ticks () {
        return uniqBy(this.data, (d) =>
          this.getDimensionVal(this.primaryDimensionKeys, d)
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
.x-axis {
}

.tick-label {
  width: 96px;
  height: 24px;
  text-align: right;
  position: relative;
  font-size: 1.6rem;
  color: $ui-03;
  text-anchor: end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.highlighted {
    font-weight: $emp;
    color: $ui-02;
  }
}

.axis-line {
  stroke-width: 2;
  stroke: $secondary-02;
}

.clickable {
  cursor: pointer;
}
</style>
