import axios from 'axios'
import { getAuthToken } from '@/service/auth/authService'

export const userService = {
  async loadUsers (companyId) {
    return (await axios.get(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${companyId}/users`,
      { headers: { authorization: getAuthToken(), }, }))
      .data
  },
  async deleteUser (company_id, user_id) {
    let response = await axios.delete(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${company_id}/user/${user_id}`,
      { headers: { authorization: getAuthToken(), }, })
    return response.data
  },
  async updateUser (user_id, new_user) {
    let response = await axios.post(
      `${process.env.VUE_APP_ADMIN_API_URL}/user/${user_id}`,
      new_user,
      { headers: { authorization: getAuthToken(), }, })
    return response.data
  },
  async updateCompanyUser (company_id, user_id, new_user) {
    let response = await axios.post(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${company_id}/user/${user_id}`,
      new_user,
      { headers: { authorization: getAuthToken(), }, })
    return response.data
  },
  async createUser (company_id, user) {
    let response = await axios.post(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${company_id}/user`,
      user,
      { headers: { authorization: getAuthToken(), }, })
    return response.data
  },
}
