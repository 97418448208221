<template>
  <div class="">
    <!-- <div
      v-for="value of values"
      :key="value"
      class="selected-value flex-row">
      <b-button @click="removeValue(value)">
        <v-icon name="x" />
      </b-button>
      {{ value }}
    </div>
    <form
      class="flex-row"
      @submit.prevent="addValue(currentInput)"
      @focusout="addValue(currentInput)">
      <input
        v-model="currentInput"
        type="text" />
      <b-button class="pad-4-left">
        Add
      </b-button>
    </form> -->

    <div class="filter-label">
      {{ dimension.name }}
    </div>
    <multiselect
      v-model="values"
      :options="[]"
      tag-placeholder="Add this to filter"
      :multiple="true"
      :taggable="true"
      placeholder="Add a new value"
      :close-on-select="false"
      :show-no-options="false"
      @tag="addValue" />
  </div>
</template>

<script>
  export default {
    name: 'NumberInFilter',
    components: {},
    props: {
      dimension: { type: Object, required: true, },
      filter: { type: Object, default: null, },
    },
    data () {
      return {
        currentInput: null,
      }
    },
    computed: {
      values: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.values
          } else {
            return []
          }
        },
        set (values) {
          this.updateFilter(values)
        },
      },
    },
    methods: {
      addTag (newTag) {
        this.values.push(newTag)
      },
      removeValue (value) {
        this.values = this.values.filter((v) => v != value)
      },
      addValue (value) {
        let newValues = (value || '')
          .split(',')
          .map((value) => value.trim())
          .filter((value) => !!value)
        this.values = [...this.values, ...newValues,].filter(
          (value, idx, a) => a.indexOf(value) == idx
        )
        this.currentInput = null
      },
      updateFilter (values) {
        if (!values || values.length == 0) {
          this.$emit('update:filter', null)
        } else {
          this.$emit('update:filter', {
            type: 'number_in',
            dimension: this.dimension.key,
            args: { values, },
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.filter-label {
  font-size: 13px;
  margin-right: auto;
  margin-bottom: 4px;
  color: $ui-03;
  font-weight: 300;
  text-transform: capitalize;
}
</style>
