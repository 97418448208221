<template>
  <div class="password-reset">
    <template v-if="!!resetToken">
      <reset-form
        :closeable="!success"
        @close="cancel">
        <template #header>
          Change Password
        </template>
        <template #body>
          <template v-if="success">
            <p class="success-body">
              Success!
            </p>
            Your password has been updated. Please log in again.
            <div class="flex-row-center-right">
              <b-button
                class="green return-button"
                @click="cancel">
                Return to Login
              </b-button>
            </div>
          </template>
          <template v-else>
            <template v-if="expired()">
              <p class="expired negative">
                Link Expired
              </p>
              Your password has not been changed. Please submit another password
              reset link request to try again.
              <div class="flex-row-center-right">
                <b-button
                  class="red rerequest-button"
                  @click="rerequest">
                  Request another link
                </b-button>
              </div>
            </template>
            <template v-else>
              <new-password-form
                :email="tokenEmail"
                @submit="onSubmit($event)">
                <template #body>
                  <p class="password-body">
                    Create a new password:
                  </p>
                </template>
              </new-password-form>
            </template>
          </template>
        </template>
      </reset-form>
    </template>
    <template v-else>
      <template v-if="!requested">
        <reset-form
          closeable
          @close="cancel">
          <template #header>
            Reset Password
          </template>
          <template #body>
            <form @submit.prevent="onEmailSubmit">
              To request a password reset link, please enter your email address
              below:
              <b-field
                label="Email"
                class="my-5">
                <b-input
                  v-model="email"
                  show-validation
                  :valid="emailValid || email.length == 0"
                  type="email"
                  autocomplete="username"
                  placeholder="Email Address" />
              </b-field>
              <div class="flex-row-center-right">
                <button
                  class="button is-black is-fullwidth"
                  :busy="busy"
                  native-type="submit"
                  :disabled="!emailValid">
                  Send Link
                </button>
              </div>
            </form>
          </template>
        </reset-form>
      </template>
      <template v-else>
        <reset-form>
          <template #header>
            Reset Password
          </template>
          <template #body>
            Password reset link sent. Please check your email and follow the
            instructions to reset your password.
            <p class="expiration-note negative">
              <i class="validation-icon material-icons negative"> error </i>
              Link expires after 5 minutes
            </p>
            <div class="flex-row-center-right">
              <b-button
                class="green return-button"
                @click="cancel">
                Return to Login
              </b-button>
            </div>
          </template>
        </reset-form>
      </template>
    </template>
  </div>
</template>

<script>
  import ResetForm from '@/components/authorization/ResetForm'
  import NewPasswordForm from '@/components/authorization/NewPasswordForm'
  import { validateEmail } from '@/utils/Validation'
  import { jwtDecode } from 'jwt-decode'

  export default {
    name: 'UpdatePassword',
    components: {
      ResetForm,
      NewPasswordForm,
    },
    data () {
      return {
        email: '',
        requested: false,
        busy: false,
        success: false,
      }
    },
    computed: {
      tokenEmail () {
        return jwtDecode(this.resetToken).user.email
      },
      emailValid () {
        return validateEmail(this.email)
      },
      resetToken () {
        return this.$store.getters['auth/resetToken']
      },
    },
    methods: {
      expired () {
        return new Date(jwtDecode(this.resetToken).exp * 1000) < new Date()
      },
      rerequest () {
        this.$router.push({
          name: 'password_reset',
          query: { ...this.$route.query, t: undefined, },
        })
      },
      cancel () {
        this.$router.push({
          name: 'login',
          query: { ...this.$route.query, t: undefined, },
        })
      },
      async onEmailSubmit () {
        this.busy = true
        await this.$store.dispatch('auth/requestResetEmail', this.email)
        this.busy = false
        this.requested = true
      },
      async onSubmit (newPass) {
        if (!this.expired()) {
          this.busy = true
          await this.$store.dispatch('auth/resetPassword', newPass)
          this.success = true
          this.busy = false
        } else {
          this.$forceUpdate()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.password-reset {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f7f3ef;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(40deg, $grayscale-9, $grayscale-7, $primary);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.reset-form {
  width: 116 * $gridBase;
}

.email-input {
  margin-top: 12 * $gridBase;
  margin-bottom: 8 * $gridBase;
}

.return-button {
  margin-top: 12 * $gridBase;
}

.negative {
  color: $ui-negative;
}

.expired.negative {
  font: $h1-ew-sl;
  color: $ui-negative;
  margin-bottom: 8 * $gridBase;
}

.rerequest-button,
.change-button {
  margin-top: 12 * $gridBase;
}

.password-body {
  margin-bottom: 3 * $gridBase;
}

.validation-icon {
  font-size: 4 * $gridBase;
  margin-right: 2 * $gridBase;

  &.indeterminate {
    color: $ui-05;
  }

  &.negative {
    color: $ui-negative;
  }

  &.positive {
    color: $ui-positive;
  }
}

.success-body {
  color: $ui-positive;
  margin-bottom: 8 * $gridBase;
}

.expiration-note {
  display: flex;
  align-items: center;
  margin-top: 4 * $gridBase;
}

label.text-input.hidden {
  position: absolute;
  top: -1000vh;
  visibility: hidden;
}

label.b-button {
  font: $h2-dw-sl;
}
</style>
