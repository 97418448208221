import axios from 'axios'

export const entitiesModel = {
  namespaced: true,
  state () {
    return {
      initialized: false,
      entityTypes: [],
      entityAttributes: [],
      currentTags: [],
      consolidatedTags: {},
      tagLoadingCanceler: null,
      loading: false,
    }
  },
  getters: {
    entityTypes (state) {
      return state.entityTypes
    },
    entityAttributes (state) {
      return state.entityAttributes
    },
    currentTags (state) {
      return state.currentTags
    },
    consolidatedTags (state) {
      return state.consolidatedTags
    },
    loading (state) {
      return state.loading
    },
  },
  mutations: {
    setInitialized (state, initialized) {
      state.initialized = initialized
    },
    setEntityTypes (state, types) {
      state.entityTypes = types
    },
    setEntityAttributes (state, attributes) {
      state.entityAttributes = attributes
    },
    setCurrentTags (state, currentTags) {
      state.currentTags = Object.freeze(currentTags)
    },
    setConsolidatedTags (state, consolidatedTags) {
      let newTags = {}
      consolidatedTags.forEach((tag) => {
        newTags[tag.id] = tag
      })
      state.consolidatedTags = Object.freeze(newTags)
    },
    startLoading (state, canceler) {
      state.tagLoadingCanceler = canceler
      state.loading = true
    },
    cancelLoading (state) {
      try {
        state.tagLoadingCanceler?.cancel('cancel')
      } catch (e) {
        console.log('failed to cancel loading tags', e)
      }

      state.loading = false
    },
    stopLoading (state) {
      state.loading = false
    },
  },
  actions: {
    async updateTags (
      context,
      { attributeUpdates, consolidationUpdates, entity_type, date_range, }
    ) {
      let postFutures = [
        axios.post(
          `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/dimensional_entities/${entity_type.key}/attribute_values`,
          attributeUpdates,
          { headers: { authorization: context.rootGetters['auth/authToken'], }, }
        ),
        axios.post(
          `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/dimensional_entities/${entity_type.key}/consolidated_values`,
          consolidationUpdates,
          { headers: { authorization: context.rootGetters['auth/authToken'], }, }
        ),
      ]
      for (let future of postFutures) {
        await future
      }
      await context.dispatch('loadCurrentTags', { entity_type, date_range, })
    },
    async loadCurrentTags (context, { entity_type, date_range, }) {
      try {
        if (context.getters.loading) {
          context.commit('cancelLoading')
        }

        let canceler = axios.CancelToken.source()
        context.commit('startLoading', canceler)

        let tags = await axios.get(
          `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/dimensional_entities/${entity_type.key}/attribute_values`,
          {
            headers: { authorization: context.rootGetters['auth/authToken'], },
            params:
              date_range.type == 'range'
                ? {
                    start: date_range.start.key,
                    end: date_range.end.key,
                  }
                : {},
            cancelToken: canceler.token,
          }
        )
        if (entity_type.consolidated_type_key) {
          let consolidatedTags = await axios.get(
            `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/dimensional_entities/${entity_type.consolidated_type_key}/attribute_values`,
            {
              headers: { authorization: context.rootGetters['auth/authToken'], },
              cancelToken: canceler.token,
            }
          )
          context.commit('setConsolidatedTags', consolidatedTags.data)
        }
        context.commit('setCurrentTags', tags.data)
      } catch (e) {
        console.log('cancelled loading tags', e)
      } finally {
        context.commit('stopLoading')
      }
    },
    async loadEntityTypes (context, date_range) {
      let entities = await axios.get(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/dimensional_entities`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('setEntityTypes', entities.data)
      await context.dispatch('loadCurrentTags', {
        entity_type: entities.data.slice(0).shift(),
        date_range,
      })
    },
    async loadEntityAttributes (context) {
      let attributesFuture = axios.get(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/attributes`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('setEntityAttributes', (await attributesFuture).data)
    },
    async createConsolidatedValue (context, { consolidated_type_key, name, }) {
      let newTag = (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/dimensional_entities/${consolidated_type_key}`,
          { name, },
          { headers: { authorization: context.rootGetters['auth/authToken'], }, }
        )
      ).data
      context.commit('setConsolidatedTags', [
        ...Object.values(context.getters['consolidatedTags']),
        newTag,
      ])
      return newTag
    },
    async deleteConsolidatedValue (
      context,
      { entity_type, entity, date_range, }
    ) {
      await axios.delete(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/dimensional_entities/${entity_type.key}/${entity.id}`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      await context.dispatch('loadCurrentTags', { entity_type, date_range, })
    },
    async createAttribute (context, attribute) {
      await axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/attributes`,
        attribute,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      await context.dispatch('loadEntityAttributes')
    },
    async updateAttribute (context, attribute) {
      await axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/attributes/${attribute.id}`,
        attribute,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      await context.dispatch('loadEntityAttributes')
    },
    async deleteAttribute (context, attribute) {
      await axios.delete(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/attributes/${attribute.id}`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      await context.dispatch('loadEntityAttributes')
    },
    async initialize (context, date_range) {
      if (
        context.state.initialized ||
        !context.rootGetters['auth/isLoggedIn']
      ) {
        return
      }
      for (let future of [
        context.dispatch('loadEntityTypes', date_range),
        context.dispatch('loadEntityAttributes'),
      ]) {
        await future
      }
      context.commit('setInitialized', true)
    },
    async importCsv (context, { csvFile, entity_type, }) {
      if (context.getters.loading) {
        context.commit('cancelLoading')
      }

      let canceler = axios.CancelToken.source()
      context.commit('startLoading', canceler)

      let formData = new FormData()
      formData.append('upload', csvFile)
      let results = await axios.post(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/dimensional_entities/${entity_type.key}/importCsv`,
        formData,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('stopLoading')
      return results
    },
  },
  modules: {},
}
