<template>
  <div class="sub-section">
    <b-field label="Space Before">
      <b-numberinput
        v-model="inputs.space_before"
        min="0"
        controls-alignment="right" />
    </b-field>

    <b-field label="Space After">
      <b-numberinput
        v-model="inputs.space_after"
        min="0"
        controls-alignment="right" />
    </b-field>
  </div>
</template>

<script>
  export default {
    name: 'TableStyle',
    components: {},
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {}
    },
    computed: {
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
    mounted () {},

    beforeDestroy () {},

    methods: {},
  }
</script>

<style lang="scss" scoped></style>
