<template>
  <div class="company-dimension-ref-editor">
    <CompanyDimensionRefSelect
      v-model="vModel"
      v-bind="subObjectBinds" />
    <ValidationErrors :errors="errors" />
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyDimensionRefSelect from '@/components/reports/config/dimensions/CompanyDimensionRefSelect'

  export default {
    name: 'CompanyDimensionRefEditor',
    components: {
      CompanyDimensionRefSelect,
    },
    mixins: [
      ObjectEditorMixin,
    ],
  }

</script>

<style lang="scss" scoped>
.company-dimension-ref-editor {
  display: flex;
  flex-direction: column;
}
</style>
