<template>
  <div class="ingest-toggle">
    <input
      :id="$id()"
      v-model="checkboxModel"
      type="checkbox" />
    <label :for="$id()">
      <div
        class="option"
        :class="{'selected': checkboxModel}"
        :data-value="left.value">{{ left.value }}</div>
      <div class="switch-container">
        <div
          class="switch"
          :class="{'left': checkboxModel}" />
      </div>
      <div
        class="option"
        :class="{'selected': !checkboxModel}"
        :data-value="right.value">{{ right.value }}</div>
    </label>
  </div>
</template>

<script>

  export default {
    name: 'IngestToggle',
    props: {
      left: { type: Object, required: true, },
      right: { type: Object, required: true, },
      selected: { type: Object, required: true, },
    },
    computed: {
      checkboxModel: {
        get () {
          return this.left.key === this.selected.key
        },
        set (value) {
          if (value) {
            this.$emit('update:selected', this.left)
          } else {
            this.$emit('update:selected', this.right)
          }
        },
      },
    },
    mounted () {
      if (this.selected) {
        this.$emit('update:selected', this.left)
      }
    },
  }

</script>

<style lang="scss" scoped>

.ingest-toggle {
}

input {
  display: none;
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
}

.option {
  position: relative;
  font-size: $h3;
  color: $ui-02;
  margin: 0;
  display: flex;
  flex-direction: column;

  &.selected {
    color: $ui-01;
    font-weight: $emp;
  }

  &::after {
    content: attr(data-value);
    visibility: hidden;
    font-weight: $emp;
    height: 0;
  }
}

.switch-container {
  width: 6 * $gridBase;
  height: 3 * $gridBase;
  border-radius: 1.5 * $gridBase;
  background: $ui-05;
  margin: $gridBase;
}

.switch {
  height: 3 * $gridBase;
  width: 3 * $gridBase;
  border-radius: 1.5 * $gridBase;
  background: $ui-01;
  position: relative;
  left: 3 * $gridBase;;
  transition: left .1s;

  &.left {
    left: 0;
  }
}

</style>

