<template>
  <div class="column-view">
    <chart-title-bar
      :name.sync="nameModel"
      :is-edit="isEdit">
      <template #toolbarButtons>
        <slot name="toolbarButtons" />
      </template>
    </chart-title-bar>
    <template v-if="error">
      <error-view
        :name="name"
        @reload="$emit('reload')" />
    </template>
    <template v-else>
      <slot name="moduleControls" />
      <div class="chart-container">
        <ingest-tooltip
          :left="tooltipX"
          :top="tooltipY"
          :enabled="tooltipEnabled">
          <hover-card
            :columns="columns"
            :datum="tooltipDatum"
            :beta-spec="betaSpec" />
        </ingest-tooltip>
        <resize-sensor @resize="onChartResize" />
        <svg
          ref="svg"
          :viewBox="viewBox"
          @mousemove="onHover"
          @mouseleave="onLeave()"
          @scroll="onLeave()">
          <y-axis
            :columns="columns"
            :hidden-columns="[varianceMeasure]"
            :data="data"
            :chart-width="viewWidth"
            :chart-height="viewHeight" />
          <x-axis
            :columns="columns"
            :data="data"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            :highlighted-data="highlightedData"
            @click="$emit('click', $event)" />
          <column-series
            v-if="!!primaryMeasure"
            :column-series-count="columnSeriesCount"
            :column-idx="0"
            :measure="primaryMeasure"
            :data="data"
            :columns="columns"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            :hidden-columns="[varianceMeasure]"
            :highlight-enabled="highlightEnabled"
            :highlighted-measures="highlightedMeasures"
            :highlighted-data="highlightedData"
            @click="$emit('click', $event)" />
          <column-series
            v-if="!!secondaryMeasure && !!(betaSpec || {}).secondary_as_columns"
            :column-series-count="columnSeriesCount"
            :column-idx="!!primaryMeasure ? 1 : 0"
            :measure="secondaryMeasure"
            :data="data"
            :columns="columns"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            :hidden-columns="[varianceMeasure]"
            :highlight-enabled="highlightEnabled"
            :highlighted-measures="highlightedMeasures"
            :highlighted-data="highlightedData"
            @click="$emit('click', $event)" />
          <constant-annotation
            v-if="!!secondaryMeasure && !(betaSpec || {}).secondary_as_columns"
            :measure="secondaryMeasure"
            :data="data"
            :columns="columns"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            :hidden-columns="[varianceMeasure]"
            :highlight-enabled="highlightEnabled"
            :highlighted-measures="highlightedMeasures"
            :highlighted-data="highlightedData"
            @click="$emit('click', $event)" />
        </svg>
      </div>
      <performance-legend
        v-if="!!primaryMeasure && !!primaryMeasure.colorSpec && (['over_under', 'under_over']
          .includes(primaryMeasure.colorSpec.type)
          || (!!betaSpec.enable_controlled_color_specs &&
            ['controlled', 'over_under', 'under_over']
              .includes(primaryMeasure.colorSpec.type)))
        "
        :columns="columns" />
      <measure-legend
        v-else
        :columns="columns" />
    </template>
  </div>
</template>
<script>

  import ChartTitleBar from '@/components/charting/ChartTitleBar'
  import ColumnSeries from '@/components/charting/ColumnSeries'
  import ConstantAnnotation from '@/components/charting/ConstantAnnotation'
  import ErrorView from '@/components/module/view/ErrorView.vue'
  import HoverCard from '@/components/module/view/HoverCard.vue'
  import IngestTooltip from '@/components/common/IngestTooltip.vue'
  import MeasureLegend from '@/components/charting/MeasureLegend'
  import PerformanceLegend from '@/components/charting/PerformanceLegend'
  import XAxis from '@/components/charting/XAxis'
  import YAxis from '@/components/charting/YAxis'

  import chartContainerMixin from '@/components/charting/chart_container_mixin'

  export default {
    name: 'PerformanceColumnView',
    components: {
      ChartTitleBar,
      ColumnSeries,
      ConstantAnnotation,
      ErrorView,
      HoverCard,
      IngestTooltip,
      MeasureLegend,
      PerformanceLegend,
      YAxis,
      XAxis,
    },
    mixins: [
      chartContainerMixin,
    ],
    computed: {
      columnSeriesCount () {
        if (!this.primaryMeasure && !this.secondaryMeasure) {
          return 0
        } else if (!!this.secondaryMeasure
          && !!this.primaryMeasure
          && !!(this.betaSpec || {}).secondary_as_columns) {
          return 2
        } else  {
          return 1
        }
      },
    },
    methods: {
      onLeave () {
        this.clearHoverValues()
      },
    },
  }

</script>

<style lang="scss" scoped>

.column-view {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 8 * $gridBase;
  padding-top: 0;

  > .chart-container {
    display: flex;
    min-height: 0;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: visible;

    margin-top: 6 * $gridBase;
    margin-bottom: 22 * $gridBase;
    margin-left: 12 * $gridBase;

    > svg {
      overflow: visible;
      width: 100%;
      height: 100%;
    }
  }
}

svg {
  overflow: visible
}

</style>
