import moment from 'moment'

export function convertToFrontend (value, type) {
  if (type === 'boolean') {
    if (typeof value === 'string') {
    return value.toUpperCase()
    }
  }

  if (type === 'date') {
    if (moment(value).toDate() == 'Invalid Date') {
      return null
    }
    return moment(value).toDate()
  }

  if (type === 'time') {
    return moment(value, 'HH:mm').toDate()
  }

  if (type === 'money') {
    return parseFloat(value) / 100
  }

  if (type === 'percent') {
    return parseFloat(value) * 100
  }

  if (type === 'number') {
    return parseFloat(value)
  }
  return value
}

export function convertToBackend (value, type) {
  let newValue = value

  if (type === 'time') {
    newValue = moment(value).format('HH:mm')
    if (newValue === 'Invalid date') {
      newValue = null
    }
  }

  if (type === 'date') {
    newValue = moment(value).format('YYYY-MM-DD')
    if (newValue === 'Invalid date') {
      newValue = null
    }
  }

  if (type === 'number') {
    newValue = parseFloat(value)
  }

  if (type === 'percent') {
    newValue = parseFloat(value) / 100
  }

  if (type === 'money') {
    newValue = parseFloat(value) * 100
  }

  return newValue
}

export function validateValue (value, type) {

  if (type === 'money') {
    return moment(value, 'HH:mm').toDate()
  }

  return value
}
