var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"performance-legend"},[(_vm.primaryColorSpec === 'under_over')?[_c('div',{staticClass:"legend-row",attrs:{"max-width":"128px"}},[_c('div',{staticClass:"legend-item",style:({
          'background-color': _vm.dvGood,
          'box-shadow': `0 0 3px ${_vm.dvGood}`,
        })}),_vm._t("belowLabel",function(){return [_vm._v(" Below average ")]})],2),_c('div',{staticClass:"legend-row",attrs:{"max-width":"128px"}},[_c('div',{staticClass:"legend-item",style:({
          'background-color': _vm.dvBad,
          'box-shadow': `0 0 3px ${_vm.dvBad}`,
        })}),_vm._t("aboveLabel",function(){return [_vm._v(" Above average ")]})],2)]:_vm._e(),(_vm.primaryColorSpec === 'over_under')?[_c('div',{staticClass:"legend-row",attrs:{"max-width":"128px"}},[_c('div',{staticClass:"legend-item",style:({
          'background-color': _vm.dvGood,
          'box-shadow': `0 0 3px ${_vm.dvGood}`,
        })}),_vm._t("aboveLabel",function(){return [_vm._v(" Above average ")]})],2),_c('div',{staticClass:"legend-row",attrs:{"max-width":"128px"}},[_c('div',{staticClass:"legend-item",style:({
          'background-color': _vm.dvBad,
          'box-shadow': `0 0 3px ${_vm.dvBad}`,
        })}),_vm._t("belowLabel",function(){return [_vm._v(" Below average ")]})],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }