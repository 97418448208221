<template>
  <div class="view-dag">
    <div class="container" id="container" />
  </div>
</template>

<script>

  import dagreD3 from 'dagre-d3'
  import dagre from 'dagre'
  import { select } from 'd3'

  export default {
    name: 'ViewDag',
    components: { },
    data () {
      return {
      }
    },
    methods: {
      async drawDag () {
        const views = await this.$apis.app.getReportingViews()
        console.log(views)
        select('#container')
          .append('svg')
          .attr('id', 'svg-canvas')
          .attr('style', 'width: 1500%; height: 400vh;')
          .append('g')

        const graph = new dagreD3.graphlib.Graph()

        graph.setGraph({
          width: '100%',
          height: '100vh',
          ranker: 'longest-path',
          ranksep: 400,
        })
        graph.setDefaultEdgeLabel(function () { return {} })

        views.forEach(view => {
          graph.setNode(view.key, {
            label: view.label,
            style: 'fill:none',
          })
          view.parents.forEach(parent => {
            graph.setEdge(view.key, parent, {
              style: 'stroke-width: 1px; fill: none; stroke: black;',
            })
          })
        })

        dagre.layout(graph)

        const render = new dagreD3.render()

        render(select('svg').select('g'), graph)
      },
    },
    mounted () {
      this.drawDag()
    },
  }

</script>

<style lang="scss" scoped>
.view-dag {
  margin-top: 30px;
  width: 100%;
  height: 100vh;
}
.container {
  width: 100%;
  height: 100vh;
}
</style>
