<template>
  <div class="container">
    <div class="title">
      <h2>Data</h2>
      <p>
        {{ navlinks.data.description }}
      </p>
    </div>

    <div class="columns is-multiline">
      <div
        v-for="(link, i) in navlinks.data.children"
        :key="i"
        class="column is-3">
        <router-link
          class=""
          :to="{ name: link.route.name }">
          <div class="link-card">
            <div class="header">
              <div
                v-if="link.icon"
                class="icon">
                <i class="material-icons item-icon">
                  {{ link.icon }}
                </i>
              </div>
              <div class="name">
                {{ link.name }}
              </div>
            </div>
            <div class="desc">
              {{ link.description }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import navigation from '@/mixins/navigation'

  export default {
    name: 'DataHome',
    components: {},
    mixins: [navigation,],
    data () {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
.container {
  padding: 80px 0 50px 0;
  .title {
    h2 {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight:700;
      color: $ui-01;
    }

    p {
      font-size: 15px;
      color: $grayscale-3;
      font-weight: 300;
      margin-bottom: 50px;
    }
  }
}
.link-card {
  border: $border-1;
  border-radius: 9px;
  font-size: 16px;
  padding: 15px;
  height: 100%;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
  }
  .header {
    display: flex;
    align-items: center;
    color: $grayscale-1;
    font-weight: 500;

    .name {
      font-size: 18px;
    }

    .icon {
      height: 35px;
      width: 35px;
      border-radius: 5px;
      display: block;
      border: $border-1;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      align-items: center;
    }
  }

  .desc {
    font-size: 15px;
    color: $grayscale-3;
    margin-top: 10px;
  }
}
</style>
