<template>
  <div class="container">
    <div v-if="companyInfo">
      <div class="header columns mx-0">
        <div
          v-if="logoUrl"
          class="company-logo">
          <img
            :src="logoUrl"
            alt="" />
        </div>

        <div
          v-else
          class="logo-placeholder">
          <img
            class="placeholder"
            src="@/assets/icons/restaurant.svg"
            alt="" />
        </div>
        <div class="company-info">
          <h2>{{ companyInfo.displayName || companyInfo.name }}</h2>
        </div>
      </div>
      <div class="settings">
        <h3 class="section-name">
          Fiscal Calendar
        </h3>

        <div class="columns mx-0 is-multiline">
          <div class="column px-0 is-3">
            <b-field
              label="Type"
              class="field">
              <b-input
                v-model="companyInfo.fiscalPeriodType"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              label="Current FY Start Date"
              class="field">
              <b-input
                v-model="companyInfo.fiscalStartDate"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              label="Start of Week"
              class="field">
              <b-input
                :value="dayMap[companyInfo.fiscalStartDayOfWeek]"
                disabled />
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="locations">
      <h3>Locations ({{ allLocations.length }})</h3>
      <div class="columns is-vcentered table-filters">
        <div class="column search-input m-0 p-0">
          <b-input
            v-model="searchInput"
            custom-class="no-border"
            placeholder="Search Locations"
            type="search"
            icon="magnify" />
        </div>
      </div>

      <b-table
        :data="resultsToShow"
        :loading="isLoading"
        :hoverable="true"
        class="locations-table"
        @click="openCompanyLocation">
        <b-table-column
          v-slot="props"
          sortable
          field="id"
          width="70"
          label="ID">
          <span class="ml-4">{{ props.row.id }}</span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          sortable
          field="name"
          label="Name">
          {{ props.row.displayName }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          sortable
          centered
          width="120"
          field="isOpen"
          label="Status">
          <b-tag
            size="is-medium"
            class="status-tags"
            :type="props.row.isOpen ? 'is-success' : ''">
            {{ props.row.isOpen ? "Open" : "Closed" }}
          </b-tag>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered mt-5 mb-5">
            <b-tag
              v-if="!isLoading"
              class="data-tags"
              rounded
              size="is-medium">
              No Locations Found 🥥
            </b-tag>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'
  export default {
    name: 'CompanyPage',
    data () {
      return {
        allLocations: [],
        companyInfo: null,
        isLoading: false,
        searchInput: '',
        s3: process.env.VUE_APP_S3_BASE_URL, //todo: move to store later
        dayMap: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',],
      }
    },

    computed: {
      resultsToShow () {
        if (this.searchInput) {
          return this.searcher.search(this.searchInput)
        }
        return this.allLocations
      },

      searcher () {
        const searcher = new FuzzySearch(this.allLocations, ['name',])
        return searcher
      },
      logoUrl () {
        if (this.companyInfo && this.companyInfo.s3Path) {
          return `${this.s3}/${this.companyInfo.s3Path}`
        }
        return false
      },
    },

    async mounted () {
      this.loadCompanyInfo()
      this.loadAllLocations()
    },

    methods: {
      openCompanyLocation (location) {
        this.$router.push({
          name: 'location_settings',
          params: { location_id: location.id, },
        })
      },
      async loadAllLocations () {
        this.isLoading = true
        const response = await this.$apis.company.getAllLocations()
        this.allLocations = response ? response : []
        this.isLoading = false
      },

      async loadCompanyInfo () {
        const response = await this.$apis.company.getCompanyInfo()
        this.companyInfo = response
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  padding: 50px 0;

  .header {
    display: flex;
    align-items: center;

    .company-logo {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 5px;
      border: $border-1;

      img {
        width: 100%;
        height: auto;
      }
    }

    .logo-placeholder {
      width: 80px;
      height: 80px;
      background-color: #eafcf5;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      img {
        height: auto;
        width: 50%;
      }
    }

    .company-info {
      padding-left: 30px;

      h2 {
        font-size: 24px;
        font-weight: 700;
        color: $ui-01;
      }
    }
  }

  .locations {
    margin-top: 50px;

    h3 {
      font-size: 19px;
      margin-bottom: 20px;
    }
  }
  .locations-table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
  }

  .table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .data-tags {
    font-size: 12px;
    margin: 2px;
  }

  .status-tags {
    min-width: 80px;
  }

  .actions {
    display: flex;

    .action-btn {
      margin: 0 5px;
    }
  }
}

.section-name {
  font-size: 19px;
  margin-bottom: 20px;
}
.settings {
  border-bottom: $border-1;
  padding: 40px 0;
  .field {
    margin-right: 20px;
  }
}
</style>

<style lang="scss">
.locations-table {
  cursor: pointer;
}
</style>
