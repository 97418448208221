<template>
  <div class="dashboard-module">
    {{ dashboardModule }}
    <b-button @click.native="loadData">Load Data</b-button>
    Data: {{ data }}
  </div>
</template>

<script>

  export default {
    name: 'DashboardModule',
    props: {
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        data: [],
      }
    },
    methods: {
      async loadData () {
        this.data = await this.$apis.companyConfigs.getDashboardModuleData(
          this.dashboardKey,
          this.dashboardModule.key,
          this.dashboardDataContext
        )
      },
    },
  }

</script>
