<template>
  <div class="scorecard-view">
    <chart-title-bar
      :name.sync="nameModel"
      :is-edit="isEdit">
      <template #toolbarButtons>
        <slot name="toolbarButtons" />
      </template>
    </chart-title-bar>
    <template v-if="error">
      <error-view
        :name="name"
        @reload="$emit('reload')" />
    </template>
    <template v-else>
      <div class="metrics-container">
        <div
          class="primary"
          :style="primaryColorStyle">
          {{ formatAggVal(primaryMeasure, primaryVal) }}
        </div>
        <div
          class="secondary">
          {{ formatAggVal(secondaryMeasure, secondaryVal) }}
        </div>
        <div
          v-if="!!varianceMeasure"
          class="variance">
          <span
            v-if="varianceVal === 0"
            class="material-icons">
            arrow_drop_up
          </span>
          <span
            v-if="varianceVal > 0"
            class="material-icons"
            :style="{'color': varianceColor}">
            arrow_drop_up
          </span>
          <span
            v-if="varianceVal < 0"
            class="material-icons"
            :style="{'color': varianceColor}">
            arrow_drop_down
          </span>
          {{ formatAggVal(varianceMeasure, varianceVal) }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

  import ChartTitleBar from '@/components/charting/ChartTitleBar'
  import ErrorView from '@/components/module/view/ErrorView.vue'
  import helpers from '@/components/charting/helpers'

  import chartComponentMixin from '@/components/charting/chart_component_mixin'

  export default {
    name: 'ScorecardView',
    components: {
      ChartTitleBar,
      ErrorView,
    },
    mixins: [
      chartComponentMixin,
    ],
    props: {
      loading: Boolean,
      columns: { type: Array, required: true, },
      data: { type: Array, default: Array, },
      betaSpec: { type: Object, default: null, },
      name: { type: String, default: '', },
      isEdit: { type: Boolean, default: false, },
      error: { type: Boolean, default: false, },
    },
    data () {
      return {
        chartType: 'scorecard',
      }
    },
    computed: {
      varianceColor () {
        return this.specColor(this.varianceMeasure.colorSpec, this.data.slice(0, 1).shift())
      },
      nameModel: {
        get () {
          return this.name
        },
        set (name) {
          this.$emit('update:name', name)
        },
      },
      primaryColorStyle () {
        if (!this.primaryMeasure) {
          return {}
        }
        return {
          color: this.measureDatumColor(this.primaryMeasure, [...this.data,].shift() || {}),
        }
      },
      datum () {
        if (this.data.length > 0) {
          return this.data[0]
        }
        return null
      },
      primaryMeasure () {
        let candidates =  this.columns.filter(column => column.role == 'primary_measure')
        if (candidates.length > 0) {
          return candidates[0]
        }
        return null
      },
      primaryVal () {
        if (!!this.primaryMeasure && !!this.datum) {
          return this.datum[this.primaryMeasure.key] || 0
        }
        return 0
      },
      secondaryMeasure () {
        let candidates = this.columns.filter(column => column.role == 'secondary_measure')
        if (candidates.length > 0) {
          return candidates[0]
        }
        return null
      },
      secondaryVal () {
        if (!!this.secondaryMeasure && !!this.datum) {
          return this.datum[this.secondaryMeasure.key] || 0
        }
        return 0
      },
      varianceMeasure () {
        let candidates = this.columns.filter(column => column.role == 'variance_measure')
        if (candidates.length > 0) {
          return candidates[0]
        }
        return null
      },
      varianceVal () {
        if (!!this.varianceMeasure && !!this.datum) {
          return this.datum[this.varianceMeasure.key] || 0
        }
        return 0
      },
    },
    methods: {
      ...helpers,
    },
  }

</script>

<style lang="scss" scoped>

.scorecard-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.metrics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 0;
}

.primary {
  color: $secondary-06;
  font-size: 2.6rem;
  font-weight: 700;
}

.secondary {
  color: $secondary-05;
  font-size: 1.8rem;
}

.variance {
  color: $secondary-06;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}
.variance-arrow {
  font-size: $h0;
  transform: translateY(10%);
}
.buttons {
  display: flex;
  align-items: center;
  padding: 2 * $gridBase;

  font-size: 1.8rem;
}

.toolbar {
  display: flex;
  width: 100%;
}

.name {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5 * $gridBase 8 * $gridBase;;
  margin-bottom: 4 * $gridBase;

  div {
    font-size: $h1;
    font-weight: $emp;
  }

  input {
    text-align: center;
  }
}
</style>
