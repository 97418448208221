<template>
  <g
    class="constant-annotation">
    <template
      v-if="!!data && data.length > 0">
      <path
        :d="lineModel"
        :style="{'stroke': ui02, 'stroke-width': 4, 'fill': 'none', 'opacity': '.5'}" />
      <text
        class="annotation-label"
        :x="chartWidth"
        :y="yScale(constantValue) - 8">
        {{ formatAggVal(measure, constantValue) }}
      </text>
    </template>
  </g>
</template>

<script>

  import chartComponentMixin from '@/components/charting/chart_component_mixin'
  import { uiPalette } from '@/components/charting/colors'

  export default {
    name: 'ConstantAnnotation',
    mixins: [
      chartComponentMixin,
    ],
    props: {
      measure: { type: Object, required: true, },
    },
    computed: {
      ui02 () {
        return uiPalette.ui02
      },
      constantValue () {
        // Assumed all data points will have the same value for the given measure
        return this.data.slice(0, 1).shift()[this.measure.key]
      },
      lineModel () {
        return `M0,${this.yScale(this.constantValue)}L${this.chartWidth},${this.yScale(this.constantValue)}`
      },
    },
  }

</script>

<style lang="scss" scoped>

.constant-annotation {
}

.annotation-label {
  font-size: 1.6rem;
  fill: $ui-02;
  alignment-baseline: after-edge;
  text-anchor: end;
}

</style>

