// UI Colors
export const uiPalette = {
  ui01: '#4f4e54',
  ui02: '#6c6b70',
  ui03: '#919097',
  ui04: '#b8b7bc',
  ui05: '#d7d7d9',
  ui06: '#e9e9ea',
  ui07: '#f5f5f5',
}

// Data visualization colors
export const dvPalette = {
    dvPrimary: '#383B55',
    dvSecondary: '#4299B0',

		dvCategory01:	'#383B55',
		dvCategory02:	'#438A9D',
		dvCategory03:	'#7F70CF',
		dvCategory04:	'#67CD9C',
		dvCategory05:	'#B85C81',
		dvCategory06:	'#F17C57',
		dvCategory07:	'#F6C856',
		dvCategory08:	'#52AFC8',
		dvCategory09:	'#8A88E9',
		dvCategory10:	'#F69D4C',
		dvCategory11:	'#91D1E3',
		dvCategory12:	'#D592E8',

    dvBad: '#E73C5D',
    dvBadAlt: '#FB7891',
    dvGood: '#07A169',
    dvGoodAlt: '#2CD396',
}
