<template>
  <div class="ingest-table">
    <busy-overlay
      :enabled="busy"
      :loading="loading"
      :message="busyMessage" />
    <div
      class="overflow-container"
      @scroll="scrollHandler">
      <resize-sensor @resize="resizeHandler" />
      <div class="table">
        <div class="headers">
          <div
            v-if="pivotLabels.length > 0"
            :style="{ height: `${pivotHeadersHeight}px` }"
            class="pivot-headers">
            <div class="dimension-headers">
              <div
                v-for="dimension in dimensions"
                :key="`dimension-header-${dimension.key}`"
                class="header"
                :style="{ width: `${getDimensionWidth(dimension)}px` }" />
            </div>
            <div
              v-for="pivotTotal in pivotTotals"
              :key="`total-header-${pivotTotal.key}`"
              class="header"
              :style="{ width: `${getPivotTotalWidth(pivotTotal)}px` }" />
            <div
              v-for="pivotValue in pivotValues"
              :key="pivotValue.key"
              :style="{ width: `${getPivotValueWidth(pivotValue)}px` }"
              class="header pivot-header">
              <div class="header-div pivot-header">
                {{ pivotValue.label }}
              </div>
            </div>
          </div>
          <div
            class="sub-headers"
            :style="{
              width: `${dataWidth}px`,
            }">
            <div
              class="dimension-headers"
              :style="{
                height: `${pivotHeadersHeight}px`,
              }">
              <div
                v-for="dimension in dimensions"
                :key="`dimension-${dimension.key}`"
                :style="{ width: `${getDimensionWidth(dimension)}px` }"
                class="header clickable nowrap ellipsis"
                @click="
                  $emit('headerClick', dimension);
                  pivotSort = null;
                ">
                <div class="header-div clickable">
                  <i
                    v-if="dimension.sortDir == 'desc'"
                    class="sort-icon material-icons">expand_more </i>

                  <i
                    v-if="dimension.sortDir == 'asc'"
                    class="sort-icon material-icons">expand_less </i>

                  {{ dimension.name }}
                </div>
              </div>
            </div>
            <div
              class="measure-headers"
              :style="{ height: `${pivotHeadersHeight}px` }">
              <template v-for="column in visibleColumns">
                <div
                  v-if="column.type == 'pivot-total'"
                  :key="`pivotTotal-${column.pivotTotal.key}`"
                  :style="{
                    width: `${column.width}px`,
                    transform: `translateX(${offsetX}px)`,
                  }"
                  class="header clickable nowrap ellipsis"
                  @click="
                    $emit('headerClick', column.pivotTotal);
                    pivotSort = null;
                  ">
                  <div class="header-div total-header">
                    <i
                      v-if="column.pivotTotal.sortDir== 'desc'"
                      class="sort-icon material-icons">expand_more </i>

                    <i
                      v-if="column.pivotTotal.sortDir == 'asc'"
                      class="sort-icon material-icons">expand_less </i>

                    {{ column.pivotTotal.name }}
                  </div>
                </div>
                <div
                  v-if="column.type == 'pivot-measure'"
                  :key="`pivot-${column.pivot.key}-measure-${column.measure.key}`"
                  :style="{
                    width: `${column.width}px`,
                    transform: `translateX(${offsetX}px)`,
                  }"
                  class="header clickable nowrap ellipsis"
                  @click="
                    $emit('headerClick', column.measure);
                    pivotSort = column.pivot;
                  ">
                  <div class="header-div clickable">
                    <i
                      v-if="
                        column.measure.sortDir == 'desc' &&
                          !!pivotSort &&
                          pivotSort.key == column.pivot.key
                      "
                      class="sort-icon material-icons">expand_more </i>

                    <i
                      v-if="
                        column.measure.sortDir == 'asc' &&
                          !!pivotSort &&
                          pivotSort.key == column.pivot.key
                      "
                      class="sort-icon material-icons">expand_less </i>

                    {{ column.measure.name }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div
          class="data-container"
          :style="{
            height: `${dataHeight}px`,
            width: `${dataWidth}px`,
          }"
          :items="pivotedRows.map((row, idx) => ({ row, idx }))"
          :item-size="52"
          :key-field="'idx'">
          <div
            v-for="item of visibleRows"
            :key="item.idx"
            :style="{
              height: `${rowHeight}px`,
              transform: `translateY(${offsetY}px)`,
            }"
            class="data-row">
            <div class="row-dimensions">
              <template v-for="(dimension, dimensionIdx) in dimensions">
                <data-field-v2
                  :key="`dimension-${dimension.key}`"
                  :field="dimension"
                  :value="item.row[dimension.key]"
                  :all-fields="fields"
                  :row="item.row"
                  :style="{ width: `${getDimensionWidth(dimension)}px` }"
                  @click.native="
                    $emit('dataClick', {
                      row: item.row,
                      field: dimension,
                      rowIdx: item.idx,
                      dimensionIdx,
                    })
                  " />
              </template>
            </div>
            <template v-for="column in visibleColumns">
              <data-field-v2
                v-if="column.type == 'pivot-total'"
                :key="`pivotTotal-${column.pivotTotal.key}`"
                :field="column.pivotTotal"
                :value="item.row[column.pivotTotal.key]"
                :all-fields="fields"
                :row="item.row"
                :style="{
                  width: `${column.width}px`,
                  transform: `translateX(${offsetX}px)`,
                }"
                is-total
                @click.native="
                  $emit('dataClick', {
                    row: item.row,
                    field: column.pivotTotal,
                    rowIdx: item.idx,
                    pivotTotalIdx: column.idx,
                  })
                " />
              <data-field-v2
                v-if="column.type == 'pivot-measure'"
                :key="`pivot-${column.pivot.key}-measure-${column.measure.key}`"
                :field="column.measure"
                :value="
                  ((item.row.pivots[column.pivot.key] || {})[0] || {})[
                    column.measure.key
                  ]
                "
                :all-fields="fields"
                :row="(item.row.pivots[column.pivot.key] || {})[0] || {}"
                :style="{
                  width: `${column.width}px`,
                  transform: `translateX(${offsetX}px)`,
                }"
                @click.native="
                  $emit('dataClick', {
                    row: item.row,
                    field: column.measure,
                    rowIdx: item.idx,
                    measureIdx: idx,
                  })
                " />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="width-samplers">
      <div
        ref="fakeTotalHeader"
        class="fake-total-header" />
      <div
        ref="fakeHeader"
        class="fake-header" />
      <div
        ref="fakeTotalField"
        class="fake-total-field" />
      <div
        ref="fakeField"
        class="fake-field" />
    </div>
  </div>
</template>

<script>
  import DataFieldV2 from '@/components/common/table/DataFieldV2'
  import BusyOverlay from '@/components/common/BusyOverlay'

  import {
    throttle,
    uniqBy,
    groupBy,
    min,
    orderBy,
    debounce
  } from 'lodash'

  import helpers from '@/components/charting/helpers'

  export default {
    name: 'IngestTable',
    components: {
      DataFieldV2,
      BusyOverlay,
    },
    props: {
      busy: Boolean,
      loading: Boolean,
      busyMessage: { type: String, default: '', },
      fields: { type: Array, default: Array, },
      dimensionKeys: { type: Array, default: Array, },
      rows: { type: Array, default: Array, },
    },
    data () {
      return {
        pivotSort: null,
        pivotHeadersHeight: 52,
        measureHeadersHeight: 52,
        rowHeight: 52,
        scrollTop: 0,
        scrollLeft: 0,
        rowPad: 4,
        colPad: 4,
        viewHeight: 0,
        viewWidth: 0,
        pivotValues: [],
        pivotedRows: [],
        columnWidths: {},
        canvas: null,
      }
    },
    computed: {
      dataHeight () {
        return this.rowHeight * this.pivotedRows.length
      },
      rowItems () {
        return this.pivotedRows.map((row, idx) => ({ row, idx, }))
      },
      startIdx () {
        let startIdx = Math.floor(this.scrollTop / this.rowHeight) - this.rowPad
        startIdx = Math.max(startIdx, 0)
        return startIdx
      },
      visibleRows () {
        let rowCount =
          Math.ceil(this.viewHeight / this.rowHeight) + 2 * this.rowPad
        let endIdx = Math.min(this.startIdx + rowCount, this.pivotedRows.length)
        return this.rowItems.slice(this.startIdx, endIdx)
      },
      offsetY () {
        return this.startIdx * this.rowHeight
      },
      scrollableColumns () {
        return [
          ...this.pivotTotals.map((pivotTotal, idx) => ({
            type: 'pivot-total',
            pivotTotal,
            width: this.getPivotTotalWidth(pivotTotal),
            idx,
          })),
          ...this.pivotValues
            .map((pivot) =>
              this.measures.map((measure, idx) => ({
                type: 'pivot-measure',
                pivot,
                measure,
                width: this.getPivotMeasureWidth(pivot, measure),
                idx,
              }))
            )
            .flat(),
        ]
      },
      dataWidth () {
        return [
          ...Object.values(this.columnWidths.dimensions || {}),
          ...this.scrollableColumns.map((c) => c.width),
        ].reduce((a, b) => a + b, 0)
      },
      startColumn () {
        let runningWidth = Object.values(
          this.columnWidths.dimensions || {}
        ).reduce((a, b) => a + b, 0)
        let startIdx = 0
        this.scrollableColumns.every((c, idx) => {
          runningWidth += c.width
          startIdx = idx
          if (runningWidth >= this.scrollLeft) {
            return false
          }
          return true
        })
        return Math.max(startIdx - this.colPad, 0)
      },
      endColumn () {
        let runningWidth = 0
        let endIdx = this.startColumn
        this.scrollableColumns.slice(this.startColumn).every((c, idx) => {
          runningWidth += c.width
          endIdx = this.startColumn + idx
          if (runningWidth >= this.viewWidth) {
            return false
          }
          return true
        })
        return Math.min(endIdx + 2 * this.colPad, this.scrollableColumns.length)
      },
      visibleColumns () {
        return this.scrollableColumns.slice(this.startColumn, this.endColumn)
      },
      offsetX () {
        return this.scrollableColumns
          .slice(0, this.startColumn)
          .map((c) => c.width)
          .reduce((a, b) => a + b, 0)
      },
      dimensions () {
        return this.fields.filter((f) =>
          [
            'primary_dimension',
            'primary_dimension_key',
            'secondary_dimension',
            'secondary_dimension_key',
          ].includes(f.role)
        )
      },
      measures () {
        return this.fields.filter((f) =>
          ['primary_measure', 'secondary_measure', 'variance_measure',].includes(
            f.role
          )
        )
      },
      pivotTotals () {
        return this.fields.filter((f) =>
          [
            'primary_pivot_total',
            'secondary_pivot_total',
            'variance_pivot_total',
          ].includes(f.role)
        )
      },
      pivotKeys () {
        return this.fields.filter((f) => f.role == 'pivot_dimension_key')
      },
      pivotLabels () {
        return this.fields.filter((f) => f.role == 'pivot_dimension')
      },
      pivotOrders () {
        return [].concat(
          this.fields.filter((f) => f.role == 'pivot_dimension_order'),
          this.pivotLabels
        )
      },
      throttleScroll () {
        return throttle(this.onScroll, 100)
      },
      throttleResize () {
        return debounce(this.onResize, 200)
      },
    },
    watch: {
      rows () {
        this.pivotValues = this.getPivotValues()
        this.pivotedRows = this.getPivotedRows()
        this.columnWidths = this.getColumnWidths()
      },
    },
    methods: {
      getDimensionWidth (dimension) {
        return (this.columnWidths.dimensions || {})[dimension.key]
      },
      getPivotTotalWidth (pivotTotal) {
        return (this.columnWidths.pivotTotals || {})[pivotTotal.key]
      },
      getPivotValueWidth (pivotValue) {
        return Object.values(
          (this.columnWidths.pivotMeasures || {})[pivotValue.key] || {}
        ).reduce((a, b) => a + b, 0)
      },
      getPivotMeasureWidth (pivot, measure) {
        return ((this.columnWidths.pivotMeasures || {})[pivot.key] || {})[
          measure.key
        ]
      },
      getColumnWidths () {
        let dimensions = {}
        this.dimensions.forEach((dimension) => {
          dimensions[dimension.key] = this.getTextWidth(
            dimension.name,
            this.getCanvasFont(this.$refs.fakeHeader)
          )
        })
        let pivotTotals = {}
        this.pivotTotals.forEach((pivotTotal) => {
          pivotTotals[pivotTotal.key] = this.getTextWidth(
            pivotTotal.name,
            this.getCanvasFont(this.$refs.fakeTotalHeader)
          )
        })
        let pivotMeasures = {}
        this.pivotValues.forEach((pivot) => {
          pivotMeasures[pivot.key] = {}
          this.measures.forEach((measure) => {
            pivotMeasures[pivot.key][measure.key] = this.getTextWidth(
              measure.name,
              this.getCanvasFont(this.$refs.fakeHeader)
            )
          })
        })

        this.pivotedRows.forEach((row) => {
          this.dimensions.forEach((dimension) => {
            dimensions[dimension.key] = Math.max(
              dimensions[dimension.key],
              this.getTextWidth(
                helpers.formatAggVal(dimension, row[dimension.key]),
                this.getCanvasFont(this.$refs.fakeField),
                256
              )
            )
          })
          this.pivotTotals.forEach((pivotTotal) => {
            pivotTotals[pivotTotal.key] = Math.max(
              pivotTotals[pivotTotal.key],
              this.getTextWidth(
                helpers.formatAggVal(pivotTotal, row[pivotTotal.key]),
                this.getCanvasFont(this.$refs.fakeTotalField),
                256
              )
            )
          })
          this.pivotValues.forEach((pivot) => {
            this.measures.forEach((measure) => {
              pivotMeasures[pivot.key][measure.key] = Math.max(
                pivotMeasures[pivot.key][measure.key],
                this.getTextWidth(
                  '  ' +
                    helpers.formatAggVal(
                      measure,
                      ((row.pivots[pivot.key] || {})[0] || {})[measure.key]
                    ),
                  this.getCanvasFont(this.$refs.fakeField),
                  256
                )
              )
            })
          })
        })
        return {
          dimensions,
          pivotTotals,
          pivotMeasures,
        }
      },
      getPivotValues () {
        return orderBy(
          uniqBy(
            this.rows.map((row) => ({
              key: helpers.getDimensionVal(this.pivotKeys, row),
              label: helpers.getDimensionVal(this.pivotLabels, row),
              order: helpers.getDimensionOrder(this.pivotOrders, row),
            })),
            (pivot) => pivot.key
          ),
          this.pivotOrders.map((o, idx) => {
            return (pivot) => pivot.order[idx]
          })
        )
      },
      getPivotedRows () {
        let rows = this.rows
        rows = rows.map((row, idx) => {
          return { ...row, pivotRank: idx, }
        })
        rows = Object.values(
          groupBy(rows, (row) => helpers.getDimensionVal(this.dimensionKeys, row))
        ).map((group) => {
          let pivotedGroup = {}
          this.dimensions.forEach((d) => {
            pivotedGroup[d.key] = group[0][d.key]
          })
          this.pivotTotals.forEach((d) => {
            pivotedGroup[d.key] = group[0][d.key]
          })
          pivotedGroup.pivots = groupBy(group, (row) =>
            helpers.getDimensionVal(this.pivotKeys, row)
          )
          return pivotedGroup
        })

        if (this.pivotSort) {
          rows = orderBy(rows, (row) => {
            return ((row.pivots[this.pivotSort.key] || {})[0] || {}).pivotRank
          })
        } else {
          rows = orderBy(rows, (row) => {
            return min(Object.values(row.pivots).map((r) => r[0])).pivotRank
          })
        }
        return rows
      },
      scrollHandler (e) {
        this.throttleScroll(e)
      },
      onScroll (e) {
        if (this.scrollTop !== e.target.scrollTop) {
          this.scrollTop = e.target.scrollTop
        }
        if (this.scrollLeft !== e.target.scrollLeft) {
          this.scrollLeft = e.target.scrollLeft
        }
      },
      resizeHandler (e) {
        this.throttleResize(e)
      },
      onResize (viewRect) {
        this.$nextTick(() => {
          if (this.viewHeight !== viewRect.height) {
            this.viewHeight = viewRect.height
          }
          if (this.viewWidth !== viewRect.width) {
            this.viewWidth = viewRect.width
          }
        })
      },
      /**
       * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
       *
       * @param {String} text The text to be rendered.
       * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
       *
       * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
       */
      getTextWidth (text, font, max) {
        const canvas =
          this.canvas || (this.canvas = document.createElement('canvas'))
        const context = canvas.getContext('2d')
        context.font = font
        const metrics = context.measureText(text)
        // 32 (header margin) + text length + 28 (icon spacer)
        return Math.min(32 + Math.ceil(metrics.width) + 28, max || 1000000)
      },

      getCssStyle (element, prop) {
        return window.getComputedStyle(element, null).getPropertyValue(prop)
      },
      getCanvasFont (el = document.body) {
        const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal'
        const fontSize = this.getCssStyle(el, 'font-size') || '16px'
        const fontFamily =
          this.getCssStyle(el, 'font-family') || 'Times New Roman'

        return `${fontWeight} ${fontSize} ${fontFamily}`
      },
    },
  }
</script>

<style lang="scss" scoped>
.ingest-table {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.overflow-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: fit-content;
  background: $secondary-01;
}

.headers {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: fit-content;
  position: sticky;
  top: 0;
  background: $secondary-01;
  z-index: 1;
  border-bottom: $border-1;
}

.pivot-headers {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: fit-content;
}

.sub-headers {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: fit-content;
}

.measure-headers {
  display: flex;
  flex-direction: row;
}

.dimension-headers,
.row-dimensions {
  display: flex;
  flex-direction: row;
  position: sticky;
  left: 0;
  background: inherit;
  z-index: 2;
  border-right: $border-1;
}

.pivot-header {
  flex-shrink: 0;
}

.data-row {
  display: flex;
  border-top: $border-1;
}

.data-field-v2 {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.header {
  flex-grow: 1;
  background-color: $secondary-01;
  vertical-align: bottom;
}

.header-div {
  font: $h3-dw-sl;
  color: $ui-03;

  display: flex;
  align-items: center;
  box-shadow: 0 3px 3px -3px $secondary-01;
  margin: 0 -3px;
  padding: 12px;
  text-align: center;
  height: 100%;
  position: relative;

  &.pivot-header {
    font: $h3-ew-sl;
    color: $ui-02;
    justify-content: flex-start;
    border-bottom: 1px solid $ui-06;
    padding: 2 * $gridBase 3 * $gridBase;
  }

  &.total-header {
    font: $h3-dw-sl;
    color: $ui-02;
  }
}

.header.pivot-header {
  padding: 0 $gridBase !important;
}
.clickable {
  cursor: pointer;
}
.data-row {
  background: $secondary-01;

  &:hover {
    background: $grayscale-7;

    &.active {
      color: $secondary-02;
      background: $secondary-04;
    }
  }
}
.row.active {
  background: $secondary-04;
  color: $secondary-02;
}
.sort-icon {
  margin: 2 * $gridBase;
  font-size: 14px;
}

.fake-total-header {
  font: $h3-dw-sl;
}
.fake-pivot-header {
  font: $h3-ew-sl;
}
.fake-header {
  font: $h3-dw-sl;
}
.fake-total-field {
  font: $h2-dw-sl;
}
.fake-field {
  font: $h2-lw-sl;
}
</style>
)
