<template>
  <div
    class="dashboard">
    <div
      v-if="dashboard"
      class="content">
      <dashboard-header
        :brand-logo-fname="brandLogoFname"
        :filters="dashboard.controls"
        :beta-spec="betaSpec"
        :report-name="name" />

      <div class="overflow-wrapper">
        <div>
          <control-bar
            class="controls"
            :read-only="!isEdit"
            :dimension-options="dimensions"
            :filters.sync="filters"
            :beta-spec="betaSpec"
            :has-logo-icon="!!brandLogoFname" />
          <div
            v-if="
              headerOptionControls.length > 0 ||
                dateOffsetControls.length > 0 ||
                hasDrilldownReset
            "
            class="header-controls"
            :class="{ hasIcon: !!brandLogoFname }">
            <template v-for="(control, idx) in headerOptionControls">
              <div
                v-if="idx !== 0"
                :key="control.id"
                class="control-spacer" />
              <option-select
                :key="`control-${control.id}`"
                :control="control" />
            </template>
            <template v-if="hasDrilldownReset">
              <div class="spacer" />
              <b-button
                v-if="hasActiveDrilldowns"
                class="drilldown-reset"
                @click="resetDrilldowns">
                Reset drill downs <span class="material-icons">refresh</span>
              </b-button>
            </template>
          </div>
        </div>
        <div
          ref="overflow"
          class="overflow">
          <dashboard-grid
            ref="gridOrigin"
            :modules="effectiveModules"
            :is-edit="false"
            :use-bigquery="betaSpec.use_bq"
            :edit-target.sync="editTarget"
            :drag-target="dragTarget"
            :filters="filters"
            :selections="selections"
            :drilldowns.sync="drilldowns"
            :controls="controls"
            :measures="measures"
            :dimensions="dimensions"
            :dashboard-id="dashboard.id"
            :report-name="dashboard.name"
            @start="onStart"
            @move="onMove"
            @resize="onResize"
            @drop="onDrop"
            @update:module="onModuleChange"
            @deleteModule="deleteModule" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  import ControlBar from '@/components/dashboard/ControlBar'
  import DashboardGrid from '@/components/dashboard/DashboardGrid'

  import OptionSelect from '@/components/controls/OptionSelect'
  import DashboardHeader from '@/components/dashboard/DashboardHeader'

  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'DashboardComponent',
    components: {
      ControlBar,
      DashboardGrid,
      OptionSelect,
      DashboardHeader,
    },
    props: {
      dashboard: { type: Object, required: true, },
      isEdit: Boolean,
    },
    data () {
      return {
        newModule: null,
        editTarget: null,
        dragTarget: null,
        gridAspect: Math.sqrt(2),
        scrollTimer: null,
        selectedDateRange: null,
        drilldowns: {},
        unwatch: () => {},
      }
    },
    computed: {
      ...mapGetters('dashboards', ['cellHeight', 'cellWidth',]),
      ...mapState('dashboards', {
        columns: (state) => state.columns,
      }),
      ...mapGetters('controls', {
        selections: 'controlSelections',
      }),
      yearDimension () {
        return this.$store.getters['metadata/dimensions']['year']
      },
      quarterDimension () {
        return this.$store.getters['metadata/dimensions']['quarter']
      },
      periodDimension () {
        return this.$store.getters['metadata/dimensions']['period']
      },
      dateDimension () {
        return this.$store.getters['metadata/dimensions']['date']
      },
      isOwner () {
        return (
          this.$store.getters['auth/companyUser'].id === this.dashboard.owner_id
        )
      },
      isEditModel: {
        get () {
          return this.isEdit
        },
        set (value) {
          this.$emit('update:isEdit', value)
        },
      },
      effectiveModules () {
        let effectiveModules = [...this.modules,]
        if (this.newModule != undefined) {
          effectiveModules.unshift(this.newModule)
          effectiveModules = this.updatePositions(
            effectiveModules,
            this.newModule
          )
        }
        return effectiveModules
      },
      betaSpec: {
        get () {
          return this.dashboard.beta_spec || {}
        },
        set (beta_spec) {
          this.$emit('update:dashboard', { ...this.dashboard, beta_spec, })
        },
      },
      betaSpecJson: {
        get () {
          return JSON.stringify(this.betaSpec)
        },
        set (json) {
          this.betaSpec = JSON.parse(json)
        },
      },
      brandLogoFname () {
        if (this.betaSpec.logo_control) {
          if (
            !!this.selections[this.betaSpec.logo_control] &&
            this.selections[this.betaSpec.logo_control].length > 0
          ) {
            return this.selections[this.betaSpec.logo_control][0].brand_logo
          } else {
            return this.$store.getters['metadata/companyMetadata']['logo_fpath']
          }
        }
        return undefined
      },
      controlTitle () {
        if (
          !!this.betaSpec.title_control &&
          !!this.selections[this.betaSpec.title_control] &&
          this.selections[this.betaSpec.title_control].length > 0
        ) {
          return this.selections[this.betaSpec.title_control][0].value
        }
        return undefined
      },
      name: {
        get () {
          return this.dashboard.name
        },
        set (name) {
          this.$emit('update:dashboard', { ...this.dashboard, name, })
        },
      },
      sharedWith: {
        get () {
          return this.dashboard.shared_with
        },
        set (shared_with) {
          this.$emit('update:dashboard', { ...this.dashboard, shared_with, })
        },
      },
      filters: {
        get () {
          return this.dashboard.filters
        },
        set (filters) {
          this.$emit('update:dashboard', { ...this.dashboard, filters, })
        },
      },
      controls: {
        get () {
          return this.dashboard.controls || []
        },
        set (controls) {
          this.$emit('update:dashboard', { ...this.dashboard, controls, })
        },
      },
      modules: {
        get () {
          return this.dashboard.modules || []
        },
        set (modules) {
          this.$emit('update:dashboard', { ...this.dashboard, modules, })
        },
      },
      measures () {
        return Object.values(this.$store.getters['metadata/measures'])
      },
      dimensions () {
        return Object.values(this.$store.getters['metadata/dimensions'])
      },
      headerOptionControls () {
        return this.controls.filter((c) => c.type === 'option-select' && !!c.id)
      },
      dateOffsetControls () {
        return this.controls.filter(
          (c) => c.type === 'date-offset-select' && !!c.id
        )
      },
      hasDrilldownReset () {
        return (
          this.controls.filter((c) => c.type === 'header-drilldown-reset')
            .length > 0
        )
      },
      hasActiveDrilldowns () {
        for (let p of Object.values(this.drilldowns)) {
          if (p.length > 0) {
            return true
          }
        }
        return false
      },
    },
    mounted () {
      let seededSelections = { ...this.selections, }
      this.$store.dispatch('expressions/initialize')
      for (let c of this.headerOptionControls) {
        seededSelections[c.id] = c.spec.options.slice(0, 1).shift()
      }
      for (let c of this.dateOffsetControls) {
        seededSelections[c.id] = c.spec.options
          .filter(this.isOffsetViable)
          .slice(0, 1)
          .shift()
      }
      this.unwatch = this.$store.watch(
        (state, getters) => getters['datePicker/appliedEffectiveDates'],
        () => {
          for (let c of this.dateOffsetControls) {
            let newSeededSelections = { ...this.selections, }
            newSeededSelections[c.id] = c.spec.options
              .filter(this.isOffsetViable)
              .slice(0, 1)
              .shift()
            this.$emit('update:selections', newSeededSelections)
          }
        }
      )
      for (let m of this.modules) {
        if (m.controls) {
          for (let c of m.controls) {
            if (c.type === 'module-option-toggle') {
              seededSelections[c.id] = c.spec.left
            }
          }
        }
      }
      this.$emit('update:selections', seededSelections)
    },
    destroyed () {
      this.unwatch()
    },
    methods: {
      isOffsetViable (offsetOption) {
        let start = new Date(
          this.$store.getters['datePicker/appliedEffectiveRangeStart']
        )
        let end = new Date(
          this.$store.getters['datePicker/appliedEffectiveRangeEnd']
        )
        let durationDays = (end - start) / (1000 * 60 * 60 * 24) + 1
        return offsetOption.max_duration >= durationDays
      },
      updateOptionSelection (control, option) {
        let newSelects = { ...this.selections, }
        newSelects[control.id] = option
        this.$emit('update:selections', newSelects)
      },
      resetDrilldowns () {
        this.drilldowns = {}
      },
      onNewModuleStart (template) {
        let key = Math.max(0, ...this.modules.map((m) => m.id || m.key)) + 1
        let module = { ...template, key, view: undefined, }
        this.onStart(module)
      },
      onModuleChange ({ newModule, }) {
        let newModules = [...this.effectiveModules,]
        newModules = newModules.filter((m) => m.id !== newModule.id)
        newModules.push(newModule)
        this.modules = newModules
      },
      onNewModuleMove ({ dragRect, template, }) {
        this.setDragTimer(dragRect)
        let module = this.dragTarget
        let gridRect = this.$refs.gridOrigin.$el.getBoundingClientRect()
        let w = template.position.w
        let h = template.position.h
        let x = Math.round((dragRect.left - gridRect.left) / this.cellWidth)
        x = Math.max(0, Math.min(this.columns - w, x))
        let y = Math.round((dragRect.top - gridRect.top) / this.cellHeight)
        y = Math.max(0, y)
        this.newModule = { ...module, position: { x, y, w, h, }, }
      },
      onNewModuleDrop (template) {
        let module = this.newModule
        this.newModule = undefined
        module.view = template.view
        this.modules = this.updatePositions([module, ...this.modules,], module)
        this.onDrop()
        Vue.nextTick(() => {
          this.editTarget = module
        })
      },
      onStart (module) {
        this.dragTarget = module
      },
      setDragTimer (dragRect) {
        clearInterval(this.scrollTimer)
        let overflowRect = this.$refs.overflow.getBoundingClientRect()
        if (dragRect.y - overflowRect.y - overflowRect.height > -150) {
          this.scrollTimer = setInterval(() => {
            this.$refs.overflow.scroll(0, this.$refs.overflow.scrollTop + 20)
          }, 50)
        } else if (dragRect.y - overflowRect.y < 50) {
          this.scrollTimer = setInterval(() => {
            this.$refs.overflow.scroll(0, this.$refs.overflow.scrollTop - 20)
          }, 50)
        }
      },
      onResize ({ dragRect, module, }) {
        let gridRect = this.$refs.gridOrigin.$el.getBoundingClientRect()
        let x = Math.round((dragRect.left - gridRect.left) / this.cellWidth)
        x = Math.min(Math.max(0, x), this.columns)
        let y = Math.round((dragRect.top - gridRect.top) / this.cellHeight)
        let w = Math.round(dragRect.width / this.cellWidth)
        w = Math.min(Math.max(1, w), this.columns - x)
        let h = Math.round(dragRect.height / this.cellHeight)
        h = Math.max(0, h)
        module.position = { x, y, w, h, }
        this.modules = this.updatePositions(this.modules, module)
      },
      onMove ({ dragRect, module, }) {
        this.setDragTimer(dragRect)
        let gridRect = this.$refs.gridOrigin.$el.getBoundingClientRect()
        let x = Math.round((dragRect.left - gridRect.left) / this.cellWidth)
        let y = Math.round((dragRect.top - gridRect.top) / this.cellHeight)
        let w = Math.round(dragRect.width / this.cellWidth)
        w = Math.min(Math.max(0, w), this.columns)
        x = Math.min(Math.max(0, x), this.columns - w)
        let h = Math.round(dragRect.height / this.cellHeight)
        h = Math.max(1, h)
        module.position = { x, y, w, h, }
        this.modules = this.updatePositions(this.modules, module)
      },
      updatePositions (modules, updatedModule) {
        let updatedIdx = modules.indexOf(updatedModule)
        let newModules = [...modules,]
        if (updatedIdx >= 0) {
          newModules.splice(updatedIdx, 1)
          newModules.unshift(updatedModule)
        }
        newModules.sort((a, b) => {
          if (a.position.y > b.position.y) {
            return 1
          } else if (a.position.y < b.position.y) {
            return -1
          } else if (a == updatedModule) {
            return -1
          } else if (b == updatedModule) {
            return 1
          } else {
            return 0
          }
        })
        let columns = [0, 0, 0, 0,]
        for (let m of newModules.keys()) {
          let module = newModules[m]
          let newY = -1
          for (let c of Array(module.position.w).keys()) {
            newY = Math.max(newY, columns[c + module.position.x])
          }
          for (let c of Array(module.position.w).keys()) {
            columns[c + module.position.x] = newY + module.position.h
          }
          module.position.y = newY
        }
        return newModules
      },
      onDrop () {
        clearInterval(this.scrollTimer)
        this.dragTarget = undefined
      },
      deleteModule (module) {
        if (!window.confirm(`Delete module, "${module.name}"?`)) {
          return
        }
        let newModules = [...this.modules.filter((m) => m.id != module.id),]
        this.modules = this.updatePositions(newModules, null)
      },
    },
  }
</script>

<style lang="scss" scoped>

.controls {
  z-index: 3;
}

.edit-panes {
  z-index: 7;
  max-width: 212px;
  display: flex;
  position: relative;
}

.content {
  background-color: transparent;
}

.controls-ribbon {
  display: flex;
  padding: 12px;
}

.header-controls {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  z-index: 2;
}

.drilldown-reset {
  border-radius: 2 * $gridBase;
  background: $ui-06;
  display: flex;
  align-items: center;
  padding: $gridBase 2 * $gridBase;
  color: $ui-04;
  font-weight: bold;

  &.active {
    @include shadow(false, $ui-warning);
    background: $ui-warning;
    color: $ui-07;
  }

  .material-icons {
    margin-left: 2 * $gridBase;
  }
}

.control-spacer {
  width: 0;
  height: 6 * $remGrid;
  border-left: 1px solid $ui-04;
  margin: 0 4 * $gridBase;
}

.option-control-dropdown {
  margin-left: 4 * $gridBase;
}

.option-control-body {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  width: 64 * $gridBase;
  display: flex;
  flex-direction: column;
  background: $ui-07;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 2 * $gridBase;
  border-bottom-right-radius: 2 * $gridBase;
  padding: $gridBase 0;
  overflow: hidden;

  .b-button {
    padding: $gridBase 2 * $gridBase;
    background: $ui-07;
    color: $ui-02;
    font-size: $h3;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipses;
    text-align: left;

    &:hover {
      color: $ui-01;
      background: $ui-05;
    }

    &.selected {
      color: $ui-07;
      background: $ui-01;
    }
  }
}

.header-option-control {
  border-bottom-left-radius: 2 * $gridBase;
  border-bottom-right-radius: 2 * $gridBase;
  display: flex;
  align-items: center;
  color: $ui-02;
  font-size: $h2;
}

.option-control-button {
  text-align: left;
  width: 64 * $gridBase;
  border-radius: 2 * $gridBase;
  background: $ui-02;
  color: $ui-07;

  font-size: $h3;
  padding: $gridBase 2 * $gridBase;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: $ui-01;
  }

  .material-icons {
    color: $ui-05;
    font-size: 4 * $remGrid;
    line-height: 4 * $remGrid;
    transition: transform 0.3s;
  }

  &.isOpen {
    color: $ui-01;
    background: $ui-07;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .material-icons {
      color: $ui-02;
      font-size: 4 * $remGrid;
      transform: rotate(-180deg);
    }
  }
}

.spacer {
  flex-grow: 1;
}
</style>
