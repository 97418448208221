<template>
  <div class="boolean-filter">
    <input
      v-model="selected"
      type="checkbox"
      :value="true" />
    <label> True </label>
    <input
      v-model="selected"
      type="checkbox"
      :value="false" />
    <label> False </label>
  </div>
</template>

<script>

  export default {
    name: 'BooleanFilter',
    props: {
      dimension: { type: Object, required: true, },
      filter: { type: Object, default: null, },
    },
    computed: {
      selected: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.values
          }
          return [true, false, ]
        },
        set (values) {
          this.$emit('update:filter', {
            type: 'in',
            dimension: this.dimension.key,
            args: { values, },
          })
        },
      },
    },
  }

</script>

<style lang="scss" scoped>

.boolean-filter {
}

</style>
