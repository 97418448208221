<template>
  <div class="row-item">
    <div class="item-header">
      <button class="button is-text drag">
        <img
          src="@/assets/icons/drag.svg"
          alt="" />
      </button>

      <h3>{{ inputs.name }}</h3>
    </div>

    <div class="tabs">
      <!-- <b-tabs
        type="is-boxed"
        class="is-fullwidth"
        @input="tabSelection">
        <b-tab-item
          v-for="tab in tabs"
          :key="tab"
          :value="tab"
          :label="tab.split('-').join(' ')" />
      </b-tabs> -->

      <Tabs
        v-model="inputSectionTab"
        :tabs="tabs" />
    </div>

    <div
      v-if="inputSectionTab === 'layout-label'"
      class="">
      <b-field
        label="Display Label"
        class="input-field"
        message="">
        <b-input
          v-model="inputs.name"
          type="" />
      </b-field>
    </div>

    <div v-if="inputSectionTab === 'measure'">
      <MeasureConfigEditor
        v-model="inputs.measure_config"
        :context="{
          reportDimensions: context.pivotDimension ? [ context.pivotDimension, ] : [],
        }" />
    </div>

    <div v-if="inputSectionTab === 'format'">
      <DataFormatEditor v-model="inputs.data_format" />
    </div>

    <div v-if="inputSectionTab === 'benchmark'">
      <BenchmarkConfigEditor
        v-model="inputs.comparison_config"
        :context="{
          parentMeasure: inputs.measure_config?.measure,
          reportDimensions: context.pivotDimension ? [ context.pivotDimension, ] : [],
        }" />
    </div>
  </div>
</template>

<script>
  import MeasureConfigEditor from '@/components/reports/config/modules/common/MeasureConfigEditor'
  import BenchmarkConfigEditor from '@/components/reports/config/modules/common/BenchmarkConfigEditor'
  import Tabs from '@/components/pdf_report/Tabs.vue'
  import DataFormatEditor from '@/components/reports/config/modules/common/DataFormatEditor'

  export default {
    name: 'TrackerRowTotal',
    components: {
      MeasureConfigEditor,
      BenchmarkConfigEditor,
      Tabs,
      DataFormatEditor,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      context: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        rowMeasureOptions: ['CompanyMeasureRef',],

        companyExpressionsOptions: [],

        tabs: ['layout-label', 'measure', 'format', 'benchmark',],

        inputSectionTab: 'layout-label',
      }
    },

    computed: {
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    async mounted () {
      this.companyExpressionsOptions = this.$store.state.temp.companyMeasures
    },

    beforeDestroy () {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
.row-item {
  margin: 10px 0;
  border: $border-1;
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px;

  .item-header {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .drag {
      width: 25px;
      height: 25px;
      padding: 0;
      img {
        width: 20px;
      }
    }

    h3 {
      font-size: 15px;
    }

    .delete {
      margin-left: auto;
    }
  }
}

.tabs {
  width: 100%;
}
</style>
