<template>
  <div
    class="rows">
    <b-tooltip
      style="width: 100%"
      position="is-right"
      type="is-light"
      :append-to-body="true"
      multilined>
      <template #content>
        <div class="data-tooltip">
          <div>
            <h2>Measure:</h2>
            {{
              module?.measure_config?.measure?.name ||
                module?.measure_config?.measure?.key ||
                "Null"
            }}
          </div>

          <div v-if="module?.comparison_config?.measure_config">
            <h2>Benchmark:</h2>
            {{ module.comparison_config.measure_config?.measure?.name }}
          </div>
        </div>
      </template>
      <b-tooltip
        style="width: 100%"
        type="is-light"
        position="is-bottom"
        :triggers="['contextmenu']"
        size="is-small"
        multilined
        :auto-close="['outside', 'escape', 'inside']">
        <div
          v-if="module?.key"
          class="row-item"
          @click.prevent.stop="openModule(module, 'measure', context)">
          <div
            class="row mini-view-item"
            :class="{ selected: currentSelectedKey === module?.key}">
            <i class="material-icons"> functions </i>

            <div>
              {{ module.name || "Table Measure" }}
            </div>
          </div>
        </div>
        <template #content>
          <div class="context-menu-actions">
            <button
              class="button is-light"
              @click="copyCode">
              Copy
            </button>
            <button
              class="button is-light"
              @click="cutCode">
              Cut
            </button>
            <button
              class="button is-danger is-light"
              @click="
                $emit('delete')
              ">
              Delete
            </button>
          </div>
        </template>
      </b-tooltip>
      <b-tooltip
        type="is-light"
        position="is-bottom"
        style="width: 100%"
        :triggers="['contextmenu']"
        size="is-small"
        multilined
        :auto-close="['outside', 'escape', 'inside']">
        <div
          class="add-module-below-section" />

        <template #content>
          <div class="context-menu-actions">
            <button
              class="button is-light"
              @click="$emit('paste')">
              Paste
            </button>
          </div>
        </template>
      </b-tooltip>
    </b-tooltip>
  </div>
</template>

<script>

  import uniqid from 'uniqid'
  export default {
    name: 'LayoutMeasureItem',
    components: {
    },
    inject: {
      openModule: {
        default: null,
      },
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      context: {
        type: Object,
        default: () => {},
      },
      currentSelectedKey: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        module: {},
      }
    },
    computed: {
      data: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
    mounted () {

      this.module = this.data
      if (!this.module?.key) {
        this.module.key = uniqid()
      }

      // this.module.tracker_rows.forEach((item) => {
      //   if (!item.key) {
      //     item.type = 'TrackerTableRow'
      //     item.key = uniqid()
      //   }
      // })
    },

    beforeDestroy () {},

    methods: {
      async copyCode ( ) {
        const data = {
          type: 'measure-item',
          code: this.module,
        }
        try {
          await this.$copyText(JSON.stringify(data))
          this.$buefy.toast.open({
            message: 'Copied',
            position: 'is-bottom',
            type: 'is-success',
          })
        } catch (e) {
          console.log(e)
          this.$buefy.toast.open({
            message: 'Error',
            position: 'is-bottom',
            type: 'is-warning',
          })
        }
      },

      async cutCode () {
        this.copyCode()
        this.$emit('delete')
      },

      openEditor (childIndex) {
        return this.$emit('open', childIndex)
      },
    },
  }
</script>

<style lang="scss" scoped>
.data-tooltip {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  hr {
    margin: 5px 0;
    border: 0;
    border-top: 1px solid black;
  }
  h2 {
    font-size: 12px;
    font-weight: 600;
  }
}
.rows {
  min-width: 250px;
  .row-item{
    display: flex;
    flex-direction: column;
    .dropdown-icon {
      width: 20px;
      // background-color: red;
    }

    .dropzone {
      width: 100%;
    }
  }

  button {
    display: flex;
    align-items: flex-start;
    color: $primary;
    border: 1px solid rgba(0, 140, 132, 0.1);
    background: rgba(0, 140, 132, 0.05);
    justify-content: flex-start;

    &:hover {
      color: $primary;
      border: 1px solid rgba(0, 140, 132, 0.1);
    }
  }

  .add-module-below-section {
    height: 8px;
    margin: 1px 0;
    width: 100%;
    cursor: pointer;
    background-color: $primary;
    border-radius: 5px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
}

.mini-view-item {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s;
    width: 100%;
    padding: 10px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    font-size: 15px;
    background-color: white;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
      0px 1px 2px 0px rgba(40, 39, 43, 0.06);

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    &:hover {
      background-color: hsla(177, 100%, 27%, 0.1);
      transition: background-color 0.1s;
      border: $border-1;
    }
    &.selected {
      background-color: $primary;
      color: white;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .context-menu-actions {
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 4px;
    font-size: 12px;
  }
}
</style>
