<template>
  <div class="row-item">
    <div class="item-header">
      <button class="button is-text drag">
        <img
          src="@/assets/icons/drag.svg"
          alt="" />
      </button>

      <h3>{{ inputs.name }}</h3>
      <button
        type="button"
        class="delete"
        @click="$emit('delete')" />
    </div>
    <div class="tabs">
      <Tabs
        v-model="inputSectionTab"
        :tabs="tabs" />
    </div>

    <div
      v-if="inputSectionTab === 'label'"
      class="">
      <b-field
        label="Display Label"
        class="input-field"
        message="">
        <b-input
          v-model="inputs.name"
          type="" />
      </b-field>
    </div>

    <div v-if="inputSectionTab === 'measure'">
      <MeasureTab v-model="inputs" />
    </div>

    <div v-if="inputSectionTab === 'format'">
      <DataFormatEditor v-model="inputs.data_format" />
    </div>

    <div v-if="inputSectionTab === 'header-styles'">
      <StyleTab v-model="inputs.header_style" />
    </div>

    <div v-if="inputSectionTab === 'cell-styles'">
      <StyleTab v-model="inputs.data_style" />
    </div>
  </div>
</template>

<script>

  import Tabs from '@/components/pdf_report/Tabs.vue'

  import MeasureTab from './MeasureTab.vue'
  import DataFormatEditor from '@/components/reports/config/modules/common/DataFormatEditor'
  import StyleTab from './StyleTab.vue'

  export default {
    name: 'TrackerRowTotal',
    components: {
      StyleTab,
      DataFormatEditor,
      MeasureTab,
      Tabs,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {

        companyExpressionsOptions: [],

        tabs: ['label', 'measure', 'format','header-styles', 'cell-styles',],
        inputSectionTab: 'label',
      }
    },

    computed: {
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    async mounted () {
      this.companyExpressionsOptions = this.$store.state.temp.companyMeasures
    },

    beforeDestroy () {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
.row-item {
  margin: 10px 0;
  border: $border-1;
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px;

  .item-header {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .drag {
      width: 25px;
      height: 25px;
      padding: 0;
      img {
        width: 20px;
      }
    }

    h3 {
      font-size: 15px;
    }

    .delete {
      margin-left: auto;
    }
  }
}

.tabs {
  width: 100%;
}
</style>
