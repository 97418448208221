<template>
  <div class="cartesian-edit">
    <div class="chart-row">
      <transition name="fade">
        <div
          v-if="isEdit"
          class="measure-drops">
          <dimension
            v-for="(dimension, idx) in dimensionCounts"
            :key="`${dimension.name}-count-${idx}`"
            :module-filters="moduleFilters"
            :dashboard-filters="dashboardFilters"
            :dimension="dimension"
            @start="dragDimension(dimension)"
            @end="dropDimensionCount(idx)"
            @update:moduleFilters="$emit('update:moduleFilters', $event)" />
          <field-drop
            :is-visible="draggedField && draggedField.kind === 'dimension' && draggedField.field.countable"
            class="dimension"
            :targets="'.dimension'"
            @drop="addDimensionCount(draggedField.field)">
            Add count
          </field-drop>
          <measure-aggregate
            v-for="(measureAggregate, idx) in measureAggregates"
            :key="`${measureAggregate.measure.name}-${idx}`"
            :measure-aggregate="measureAggregate"
            :dimension-options="avgOptions"
            @start="dragMeasureAggregate(measureAggregate)"
            @end="dropMeasureAggregate(idx)"
            @update:measureAggregate="updateMeasureAggregate(idx, $event)" />
          <field-drop
            v-show="draggedField && draggedField.kind === 'measure'"
            class="measure"
            :targets="'.measure'"
            @drop="addMeasureAggregate(draggedField.field)">
            Add measure
          </field-drop>
        </div>
      </transition>
      <div class="chart-col">
        <slot class="view" />
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isEdit"
        class="dimension-drops">
        <dimension
          v-for="(dimension, idx) in dimensions"
          :key="`${dimension.name}-${idx}`"
          :module-filters="moduleFilters"
          :dashboard-filters="dashboardFilters"
          :dimension="dimension"
          @start="dragDimension(dimension)"
          @end="dropDimension(idx)"
          @update:moduleFilters="$emit('update:moduleFilters', $event)" />
        <field-drop
          :is-visible="draggedField &&
            (draggedField.kind ==='dimension' || draggedField.kind === 'dimension-hierarchy')
            && draggedField.field.groupable"
          class="dimension"
          :targets="'.dimension'"
          @drop="addDimension(draggedField.field)">
          Add dimension
        </field-drop>
      </div>
    </transition>
  </div>
</template>
<script>

  import FieldDrop from '@/components/module/edit/FieldDrop'
  import MeasureAggregate from '@/components/module/edit/MeasureAggregate'
  import Dimension from '@/components/module/edit/Dimension'
  import viewEditMixin from '@/components/module/edit/viewEditMixin'

  export default {
    name: 'CartesianEdit',
    components: {
      FieldDrop,
      MeasureAggregate,
      Dimension,
    },
    mixins: [
      viewEditMixin,
    ],
  }

</script>

<style lang="scss" scoped>

.chart-row {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.chart-col {
  position:relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  height: 100%;
}

.measure-drops {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.measure.drop-zone {
  color:  #85BAE6 !important;
  border-color:  #85BAE6 !important;
}

.dimension.drop-zone {
  color:  #98CABF !important;
  border-color:  #98CABF !important;
}

.dimension-drops {
  display: flex;
  justify-content: center;
}

.cartesian-edit {
  flex-grow: 1;
  height: inherit;
  background: $secondary-01;
  @include shadow;
  border-radius: 2 * $gridBase;
}

</style>
