<template>
  <transition name="fade">
    <div
      v-show="enabled"
      class="busy-overlay">
      <div
        v-if="loading"
        class="loading message">
        {{ message }}
      </div>
      <div
        v-else
        class="message">
        {{ message }}
      </div>
    </div>
  </transition>
</template>

<script>

  export default {
    name: 'BusyOverlay',
    props: {
      enabled: Boolean,
      loading: Boolean,
      message: { type: String, default: '', },
    },
  }

</script>

<style lang="scss" scoped>

.busy-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 300;
  background: $secondary-04;
  opacity: .5;
  border-radius: inherit;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  z-index: 3;
  font-size: 1.4rem;
  color: $grayscale-1;
  text-align: center;
  vertical-align: center;
  background-color: transparent !important;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "...";
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
</style>

