import { uniq } from 'lodash'
import * as d3  from 'd3'
import { dvPalette } from '@/components/charting/colors'
import helpers from '@/components/charting/helpers'

export default {
  props: {
    loading: Boolean,
    debug: Boolean,
    columns: { type: Array, default: Array, },
    hiddenColumns: { type: Array, default: Array, },
    data: { type: Array, default: Array, },
    betaSpec: { type: Object, default: null, },
    highlightedData: { type: Array, default: null, },
    highlightedMeasures: { type: Array, default: null, },
    highlightedCategories: { type: Array, default: Array, },
    hiddenCategories: { type: Array, default: Array, },
    name: { type: String, default: '', },
    isEdit: { type: Boolean, default: false, },
    error: { type: Boolean, default: false, },
    mirrorYAxis: { type: Boolean, default: false, },
    tooltipEnabled: { type: Boolean, default: false, },
    highlightEnabled: { type: Boolean, default: false, },
    chartWidth: { Type: Number, default: 1, },
    chartHeight: { Type: Number, default: 1, },
  },
  computed: {
    nameModel: {
      get () {
        return this.name
      },
      set (name) {
        this.$emit('update:name', name)
      },
    },
    dvGood () { return dvPalette.dvGood },
    dvBad () { return dvPalette.dvBad },
    primaryDimensions () {
      // TODO: Migrate all role="dimension" columns to role="primary_dimension"
      return this.columns.filter(c => c.role === 'primary_dimension')
    },
    primaryDimensionKeys () {
      // TODO: Migrate all role="dimension" columns to role="primary_dimension"
      return this.columns.filter(c => c.role === 'primary_dimension_key')
    },
    secondaryDimensionKeys () {
      return this.columns.filter(c => c.role === 'secondary_dimension_key')
    },
    secondaryDimensions () {
      return this.columns.filter(c => c.role === 'secondary_dimension')
    },
    primaryMeasure () {
      return this.columns.filter(c => c.role === 'primary_measure').shift()
    },
    secondaryMeasure () {
      return this.columns.filter(c => c.role === 'secondary_measure').shift()
    },
    varianceMeasure () {
      return this.columns.filter(c => c.role === 'variance_measure').shift()
    },
    allMeasures () {
      return [this.primaryMeasure, this.secondaryMeasure, this.varianceMeasure,].filter(m => !!m)
    },
    yDomain () {
      let values = [0, ...this.data
        .filter(d => !this.hiddenCategories.includes(this.getDimensionVal(this.secondaryDimensionKeys, d)))
        .flatMap((datum) => this.allMeasures
        .filter(m => !this.hiddenColumns.includes(m))
        .map((measure) => datum[measure.key])),]
      if (this.mirrorYAxis) {
        let absMax = Math.max(...values.map(Math.abs))
        return [-absMax, absMax,]
      }
      return [Math.min(...values), Math.max(...values),]
    },
    yScale () {
      return d3.scaleLinear().domain(this.yDomain).range([this.chartHeight, 0,]).nice()
    },
    xDomain () {
      return uniq(this.data.map((datum) => this.getDimensionVal(this.primaryDimensionKeys, datum)))
    },
    xScale () {
      return d3.scaleBand().domain(this.xDomain).range([0,this.chartWidth,])
    },
    hasClickableDimensions () {
      return [].concat(
        this.secondaryDimensions, this.secondaryDimensionKeys,
        this.primaryDimensions, this.primaryDimensionKeys).filter(d => d.clickable).length > 0
    },
  },
  methods: {
    ...helpers,
    getYPosition (value) {
      if (['',].includes(value)) {
        return NaN
      } else {
        return this.yScale(value)
      }
    },
    specColor (spec, datum) {
      if (spec.type === 'manual') {
        return spec.value
      }
      if (spec.type === 'controlled') {
        return this.specColor(this.$store.getters['controls/debouncedSelections'][spec.control_id].colorSpec, datum)
      }
      if (datum !== null) {
        if (spec.type === 'over_under') {
          if (parseFloat(datum[this.varianceMeasure.key]) > 0) {
            return dvPalette.dvGood
          } else if (parseFloat(datum[this.varianceMeasure.key]) < 0) {
            return dvPalette.dvBad
          }
        }
        if (spec.type === 'under_over') {
          if (parseFloat(datum[this.varianceMeasure.key]) < 0) {
            return dvPalette.dvGood
          } else if (parseFloat(datum[this.varianceMeasure.key]) > 0) {
            return dvPalette.dvBad
          }
        }
      }
      return null
    },
    measureDatumColor (measure, datum) {
      if (measure.colorSpec) {
        let specColor = this.specColor(measure.colorSpec, datum)
        if (specColor) {
          return specColor
        }
      }
      if (measure.role === 'primary_measure') {
        return dvPalette.dvPrimary
      }
      if (measure.role === 'secondary_measure') {
        return dvPalette.dvSecondary
      }
    },
  },
}
