<template>
  <div class="forecast-uploader">
    <div class="header">
      Upload Forecasts
    </div>
    <div class="form">
      <upload-step>
        <template #name>
          Step 1
        </template>
        <template #description>
          Select budget type to upload
          <br />
          <br />
          <span class="bold">Tip:</span>
          <br />
          For each type, select:
          <br />
          <ul>
            <li>
              <span class="bold">Forecast</span> to upload projected numbers
            </li>
            <li><span class="bold">Budget</span> to upload budgeted numbers</li>
          </ul>
        </template>
        <template #controls>
          <b-dropdown @change="selectUploadTypeOption">
            <template #trigger="{ active }">
              <div>
                <button
                  class="button forcast-type-dropdown-button"
                  type="is-outline">
                  <div class="dropdown-btn-content">
                    <div class="label">
                      Forecast Type
                    </div>
                    <div class="selected">
                      {{ (selectedUploadType || {}).name }}
                    </div>
                  </div>
                  <div class="dropdown-icon">
                    <b-icon
                      class="icon"
                      :icon="active ? 'menu-up' : 'menu-down'" />
                  </div>
                </button>
              </div>
            </template>
            <b-dropdown-item
              v-for="option in uploadTypeOptions"
              :key="option.uploadType.key"
              :value="option"
              aria-role="listitem">
              {{ option.uploadType.name }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </upload-step>
      <upload-step class="headers-section">
        <template #name>
          Step 2
        </template>
        <template #description>
          Review required columns and values
        </template>
        <template #custom-controls>
          <div class="header-viewport-wrapper">
            <div class="header-viewport">
              <div class="header-group">
                <div class="header-group-label required">
                  Required
                </div>
                <div class="header-sets">
                  <template
                    v-for="(column, columnIdx) of headerGroups.required">
                    <header-set
                      v-if="column.type === 'dimension_header_group'"
                      :key="`required-${columnIdx}`"
                      :header-set="column.dimension_headers"
                      class="required" />
                    <header-set
                      v-else
                      :key="`required-${columnIdx}`"
                      :header-set="[column]"
                      class="required" />
                  </template>
                </div>
              </div>
              <div class="header-group">
                <div class="header-group-label">
                  Optional
                </div>
                <div class="header-sets">
                  <template
                    v-for="(column, columnIdx) of headerGroups.optional">
                    <header-set
                      v-if="column.type === 'dimension_header_group'"
                      :key="`optional-${columnIdx}`"
                      :header-set="column.dimension_headers"
                      class="optional" />
                    <header-set
                      v-else
                      :key="`optional-${columnIdx}`"
                      :header-set="[column]"
                      class="optional" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
      </upload-step>
      <upload-step>
        <template #name>
          Step 3
        </template>
        <template #description>
          Upload CSV file
        </template>
        <template #controls>
          <b-upload
            v-model="dropFile"
            accept=".csv"
            drag-drop
            @input="onFileUpload">
            <section
              class="uploader-content has-text-centered"
              :class="{
                'error-state': !uploading && errors.length > 0,
                'success-state': !uploading && dropFile?.name && errors.length == 0,
              }">
              <p>
                <b-icon
                  icon="upload"
                  size="is-large" />
              </p>
              <p
                v-if="uploading"
                class="w-full">
                loading
              </p>
              <p v-else-if="!dropFile?.name">
                Drop your files here or click to upload (.csv)
              </p>

              <div
                v-else-if="dropFile?.name"
                class="tag filetag is-outline">
                {{ dropFile.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteDropFile" />
              </div>
            </section>
          </b-upload>
          <div v-if="!uploading && dropFile.name">
            <div
              v-for="(errorMsg, idx) in errors"

              :key="`error-${idx}`"
              class="error">
              <i class="material-icons error-circle"> error </i>
              <span class="error-label"> Error </span>
              {{ errorMsg }}
            </div>
            <div
              v-for="(warning, idx) in warnings"
              :key="`warning-${idx}`"
              class="warning">
              <i class="material-icons warning-circle"> error </i>
              <span class="warning-label"> Warning </span>
              {{ warning }}
            </div>
            <div
              v-if="errors.length == 0 && !!datesMessage"
              class="success">
              <i class="material-icons success-circle"> check_circle </i>
              <span class="success-label"> Success </span>
              {{ datesMessage }}
            </div>
          </div>
        </template>
      </upload-step>
      <upload-step class="last">
        <template #name>
          Step 4
        </template>
        <template #description>
          Submit forecast data
        </template>
        <template #controls>
          <b-button
            class="submit-button is-primary"
            :disabled="!dropFile.name || errors.length > 0"
            @click="openSubmit">
            Submit Forecast
          </b-button>
        </template>
      </upload-step>
    </div>

    <b-loading
      v-model="submitting"
      :is-full-page="false" />
    <div
      v-if="!!submitResponse"
      class="confirmation-message">
      <div class="confirmation-header">
        Success!
      </div>
      <div class="confirmation-body">
        {{ submitResponse.created }} new rows were uploaded
        <br />
        <br />
        {{ submitResponse.deleted }} existing rows were overwritten
        <div class="dates-confirmation">
          Dates Uploaded:
          <div
            v-for="(range, idx) of submitResponse.date_ranges.slice(0, 3)"
            :key="idx"
            class="date-range">
            {{ formatDateRange(range[0], range[1]) }}
          </div>
          <div
            v-if="submitResponse.date_ranges.length > 3"
            class="date-range">
            and {{ submitResponse.date_ranges.length - 3 }} other{{
              submitResponse.date_ranges.length > 4 ? "s" : ""
            }}
          </div>
        </div>
      </div>
      <b-button
        @click="reset">
        <span
          class="is-flex"
          style="align-items: center;">
          <i class="material-icons refresh-icon mr-3 h-full"> refresh </i>
          Upload another file
        </span>
      </b-button>
    </div>
    <div
      v-if="failedSubmit"
      class="confirmation-message">
      <div class="confirmation-header confirmation-error">
        Error
      </div>
      <div class="confirmation-body">
        Something went wrong while submitting your data
        <br />
        <br />
        If the issue persists, contact your administrator
      </div>
      <b-button
        @click="retry">
        <i class="material-icons refresh-icon"> refresh </i>
        Try again
      </b-button>
    </div>
  </div>
</template>

<script>
  import HeaderSet from '@/components/forecasts/HeaderSet'
  import UploadStep from '@/components/forecasts/UploadStep'
  import { formatDateRange } from '@/utils/Date'
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    name: 'ForecastUploader',
    components: {
      UploadStep,
      HeaderSet,
    },
    data () {
      return {
        dropFile: {},
      }
    },
    computed: {
      ...mapGetters('app/forecasts', [
        'initialized',
        'uploadTypes',
        'selectedUploadType',
        'uploadTypeOptions',
        'selectedUploadTypeOption',
        'file',
        'uploading',
        'submitting',
        'submitResponse',
        'failedSubmit',
        'validationResponse',
        'failedUpload',
      ]),
      datesMessage () {
        if (!this.validationResponse || !this.validationResponse.date_ranges) {
          return null
        }
        let dateCount = this.validationResponse.date_ranges.length
        let datesString = ''
        this.validationResponse.date_ranges
          .slice(0, 3)
          .forEach((date_range, idx) => {
            if (idx > 0) {
              datesString += ', '
            }
            if (idx == dateCount - 1) {
              datesString += 'and '
            }
            datesString += formatDateRange(date_range[0], date_range[1])
          })
        if (dateCount > 3) {
          datesString += ` and ${dateCount - 3} other`
          if (dateCount > 4) {
            datesString += 's'
          }
        }
        return `Dates will be uploaded for ${datesString}`
      },
      warnings () {
        if (!this.validationResponse) {
          return []
        }
        let warnings = []
        this.validationResponse.overwrites.forEach((overwrite) => {
          warnings.push(
            `Uploading this data will replace previous uploads for ${overwrite.locations} locations between ${overwrite.start} and ${overwrite.end}.`
          )
        })
        this.validationResponse.extra_headers.forEach((header) => {
          warnings.push(
            `"${header}" is unexpected and will not be uploaded to the database.`
          )
        })
        return warnings
      },
      errors () {
        if (this.failedUpload) {
          return [
            'Failed to validate file. Please double check the file type and try again.',
          ]
        }
        if (!this.validationResponse) {
          return []
        }
        if (this.validationResponse.no_data) {
          return ['No data was found in the uploaded file.',]
        }
        let errors = []
        this.validationResponse.missing_headers.forEach((header) => {
          errors.push(`Required column header, "${header}", not found.`)
        })
        this.validationResponse.bad_data.forEach((problem) => {
          errors.push(
            `Invalid values for header, "${problem.header}". Found ${problem.rows.length} instances starting with "${problem.rows[0].value}" in row ${problem.rows[0].row}.`
          )
        })
        return errors
      },
      headerGroups () {
        if (!this.selectedUploadType) {
          return {
            required: [],
            optional: [],
          }
        }
        return {
          optional: this.selectedUploadType.optional_columns,
          required: this.selectedUploadType.required_columns,
        }
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions('app/forecasts', [
        'initialize',
        'uploadFile',
        'submitFile',
        'clearFile',
        'selectUploadTypeOption',
        'reset',
        'retry',
      ]),
      ...mapMutations('app/forecasts', []),
      formatDateRange,
      deleteDropFile () {
        this.dropFile = {}
      },
      openSubmit () {
        if (this.validationResponse.overwrites.length > 0) {
          this.$buefy.dialog.confirm({
            title: 'Replace existing data?',
            message: ' The file you are about to submit contains forecast information for dates that already contain forecast data in the database.  <br/><br/> Click ‘Confirm’ if you would like to replace the existing forecast datawith data from this file',
            onConfirm: () => this.submitFile(),
            confirmText: 'Confirm',
          })
        } else {
          this.submitFile()
        }
      },
      async onFileUpload (file) {
        if (!file.name) {
          return
        }
        this.uploadFile(file)
      },
    },
  }
</script>

<style lang="scss" scoped>
.forecast-uploader {
  padding: 8 * $gridBase;
  background: $ui-08;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-height: 100vh;
  overflow: auto;
}
.uploader-content {
  padding: 10px;
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.error-state {
    border: 1px solid $ui-negative;
    background-color: rgba($ui-negative, 0.1);
  }
  &.success-state {
    border: 1px solid $ui-positive;
    background-color: rgba($ui-positive, 0.1);
  }
}
.filetag {
  font-size: 15px !important;
}
.forcast-type-dropdown-button {
  width: 300px;
  display: flex;
  height: 50px !important;
  .dropdown-btn-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;
    align-items: start;

    .label {
      font-size: 12px;
      color: $ui-03;
      margin-bottom: 0;
      font-weight: 300;
    }
    .selected {
      font-size: 16px;
      color: $ui-01;
    }
  }
}

.header {
  font: $lg-dw-sl;
  color: $ui-01;
  margin-bottom: 8 * $gridBase;
}

.form {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.forecast-select {
  width: 60 * $remGrid;
}

.upload-button {
  font: $h2-ew-sl;
}

i.upload-arrow.material-icons {
  font-size: 1.2rem;
}

.header-viewport-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  padding: 4 * $gridBase 0;
  background: $ui-07;
  box-shadow: inset 0px 0px 2px rgba(79, 78, 84, 0.15),
    inset 0px 0px 8px rgba(79, 78, 84, 0.05);
  border-radius: 4px;
  overflow: auto;
}

.header-viewport {
  min-width: 0;
  display: flex;
  align-items: flex-start;
  overflow: auto;
}

.card.required {
  border: 1px solid $ui-accent-alt;
}

.header-sets {
  display: flex;
  align-items: flex-start;
  overflow: auto;
  position: relative;
}

.header-set {
  margin: 0 1.5 * $gridBase;
  display: flex;
  align-items: stretch;
  padding: 4 * $gridBase;
  overflow: auto;
  max-height: 100%;
}

.or {
  color: $ui-05;
  font: $h3-lw-sl;
  margin: $gridBase 8 * $gridBase;
}

.header-group {
  margin: 0 2.5 * $gridBase;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow: auto;
}

.header-group-label {
  margin: 0 1.5 * $gridBase;
  margin-bottom: 3 * $gridBase;
  font: $h3-dw-sl;
  color: $ui-03;

  &.required {
    color: $ui-accent;
  }
}

.error {
  background: rgba(211, 27, 63, 0.1);
  border-radius: $gridBase;
  margin-top: 4 * $gridBase;
  padding: 2 * $gridBase 3 * $gridBase;
  display: flex;
  align-items: center;
  color: $ui-negative;
  font: $h3-lw-sl;
}

.error-circle {
  color: $ui-negative;
  font-size: 2.5 * $remGrid;
}

.error-label {
  margin: 0 3 * $gridBase;
  color: $ui-negative;
  font: $h3-dw-sl;
}

.warning {
  background: rgba(255, 138, 31, 0.1);
  border-radius: $gridBase;
  margin-top: 4 * $gridBase;
  padding: 2 * $gridBase 3 * $gridBase;
  display: flex;
  align-items: center;
  color: $ui-warning;
  font: $h3-lw-sl;
}

.warning-circle {
  color: $ui-warning;
  font-size: 2.5 * $remGrid;
}

.warning-label {
  margin: 0 3 * $gridBase;
  color: $ui-warning;
  font: $h3-dw-sl;
}

.success {
  background: rgba($ui-positive, 0.1);
  border-radius: $gridBase;
  margin-top: 4 * $gridBase;
  padding: 2 * $gridBase 3 * $gridBase;
  display: flex;
  align-items: center;
  color: $ui-positive;
  font: $h3-lw-sl;
}

.success-circle {
  color: $ui-positive;
  font-size: 2.5 * $remGrid;
}

.success-label {
  margin: 0 3 * $gridBase;
  color: $ui-positive;
  font: $h3-dw-sl;
}

.submitting-overlay {
  top: 0;
  left: 0;
  background: $ui-01;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-message {
  top: 0;
  left: 0;
  background: $ui-09;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmation-header {
  color: $ui-neutral;
  font: $lg-dw-sl;
  margin-bottom: 8 * $gridBase;

  &.confirmation-error {
    color: $ui-negative;
  }
}

.confirmation-body {
  margin-bottom: 7 * $gridBase;
  color: $ui-02;
  font: $h1-lw-sl;
  text-align: center;
}

.confirmation-button {
}

label.confirmation-button {
  white-space: nowrap;
  font: $h2-dw-sl;
  margin: 0;
  &:not(:last-child) {
    margin-right: 4 * $gridBase;
  }
}

i.material-icons.refresh-icon {
  font-size: 18px;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.processing-graphic {
  animation: rotating 2s linear infinite;
}

.step-section.headers-section {
  min-height: 455px;
  flex-grow: 1;
  overflow: hidden;
}

.dates-confirmation {
  font: $h3-lw-sl;
  margin-bottom: 6 * $gridBase;
  padding: 6 * $gridBase;
  text-align: center;
  color: $ui-03;
}

.date-range {
  font: $h1-lw-sl;
  text-align: center;
  color: $ui-02;
  margin-top: 2 * $gridBase;
}

.bold {
  font: $h3-ew-ns;
}
</style>
