import axios from 'axios'
import { getAuthToken } from '@/service/auth/authService'

export async function getReport (spec, company_id, cancelToken) {
  return (await axios.post(
    `${process.env.VUE_APP_API_URL}/company/${company_id}/report`,
    spec, { headers: { Authorization: getAuthToken(), }, cancelToken, })
  ).data
}

export async function getReportCsv (spec, company_id) {
  return (await axios.post(
    `${process.env.VUE_APP_API_URL}/company/${company_id}/reportCsv`,
    spec, { headers: { Authorization: getAuthToken(), }, })
  ).data
}
