import * as d3  from 'd3'
import { throttle, uniqBy } from 'lodash'

import { dvPalette } from '@/components/charting/colors'
import helpers from '@/components/charting/helpers'

export default {
  data () {
    return {
      chartType: 'line',
      tooltipX: 0,
      tooltipY: 0,
      tooltipEnabled: false,
      tooltipDatum: null,
      tooltipMeasure: null,
      tooltipCategory: null,
      categories: [],
      hiddenCategories: [],
      viewRect: { width: 1, height: 1, },
      throttledHandler: throttle(this.rawHoverHandler, 50, { leading: true, trailing: false, }),
    }
  },
  props: {
    loading: Boolean,
    columns: { type: Array, default: Array, },
    data: { type: Array, default: Array, },
    betaSpec: { type: Object, default: null, },
    name: { type: String, default: '', },
    isEdit: { type: Boolean, default: false, },
    error: { type: Boolean, default: false, },
  },
  computed: {
    mirrorYAxis () {
      return false
    },
    nameModel: {
      get () {
        return this.name
      },
      set (name) {
        this.$emit('update:name', name)
      },
    },
    viewHeight () {
      return this.viewRect.height
    },
    viewWidth () {
      return this.viewRect.width
    },
    viewBox () {
      return `0 0 ${this.viewWidth} ${this.viewHeight}`
    },
    hiddenColumns () {
      return [this.varianceMeasure,]
    },
    primaryDimensionKeys () {
      // TODO: Migrate all role="dimension" columns to role="primary_dimension"
      return this.columns.filter(c => c.role === 'primary_dimension_key')
    },
    secondaryDimensionKeys () {
      return this.columns.filter(c => c.role === 'secondary_dimension_key')
    },
    primaryDimensions () {
      // TODO: Migrate all role="dimension" columns to role="primary_dimension"
      return this.columns.filter(c => c.role === 'primary_dimension')
    },
    secondaryDimensions () {
      return this.columns.filter(c => c.role === 'secondary_dimension')
    },
    primaryMeasure () {
      return this.columns.filter(c => c.role === 'primary_measure').shift()
    },
    secondaryMeasure () {
      return this.columns.filter(c => c.role === 'secondary_measure').shift()
    },
    varianceMeasure () {
      return this.columns.filter(c => c.role === 'variance_measure').shift()
    },
    allMeasures () {
      return [this.primaryMeasure, this.secondaryMeasure, this.varianceMeasure,].filter(m => !!m)
    },
    highlightDimensions () {
      return [].concat(
        this.primaryDimensions, this.primaryDimensionKeys,
        this.secondaryDimensions, this.secondaryDimensionKeys).filter(d => !!d.highlightKey)
    },
    hasHighlightDimension () {
      return this.highlightDimensions.length > 0
    },
    highlightEnabled () {
      return this.tooltipEnabled || this.hasHighlightDimension
    },
    highlightedCategories () {
      if (this.tooltipEnabled && !!this.tooltipCategory) {
        return [this.tooltipCategory,]
      }
      return []
    },
    highlightedMeasures () {
      if (this.tooltipEnabled && !!this.tooltipMeasure) {
        return [this.tooltipMeasure,]
      }
      if (this.hasHighlightDimension) {
        return this.allMeasures
      }
      return []
    },
    highlightedData () {
      let highlightedData = []
      if (this.hasHighlightDimension) {
        highlightedData = [...this.data.filter(this.isDatumHighlighted),]
      }
      if (this.tooltipEnabled
          && !!this.tooltipDatum
          && !highlightedData.includes(this.tooltipDatum)) {
        highlightedData.push(this.tooltipDatum)
      }
      return highlightedData
    },
    categoryKeys () {
      return this.categories.map(c => c.key)
    },
    categoryColor () {
      return d3.scaleOrdinal().domain(this.categoryKeys).range([
        dvPalette.dvCategory01,
        dvPalette.dvCategory02,
        dvPalette.dvCategory03,
        dvPalette.dvCategory04,
        dvPalette.dvCategory05,
        dvPalette.dvCategory06,
        dvPalette.dvCategory07,
        dvPalette.dvCategory08,
        dvPalette.dvCategory09,
        dvPalette.dvCategory10,
      ])
    },
    yDomain () {
      let values = [0, ...this.data.filter(d => !this.hiddenCategories.includes(this.getDimensionVal(this.secondaryDimensionKeys, d))).flatMap((datum) => this.allMeasures.filter(
        m => !this.hiddenColumns.includes(m)).map((measure) => datum[measure.key])),]
      if (this.mirrorYAxis) {
        let absMax = Math.max(...values.map(Math.abs))
        return [-absMax, absMax,]
      }
      return [Math.min(...values), Math.max(...values),]
    },
    yScale () {
      return d3.scaleLinear().domain(this.yDomain).range([this.viewHeight, 0,]).nice()
    },
    xDomain () {
      return uniqBy(this.data.map((datum) => this.getDimensionVal(this.primaryDimensionKeys, datum)))
    },
    xScale () {
      return d3.scaleBand().domain(this.xDomain).range([0,this.viewWidth,])
    },
  },
  methods: {
    ...helpers,
    isDatumHighlighted (datum) {
      return this.highlightDimensions.filter(d => d.highlightKey.includes(datum[d.key].toString())).length > 0
    },
    onChartResize (viewRect) {
      this.viewRect = viewRect
    },
    rawHoverHandler (hoverEvent) {
      let visibleMeasure = this.allMeasures.filter(m => !this.hiddenColumns.includes(m)).slice(0, 1).shift()
      let allVisibleMeasures = this.allMeasures.filter(m => !this.hiddenColumns.includes(m))
      let rect = this.$refs.svg.getBoundingClientRect()
      let hoverX = hoverEvent.clientX - rect.x
      let hoverY = hoverEvent.clientY - rect.y
      let datum = null

      this.data
        .filter(d => !this.hiddenCategories.includes(
          this.getDimensionVal(this.secondaryDimensionKeys, d)))
        .forEach(d => {
        if (datum === null) {
          datum = d
        } else {
          let currX = Math.abs(
            (this.xScale(this.getDimensionVal(this.primaryDimensionKeys, d))
                + this.xScale.bandwidth() / 2)
              - hoverX)
          let minX = Math.abs(
            (this.xScale(this.getDimensionVal(this.primaryDimensionKeys, datum))
                + this.xScale.bandwidth() / 2)
              - hoverX)
          let currY = Math.abs(
            this.yScale(d[visibleMeasure.key])
              - hoverY)
          let minY = Math.abs(
            this.yScale(datum[visibleMeasure.key])
              - hoverY)
          if (currX < minX || currX === minX && currY < minY) {
            datum = d
          }
        }
      })

      if (!datum) {
        return
      }

      let category = this.categories.filter(c =>
        c.key === this.getDimensionVal(this.secondaryDimensionKeys, datum)).shift()

      this.setHoverValues(
(this.xScale(this.getDimensionVal(this.primaryDimensionKeys, datum)) + this.xScale.bandwidth() / 2) + rect.x,
        Math.min(...allVisibleMeasures.map(m => this.yScale(datum[m.key]) < this.yScale(0) ? this.yScale(datum[m.key]) + rect.y : this.yScale(0) + 4 + rect.y)),
  datum, this.primaryMeasure, category)
      /*
      this.setHoverValues(hoverEvent.chartX + rect.x, hoverEvent.chartY + rect.y, hoverEvent.datum, hoverEvent.measure)
      */
    },
    onHover (hoverEvent) {
      return this.throttledHandler(hoverEvent)
    },
    setHoverValues (x, y, datum, agg, category) {
      this.tooltipX = x
      this.tooltipY = y
      this.tooltipEnabled = true
      this.tooltipDatum = datum
      this.tooltipMeasure = agg
      this.tooltipCategory = category
    },
    clearHoverValues () {
      this.$nextTick(() => {
        this.tooltipX = 0
        this.tooltipY = 0
        this.tooltipEnabled = false
        this.tooltipDatum = null
        this.tooltipMeasure = null
        this.tooltipCategory = null
      })
    },
    toggleCategory (category) {
      let wasHidden = this.hiddenCategories.includes(category.key)
      if (wasHidden) {
        this.hiddenCategories = this.hiddenCategories.filter(k => k !== category.key)
      } else {
        this.hiddenCategories = [...this.hiddenCategories, category.key,]
      }
    },
  },
  watch: {
    data () {
      this.categories = Object.freeze(uniqBy(this.data.map((datum) => ({
          key: this.getDimensionVal(this.secondaryDimensionKeys, datum),
          value: this.getDimensionVal(this.secondaryDimensions, datum),
        })),
          c => c.key)
        .filter(c => !!c.key))
      this.hiddenCategories = this.categories.slice(10).map(c => c.key)
    },
  },
  beforeMount () {
      this.categories = Object.freeze(uniqBy(this.data.map((datum) => ({
          key: this.getDimensionVal(this.secondaryDimensionKeys, datum),
          value: this.getDimensionVal(this.secondaryDimensions, datum),
        })),
          c => c.key)
        .filter(c => !!c.key))
      this.hiddenCategories = this.categories.slice(10).map(c => c.key)
  },
}
