<template>
  <div
    class="company-expression-node"
    :class="{ 'company-expression-viewer-open': companyExpressionViewerOpen }">
    <div class="flexing-row">
      <div
        v-if="data.operation"
        class="arrigate-type-label cells">
        {{ data.operation.replaceAll("_", " ") }} from
      </div>
      <div
        class="expression-field cells"
        :class="{ curved: !data.operation }">
        Company Expression
      </div>
      <div class="expression-value cells">
        {{ data.name }}
      </div>
      <div class="actions">
        <div
          class="button is-text"
          @click="toggleCompanyExpressionViewer">
          <b-icon
            :icon="companyExpressionViewerOpen ? 'eye' : 'eye-off'"
            size="is-small" />
        </div>
        <router-link
          class="button is-text"
          target="_blank"
          :to="{
            name: 'expression-viewer',
            query: { id: data.expressionId },
          }">
          <b-icon
            icon="open-in-new"
            size="is-small" />
        </router-link>
      </div>
    </div>
    <div
      v-if="companyExpressionViewerOpen"
      class="company-expression-viewer">
      <expression-graph
        :expression-id="data.expressionId"
        :is-sub-tree="true" />
    </div>
  </div>
</template>

<script>
  import ExpressionGraph from '@/components/expression_viewer/ExpressionGraphComponent'
  export default {
    name: 'ComapnyExpressionNode',
    components: {
      ExpressionGraph,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data () {
      return {
        companyExpressionViewerOpen: false,
      }
    },

    async mounted () {},
    methods: {
      toggleCompanyExpressionViewer () {
        this.companyExpressionViewerOpen = !this.companyExpressionViewerOpen
      },
    },
  }
</script>

<style lang="scss" scoped>
.company-expression-node {
  border-radius: 7px;
  background-color: white;
}
.company-expression-viewer-open {
  border: 1px solid $grayscale-6;
  .flexing-row {
    border-top: 0;
    border-radius: 0 0 7px 7px;
  }
}
.flexing-row {
  border-radius: 7px;
  background-color: white;
  width: min-content;
  border: 1px solid $grayscale-6;
  margin: auto;
}
.cells {
  padding: 9px 14px;
}

.company-expression-viewer {
  height: auto;
  width: auto;
  border-radius: 7px;
  position: relative;
  top: -1px;
  padding: 5px 0 10px;
}
.arrigate-type-label {
  text-transform: capitalize;
  color: #5298ad;
  border-right: 1px solid $grayscale-6;
  background: #eff6f9;
  border-radius: 7px 0 0 7px;
}
.expression-field {
  background: #f2faf3;
  border-right: 1px solid $grayscale-6;
  color: #52b482;
  &.curved {
    border-radius: 7px 0 0 7px;
  }
}

.expression-value {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $grayscale-6;
}
.actions {
  padding: 0 5px;
  display: flex;

  .button {
    margin: 0;
    height: 32px;
    width: 32px;
    border: none;
  }
}
</style>
