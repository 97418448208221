<template>
  <div>
    <div v-if="long">
      {{ minAmount }} &ndash; {{ maxAmount }}
    </div>
    <div v-else>
      {{ dimension.name }} ({{ minAmount }} &ndash; {{ maxAmount }})
    </div>
  </div>
</template>

<script>

  import { shortFormatNumber, formatNumber } from '@/utils/Format'

  export default {
    name: 'NumberFilterSummary',
    props: {
      filter: { type: Object, required: true, },
      long: { type: Boolean, default: false, },
    },
    computed: {
      dimension () {
        return this.$store.getters['metadata/dimensions'][this.filter.dimension]
      },
      minAmount () {
        if (this.long) {
          return formatNumber(this.filter.args.start)
        } else {
          return shortFormatNumber(this.filter.args.start)
        }
      },
      maxAmount () {
        if (this.long) {
          return formatNumber(this.filter.args.end)
        } else {
          return shortFormatNumber(this.filter.args.end)
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

.number-filter-summary {
}

</style>
