<template>
  <div class="sub-section">
    <b-field
      class="input-field"
      label="Time Dimension">
      <singleselect
        v-model="inputs"
        class="select-input"
        :return-as-object="true"
        :options="timeDimensionKeyOptions"
        label="name"
        track-by="key" />
    </b-field>
  </div>
</template>

<script>
  import singleselect from '@/components/ui/singleselect.vue'

  export default {
    name: 'TrackerTableTimeDimension',
    components: {
      singleselect,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {}
    },
    computed: {
      inputs: {
        get () {
          return this.value?.key
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      timeDimensionKeyOptions () {
        return this.$store.state.temp.companyDimensions || []
      },
    },
    mounted () {},

    beforeDestroy () {},

    methods: {},
  }
</script>

<style lang="scss" scoped></style>
