<template>
  <span class="material-icons">show_chart</span>
</template>

<script>

  export default {
    name: 'LineIcon',
    props: {
      variant: { type: String, required: true, },
    },
  }

</script>

<style lang="scss" scoped>
.slate-variant {
  & .st0{
    fill:#494D51;
  }
}

.green-variant {
  .st0 {
    fill: #98CABF;
  }
}
</style>
