import Vue from 'vue'

export default {
  props: {
    isEdit: Boolean,
    draggedField: Object,
    measureAggregates: Array,
    dimensions: Array,
    dimensionOptions: Array,
    dimensionCounts: Array,
    moduleFilters: Array,
    dashboardFilters: Array,
  },
  computed: {
    avgOptions () {
      return this.dimensionOptions.filter(option => {
        let hasDescendant = false
        this.dimensions.forEach(dimension => {
          if (option.descendants.includes(dimension.key)) {
            hasDescendant = true
          }
        })
        return !hasDescendant
      })
    },
  },
  methods: {
    updateModuleFilters (value) {
      this.$emit('update:moduleFilters', value)
    },
    updateMeasureAggregate (idx, newAgg) {
      let newMeasureAggregates = [...this.measureAggregates, ]
      newMeasureAggregates.splice(idx, 1, newAgg)
      this.$emit('update:measureAggregates', newMeasureAggregates)
    },
    dropMeasureAggregate (idx) {
      let newMeasureAggregates = [...this.measureAggregates, ]
      newMeasureAggregates.splice(idx, 1)
      this.$emit('update:measureAggregates', newMeasureAggregates)
      this.$emit('update:draggedField', undefined)
    },
    addMeasureAggregate (newAgg) {
      let newMeasureAggregates = [...this.measureAggregates, ]
      newMeasureAggregates.push(newAgg)
      this.$emit('update:measureAggregates', newMeasureAggregates)
    },
    dropDimension (idx) {
      let newDimensions = [...this.dimensions, ]
      newDimensions.splice(idx, 1)
      this.$emit('update:dimensions', newDimensions)
      this.$emit('update:draggedField', undefined)
    },
    addDimension (newDim) {
      Vue.nextTick(() => {
        let newDimensions = [...this.dimensions, ]
        newDimensions.push(newDim)
        this.$emit('update:dimensions', newDimensions)
      })
    },
    dropDimensionCount (idx) {
      let newDimensionCounts = [...this.dimensionCounts, ]
      newDimensionCounts.splice(idx, 1)
      this.$emit('update:dimensionCounts', newDimensionCounts)
      this.$emit('update:draggedField', undefined)
    },
    addDimensionCount (newDim) {
      Vue.nextTick(() => {
        let newDimensionCounts = [...this.dimensionCounts, ]
        newDimensionCounts.push(newDim)
        this.$emit('update:dimensionCounts', newDimensionCounts)
      })
    },
    dragMeasureAggregate (field) {
      this.$emit('update:draggedField', { kind: 'measure', field, })
    },
    dragDimension (field) {
      this.$emit('update:draggedField', { kind: 'dimension', field, })
    },
  },
}
