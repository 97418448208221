var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view"},[(_vm.columns.length > 0)?_c('busy-overlay',{staticClass:"custom-loading-overlay",attrs:{"enabled":_vm.loading || _vm.busy || _vm.missingDeps.length > 0,"loading":_vm.loading,"message":_vm.error
        ? 'Failed to load'
        : _vm.loading || _vm.missingDeps.length > 0
          ? _vm.isEdit
            ? 'Calculating'
            : 'Loading'
          : ''}}):_vm._e(),(!!_vm.reportData && !!_vm.reportColumns)?_c(_vm.type,_vm._b({tag:"component",staticClass:"view-component",on:{"update:name":function($event){_vm.nameModel = $event},"update:sortBy":function($event){_vm.sortBy = $event},"click":_vm.onChartClick,"reload":_vm.reloadData},scopedSlots:_vm._u([{key:"filters",fn:function(){return [(!_vm.isEdit)?_c('dimension-filter-menu',{attrs:{"read-only":true,"filters":_vm.filters,"add-remove":false}}):_vm._e()]},proxy:true},{key:"toolbarButtons",fn:function(){return [(_vm.hasDrilldown)?_c('ingest-dropdown',{scopedSlots:_vm._u([{key:"button-content",fn:function({ isOpen }){return [_c('div',{staticClass:"drilldown-path-button",class:{ isOpen }},[_vm._v(" Drill down "),_c('span',{staticClass:"material-icons"},[_vm._v("arrow_drop_down")])])]}},{key:"body-content",fn:function(){return [_c('div',{staticClass:"drilldown-path-body"},[_c('b-button',{staticClass:"drilldown-path-item",on:{"click":function($event){return _vm.onBreadcrumbClick(-1)}}},[_vm._v(" "+_vm._s(_vm.reportName)+" ")]),_vm._l((_vm.drilldownPath),function(leg,idx){return [_c('b-button',{key:idx,staticClass:"drilldown-path-item",class:{ last: idx === _vm.drilldownPath.length - 1 },on:{"click":function($event){return _vm.onBreadcrumbClick(idx)}}},[_c('span',{staticClass:"material-icons"},[_vm._v("subdirectory_arrow_right")]),_vm._v(" "+_vm._s(leg.valueLabel)+" ")])]})],2)]},proxy:true}],null,false,4127230636)}):_vm._e(),(_vm.viewTypes.length > 1)?_vm._l((_vm.viewTypes),function(typeOption,idx){return _c('button',{key:typeOption,staticClass:"button icon-button",class:{ active: _vm.selectedType == idx },on:{"click":function($event){_vm.selectedType = idx}}},[_c(_vm.ingestViews[typeOption].icon.name,{tag:"component",attrs:{"variant":"none"}})],1)}):_vm._e(),(
          _vm.columns.length > 0 && _vm.viewTypes[_vm.selectedType] != 'scorecard-view'
        )?_c('button',{staticClass:"button",attrs:{"disabled":_vm.exporting},on:{"click":function($event){return _vm.exportCsv()}}},[_c('i',{key:"csvExport",staticClass:"material-icons"},[_vm._v(" save_alt ")])]):_vm._e()]},proxy:true},{key:"moduleControls",fn:function(){return [_c('div',{staticClass:"control-bar"},[_vm._l((_vm.controls),function(control,idx){return [(!!control.id && control.type === 'module-option-toggle')?[_c('ingest-toggle',{key:control.id,attrs:{"left":control.spec.left,"right":control.spec.right,"selected":_vm.localSelections[control.id] || control.spec.left},on:{"update:selected":function($event){return _vm.updateLocalSelection($event, control)}}})]:_vm._e(),(!!control.id && control.type === 'option-select')?[(idx !== 0)?_c('div',{key:control.id,staticClass:"control-spacer"}):_vm._e(),_c('option-select',{key:`control-${control.id}`,attrs:{"control":control}})]:_vm._e(),(!!control.id && control.type === 'date-offset-select')?[(idx !== 0 || _vm.controls.length > 0)?_c('div',{key:control.id,staticClass:"control-spacer"}):_vm._e(),_c('offset-select',{key:`control-${control.id}`,attrs:{"control":control}})]:_vm._e()]})],2)]},proxy:true}],null,false,472288447)},'component',{
      columns: _vm.reportColumns,
      data: _vm.reportData,
      comparison: _vm.comparison,
      loading: _vm.loading,
      draggedField: _vm.draggedField,
      sortBy: [].concat(_vm.sortBy, _vm.defaultSortBy),
      position: _vm.position,
      betaSpec: _vm.betaSpec,
      name: _vm.name,
      isEdit: _vm.isEdit,
      error: _vm.error,
    },false)):_vm._e(),(
      (_vm.hasDrilldown || _vm.defaultDrilled) &&
        !!_vm.drilldownTarget &&
        _vm.drilldownTargetOptions.length > 1
    )?_c('div',{staticClass:"dimension-select"},[_c('div',{staticClass:"input-label"},[_vm._v(" Dimension ")]),_c('b-field',[_c('b-dropdown',{attrs:{"position":"is-top-right","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('button',{staticClass:"button dropdown-button",attrs:{"type":"is-light"}},[_c('div',{staticClass:"option-selected-label"},[_vm._v(" "+_vm._s(_vm.drilldownTarget.name)+" ")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)]}}],null,false,2776056006),model:{value:(_vm.drilldownTarget),callback:function ($$v) {_vm.drilldownTarget=$$v},expression:"drilldownTarget"}},_vm._l((_vm.drilldownTargetOptions),function(option,i){return _c('b-dropdown-item',{key:i,staticClass:"dropdown-item",attrs:{"value":option,"aria-role":"listitem"}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }