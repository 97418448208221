<template>
  <div class="company-object-sidebar">
    <button
      class="button is-light create-button"
      @click="createCompanyObject">
      New {{ objectType }}
    </button>
    <div class="search-input">
      <b-input
        ref="searchInput"
        v-model="searchInput"
        custom-class="no-border"
        :placeholder="`Search Company ${objectType}s`"
        type="search"
        icon="magnify" />
    </div>

    <div class="search-results">
      <div
        v-for="object in resultsToShow"
        :key="object.key">
        <div
          tabindex="0"
          class="sidebar-item"
          :class="{ selected: object.key === currentObjectKey }"
          @keydown.enter="() => currentObjectKey = object.key"
          @click="() => currentObjectKey = object.key">
          {{ object.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import FuzzySearch from 'fuzzy-search'
  export default {
    name: 'CompanyObjectSidebar',
    props: {
      objectType: {
        type: String,
        required: true,
      },
      companyObjects: {
        type: Array,
        required: true,
      },
      value: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        searchInput: '',
        isUsageOpen: false,
        modalLoading: false,

        usageReport: [],
        previewData: [],

        edited: false,
      }
    },
    computed: {
      searcher () {
        const searcher = new FuzzySearch(Object.values(this.companyObjects), [
          'name',
          'key',
        ])
        return searcher
      },
      resultsToShow () {
        return this.searcher.search(this.searchInput)
      },
      currentObjectKey: {
        get () {
          return this.value
        },
        set (currentObjectKey) {
          this.$emit('input', currentObjectKey)
        },
      },
    },
    methods: {
      createCompanyObject () {
        this.$emit('createNew')
      },
    },
  }
</script>

<style lang="scss" scoped>

.company-object-sidebar {
  position: relative;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.create-button {
  border-bottom: $border-1 !important;
  font-size: 15px !important;
  width: 100%;
}

.search-input {
  padding: 3px 8px;
  border-bottom: $border-1;
  margin: 0px;
}

.search-results {
  overflow-y: scroll;
  height: 100%;
}

.sidebar-item {
  padding: 10px 20px;
  &:hover {
    cursor: pointer;
    background-color: $grayscale-8;
  }
  &.selected {
    cursor: default;
    background-color: $grayscale-7;
  }
  .id-tag {
    font-size: 12px;
    margin-right: 10px;
  }
}

.measure-canvas {
  border: $border-1;
  border-radius: 10px;
  margin: 20px;
}
</style>
