<template>
  <div class="and-filter-editor">
    <FilterListEditor v-model="filters"
                      v-bind="subObjectBinds"/>
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import FilterListEditor from '@/components/reports/config/filters/FilterListEditor'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'AndFilterEditor',
    components: {
      FilterListEditor,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    computed: {
      ...mapChildren({
        filters: 'filters',
      }),
    },
  }

</script>

<style lang="scss" scoped>

.and-filter-editor {
  display: flex;
  flex-direction: column;
}

</style>
