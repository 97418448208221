<template>
  <accordian-container
    v-if="!!controls && controls.length > 0"
    class="controls-accordian">
    <dimension-select
      v-for="(control, idx) in controls.filter(c => !!c.id && c.type == 'dimension-select')"
      :key="control.id"
      :accordian.sync="accordianModel"
      :all-controls="controls"
      :control="control"
      :start-open="idx == 0" />
  </accordian-container>
</template>

<script>
  import AccordianContainer from '@/components/common/layout/accordian/AccordianContainer'
  import DimensionSelect from '@/components/controls/DimensionSelect'
  // import V2DimensionSelect from '@/components/controls/V2DimensionSelect'

  export default {
    name: 'DimensionSelectAccordian',
    components: {
      AccordianContainer,
      DimensionSelect,
      // V2DimensionSelect,
    },
    props: {
      controls: { type: Array, default: Array, },
    },
    data () {
      return {
        controlAccordian: null,
        v2DimensionValues: [],
      }
    },
    computed: {

      accordianModel: {
        get () {
          return this.controlAccordian
        },
        set (value) {
          this.controlAccordian = value
          this.controlIsSearch = false
          this.controlSearchTerm = ''
        },
      },
    },
    watch: {
    },
    methods: {
      async loadV2DimensionValues () {
        if (this.v2DimensionSelects.length == 0) {
          return
        }
        this.v2DimensionSelects.forEach(control => {
          this.$store.dispatch('controls/startLoading', control.id)
        })
        if (this.v2DimensionSelects.length > 0) {
          this.v2DimensionValues = (await this.$apis.reporting.getDimensionValues(
            {
              dimensions: this.v2DimensionNodes,
              dates: this.allDates,
            }
          )).dimensionValues || []
        }
        this.v2DimensionSelects.forEach(control => {
          this.$store.dispatch('controls/endLoading', control.id)
        })
      },
    },
  }

</script>

<style lang="scss" scoped>

.controls-accordian.accordian-container {
  padding: 2 * $gridBase 6 * $gridBase 8 * $gridBase 6 * $gridBase;;
  height: 100%;
  width: 74 * $gridBase;
}

</style>

