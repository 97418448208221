<template>
  <drop-zone
    v-show="isVisible"
    class="field-drop"
    handle="field-container"
    :target-selector="targets"
    @drop="$emit('drop', $event)">
    <v-icon name="plus-circle" />
    <slot />
  </drop-zone>
</template>

<script>

  import DropZone from '@/components/interactable/DropZone'

  export default {
    name: 'FieldDrop',
    components: {
      DropZone,
    },
    props: {
      isVisible: Boolean,
      targets: { type: String, required: true, },
    },
  }

</script>

<style lang="scss" scoped>

.field-drop {
  width: calc(20vw - 64px);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 8px;
  border: dashed 1px;
  padding: 8px;
  margin: 8px;
  background: white;
  Height: 40px;
  flex-grow: 0;
}

.drop-zone.draggedOver {
  &, * {
    font-weight: bold;
    stroke-width: 3px;
    border-width: 3px;
  }
}

</style>
