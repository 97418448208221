<template>
  <div class="container-fluid">
    <div class="container">
      <div class="header columns mx-0 px-0 is-vcentered">
        <router-link :to="{ name: 'manage_attributes' }">
          <b-button
            type="is-light"
            class="back-btn mr-3"
            icon-right="arrow-left" />
        </router-link>
        <h2>
          {{ isNew ? "Create" : "Edit" }}
          Attribute
        </h2>
        <div class="control-bar">
          <b-button
            v-if="!isNew && isDirty"
            class="mr-5"
            @click="discardChanges">
            Discard changes
          </b-button>
          <b-button
            type="is-primary"
            :disabled="(!isNew && !isDirty) || !isValid"
            @click="submit">
            {{ isNew ? "Create" : "Save" }}
          </b-button>
        </div>
      </div>

      <div class="columns m-0 mt-6">
        <div class="column is-4 p-0 pr-6">
          <h2 class="header-with-underline">
            Info
          </h2>
          <div class="input-form">
            <validation-provider
              v-slot="{ errors }"
              rules="required">
              <b-field
                label="Name"
                :type="errors.length ? 'is-danger' : ''"
                :message="errors[0]"
                class="mt-5">
                <b-input
                  ref="attributeName"
                  v-model="nameModel" />
              </b-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required">
              <b-field
                label="Data Type"
                :type="errors.length ? 'is-danger' : ''"
                :message="errors[0]"
                class="mt-5">
                <b-dropdown
                  v-model="dataTypeModel"
                  style="width: 100%"
                  aria-role="list">
                  <template #trigger="{ active }">
                    <button
                      style="width: 100%"
                      class="button dropdown-button is-fullwidth"
                      type="is-light">
                      <span class="button-label">
                        {{ inputs?.data_type ? inputs?.data_type.key : "Select Data Type" }}
                      </span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
                    </button>
                  </template>
                  <template v-for="option in dataTypeOptions">
                    <b-dropdown-item
                      :key="option.key"
                      :value="option"
                      class="is-capitalized"
                      aria-role="listitem">
                      {{ option.key }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </b-field>
            </validation-provider>
            <b-field
              v-if="dataTypeModel"
              class="attribute-input mt-5"
              label="Default Value">
              <b-dropdown
                v-if="dataTypeModel.key === 'boolean'"
                v-model="defaultValueModel"
                expanded
                placeholder="Select a value">
                <template #trigger="{ active }">
                  <button
                    expaneded
                    class="button dropdown-button is-fullwidth"
                    type="is-light">
                    <span class="button-label mr-auto">
                      {{ defaultValueModel }}
                    </span>
                    <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
                  </button>
                </template>
                <template v-for="option in booleanOptions">
                  <b-dropdown-item
                    :key="option"
                    :value="option"
                    class="is-capitalized"
                    aria-role="listitem">
                    {{ option }}
                  </b-dropdown-item>
                </template>
              </b-dropdown>

              <b-numberinput
                v-else-if="dataTypeModel.key === 'percent'"
                v-model="defaultValueModel"
                :min-step="0.0000000000001"
                icon-right="percent"
                :controls="false" />
              <b-numberinput
                v-else-if="dataTypeModel.key === 'number'"
                v-model="defaultValueModel"
                :min-step="0.0000000000001"
                icon="numeric"
                :controls="false" />

              <b-numberinput
                v-else-if="dataTypeModel.key === 'money'"
                ref="moneyInput"
                v-model="defaultValueModel"
                :min-step="0.01"
                icon="currency-usd"
                :controls="false" />

              <b-input
                v-else-if="dataTypeModel.key === 'string'"
                v-model="defaultValueModel"
                icon="alphabetical-variant"
                :controls="false" />

              <b-timepicker
                v-else-if="dataTypeModel.key === 'time'"
                v-model="defaultValueModel"
                icon="clock"
                editable
                :enable-seconds="false" />

              <b-datepicker
                v-else-if="dataTypeModel.key === 'date'"
                v-model="defaultValueModel"
                position="is-bottom-left"
                icon="calendar-today"
                :icon-right="selected ? 'close-circle' : ''"
                icon-right-clickable
                editable
                trap-focus />
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { convertToBackend, convertToFrontend } from '@/components/inputs/AttributeValueConverterHelpers'
  export default {
    name: 'EditAttributePage',
    data () {
      return {
        attributeId: '',
        isLoading: true,
        isNew: true,

        isValid : true,
        inputs: null,

        isDirty: false,

        dates: {
          type: 'range',
          start: {
            key: new Date(new Date() - 30 * 24 * 3600000)
              .toISOString()
              .split('T')[0],
            value: new Date(new Date() - 30 * 24 * 3600000)
              .toISOString()
              .split('T')[0],
          },
          end: {
            key: new Date().toISOString().split('T')[0],
            value: new Date().toISOString().split('T')[0],
          },
        },

        defaultValues: {
          string: undefined,
          number: undefined,
          date: null,
          time: undefined,
          money: undefined,
          percent: undefined,
          boolean: 'FALSE',
        },

        booleanOptions: ['TRUE', 'FALSE',],
      }
    },
    computed: {
      ...mapGetters({
        entityAttributes: 'entities/entityAttributes',
        dataTypes: 'expressions/dataTypes',
        entityTypes: 'entities/entityTypes',
      }),

      dataTypeModel: {
        get () {
          return this.dataTypes[this.inputs?.data_type.key]
        },
        set (dataTypeModel) {
          this.isDirty = true
          this.inputs = {
            ...this.inputs,
            data_type: dataTypeModel,
            default_value: this.defaultValues[dataTypeModel.key],
          }
        },
      },
      dataTypeOptions () {
        return Object.values(this.dataTypes).filter(
          type =>
            ['string', 'number', 'date', 'time', 'money', 'percent','boolean',].includes(type.key))
          .map(type => ({ ...type, label: type.title, }))
      },
      attributes () {
        return this.entityAttributes.filter(ea => ea.entity_type.key == this.entityType.key)
      },
      order () {
        return {
          field: 'name',
          ascending: true,
        }
      },
      nameModel: {
        get () {
          return this.inputs?.name || ''
        },
        set (nameModel) {
          this.isDirty = true
          this.inputs = { ...this.inputs, name: nameModel, }
        },
      },
      defaultValueModel: {
        get () {
          return convertToFrontend(this.inputs?.default_value, this.dataTypeModel.key)
        },
        set (defaultValue) {
          this.isDirty = true
          if (this.dataTypeModel.key === 'money' && !this.$refs.moneyInput?.checkHtml5Validity()) {
            this.isValid = false
            return
          }
          else {
            this.isValid = true
          }

          this.inputs = { ...this.inputs, default_value: convertToBackend(defaultValue, this.dataTypeModel.key), }
        },
      },
    },

    async mounted () {
      await this.loadData()
      this.attributeId = this.$route.params.attribute_id

      if (this.attributeId && this.attributeId !== 'new') {
        this.editAttribute()
      } else {
        this.startNew()
      }
    },

    methods: {
      editAttribute () {
        const attribute = this.entityAttributes.filter(attribute => attribute.id === this.attributeId)[0]
        this.isNew = false
        this.inputs = attribute
      },
      startNew () {
        this.inputs = {
          entity_type: this.entityType,
          name: '',
          data_type: { key: 'string', },
          key: null,
          default_value: this.defaultValues['string'],
        }
        this.isNew = true
        this.$nextTick(() => {
          this.$refs.attributeName.focus()
        })
      },
      requestCancel () {
        if (this.isDirty) {
          this.cancelling = true
        } else {
          this.confirmCancel()
        }
      },
      confirmCancel () {
        this.inputs = null
        this.isNew = false
        this.cancelling = false
      },

      async submit () {
        if (this.isNew) {
          await this.saveNew()
        } else {
          await this.saveEdit()
        }},

      async saveNew () {
        await this.$store.dispatch('entities/createAttribute', this.inputs)
        this.inputs = null
        this.isNew = false
        this.isDirty = false
        this.$router.push({ name: 'manage_attributes', })
      },

      async saveEdit () {
        await this.$store.dispatch('entities/updateAttribute', this.inputs)
        this.isNew = false
        this.isDirty = false
      },

      discardChanges () {
        this.editAttribute()
        this.isDirty = false
      },

      async loadData () {
        this.isLoading = true
        await this.$store.dispatch('entities/initialize', this.dates)
        await this.$store.dispatch('expressions/initialize')

        const entityTypeKey = this.$route.params.entity_type

        this.entityType = this.entityTypes.find(entityType => entityType.key == entityTypeKey)
        this.isLoading = false
      },

    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: white;
  width: 100%;
  padding: 20px 14px;
  overflow: auto;

  .header {
    margin-top: 30px;
    padding-bottom: 20px;

    border-bottom: $border-1;

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .back-btn {
      font-size: 24px;
      border-radius: 100%;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .dropdown-button {
    padding: 0 10px;
    display: flex;
    text-transform: capitalize;
    .button-label {
      margin-right: auto;
    }
  }

  .pdf-reports-table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
  }

  .report-table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .data-tags {
    font-size: 12px;
    margin: 2px;
  }

  .actions {
    display: flex;

    .action-btn {
      margin: 0 5px;
    }
  }
}
</style>
<style lang="scss">
.attribute-input {
  input,
  select {
    border: $border-1 !important;

    &:focus {
      border-color: $primary !important;
    }

    ::after {
      border-color: black !important;
    }

    &[type="number"] {
      text-align: right !important;
    }
  }
  .select.is-primary::after {
    border-color: $grayscale-3 !important;
  }
}
</style>
