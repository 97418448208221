<template>
  <div class="company-filter-editor">
    <CompanyObjectEditor
      v-model="vModel"
      object-type="Filter">
      <template #header-actions>
        <slot name="header-actions" />
      </template>
      <template #object-editor>
        <FilterEditor
          v-model="filter"
          v-bind="subObjectBinds" />
      </template>
    </CompanyObjectEditor>
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyObjectEditor from '@/components/reports/config/common/CompanyObjectEditor'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'CompanyFilterEditor',
    components: {
      CompanyObjectEditor,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    computed: {
      ...mapChildren({
        filter: 'filter',
      }),
    },
  }

</script>

<style lang="scss" scoped>

.company-filter-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}

</style>
