import axios from 'axios'

export const dashboardModel = {
  namespaced: true,
  state () {
    return {
      name: '',
      dashboard: null,
      loading: false,
    }
  },
  getters: {
    id (state) {
      if (state.dashboard !== null) {
        return `${state.dashboard.id}`
      }
      return null
    },
    dashboard (state) {
      return state.dashboard
    },
  },
  mutations: {
    setDashboard (state, dashboard) {
      state.dashboard = dashboard
    },
    setLoading (state, loading) {
      state.loading = loading
    },
  },
  actions: {
    async checkStale (context, expectedId) {
      if (context.getters.id !== expectedId) {
        await context.dispatch('loadDashboard', expectedId)
      }
    },
    async loadDashboard (context, id) {
      context.commit('setDashboard', null)
      context.commit('setLoading', true)
      if (!id) {
        return
      }
      let dashPromise = axios.get(
      `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/dashboard/${id}`,
      { headers: { authorization: context.rootGetters['auth/authToken'], }, })
      let dash = (await dashPromise).data
      if (
        context.rootGetters['dashboards/selectedDashboardId'] === `${id}`) {
        context.commit('setDashboard', dash)
        context.commit('setLoading', false)
        try {
          let viewPromise = axios.post(
          `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/dashboard/${id}/view`,
          {},
          { headers: { authorization: context.rootGetters['auth/authToken'], }, })
          await viewPromise
        }
        catch (e) {
          console.log('API Error', e)
        }
      }
    },
  },
}
