<template>
  <div class="metric">
    <div class="row">
      <div class="left main-title">
        {{ dashboardModule.name }}
      </div>

      <div class="right main-value">
        <span v-if="!isLoading"> {{ data.primary_row?.value }}</span>
        <b-skeleton
          v-else
          height="62"
          style="border-radius: 0"
          width="100%"
          :active="isLoading" />
      </div>
    </div>

    <div v-if="!isLoading && data?.secondary_rows">
      <div
        v-for="subRow in data?.secondary_rows"
        :key="subRow.id"
        class="row">
        <div class="left sub-title">
          {{ subRow.label }}
        </div>

        <div
          class="right sub-value"
          :class="{
            red: subRow.conditional_quality === 'bad',
            green: subRow.conditional_quality === 'good',
          }">
          <i
            v-if="subRow.conditional_direction !== 'none'"
            class="material-icons arrow-icon">
            {{
              subRow.conditional_direction === "up"
                ? "arrow_upward"
                : "arrow_downward"
            }}
          </i>
          {{ subRow.value }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'KPIModule',
    props: {
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        data: null,
        isLoading: true,
      }
    },
    computed: {},

    watch: {
      dashboardDataContext: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },
    mounted () {
      this.loadData()
    },
    methods: {
      async loadData () {
        this.isLoading = true
        this.data = await this.$apis.companyConfigs.getDashboardModuleData(
          this.dashboardKey,
          this.dashboardModule.key,
          this.dashboardDataContext
        )
        this.isLoading = false
      },
    },
  }
</script>
<style scoped>
.metric {
  background-color: white;
  border: 1px solid #e9e9ea;
  border-radius: 4px;

  .row {
    display: flex;
    border-bottom: 1px solid #e9e9ea;

    &:last-child {
      border-bottom: none;
    }

    .left {
      padding: 12px 16px;
      border-right: 1px solid #e9e9ea;
      text-align: left;
      width: 55%;
      font-size: 14px;
      color: #7e7d84;
    }

    .right {
      width: 45%;
      font-size: 16px;
      color: #7e7d84;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .main-title {
      font-size: 16px;
      font-weight: 500;
      height: 62px;
      padding: 16px;
      color: #504f54;
      display: flex;
      align-items: center;
    }

    .main-value {
      font-size: 24px;
      font-weight: 500;
      height: 62px;
      color: #008c84;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        padding: 16px;
      }
    }

    .sub-value {
      padding: 12px 16px;
      &.green {
        color: #008c84;
        background-color: #f2f9f9;
      }
      &.red {
        color: #ff0000;
        background-color: #f9f2f2;
      }

      .arrow-icon {
        font-size: 20px;

        margin-right: auto;
      }
    }
  }
}
</style>
