<template>
  <div class="category-line-view">
    <chart-title-bar
      :name.sync="nameModel"
      :is-edit="isEdit">
      <template #toolbarButtons>
        <slot name="toolbarButtons" />
      </template>
    </chart-title-bar>
    <template v-if="error">
      <error-view
        :name="name"
        @reload="$emit('reload')" />
    </template>
    <template v-else>
      <slot name="moduleControls" />
      <div class="chart-container">
        <ingest-tooltip
          :left="tooltipX"
          :top="tooltipY"
          :enabled="tooltipEnabled">
          <hover-card
            :columns="columns"
            :datum="tooltipDatum"
            :category="tooltipCategory"
            :category-color="categoryColor"
            :beta-spec="betaSpec" />
        </ingest-tooltip>
        <resize-sensor @resize="onChartResize" />
        <svg
          ref="svg"
          :viewBox="viewBox"
          @mousemove="onHover"
          @mouseleave="onLeave()"
          @scroll="onLeave()">
          <y-axis
            :columns="columns"
            :data="data.filter(d => !hiddenCategories.includes(getDimensionVal(secondaryDimensionKeys, d)))"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            :hidden-columns="[varianceMeasure]" />
          <x-axis
            :columns="columns"
            :data="data"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            :highlighted-data="highlightedData"
            @click="$emit('click', $event)"
            @hover="onHover"
            @unHover="onLeave()" />
          <template
            v-if="!!primaryMeasure">
            <line-series
              v-for="category in categories.filter(c => !hiddenCategories.includes(c.key))"
              :key="`primary-${category.key}`"
              :measure="categoryMeasure(category)"
              :data="data"
              :category="category"
              :columns="columns"
              :chart-width="viewWidth"
              :chart-height="viewHeight"
              :hidden-columns="[varianceMeasure]"
              :hidden-categories="hiddenCategories"
              :highlight-enabled="highlightEnabled"
              :highlighted-measures="highlightedMeasures"
              :highlighted-categories="highlightedCategories"
              :highlighted-data="highlightedData"
              @click="$emit('click', $event)" />
          </template>
        </svg>
      </div>
      <category-legend
        v-bind="{ categories, categoryColor, hiddenCategories, }"
        @click="toggleCategory($event)" />
    </template>
  </div>
</template>
<script>

  import ChartTitleBar from '@/components/charting/ChartTitleBar'
  import ErrorView from '@/components/module/view/ErrorView.vue'
  import HoverCard from '@/components/module/view/HoverCard.vue'
  import IngestTooltip from '@/components/common/IngestTooltip.vue'
  import LineSeries from '@/components/charting/LineSeries'
  import CategoryLegend from '@/components/charting/CategoryLegend'
  import XAxis from '@/components/charting/XAxis'
  import YAxis from '@/components/charting/YAxis'

  import chartContainerMixin from '@/components/charting/chart_container_mixin'

  export default {
    name: 'CategoryLineView',
    components: {
      ChartTitleBar,
      ErrorView,
      HoverCard,
      IngestTooltip,
      LineSeries,
      CategoryLegend,
      YAxis,
      XAxis,
    },
    mixins: [
      chartContainerMixin,
    ],
    computed: {
    },
    methods: {
      categoryMeasure (category) {
        return { ...this.primaryMeasure, colorSpec: { type: 'manual', value: this.categoryColor(category.key), }, }
      },
      onLeave () {
        this.clearHoverValues()
      },
    },
  }

</script>

<style lang="scss" scoped>

.category-line-view {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 8 * $gridBase;
  padding-top: 0;

  > .chart-container {
    display: flex;
    min-height: 0;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: visible;

    margin-top: 6 * $gridBase;
    margin-bottom: 22 * $gridBase;
    margin-left: 12 * $gridBase;

    > svg {
      overflow: visible;
      width: 100%;
      height: 100%;
    }
  }
}

svg {
  overflow: visible
}

</style>
