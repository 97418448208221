import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import { userModel } from '@/models/auth/userModel'

const TOKEN_KEY = 'auth_token'

export const authModel = {
  namespaced: true,
  state () {
    return {
      authToken: null,
    }
  },
  getters: {
    authToken (state) {
      return state.authToken
    },
    resetToken (state, getters, rootState, rootGetters) {
      return rootGetters['routeQuery'].t
    },
    isLoggedIn (state, getters) {
      let token = getters.authToken
      if (!token) {
        return false
      }
      let expirationDate = new Date(0)
      expirationDate.setUTCSeconds(jwtDecode(token).exp)

      if (expirationDate < new Date()) {
        return false
      }

      return true
    },
    companyUser (state, getters, rootState, rootGetters) {
      return rootGetters['auth/user/companyUser']
    },
    permissions (state, getters, rootState, rootGetters) {
      return rootGetters['auth/user/permissions']
    },
    loggedInUser (state, getters, rootState, rootGetters) {
      return rootGetters['auth/user/loggedInUser']
    },
    defaultCompanyId (state, getters, rootState, rootGetters) {
      return rootGetters['auth/user/defaultCompanyId']
    },
  },
  mutations: {
    setAuthToken (state, token) {
      if (token === null) {
        localStorage.removeItem(TOKEN_KEY)
      } else {
        localStorage.setItem(TOKEN_KEY, token)
      }
      state.authToken = token
    },
  },
  actions: {
    logout (context) {
      context.commit('setAuthToken', null)
      context.commit('user/setUser', null)
    },
    async initialize (context) {
      if (context.getters.loggedInUser !== null) {
        return
      }
      let persistedToken = localStorage.getItem(TOKEN_KEY)
      if (persistedToken === null) {
        return
      }
      context.commit('setAuthToken', localStorage.getItem(TOKEN_KEY))
      await context.dispatch('user/loadUser')
      return
    },
    async login (context, { email, password, }) {
      let response = await axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/login`,
        { email, password, })
      if (response.data.valid) {
        context.commit('setAuthToken', response.data.token)
        await context.dispatch('user/loadUser')
        return true
      }
      return false
    },
    async requestResetEmail (context, email) {
      await axios.post(`${process.env.VUE_APP_ADMIN_API_URL}/email_password`, { email, })
    },
    async resetPassword (context, password) {
      let response =
        await axios.post(`${process.env.VUE_APP_ADMIN_API_URL}/reset_password`,
        { password, },
        { headers: { authorization: context.getters.resetToken, }, })
      return response.data.valid
    },
    async changePassword (context, { password, newPassword, }) {
      let response = await axios.post(`${process.env.VUE_APP_ADMIN_API_URL}/user/${context.getters.loggedInUser.id}/changePassword`,
        { password, newPassword, },
        { headers: {
          authorization: context.getters.authToken,
        },
        })
      return response.data.valid
    },
  },
  modules: {
    user: userModel,
  },
}
