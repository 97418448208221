<template>
  <i
    class="material-icons">table_chart</i>
</template>

<script>

  export default {
    name: 'TableIcon',
    props: {},
  }

</script>

<style lang="scss" scoped>

</style>
