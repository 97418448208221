<template>
  <div class="role-settings">
    <div class="container">
      <validation-observer ref="form">
        <div class="page-title">
          <router-link :to="{ name: 'roles' }">
            <b-button
              type="is-light"
              class="back-btn mr-3"
              icon-right="arrow-left" />
          </router-link>
          <h2>{{ isCreateMode ? "Create Role" : "Edit Role" }}</h2>

          <div
            v-if="isDirty"
            class="actions">
            <button
              v-if="!isCreateMode"
              class="button is-danger mr-3"
              @click="cancelRoleEdit">
              Discard Changes
            </button>
            <button
              v-if="isCreateMode"
              class="button is-primary"
              @click="createRole">
              Create Role
            </button>
            <button
              v-else
              class="button is-primary"
              @click="saveEdits">
              Save Changes
            </button>
          </div>
        </div>

        <div class="settings">
          <h3 class="section-name">
            Basic Info
          </h3>

          <div class="columns mx-0 is-multiline">
            <div class="column px-0 is-3">
              <validation-provider
                v-slot="{ errors }"
                rules="required">
                <b-field
                  label="Name"
                  :type="errors.length ? 'is-danger' : ''"
                  class="field">
                  <b-input
                    v-model="nameInput"
                    @input="makeInputsDirty" />
                </b-field>
              </validation-provider>
            </div>
            <div class="column px-0 is-3">
              <b-field
                label="Description"
                class="field">
                <b-input
                  v-model="descInput"
                  type="textarea"
                  rows="2"
                  @input="makeInputsDirty" />
              </b-field>
            </div>
          </div>
        </div>
        <div class="settings">
          <h3 class="section-name">
            Access
          </h3>

          <div class="columns mx-0 is-multiline">
            <div class="column px-0">
              <div class="permission-container">
                <div class="header">
                  <h3>Permissions</h3>

                  <div class="controls">
                    <button
                      class="button is-text"
                      @click="selectAllPermissions">
                      Select All
                    </button>

                    <button
                      class="button is-text"
                      @click="unselectAllPermissions">
                      Deselect All
                    </button>
                  </div>
                </div>

                <div
                  v-for="permission in permissionsInput"
                  :key="permission.id"
                  class="permission-item">
                  <div class="item-title">
                    {{ permission.name }}
                  </div>

                  <b-checkbox
                    v-model="permission.active"
                    size="is-medium"
                    @input="makeInputsDirty" />
                </div>
              </div>
            </div>
            <div class="column px-0 ml-4">
              <div class="permission-container">
                <div class="header">
                  <h3>Reports</h3>

                  <div class="controls">
                    <button
                      class="button is-text"
                      @click="selectAllReports">
                      Select All
                    </button>

                    <button
                      class="button is-text"
                      @click="unselectAllReports">
                      Deselect All
                    </button>
                  </div>
                </div>

                <div
                  v-for="report in reportsInput"
                  :key="report.id"
                  class="permission-item">
                  <i class="material-icons report-icons">{{ report.icon }}</i>
                  <div class="item-title">
                    {{ report.name }}
                  </div>

                  <b-checkbox
                    v-model="report.active"
                    size="is-medium"
                    @input="makeInputsDirty" />
                </div>

                <div
                  v-for="dashboard_report in dashboardReportsInput"
                  :key="dashboard_report.key"
                  class="permission-item">
                  <i class="material-icons report-icons">{{ dashboard_report.icon }}</i>
                  <div class="item-title">
                    {{ dashboard_report.name }}
                  </div>

                  <b-checkbox
                    v-model="dashboard_report.active"
                    size="is-medium"
                    @input="makeInputsDirty" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false" />
  </div>
</template>

<script>
  import { roleService } from '@/service/admin/roleService'

  export default {
    name: 'RoleEditor',
    components: {},
    beforeRouteLeave (from, to, next) {
      if (this.isDirty) {
        this.$buefy.dialog.confirm({
          title: 'Unsaved Changes',
          container: '.role-settings',
          message: 'Are you sure you want to discard changes and leave page?',
          onConfirm: () => next(),
        })
      } else {
        next()
      }
    },
    data () {
      return {
        isLoading: false,
        isDirty: false,

        role: null,

        nameInput: null,
        descInput: null,

        permissionsInput: [],
        reportsInput: [],
        dashboardReportsInput: [],
      }
    },
    computed: {
      currentReports () {
        return []
      },
      roleId () {
        return this.$route.params.role_id
      },

      isCreateMode () {
        return this.$route.name === 'create_role'
      },
    },
    async mounted () {
      await this.loadOptions()

      if (!this.isCreateMode) {
        await this.loadRoleData()
      }
    },
    methods: {
      selectAllReports () {
        this.reportsInput.forEach((item) => (item.active = true))
        this.dashboardReportsInput.forEach((item) => (item.active = true))
        this.isDirty = true
      },

      unselectAllReports () {
        this.reportsInput.forEach((item) => (item.active = false))
        this.dashboardReportsInput.forEach((item) => (item.active = false))
        this.isDirty = true
      },

      selectAllPermissions () {
        this.permissionsInput.forEach((item) => (item.active = true))
        this.isDirty = true
      },

      unselectAllPermissions () {
        this.permissionsInput.forEach((item) => (item.active = false))
        this.isDirty = true
      },

      cancelRoleEdit () {
        this.loadRoleData()
        this.isDirty = false
      },

      async saveEdits () {
        this.isLoading = true

        const editedRole = {
          ...this.role,
          name: this.nameInput,
          description: this.descInput,
          permission_groups: this.permissionsInput.filter((item) => item.active),
          reports: this.reportsInput.filter((item) => item.active),
          dashboard_reports: this.dashboardReportsInput.filter((item) => item.active),
        }

        await roleService.updateRole(this.$route.params.company_id, editedRole)

        this.isDirty = false
        this.isLoading = false
      },

      async createRole () {

        const isFormValidatied = await this.$refs.form.validate()
        if (!isFormValidatied) return

        this.isLoading = true
        const newRole = {
          ...this.role,
          name: this.nameInput,
          description: this.descInput,
          permission_groups: this.permissionsInput.filter((item) => item.active),
          reports: this.reportsInput.filter((item) => item.active),
          dashboard_reports: this.dashboardReportsInput.filter((item) => item.active),
        }
        await roleService.createRole(this.$route.params.company_id, newRole)

        this.isDirty = false
        this.isLoading = false

        this.$router.push({
          name: 'roles',
        })
      },

      makeInputsDirty () {
        this.isDirty = true
      },

      async loadOptions () {
        this.isLoading = true

        let companyId = this.$route.params.company_id

        try {
          const permissionOptions = await roleService.loadPermissions(companyId)
          const reportOptions = await roleService.loadReports(companyId)
          const dashboardReportOptions = await roleService.loadDashboardReports(companyId)

          this.permissionsInput = permissionOptions.map((item) => {
            return {
              ...item,
              active: false,
            }
          })

          this.reportsInput = reportOptions.map((item) => {
            return {
              ...item,
              active: false,
            }
          })

          this.dashboardReportsInput = dashboardReportOptions.map((item) => {
            return {
              ...item,
              active: false,
            }
          })

          this.isLoading = false
        } catch (e) {
          console.log('error')
        }
      },
      async loadRoleData () {
        this.isLoading = true
        let companyId = this.$route.params.company_id

        const rolesData = await roleService.loadRoles(companyId)
        const roleData = rolesData.find((role) => role.id == this.roleId)
        this.role = roleData

        this.nameInput = roleData.name
        this.descInput = roleData.description

        this.permissionsInput = this.permissionsInput.map((item) => {
          return {
            ...item,
            active: roleData.permission_groups.some((permission) => {
              return permission.id === item.id
            }),
          }
        })

        this.reportsInput = this.reportsInput.map((item) => {
          return {
            ...item,
            active: roleData.reports.some((report) => {
              return report.id === item.id
            }),
          }
        })

        this.dashboardReportsInput = this.dashboardReportsInput.map((item) => {
          return {
            ...item,
            active: roleData.dashboard_reports.some((dashboard_report) => dashboard_report.key === item.key),
          }
        })

        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.page-title {
  margin-top: 50px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  height: 50px;

  h2 {
    font-weight: 400;
    font-size: 22px;
  }

  .back-btn {
    font-size: 24px;
    border-radius: 100%;
  }

  .actions {
    margin-left: auto;
  }
}

.permission-container {
  border: $border-1;
  border-radius: 5px;

  .header {
    font-size: 15px;
    height: 40px;
    font-weight: 500;
    background-color: $grayscale-8;
    padding: 0 10px;

    display: flex;
    align-items: center;

    .controls {
      margin-left: auto;
      .button {
        font-size: 11px;
      }
    }
  }

  .permission-item {
    display: flex;
    font-size: 14px;
    border-top: $border-1;
    padding: 10px;
    align-items: center;
    .item-title {
      margin-right: auto;
    }

    .item-icon {
      margin: 0;
      opacity: 0.8;
    }

    .report-icons {
      font-size: 17px;
      margin-right: 15px;
    }
  }
}
.settings {
  border-bottom: $border-1;
  padding: 40px 0;
  .field {
    margin-right: 20px;
  }

  .section-name {
    font-size: 19px;
    margin-bottom: 20px;
  }
}
</style>
