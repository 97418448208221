<template>
  <div class="container">
    <div class="page-title columns is-vcentered">
      <router-link :to="{ name: 'company_settings' }">
        <b-button
          type="is-light"
          class="back-btn mr-3"
          icon-right="arrow-left" />
      </router-link>
      <h2>Location Settings</h2>
    </div>

    <div v-if="locationInfo">
      <div class="header columns mx-0">
        <div
          v-if="logoUrl"
          class="location-logo">
          <img
            :src="logoUrl"
            alt="" />
        </div>

        <div
          v-else
          class="logo-placeholder">
          <img
            class="placeholder"
            src="@/assets/icons/restaurant.svg"
            alt="" />
        </div>

        <div class="location-info">
          <h2>{{ locationInfo.displayName || locationInfo.name }}</h2>
        </div>
      </div>

      <div class="settings">
        <h3 class="section-name">
          Info
        </h3>

        <div class="columns mx-0 is-multiline">
          <div class="column px-0 is-3">
            <b-field
              label="Address"
              class="field">
              <b-input
                v-model="locationInfo.address"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              label="Phone"
              class="field">
              <b-input
                v-model="locationInfo.contactPhone"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              label="Email"
              class="field">
              <b-input
                v-model="locationInfo.fiscalStartYear"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              class="field"
              label="Time Zone">
              <b-input
                v-model="locationInfo.timeZone"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              class="field"
              label="Status">
              <b-input
                :value="locationInfo.isOpen ? 'Open' : 'Closed'"
                disabled />
            </b-field>
          </div>
        </div>
      </div>

      <div class="settings">
        <h3 class="section-name">
          Data Sources
        </h3>

        <div class="columns mx-0 is-multiline">
          <div class="column px-0 is-3">
            <b-field
              label="Accounting"
              class="field">
              <b-input
                v-model="locationInfo.accountingType"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              class="field"
              label="Invoices">
              <b-input
                v-model="locationInfo.invoicingType"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              class="field"
              label="Point of Sale">
              <b-input
                v-model="locationInfo.posType"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              class="field"
              label="Reservations">
              <b-input
                v-model="locationInfo.reservationType"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              class="field"
              label="Scheduling">
              <b-input
                v-model="locationInfo.schedulingType"
                disabled />
            </b-field>
          </div>
          <div class="column px-0 is-3">
            <b-field
              class="field"
              label="Time & Attendance">
              <b-input
                v-model="locationInfo.timeAndAttendanceType"
                disabled />
            </b-field>
          </div>
        </div>
      </div>

      <div class="meal-periods">
        <h3 class="section-name">
          Meal Periods
        </h3>

        <b-table
          :data="locationInfo.mealPeriods"
          :loading="isLoading"
          :hoverable="true"
          class="table">
          <b-table-column
            v-slot="props"
            sortable
            field="name"
            label="Name">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            sortable
            field="start"
            label="Start">
            {{ props.row.start | formatTime }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            sortable
            field="end"
            label="End">
            {{ props.row.end | formatTime }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            width="450"
            sortable
            label="Days">
            <span
              v-for="day in props.row.daysOfWeek"
              :key="day">
              <b-tag
                v-if="dayMap[day]"
                size="is-medium"
                type="is-info is-light"
                class="day-tags mr-3">
                {{ dayMap[day] }}
              </b-tag>
            </span>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered mt-5 mb-5">
              <b-tag
                v-if="!isLoading"
                class="data-tags"
                rounded
                size="is-medium">
                No Meal Periods Found 🥥
              </b-tag>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
  import * as moment from 'moment'
  export default {
    name: 'LocationPage',
    filters: {
      formatTime (date) {
        return moment(date, 'HH:mm:ss').format('h:mm A') || ''
      },
    },
    data () {
      return {
        isLoading: false,
        locationId: null,
        locationInfo: null,
        s3: process.env.VUE_APP_S3_BASE_URL, //todo: move to store later
        dayMap: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun',],
      }
    },
    computed: {
      logoUrl () {
        if (this.locationInfo && this.locationInfo.s3Path) {
          return `${this.s3}/${this.locationInfo.s3Path}`
        }
        return false
      },
    },

    async mounted () {
      await this.onLoad()
    },

    methods: {
      async onLoad () {
        this.locationId = this.$route.params.location_id
        const response = await this.$apis.company.getLocation(this.locationId)
        this.locationInfo = response
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  padding: 50px 0;
}

.page-title {
  margin-top: 30px;
  padding-bottom: 50px;

  .back-btn {
    font-size: 24px;
    border-radius: 100%;
  }

  h2 {
    font-weight: 400;
    font-size: 22px;
    margin-left: 10px;
  }
}

.header {
  display: flex;
  align-items: center;

  .location-logo {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
    border: $border-1;

    img {
      width: 100%;
      height: auto;
    }
  }

  .logo-placeholder {
    width: 80px;
    height: 80px;
    background-color: #eafcf5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    img {
      height: auto;
      width: 50%;
    }
  }

  .location-info {
    padding-left: 30px;

    h2 {
      font-size: 24px;
      font-weight: 700;
      color: $ui-01;
    }
  }
}
.section-name {
  font-size: 19px;
  margin-bottom: 20px;
}
.settings {
  border-bottom: $border-1;
  padding: 40px 0;
  .field {
    margin-right: 20px;
  }
}
.meal-periods {
  margin-top: 50px;

  .table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
    text-transform: capitalize;
    max-width: 1000px;
  }
}
</style>
