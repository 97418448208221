<template>
  <div class="container">
    <div class="header columns m-0 px-0 is-vcentered">
      <router-link
        :to="
          isCreateMode
            ? { name: 'pdf_report_library' }
            : { name: 'pdf_report_editor', params: { report_id: pdfReportID } }
        ">
        <b-button
          type="is-light"
          class="back-btn mr-3"
          icon-right="arrow-left" />
      </router-link>

      <div class="report-breadcrumbs">
        <div>
          <h4>Report Settings</h4>
          <h2>{{ reportData?.name || 'Pdf Report' }}</h2>
        </div>
      </div>
      <div class="control-bar">
        <b-button
          :disabled="!isDirty"
          class="mr-5"
          @click="discardChanges">
          Discard changes
        </b-button>

        <b-button
          type="is-primary"
          :disabled="!isDirty"
          @click="saveEdits">
          {{ isCreateMode ? "Create Report" : "Publish Changes" }}
        </b-button>
      </div>
    </div>

    <div class="mt-6">
      <div class="columns m-0">
        <div class="column is-4 mr-auto">
          <b-field
            label="Report Name"
            class="field">
            <b-input
              v-model="nameInput"
              @input="makeInputsDirty" />
          </b-field>
          <b-field
            label="Time Range"
            class="field">
            <TimeRangeEditor
              v-model="timeRangeInput"
              :optional="false" />
          </b-field>
        </div>
        <div class="column is-6">
          <FilterConfigListEditor
            v-model="filtersInput"
            class="editor-inputs"
            @input="makeInputsDirty" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TimeRangeEditor from '@/components/reports/config/modules/common/TimeRangeEditor'
  import FilterConfigListEditor from '@/components/reports/config/modules/common/FilterConfigListEditor'

  export default {
    name: 'PdfReportSettings',
    components: {
      FilterConfigListEditor,
      TimeRangeEditor,
    },
    props: {},
    data () {
      return {
        isCreateMode: true,
        reportData: {
          name: '',
          default_time_range: {
            key: 'wtd',
            name: 'Week to Date',
            type: 'CalendarPreset',
          },
          style: {
            is_a11y: false,
          },
          modules: [],
        },
        pdfReportID: null,

        isLoading: false,
        isDirty: false,
      }
    },
    computed: {
      filtersInput: {
        get () {
          return this.reportData.filters
        },

        set (value) {
          this.reportData.filters = value
          this.isDirty = true
        },
      },

      nameInput: {
        get () {
          return this.reportData.name || ''
        },
        set (value) {
          this.reportData.name = value
          this.isDirty = true

        },
      },

      timeRangeInput: {
        get () {
          return this.reportData.default_time_range
        },
        set (value) {
          this.reportData.default_time_range = { ...this.reportData.default_time_range, ...value, }
          this.isDirty = true

        },
      },
    },

    async mounted () {
      this.pdfReportID = this.$route.params.report_id

      if (this.pdfReportID && this.pdfReportID !== 'new') {
        this.isCreateMode = false
        this.loadPdfReportData()
      }
      await this.$store.dispatch('app/reportsConfig/refreshOptions')
    },
    methods: {
      toggleFilters (val) {
        if (val) {
          this.reportData.filters = [
            {
              'type': 'DimensionInFilter',
              'dimension': 'location',
              'values': [],
            },
          ]
        } else {
          this.reportData.filters = []
        }

        this.isDirty = true
      },
      discardChanges () {
        this.loadPdfReportData()
      },

      async loadPdfReportData () {
        this.isLoading = true
        const response = await this.$apis.pdfReports.getPdfReportData(
          this.pdfReportID
        )

        this.reportData = response

        await this.$nextTick()
        await this.$nextTick()
        await this.$nextTick()

        this.isDirty = false
        this.isLoading = false
      },

      async saveEdits () {
        this.isLoading = true
        const requestBody = this.reportData

        if (this.isCreateMode) {
          const response = await this.$apis.pdfReports.createPdfReport(
            requestBody
          )
          if (response) {
            this.$router.push({
              name: 'pdf_report_editor',
              params: { report_id: response.key, },
            })
          } else {
            this.$buefy.toast.open({
              message: `Error Saving Data`,
              type: 'is-warning',
            })
          }
        } else {
          const response = await this.$apis.pdfReports.updatePdfReport(
            this.pdfReportID,
            requestBody
          )
          if (!response) {
            this.$buefy.toast.open({
              message: `Error Saving Data`,
              type: 'is-warning',
            })
          } else {
            this.isDirty = false
          }
        }

        this.isLoading = false
      },

      makeInputsDirty () {
        this.isDirty = true
      },
    },
  }
</script>

<style lang="scss" scoped>
.report-breadcrumbs{
  display: flex;

 h2 {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
  }

 h4 {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    color: $grayscale-3;
  }

}

.container {
  overflow-y: auto;
  position: relative;
  padding-bottom: 50px;
  padding: 20px;

  .header {
    border-bottom: $border-1;
    margin-top: 30px;

    padding-bottom: 20px;

    .back-btn {
      font-size: 24px;
      border-radius: 100%;
    }

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }
}
</style>
