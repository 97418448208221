<template>
  <div class="form">
    <div class="container">
      <div class="header columns is-vcentered">
        <router-link :to="{ name: 'module-library' }">
          <b-button
            type="is-light"
            class="back-btn mr-3"
            icon-right="arrow-left" />
        </router-link>
        <h2>Edit Module</h2>
        <div class="control-bar">
          <b-button
            type="is-primary"
            @click="saveEdits">
            Save
          </b-button>
          <b-button
            type="is-warning"
            class="ml-5"
            @click="deleteModule">
            Delete
          </b-button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h3 class="row">
            Module Configuration
          </h3>
          <ingest-form :fields.sync="moduleConfigInputs" />
        </div>
        <div class="column">
          <div v-if="moduleContent.type">
            <h3>Preview</h3>
            <div class="component-container">
              <component
                :is="widgets[moduleContent.type]"
                :content="moduleContent.content"
                class="component" />
            </div>
          </div>
        </div>
      </div>
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="true" />
    </div>
  </div>
</template>

<script>
  import Kpis from '@/components/dashboard/Kpis.vue'
  import HtmlModule from '@/components/dashboard/HtmlModule.vue'
  import IngestForm from '@/components/form/IngestForm.vue'

  export default {
    name: 'GridBuilder',
    components: {
      Kpis,
      HtmlModule,
      IngestForm,
    },
    props: {},
    data () {
      return {
        editorOptions: {
          mode: 'code',
        },
        moduleConfigInputs: [],
        moduleID: null,
        isLoading: false,
        widgets: {
          kpi: Kpis,
          html: HtmlModule,
        },

        moduleContent: {},
      }
    },
    computed: {},

    async mounted () {
      this.moduleID = this.$route.params.module_id

      await this.loadInputs()
      await this.loadModuleContent()
    },
    methods: {
      async loadInputs () {
        const moduleConfigs = await this.$apis.modules.getModuleConfig(
          this.moduleID
        )
        if (moduleConfigs) {
          this.moduleConfigInputs = moduleConfigs.fields
        } else {
          this.$buefy.toast.open({
            message: `Error Loading Config`,
            type: 'is-warning',
          })
        }

      },
      async saveEdits () {
        this.isLoading = true
        const moduleConfigs = await this.$apis.modules.updateModuleConfig(
          this.moduleID,
          this.moduleConfigInputs
        )

        if (moduleConfigs) {
          this.moduleConfigInputs = moduleConfigs.fields
        } else {
          this.$buefy.toast.open({
            message: `Error Saving Config`,
            type: 'is-warning',
          })
        }

        this.isLoading = false

        this.loadModuleContent()
      },
      async loadModuleContent () {
        const moduleContent = await this.$apis.modules.getModuleContent(
          this.moduleID,
          {}
        )

        if (moduleContent) {
          this.moduleContent = moduleContent
        } else {
          this.$buefy.toast.open({
            message: `Error Loading Preview`,
            type: 'is-warning',
          })
        }

      },
      async deleteModule () {
        this.$buefy.dialog.confirm({
          title: 'Deleting Module',
          message: 'Are you sure you want to <b>delete</b> this Module.',
          confirmText: 'Delete Module',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true
            await this.$apis.modules.deleteModule(this.moduleID)
            this.isLoading = false
            this.$router.push({ name: 'module-library', })
          },
        })
      },
      onError (e) {
        console.log('error', e)
      },
      isInputVisible (input) {
        if (input.dependsOn) {
          if (Array.isArray(input.dependsOn)) {
            for (let dependsOn of input.dependsOn) {
              const parent = this.moduleConfigInputs.find(
                (val) => val.id === dependsOn.id
              )
              if (
                !(Array.isArray(parent.value)
                  ? parent.value.includes(dependsOn.value)
                  : parent.value === dependsOn.value)
              ) {
                return false
              }
            }
          } else {
            const parent = this.moduleConfigInputs.find((val) => {
              return val.id === input.dependsOn.id
            })
            return Array.isArray(parent.value)
              ? parent.value.includes(input.dependsOn.value)
              : parent.value === input.dependsOn.value
          }
        }
        return true
      },
    },
  }
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  overflow-y: auto;
  background: $grayscale-7;
  position: relative;
  padding-bottom: 50px;
  padding: 20px;

  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .header {
    border-bottom: $border-1;
    margin-top: 30px;

    padding-bottom: 20px;
    .back-btn {
      font-size: 24px;
      border-radius: 100%;
    }
    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .columns {
    height: 100%;
  }

  .ingest-form {
    max-width: 400px;
  }
}
</style>
