var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ingest-tooltip"},[_c('transition',{attrs:{"name":"vertical-slide"}},[_c('div',{staticClass:"wrapper flex-column",style:({
        'position': 'fixed',
        'top': `${_vm.top}px`,
        'left': `${_vm.left}px`,
        'z-index': '10',
        'transform': 'translate(-50%, -100%)',
      })},[_c('div',{staticClass:"content"},[_vm._t("default")],2),_c('svg',{staticClass:"triangle",attrs:{"viewBox":"0 0 100 100","preserveAspectRatio":"none"}},[_c('polygon',{attrs:{"points":"0,0 50,100 100,0"}})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }