<template>
  <accordian-section
    ref="dimensionSelect"
    v-model="accordianModel"
    class="control"
    :start-open="startOpen">
    <template #button="{ isExpanded }">
      <div
        class="accordian-section-toggle flex-row"
        :class="{ isExpanded }">
        <span
          v-if="control.spec.icon"
          class="material-icons"
          :class="{ controlIsSearch }">
          {{ control.spec.icon }}
        </span>
        <div class="name">
          <div
            v-if="controlIsSearch && isExpanded"
            class="search-container">
            <input
              ref="controlSearch"
              v-model="controlSearchTerm"
              placeholder="Search"
              class="control-search"
              @click.stop />
          </div>
          <template v-else>
            <template v-if="!control.spec.allowMulti">
              <template v-if="selectionModel.length > 0">
                {{ selectionModel[0].value }}
              </template>
              <template v-else>
                {{ control.name }}
              </template>
            </template>
            <template v-else>
              {{ control.name }}
              ({{ selectionModel.length }} / {{ enabledOptions.length }})
            </template>
          </template>
        </div>
        <div
          v-if="isExpanded"
          @click.stop="onDimensionSearch">
          <span
            class="search material-icons"
            :class="{ controlIsSearch }">
            search
          </span>
        </div>
      </div>
    </template>
    <template #body>
      <div class="dimension-select flex-column">
        <div class="shadow-mask" />
        <div class="scroll-container">
          <div
            v-if="allowMulti"
            class="quick-selects">
            <b-button @click="selectAll">
              Select All
            </b-button>
            <b-button @click="deselectAll">
              Deselect All
            </b-button>
          </div>
          <busy-overlay
            :enabled="loading"
            loading />
          <single-select-optimized
            v-if="options && !allowMulti"
            :value="[...selectionModel].shift()"
            :options="searchedOptions"
            :item-size="36"
            key-field="key"
            show-check
            :rounded="false"
            round-items
            class="dropdown-items"
            @input="selectionModel = [$event]">
            <template #label="{ item }">
              <span :class="{ excluded: isExcluded(item) }">
                {{ item.value }}
              </span>
              <template v-if="hasExclusion">
                <div>
                  <input
                    :id="`${$id(item.key)}-exclude`"
                    v-model="excluded"
                    type="checkbox"
                    :value="item"
                    :disabled="item.disabled" />
                  <label
                    class="nowrap exclude-label"
                    :class="{ excluded: isExcluded(item) }"
                    :for="`${$id(item.key)}-exclude`">
                    <span
                      v-if="isExcluded(item)"
                      class="material-icons">visibility_off</span>
                    <span
                      v-else
                      class="material-icons">visibility</span>
                  </label>
                </div>
              </template>
            </template>
          </single-select-optimized>
          <multi-select-optimized
            v-if="options && allowMulti"
            v-model="selectionModel"
            key-field="key"
            show-check
            :rounded="false"
            round-items
            :options="searchedOptions"
            :item-size="36"
            class="dropdown-items">
            <template #label="{ item }">
              <span :class="{ excluded: isExcluded(item) }">
                {{ item.value }}
              </span>
              <template v-if="hasExclusion">
                <div>
                  <input
                    :id="`${$id(item.key)}-exclude`"
                    v-model="excluded"
                    type="checkbox"
                    :value="item"
                    :disabled="item.disabled" />
                  <label
                    class="nowrap exclude-label"
                    :class="{ excluded: isExcluded(item) }"
                    :for="`${$id(item.key)}-exclude`">
                    <span
                      v-if="isExcluded(item)"
                      class="material-icons">visibility_off</span>
                    <span
                      v-else
                      class="material-icons">visibility</span>
                  </label>
                </div>
              </template>
            </template>
          </multi-select-optimized>
        </div>
      </div>
    </template>
  </accordian-section>
</template>

<script>
  import { debounce , uniqBy } from 'lodash'
  import axios from 'axios'
  import Vue from 'vue'
  import BusyOverlay from '@/components/common/BusyOverlay'
  import { getReport } from '@/service/data/reportService'
  import AccordianSection from '@/components/common/layout/accordian/AccordianSection'
  import { expressionProcessorMixin } from '@/components/reporting/expressionProcessorMixin'
  import SingleSelectOptimized from '@/components/controls/SingleSelectOptimized'
  import MultiSelectOptimized from '@/components/controls/MultiSelectOptimized'

  export default {
    name: 'DimensionSelect',
    components: {
      AccordianSection,
      BusyOverlay,
      SingleSelectOptimized,
      MultiSelectOptimized,
    },
    mixins: [expressionProcessorMixin,],
    props: {
      control: { type: Object, required: true, },
      allControls: { type: Array, required: true, },
      accordian: { type: String, default: null, },
      startOpen: { type: Boolean, required: true, },
    },
    data () {
      return {
        unwatch: () => {},
        columns: [],
        options: [],
        internalSelection: null,
        controlIsSearch: false,
        controlSearchTerm: '',
        requestCanceler: null,
      }
    },
    computed: {
      refreshOptions () {
        return debounce(this.trueRefreshOptions, 300)
      },
      selection: {
        get () {
          let keys = (this.allSelections[this.control.id] || []).map(
            (s) => s.key
          )
          return this.enabledOptions.filter((o) => keys.includes(o.key))
        },
        set (newSelection) {
          let newSelections = { ...this.allSelections, }
          newSelections[this.control.id] = newSelection
          this.allSelections = newSelections
        },
      },
      allSelections: {
        get () {
          return this.$store.getters['controls/controlSelections']
        },
        set (selections) {
          this.$store.dispatch('controls/setSelections', selections)
        },
      },
      accordianModel: {
        get () {
          return this.accordian
        },
        set (accordianModel) {
          if (this.accordian === accordianModel) {
            return
          }
          this.controlIsSearch = false
          this.controlSearchTerm = ''
          this.$emit('update:accordian', accordianModel)
        },
      },
      loading: {
        get () {
          return !!this.$store.getters['controls/loadingControls'][
            this.control.id
          ]
        },
        set (loading) {
          this.$store.dispatch(
            loading ? 'controls/startLoading' : 'controls/endLoading',
            this.control.id
          )
        },
      },
      allowMulti () {
        return !!this.control.spec.allowMulti
      },
      dateSelection () {
        return this.$store.getters['datePicker/appliedEffectiveDates']
      },
      offsetSelections () {
        return this.$store.getters['controls/offsetDates']
      },
      dateFilters () {
        let dates = this.dateSelection.map((d) => d.date_key)
        if (!dates) {
          return null
        }
        for (let control_dates of Object.values(this.offsetSelections)) {
          if (!control_dates) {
            return null
          }
          dates = [...dates, ...control_dates,]
        }
        return [
          {
            type: 'in',
            left: {
              type: 'field',
              field: {
                key: 'date',
              },
            },
            right: {
              type: 'const',
              data_type: { key: 'list', item_type: { key: 'date', }, },
              value: dates,
            },
          },
        ]
      },
      hasExclusion () {
        return (
          this.control.spec.controls.filter(
            (control) => control.type == 'dimension-exclude'
          ).length > 0
        )
      },
      exclusionControl () {
        if (this.hasExclusion) {
          return this.control.spec.controls.filter(
            (control) => control.type == 'dimension-exclude'
          )[0]
        }
        return null
      },
      excluded: {
        get () {
          if (this.allSelections[this.exclusionControl.id]) {
            return uniqBy(
              this.filteredOptions.filter((o) =>
                this.allSelections[this.exclusionControl.id]
                  .map((s) => s.key)
                  .includes(o.key)
              ),
              (s) => s.key
            )
          }
          return []
        },
        set (values) {
          let newSelects = {
            ...this.allSelections,
          }
          newSelects[this.exclusionControl.id] = values
          this.allSelections = newSelects
        },
      },
      indexedColumns () {
        return []
          .concat(
            this.control.spec.columns,
            this.control.spec.controls
              .filter((c) => c.type === 'dimension-select-filter')
              .map((control) => ({
                ...this.allControls
                  .filter((c) => control.id == c.id)
                  .slice(-1)
                  .shift()
                  .spec.columns.filter((c) => c.role == 'option_key')
                  .slice(-1)
                  .shift(),
                role: `control-${control.id}`,
                expression:
                  control.key_expression ||
                  this.allControls
                    .filter((c) => control.id == c.id)
                    .slice(-1)
                    .shift()
                    .control.spec.columns.filter((c) => c.role == 'option_key')
                    .slice(-1)
                    .shift().expression,
              }))
          )
          .map((s) => ({
            ...s,
            expression: this.preprocessExpression({
              type: 'filter',
              expression: s.expression,
              by: this.dateFilters,
            }),
          }))
          .map((s, idx) => ({ ...s, id: idx, }))
      },
      reportSpec () {
        return {
          dashboard_id: this.$store.getters['dashboards/selectedDashboardId'],
          control_id: this.control.id,
          sort_by: this.control.spec.sort_by,
          series: this.indexedColumns,
        }
      },
      dimensionKeyColumns () {
        return this.columns.filter((c) => c.role === 'option_key')
      },
      dimensionColumns () {
        return this.columns.filter((c) => c.role === 'option_label')
      },
      internalKeys () {
        return (this.internalSelection || []).map((s) => s.key)
      },
      selectionModel: {
        get () {
          return this.selection || []
        },
        set (values) {
          if (this.allowMulti) {
            let availableKeys = this.enabledOptions.map((o) => o.key)
            this.internalSelection = [
              ...values,
              ...(this.internalSelection || []).filter(
                (v) => !availableKeys.includes(v.key)
              ),
            ]
            this.selection = values
          } else {
            let newVals = values.filter(
              (v) => !this.internalKeys.includes(v.key)
            )
            if (newVals.length > 0) {
              this.internalSelection = [newVals[0],]
              this.selection = [newVals[0],]
            }
          }
        },
      },
      filteredOptions () {
        var options = this.options
        this.control.spec.controls
          .filter((control) => control.type === 'dimension-select-filter')
          .map((c) =>
            this.allControls
              .filter((a) => a.id == c.id)
              .slice(-1)
              .shift()
          )
          .forEach((control) => {
            if (this.$store.getters['controls/loadingControls'][control.id]) {
              options = []
            } else {
              var selection = new Set(
                (this.allSelections[control.id] || []).map((s) => s.key)
              )
              var column = this.columns.filter(
                (col) => col.role == `control-${control.id}`
              )
              options = options.filter((o) =>
                selection.has(this.getDimensionVal(column, o))
              )
            }
          })

        return uniqBy(options, (o) => o.key)
      },
      enabledOptions () {
        return this.filteredOptions.filter((o) => !o.disabled)
      },
      searchedOptions () {
        return uniqBy(
          this.filteredOptions.filter((option) =>
            `${option.value}`
              .toLowerCase()
              .includes(this.controlSearchTerm.toLowerCase())
          ),
          (o) => o.key
        )
      },
      enabledSearchedOptions () {
        return this.searchedOptions.filter((o) => !o.disabled)
      },
    },
    watch: {
      control () {
        this.registerSpec()
      },
      allSelections (selections, oldSelections) {
        let needsUpdate = false
        Object.keys(selections).forEach((key) => {
          if (
            (this.control.spec.controls || []).filter((c) => c.id == key).length >
            0
          ) {
            if (oldSelections[key] != selections[key]) {
              needsUpdate = true
            }
          }
        })
        if (needsUpdate) {
          this.$nextTick(() => {
            this.selectAfterChange()
          })
        }
      },
      otherControls () {
        return this.$store.controlSelections
      },
      dateSelection (filters) {
        if (filters) {
          this.loading = true
          this.refreshOptions()
        }
      },
      offsetSelections (filters, oldFilters) {
        if (filters && JSON.stringify(filters) != JSON.stringify(oldFilters)) {
          this.loading = true
          this.refreshOptions()
        }
      },
      spec (spec) {
        if (spec && !!this.dateFilters) {
          this.loading = true
          this.refreshOptions()
        }
      },
    },
    mounted () {
      this.registerSpec()
      this.unwatch = this.$store.watch(
        (_, getters) => getters['expressions/initialized'],
        () => {
          this.loading = true
          this.refreshOptions(true)
        }
      )
      this.selection = []
      this.loading = true
      this.refreshOptions(true)
    },
    destroyed () {
      this.unregisterSpec()
      this.unwatch()
    },
    methods: {
      registerSpec () {
        let newSpecs = { ...this.$store.getters['controls/controlSpecs'], }
        newSpecs[this.control.id] = this.control
        this.$store.commit('controls/setSpecs', newSpecs)
      },
      unregisterSpec () {
        let newSpecs = { ...this.$store.getters['controls/controlSpecs'], }
        delete newSpecs[this.control.id]
        this.$store.commit('controls/setSpecs', newSpecs)
      },
      onDimensionSearch () {
        this.controlIsSearch = !this.controlIsSearch
        Vue.nextTick(() => {
          if (this.$refs.controlSearch) {
            this.$refs.controlSearch.focus()
          }
        })
      },
      selectAll () {
        this.selectionModel = this.enabledSearchedOptions
      },
      deselectAll () {
        this.selectionModel = []
      },
      isExcluded (item) {
        return (
          this.hasExclusion &&
          this.excluded.filter((i) => i.key === item.key).length > 0
        )
      },
      processReport (rep) {
        this.columns = Object.freeze(
          rep.series.map((s) => ({
            ...this.indexedColumns
              .filter((c) => c.id === s.id)
              .slice(0, 1)
              .shift(),
            ...s,
          }))
        )

        this.options = Object.freeze(
          rep.data.map((o) => ({
            ...o,
            key: this.getDimensionVal(this.dimensionKeyColumns, o),
            value: this.getDimensionVal(this.dimensionColumns, o),
            disabled: this.columns.filter((c) => c.role === 'disabler').shift()
              ? !(
                o[
                  this.columns.filter((c) => c.role === 'disabler').shift().key
                ] === false
              )
              : false,
            brand_logo: this.columns
              .filter((c) => c.role == 'brand_logo')
              .map((c) => o[c.key])
              .slice(-1)
              .shift(),
          }))
        )

        this.$nextTick(() => {
          this.loading = false
        })

        this.selectAfterChange()
      },
      async trueRefreshOptions () {
        this.loading = true
        if (!this.dateFilters) {
          return
        }
        if (!this.$store.getters['expressions/initialized']) {
          return
        }
        let spec = this.reportSpec
        let companyId = this.$route.params.company_id
        try {
          if (this.requestCanceler) {
            this.requestCanceler.cancel('Canceled stale report')
          }
          this.requestCanceler = axios.CancelToken.source()

          //load new value
          const newData = await getReport(
            spec,
            companyId,
            this.requestCanceler.token
          )
          this.processReport(newData)
        } catch (e) {
          if (!!e.message && e.message != 'Canceled stale report') {
            throw e
          }
        }
      },
      getDimensionVal (dimensions, datum) {
        return dimensions.map((d) => datum[d.key]).join('–')
      },
      selectAfterChange () {
        if (this.allowMulti) {
          if (this.internalSelection == null) {
            this.selection = this.enabledOptions
          } else {
            this.selection = this.enabledOptions.filter((o) =>
              this.internalKeys.includes(o.key)
            )
          }
        } else {
          let candidates = [
            ...this.enabledOptions.filter((o) =>
              this.internalKeys.includes(o.key)
            ),
            ...this.enabledOptions,
          ].filter((o) => !o.disabled)
          if (candidates.length > 0) {
            let selected = candidates[0]
            this.selection = [selected,]
            this.internalSelection = [selected,]
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
[type="checkbox"] {
  display: none;
}

.dimension-select {
  height: 100%;
}

.scroll-container {
  max-height: 100%;
  position: relative;
  padding: 4 * $gridBase 0;
}

.dropdown-items {
  height: 100%;
  overflow: auto;
}

.filter-option {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: $h1;
  padding: 1 * $gridBase 2 * $gridBase;
}
.shadow-mask {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(
      50% 8 * $gridBase at top,
      rgba(0, 0, 0, 0.04),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
      50% 8 * $gridBase at bottom,
      rgba(0, 0, 0, 0.04),
      rgba(0, 0, 0, 0)
    );
  top: 0;
}
.exclude-label {
  color: $secondary-05;
  display: flex;
  margin: 0;
  height: 100%;
  align-items: center;
  font-size: $h2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s;

  &:hover {
    color: $secondary-06;
  }

  :hover > .filter-option &,
  &.excluded {
    opacity: 1;
  }
}
.excluded {
  color: $secondary-05;
}
.quick-selects {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .b-button {
    font-size: $h2;
    color: $secondary-04;
    padding: 2 * $gridBase;
    padding-bottom: 0;

    &:hover {
      color: $secondary-06;
    }
  }
}

.control-search {
  border: none;
  background: none;

  &:focus {
    outline: none;
  }
}

.material-icons {
  margin-right: 4 * $gridBase;
}

.accordian-section-toggle {
  align-items: center;
  height: 10 * $gridBase;
  color: $secondary-05;
  font-size: $h1;

  & .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $secondary-05;
  }

  & i {
    color: $secondary-04;
  }

  &:hover,
  &.isExpanded {
    & .name {
      color: $secondary-07;
    }
  }

  &:hover {
    & i:not(.search) {
      color: $secondary-05;
    }
  }

  &.isExpanded {
    & i:not(.search) {
      color: $primary-01;

      &.controlIsSearch {
        color: $secondary-04;
      }
    }
  }

  & .search:hover {
    color: $secondary-07;
  }

  & .search.controlIsSearch {
    &,
    &:hover {
      color: $primary-01;
    }
  }

  &.isExpanded,
  &:hover {
    color: $secondary-07;
  }
}

.search {
  width: 4 * $remGrid;
  height: 4 * $remGrid;
  margin: 0;
}

.name {
  flex-grow: 1;
  font-weight: $emp;
}
</style>
