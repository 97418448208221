<template>
  <g
    class="line-series">
    <defs>
      <template v-for="(datum, i) in categoryData">
        <filter
          :id="$id('shadow-' + i)"
          :key="`filter-${i}`"
          width="12"
          height="12"
          :x="-6"
          :y="-6">
          <feDropShadow
            dy="0"
            dx="0"
            std-deviation="2"
            :flood-color="measureDatumColor(measure, datum)" />
        </filter>
      </template>
    </defs>
    <path
      :d="lineModel(categoryData)"
      :flood-color="measureDatumColor(measure, null)"
      :style="{'stroke': measureDatumColor(measure, null), 'stroke-width': 4, 'fill': 'none', 'opacity':
        highlightEnabled ? (highlightedCategories.includes(category) || highlightedMeasures.includes(measure) ? '.5' : '.2') : '.5'}" />
    <template
      v-if="includeDots">
      <template
        v-for="(datum, i) in categoryData">
        <circle
          v-if="!isNaN(getYPosition(datum[measure.key]))"
          :key="`dot-${i}`"
          :stroke="measureDatumColor(measure, datum)"
          :fill="measureDatumColor(measure, datum)"
          :style="{
            'opacity': highlightEnabled ? (!highlightedCategories.includes(category) && !highlightedMeasures.includes(measure) ? '.2' : (!highlightedData.includes(datum) ? '1' : '1')) : '1',
            'filter': highlightEnabled && (!highlightedCategories.includes(category) && !highlightedMeasures.includes(measure)) ? 'none' : `url(#${$id('shadow-' + i)})`}"
          :cx="xScale(getDimensionVal(primaryDimensionKeys, datum)) + xScale.bandwidth() / 2"
          :cy="getYPosition(datum[measure.key])"
          :r="4"
          :class="{'clickable': hasClickableDimensions}"
          @click="$emit('click', datum)"
          @mousemove="$emit('hover', { mouseEvent: $event, datum, measure })"
          @mouseleave="$emit('unHover')" />
      </template>
    </template>
  </g>
</template>

<script>

  import * as d3  from 'd3'
  import chartComponentMixin from '@/components/charting/chart_component_mixin'

  export default {
    name: 'LineSeries',
    mixins: [
      chartComponentMixin,
    ],
    props: {
      includeDots: { type: Boolean, default: true, },
      measure: { type: Object, required: true, },
      category: { type: Object, default: null, },
    },
    computed: {
      dataMap () {
        let map = {}
        this.data.forEach(d => {
          if (!map[this.getDimensionVal(this.primaryDimensionKeys, d)]) {
            map[this.getDimensionVal(this.primaryDimensionKeys, d)] = {}
          }
          map[this.getDimensionVal(this.primaryDimensionKeys, d)][this.getDimensionVal(this.secondaryDimensionKeys, d)] = d
        })
        return map
      },
      categoryData () {
        if (this.category === null) {
          return this.data.map(d => ({
            ...d,
            primaryDimensionVal: this.getDimensionVal(this.primaryDimensionKeys, d),
            measureVal: d[this.measure.key],
          }))
        }
        return this.xDomain.map(x => {
          let d = this.dataMap[x][this.category.key] || {}
          return {
            ...d,
            primaryDimensionVal: x,
            measureVal: d[this.measure.key],
          }
        })
      },
      lineModel () {
        return d3.line()
          .defined(d => !isNaN(this.getYPosition(d.measureVal)))
          .x(d => this.xScale(d.primaryDimensionVal) + this.xScale.bandwidth() / 2)
          .y(d =>
            this.getYPosition(d.measureVal))
      },
    },
  }

</script>

<style lang="scss" scoped>

.line-series {
}

.clickable {
  cursor: pointer;
}

</style>

