import axios from 'axios'
import { getAuthToken } from '@/service/auth/authService'

export const chequeService = {
  async searchCheques (query, company_id) {
    return (await axios.post(
      `${process.env.VUE_APP_CHECKS_API_URL}/company/${company_id}/cheques`,
      query, { headers: { Authorization: getAuthToken(), }, }))
      .data
  },
  async loadChequeDetails (company_id, cheque_id) {
    return (await axios.get(
      `${process.env.VUE_APP_CHECKS_API_URL}/company/${company_id}/cheque/${cheque_id}`,
      { headers: { Authorization: getAuthToken(), }, }))
      .data
  },
  async getReport (spec, company_id) {
    return (await axios.post(
      `${process.env.VUE_APP_CHECKS_API_URL}/company/${company_id}/report`,
      spec, { headers: { Authorization: getAuthToken(), }, })
    ).data
  },
  async loadOptions (dimension, otherFilters, company_id) {
    let filters = [...otherFilters,]
    let response = await this.getReport(
      {
        dimensions: [dimension.key, ],
        order: [{ dimension: dimension.key, ascending: true, },],
        filters,
      }, company_id)
    return response.data
      .map(row => row[response.dimensions[0].key])
      .filter(option => option != undefined)
  },
}
