var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ingest-table"},[_c('busy-overlay',{attrs:{"enabled":_vm.busy,"loading":_vm.loading,"message":_vm.busyMessage}}),_c('div',{staticClass:"overflow-container",on:{"scroll":_vm.scrollHandler}},[_c('resize-sensor',{on:{"resize":_vm.resizeHandler}}),_c('div',{staticClass:"table"},[_c('div',{staticClass:"headers"},[(_vm.pivotLabels.length > 0)?_c('div',{staticClass:"pivot-headers",style:({ height: `${_vm.pivotHeadersHeight}px` })},[_c('div',{staticClass:"dimension-headers"},_vm._l((_vm.dimensions),function(dimension){return _c('div',{key:`dimension-header-${dimension.key}`,staticClass:"header",style:({ width: `${_vm.getDimensionWidth(dimension)}px` })})}),0),_vm._l((_vm.pivotTotals),function(pivotTotal){return _c('div',{key:`total-header-${pivotTotal.key}`,staticClass:"header",style:({ width: `${_vm.getPivotTotalWidth(pivotTotal)}px` })})}),_vm._l((_vm.pivotValues),function(pivotValue){return _c('div',{key:pivotValue.key,staticClass:"header pivot-header",style:({ width: `${_vm.getPivotValueWidth(pivotValue)}px` })},[_c('div',{staticClass:"header-div pivot-header"},[_vm._v(" "+_vm._s(pivotValue.label)+" ")])])})],2):_vm._e(),_c('div',{staticClass:"sub-headers",style:({
            width: `${_vm.dataWidth}px`,
          })},[_c('div',{staticClass:"dimension-headers",style:({
              height: `${_vm.pivotHeadersHeight}px`,
            })},_vm._l((_vm.dimensions),function(dimension){return _c('div',{key:`dimension-${dimension.key}`,staticClass:"header clickable nowrap ellipsis",style:({ width: `${_vm.getDimensionWidth(dimension)}px` }),on:{"click":function($event){_vm.$emit('headerClick', dimension);
                _vm.pivotSort = null;}}},[_c('div',{staticClass:"header-div clickable"},[(dimension.sortDir == 'desc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_more ")]):_vm._e(),(dimension.sortDir == 'asc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_less ")]):_vm._e(),_vm._v(" "+_vm._s(dimension.name)+" ")])])}),0),_c('div',{staticClass:"measure-headers",style:({ height: `${_vm.pivotHeadersHeight}px` })},[_vm._l((_vm.visibleColumns),function(column){return [(column.type == 'pivot-total')?_c('div',{key:`pivotTotal-${column.pivotTotal.key}`,staticClass:"header clickable nowrap ellipsis",style:({
                  width: `${column.width}px`,
                  transform: `translateX(${_vm.offsetX}px)`,
                }),on:{"click":function($event){_vm.$emit('headerClick', column.pivotTotal);
                  _vm.pivotSort = null;}}},[_c('div',{staticClass:"header-div total-header"},[(column.pivotTotal.sortDir== 'desc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_more ")]):_vm._e(),(column.pivotTotal.sortDir == 'asc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_less ")]):_vm._e(),_vm._v(" "+_vm._s(column.pivotTotal.name)+" ")])]):_vm._e(),(column.type == 'pivot-measure')?_c('div',{key:`pivot-${column.pivot.key}-measure-${column.measure.key}`,staticClass:"header clickable nowrap ellipsis",style:({
                  width: `${column.width}px`,
                  transform: `translateX(${_vm.offsetX}px)`,
                }),on:{"click":function($event){_vm.$emit('headerClick', column.measure);
                  _vm.pivotSort = column.pivot;}}},[_c('div',{staticClass:"header-div clickable"},[(
                      column.measure.sortDir == 'desc' &&
                        !!_vm.pivotSort &&
                        _vm.pivotSort.key == column.pivot.key
                    )?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_more ")]):_vm._e(),(
                      column.measure.sortDir == 'asc' &&
                        !!_vm.pivotSort &&
                        _vm.pivotSort.key == column.pivot.key
                    )?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_less ")]):_vm._e(),_vm._v(" "+_vm._s(column.measure.name)+" ")])]):_vm._e()]})],2)])]),_c('div',{staticClass:"data-container",style:({
          height: `${_vm.dataHeight}px`,
          width: `${_vm.dataWidth}px`,
        }),attrs:{"items":_vm.pivotedRows.map((row, idx) => ({ row, idx })),"item-size":52,"key-field":'idx'}},_vm._l((_vm.visibleRows),function(item){return _c('div',{key:item.idx,staticClass:"data-row",style:({
            height: `${_vm.rowHeight}px`,
            transform: `translateY(${_vm.offsetY}px)`,
          })},[_c('div',{staticClass:"row-dimensions"},[_vm._l((_vm.dimensions),function(dimension,dimensionIdx){return [_c('data-field-v2',{key:`dimension-${dimension.key}`,style:({ width: `${_vm.getDimensionWidth(dimension)}px` }),attrs:{"field":dimension,"value":item.row[dimension.key],"all-fields":_vm.fields,"row":item.row},nativeOn:{"click":function($event){return _vm.$emit('dataClick', {
                    row: item.row,
                    field: dimension,
                    rowIdx: item.idx,
                    dimensionIdx,
                  })}}})]})],2),_vm._l((_vm.visibleColumns),function(column){return [(column.type == 'pivot-total')?_c('data-field-v2',{key:`pivotTotal-${column.pivotTotal.key}`,style:({
                width: `${column.width}px`,
                transform: `translateX(${_vm.offsetX}px)`,
              }),attrs:{"field":column.pivotTotal,"value":item.row[column.pivotTotal.key],"all-fields":_vm.fields,"row":item.row,"is-total":""},nativeOn:{"click":function($event){return _vm.$emit('dataClick', {
                  row: item.row,
                  field: column.pivotTotal,
                  rowIdx: item.idx,
                  pivotTotalIdx: column.idx,
                })}}}):_vm._e(),(column.type == 'pivot-measure')?_c('data-field-v2',{key:`pivot-${column.pivot.key}-measure-${column.measure.key}`,style:({
                width: `${column.width}px`,
                transform: `translateX(${_vm.offsetX}px)`,
              }),attrs:{"field":column.measure,"value":((item.row.pivots[column.pivot.key] || {})[0] || {})[
                  column.measure.key
                ],"all-fields":_vm.fields,"row":(item.row.pivots[column.pivot.key] || {})[0] || {}},nativeOn:{"click":function($event){return _vm.$emit('dataClick', {
                  row: item.row,
                  field: column.measure,
                  rowIdx: item.idx,
                  measureIdx: _vm.idx,
                })}}}):_vm._e()]})],2)}),0)])],1),_c('div',{staticClass:"width-samplers"},[_c('div',{ref:"fakeTotalHeader",staticClass:"fake-total-header"}),_c('div',{ref:"fakeHeader",staticClass:"fake-header"}),_c('div',{ref:"fakeTotalField",staticClass:"fake-total-field"}),_c('div',{ref:"fakeField",staticClass:"fake-field"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }