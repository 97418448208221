<template>
  <div class="reset-form">
    <div class="header">
      <div class="header-wrapper">
        <slot name="header" />
      </div>
      <div
        v-if="closeable"
        class="close-button"
        @click="$emit('close')">
        Return to login
      </div>
    </div>
    <div class="body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>

  export default {
    name: 'ResetForm',
    props: {
      closeable: { type: Boolean, default: false, },
    },
  }

</script>

<style lang="scss" scoped>

.reset-form {
  background-color: white;
  border-radius: 4 * $gridBase;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(79, 78, 84, 0.05), 0px 4px 12px rgba(79, 78, 84, 0.02);
}

.header {
  padding:  8 * $gridBase;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  font-weight: bold;
}

.header-wrapper {
  flex-grow: 1;
}

.body {
  background: $ui-09;
  padding: 8 * $gridBase;
  color: $ui-02;
  font: $h2-dw-sl;
}

.close-button {
  cursor: pointer;
  font-size: $h2;
  font-weight: 500;
  opacity: .75;

  &:hover {
    opacity: 1;
  }
}

</style>

