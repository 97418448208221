<template>
  <div class="expression-dimension-editor">
    <div class="ref-select">
      <ExpressionEditor
        v-model="expression"
        v-bind="subObjectBinds" />
    </div>
    <b-field>
      <b-switch
        v-model="hasKeyExpression"
        type="is-info">
        Key Expression (Optional):
      </b-switch>
      <ExpressionEditor
        v-if="hasKeyExpression"
        v-model="keyExpression"
        v-bind="subObjectBinds" />
    </b-field>
    <b-field>
      <b-switch
        v-model="hasLabelExpression"
        type="is-info">
        Label Expression (Optional):
      </b-switch>
      <ExpressionEditor
        v-if="hasLabelExpression"
        v-model="labelExpression"
        v-bind="subObjectBinds" />
    </b-field>
    <b-field>
      <b-switch
        v-model="hasOrderExpression"
        type="is-info">
        Order Expression (Optional):
      </b-switch>
      <ExpressionEditor
        v-if="hasOrderExpression"
        v-model="orderExpression"
        v-bind="subObjectBinds" />
    </b-field>
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'ExpressionDimensionEditor',
    mixins: [
      ObjectEditorMixin,
    ],
    computed: {
      ...mapChildren({
        expression: 'expression',
        keyExpression: 'key_expression',
        labelExpression: 'label_expression',
        orderExpression: 'order_expression',
      }),
      hasKeyExpression: {
        get () {
          return ![ null, undefined, ].includes(this.keyExpression)
        },
        set (hasKeyExpression) {
          if (hasKeyExpression) {
            this.keyExpression = {}
          } else {
            this.keyExpression = undefined
          }
        },
      },
      hasLabelExpression: {
        get () {
          return ![ null, undefined, ].includes(this.labelExpression)
        },
        set (hasLabelExpression) {
          if (hasLabelExpression) {
            this.labelExpression = {}
          } else {
            this.labelExpression = undefined
          }
        },
      },
      hasOrderExpression: {
        get () {
          return ![ null, undefined, ].includes(this.orderExpression)
        },
        set (hasOrderExpression) {
          if (hasOrderExpression) {
            this.orderExpression = {}
          } else {
            this.orderExpression = undefined
          }
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.expression-dimension-editor {
  display: flex;
  flex-direction: column;
}
.ref-select {
  display: flex;
  flex-direction: row;
}
</style>
