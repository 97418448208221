<template>
  <div
    class="module"
    :class="{ dragging }"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false">
    <interactable
      class="interactable"
      :enable-move="isEnabled && isHover || dragging"
      :enable-resize="isEnabled && isHover || dragging"
      @start="onStart"
      @move="onMove"
      @resize="onResize"
      @drop="onDrop">
      <slot />
    </interactable>
  </div>
</template>

<script>

  import Interactable from '../interactable/Interactable'

  export default {
    name: 'ModuleComponent',
    components: {
      Interactable,
    },
    props: {
      isEnabled: Boolean,
    },
    data () {
      return {
        isHover: false,
        dragging: false,
      }
    },
    methods: {
      onStart () {
        this.dragging = true
        this.$emit('start')
      },
      onMove (event) {
        this.$emit('move', event)
      },
      onResize (event) {
        this.$emit('resize', event)
      },
      onDrop () {
        this.dragging = false
        this.$emit('drop')
      },
    },
  }

</script>

<style lang="scss" scoped>

.content {
  width: 100%;
  height: 100%;
}

.module {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 4px;
}

.interactable {
  padding: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.module {
  z-index: 1;
}

.module.dragging {
  z-index: 8;
}

.interactable {
  width: 100%;
  height: 100%;
  touch-action: none;
  user-select: none;
}

</style>
