<template>
  <div class="cheque-search">
    <cheque-search-filters
      class="cheque-filters secondary-01-background"
      :query.sync="query"
      :dirty.sync="dirty"
      @reset="resetQuery"
      @search="refreshSearchResults(query)" />
    <div class="cheque-table">
      <cheque-table
        :dirty="dirty"
        :cheques="cheques"
        :query.sync="query"
        :loading="loading"
        @search="refreshSearchResults(query)" />
    </div>
    <div class="selected-cheque secondary-06-background">
      <customer-cheque
        v-if="selectedCheque != null"
        :cheque="selectedCheque" />
    </div>
  </div>
</template>

<script>
  import { chequeService } from '@/service/cheque_search/chequeService'
  import ChequeSearchFilters from '@/components/cheque_search/ChequeSearchFilters'
  import ChequeTable from '@/components/cheque_search/ChequeTable'
  import CustomerCheque from '@/components/cheque_search/CustomerCheque'

  export default {
    name: 'ChequeSearch',
    components: {
      ChequeTable,
      ChequeSearchFilters,
      CustomerCheque,
    },
    data () {
      return {
        searchResults: null,
        dirty: false,
        loading: false,
        query: this.getBaseQuery(),
        filterOptions: [],
        selectedCheque: null,
      }
    },
    computed: {
      selectedChequeId () {
        return this.$route.params.cheque_id
      },
      cheques () {
        if (this.searchResults == null) {
          return []
        }
        return this.searchResults.cheques
      },
    },
    watch: {
      dirty (value) {
        if (value) {
          this.loading = false
        }
      },
      selectedChequeId (value) {
        if (value) {
          this.updateSelected(value)
        }
      },
    },
    methods: {
      async updateSelected (cheque) {
        this.selectedCheque = await chequeService.loadChequeDetails(this.$route.params.company_id, cheque)
      },
      resetQuery () {
        this.query = this.getBaseQuery()
        this.dirty = true
      },
      getBaseQuery () {
        let start = new Date()
        start.setDate(start.getDate() - 1)
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        let end = new Date()
        end.setHours(0)
        end.setMinutes(0)
        end.setSeconds(0)
        return {
          order: [{ dimension: 'open_time', ascending: true, },],
          restaurantFilter: null,
          revenueCenterFilter: null,
          paymentAmountFilter: null,
          paymentTypeFilter: null,
          last4Filter: null,
          paymentCommentFilter: null,
          chequeVoidFilter: null,
          chequeNumberFilter: null,
          dateFilter: {
            dimension: 'date',
            type: 'between',
            args: {
              start: start.toISOString().split('T')[0],
              end: end.toISOString().split('T')[0],
            },
          },
          mealPeriodFilter: null,
          paging: {
            limit: 500,
          },
        }
      },
      async refreshSearchResults (query) {
        this.loading = true
        this.dirty = false
        let results = await chequeService.searchCheques(
          query,
          this.$route.params.company_id
        )
        if (query == this.query) {
          this.loading = false
          this.searchResults = results
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.cheque-search {
  flex-grow: 1;
  display: flex;
  max-height: 100%;
  overflow-y: auto;
  height: 100%;
}

.cheque-filters {
  display: flex;
  flex-direction: column;
  width: 20vw;
  border-top-left-radius: 8px;
  overflow-y: auto;
  overflow-x: visible;
  box-shadow: 3px 0 3px -3px rgba($darkGreen, .5);
  border-right: $border-1;
}

.selected-cheque {
  width: 35vw;
  min-width: 35vw;
  padding: 24px;
  max-height: 100%;
  overflow-y: auto;
}
.cheque-table {
  flex-grow: 1;
  z-index: 1;
}

</style>
