<template>
  <div class="container-fluid">
    <div class="container">
      <div class="header columns mx-0 px-0 is-vcentered">
        <router-link
          :to="{ name: 'attributes', query: {
            entity: entityType ,
          }, }">
          <b-button
            type="is-light"
            class="back-btn mr-3"
            icon-right="arrow-left" />
        </router-link>
        <h2>Manage {{ entityTypeDetails?.name }} Attributes</h2>

        <div class="control-bar">
          <router-link
            :to="{
              name: 'edit_attribute',
              params: { attribute_id: 'new' },
            }">
            <button class="button is-primary">
              Create Attribute
            </button>
          </router-link>
        </div>
      </div>
      <div>
        <div class="columns is-vcentered report-table-filters">
          <div class="column pdf-search-input m-0 p-0">
            <b-input
              v-model="reportSearchInput"
              custom-class="no-border"
              placeholder="Search..."
              type="search"
              icon="magnify" />
          </div>
        </div>
      </div>
      <b-table
        :data="resultsToShow"
        :loading="isLoading"
        :hoverable="true"
        class="pdf-reports-table">
        <b-table-column
          v-slot="props"
          sortable
          field="name"
          label="Attribute">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          sortable
          field="data_type.key"
          class="is-capitalized"
          label="Date Type">
          <span class="is-capitalized">
            {{ props.row.data_type.key }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          sortable
          field="default_value"
          label="Default Values">
          <b-field class="default-value attribute-input">
            <b-input
              v-if="props.row.data_type.key === 'boolean'"
              disabled
              :value="
                formatValue(props.row.default_value, props.row.data_type.key)
              "
              :controls="false" />
            <b-numberinput
              v-else-if="props.row.data_type.key === 'percent'"
              disabled
              :value="
                formatValue(props.row.default_value, props.row.data_type.key)
              "
              :min-step="0.01"
              icon-right="percent"
              :controls="false" />
            <b-numberinput
              v-else-if="props.row.data_type.key === 'number'"
              disabled
              :value="
                formatValue(props.row.default_value, props.row.data_type.key)
              "
              :min-step="0.01"
              icon="numeric"
              :controls="false" />

            <b-numberinput
              v-else-if="props.row.data_type.key === 'money'"
              disabled
              :value="
                formatValue(props.row.default_value, props.row.data_type.key)
              "
              :min-step="0.01"
              icon="currency-usd"
              :controls="false" />

            <b-input
              v-else-if="props.row.data_type.key === 'string'"
              disabled
              :value="
                formatValue(props.row.default_value, props.row.data_type.key)
              "
              icon="alphabetical-variant"
              :controls="false" />

            <b-timepicker
              v-else-if="props.row.data_type.key === 'time'"
              hour-format="12"
              disabled
              :value="
                formatValue(props.row.default_value, props.row.data_type.key)
              "
              icon="clock"
              editable
              :enable-seconds="false" />

            <b-datepicker
              v-else-if="props.row.data_type.key === 'date'"
              disabled
              :value="
                formatValue(props.row.default_value, props.row.data_type.key)
              "
              position="is-bottom-left"
              icon="calendar-today"
              icon-right-clickable
              editable
              trap-focus />
          </b-field>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="Actions"
          width="50"
          label="Actions">
          <div class="actions">
            <b-button
              type="is-light"
              class="action-btn"
              icon-right="delete-outline"
              @click="deleteAttribute(props.row)" />
            <router-link
              :to="{
                name: 'edit_attribute',
                params: { attribute_id: props.row.id },
              }">
              <b-button
                type="is-light"
                class="action-btn"
                icon-right="cog" />
            </router-link>
          </div>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered mt-5 mb-5">
            <b-tag
              v-if="!isLoading"
              class="data-tags"
              rounded
              size="is-medium">
              No Attributes Found 🥥
            </b-tag>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'
  import { mapGetters } from 'vuex'
  import { convertToFrontend } from '@/components/inputs/AttributeValueConverterHelpers'

  export default {
    name: 'ManageAttributePage',
    data () {
      return {
        allAttributes: [],
        entityType: '',
        entityTypeDetails: {},
        isLoading: false,
        reportSearchInput: '',
        reportsFilterByActiveTabs: 0,

        dates: {
          type: 'range',
          start: {
            key: new Date(new Date() - 30 * 24 * 3600000)
              .toISOString()
              .split('T')[0],
            value: new Date(new Date() - 30 * 24 * 3600000)
              .toISOString()
              .split('T')[0],
          },
          end: {
            key: new Date().toISOString().split('T')[0],
            value: new Date().toISOString().split('T')[0],
          },
        },
      }
    },

    computed: {
      ...mapGetters({
        entityAttributes: 'entities/entityAttributes',
        dataTypes: 'expressions/dataTypes',
        entityTypes: 'entities/entityTypes',
      }),
      resultsToShow () {
        if (this.reportSearchInput) {
          return this.searcher.search(this.reportSearchInput)
        }
        return this.allAttributes
      },

      searcher () {
        const searcher = new FuzzySearch(this.allAttributes, [
          'name',
          'key',
          'data_type.key',
          'default_value',
        ])
        return searcher
      },
    },

    async mounted () {
      this.entityType = this.$route.params.entity_type
      this.loadAttributesForEntity()
    },

    methods: {
      formatValue (value, type) {
        return convertToFrontend(value, type)
      },

      async loadAttributesForEntity () {
        this.isLoading = true
        await this.$store.dispatch('entities/initialize', this.dates)
        this.entityTypeDetails = this.entityTypes.find(
          (entityType) => entityType.key == this.entityType
        )
        this.allAttributes = this.entityAttributes.filter(
          (ea) => ea.entity_type.key == this.entityType
        )
        this.isLoading = false
      },

      async deleteAttribute (attribute) {
        this.$buefy.dialog.confirm({
          title: 'Delete Attribute',
          message:
            '<div class="ml-4"> Are you sure you want to delete this attribute? <br> This action cannot be undone. </div>',
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            await this.$store.dispatch('entities/deleteAttribute', attribute)
            this.allAttributes = this.entityAttributes.filter(
              (ea) => ea.entity_type.key == this.entityType
            )
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: white;
  width: 100%;
  padding: 20px 14px;
  overflow: auto;

  .default-value {
    max-width: 220px;
  }
  .header {
    margin-top: 30px;
    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .back-btn {
      font-size: 24px;
      border-radius: 100%;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .pdf-reports-table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
  }

  .report-table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .data-tags {
    font-size: 12px;
    margin: 2px;
  }

  .actions {
    display: flex;

    .action-btn {
      margin: 0 5px;
    }
  }
}
</style>

<style lang="scss">
.attribute-input {
  input,
  select {
    border: $border-1 !important;

    &:focus {
      border-color: $primary !important;
    }

    ::after {
      border-color: black !important;
    }

    &[type="number"] {
      text-align: right !important;
    }
  }
  .select.is-primary::after {
    border-color: $grayscale-3 !important;
  }
}
</style>
