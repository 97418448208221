var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ingest-table"},[_c('busy-overlay',{attrs:{"enabled":_vm.busy,"loading":_vm.loading,"message":_vm.busyMessage}}),_c('div',{staticClass:"overflow-container"},[_c('table',[(_vm.pivotLabels.length > 0)?[_c('thead',{attrs:{"height":"104px"}},[_c('tr',{attrs:{"height":"52px"}},[(_vm.dimensions.length > 0)?_c('th',{attrs:{"colspan":_vm.dimensions.length}}):_vm._e(),(_vm.pivotTotals.length > 0)?_c('th',{attrs:{"colspan":_vm.pivotTotals.length}}):_vm._e(),_vm._l((_vm.pivotValues),function(pivotValue){return _c('th',{key:pivotValue.key,staticClass:"pivot-header",attrs:{"colspan":_vm.measures.length}},[_c('div',{staticClass:"header-div pivot-header"},[_vm._v(" "+_vm._s(pivotValue.label)+" ")])])})],2),_c('tr',{staticClass:"second-header",attrs:{"height":"52px"}},[_vm._l((_vm.dimensions),function(dimension){return _c('th',{key:`dimension-${dimension.key}`,staticClass:"clickable nowrap ellipsis",style:({
                width: !!dimension.width ? `${dimension.width}px` : 'initial',
              }),on:{"click":function($event){_vm.$emit('headerClick', dimension);
                _vm.pivotSort = null;}}},[_c('div',{staticClass:"header-div"},[_vm._v(" "+_vm._s(dimension.name)+" "),(dimension.sortDir == 'desc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v(" expand_more ")]):_vm._e(),(dimension.sortDir == 'asc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_less ")]):_vm._e()])])}),_vm._l((_vm.pivotTotals),function(pivotTotal){return _c('th',{key:`pivotTotal-${pivotTotal.key}`,staticClass:"clickable nowrap ellipsis",style:({
                width: !!pivotTotal.width
                  ? `${pivotTotal.width}px`
                  : 'initial',
              }),on:{"click":function($event){_vm.$emit('headerClick', pivotTotal);
                _vm.pivotSort = null;}}},[_c('div',{staticClass:"header-div total-header"},[_vm._v(" "+_vm._s(pivotTotal.name)+" "),(pivotTotal.sortDir == 'desc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v(" expand_more")]):_vm._e(),(pivotTotal.sortDir == 'asc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_less")]):_vm._e()])])}),_vm._l((_vm.pivotValues),function(pivot){return _vm._l((_vm.measures),function(measure){return _c('th',{key:`pivot-${pivot.key}-measure-${measure.key}`,staticClass:"clickable nowrap ellipsis",style:({
                  width: !!measure.width ? `${measure.width}px` : 'initial',
                }),on:{"click":function($event){_vm.$emit('headerClick', measure);
                  _vm.pivotSort = pivot;}}},[_c('div',{staticClass:"header-div"},[_vm._v(" "+_vm._s(measure.name)+" "),(
                      measure.sortDir == 'desc' &&
                        !!_vm.pivotSort &&
                        _vm.pivotSort.key == pivot.key
                    )?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_more")]):_vm._e(),(
                      measure.sortDir == 'asc' &&
                        !!_vm.pivotSort &&
                        _vm.pivotSort.key == pivot.key
                    )?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_less")]):_vm._e()])])})})],2)])]:[_c('thead',{attrs:{"height":"52px"}},_vm._l((_vm.fields),function(field){return _c('th',{key:field.key,staticClass:"clickable nowrap ellipsis",style:({ width: !!field.width ? `${field.width}px` : 'initial' }),on:{"click":function($event){_vm.$emit('headerClick', field);
              _vm.pivotSort = null;}}},[_c('div',{staticClass:"header-div"},[_vm._v(" "+_vm._s(field.name)+" "),(field.sortDir == 'desc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v(" expand_more ")]):_vm._e(),(field.sortDir == 'asc')?_c('i',{staticClass:"sort-icon material-icons"},[_vm._v("expand_less")]):_vm._e()])])}),0)],_c('tbody',{attrs:{"table":""}},[(_vm.pivotLabels.length > 0)?[_vm._l((_vm.pivotedRows),function(row,idx){return [_c('tr',{key:idx,class:{ active: row.active, clickable: row.clickable },attrs:{"height":"52px"},on:{"click":function($event){return _vm.$emit('rowClick', row)}}},[_vm._l((_vm.dimensions),function(dimension,dimensionIdx){return [_vm._t(dimension.slot,function(){return [_c('data-field',{key:`dimension-${dimension.key}`,style:({
                      width: !!dimension.width
                        ? `${dimension.width}px`
                        : 'initial',
                    }),attrs:{"field":dimension,"value":row[dimension.key],"all-fields":_vm.fields,"row":row},nativeOn:{"click":function($event){return _vm.$emit('dataClick', {
                        row,
                        field: dimension,
                        rowIdx: idx,
                        dimensionIdx,
                      })}}})]},null,{ field: dimension, row })]}),_vm._l((_vm.pivotTotals),function(pivotTotal,pivotTotalIdx){return [_vm._t(pivotTotal.slot,function(){return [_c('data-field',{key:`pivotTotal-${pivotTotal.key}`,style:({
                      width: !!pivotTotal.width
                        ? `${pivotTotal.width}px`
                        : 'initial',
                    }),attrs:{"field":pivotTotal,"value":row[pivotTotal.key],"all-fields":_vm.fields,"row":row,"is-total":""},nativeOn:{"click":function($event){return _vm.$emit('dataClick', {
                        row,
                        field: pivotTotal,
                        rowIdx: idx,
                        pivotTotalIdx,
                      })}}})]},{"sTot":""},{ field: pivotTotal, row })]}),_vm._l((_vm.pivotValues),function(pivot){return [_vm._l((_vm.measures),function(measure,measureIdx){return [_vm._t(measure.slot,function(){return [_c('data-field',{key:`pivot-${pivot.key}-measure-${measure.key}`,style:({
                        width: !!measure.width
                          ? `${measure.width}px`
                          : 'initial',
                      }),attrs:{"field":measure,"value":((row.pivots[pivot.key] || {})[0] || {})[measure.key],"all-fields":_vm.fields,"row":(row.pivots[pivot.key] || {})[0] || {}},nativeOn:{"click":function($event){return _vm.$emit('dataClick', {
                          row,
                          field: measure,
                          rowIdx: idx,
                          measureIdx,
                        })}}})]},null,{ field: measure, row })]})]})],2)]})]:[_vm._l((_vm.rows),function(row,idx){return [_c('tr',{key:idx,class:{ active: row.active, clickable: row.clickable },attrs:{"height":"52px"},on:{"click":function($event){return _vm.$emit('rowClick', row)}}},[_vm._l((_vm.fields),function(field,fieldIdx){return [_vm._t(field.slot,function(){return [_c('data-field',{key:field.key,style:({
                      width: !!field.width ? `${field.width}px` : 'initial',
                    }),attrs:{"field":field,"value":row[field.key],"all-fields":_vm.fields,"row":row},nativeOn:{"click":function($event){return _vm.$emit('dataClick', {
                        row,
                        field,
                        rowIdx: idx,
                        fieldIdx,
                      })}}})]},null,{ field, row })]})],2)]})]],2)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }