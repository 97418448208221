<template>
  <div
    class="module-edit"
    :class="{'edit': isEdit, readOnly}">
    <div
      v-if="!readOnly"
      class="drag handle">
      <dimension-filter-menu
        :filters.sync="moduleFilters"
        :external-filters="dashboardFilters"
        :dimension-options="dimensionOptions
          .filter(dimension => dimension.filters.length > 0)"
        add-remove />
      <div class="buttons">
        <button
          v-if="paletteOpen"
          key="done"
          class="done-button ingest-light-button"
          variant="none"
          @click="paletteOpen = false">
          DONE
          <v-icon name="check-square" />
        </button>
        <template v-else>
          <button
            key="edit"
            class="edit-button ingest-dark-button"
            variant="none"
            @click="paletteOpen = true">
            EDIT
            <v-icon
              key="enter-edit"
              name="edit" />
          </button>
          <button
            class="ingest-dark-button"
            variant="none"
            @click="$emit('deleteModule')">
            <v-icon
              key="deleteModule"
              name="x" />
          </button>
        </template>
      </div>
    </div>
    <input
      v-if="isEdit"
      v-model="columnJson"
      placeholder="columns" />
    <input
      v-if="isEdit"
      v-model="sortByJson"
      placeholder="sortBy" />
    <input
      v-if="isEdit"
      v-model="controlsJson"
      placeholder="controls" />
    <input
      v-if="isEdit"
      v-model="limitJson"
      placeholder="limit" />
    <input
      v-if="isEdit"
      v-model="betaSpecJson"
      placeholder="betaSpec" />
    <input
      v-if="isEdit"
      v-model="positionJson" />
    <component
      :is="viewDef.editor.name"
      :measure-aggregates="selectedMeasures"
      :dimensions="selectedDimensions"
      :is-edit="isEdit"
      :dragged-field.sync="draggedField"
      class="editor-component"
      :dimension-options="dimensionOptions"
      :dimension-counts="selectedCounts"
      :module-filters.sync="moduleFilters"
      :dashboard-filters="dashboardFilters"
      @update:measureAggregates="updateMeasureAggregates"
      @update:dimensionCounts="updateDimensionCounts"
      @update:dimensions="updateDimensions">
      <div class="main">
        <div v-if="readOnly" />
        <slot />
      </div>
    </component>
  </div>
</template>
<script>

  import { ingestViews } from '@/components/module/view/viewDefs'
  import MeasureAggregate from '@/components/module/edit/MeasureAggregate'
  import DimensionHierarchy from '@/components/module/edit/DimensionHierarchy'
  import DrilldownOptions from '@/components/module/edit/DrilldownOptions'
  import DimensionFilterMenu from '@/components/controls/filter/DimensionFilterMenu'
  import RollupComparisonOptions from '@/components/comparison/RollupComparisonOptions'
  import OffsetComparisonOptions from '@/components/comparison/OffsetComparisonOptions'
  import { groupBy } from 'lodash'

  export default {
    name: 'ModuleEdit',
    components: {
      // Palette,
      // PaletteSection,
      DimensionHierarchy,
      DrilldownOptions,
      MeasureAggregate,
      DimensionFilterMenu,
      RollupComparisonOptions,
      OffsetComparisonOptions,
    },
    props: {
      readOnly: Boolean,
      isEdit: Boolean,
      viewTypes: { type: Array, required: true, },
      columns: { type: Array, required: true, },
      sortBy: { type: Array, required: true, },
      controls: { type: Array, required: true, },
      limit: { type: Number, default: null, },
      betaSpec: { type: Object, default: null, },
      position: { type: Object, default: null, },
      comparison: { type: Object, default: null, },
      filters: { type: Array, required: true, },
      measures: { type: Array, default: Array, },
      dimensions: { type: Array, default: Array, },
      dashboardFilters: { type: Array, required: true, },
    },
    data () {
      return {
        height: 0,
        isHover: false,
        loading: false,
        selectedType: 0,
        draggedField: undefined,
        drillDownPath: [],
      }
    },
    computed: {
      columnJson: {
        get () {
          return JSON.stringify(this.columns)
        },
        set (value) {
          this.$emit('update:columns', JSON.parse(value))
        },
      },
      sortByJson: {
        get () {
          return JSON.stringify(this.sortBy)
        },
        set (value) {
          this.$emit('update:sortBy', JSON.parse(value))
        },
      },
      controlsJson: {
        get () {
          return JSON.stringify(this.controls)
        },
        set (value) {
          this.$emit('update:controls', JSON.parse(value))
        },
      },
      limitJson: {
        get () {
          return JSON.stringify(this.limit)
        },
        set (value) {
          this.$emit('update:limit', JSON.parse(value))
        },
      },
      betaSpecJson: {
        get () {
          return JSON.stringify(this.betaSpec)
        },
        set (value) {
          this.$emit('update:betaSpec', JSON.parse(value))
        },
      },
      positionJson: {
        get () {
          return JSON.stringify(this.position)
        },
        set (value) {
          this.$emit('update:position', JSON.parse(value))
        },
      },
      dimensionHierarchies () {
        let group_map = groupBy(this.dimensionOptions,
                                dimension => dimension.group)
        return Object.keys(group_map)
          .map(name => ({ name, dimensions: group_map[name], }))
      },
      dimensionOptions () {
        return this.dimensions
      },
      measureOptions () {
        return this.measures
      },
      selectedCounts: {
        get () {
          return this.columns.filter((column) => column.type == 'count')
            .map((column) => column.dimension)
        },
        set (counts) {
          this.updateDimensionCounts(counts)
        },
      },
      selectedMeasures: {
        get () {
          return this.columns.filter((column) => column.type == 'sum' || column.type == 'avg')
        },
        set (measures) {
          this.updateMeasures(measures)
        },
      },
      selectedDimensions: {
        get () {
          return this.columns.filter((column) => column.type == 'group')
            .map((column) => column.dimension)
        },
        set (dimensions) {
          this.updateDimensions(dimensions)
        },
      },
      rollupDimensionOptions () {
        return this.dimensionOptions.filter(dimension => dimension.groupable)
      },
      comparisonType: {
        get () {
          if (this.comparison) {
            return this.comparison['type']
          } else {
            return null
          }
        },
        set (type) {
          if (type == 'rollup') {
            this.$emit('update:comparison', { type, per_dimension: null, over_dimension: null, })
          } else if (type == 'offset') {
            this.$emit('update:comparison', { type, dimension: 'date',  amount: 364, })
          } else {
            this.$emit('update:comparison', null)
          }
        },
      },
      fields () {
        return [].concat(this.selectedDimensions, this.selectedMeasures, this.selectedCounts)
      },
      type () {
        return this.viewTypes[this.selectedType]
      },
      viewDef () {
        return ingestViews[this.type]
      },
      moduleFilters: {
        get () {
          return this.filters
        },
        set (filters) {
          this.$emit('update:filters', filters)
        },
      },
      measureAggregates () {
        return this.measureOptions.map(measure => ({
          type: 'sum',
          measure,
        }))
      },
      limits () {
        let fields = {}
        let dimensions = {}
        let measures = {}
        for (let t in this.viewTypes) {
          let type = ingestViews[this.viewTypes[t]]
          fields = this.mergeLimits(fields, type.limits.fields || {})
          dimensions = this.mergeLimits(dimensions, type.limits.dimensions || {})
          measures = this.mergeLimits(measures, type.limits.measures || {})
        }
        return { fields, dimensions, measures, }
      },
      fieldsValid () {
        return this.checkValid(this.limits.fields || {}, this.fields)
      },
      dimensionsValid () {
        return this.checkValid(this.limits.dimensions || {}, this.selectedDimensions)
      },
      measuresValid () {
        return this.checkValid(this.limits.measures || {}, [...this.selectedMeasures, this.selectedCounts,] )
      },
      paletteOpen: {
        get () {
          return this.isEdit
        },
        set (value) {
          this.$emit('update:isEdit', value)
        },
      },
    },
    watch: {
      isEdit (value) {
        if (value) {
          this.$el.blur()
          this.$el.focus()
        }
      },
    },
    methods: {
      startFieldDrag (field, kind) {
        this.draggedField = { field, kind, }
      },
      endFieldDrag () {
        this.draggedField = undefined
      },
      updateMeasureAggregates (measureAggregates) {
        this.updateColumns(this.selectedDimensions, this.selectedCounts, measureAggregates)
      },
      updateDimensionCounts (dimensionCounts) {
        this.updateColumns(this.selectedDimensions, dimensionCounts, this.selectedMeasures)
      },
      updateDimensions (dimensions) {
        this.updateColumns(dimensions, this.selectedCounts, this.selectedMeasures)
      },
      getDimensionColumn (dimension) {
        if (this.columns.filter(column => column.type == 'group' && column.dimension == dimension.key).length > 0) {
          return this.columns.filter(column => column.type == 'group' &&  column.dimension == dimension.key)[0]
        } else {
          let column = { type: 'group', dimension: dimension, }
          column.drilldowns = []
          while (dimension.default_drilldown) {
            column.drilldowns.push({ dimension: dimension.default_drilldown, })
            dimension = this.$store.getters['metadata/dimensions'][dimension.default_drilldown]
          }
          return column
        }
      },
      updateColumns (dimensions, counts, measureAggregates) {
        this.$emit('update:columns', [
          ...dimensions.map(this.getDimensionColumn),
          ...counts.map(count => ({ type: 'count', dimension: count, })),
          ...measureAggregates,
        ])
      },
      mergeLimits (first, second) {
        return {
          min: Math.max(first.min || 0, second.min || 0),
          max: Math.min(first.max || Infinity, second.max || Infinity),
        }
      },
      checkValid (limit, selection) {
        let { min, max, } = {
          min: (limit || {}).min,
          max: (limit || {}).max,
        }
        return (!min || selection.length >= min) &&
          (!max || selection.length <= max)
      },
      validMsg (limit, type) {
        if (!limit) {
          return ''
        }
        let { min, max, } = {
          min: limit.min,
          max: limit.max,
        }
        if (min && max) {
          if (min == max) {
            return `Select ${min} ${type}(s)`
          }
          return `Select between ${min} and ${max} ${type}(s)`
        } else if (min) {
          return `Select at least ${min} ${type}(s)`
        } else if (max) {
          return `select at most ${max} ${type}(s)`
        } else {
          return ''
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

.module-edit, .editor-component {
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.module-edit {
  height: 100%;
  border-radius: 8px;
}

.module-edit.readOnly .editor-component  {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.module-edit.readOnly .main {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.type-option, .edit {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}

.main {
  flex-grow: 1;
  max-width: 100%;
  padding: 0 !important;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dimension-options {
  border: 1px solid #98CABF;
}

.measure-options {
  border: 1px solid #85BAE6;
}

.measure-options, .dimension-options {
  padding: 8px;
  border-radius: 8px;
}

fieldset {
  margin: 0;
}

.drag.handle {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: $darkGreen;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.palette {
  height: 100%;
}
</style>
