<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :class="[`${variant}-variant`]"
    viewBox="0 0 24 24"
    style="enable-background:new 0 0 24 24;"
    xml:space="preserve">
    <g>
      <path
        class="st0"
        d="M12,23c-1.5,0-2.9-0.3-4.3-0.9c-5.6-2.4-8.2-8.8-5.8-14.4C3,5.1,5,3,7.6,1.9c0.5-0.2,1.1,0,1.3,0.5
        s0,1.1-0.5,1.3C6.3,4.7,4.6,6.4,3.7,8.5c-1.9,4.6,0.2,9.9,4.8,11.8c2.2,0.9,4.7,1,6.9,0.1c2.2-0.9,4-2.6,4.9-4.8
        c0.2-0.5,0.8-0.7,1.3-0.5c0.5,0.2,0.7,0.8,0.5,1.3c-1.1,2.7-3.3,4.8-6,5.9C14.8,22.7,13.4,23,12,23z" />
    </g>
    <g>
      <path
        class="st0"
        d="M22,13H12c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1c6.1,0,11,4.9,11,11C23,12.6,22.6,13,22,13z M13,11h7.9
        c-0.5-4.2-3.8-7.5-7.9-7.9V11z" />
    </g>
  </svg>
</template>

<script>

  export default {
    name: 'PieIcon',
    props: {
      variant: { type: String, required: true, },
    },
  }

</script>

<style lang="scss" scoped>
.slate-variant {
  & .st0 {
    fill:#494D51;
  }
}

.green-variant {
  .st0 {
    fill: #98CABF;
  }
}
</style>
