import axios from 'axios'
import Vue from 'vue'

export const userModel = {
  namespaced: true,
  state () {
    return {
      userObject: null,
    }
  },
  getters: {
    loggedInUser (state) {
      return state.userObject
    },
    defaultCompanyId (state, getters) {
      return getters.loggedInUser.company_users.slice(0, 1).shift().company.id
    },
    companyId (state, getters, rootState, rootGetters) {
      return rootGetters.companyId || getters.defaultCompanyId
    },
    companyUsers (state, getters) {
      let user = getters.loggedInUser
      if (user == null) {
        return null
      }
      return user.company_users
    },
    companyUser (state, getters) {
      return (getters.companyUsers || []).filter((user) => `${user.company.id}` === `${getters.companyId}`).slice(0, 1).shift() || null
    },
    reports (state, getters) {
      return getters.companyUser.role.reports
    },
    permissions (state, getters) {
      let companyUser = getters.companyUser
      if (companyUser === null) {
        return []
      }
      return companyUser.role.permissions
    },
    companyName (state, getters) {
      let companyUser = getters.companyUser
      if (companyUser === null || !companyUser.company.display_name) {
        return 'Ingest AI'
      }
      return companyUser.company.display_name
    },
    canViewChecks (state, getters) {
      return getters['permissions'].includes('VIEW_CHECKS')
    },
    canManageAttributes (state, getters) {
      return getters['permissions'].includes('MANAGE_ATTRIBUTES')
    },
    canManageExpressions (state, getters) {
      return getters['permissions'].includes('PROTOTYPE_EXPRESSIONS')
    },
    canViewDashboards (state, getters) {
      return getters['permissions'].includes('VIEW_DASHBOARDS')
    },
    canManageDashboards (state, getters) {
      return getters['permissions'].includes('MANAGE_DASHBOARDS')
    },
    canViewConsolidation (state, getters) {
      return getters['permissions'].includes('VIEW_CONSOLIDATION_TABLES')
    },
    canUploadForecasts (state, getters) {
      return getters['permissions'].includes('UPLOAD_BUDGETS')
    },
    canViewIntegrations (state, getters) {
      return getters['permissions'].includes('VIEW_INTEGRATIONS')
    },
    canViewUsers (state, getters) {
      return getters['permissions'].includes('MANAGE_USERS')
    },
    userPermissions (state, getters) {
      return getters['permissions']
    },
    defaultRoute (state, getters, rootState, rootGetters) {
      if (!rootGetters['auth/isLoggedIn']) {
        return '/login'
      }
      if (getters.companyId === null) {
        return '/404'
      }
      let companyPath = `/company/${getters.companyId}`
      if (getters.canManageDashboards) {
        return `${companyPath}/reports`
      }
      if (getters.canViewDashboards) {
        return `${companyPath}/reports`
      }
      if (getters.canViewChecks) {
        return `${companyPath}/checks`
      }
      return `${companyPath}/settings/userSettings`
    },
  },
  mutations: {
    setUser (state, user) {
      state.userObject = user
      Vue.prototype.$tracker.identify(
        user.id,
        { email: user.email, name: user.name, }
      )
    },
  },
  actions: {
    async loadUser (context) {
      if (!context.rootGetters['auth/isLoggedIn']) {
        return
      }
      let token = context.rootGetters['auth/authToken']
      let validatedLogin = await axios.get(
        `${process.env.VUE_APP_ADMIN_API_URL}/login`,
        { headers: { authorization: token, }, })
      if (validatedLogin.data.valid == true) {
        context.commit('setUser', validatedLogin.data.user)
      }
      return
    },
  },
}
