import { childErrors } from '@/components/reports/config/common/validation'

export function mapChildren (children) {
  const childFields = {}
  const childErrorGetters = {}
  Object.keys(children).forEach(childKey => {
    childFields[childKey] = {
      get () {
        if (this.vModel) {
          return this.vModel[children[childKey]]
        } else {
          return null
        }
      },
      set (value) {
        const newModel = { ...this.vModel, }
        newModel[children[childKey]] = value
        this.vModel = newModel
      },
    }
    childErrorGetters[`${childKey}Errors`] = function () {
      return childErrors(children[childKey], this.errors)
    }
  })
  return {
    ...childFields,
    ...childErrorGetters,
  }
}
