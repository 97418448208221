<template>
  <div class="cheque-filter filter">
    <div
      v-if="showLabelSection"
      class="label-sections">
      <div class="button-label ">
        {{ data.label }}
      </div>

      <b-button
        class="label-section-button"
        type="is-text"
        :disabled="loading"
        @click="selectionModel = data.options">
        Select All
      </b-button>
      <b-button
        class="label-section-button"
        type="is-text"
        :disabled="loading"
        @click="selectionModel = []">
        Deselect All
      </b-button>
    </div>
    <div class="dropdown-filter">
      <multiselect
        id="key"
        ref="dropdown"
        v-model="selectionModel"
        class="multiselect"
        track-by="key"
        :options="data.options"
        tag-placeholder=""
        select-label=""
        selected-label=""
        deselect-label=""
        deselect-group-label=""
        :close-on-select="false"
        :clear-on-select="false"
        :taggable="false"
        :multiple="true"
        :allow-empty="true"
        :searchable="true"
        :options-limit="300"
        :max-height="500"
        placeholder="Search"
        label="value"
        @open="isOpen = true"
        @close="isOpen = false">
        <template slot="tag">
          <div class="dropdown-tag" />
        </template>
        <template slot="caret">
          <b-icon
            :icon="isOpen ? 'menu-up' : 'menu-down'"
            class="caret"
            @click="toggle" />
        </template>

        <template slot="placeholder">
          <span />
        </template>

        <template
          slot="selection"
          slot-scope="{ values, isOpened }">
          <template v-if="!isOpened">
            <span class="multiselect__single">{{
              values.length ? `${values.length} Selected` : "Any"
            }}</span>
          </template>
        </template>

        <template
          slot="option"
          slot-scope="props">
          <div class="option__desc">
            <b-checkbox
              :value="true"
              class="selected-checkbox" />
            <b-checkbox
              :value="false"
              class="unselected-checkbox" />
            <span class="option__title">{{ props.option.label }}</span>
          </div>
        </template>
        <span />
      </multiselect>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChequeFilterItem',
    components: {},

    props: {
      data: { type: Object, required: true, },

      showLabelSection: { type: Boolean, default: true, },
    },
    data () {
      return {
        isOpen: false,
        loading: false,
        selectionModel: [],
        isFiltered: false, //make this computed
      }
    },
    computed: {},
    methods: {
      toggle () {
        if (this.isOpen) {
          this.$refs.dropdown.deactivate()
        } else {
          this.$refs.dropdown.activate()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.dropdown-content {
  border-radius: 16px !important;
}

.label-sections {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  .button-label {
    font-size: 13px;
    margin-right: auto;
    color: $ui-03;
    font-weight: 300;
  }

  .label-section-button {
    font-size: 10px;
    padding: 0 6px;
    height: 16px;
    color: $grayscale-3;
  }
}
</style>

<style lang="scss">
.cheque-filter {
  .dropdown {
    display: flex;
    width: 100%;
    position: relative;
  }
  .dropdown-item.is-active {
    background-color: $primary !important;
    color: white !important;
  }

  .caret {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .dropdown-filter {
    .multiselect__single {
      height: 30px;
      display: flex;
      align-items: center;
    }

    .multiselect__content-wrapper {
      position: absolute !important; //make the dropdown overlay
    }

    .multiselect__placeholder {
      display: none !important;
    }

    .multiselect__option {
      .selected-checkbox {
        display: none !important;
      }
      .unselected-checkbox {
        display: inline-flex !important;
      }
      .option__desc {
        display: flex;
        align-items: center;

        .option__title {
          position: relative;
          top: 1px;
        }
      }

      &.multiselect__option--selected {
        background-color: white !important;
        color: $grayscale-1 !important;

        &:hover {
          background-color: $grayscale-6;
        }
        .unselected-checkbox {
          display: none !important;
        }
        .selected-checkbox {
          display: inline-flex !important;
        }
      }
    }
  }
}
</style>
