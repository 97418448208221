import { mapGetters } from 'vuex'

export const expressionProcessorMixin = {
  computed: {
    ...mapGetters({
      dateFilterExpressionOptions: 'datePicker/availableExpressions',
      controlFilterExpressionOptions: 'controls/availableExpressions',
      companyExpressions: 'expressions/companyExpressions',
    }),
    dateFilterOptionKeys () {
      return Object.keys(this.dateFilterExpressionOptions)
    },
    controlFilterOptionKeys () {
      return Object.keys(this.controlFilterExpressionOptions)
    },
    injectedDimensions () {
      return []
    },
  },
  methods: {
    processString (string) {
      return string.replace(/{{[a-z,A-Z,0-9,_]+}}/g, (match) => {
        let noMustache = match.substring(2, match.length - 2)
        if (noMustache.startsWith('option_control_label_')) {
          let controlId = parseInt(noMustache.substring(21))
          let selection = this.$store.getters['controls/debouncedSelections'][controlId]
          if (selection) {
            return selection.value
          } else {
            return ''
          }
        }
        if (noMustache.startsWith('dimension_control_label_')) {
          let controlId = parseInt(noMustache.substring(24))
          let selection = this.$store.getters['controls/debouncedSelections'][controlId]
          if (selection) {
            return selection.map(d => d.value).filter(v => !!v).join(', ')
          } else {
            return ''
          }
        }
        if (noMustache == 'drilldown_target_label') {
          if (this.drilldownTarget) {
            return this.drilldownTarget.name
          } else {
            return !!this.drilldownTargetOptions && this.drilldownTargetOptions.length > 0 ? this.drilldownTargetOptions[0].name : ''
          }
        }
      })
    },
    preprocessExpression (expression, injectedDimensions = []) {
      if (!expression) {
        return null
      }
      if (expression.type === 'drilldown_filter') {
        return {
          type: 'filter',
          by: this.drilldownFilters,
          expression: this.preprocessExpression(expression.expression, injectedDimensions),
        }
      } else if (expression.type === 'drilldown_key') {
        return this.preprocessExpression((this.drilldownTarget || this.drilldownTargetOptions.slice(0, 1).shift()).keyExpression, injectedDimensions)
      } else if (expression.type === 'drilldown_label') {
        return this.preprocessExpression((this.drilldownTarget || this.drilldownTargetOptions.slice(0, 1).shift()).labelExpression, injectedDimensions)
      } else if (expression.type === 'drilldown_key_field') {
        return {
          type: 'field',
          field: { key: (this.drilldownTarget || this.drilldownTargetOptions.slice(0, 1).shift()).keyField, },
        }
      } else if (expression.type === 'drilldown_label_field') {
        return {
          type: 'field',
          field: { key: (this.drilldownTarget || this.drilldownTargetOptions.slice(0, 1).shift()).labelField, },
        }
      } else if (this.dateFilterOptionKeys.includes(expression.type)) {
        let filteredExpression = this.dateFilterExpressionOptions[expression.type].getExpression(expression, this.preprocessExpression)
        return {
          ...filteredExpression,
          expression: this.preprocessExpression(expression.expression, injectedDimensions),
        }
      } else if (this.controlFilterOptionKeys.includes(expression.type)) {
        let filteredExpression = this.controlFilterExpressionOptions[expression.type].getExpression(expression, this.preprocessExpression, injectedDimensions)
        if (expression.type != 'uninjectable_expression') {
          filteredExpression = this.preprocessExpression(filteredExpression, injectedDimensions)
        } else {
          filteredExpression = this.preprocessExpression(filteredExpression, [])
        }
        return filteredExpression
      } else if (expression.type == 'company_expression') {
        if (!this.companyExpressions[expression.expression_id]) {
          return undefined
        }
        return this.preprocessExpression(this.companyExpressions[expression.expression_id].spec, injectedDimensions)
      } else if (Object.keys(this.$store.getters['metadata/expressionTypes'])
        .includes(expression.type)) {
        let expressionType = this.$store.getters['metadata/expressionTypes'][expression.type]
        let processedExpression = { ...expression, }
        for (let fieldKey of Object.keys(expressionType.args)) {
          if (expressionType.args[fieldKey] === 'expression') {
            processedExpression[fieldKey] = this.preprocessExpression(processedExpression[fieldKey], injectedDimensions)
          } else if (expressionType.args[fieldKey] === 'expression_list') {
            if (['any', 'sum', 'count', 'count_distinct','join', 'min', 'max',].includes(expression.type)) {
              processedExpression[fieldKey] =
                [].concat(
                  ...this.injectedDimensions,
                  ...injectedDimensions,
                  processedExpression[fieldKey]).map(subExpression =>
                    this.preprocessExpression(subExpression,
                      injectedDimensions.filter(d => d != subExpression))
                )
            } else {
              processedExpression[fieldKey] =
                  processedExpression[fieldKey].map(subExpression =>
                    this.preprocessExpression(subExpression, injectedDimensions))
            }
          }
        }
        return processedExpression
      } else {
        throw `Unknown expression type ${expression.type}`
      }
    },
  },
}
