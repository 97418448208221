<template>
  <div class="measure-config-editor">
    <b-field
      class="input-field"
      label="Measure">
      <CompanyMeasureRefSelect v-model="inputs.measure" />
    </b-field>

    <hr />
    <b-switch
      v-model="enableFutureMeasure"
      class="mt-5 mb-4"
      type="is-info">
      Use a different measure for data after the report date.
    </b-switch>
    <b-field
      v-if="enableFutureMeasure"
      class="input-field">
      Future measure:
      <CompanyMeasureRefSelect v-model="inputs.future_measure" />
    </b-field>

    <MeasureConfigOptions v-model="inputs" :context="context" />
  </div>
</template>

<script>
  import CompanyMeasureRefSelect from '@/components/reports/config/measures/CompanyMeasureRefSelect'
  import ModuleConfigMixin from '@/components/reports/config/modules/common/ModuleConfigMixin'
  import MeasureConfigOptions from '@/components/reports/config/modules/common/MeasureConfigOptions'

  export default {
    name: 'MeasureConfigEditor',
    components: {
      CompanyMeasureRefSelect,
      MeasureConfigOptions,
    },
    mixins: [ModuleConfigMixin,],
    data () {
      return {
        defaultValue: {
          measure: this.context.parentMeasure,
          is_pct_total: false,
          pct_total_dimensions: [],
        },
      }
    },
    computed: {
      enableFutureMeasure: {
        get () {
          return ![null, undefined,].includes(this.inputs.future_measure)
        },
        set (enableAverage) {
          if (enableAverage) {
            this.inputs.future_measure = {}
          } else {
            this.inputs.future_measure = undefined
          }
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.benchmark-inputs {
  margin-top: 30px;
}
</style>
