<template>
  <div class="company-filter-ref-editor">
    <span class="label"> Company Filter: </span>
    <CompanyFilterRefSelect v-model="vModel" />
    <ValidationErrors :errors="errors" />
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyFilterRefSelect from '@/components/reports/config/filters/CompanyFilterRefSelect'

  export default {
    name: 'CompanyFilterRefEditor',
    components: {
      CompanyFilterRefSelect,
    },
    mixins: [
      ObjectEditorMixin,
    ],
  }

</script>

<style lang="scss" scoped>
.company-filter-ref-editor {
  display: flex;
  flex-direction: column;
}
</style>
