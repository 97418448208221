<template>
  <div class="company-changer">
    <multiselect
      :value="currentCompany"
      :allow-empty="false"
      :options="allCompanies"
      :searchable="true"
      class="company-dropdown"
      label="name"
      deselect-label=""
      selected-label=""
      select-label=""
      track-by="id"
      placeholder="Select Company"
      open-direction="bottom"
      @input="switchCompany">
      <template
        slot="option"
        slot-scope="{ option }">
        {{ option.name || option.display_name }}
      </template>
      <template
        slot="singleLabel"
        slot-scope="{ option }">
        <div class="selected-company">
          {{ option.name.replace(/_/g, ' ') }}
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import navigation from '@/mixins/navigation'
  import { mapGetters } from 'vuex'

  export default {
    name: 'CompanyChanger',
    components: {
      Multiselect,
    },
    mixins: [navigation,],
    computed: {
      ...mapGetters({
        companyName: 'auth/user/companyName',
        companyUser: 'auth/user/companyUser',
      }),

      currentCompany () {
        return this.companyUser.company
      },
    },
    methods: {
      isSelected (companyId) {
        return companyId == this.$store.getters['companyId']
      },
      async switchCompany (option) {
        option.callback()
      },
    },
  }
</script>

<style lang="scss" scoped>
.company-changer {
  position: relative;
  height: 40px;
  width: 100%;

  .company-dropdown {
    width: auto;
    min-width: 240px;
  }

  .selected-company {
    max-width: 200px;
    min-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
  }
}
</style>
