<template>
  <input
    v-model="model"
    type="number"
    class="number-input"
    v-bind="{ min, max, step }" />
</template>

<script>

  import inputMixin from '@/components/common/input/inputMixin.js'

  export default {
    name: 'NumberInput',
    mixins: [
      inputMixin,
    ],
    props: {
      min: { type: String, default: null, },
      max: { type: String, default: null, },
      step: { type: String, default: '1', },
    },
  }

</script>

<style lang="scss" scoped>

.number-input {
  min-width: 3 * $remGrid;
}

</style>

