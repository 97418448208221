<template>
  <g
    class="column-series">
    <defs>
      <template v-for="(datum, i) in data">
        <filter
          :id="$id('shadow-' + i)"
          :key="`filter-${i}`"
          width="12"
          height="12"
          :x="-6"
          :y="-6">
          <feDropShadow
            dy="0"
            dx="0"
            std-deviation="2"
            :flood-color="measureDatumColor(measure, datum)"
            :flood-opacity=".75" />
        </filter>
      </template>
    </defs>
    <template
      v-for="(datum, i) in data">
      <rect
        v-if="!isNaN(getYPosition(datum[measure.key]))"
        :key="`bar-${i}`"
        :stroke="measureDatumColor(measure, datum)"
        :fill="measureDatumColor(measure, datum)"
        :style="{
          'stroke-width': highlightEnabled && highlightedData.includes(datum) ? '2' : '0',
          'fill-opacity': '.75',
          'opacity': highlightEnabled ? (!highlightedData.includes(datum) ? '.4' : '1') : '1',
          'filter': tooltipEnabled && !highlightedData.includes(datum) ? 'none' : `url(#${$id('shadow-' + i)})`}"
        :x="columnScale(getDimensionVal(primaryDimensionKeys, datum)) + secondaryScale(columnIdx)"
        :y="getYPosition(datum[measure.key]) > getYPosition(0) ? getYPosition(0) + 4 : getYPosition(datum[measure.key])"
        :rx="4"
        :ry="4"
        :width="secondaryScale.bandwidth()"
        :height="Math.max(getYPosition(datum[measure.key]) > getYPosition(0)
          ? getYPosition(datum[measure.key]) - getYPosition(0) - 4
          : getYPosition(0) - getYPosition(datum[measure.key]) - 4, 0)"
        :class="{
          'clickable': hasClickableDimensions,
        }"
        @click="$emit('click', datum)" />
    </template>
  </g>
</template>

<script>

  import chartComponentMixin from '@/components/charting/chart_component_mixin'
  import * as d3  from 'd3'

  export default {
    name: 'ColumnSeries',
    mixins: [
      chartComponentMixin,
    ],
    props: {
      measure: { type: Object, required: true, },
      columnSeriesCount: { type: Number, default: 1, },
      columnIdx: { type: Number, default: 0, },
    },
    computed: {
      columnScale () {
        return this.xScale.paddingInner(.05).paddingOuter(.05)
      },
      secondaryHoverScale () {
        return d3.scaleBand().domain([0, this.columnSeriesCount - 1,]).range([0,this.xScale.bandwidth(),])
      },
      secondaryScale () {
        return d3.scaleBand().domain([0, this.columnSeriesCount - 1,]).range([0,this.columnScale.bandwidth(),]).paddingInner(.05).paddingOuter(.05)
      },
    },
  }

</script>

<style lang="scss" scoped>

.column-series {
}

.clickable {
  cursor: pointer;
}

</style>

