<template>
  <div class="chart-title-bar">
    <slot name="filters" />
    <div class="name">
      <input
        v-if="isEdit"
        v-model="nameModel"
        placeholder="Module name" />
      <div v-else>
        {{ processString(nameModel) }}
      </div>
    </div>
    <div class="buttons">
      <slot name="toolbarButtons" />
    </div>
  </div>
</template>

<script>

  import chartComponentMixin from '@/components/charting/chart_component_mixin'
  import { expressionProcessorMixin } from '@/components/reporting/expressionProcessorMixin'

  export default {
    name: 'ChartTitleBar',
    mixins: [
      chartComponentMixin,
      expressionProcessorMixin,
    ],
  }

</script>

<style lang="scss" scoped>

.chart-title-bar {
  display: flex;
  width: 100%;
}

.name {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  div {
    font-size: $h1;
    font-weight: $emp;
  }

  input {
    text-align: center;
  }
}

.buttons {
  display: flex;
  align-items: center;

  font-size: 1.6rem;
}

</style>

