<template>
  <div class="router-container">
    <router-view class="router-view" />
  </div>
</template>

<script>
  export default {
    name: 'AuthLayout',
  }
</script>

<style lang="scss" scoped>
.router-container {
  background-color: $dashboard-bg;
  height: 100%;
}
</style>
