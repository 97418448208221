import { render, staticRenderFns } from "./DateFilter.vue?vue&type=template&id=0a0ba004&scoped=true"
import script from "./DateFilter.vue?vue&type=script&lang=js"
export * from "./DateFilter.vue?vue&type=script&lang=js"
import style0 from "./DateFilter.vue?vue&type=style&index=0&id=0a0ba004&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a0ba004",
  null
  
)

export default component.exports