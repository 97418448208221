<template>
  <div class="container-fluid">
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="true" />
  </div>
</template>

  <script>
  export default {
    name: 'IntegrationsRedirect',
    components: {

    },
    data () {
      return {
        isLoading: true,
      }
    },

    async  mounted () {
      const companyID = JSON.parse(localStorage.getItem('companyID'))
      if (this.$route.query && companyID) {
        this.$router.push({
          name: 'integrations_page',
          params: {
            company_id: companyID,
          },
          query: this.$route.query,
        })
      }
    },
  }
  </script>
