<template>
  <div class="MeasureJoinTree">
    <div v-if="node.measure">
      {{ node.measure.name }}
    </div>
    <div v-if="node.view"
         class="view">
      {{  node.view.label }}
    </div>
    <div class="children"
         v-if="node.children.length > 0">
      <div v-for="child, idx in node.children"
           class="child"
           :key="idx">
        <MeasureJoinTree :node="child" />
      </div>
    </div>
    <ul class="dimensions">
      <li v-for="dimension, idx in node.dimensions || []"
          class="dimension"
          :key="idx">
        {{ dimension.name }}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'MeasureJoinTree',
    props: {
      node: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>

.measure-join-tree {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.children {
  display: flex;
  flex-direction: row;
}

div {
  display: flex;
  flex-direction: column;
  margin: 8px;
  border: 1px solid black;
  flex-grow: 1;
}

.view {
  font-weight: bold;
}

</style>
