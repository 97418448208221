<template>
  <div
    class="performance-legend">
    <template
      v-if="primaryColorSpec === 'under_over'">
      <div
        class="legend-row"
        max-width="128px">
        <div
          class="legend-item"
          :style="{
            'background-color': dvGood,
            'box-shadow': `0 0 3px ${dvGood}`,
          }" />
        <slot name="belowLabel">
          Below average
        </slot>
      </div>
      <div
        class="legend-row"
        max-width="128px">
        <div
          class="legend-item"
          :style="{
            'background-color': dvBad,
            'box-shadow': `0 0 3px ${dvBad}`,
          }" />
        <slot name="aboveLabel">
          Above average
        </slot>
      </div>
    </template>
    <template
      v-if="primaryColorSpec === 'over_under'">
      <div
        class="legend-row"
        max-width="128px">
        <div
          class="legend-item"
          :style="{
            'background-color': dvGood,
            'box-shadow': `0 0 3px ${dvGood}`,
          }" />
        <slot name="aboveLabel">
          Above average
        </slot>
      </div>
      <div
        class="legend-row"
        max-width="128px">
        <div
          class="legend-item"
          :style="{
            'background-color': dvBad,
            'box-shadow': `0 0 3px ${dvBad}`,
          }" />
        <slot name="belowLabel">
          Below average
        </slot>
      </div>
    </template>
  </div>
</template>

<script>

  import chartComponentMixin from '@/components/charting/chart_component_mixin'

  export default {
    name: 'PerformanceLegend',
    mixins: [
      chartComponentMixin,
    ],
    computed: {
      primaryColorSpec () {
        if (!this.primaryMeasure || !this.primaryMeasure.colorSpec) {
          return null
        }
        if (this.primaryMeasure.colorSpec.type === 'controlled') {
          return this.$store.getters['controls/debouncedSelections'][this.primaryMeasure.colorSpec.control_id].colorSpec.type
        }
        return this.primaryMeasure.colorSpec.type
      },
    },
  }

</script>

<style lang="scss" scoped>

.performance-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6 * $gridBase;
  padding-bottom: 6 * $gridBase;
  font-size: 1.6rem;
}

.legend-item {
  display: inline-block;
  min-width: 4 * $gridBase;
  width: 4 * $gridBase;
  height: 4 * $gridBase;
  margin: 2*$gridBase;
  border-radius: 50%;
}

.legend-row {
  align-items: center;
  display: flex;
  margin-left: 4*$gridBase;
}

</style>

