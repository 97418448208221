<template>
  <div class="accordian-container">
    <slot />
    <slot name="foot" />
  </div>
</template>

<script>

  export default {
    name: 'AccordianContainer',
  }

</script>

<style lang="scss" scoped>

.accordian-container {
  background-color: white;
  display: flex;
  flex-direction: column;
}

</style>

