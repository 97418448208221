<template>
  <div class="cheque-search">
    <div class="container">
      <div>
        <h2 class="page-title">
          Check Search
        </h2>
        <p class="page-desc">
          Create a search query to return checks matching the chosen criteria
        </p>
      </div>
      <div class="basic-filters">
        <div class="filters">
          <div class="filter date-filter">
            <div class="filter-label">
              <div class="label">
                Date Range
              </div>
            </div>
            <time-range-filter
              :hide-secondary-label="true"
              :default="timeRangeFilterDefault" />
          </div>
          <div class="filter">
            <cheque-filter-item :data="filters.location" />
          </div>

          <div class="filter">
            <cheque-filter-item :data="filters.revenue_center" />
          </div>
          <div class="filter">
            <cheque-filter-item :data="filters.meal_period" />
          </div>

          <div class="filter">
            <div class="filter-label">
              <div class="label">
                Check Number
              </div>
            </div>
            <b-input class="text-input" />
          </div>
        </div>
      </div>

      <div class="advanced-filters">
        <b-collapse
          class=""
          :open="false"
          animation="slide"
          aria-id="contentIdForA11y3">
          <template #trigger="props">
            <div
              class="advanced-section-header"
              role="button"
              :aria-expanded="props.open">
              <h3>Advanced</h3>
              <a class="icon">
                <b-icon :icon="props.open ? 'menu-up' : 'menu-down'" />
              </a>
            </div>
          </template>

          <div class="">
            <div class="filters">
              <div class="filter">
                <cheque-filter-item :data="filters.server" />
              </div>
              <div class="filter">
                <cheque-filter-item :data="filters.payment_method" />
              </div>

              <div class="filter">
                <div class="filter">
                  <div class="filter-label">
                    <div class="label">
                      Payment Mininum
                    </div>
                  </div>
                  <b-input class="text-input" />
                </div>
              </div>
              <div class="filter">
                <div class="filter">
                  <div class="filter-label">
                    <div class="label">
                      Payment Maximum
                    </div>
                  </div>
                  <b-input class="text-input" />
                </div>
              </div>

              <div class="filter">
                <div class="filter-label">
                  <div class="label">
                    Credit Card Last 4 digits
                  </div>
                </div>
                <b-input class="text-input" />
              </div>
            </div>
            <div class="custom-filters">
              <div class="title-section">
                <h3>Filters:</h3>
                <b-button
                  icon-left="plus"
                  type="is-primary"
                  @click="customFilterCount++">
                  Add Filter
                </b-button>
              </div>

              <div class="custom-filters-content columns m-0 is-multiline">
                <div
                  v-for="i in customFilterCount"
                  :key="i"
                  class="column custom-filter is-3">
                  <ChequeCustomFilterItem />
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <div class="search-buttons">
        <b-button
          class="mr-3"
          type="is-primary">
          Search
        </b-button>

        <b-button type="">
          Reset Filters
        </b-button>
      </div>
      <div class="results">
        <div class="columns">
          <div class="column">
            <div class="buttons">
              <button class="download-btn button">
                <b-icon
                  icon="download"
                  custom-size="h-6"
                  class="icon" />
                Export Table
              </button>
            </div>
            <b-table
              class="table is-fullwidth is-hoverable results-table"
              :data="data"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :pagination-position="paginationPosition"
              :default-sort-direction="defaultSortDirection"
              :pagination-rounded="isPaginationRounded"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              default-sort="user.first_name"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              :page-input="hasInput"
              :pagination-order="paginationOrder"
              :page-input-position="inputPosition"
              :debounce-page-input="inputDebounce">
              <b-table-column
                v-slot="props"
                field="chequeNumber"
                label="Check #"
                width="100"
                sortable>
                {{ props.row.chequeNumber }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="location"
                label="Location"
                sortable>
                {{ props.row.location }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="date"
                label="Date"
                sortable>
                {{ props.row.date }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="openTime"
                label="Open Time"
                sortable>
                {{ props.row.openTime }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="amountPaid"
                label="Amount Paid"
                sortable>
                {{ props.row.amountPaid }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="server"
                label="Server"
                sortable>
                {{ props.row.server }}
              </b-table-column>
            </b-table>
          </div>

          <div class="render-column m-0">
            <ChequeRender :cheque="selectedCheque" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TimeRangeFilter from '@/components/controls/filter/TimeRangeFilter.vue'
  import ChequeFilterItem from '@/components/cheque_search/ChequeFilterItem.vue'
  import ChequeRender from '@/components/cheque_search/ChequeRender.vue'
  import ChequeCustomFilterItem from '@/components/cheque_search/ChequeCustomFilterItem.vue'
  const data = [
    {
      id: 1,
      chequeNumber: 2,
      location: 'location',
      date: '09-13-2023',
      openTime: '09:00',
      amountPaid: '$100',
      server: 'server',
    },
    {
      id: 1,
      chequeNumber: 2,
      location: 'location',
      date: '09-13-2023',
      openTime: '09:00',
      amountPaid: '$100',
      server: 'server',
    },
    {
      id: 1,
      chequeNumber: 2,
      location: 'location',
      date: '09-13-2023',
      openTime: '09:00',
      amountPaid: '$100',
      server: 'server',
    },
    {
      id: 1,
      chequeNumber: 2,
      location: 'location',
      date: '09-13-2023',
      openTime: '09:00',
      amountPaid: '$100',
      server: 'server',
    },
  ]
  export default {
    name: 'ChequeSearch',
    components: {
      TimeRangeFilter,
      ChequeFilterItem,
      ChequeCustomFilterItem,
      ChequeRender,
    },
    data () {
      return {
        customFilterCount: 2,

        data,
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 5,
        hasInput: false,
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',

        timeRangeFilterDefault: {
          type: 'calendar',
          calendarPreset: 'last_7',
          fiscalPreset: 'std',
        },

        selectedCheque: {
          closeTime: '2023-10-19T12:11:45.975000+00:00',
          contact_email: null,
          contact_phone: null,
          discounts: 0,
          id: 89893,
          items: [
            {
              amount: 750,
              isVoid: false,
              item: 'latte large',
              orderTime: '2023-10-19T12:10:56.871000+00:00',
              quantity: 1,
            },
            {
              amount: 475,
              isVoid: false,
              item: 'breakfast taco-bacon',
              orderTime: '2023-10-19T12:10:56.865000+00:00',
              quantity: 1,
            },
            {
              amount: 900,
              isVoid: false,
              item: 'biscuit sandwich',
              orderTime: '2023-10-19T12:10:56.869000+00:00',
              quantity: 1,
            },
            {
              amount: 475,
              isVoid: false,
              item: 'breakfast taco-potato',
              orderTime: '2023-10-19T12:10:56.868000+00:00',
              quantity: 1,
            },
            {
              amount: 400,
              isVoid: false,
              item: 'eggs',
              orderTime: '2023-10-19T12:10:56.861000+00:00',
              quantity: 1,
            },
            {
              amount: 400,
              isVoid: false,
              item: 'toast wheat',
              orderTime: '2023-10-19T12:10:56.863000+00:00',
              quantity: 1,
            },
            {
              amount: 850,
              isVoid: false,
              item: 'cortado',
              orderTime: '2023-10-19T12:10:56.874000+00:00',
              quantity: 1,
            },
          ],
          logo_fname: 'headington_companies.png',
          number: '5',
          openTime: '2023-10-19T12:10:56.838000+00:00',
          paid: 4601,
          payments: [
            {
              amount: 4601,
              comment: 'DD 5b2e45f9 Annette B',
              last4: null,
              method: 'doordash',
            },
          ],
          restaurant: 'commissary',
          revenueCenter: 'no revenue center',
          s3_prefix: 'headington-companies',
          server: 'default',
          subTotal: 4250,
          tax: 351,
          tip: 0,
          void: false,
        },

        filters: {
          location: {
            label: 'Location',
            options: [
              { key: 'a', label: 'a', },
              { key: 'b', label: 'b', },
              { key: 'c', label: 'c', },
            ],
          },

          revenue_center: {
            label: 'Revenue Center',
            options: [
              { key: 'a', label: 'a', },
              { key: 'b', label: 'b', },
              { key: 'c', label: 'c', },
            ],
          },

          meal_period: {
            label: 'Meal Period',
            options: [
              { key: 'a', label: 'a', },
              { key: 'b', label: 'b', },
              { key: 'c', label: 'c', },
            ],
          },

          server: {
            label: 'Server',
            options: [
              { key: 'a', label: 'a', },
              { key: 'b', label: 'b', },
              { key: 'c', label: 'c', },
            ],
          },

          payment_method: {
            label: 'Payment Method',
            options: [
              { key: 'a', label: 'a', },
              { key: 'b', label: 'b', },
              { key: 'c', label: 'c', },
            ],
          },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
.page-title {
  margin-top: 50px;
  font-weight: 500;
  font-size: 24px;
}

p.page-desc {
  font-size: 16px;
  margin-top: 15px;
  color: #7e7d84;
  display: block;
}

.advanced-filters {
  margin-top: 50px;
  .advanced-section-header {
    display: flex;
    border-bottom: $border-1;
    padding-bottom: 13px;
    margin-right: 10px;
    h3 {
      font-size: 18px;
      font-weight: 400;
      margin-right: auto;
    }
    .icon {
      margin-left: auto;
    }
  }

  .custom-filters {
    margin-top: 30px;
    padding-top: 30px;
    border-top: $border-1;
    .title-section {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      h3 {
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    border-bottom: $border-1;
    padding-bottom: 40px;
    .custom-filters-content {
      padding: 5px;
      background-color: #f5f5f5;
      border-radius: 10px;
      overflow: visible;
    }
  }
}

.basic-filters {
  margin-top: 50px;
}

.search-buttons {
  margin-top: 30px;
}
.filters {
  display: flex;
  width: 100%;
  margin-top: 40px;

  .filter {
    width: 100%;

    margin-right: 15px;

    .filter-label {
      display: flex;

      margin-bottom: 7px;
      .label {
        font-size: 13px;
        margin-right: auto;
        color: $ui-03;
        font-weight: 300;
      }
    }
  }
}

.results {
  margin-top: 30px;
  padding-bottom: 50px;

  .buttons {
    display: flex;
    width: 100%;

    .download-btn {
      font-size: 15px;
      margin-left: auto;
      .icon {
        margin-right: 10px !important;
      }
    }
  }

  .render-column {
    padding-left: 20px;
    padding-top: 8px;
  }
}
</style>

<style lang="scss">
.cheque-search {
  .datepicker-button {
    height: 47px;
  }

  .text-input {
    input {
      height: 47px;
    }
  }

  .results-table {
    font-size: 14px;
    border-radius: 4px;
    margin-top: 5px;

    table {
      border: $border-1 !important;
      border-radius: 4px;
    }

    tr {
      height: 50px;
    }

    .pagination-link.is-current {
      background-color: $primary !important;
    }
  }
}
</style>
