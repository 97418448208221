<template>
  <div class="company-object-ref-select">
    <template v-if="['edit'].includes(mode)">
      <singleselect
        v-model="vModel"
        class="select-input"
        :loading="isLoading"
        :options="Object.values(options)"
        label="name"
        track-by="key">
        <template #reload>
          <button
            class="button refresh-button"
            @click.prevent="refreshOptions">
            <i class="material-icons mr-2"> refresh </i>
            <span>Refresh Options</span>
          </button>
        </template>
      </singleselect>
      <button
        v-if="key"
        class="button open-selected"
        @click="openSelected">
        <i class="material-icons"> open_in_new </i>
      </button>
      <button
        v-if="key"
        class="button open-selected"
        @click="showVisualizer = true">
        <i class="material-icons"> visibility </i>
      </button>
    </template>
    <template v-if="['summary'].includes(mode)">
      {{ vModel.name }}
    </template>

    <b-modal
      v-model="showVisualizer"
      :can-cancel="['x', 'escape']"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      close-button-aria-label="Close"
      aria-modal>
      <form action="">
        <div class="modal-card select-module-modal">
          <div class="modal-body card">
            <visualize-object :data="vModel" />
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import singleselect from '@/components/ui/singleselect.vue'
  import visualizeObject from '@/components/reports/config/common/CompanyObjectVisualizer.vue'
  export default {
    name: 'CompanyObjectRefSelect',
    components: {
      singleselect,
      visualizeObject,
    },
    props: {
      placeholder: {
        type: String,
        required: true,
      },
      objectType: {
        type: String,
        required: true,
      },
      externalRoute: {
        type: String,
        required: true,
      },
      externalQuery: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        default: null,
      },
      mode: {
        type: String,
        default: 'edit',
      },
    },
    data () {
      return {
        showVisualizer: false,
      }
    },
    computed: {
      isLoading () {
        return this.$store.getters['app/reportsConfig/loading']
      },
      vModel: {
        get () {
          return this.options[this.key]
        },
        set (vModel) {
          this.$emit('input', {
            type: this.objectType,
            name: vModel.name,
            key: vModel.key,
          })
        },
      },
      key () {
        return this.value?.key
      },
    },
    methods: {
      refreshOptions () {
        this.$store.dispatch('app/reportsConfig/refreshOptions')
      },
      openSelected () {
        const routeData = this.$router.resolve({
          name: this.externalRoute,
          query: {
            ...this.externalQuery,
            key: this.key,
          },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
.company-object-ref-select {
  display: flex;
  flex-direction: row;
}

.select-input {
  width: 100%;
}

.open-selected {
  height: 45px !important;
  margin-left: 4px;

  .material-icons {
    font-size: 15px;
  }
}
.refresh-button {
  border: none;
  font-size: 9px;
  box-shadow: none;
  width: 100%;
  .material-icons {
    font-size: 14px;
  }

  span {
    font-size: 13px;
  }
}
</style>
