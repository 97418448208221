<template>
  <div class="drilldown-options dark-green-background-75">
    <div
      v-for="(column, idx) in columns.filter(column => column.type == 'group')"
      :key="idx"
      class="base-dimension">
      <div class="dimension">
        {{ column.dimension.name }}
      </div>
      <div
        v-for="(target, targetIdx) in getDrilldownHierarchy(column)"
        :key="target.dimension"
        class="target-option">
        <v-icon name="corner-down-right" />
        <div class="dimension">
          {{ $store.getters['metadata/dimensions'][target.dimension].name }}
          <b-button @click="removeTarget(idx, targetIdx)">
            <v-icon name="x" />
          </b-button>
        </div>
      </div>
      <ingest-dropdown
        v-if="getPotentialTargets(column).length > 0"
        :force-close="closeDropdown"
        class="ingest-green-text-75"
        hover-class="ingest-green-text medium"
        opens="left"
        align="center">
        <template slot="button-content">
          <v-icon
            name="plus-circle" />
          Add drilldown
        </template>
        <template slot="body-content">
          <div class="target-options">
            <div
              v-for="target in getPotentialTargets(column)"
              :key="target.name">
              <b-button
                :label="target.name"
                @click="addTarget(idx, target)" />
            </div>
          </div>
        </template>
      </ingest-dropdown>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import IngestDropdown from '@/components/common/IngestDropdown'

  export default {
    name: 'DrilldownOptions',
    components: {
      IngestDropdown,

    },
    props: {
      columns: { type: Array, required: true, },
      dimensionOptions: { type: Array, required: true, },
    },
    data () {
      return {
        closeDropdown: false,
      }
    },
    methods: {
      removeTarget (columnIdx, targetIdx) {
        let newColumn = { ...this.columns[columnIdx], }
        newColumn.drilldowns.splice(targetIdx, 1)
        let newColumns = [ ...this.columns, ]
        newColumns.splice(columnIdx, 1, newColumn)
        this.$emit('update:columns', newColumns)
      },
      getDrilldownHierarchy (column) {
        return column.drilldowns || []
      },
      addTarget (columnIdx, target) {
        this.closeDropdown = true
        Vue.nextTick(() => {
          this.closeDropdown = false
        })
        let newColumn = { drilldowns: [], ...this.columns[columnIdx], }
        newColumn.drilldowns.push({ dimension: target.key, })
        let newColumns = [ ...this.columns, ]
        newColumns.splice(columnIdx, 1, newColumn)
        this.$emit('update:columns', newColumns)
      },
      getPotentialTargets (column) {
        let all_dimensions = [column.dimension.key, ...this.getDrilldownHierarchy(column).map(drilldown => drilldown.dimension),]
        let dimension = all_dimensions[all_dimensions.length - 1]
        if (this.$store.getters['metadata/dimensions'][dimension].type != 'entity') {
          return []
        }
        return this.dimensionOptions.filter(option =>
          !option.descendants.includes(dimension) &&
          !all_dimensions.includes(option.key) &&
          option.groupable
        )
      },
    },
  }

</script>

<style lang="scss" scoped>

.drilldown-options {
  padding: 8px;
  border-radius: 8px;
}

.target-options {
  overflow: auto;
  height: 300px;
  background: $ingestOffwhite;
}

.target-option {
  display: flex;
}

.dimension {
  display: flex;
  margin: 4px;
  padding: 4px;
  background: $ingestGreen;
  border-radius: 6px;
}

.icon {
  width: 24px;
}

</style>
