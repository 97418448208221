<template>
  <div
    v-if="!!datum"
    class="hover-card">
    <div class="primary-values">
      <div class="dimension-label">
        {{ getDimensionVal(dimensions, datum) }}
      </div>
      <div
        v-if="!!category"
        class="category-label"
        :style="{'color': categoryColor(category.key)}">
        {{ category.value }}
      </div>
      <div
        class="primary-measure">
        {{ formatAggVal(primaryMeasure, primaryVal) }}
      </div>
      <div
        v-if="!!varianceMeasure && !isNaN(varianceVal)"
        class="variance">
        <span
          v-if="varianceVal === 0"
          class="material-icons variance-arrow">
          arrow_drop_up
        </span>
        <span
          v-if="varianceVal > 0"
          class="material-icons variance-arrow"
          :style="{'color': varianceColor}">
          arrow_drop_up
        </span>
        <span
          v-if="varianceVal < 0"
          class="material-icons variance-arrow"
          :style="{'color': varianceColor}">
          arrow_drop_down
        </span>
        {{ formatAggVal(varianceMeasure, varianceVal) }}
      </div>
    </div>
    <div
      v-if="!!secondaryMeasure && !isNaN(secondaryVal)"
      class="secondary-values">
      <div class="secondary-label">
        {{ secondaryMeasure.name }}
      </div>
      <div
        class="secondary-measure">
        {{ formatAggVal(secondaryMeasure, secondaryVal) }}
      </div>
    </div>
  </div>
</template>

<script>
  import helpers from '@/components/charting/helpers'
  import { dvPalette } from '@/components/charting/colors'

  export default {
    name: 'HoverCard',
    props: {
      columns: { type: Array, required: true, },
      datum: { type: Object, default: null, },
      category: { type: Object, default: null, },
      categoryColor: { type: Function, default: null, },
      betaSpec: { type: Object, default: null, },
    },
    computed: {
      varianceColor () {
        return this.specColor(this.varianceMeasure.colorSpec, this.datum)
      },
      dimensions () {
        return this.columns.filter(column => column.role == 'dimension' || column.role === 'primary_dimension')
          .map(column => ({ ...column, key: column.key, }))
      },
      aggregates () {
        return this.columns.filter(column => column.role != 'dimension')
          .map(column => ({ ...column, key: column.key, }))
      },
      primaryMeasure () {
        let candidates =  this.columns.filter(column => column.role == 'primary_measure')
        if (candidates.length > 0) {
          return candidates[0]
        }
        return null
      },
      primaryVal () {
        if (!!this.primaryMeasure && !!this.datum) {
          return this.datum[this.primaryMeasure.key] || 0
        }
        return 0
      },
      secondaryMeasure () {
        let candidates = this.columns.filter(column => column.role == 'secondary_measure')
        if (candidates.length > 0) {
          return candidates[0]
        }
        return null
      },
      secondaryVal () {
        if (!!this.secondaryMeasure && !!this.datum) {
          return this.datum[this.secondaryMeasure.key] || NaN
        }
        return NaN
      },
      varianceMeasure () {
        let candidates = this.columns.filter(column => column.role == 'variance_measure')
        if (candidates.length > 0) {
          return candidates[0]
        }
        if (!!this.betaSpec && !!this.betaSpec.variance_type && !!this.primaryMeasure && !!this.secondaryMeasure) {
          return {
            colorSpec: { type: this.betaSpec.variance_type, },
            data_type: this.primaryMeasure.data_type,
          }
        }
        return null
      },
      varianceVal () {
        if (!!this.varianceMeasure && !!this.varianceMeasure.key) {
          return this.datum[this.varianceMeasure.key] || NaN
        }
        if (!!this.varianceMeasure && !!this.datum) {
          return this.primaryVal - this.secondaryVal || 0
        }
        return 0
      },
    },
    methods: {
      ...helpers,
      specColor (spec, datum) {
        if (spec.type === 'manual') {
          return spec.value
        }
        if (spec.type === 'controlled') {
          return this.specColor(this.$store.getters['controls/debouncedSelections'][spec.control_id].colorSpec, datum)
        }
        if (datum !== null) {
          if (spec.type === 'over_under') {
            return parseFloat(datum[this.varianceMeasure.key]) >= 0
              ? dvPalette.dvGood
              : dvPalette.dvBad
          }
          if (spec.type === 'under_over') {
            return parseFloat(datum[this.varianceMeasure.key]) <= 0
              ? dvPalette.dvGood
              : dvPalette.dvBad
          }
        }
        return null
      },
    },
  }

</script>

<style lang="scss" scoped>

.hover-card {
  padding: 2 * $gridBase;
}

.primary-values, .secondary-values {
  padding: 2 * $gridBase;
}

.primary-values {
  font-weight: $emp;
  font-size: $h0;
}

.primary-value, .secondary-value {
  color: $secondary-07;
}

.secondary-values {
  border-top: 1px solid $secondary-03;
  padding: 2 * $gridBase;
  font-size: 1.6rem;
}

.dimension-label {
  color: $secondary-05;
  font-size: 1.6rem;
}

.secondary-label {
  color: $secondary-04;
  font-size: $h1;
}

.variance {
  font-weight: $default;
  color: $secondary-06;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}
.variance-arrow {
  font-size: $h0;
  transform: translateY(10%);
}
.category-label {
  font-weight: $emp;
  font-size: $h3;
}

</style>
