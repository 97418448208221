<template>
  <div
    class="grid-item"
    :style="style">
    <slot />
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'GridItem',
    props: {
      position: { type: Object, required: true, },
    },
    computed: {
      ...mapGetters('dashboards', [
        'cellHeight',
        'cellWidth',
      ]),
      style () {
        return {
          'left': `${this.position.x * this.cellWidth}px`,
          'top': `${this.position.y * this.cellHeight}px`,
          'width': `${this.position.w * this.cellWidth}px`,
          'height': `${this.position.h * this.cellHeight}px`,
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

.grid-item {
  position: absolute;
}

</style>
