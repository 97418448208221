<template>
  <div>
    <div v-if="long">
      {{ range }}
    </div>
    <div v-else>
      <span> {{ dimension.name }} </span>
      ({{ range }})
    </div>
  </div>
</template>

<script>

  import { formatDate, formatDifference } from '@/utils/Date'

  export default {
    name: 'DateFilterSummary',
    props: {
      customDimension: { type: Object, default: null, },
      filter: { type: Object, default: null, },
      long: { type: Boolean, default: false, },
    },
    computed: {
      dimension () {
        return this.customDimension
      },
      range () {
        let start = new Date(this.filter.args.start)
        start.setDate(start.getDate() - 1)
        if (this.long) {
          return `${formatDate(this.filter.args.start)} – ${formatDate(this.filter.args.end)}`
        } else {
          return `${formatDifference(start, this.filter.args.end)}`
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
