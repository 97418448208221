<template>
  <droppable-field
    class="measure-aggregate"
    target-selector="measure"
    @start="$emit('start', measureAggregate)"
    @end="$emit('end')">
    <div class="field">
      <div class="field-container">
        <div class="measure-label">
          {{ measureAggregate.measure.name }}
        </div>
      </div>
      <ingest-dropdown
        v-if="dimensionOptions.length > 0"
        class="light-green-text agg-menu"
        :force-close="closeDropdowns"
        :label="measureAggregate.type == 'sum' ? '\u03A3' : 'Avg'"
        right-align>
        <template slot="body-content">
          <b-card class="aggregate-card">
            <div class="aggregation-option">
              <b-button
                class="dark-green-text"
                :label="'\u03A3'"
                @click="updateAggregate({type: 'sum', measure: measureAggregate.measure})" />
            </div>
            <div
              class="aggregation-option">
              <ingest-dropdown
                variant="None"
                class="dark-green-text"
                label="Avg per"
                right-align
                :force-close="closeDropdowns">
                <div
                  slot="body-content"
                  class="avg-options flex-column">
                  <b-button
                    v-for="dimension in (dimensionOptions || [])
                      .filter(dimension => dimension.countable)"
                    :key="dimension.key"
                    :label="dimension.name"
                    class="dark-green-text"
                    @click="setAvgPerDimension(dimension)" />
                </div>
              </ingest-dropdown>
            </div>
          </b-card>
        </template>
      </ingest-dropdown>
    </div>
  </droppable-field>
</template>

<script>

  import DroppableField from '@/components/module/edit/DroppableField'
  import { directive as onClickaway } from 'vue-clickaway'
  import IngestDropdown from '@/components/common/IngestDropdown'

  import Vue from 'vue'

  export default {
    name: 'MeasureAggregate',
    directives: {
      onClickaway,
    },
    components: {
      DroppableField,
      IngestDropdown,

    },
    props: {
      measureAggregate: { type: Object, required: true, },
      dimensionOptions: { type: Array, default: Array, },
    },
    data () {
      return {
        closeDropdowns: false,
      }
    },
    methods: {
      setAvgPerDimension (dimension) {
        this.updateAggregate({
          type: 'avg',
          measure: this.measureAggregate.measure,
          dimension: dimension,
        })
      },
      updateAggregate (aggregate) {
        this.closeDropdowns = true
        Vue.nextTick(() => {
          this.closeDropdowns = false
        })
        this.$emit('update:measureAggregate', aggregate)
      },
    },
  }

</script>

<style lang="scss" scoped>

.measure-aggregate {
  margin: 8px;
  transition: min-width .3 ease;
  min-width: 0;
  width: 144px;

  &:hover, &.open {
    min-width: 144px;
  }
}

.ingest-dropdown {
  border-left: solid 1px white;
  min-width: 8 * $remGrid;
}

p {
  margin: 0;
}

.field {
  background:  #85BAE6 !important;
  box-shadow: 0px 3px 6px 0px rgba(133, 186, 230, .5);
  flex-grow: 1;
  display: flex;
  border-radius: 8px !important;
}

.field-container {
  flex-grow: 1;
  display: flex;
}

.measure-label {
  flex-grow: 1;
  padding: 8px;
  color: $lightGreen;

  &::first-letter {
    text-transform: uppercase;
  }
}

.aggregate-menu {
  z-index: 10;
  position: absolute;
  background: $backGround0;
  padding: 0;
  top: 100%;
  right: 0;
  width: 100%;

  .card-body {
    padding: 0;
  }

  .card {
    width: 100%;
  }
}

.avg-expand {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.avg-option {
  text-align: left;
}

.avg-per-menu {
  display: flex;
  flex-direction: column;
}

.aggregation-option {
  width: 100%;
  text-align: left;
}

.avg-options {
  padding: 16px;
  background: $white;
  height: 300px;
  overflow: auto;
}

.aggregate-card {
}

.agg-menu {
  padding-left: $gridBase;
}

</style>
