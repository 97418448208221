<template>
  <div
    :key="control.id"
    class="offset-select">
    <div class="input-label">
      {{ control.name }}
    </div>
    <b-field>
      <b-dropdown
        v-model="selection"
        aria-role="list">
        <template #trigger="{ active }">
          <button
            class="button dropdown-button"
            type="is-light">
            <div class="option-selected-label">
              {{ !!selection ? selection.value : "" }}
            </div>

            <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
          </button>
        </template>

        <b-dropdown-item
          v-for="option in control.spec.options"
          :key="option.key"
          :value="option"
          aria-role="listitem">
          {{ option.value }}
        </b-dropdown-item>
      </b-dropdown>
    </b-field>
  </div>
</template>

<script>
  import controlSelectorMixin from '@/components/controls/controlSelectorMixin'

  export default {
    name: 'OffsetSelect',
    components: {},
    mixins: [controlSelectorMixin,],
  }
</script>

<style lang="scss" scoped>
.offset-select {
  display: flex;
  margin-left: 14px;
}

.input-label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 13px;
}

.dropdown-button {
  width: 250px;
  padding: 0 15px 0 10px !important;
  border: $border-1 !important;

  .option-selected-label {
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    text-align: left;
    font-size: 14px;
  }
}
</style>
