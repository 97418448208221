<template>
  <div class="droppable-field">
    <droppable
      handle=".field-container"
      :enabled="enabled"
      :class="[targetSelector]"
      @start="$emit('start')"
      @end="$emit('end')">
      <slot />
    </droppable>
  </div>
</template>

<script>

  import Droppable from '@/components/interactable/Droppable'

  export default {
    name: 'DroppableField',
    components: {
      Droppable,
    },
    props: {
      targetSelector: { type: String, required: true, },
      enabled: {
        type: Boolean,
        default: true,
      },
    },
  }

</script>

<style lang="scss" scoped>

.droppable {
  display: flex;
  border: none;
  overflow: visible;

  &.dragging {
    width: calc(20vw + -64px);
  }
}

</style>
