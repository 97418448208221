<template>
  <div
    class="category-legend">
    <template v-for="(category, idx) in categories">
      <div
        :key="`legend-${idx}`"
        class="legend-row"
        max-width="128px"
        @click="$emit('click', category)">
        <div
          class="legend-item"
          :style="{
            'background-color': hiddenCategories.includes(category.key) ? undefined : categoryColor(category.key),
            'border': `solid 1px ${categoryColor(category.key)}`,
            'box-shadow': hiddenCategories.includes(category.key) ? undefined : `0 0 3px ${categoryColor(category.key)}`,
          }" />
        {{ category.value }}
      </div>
    </template>
  </div>
</template>

<script>

  import chartComponentMixin from '@/components/charting/chart_component_mixin'

  export default {
    name: 'CategoryLegend',
    mixins: [
      chartComponentMixin,
    ],
    props: {
      categories: { type: Array, required: true, },
      categoryColor: { type: Function, required: true, },
    },
  }

</script>

<style lang="scss" scoped>

.category-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 6 * $gridBase;
  padding-bottom: 6 * $gridBase;
  font-size: 1.6rem;
  max-height: 48 * $gridBase;
  overflow: auto;
}

.legend-item {
  display: inline-block;
  min-width: 4 * $gridBase;
  width: 4 * $gridBase;
  height: 4 * $gridBase;
  margin: 2*$gridBase;
  border-radius: 50%;
}

.legend-row {
  cursor: pointer;
  align-items: center;
  display: flex;
  margin-left: 4*$gridBase;
}

</style>

