<template>
  <div
    ref="droppable"
    class="droppable"
    :class="{dragging}"
    :style="style">
    <slot />
  </div>
</template>

<script>

  export default {
    name: 'DroppableComponent',
    props: {
      dropZoneType: { type: String, default: null, },
      handle: { type: String, required: true, },
      enabled: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        x: 0,
        y: 0,
        dragging: false,
      }
    },
    computed: {
      style () {
        return {
          'position': this.dragging ? 'fixed' : 'relative',
          'left': this.dragging ? `${this.x}px` : '0',
          'top': this.dragging ? `${this.y}px` : '0',
        }
      },
    },
    watch: {
      enabled (value) {
        if (value) {
          this.unsetInteractable()
          this.setInteractable()
        }
      },
    },
    mounted () {
      this.setInteractable()
    },
    destroyed () {
      this.unsetInteractable()
    },
    methods: {
      unsetInteractable () {
        if (this.interactable) {
          this.interactable.unset()
          this.interactable = undefined
        }
      },
      setInteractable () {
        if (!this.enabled) {
          return
        }
        this.interactable = this.$interact(this.$el)
          .draggable({
            autoScroll: true,
            onstart: this.onStart,
            onend: this.onEnd,
            onmove: this.onMove,
            allowFrom: this.handle,
          })
      },
      onStart (event) {
        this.$emit('start')
        this.x = event.client.x - event.page.x + event.rect.left
        this.y = event.client.y - event.page.y + event.rect.top
        this.w = event.rect.width
        this.h = event.rect.height
        this.dragging = true
      },
      onEnd () {
        this.dragging = false
        this.$emit('end')
        this.x = this.y = this.w = this.h = 0
      },
      onMove (event) {
        this.x = event.client.x - event.page.x + event.rect.left
        this.y = event.client.y - event.page.y + event.rect.top
        this.$emit('move', this.$el.getBoundingClientRect())
      },
    },
  }
</script>

<style lang="scss" scoped>

.droppable {
  touch-action: none;

  &.dragging {
    z-index: 10;
  }
}

</style>
