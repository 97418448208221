<template>
  <div class="ingest-table">
    <busy-overlay
      :enabled="busy"
      :loading="loading"
      :message="busyMessage" />
    <div class="overflow-container">
      <table>
        <template v-if="pivotLabels.length > 0">
          <thead height="104px">
            <tr height="52px">
              <th
                v-if="dimensions.length > 0"
                :colspan="dimensions.length" />
              <th
                v-if="pivotTotals.length > 0"
                :colspan="pivotTotals.length" />
              <th
                v-for="pivotValue in pivotValues"
                :key="pivotValue.key"
                :colspan="measures.length"
                class="pivot-header">
                <div class="header-div pivot-header">
                  {{ pivotValue.label }}
                </div>
              </th>
            </tr>
            <tr
              class="second-header"
              height="52px">
              <th
                v-for="dimension in dimensions"
                :key="`dimension-${dimension.key}`"
                :style="{
                  width: !!dimension.width ? `${dimension.width}px` : 'initial',
                }"
                class="clickable nowrap ellipsis"
                @click="
                  $emit('headerClick', dimension);
                  pivotSort = null;
                ">
                <div class="header-div">
                  {{ dimension.name }}
                  <i
                    v-if="dimension.sortDir == 'desc'"
                    class="sort-icon material-icons">
                    expand_more
                  </i>
                  <i
                    v-if="dimension.sortDir == 'asc'"
                    class="sort-icon material-icons">expand_less
                  </i>
                </div>
              </th>
              <th
                v-for="pivotTotal in pivotTotals"
                :key="`pivotTotal-${pivotTotal.key}`"
                :style="{
                  width: !!pivotTotal.width
                    ? `${pivotTotal.width}px`
                    : 'initial',
                }"
                class="clickable nowrap ellipsis"
                @click="
                  $emit('headerClick', pivotTotal);
                  pivotSort = null;
                ">
                <div class="header-div total-header">
                  {{ pivotTotal.name }}
                  <i
                    v-if="pivotTotal.sortDir == 'desc'"
                    class="sort-icon material-icons">
                    expand_more</i>
                  <i
                    v-if="pivotTotal.sortDir == 'asc'"
                    class="sort-icon material-icons">expand_less</i>
                </div>
              </th>
              <template v-for="pivot in pivotValues">
                <th
                  v-for="measure in measures"
                  :key="`pivot-${pivot.key}-measure-${measure.key}`"
                  :style="{
                    width: !!measure.width ? `${measure.width}px` : 'initial',
                  }"
                  class="clickable nowrap ellipsis"
                  @click="
                    $emit('headerClick', measure);
                    pivotSort = pivot;
                  ">
                  <div class="header-div">
                    {{ measure.name }}
                    <i
                      v-if="
                        measure.sortDir == 'desc' &&
                          !!pivotSort &&
                          pivotSort.key == pivot.key
                      "
                      class="sort-icon material-icons">expand_more</i>
                    <i
                      v-if="
                        measure.sortDir == 'asc' &&
                          !!pivotSort &&
                          pivotSort.key == pivot.key
                      "
                      class="sort-icon material-icons">expand_less</i>
                  </div>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template v-else>
          <thead height="52px">
            <th
              v-for="field in fields"
              :key="field.key"
              :style="{ width: !!field.width ? `${field.width}px` : 'initial' }"
              class="clickable nowrap ellipsis"
              @click="
                $emit('headerClick', field);
                pivotSort = null;
              ">
              <div class="header-div">
                {{ field.name }}
                <i
                  v-if="field.sortDir == 'desc'"
                  class="sort-icon material-icons">
                  expand_more
                </i>
                <i
                  v-if="field.sortDir == 'asc'"
                  class="sort-icon material-icons">expand_less</i>
              </div>
            </th>
          </thead>
        </template>
        <tbody table>
          <template v-if="pivotLabels.length > 0">
            <template v-for="(row, idx) in pivotedRows">
              <tr
                :key="idx"
                height="52px"
                :class="{ active: row.active, clickable: row.clickable }"
                @click="$emit('rowClick', row)">
                <template v-for="(dimension, dimensionIdx) in dimensions">
                  <slot
                    :name="dimension.slot"
                    v-bind="{ field: dimension, row }">
                    <data-field
                      :key="`dimension-${dimension.key}`"
                      :field="dimension"
                      :value="row[dimension.key]"
                      :all-fields="fields"
                      :row="row"
                      :style="{
                        width: !!dimension.width
                          ? `${dimension.width}px`
                          : 'initial',
                      }"
                      @click.native="
                        $emit('dataClick', {
                          row,
                          field: dimension,
                          rowIdx: idx,
                          dimensionIdx,
                        })
                      " />
                  </slot>
                </template>
                <template v-for="(pivotTotal, pivotTotalIdx) in pivotTotals">
                  <slot
                    :name="pivotTotal.slot"
                    v-bind="{ field: pivotTotal, row }"
                    s-tot>
                    <data-field
                      :key="`pivotTotal-${pivotTotal.key}`"
                      :field="pivotTotal"
                      :value="row[pivotTotal.key]"
                      :all-fields="fields"
                      :row="row"
                      :style="{
                        width: !!pivotTotal.width
                          ? `${pivotTotal.width}px`
                          : 'initial',
                      }"
                      is-total
                      @click.native="
                        $emit('dataClick', {
                          row,
                          field: pivotTotal,
                          rowIdx: idx,
                          pivotTotalIdx,
                        })
                      " />
                  </slot>
                </template>
                <template v-for="pivot in pivotValues">
                  <template v-for="(measure, measureIdx) in measures">
                    <slot
                      :name="measure.slot"
                      v-bind="{ field: measure, row }">
                      <data-field
                        :key="`pivot-${pivot.key}-measure-${measure.key}`"
                        :field="measure"
                        :value="
                          ((row.pivots[pivot.key] || {})[0] || {})[measure.key]
                        "
                        :all-fields="fields"
                        :row="(row.pivots[pivot.key] || {})[0] || {}"
                        :style="{
                          width: !!measure.width
                            ? `${measure.width}px`
                            : 'initial',
                        }"
                        @click.native="
                          $emit('dataClick', {
                            row,
                            field: measure,
                            rowIdx: idx,
                            measureIdx,
                          })
                        " />
                    </slot>
                  </template>
                </template>
              </tr>
            </template>
          </template>
          <template v-else>
            <template v-for="(row, idx) in rows">
              <tr
                :key="idx"
                height="52px"
                :class="{ active: row.active, clickable: row.clickable }"
                @click="$emit('rowClick', row)">
                <template v-for="(field, fieldIdx) in fields">
                  <slot
                    :name="field.slot"
                    v-bind="{ field, row }">
                    <data-field
                      :key="field.key"
                      :field="field"
                      :value="row[field.key]"
                      :all-fields="fields"
                      :row="row"
                      :style="{
                        width: !!field.width ? `${field.width}px` : 'initial',
                      }"
                      @click.native="
                        $emit('dataClick', {
                          row,
                          field,
                          rowIdx: idx,
                          fieldIdx,
                        })
                      " />
                  </slot>
                </template>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import DataField from '@/components/common/table/DataField'
  import BusyOverlay from '@/components/common/BusyOverlay'

  import { uniqBy, orderBy, groupBy, min } from 'lodash'

  import helpers from '@/components/charting/helpers'

  export default {
    name: 'IngestTable',
    components: {
      DataField,
      BusyOverlay,
    },
    props: {
      busy: Boolean,
      loading: Boolean,
      busyMessage: { type: String, default: '', },
      fields: { type: Array, default: Array, },
      rows: { type: Array, default: Array, },
    },
    data () {
      return {
        pivotSort: null,
      }
    },
    computed: {
      dimensions () {
        return this.fields.filter((f) =>
          [
            'primary_dimension',
            'primary_dimension_key',
            'secondary_dimension',
            'secondary_dimension_key',
          ].includes(f.role)
        )
      },
      measures () {
        return this.fields.filter((f) =>
          ['primary_measure', 'secondary_measure', 'variance_measure',].includes(
            f.role
          )
        )
      },
      pivotTotals () {
        return this.fields.filter((f) =>
          [
            'primary_pivot_total',
            'secondary_pivot_total',
            'variance_pivot_total',
          ].includes(f.role)
        )
      },
      pivotKeys () {
        return this.fields.filter((f) => f.role == 'pivot_dimension_key')
      },
      pivotLabels () {
        return this.fields.filter((f) => f.role == 'pivot_dimension')
      },
      pivotOrders () {
        return [].concat(
          this.fields.filter((f) => f.role == 'pivot_dimension_order'),
          this.pivotLabels
        )
      },
      pivotValues () {
        return orderBy(
          uniqBy(
            this.rows.map((row) => ({
              key: helpers.getDimensionVal(this.pivotKeys, row),
              label: helpers.getDimensionVal(this.pivotLabels, row),
              order: helpers.getDimensionVal(this.pivotOrders, row),
            })),
            (pivot) => pivot.key
          ),
          'order'
        )
      },
      pivotedRows () {
        let rows = this.rows
        rows = rows.map((row, idx) => {
          return { ...row, pivotRank: idx, }
        })
        rows = Object.values(
          groupBy(rows, (row) => helpers.getDimensionVal(this.dimensions, row))
        ).map((group) => {
          let pivotedGroup = {}
          this.dimensions.forEach((d) => {
            pivotedGroup[d.key] = group[0][d.key]
          })
          this.pivotTotals.forEach((d) => {
            pivotedGroup[d.key] = group[0][d.key]
          })
          pivotedGroup.pivots = groupBy(group, (row) =>
            helpers.getDimensionVal(this.pivotKeys, row)
          )
          return pivotedGroup
        })

        if (this.pivotSort) {
          rows = orderBy(rows, (row) => {
            return ((row.pivots[this.pivotSort.key] || {})[0] || {}).pivotRank
          })
        } else {
          rows = orderBy(rows, (row) => {
            return min(Object.values(row.pivots).map((r) => r[0])).pivotRank
          })
        }
        return rows
      },
    },
  }
</script>

<style lang="scss" scoped>
.ingest-table {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.overflow-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}
table {
  width: 100%;
  background: $secondary-01;
}
th {
  padding: 0 !important;
  flex-grow: 1;
  position: sticky;
  top: 0;
  height: 52px;
  background-color: $secondary-01;
  vertical-align: bottom;
}
.second-header > th {
  top: 52px;
}
.header-div {
  font: $h3-dw-sl;
  color: $ui-03;

  display: flex;
  align-items: center;
  box-shadow: 0 3px 3px -3px $secondary-01;
  margin: 0 -3px;
  padding: 12px;
  text-align: center;
  height: 100%;

  &.pivot-header {
    font: $h3-ew-sl;
    color: $ui-02;
    justify-content: flex-start;
    border-bottom: 1px solid $ui-06;
    padding: 2 * $gridBase 3 * $gridBase;
  }

  &.total-header {
    font: $h3-dw-sl;
    color: $ui-02;
  }
}
th.pivot-header {
  padding: 0 $gridBase !important;
}
tbody {
  max-height: 100%;
}
table,
thead,
th,
tr {
  z-index: 1;
  border-collapse: collapse;
  border: none !important;
}
td {
  padding: 12px;
  text-align: right;
  vertical-align: middle !important;
}
th.clickable {
  cursor: pointer;
}
tr.clickable {
  cursor: pointer;
}
tr {
  background: $secondary-01;

  &:hover {
    background: $secondary-02;

    &.active {
      color: $secondary-02;
      background: $secondary-04;
    }
  }
}
tr.active {
  background: $secondary-04;
  color: $secondary-02;
}
.sort-icon {
  margin: 2 * $gridBase;
  font-size: 12px;
}
</style>
