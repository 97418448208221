<template>
  <RecycleScroller
    v-slot="{ item }"
    class="single-select"
    :class="{ rounded }"
    :items="options"
    :item-size="itemSize"
    :key-field="keyField">
    <slot
      :option="item"
      name="option">
      <single-select-item
        :selected="isSelected(item)"
        :rounded="roundItems"
        :round-top="rounded && false"
        :round-bottom="rounded && false"
        :disabled="disabled || !!item.disabled"
        :show-check="showCheck"
        :color="color"
        :size="size"
        @update:selected="onSelect(item)">
        <template #label>
          <slot
            name="label"
            :item="item">
            {{ item.label }}
          </slot>
        </template>
        <template #icon>
          <slot
            name="icon"
            :item="item">
            <i
              v-if="!!item.icon"
              class="material-icons"> {{ item.icon }} </i>
          </slot>
        </template>
      </single-select-item>
    </slot>
  </RecycleScroller>
</template>

<script>
  export default {
    name: 'SingleSelectOptimized',
    props: {
      options: { type: Array, default: Array, },
      value: { type: Object, default: null, },
      keyField: { type: String, default: null, },
      color: { type: String, default: 'ui-neutral', },
      size: { type: String, default: 'h3', },
      itemSize: { type: Number, default: 45, },
      rounded: { type: Boolean, default: true, },
      roundItems: { type: Boolean, default: false, },
      disabled: { type: Boolean, default: false, },
      showCheck: { type: Boolean, default: false, },
    },
    methods: {
      isSelected (item) {
        if (this.value == null) {
          return false
        } else if (this.keyField == null) {
          return this.value == item
        } else {
          return this.value[this.keyField] == item[this.keyField]
        }
      },
      onSelect (value) {
        if (!this.disabled) {
          this.$emit('input', value)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.single-select {
  display: flex;
  flex-direction: column;

  &.rounded {
    border-radius: 2 * $gridBase;
  }
}
</style>
