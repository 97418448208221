<template>
  <div class="roles-library">
    <div class="container">
      <div class="header">
        <h2>Roles</h2>
        <p>Manage user roles and permissions</p>
        <div class="control-bar">
          <div class="left">
            <router-link
              :to="{
                name: 'create_role',
                params: { role_id: 'new' },
              }">
              <b-button
                type="is-primary"
                class="action-btn"
                icon-left="plus">
                Create Role
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <div class="columns is-vcentered table-filters">
          <div class="column m-0 p-0 mr-4">
            <b-input
              v-model="searchInput"
              custom-class="no-border"
              placeholder="Search..."
              type="search"
              icon="magnify" />
          </div>
        </div>
        <b-table
          :data="resultsToShow"
          :loading="isLoading"
          class="users-table">
          <b-table-column
            v-slot="props"
            sortable
            field="name"
            label="Name">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="description"
            label="Description">
            {{ props.row.description }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="permissions"
            label="Permissions">
            <b-dropdown
              :triggers="['hover']"
              aria-role="list">
              <template #trigger>
                <b-button
                  class="location-dropdown-button unclickable"
                  type="is-light"
                  icon-right="menu-down">
                  <div>
                    {{ props.row.permission_groups.length || "No" }}
                    Permissions
                  </div>
                </b-button>
              </template>
              <b-dropdown-item
                v-for="(permission, i) in props.row.permission_groups"
                :key="i"
                disabled
                :focusable="false"
                class="unclickable"
                aria-role="listitem">
                {{ permission.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="reports"
            label="Reports">
            <b-dropdown
              :triggers="['hover']"
              aria-role="list">
              <template #trigger>
                <b-button
                  class="location-dropdown-button unclickable"
                  type="is-light"
                  icon-right="menu-down">
                  <div>{{ (props.row.reports.length + props.row.dashboard_reports.length)  || "No" }} Reports</div>
                </b-button>
              </template>
              <b-dropdown-item
                v-for="(report, i) in [].concat(props.row.reports, props.row.dashboard_reports)"
                :key="i"
                disabled
                :focusable="false"
                class="unclickable"
                aria-role="listitem">
                {{ report.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>

          <b-table-column
            v-slot="props"
            sortable
            field="company_users"
            label="Users">
            {{ props.row.company_users.length || "No" }} Users
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="Actions"
            centered
            width="100"
            label="Actions">
            <div class="actions">
              <b-tooltip
                label="Delete Role"
                position="is-bottom"
                type="is-light">
                <b-button
                  type="is-light"
                  class="action-btn"
                  icon-right="delete"
                  @click="deleteRole(props.row)" />
              </b-tooltip>
              <b-tooltip
                label="Edit Role"
                position="is-bottom"
                type="is-light">
                <router-link
                  :to="{
                    name: 'edit_role',
                    params: { role_id: props.row.id },
                  }">
                  <b-button
                    type="is-light"
                    class="action-btn"
                    icon-right="lead-pencil" />
                </router-link>
              </b-tooltip>
            </div>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered mt-5 mb-5">
              <b-tag
                v-if="!isLoading"
                class="data-tags"
                rounded
                size="is-medium">
                No Users Found 🥥
              </b-tag>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { roleService } from '@/service/admin/roleService'
  import FuzzySearch from 'fuzzy-search'

  export default {
    name: 'UsersEditor',
    components: {},

    data () {
      return {
        roles: [],
        searchInput: '',
        isLoading: false,
      }
    },

    computed: {
      searcher () {
        const searcher = new FuzzySearch(this.roles, ['name',])
        return searcher
      },
      resultsToShow () {
        if (this.searchInput) {
          return this.searcher.search(this.searchInput)
        }
        return this.roles
      },
    },
    mounted () {
      this.$store.dispatch(
        'metadata/loadMetadata',
        this.$route.params.company_id
      )
      this.loadMeta()
    },
    methods: {
      async loadMeta () {
        this.isLoading = true

        let companyId = this.$route.params.company_id
        this.roles = await roleService.loadRoles(companyId)

        this.isLoading = false
      },

      async deleteRole (role) {
        this.$buefy.dialog.confirm({
          title: 'Delete Role',
          container: '.roles-library',
          message: `Are you sure you want to delete ${role.name}`,
          confirmText: 'Delete Role',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true
            await roleService.deleteRole(this.$route.params.company_id, role.id)
            this.loadMeta()
            this.isLoading = false

          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 20px 14px;

  .header {
    margin-top: 30px;
    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    p {
      color: $ui-03;
      font-size: 16px;
      margin-top: 14px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
      margin-top: 50px;
    }

    .right {
      margin-left: auto;
    }
  }

  .users-table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
  }

  .table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .data-tags {
    font-size: 12px;
    margin: 2px;
  }

  .location-dropdown-button {
    font-size: 14px;
  }

  .actions {
    display: flex;
    justify-content: center;

    .action-btn {
      margin: 0 5px;
    }
  }
}
</style>
