<template>
  <div class="pie-view">
    <div class="chart-container">
      <ingest-tooltip
        :left="tooltipX"
        :top="tooltipY"
        :enabled="tooltipEnabled">
        <div
          v-if="!!tooltipDatum"
          class="pad-8-all">
          <div class="tooltip-header light-green-text title">
            {{ getDimensionVal(baseDimension, tooltipDatum) }}
          </div>
          <div class="agg-values light-green-background round-4-all">
            <div
              v-for="(agg, idx) in aggregates"
              :key="`legend-${idx}`"
              class="legend-row flex-row"
              max-width="128px">
              {{ formatAggVal(agg, tooltipDatum[agg.key]) }}
            </div>
          </div>
          <div
            v-if="!!baseDimension && !!baseDimension.drilldown"
            class="pad-8-top dark-green-text">
            Drilldown: {{ baseDimension.drilldown.name }}
          </div>
        </div>
      </ingest-tooltip>
      <svg
        viewBox="0 0 100 100">
        <defs>
          <filter
            v-for="(datum, i) in pieModel"
            :id="`dropShadow-${i}`"
            :key="`filter-${i}`">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation=".1"
              :flood-color="colorScale(i)" />
          </filter>
        </defs>
        <g>
          <path
            v-for="(datum, i) in pieModel"
            :key="`arc-${i}`"
            :flood-color="colorScale(i)"
            :style="{'stroke': colorScale(i), 'stroke-width': 0, 'fill': colorScale(i), 'cursor': !!baseDimension && !!baseDimension.drilldown ? 'pointer' : 'default'}"

            :filter="`url(#dropShadow-${i})`"
            :d="arcModel(datum)"
            transform="translate(50, 50)"
            @click="$emit('click', {dimensionIndex: 0, value: datum.data[baseDimension.key]})"
            @mousemove="onHover($event, datum)"
            @mouseleave="onLeave()" />
        </g>
      </svg>
    </div>
    <div
      v-if="aggregates.length > 0"
      class="legend flex-column">
      <div
        v-for="(datum, idx) in pieModel"
        :key="`legend-${idx}`"
        class="legend-row flex-row pad-4-all"
        max-width="128px">
        <div
          class="legend-item"
          :style="{'background-color': colorScale(idx)}" />
        {{ getDimensionVal(baseDimension, datum.data) }}
      </div>
    </div>
  </div>
</template>
<script>

  import * as d3  from 'd3'
  import IngestTooltip from '@/components/common/IngestTooltip.vue'
  import { formatAmount, formatNumber } from '@/utils/Format.js'

  export default {
    name: 'PieView',
    components: {
      IngestTooltip,
    },
    props: {
      loading: Boolean,
      columns: { type: Array, required: true, },
      data: { type: Array, default: Array, },
    },
    data () {
      return {
        chartType: 'pie',
        tooltipX: 0,
        tooltipY: 0,
        tooltipEnabled: false,
        tooltipDatum: null,
      }
    },
    computed: {
      dimensions () {
        return this.columns.filter(column => column.expression.type == 'group')
          .map(column => ({ ...column, key: column.idx, }))
      },
      aggregates () {
        return this.columns.filter(column => column.expression.type != 'group')
          .map(column => ({ ...column, key: column.idx, }))
      },
      colorScale () {
        return d3.scaleLinear().domain([0, d3.max([1, this.data.length - 1,]),])
          .interpolate(d3.interpolateHcl)
          .range([d3.rgb('#2a3533'), d3.rgb('#6ecbb6'),])
      },
      pieModel () {
        return d3.pie()
          .sort(null)
          .value((datum) => datum[this.aggregates[0].key])(this.data)
      },
      arcModel () {
        return d3.arc()
          .innerRadius(0)
          .outerRadius(50)
      },
      baseDimension () {
        if (this.dimensions.length == 0) {
          return null
        }
        return this.dimensions[0]
      },
    },
    watch: {
      dimensions () {
        this.tooltipEnabled = false
        this.tooltipDatum = null
      },
    },
    methods: {
      onHover (event, datum) {
        this.tooltipX = event.clientX
        this.tooltipY = event.clientY
        this.tooltipEnabled = true
        this.tooltipDatum = datum.data
      },
      onLeave () {
        this.tooltipEnabled = false
      },
      getDimensionVal (dimension, datum) {
        if (dimension == null) {
          return null
        }
        if (dimension.type == 'entity') {
          return datum[dimension.key].name
        }
        return datum[dimension.key]
      },
      getDimensionKey (dimension, datum) {
        if (dimension == null) {
          return null
        }
        if (dimension.type == 'entity') {
          return datum[dimension.key].key
        }
        return datum[dimension.key]
      },
      formatAggVal (aggregate, val) {
        if (!aggregate) {
          return ''
        }
        if (aggregate.type == 'money') {
          return formatAmount(val)
        } else {
          return formatNumber(val)
        }
      },
      getAggHeight (aggregate, datum) {
        return this.yScale(datum[aggregate.key])
      },
    },
  }

</script>

<style lang="scss" scoped>

.pie-view {
  display: flex;
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  padding: 8 * $gridBase 4 * $gridBase 24 * $gridBase 32 * $gridBase;

  > .chart-container {
    flex-basis: 0;
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;

    > svg {
      width: 100%;
      height: 100%;
    }
  }
}

.legend {
  max-width: 32 * $gridBase;
}

.legend-item {
  display: inline-block;
  min-width: 4 * $gridBase;
  width: 4 * $gridBase;
  height: 4 * $gridBase;
  margin: 2 * $gridBase;
}

.legend-row {
  align-items: center;
}

</style>
