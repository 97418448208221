<template>
  <div class="html-component">
    <iframe :srcdoc="content" />
  </div>
</template>

<script>
  export default {
    name: 'HtmlModule',
    props: {
      content: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="scss" scoped>

.html-component {
}

iframe {
   width: 100%;
}

</style>
