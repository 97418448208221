import SectionDividerView from '@/components/module/view/SectionDividerView'
import SectionEdit from '@/components/module/edit/SectionEdit'
import TableView from '@/components/module/view/TableView'
import TableViewV2 from '@/components/module/view/TableViewV2'
import TableEdit from '@/components/module/edit/TableEdit'
import TableIcon from '@/components/module/view/TableIcon'
import PerformanceColumnView from '@/components/module/view/PerformanceColumnView'
import VarianceColumnView from '@/components/module/view/VarianceColumnView'
import ScorecardView from '@/components/module/view/ScorecardView'
import BarIcon from '@/components/module/view/BarIcon'
import CategoryLineView from '@/components/module/view/CategoryLineView'
import MeasureLineView from '@/components/module/view/MeasureLineView'
import LineIcon from '@/components/module/view/LineIcon'
import RadarView from '@/components/module/view/RadarView'
import RadarIcon from '@/components/module/view/RadarIcon'
import PieView from '@/components/module/view/PieView'
import PieIcon from '@/components/module/view/PieIcon'
import CartesianEdit from '@/components/module/edit/CartesianEdit'
import Vue from 'vue'

export const ingestViews = {
  'section-divider-view': {
    name: 'section-divider',
    component: SectionDividerView,
    editor: SectionEdit,
    limits: {
      fields: { min: 1, },
    },
    icon: TableIcon,
  },
  'table-view': {
    name: 'table',
    component: TableView,
    editor: TableEdit,
    limits: {
      fields: { min: 1, },
    },
    icon: TableIcon,
  },
  'table-view-v2': {
    name: 'table',
    component: TableViewV2,
    editor: TableEdit,
    limits: {
      fields: { min: 1, },
    },
    icon: TableIcon,
  },
  'scorecard-view': {
    name: 'kpi',
    component: ScorecardView,
    editor: TableEdit,
    limits: {
      dimensions: { min: 0, max: 0, },
      measures: { min: 1, max: 1, },
    },
    icon: BarIcon,
  },
  'bar-view': {
    name: 'bar chart',
    component: PerformanceColumnView,
    editor: CartesianEdit,
    limits: {
      dimensions: { min: 1, max: 1, },
      measures: { min: 1, max: 1, },
    },
    icon: BarIcon,
  },
  'performance-column-view': {
    name: 'performance column chart',
    component: PerformanceColumnView,
    editor: CartesianEdit,
    limits: {
      dimensions: { min: 1, max: 1, },
      measures: { min: 1, max: 1, },
    },
    icon: BarIcon,
  },
  'variance-column-view': {
    name: 'variance column chart',
    component: VarianceColumnView,
    editor: CartesianEdit,
    limits: {
      dimensions: { min: 1, max: 1, },
      measures: { min: 1, max: 1, },
    },
    icon: BarIcon,
  },
  'line-view': {
    name: 'line chart',
    component: MeasureLineView,
    editor: CartesianEdit,
    limits: {
      dimensions: { min: 1, max: 1, },
      measures: { min: 1, max: 1, },
    },
    icon: LineIcon,
  },
  'measure-line-view': {
    name: 'measure line chart',
    component: MeasureLineView,
    editor: CartesianEdit,
    limits: {
      dimensions: { min: 1, max: 1, },
      measures: { min: 1, max: 1, },
    },
    icon: LineIcon,
  },
  'category-line-view': {
    name: 'category line chart',
    component: CategoryLineView,
    editor: CartesianEdit,
    limits: {
      dimensions: { min: 1, max: 1, },
      measures: { min: 1, max: 1, },
    },
    icon: LineIcon,
  },
  'radar-view': {
    name: 'radar chart',
    component: RadarView,
    editor: CartesianEdit,
    limits: {
      dimensions: { min: 1, max: 1, },
      measures: { min: 1, max: 1, },
    },
    icon: RadarIcon,
  },
  'pie-view': {
    name: 'pie chart',
    component: PieView,
    editor: CartesianEdit,
    limits: {
      dimensions: { min: 1, max: 1, },
      measures: { min: 1, max: 1, },
    },
    icon: PieIcon,
  },
}

Object.keys(ingestViews).forEach(name => {
  let view = ingestViews[name]
  Vue.component(name, view.component)
  Vue.component(view.editor.name, view.editor)
  Vue.component(view.icon.name, view.icon)
})
