<template>
  <div
    v-click-outside="closeFilters"
    class="other-filters">
    <b-dropdown
      aria-role="menu"
      min-height="auto"
      position="is-bottom-left">
      <template #trigger="{ active }">
        <a
          class="filter-button"
          role="button"
          @click="toggleFilter">
          <b-icon
            :class="{ filtered: isFiltered }"
            class="icon"
            :icon="isFiltered ? 'filter' : 'filter-outline'" />
          <div class="button-content">
            <div class="button-label">Filters</div>

            <div class="button-value">
              {{ isFiltered ? "Filtered" : "None" }}
            </div>
          </div>
          <b-icon
            class="icon right"
            :icon="active ? 'menu-up' : 'menu-down'" />
        </a>
      </template>
    </b-dropdown>
    <form @submit.prevent="submitHandler">
      <div
        class="dropdown-modal"
        :class="{ 'is-open': isFilterContainerOpen }">
        <section
          v-if="controls && controls.length"
          class="">
          <div class="columns m-0 p-0 is-mobile is-multiline">
            <div
              v-for="control in controls"
              :key="control.id"
              class="column m-0 p-0 is-3">
              <report-filter-item
                :accordian.sync="accordianModel"
                :all-controls="allControls"
                :control="control"
                :start-open="false"
                :use-bigquery="useBigquery"
                @update="updateFilterStatus" />
            </div>
          </div>
        </section>
      </div>
    </form>
  </div>
</template>

<script>
  import ReportFilterItem from '@/components/controls/filter/ReportFilterItem'
  import ClickOutside from 'vue-click-outside'

  export default {
    name: 'OtherFilters',
    components: {
      ReportFilterItem,
    },
    directives: {
      ClickOutside,
    },
    props: {
      controls: { type: Array, required: true, },
      allControls: { type: Array, required: true, },
      useBigquery: { type: Boolean, default: false, },
    },
    data () {
      return {
        controlAccordian: null,
        filterStatus: {},
        isFilterContainerOpen: false,
      }
    },
    computed: {
      isFiltered () {
        return Object.values(this.filterStatus).includes(true)
      },

      filterIds () {
        return this.controls.map((item) => item.id)
      },
      accordianModel: {
        get () {
          return this.controlAccordian
        },
        set (value) {
          this.controlAccordian = value
          this.controlIsSearch = false
          this.controlSearchTerm = ''
        },
      },
    },
    methods: {
      updateFilterStatus (data) {
        this.$set(this.filterStatus, data.id, data.value)
      },
      closeFilters () {
        this.isFilterContainerOpen = false
      },

      toggleFilter () {
        this.isFilterContainerOpen = !this.isFilterContainerOpen
      },
    },
  }
</script>

<style lang="scss" scoped>
.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;

  .icon {
    color: $gray-3;

    &.filtered {
      color: #ff6341;
    }

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;

    .button-label {
      font-size: 10px;
      color: $gray-4;
      text-transform: capitalize;
      margin-bottom: 2px;
    }

    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      // max-width: 150px;
      width: 100%;
    }
  }

  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-modal {
  position: absolute;
  top: -1000px;
  left: -1px;
  z-index: 10;
  width: calc(100vw - 50px);
  max-width: 1344px;
  padding: 4px;
  border: 1px solid $ui-06;
  border-radius: 0px 0px 8px 8px;
  background-color: $ui-07;
  box-shadow: 0px 2px 4px rgba(40, 39, 43, 0.04),
    0px 2px 8px rgba(40, 39, 43, 0.02);
  opacity: 0;

  @media (max-width: 1408px) {
    max-width: 1152px;
  }

  &.is-open {
    opacity: 1;
    top: 120px;
    transition: opacity 0.3s;
    z-index: 10;
  }
}
</style>

<style lang="scss">
.other-filters {
  .dropdown {
    display: flex;
    width: 100%;
    position: relative;
  }
}
</style>
