import { render, staticRenderFns } from "./DimensionFilterEditor.vue?vue&type=template&id=1e696386&scoped=true"
import script from "./DimensionFilterEditor.vue?vue&type=script&lang=js"
export * from "./DimensionFilterEditor.vue?vue&type=script&lang=js"
import style0 from "./DimensionFilterEditor.vue?vue&type=style&index=0&id=1e696386&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e696386",
  null
  
)

export default component.exports