<template>
  <div class="company-dimension-editor">
    <CompanyObjectEditor
      v-model="vModel"
      object-type="Dimension">
      <template #header-actions>
        <b-button
          type="button ml-2"
          class="button is-light"
          :disabled="loadingPreview"
          @click="previewValue">
          Show Values
        </b-button>
        <slot name="header-actions" />
      </template>
      <template #object-editor>
        <div class="icon-field">
          <TextInput
            v-model="icon"
            placeholder="Icon"
            class="icon-input" />
          <span class="material-icons">{{ icon }}</span>
        </div>
        <DimensionEditor
          v-model="dimension"
          v-bind="subObjectBinds" />
      </template>
    </CompanyObjectEditor>
  </div>
</template>

<script>
  import TextInput from '@/components/ui/TextInput'
  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyObjectEditor from '@/components/reports/config/common/CompanyObjectEditor'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'CompanyDimensionEditor',
    components: {
      TextInput,
      CompanyObjectEditor,
    },
    mixins: [ObjectEditorMixin,],
    data () {
      return {
        loadingPreview: false,
      }
    },
    computed: {
      ...mapChildren({
        icon: 'icon',
        dimension: 'dimension',
      }),
    },
    methods: {
      async previewValue () {
        this.loadingPreview = true
        try {
          const res = await this.$apis.companyConfigs.getDimensionValues(
            this.dimension
          )
          window.alert(res.map((o) => o.value))
        } finally {
          this.loadingPreview = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.company-dimension-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.icon-field {
  display: flex;
  flex-direction: row;
  padding-right: 128px;
}

.icon-input {
  flex-grow: 1;
}
</style>
