<template>
  <g
    class="y-axis">
    <path
      class="axis-line"
      :d="`M0,0 L0,${chartHeight}`" />
    <text
      v-for="(tick, idx) of ticks"
      :key="`yTick-${idx}`"
      class="tick-label"
      :class="{'first': idx === 0, 'last': idx === ticks.length - 1}"
      :x="-8"
      :y="`${yScale(tick)}`">
      {{ shortFormatAggVal(allMeasures.filter(m => !hiddenColumns.includes(m))[0], tick) }}
    </text>
    <template v-for="(tick, idx) of ticks">
      <path
        v-if="yScale(tick) !== chartHeight"
        :key="`yGrid-${idx}`"
        class="gridline"
        :d="`M0,${yScale(tick)} L${chartWidth},${yScale(tick)}`" />
    </template>
  </g>
</template>

<script>

  import chartComponentMixin from '@/components/charting/chart_component_mixin'

  export default {
    name: 'YAxis',
    mixins: [
      chartComponentMixin,
    ],
    computed: {
      ticks () {
        return this.yScale.ticks(3)
      },
    },
  }

</script>

<style lang="scss" scoped>

.y-axis {
}

.tick-label {
  position: relative;
  font-size: $h1;
  fill: $ui-04;
  text-anchor: end;

  &, &.first.last {
    alignment-baseline: middle;
  }

  &.first {
    alignment-baseline: after-edge;
  }

  &.last {
    alignment-baseline: before-edge;
  }
}

.gridline {
  stroke: $secondary-04;
  stroke-width: 1;
  stroke-dasharray: 1,5;
}

.axis-line {
  stroke-width: 2;
  stroke: $secondary-02;
}

</style>

