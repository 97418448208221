<template>
  <div class="row-editor-container">
    <div class="editor-header">
      <div class="widget-type">
        <i class="material-icons mr-2"> functions </i>
        <div>{{ inputs.name || moduleConfigs.name }}</div>
      </div>
      <Tabs
        v-model="inputSectionTab"
        :tabs="tabs"
        :is-objects="true"
        @input="scrollToSection" />
    </div>

    <div
      class="editor"
      @scroll="throttleScroll">
      <div
        id="display-label"
        class="editor-section">
        <h2>display label</h2>
        <div class="body">
          <b-field
            label="Display Label"
            class="input-field"
            message="">
            <b-input
              v-model="inputs.name"
              type="" />
          </b-field>
        </div>
      </div>

      <div
        id="measure"
        class="editor-section">
        <h2>measure</h2>
        <div class="body">
          <MeasureConfigEditor
            v-model="inputs.measure_config"
            :context="context" />
        </div>
      </div>

      <div
        id="format"
        class="editor-section">
        <h2>format</h2>
        <div class="body">
          <DataFormatEditor v-model="inputs.data_format" />
        </div>
      </div>

      <div
        id="benchmark"
        class="editor-section">
        <h2>benchmark</h2>
        <div class="body">
          <BenchmarkConfigEditor
            v-model="comparisonConfig"
            :context="{
              ...context,
              parentMeasure: inputs.measure_config.measure,
            }" />
        </div>
      </div>

      <div
        id="header-style"
        class="editor-section">
        <h2>header style</h2>
        <div class="body">
          <StyleTab v-model="inputs.header_style">
            <b-field
              label="Column Width"
              class="input-field"
              message="">
              <b-numberinput
                v-model="inputs.column_width"
                min="0" />
            </b-field>
          </StyleTab>
        </div>
      </div>

      <div
        id="cell-style"
        class="editor-section">
        <h2>cell style</h2>
        <div class="body">
          <StyleTab
            key="datastyle"
            v-model="inputs.data_style" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/pdf_report/Tabs.vue'
  import {
    throttle
  } from 'lodash'
  import StyleTab from './StyleTab.vue'
  import MeasureConfigEditor from '@/components/reports/config/modules/common/MeasureConfigEditor'
  import BenchmarkConfigEditor from '@/components/reports/config/modules/common/BenchmarkConfigEditor'
  import DataFormatEditor from '@/components/reports/config/modules/common/DataFormatEditor'

  export default {
    name: 'MeasureEditor',
    components: {
      Tabs,
      MeasureConfigEditor,
      BenchmarkConfigEditor,
      DataFormatEditor,
      StyleTab,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      context: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        moduleConfigs: {
          name: 'Measure',
          hideInLibrary: true,
        },

        inputSectionTab: 'display-label',
      }
    },

    computed: {
      tabs () {
        return [{
                  label: 'display label',
                  id: 'display-label',
                  hasErrors: false,
                },
                {
                  label: 'measure',
                  id: 'measure',
                  hasErrors: this.inputs?.measure_config?.errors,
                },
                {
                  label: 'format',
                  id: 'format',
                  hasErrors: this.inputs?.data_format?.errors,
                },
                {
                  label: 'benchmark',
                  id: 'benchmark',
                  hasErrors: this.inputs?.comparison_config?.errors,
                },
                {
                  label: 'header style',
                  id: 'header-style',
                  hasErrors: this.inputs?.header_style?.errors,
                },
                {
                  label: 'cell style',
                  id: 'cell-style',
                  hasErrors: this.inputs?.data_style?.errors,
                },
        ]
      },
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      throttleScroll () {
        return throttle(this.onScroll, 100)
      },
      comparisonConfig: {
        get () {
          return this.inputs.comparison_config
        },
        set (comparisonConfig) {
          this.inputs.comparison_config = comparisonConfig
        },
      },
    },

    async mounted () {},

    beforeDestroy () {},

    methods: {
      onScroll (event) {
        const sectionsByDistanceFromTop = this.tabs.map(section => {
          const el = document.getElementById(section.id)
          if (!el) return
          const rect = el.getBoundingClientRect()
          const scrollableDivRect = event.target.getBoundingClientRect()
          return {
            section: section.id,
            top: Math.abs(rect.top - scrollableDivRect.top),
          }
        })

        const sortedSections = sectionsByDistanceFromTop.sort((a, b) => a.top - b.top)
        this.inputSectionTab = sortedSections[0].section
      },
      scrollToSection (sectionId) {
        const element = document.getElementById(sectionId)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.editor-header {
  border-bottom: $border-1;
  padding: 20px;

  .widget-type {
    display: flex;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    margin-bottom: 15px;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border: $border-1;
      border-radius: 8px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.row-editor-container {
  width: 100%;
  height: 100%;
  margin: auto !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.editor {
  padding: 20px;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-direction: column;

  .editor-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    h2 {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    .body {
      border: $border-1;
      border-radius: 10px;
      padding: 18px;
    }
  }
}
// .card {
//   border: $border-1;

//   .card-header-title {
//     margin-bottom: 0 !important;
//   }
// }
// .input-field {
//   max-width: 400px;
// }

// h3.editor-section-title {
//   margin-bottom: 12px;
//   font-weight: 500;
//   font-size: 17px;
//   // margin-left: -50px;
// }
</style>
