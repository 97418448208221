<template>
  <div
    :key="module.key"
    class="rows">
    <vuedraggable
      group="moduleCards"
      ghost-class="ghost"
      class="dropzone"
      :list="module.tracker_rows">
      <div
        v-for="(row, i) in module.tracker_rows"
        :key="row.key">
        <b-tooltip
          style="width: 100%"
          position="is-right"
          type="is-light"
          append-to-body="true"
          multilined>
          <template #content>
            <div class="data-tooltip">
              <div>
                <h2>Measure:</h2>
                {{
                  row?.measure_config?.measure?.name ||
                    row?.measure_config?.measure?.key ||
                    "Null"
                }}
              </div>

              <div v-if="row?.comparison_config?.measure_config">
                <h2>Benchmark:</h2>
                {{ row.comparison_config.measure_config?.measure?.name }}
              </div>

              <div v-if="row?.tracker_row_totals?.length">
                <hr />
                <h2>Total Columns:</h2>
                <div>
                  <div
                    v-for="column in row.tracker_row_totals"
                    :key="column.key"
                    class="ml-4">
                    <div>
                      <h2>Measure:</h2>
                      {{ column?.measure_config?.measure?.name || "Null" }}
                    </div>

                    <div v-if="column?.comparison_config?.measure_config">
                      <h2>Benchmark:</h2>
                      {{
                        column?.comparison_config?.measure_config?.measure?.name
                      }}
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <b-tooltip
            style="width: 100%"
            type="is-light"
            position="is-bottom"
            :triggers="['contextmenu']"
            size="is-small"
            multilined
            :auto-close="['outside', 'escape', 'inside']">
            <div
              class="row mini-view-item"
              :class="{
                selected: currentSelectedKey === row.key,
                secondary: row.style.row_type === 'Secondary',
                'error-border': row.errors,
              }"
              @click="openEditor(i)">
              <img
                src="@/assets/icons/tracker-row.svg"
                alt="" />

              <div>{{ row.name || "Tracker Row" }}</div>
            </div>
            <template #content>
              <div class="context-menu-actions">
                <button
                  class="button is-light"
                  @click="copyCode(i)">
                  Copy
                </button>
                <button
                  class="button is-light"
                  @click="cutCode(i)">
                  Cut
                </button>
                <button
                  class="button is-danger is-light"
                  @click="deleteModule(i)">
                  Delete
                </button>
              </div>
            </template>
          </b-tooltip>
        </b-tooltip>
        <b-tooltip
          type="is-light"
          position="is-bottom"
          style="width: 100%"
          :triggers="['contextmenu']"
          size="is-small"
          multilined
          :auto-close="['outside', 'escape', 'inside']">
          <div class="add-module-below-section" />

          <template #content>
            <div class="context-menu-actions">
              <button
                class="button is-light"
                @click="pasteModule(i)">
                Paste
              </button>

              <button
                class="button is-light"
                @click="addTrackerRow(i)">
                Add new row below
              </button>
            </div>
          </template>
        </b-tooltip>
      </div>
    </vuedraggable>

    <button
      class="mini-view-item button"
      @click="addTrackerRow(false)">
      <img
        src="@/assets/icons/add-row.svg"
        alt="" />

      <div>Add row</div>
    </button>
  </div>
</template>

<script>
  import vuedraggable from 'vuedraggable'
  import uniqid from 'uniqid'
  export default {
    name: 'MiniTrackerTableModule',
    components: {
      vuedraggable,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      currentSelectedKey: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        module: {},
      }
    },
    computed: {
      data: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {
      this.module = this.data
      if (!this.module.key) {
        this.module.key = uniqid()
      }

      this.module.tracker_rows.forEach((item) => {
        if (!item.key) {
          item.type = 'TrackerTableRow'
          item.key = uniqid()
        }
      })
    },

    beforeDestroy () {},

    methods: {
      async copyCode (i) {
        const data = {
          type: 'tracker_row',
          code: this.module.tracker_rows[i],
        }
        try {
          await this.$copyText(JSON.stringify(data))
          this.$buefy.toast.open({
            message: 'Copied',
            position: 'is-bottom',
            type: 'is-success',
          })
        } catch (e) {
          console.log(e)
          this.$buefy.toast.open({
            message: 'Error',
            position: 'is-bottom',
            type: 'is-warning',
          })
        }
      },

      async cutCode (i) {
        this.copyCode(i)
        this.deleteModule(i)
      },

      deleteModule (i) {
        this.module.tracker_rows.splice(i, 1)
      },

      async pasteModule (index) {
        const clipboard = await navigator.clipboard.readText()

        try {
          const data = JSON.parse(clipboard)
          if (data.type === 'tracker_row') {
            this.module.tracker_rows.splice(index + 1, 0, {
              ...data.code,
              key: uniqid(),
              name: (data.code.name || 'Tracker Row') + ' (copy)',
            })
          } else {
            this.$buefy.toast.open({
              message: 'This module cannot be pasted here.',
              position: 'is-bottom',
              type: 'is-warning',
            })
          }
        } catch (e) {
          this.$buefy.toast.open({
            message: 'Error',
            position: 'is-bottom',
            type: 'is-warning',
          })
        }
      },

      addTrackerRow (index) {
        const newRow = {
          name: '',
          key: uniqid(),
          type: 'TrackerTableRow',
          style: {
            row_type: 'Primary',
          },
          measure_config: {
            measure: {
              type: 'CompanyMeasureRef',
              key: 'forecasted_net_sales',
              name: '',
            },
          },
          tracker_row_totals: this.module.tracker_total_headers.map(() => ({
            measure_config: {
              measure: {
                type: 'CompanyMeasureRef',
                key: 'forecasted_net_sales',
                name: '',
              },
            },
            conditional_format: null,
            data_format: {
              data_type: 'money',
              show_commas: true,
              decimal_places: 2,
              parenthesize_negatives: false,
            },
          })),
          data_format: {
            data_type: 'money',
            show_commas: true,
            decimal_places: 2,
            parenthesize_negatives: false,
          },
        }

        if (index !== false) {
          this.module.tracker_rows.splice(index + 1, 0, newRow)
          this.openEditor(index + 1)
        } else {
          this.module.tracker_rows.push(newRow)
          this.openEditor(this.module.tracker_rows.length - 1)
        }
      },
      openEditor (childIndex) {
        return this.$emit('open', childIndex)
      },
    },
  }
</script>

<style lang="scss" scoped>
.data-tooltip {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  hr {
    margin: 5px 0;
    border: 0;
    border-top: 1px solid black;
  }
  h2 {
    font-size: 12px;
    font-weight: 600;
  }
}

.rows {
  margin-top: 8px;
  margin-left: 20px;

  button {
    display: flex;
    align-items: flex-start;
    color: $primary;
    border: 1px solid rgba(0, 140, 132, 0.1);
    background: rgba(0, 140, 132, 0.05);
    justify-content: flex-start;

    &:hover {
      color: $primary;
      border: 1px solid rgba(0, 140, 132, 0.1);
    }
  }

  .add-module-below-section {
    height: 8px;
    margin: 1px 0;
    width: 100%;
    cursor: pointer;
    background-color: $primary;
    border-radius: 5px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
}

.mini-view-item {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s;
  width: 100%;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  background-color: white;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
    0px 1px 2px 0px rgba(40, 39, 43, 0.06);

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    background-color: hsla(177, 100%, 27%, 0.1);
    transition: background-color 0.1s;
    border: $border-1;
  }
  &.selected {
    background-color: $primary;
    color: white;
    img {
      filter: brightness(0) invert(1);
    }
  }

  &.secondary {
    margin-left: 20px;
  }
}

.context-menu-actions {
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 4px;
    font-size: 12px;
  }
}
</style>
