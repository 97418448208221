<template>
  <canvas
    ref="barChart"
    class="bar-chart-container" />
</template>

<script>
  import { Chart, registerables } from 'chart.js'
  Chart.register(...registerables)

  export default {
    name: 'BarChart',
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        chartInstance: null,
      }
    },
    computed: {
      chartData () {
        const labels = this.data.bars.map(item => item.label)
        const datasets = [
          {
            label: 'Values',
            data: this.data.bars.map(item => item.value),
            backgroundColor: this.data.bars.map(item => item.color),
            borderWidth: 0,
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomLeft: 0,
              bottomRight: 0,
            },
            borderSkipped: 'bottom',
          },
          {
            label: 'Benchmark',
            data: this.data.bars.map(item => item.benchmark),
            backgroundColor: this.data.bars.map(item => item.color + '33'), // Adding 33 for 20% opacity
            borderWidth: 0,
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomLeft: 0,
              bottomRight: 0,
            },
            borderSkipped: 'bottom',
          },
        ]
        return { labels, datasets, }
      },
    },
    watch: {
      data: {
        handler () {
          this.updateChart()
        },
        deep: true,
      },
    },
    mounted () {
      this.createChart()
      window.addEventListener('resize', this.resizeChart)
    },
    beforeUnmount () {
      if (this.chartInstance) {
        this.chartInstance.destroy()
      }
      window.removeEventListener('resize', this.resizeChart)
    },
    methods: {
      createChart () {
        const ctx = this.$refs.barChart.getContext('2d')
        this.chartInstance = new Chart(ctx, {
          type: 'bar',
          data: this.chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: function (tooltipItems) {
                    return tooltipItems[0].label
                  },
                  label: function (context) {
                    let label = context.dataset.label || ''
                    if (label) {
                      label += ': '
                    }
                    if (context.parsed.y !== null) {
                      label += context.parsed.y
                    }
                    return label
                  },
                },
              },
            },
            onClick: (event, elements) => {
              if (elements.length > 0) {
                const barIndex = elements[0].index
                console.log(`Bar ID: ${this.chartData.labels[barIndex]}`)
              }
            },
          },
        })
      },
      updateChart () {
        if (this.chartInstance) {
          this.chartInstance.data = this.chartData
          this.chartInstance.update()
        }
      },
      resizeChart () {
        if (this.chartInstance) {
          this.chartInstance.resize()
        }
      },
    },
  }
</script>

<style scoped>
.bar-chart-container {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
