import { Chart } from 'chart.js'
import { formatAmount, formatNumber } from '@/utils/Format.js'

export default {
  data () {
    return {
      chart: undefined,
    }
  },
  props: {
    loading: Boolean,
    columns: { type: Array, required: true, },
    data: Array,
  },
  computed: {
    dimensions () {
      return this.columns
        .filter((column) => column.expression.type == 'group')
        .map((column) => ({ ...column, key: column.idx, }))
    },
    aggregates () {
      return this.columns
        .filter((column) => column.expression.type != 'group')
        .map((column) => ({ ...column, key: column.idx, }))
    },
    chartOptions () {
      return {
        layout: {
          padding: {
            left: 32,
            right: 32,
            top: 32,
            bottom: 64,
          },
        },
        scales: {
          yAxes: this.hasAxes
            ? [
                {
                  min: 0,
                  ticks: {
                    callback: (value) => {
                      if (
                        this.aggregates &&
                        this.aggregates.length > 0 &&
                        this.aggregates[0].val_type == 'money'
                      ) {
                        return formatAmount(value)
                      } else {
                        return formatNumber(value)
                      }
                    },
                    maxTicksLimit: 5,
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ]
            : [],
          xAxes: this.hasAxes
            ? [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ]
            : [],
        },
        legend: {
          display: false,
          fullWidth: false,
          position: 'right',
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, data) => {
              let value =
                data.datasets[tooltipItems.datasetIndex].data[
                  tooltipItems.index
                ]
              let formatted
              if (
                this.aggregates &&
                this.aggregates.length > 0 &&
                this.aggregates[0].val_type == 'money'
              ) {
                formatted = formatAmount(value)
              } else {
                formatted = formatNumber(value)
              }
              return `${
                data.datasets[tooltipItems.datasetIndex].label
              }: ${formatted}`
            },
          },
        },
        onClick: (event, chartElements) => {
          this.$emit('click', {
            dimensionIndex: 0,
            value: this.data[chartElements[0]._index].d_0,
          })
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    },
    chartData () {
      if (this.aggregates.length < 1) {
        return {
          labels: ['', '', '',],
          datasets: [
            {
              label: '',
              data: [0.8, 0.6, 1,],
            },
          ],
        }
      }
      if (this.dimensions.length < 1) {
        return {
          labels: [this.aggregates[0].name,],
          datasets: [
            {
              data: [this.data[0][this.aggregates[0].key],],
            },
          ],
        }
      } else if (this.dimensions.length == 1) {
        let datasets = []
        this.rows = this.data.length
        let labels = []
        this.data.forEach((row) => {
          let dValue =
            this.dimensions[0].type == 'entity'
              ? row[this.dimensions[0].key].name
              : row[this.dimensions[0].key]
          labels.push(dValue)
        })

        for (let a of this.aggregates) {
          let data = []
          this.data.forEach((row) => {
            data.push(row[a.key])
          })
          datasets.push({
            label: a.name,
            data,
          })
        }
        return { datasets, labels, }
      }
    },
  },
  watch: {
    chartOptions () {
      this.update()
    },
    chartData () {
      this.update()
    },
  },
  methods: {
    update () {
      this.chart.data = this.chartData
      this.chart.options = this.chartOptions
      this.chart.update()
    },
  },
  mounted () {
    this.chart = new Chart(this.$refs.canvas.getContext('2d'), {
      type: this.chartType,
      data: this.chartData,
      options: this.chartOptions,
    })
  },
}
