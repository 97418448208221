<template>
  <div class="container-fluid">
    <div class="container pt-6">
      <div class="columns">
        <div class="mr-6">
          <h2 class="mb-3">
            Datepicker UI Variations
          </h2>
          <time-range-filter
            :default="timeRangeFilterDefaultForCalendar"
            @submit="onTimeRangeFilterSubmit" />
          <br />
          <time-range-filter
            :hide-icon="true"
            :default="timeRangeFilterDefaultForCalendar"
            @submit="onTimeRangeFilterSubmit" />

          <br />
          <time-range-filter
            :hide-icon="true"
            :hide-secondary-label="true"
            :default="timeRangeFilterDefaultForCalendar"
            @submit="onTimeRangeFilterSubmit" />

          <br />
          <time-range-filter
            :hide-icon="false"
            :hide-secondary-label="true"
            :default="timeRangeFilterDefaultForCalendar"
            @submit="onTimeRangeFilterSubmit" />

          <br />
          <time-range-filter
            :hide-icon="true"
            :hide-secondary-label="false"
            :default="timeRangeFilterDefaultForCalendar"
            @submit="onTimeRangeFilterSubmit" />

          <br />
          <time-range-filter
            :disabled="true"
            :default="timeRangeFilterDefaultForCalendar"
            @submit="onTimeRangeFilterSubmit" />
        </div>

        <div class="">
          <h2 class="mb-3">
            Datepicker Null State for fiscal calendar
          </h2>
          <time-range-filter
            :default="timeRangeFilterDefaultForCalendar"
            @submit="onTimeRangeFilterSubmit" />
          <br />

          <h2 class="mb-3">
            Datepicker Null State for calendar
          </h2>
          <time-range-filter
            :default="timeRangeFilterDefaultForFiscalCalendar"
            @submit="onTimeRangeFilterSubmit" />

          <br />

          <h2 class="mb-3">
            Datepicker Null State for both
          </h2>
          <time-range-filter
            @submit="onTimeRangeFilterSubmit" />
        </div>
      </div>
    </div>
    <div class="container pt-6">
      <div class="columns is-multiline">
        <div class="column is-4">
          <kpis
            :content="kpi1"
            class="component-container" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TimeRangeFilter from '@/components/controls/filter/TimeRangeFilter.vue'
  import Kpis from '@/components/dashboard/Kpis.vue'

  export default {
    name: 'ComponentLibrary',
    components: {
      Kpis,
      TimeRangeFilter,
    },
    data () {
      return {
        timeRangeFilterDefaultForCalendar: {
          calendarPreset: 'last_7',
        },
        timeRangeFilterDefaultForFiscalCalendar: {
          fiscalPreset:'std',
        },

        kpi1: {
          dataPoint: 'Net Sales',
          dataPointValue: {
            main: '$103,368',
            sup: '36',
          },
          isGood: false,
          changes: {
            value: '$6',
            percentage : '7.6%',
          },
          benchmark:{
            value: '$79',
            label: 'Last Week',
          },
          hasProgressbar: true,
        },
      }
    },
    methods:{
      onTimeRangeFilterSubmit (val) {
        console.log(val)
      },
    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: #F5F5F5;
  width: 100%;
  min-height: 1000px;

  h2 {
    font-size: 17px;
  }

}
</style>
