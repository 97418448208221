var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cheque-table flex-column"},[_c('div',{staticClass:"table"},[_c('ingest-table',{attrs:{"fields":[
        {name: 'Check no.', data_type:{ key: 'number' }, key: 'number',
         sortDir: _vm.dimensionSortDirection('cheque_number')},
        {name: 'Restaurant name', data_type: { key: 'string' }, key: 'restaurant',
         sortDir: _vm.dimensionSortDirection('restaurant')},
        {name: 'Open time', data_type: { key: 'datetime' }, key: 'openTime',
         sortDir: _vm.dimensionSortDirection('open_time')},
        {name: 'Amount paid', data_type: {key: 'money' }, key: 'amount',
         sortDir: _vm.dimensionSortDirection('payment_amount')},
      ],"rows":_vm.cheques.map(cheque => ({ ...cheque, clickable: true, active: _vm.$route.params.cheque_id == cheque.id })),"busy":_vm.loading || _vm.dirty,"loading":_vm.loading,"busy-message":_vm.dirty ? 'Search to refresh' : 'Searching'},on:{"headerClick":function($event){return _vm.onDimensionClick($event.key)},"rowClick":function($event){return _vm.selectCheque($event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }