<template>
  <div class="cheque-filter custom-filter">
    <div class="label-section">
      <div class="label-text">
        Filter
      </div>
    </div>
    <div class="dropdown-filter">
      <cheque-filter-item
        :data="filters.location"
        :show-label-section="false" />
    </div>

    <hr />

    <div class="dropdown-filter">
      <div class="label-section">
        <div class="label-text">
          Show checks that
          <button
            class="button contains-toogle-button"
            :class="{ red: !containsToggle, green: containsToggle }"
            @click="containsToggle = !containsToggle">
            {{ containsToggle ? "contains" : "do not contain" }}
          </button>
        </div>
      </div>
      <cheque-filter-item
        :data="filters.location"
        :show-label-section="false" />
    </div>

    <button class="delete-btn">
      <b-icon
        icon="close"
        custom-size="h-5"
        class="icon" />
      Remove Filter
    </button>
  </div>
</template>

<script>
  import ChequeFilterItem from '@/components/cheque_search/ChequeFilterItem.vue'

  export default {
    name: 'ChequeCustomFilterItem',
    components: {
      ChequeFilterItem,
    },

    props: {
      // data: { type: Object, required: true, },
    },
    data () {
      return {
        containsToggle: false,
        loading: false,
        filterSelect: null,
        selectionModel: [],

        isFiltered: false, //make this computed

        filters: {
          location: {
            label: 'Location',
            options: [
              { key: 'a', label: 'a', },
              { key: 'b', label: 'b', },
              { key: 'c', label: 'c', },
            ],
          },
        },
      }
    },
    computed: {},
    methods: {},
  }
</script>
<style lang="scss" scoped>
.custom-filter {
  background-color: white;
  border: $border-1;
  padding: 20px;
  border-radius: 10px;
}

button.delete-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 30px;
  padding-right: 10px;

  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;

  border: 1px solid #fbe1eb;
  color: #d31b5e;
  background-color: white;

  &:hover {
    background-color: #fdf4f7;
  }

  .icon {
    margin-right: 8px;
  }
}

.label-section {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  .label-text {
    font-size: 13px;
    margin-right: auto;
    color: $ui-03;
    font-weight: 300;
    display: flex;
    align-items: center;

    .contains-toogle-button {
      margin-left: 4px;
      margin-right: 10px;
      border-radius: 4px;
      border: 1px solid $gray-1;
      padding: 3px 10px;
      font-size: 12px;
      font-weight: 400;
      color: $gray-1;
      background-color: white;
      cursor: pointer;

      &.red {
        border: 1px solid #fbe1eb;
        color: #d31b5e;
        background-color: #fdf4f7;
      }

      &.green {
        border: 1px solid #b5dedc;
        color: $primary;
        background-color: #f2f9f9;
      }
    }
  }
}
</style>
