<template>
  <div class="rollup-comparison-options flex-column">
    <div class="flex-row">
      per
      <select v-model="perDimension">
        <option
          v-for="dimension in dimensionOptions"
          :key="`per-${dimension.key}`"
          :value="dimension.key">
          {{ dimension.name }}
        </option>
      </select>
    </div>
    <div class="flex-row">
      over
      <select v-model="overDimension">
        <option
          v-for="dimension in overOptions"
          :key="`over-${dimension.key}`"
          :value="dimension.key">
          {{ dimension.name }}
        </option>
      </select>
    </div>
    <b-button
      label="remove"
      class="secondary-06-text"
      @click="$emit('update:comparison', null)" />
  </div>
</template>

<script>

  export default {
    name: 'RollupComparisonOptions',
    components: {
    },
    props: {
      dimensionOptions: { type: Array, required: true, },
      comparison: { type: Object, default: Array, },
    },
    computed: {
      overOptions () {
        return this.dimensionOptions.filter((option) => option.descendants.includes(this.perDimension) && option.groupable)
      },
      perDimension: {
        get () {
          if (this.comparison && this.comparison.type == 'rollup' && this.comparison.per_dimension) {
            return this.comparison.per_dimension
          } else {
            return this.dimensionOptions[0].key
          }
        },
        set (per_dimension) {
          this.$emit('update:comparison', { type: 'rollup', per_dimension, over_dimension: per_dimension, })
        },
      },
      overDimension: {
        get () {
          if (this.comparison && this.comparison.type == 'rollup' && this.comparison.over_dimension) {
            return this.comparison.over_dimension
          } else {
            return this.overOptions.length > 0 ? this.overOptions[0].key : null
          }
        },
        set (over_dimension) {
          this.$emit('update:comparison', { type: 'rollup', per_dimension: this.perDimension, over_dimension, })
        },
      },
    },
    mounted () {
      this.$emit('update:comparison', { type: 'rollup', per_dimension: this.perDimension, over_dimension: this.overDimension, })
    },
  }

</script>

<style lang="scss" scoped>

.rollup-comparison-options {
}

</style>
