<template>
  <div class="dashboard-v2">
    <div class="container">
      <DashboardReport
        :key="key"
        :dashboard-key="key" />
    </div>
  </div>
</template>

<script>

  import DashboardReport from '@/components/reports/dashboards/DashboardReport'

  export default {
    name: 'DashboardV2',
    components: {
      DashboardReport,
    },
    computed: {
      key () {
        return this.$route.params.id
      },
    },
  }

</script>

<style lang="scss" scoped>

.container {
  min-height: 100%
}
.dashboard-v2 {
  display: flex;
  background-color: $grayscale-8;
}

</style>
