<template>
  <div class="cheque-filter filter">
    <div
      v-if="showLabelSection"
      class="label-sections">
      <div class="button-label is-capitalized">
        {{ dimension.name }}
      </div>

      <b-button
        class="label-section-button"
        type="is-text"
        :disabled="loading"
        @click.stop.prevent="selected = options">
        Select All
      </b-button>
      <b-button
        class="label-section-button"
        type="is-text"
        :disabled="loading"
        @click.stop.prevent="selected = []">
        Deselect All
      </b-button>
    </div>
    <div class="dropdown-filter">
      <filter-multiselect
        v-model="selected"
        track-by="name"
        label="name"
        :disabled="loading"
        :loading="loading"
        :options="options" />
    </div>
  </div>
</template>

<script>

  import filterMultiselect  from '@/components/ui/multiselect'
  export default {
    name: 'EntityFilter',
    components: {
      filterMultiselect,
    },
    props: {
      dimension: { type: Object, required: true, },
      options: { type: Array, default: Array, },
      filter: { type: Object, default: null, },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        searchTerm: '',
        showLabelSection: true,
        isOpen: false,
        isFiltered: false, //make this computed
      }
    },
    computed: {
      selected: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.values
          }
          return this.options
        },
        set (values) {
          this.$emit('update:filter', {
            type: 'in',
            dimension: this.dimension.key,
            args: { values, },
          })
        },
      },
    },
    methods: {
      toggle () {
        if (this.isOpen) {
          this.$refs.dropdown.deactivate()
        } else {
          this.$refs.dropdown.activate()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.dropdown-content {
  border-radius: 16px !important;
}

.label-sections {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  .button-label {
    font-size: 13px;
    margin-right: auto;
    color: $ui-03;
    font-weight: 300;
  }

  .label-section-button {
    font-size: 10px;
    padding: 0 6px;
    height: 16px;
    color: $grayscale-3;
  }
}
</style>
<style lang="scss">
.cheque-filter {
  .dropdown {
    display: flex;
    width: 100%;
    position: relative;
  }
  .dropdown-item.is-active {
    background-color: $primary !important;
    color: white !important;
  }

  .caret {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .dropdown-filter {
    .multiselect__single {
      height: 30px;
      display: flex;
      align-items: center;
    }

    .multiselect__content-wrapper {
      position: absolute !important; //make the dropdown overlay
    }

    .multiselect__placeholder {
      display: none !important;
    }

    .multiselect__option {
      .selected-checkbox {
        display: none !important;
      }
      .unselected-checkbox {
        display: inline-flex !important;
      }
      .option__desc {
        display: flex;
        align-items: center;

        .option__title {
          position: relative;
          top: 1px;
        }
      }

      &.multiselect__option--selected {
        background-color: white !important;
        color: $grayscale-1 !important;

        &:hover {
          background-color: $grayscale-6;
        }
        .unselected-checkbox {
          display: none !important;
        }
        .selected-checkbox {
          display: inline-flex !important;
        }
      }
    }
  }
}
</style>
