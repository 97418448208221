<template>
  <div class="module">
    <div class="columns">
      <div class="column">
        <div class="module-container">
          <kpis :content="json" />
        </div>
      </div>
      <div class="column">
        <v-jsoneditor
          v-model="json"
          :options="editorOptions"
          :plus="false"
          height="700px"
          @error="onError" />
      </div>
    </div>
  </div>
</template>

<script>
  import VJsoneditor from 'v-jsoneditor'
  import Kpis from '@/components/dashboard/Kpis.vue'

  export default {
    name: 'GridBuilder',
    components: {
      VJsoneditor,
      Kpis,
    },
    props: {},
    data () {
      return {
        editorOptions: {
          'mode': 'code',
        },

        json: {
          dataPoint: 'Net Sales',
          dataPointValue: {
            main: '$103,368',
            sup: '36',
          },
          isGood: false,
          changes: {
            value: '$6',
            percentage : '7.6%',
          },
          benchmark:{
            value: '$79',
            label: 'Last Week',
          },
          hasProgressbar: true,
        },
      }
    },
    computed: {
    },
    methods: {
      onError (e) {
        console.log('error',e)
      },
    },
  }
</script>

<style lang="scss" scoped>
.module {
  width: 100%;
  overflow-y: auto;
  background: $grayscale-7;
  position: relative;
  padding-bottom: 50px;
  padding: 20px;
  .form-container{
    max-width: 400px;

  }

  .columns{
    height: 100%;
  }

}
</style>
