<template>
  <div class="dashboard-section">
    {{ dashboardSection }}
    <div class="dashboard-controls">
      Section Controls:
      <DashboardControl
        v-for="control in dashboardSection.controls"
        :key="control.id"
        :dashboard-control="control" />
    </div>
    <div class="dashboard-modules">
      Sub-Sections:
      <template v-for="subSection in dashboardSection.sub_sections">
        {{ subSection }}
        Section Modules:
        <DashboardModule
          v-for="module in subSection.modules"
          :key="module.id"
          :dashboard-data-context="dashboardDataContext"
          :dashboard-key="dashboardKey"
          :dashboard-module="module" />
      </template>
    </div>
  </div>
</template>

<script>

  import DashboardControl from '@/components/reports/dashboards/controls/DashboardControl'
  import DashboardModule from '@/components/reports/dashboards/modules/DashboardModule'

  export default {
    name: 'DashboardSection',
    components: {
      DashboardModule,
      DashboardControl,
    },
    props: {
      dashboardSection: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
  }

</script>

<style scoped lang="scss">

.dashboard-section {
  padding: 16px;
}

</style>
