import { forecastUploaderStore } from '@/vuex/app/forecasts/forecastUploaderStore'
import { reportsConfigStore } from '@/vuex/app/reports/reportsConfigStore'
import { dashboardReportsStore } from '@/vuex/app/reports/dashboardReportsStore'

export const appStore = {
  namespaced: true,
  state () {
    return {
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    forecasts: forecastUploaderStore,
    reportsConfig: reportsConfigStore,
    dashboardReports: dashboardReportsStore,
  },
}
