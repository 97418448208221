<template>
  <div class="cheque-table flex-column">
    <div class="table">
      <ingest-table
        :fields="[
          {name: 'Check no.', data_type:{ key: 'number' }, key: 'number',
           sortDir: dimensionSortDirection('cheque_number')},
          {name: 'Restaurant name', data_type: { key: 'string' }, key: 'restaurant',
           sortDir: dimensionSortDirection('restaurant')},
          {name: 'Open time', data_type: { key: 'datetime' }, key: 'openTime',
           sortDir: dimensionSortDirection('open_time')},
          {name: 'Amount paid', data_type: {key: 'money' }, key: 'amount',
           sortDir: dimensionSortDirection('payment_amount')},
        ]"
        :rows="cheques.map(cheque => ({ ...cheque, clickable: true, active: $route.params.cheque_id == cheque.id }))"
        :busy="loading || dirty"
        :loading="loading"
        :busy-message="dirty ? 'Search to refresh' : 'Searching'"
        @headerClick="onDimensionClick($event.key)"
        @rowClick="selectCheque($event)" />
    </div>
  </div>
</template>

<script>

  import { formatDate } from '@/utils/Date'
  import { formatAmount } from '@/utils/Format'
  import IngestTable from '@/components/common/table/IngestTable'

  export default {
    name: 'ChequeTable',
    components: {
      IngestTable,
    },
    props: {
      dirty: {
        type: Boolean,
        default: false,
      },
      query: {
        type: Object,
        required: true,
      },
      cheques: {
        type: Array,
        default: Array,
      },
      loading: Boolean,
    },
    data () {
      return {
        timeFilterOpen: false,
      }
    },
    computed: {
      order: {
        get () {
          return this.query.order
        },
        set (order) {
          this.$emit('update:query', { ...this.query, order, })
          this.$emit('search')
        },
      },
    },
    methods: {
      closeDateTimeFilter () {
        this.timeFilterOpen = false
      },
      selectCheque (cheque) {
        this.$router.replace(
          `/company/${this.$route.params.company_id}/tools/checks/${cheque.id}`)
      },
      formatOpenTime (dateTime) {
        return formatDate(dateTime)
      },
      formatAmount,
      dimensionSortDirection (dimensionName) {
        if (this.order.length > 0 && this.order[0].dimension == dimensionName) {
          if (this.order[0].ascending) {
            return 'asc'
          } else {
            return 'desc'
          }
        }
        return null
      },
      isCurrentSort (dimensionName, asc) {
        return (
          this.order.length > 0 && this.order[0].dimension == dimensionName && this.order[0].ascending == asc
        )
      },
      onDimensionClick (dimensionKey) {
        let dimensionName = {
          'number': 'cheque_number',
          'restaurant': 'restaurant',
          'openTime': 'open_time',
          'amount': 'payment_amount',
        }[dimensionKey]
        this.order = [
          {
            dimension: dimensionName,
            ascending: !this.isCurrentSort(dimensionName, true),
          },
          ...this.order.filter(field => field.dimension != dimensionName),
        ]
      },
    },
  }
</script>

<style lang="scss" scoped>
.cheque-table {
  background-color: $secondary-02;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: inset 3px 0 3px -3px rgba($secondary-06, .5);
}
.table {
  overflow: auto;
  margin: 0;
  flex-grow: 1;
}
.ingest-table {
  height: 100%;
}
.top-section {
  background-color: $secondary-01;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
}
</style>
