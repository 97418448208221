import { render, staticRenderFns } from "./TableStyle.vue?vue&type=template&id=13641b64&scoped=true"
import script from "./TableStyle.vue?vue&type=script&lang=js"
export * from "./TableStyle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13641b64",
  null
  
)

export default component.exports