<template>
  <div class="date-filter">
    <div class="filter-label">
      {{ dimension.name }}
    </div>

    <div class="inputs">
      <date-input
        v-model="start"
        class="date-input mr-2" />
      <date-input
        v-model="end"
        position="is-bottom-left"
        class="date-input" />
    </div>
  </div>
</template>

<script>

  import DateInput from '@/components/common/input/DateInput'

  export default {
    name: 'DateFilter',
    components: {
      DateInput,
    },
    props: {
      dimension: { type: Object, required: true, },
      filter: { type: Object, default: null, },
    },
    computed: {
      start: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.start
          }
          return null
        },
        set (start) {
          this.updateFilter(start, this.end)
        },
      },
      end: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.end
          }
          return null
        },
        set (end) {
          this.updateFilter(this.start, end)
        },
      },
    },
    methods: {
      updateFilter (start, end) {
        this.$emit('update:filter', {
          type: 'between',
          dimension: this.dimension.key,
          args: { start, end, },
        })
      },
    },
  }

</script>

<style lang="scss" scoped>

.filter-label {
    font-size: 13px;
    margin-right: auto;
    color: $ui-03;
    font-weight: 300;
  }

  .inputs {
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    .date-input {
      width: 100%;
    }
  }

</style>
