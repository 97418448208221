<template>
  <div class="text-input">
    <b-input
      ref="input"
      v-model="vModel"
      :placeholder="placeholder"
      class="input-field"
      custom-class="no-border" />
    <ValidationErrors
      v-if="errors"
      :errors="errors" />
  </div>
</template>

<script>

  import ValidationErrors from '@/components/ui/ValidationErrors'

  export default {
    name: 'TextInput',
    components: {
      ValidationErrors,
    },
    props: {
      value: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
      errors: {
        type: Array,
        default: Array,
      },
    },
    computed: {
      vModel: {
        get () {
          return this.value
        },
        set (vModel) {
          this.$emit('input', vModel)
        },
      },
    },
  }

</script>

<style lang="scss" scoped>

.text-input {
  display: flex;
  flex-direction: column;
}
