<template>
  <div class="ingest-form">
    <div
      v-for="(input,i) in fields"
      :key="`${i}-${input.id}-${input.type}-${input.component}`">
      <b-field
        v-if="isVisible(input)"
        class="mt-3"
        :label="input.required ? input.label + '*' : input.label">
        <div
          v-if="input.component == 'ingest-form'"
          class="indent">
          <ingest-form
            :fields="input.value"
            @update:fields="updateFieldValue(i, $event)" />
        </div>
        <div
          v-if="input.component == 'list'"
          class="indent">
          <div
            v-for="item, listIdx in input.value"
            :key="`list-${input.id}-${listIdx}`"
            class="list-item">
            <ingest-form
              :fields="[item]"
              @update:fields="updateListValue(i, listIdx, $event)" />
            <b-button @click="deleteListValue(i, listIdx)">
              Del
            </b-button>
          </div>
          <b-button
            type="is-secondary"
            @click="addListValue(i)">
            Add
          </b-button>
        </div>
        <component
          :is="`b-${input.component}`"
          v-if="!['list','ingest-form'].includes(input.component)"
          :value="input.value"
          :multiple="input.multiple"
          :min="input.min"
          :max="input.max"
          expanded
          :type="input.type"
          @input="updateFieldValue(i, $event)">
          <template v-if="input.component === 'select'">
            <option
              v-for="(option, j) in input.options"
              :key="j"
              :value="option.value">
              {{ option.label }}
            </option>
          </template>
        </component>
      </b-field>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'IngestForm',
    props: {
      fields: { type: Array, default: Array, },
    },
    methods: {
      updateFieldValue (idx, newValue) {
        let newFields = [...this.fields,]
        newFields[idx].value = newValue
        this.$emit('update:fields', newFields)
      },
      updateListValue (fieldIdx, listIdx, newValue) {
        let newList = [...this.fields[fieldIdx].value,]
        newList[listIdx] = newValue[0]
        this.updateFieldValue(fieldIdx, newList)
      },
      deleteListValue (fieldIdx, listIdx) {
        let newList = [
          ...this.fields[fieldIdx].value.slice(0, listIdx),
          ...this.fields[fieldIdx].value.slice(listIdx + 1 ),
        ].map((item, idx) => ({
          ...item,
          label: `${this.fields[fieldIdx].itemField.label} ${idx}`,
          id: `${idx}`,
        }))
        this.updateFieldValue(fieldIdx, newList)
      },
      addListValue (fieldIdx) {
        let newList = [
          ...this.fields[fieldIdx].value,
          {
            ...JSON.parse(JSON.stringify(this.fields[fieldIdx].itemField)),
            id: `${this.fields[fieldIdx].value.length}`,
            label: `${this.fields[fieldIdx].itemField.label} ${this.fields[fieldIdx].value.length}`,
          },
        ]
        this.updateFieldValue(fieldIdx, newList)
      },
      isVisible (input) {
        if (input.dependsOn) {
          if (Array.isArray(input.dependsOn)) {
            for (let dependsOn of input.dependsOn) {
              const parent = this.fields.find((val) =>
                val.id === dependsOn.id)
              if (!(Array.isArray(parent.value) ? parent.value.includes(dependsOn.value) : parent.value === dependsOn.value )) {
                return false
              }
            }
          }
          else {
            const parent = this.fields.find((val) => {
              return val.id === input.dependsOn.id
            })
            return  (Array.isArray(parent.value) ? parent.value.includes(input.dependsOn.value) : parent.value === input.dependsOn.value )
          }

        }
        return true
      },
    },
  }
</script>

<style lang="scss" scoped>

.ingest-form {
}

.indent {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

</style>
