<template>
  <div
    class="data-field nowrap"
    :class="{ clickable: field.clickable, isTotal }"
    :style="{ color: specColor(field.colorSpec, value) }">
    <div class="text-overflow">
      <span
        v-if="neutralVariance"
        class="material-icons"> arrow_drop_up </span>
      <span
        v-if="positiveVariance"
        class="material-icons">
        arrow_drop_up
      </span>
      <span
        v-if="negativeVariance"
        class="material-icons">
        arrow_drop_down
      </span>
      {{ rendered(valueModel) }}
    </div>
    <div class="hover-tip">
      <span
        v-if="neutralVariance"
        class="material-icons"> arrow_drop_up </span>
      <span
        v-if="positiveVariance"
        class="material-icons">
        arrow_drop_up
      </span>
      <span
        v-if="negativeVariance"
        class="material-icons">
        arrow_drop_down
      </span>
      {{ rendered(valueModel) }}
    </div>
  </div>
</template>

<script>
  import helpers from '@/components/charting/helpers'
  import { dvPalette } from '@/components/charting/colors'

  export default {
    name: 'DataFieldV2',
    props: {
      field: { type: Object, required: true, },
      allFields: { type: Array, default: Array, },
      value: { type: [Object, String, Number, Date, Boolean,], default: null, },
      row: { type: Object, default: null, },
      isTotal: { type: Boolean, default: false, },
    },
    data () {
      return {
        newValue: null,
      }
    },
    computed: {
      neutralVariance () {
        return (
          ['variance_measure', 'variance_pivot_total',].includes(
            this.field.role
          ) &&
          !isNaN(this.value) &&
          !!this.value &&
          this.value == 0
        )
      },
      negativeVariance () {
        return (
          ['variance_measure', 'variance_pivot_total',].includes(
            this.field.role
          ) &&
          !isNaN(this.value) &&
          !!this.value &&
          this.value < 0
        )
      },
      positiveVariance () {
        return (
          ['variance_measure', 'variance_pivot_total',].includes(
            this.field.role
          ) &&
          !isNaN(this.value) &&
          !!this.value &&
          this.value > 0
        )
      },
      valueModel: {
        get () {
          return this.value
        },
        set (value) {
          if (value === 'NEW_VALUE') {
            value = window.prompt('Enter a new value:')
            this.newValue = value
          } else {
            this.newValue = null
          }
          if (value) {
            this.$emit('edit', value)
          }
        },
      },
    },
    methods: {
      rendered (value) {
        return helpers.formatAggVal(this.field, value)
      },
      specColor (spec, value, parent_threshold_id, invert_comparisons) {
        if (spec == null) {
          return null
        }
        if (spec.type === 'manual') {
          return spec.value
        }
        if (spec.type === 'controlled') {
          return this.specColor(this.$store.getters['controls/debouncedSelections'][spec.control_id].colorSpec, value, spec.threshold_series_id | parent_threshold_id, spec.invert_comparisons ? !invert_comparisons : invert_comparisons)
        }
        if (value) {
          if (spec.type === (invert_comparisons ? 'under_over' : 'over_under')) {
            if (spec.threshold_series_id) {
              let thresholdValue =
                this.row[
                  this.allFields
                    .filter((f) => f.id == spec.threshold_series_id)
                    .shift().key
                ]
              if (
                !isNaN(this.value) &&
                (isNaN(thresholdValue) || this.value > thresholdValue)
              ) {
                return dvPalette.dvGood
              }
              if (
                !isNaN(this.value) &&
                !isNaN(thresholdValue) &&
                this.value < thresholdValue
              ) {
                return dvPalette.dvBad
              }
            }
            if (parent_threshold_id) {
              let thresholdValue = this.row[
                this.allFields.filter(f => f.id == parent_threshold_id).shift().key]
              if (!isNaN(this.value) && (isNaN(thresholdValue) || this.value > thresholdValue)) {
                return dvPalette.dvGood
              }
              if (!isNaN(this.value) && !isNaN(thresholdValue) && this.value < thresholdValue) {
                return dvPalette.dvBad
              }
            }
            if (this.positiveVariance) {
              return dvPalette.dvGood
            } else if (this.negativeVariance) {
              return dvPalette.dvBad
            }
          }
          if (spec.type === (invert_comparisons ? 'over_under' : 'under_over')) {
            if (spec.threshold_series_id) {
              let thresholdValue =
                this.row[
                  this.allFields
                    .filter((f) => f.id == spec.threshold_series_id)
                    .shift().key
                ]
              if (
                !isNaN(this.value) &&
                (isNaN(thresholdValue) || this.value > thresholdValue)
              ) {
                return dvPalette.dvBad
              }
              if (
                !isNaN(this.value) &&
                !isNaN(thresholdValue) &&
                this.value < thresholdValue
              ) {
                return dvPalette.dvGood
              }
            }
            if (parent_threshold_id) {
              let thresholdValue = this.row[
                this.allFields.filter(f => f.id == parent_threshold_id).shift().key]
              if (!isNaN(this.value) && (isNaN(thresholdValue) || this.value > thresholdValue)) {
                return dvPalette.dvBad
              }
              if (!isNaN(this.value) && !isNaN(thresholdValue) && this.value < thresholdValue) {
                return dvPalette.dvGood
              }
            }
            if (this.positiveVariance) {
              return dvPalette.dvBad
            } else if (this.negativeVariance) {
              return dvPalette.dvGood
            }
          }
        }
        return null
      },
    },
  }
</script>

<style lang="scss" scoped>
.data-field {
  border-collapse: collapse;
  border: none;
  padding: 4 * $gridBase;
  flex-grow: 0;
  text-align: left;
  font: $h2-lw-sl;
  color: $ui-02;
  background: inherit;
  position: relative;

  .text-overflow {
    display: flex;
    align-items: center;
  }

  &.clickable {
    cursor: pointer;
  }

  &.isTotal {
    color: $ui-01;
    font: $h2-dw-sl;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

select {
  width: 32 * $remGrid;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hover-tip {
  position: absolute;
  padding: 4 * $gridBase;
  top: 0px;
  left: 0px;
  background: inherit;
  min-width: 100%;
  height: 100%;

  display: none;

  // .data-field:hover > & {
  //   display: block;
  // }
}
</style>
