<template>
  <div>
    <div v-if="long">
      {{ summary }}
    </div>
    <div v-else>
      {{ dimension.name }} ({{ summary }})
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BooleanFilterSummary',
    props: {
      filter: { type: Object, default: null, },
      long: { type: Boolean, default: false, },
    },
    computed: {
      dimension () {
        return this.$store.getters['metadata/dimensions'][this.filter.dimension]
      },
      values () {
        return this.filter.args.values
      },
      summary () {
        if (this.long) {
          if (this.values.includes(true) && this.values.includes(false)) {
            return 'true or false'
          } else if (this.values.includes(true)) {
            return 'true only'
          } else if (this.values.includes(false)) {
            return 'false only'
          } else {
            return 'none'
          }
        } else {
          if (this.values.includes(true) && this.values.includes(false)) {
            return 'any'
          } else if (this.values.includes(true)) {
            return 'true'
          } else if (this.values.includes(false)) {
            return 'false'
          } else {
            return 'none'
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
