<template>
  <div
    v-on-clickaway="closeDropdown"
    class="ingest-dropdown"
    :class="{ isOpen }">
    <b-button
      type="button"
      :label="label"
      :class="{ isOpen }"
      v-bind="{ activeClass, hoverClass, unactiveClass, unhoverClass, active, }"
      @click="toggleDropdown">
      <slot
        v-bind="{ isOpen }"
        name="button-content" />
      <template slot="before">
        <slot
          v-bind="{ isOpen }"
          name="before-button-content" />
      </template>
    </b-button>
    <div v-if="isOpen">
      <transition name="vertical-slide">
        <div
          ref="popupContent"
          class="dropdown-body"
          :style="dropdownPosition"
          :class="[`align-${align}`, `opens-${opens}`]">
          <resize-sensor @resize="onContentResize" />
          <slot
            name="body-content"
            v-bind="{ isOpen, closeDropdown }" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import { mixin as clickaway } from 'vue-clickaway'

  export default {
    name: 'IngestDropdown',
    components: {
    },
    mixins:
      [clickaway,]
    ,
    props: {
      opens: { type: String, default: 'down', },
      align: { type: String, default: 'left', },
      forceClose: { type: Boolean, default: false, },
      startOpen: { type: Boolean, default: false, },
      alwaysOpen: { type: Boolean, default: false, },
      openUp: { type: Boolean, default: false, },
      label: {
        type: String,
        default: '',
        rect: null,
        contentRect: null,
      },
      activeClass: {
        type: String,
        default: '',
      },
      unactiveClass: {
        type: String,
        default: '',
      },
      hoverClass: {
        type: String,
        default: '',
      },
      unhoverClass: {
        type: String,
        default: '',
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        isOpen: false,
        rect: null,
        contentRect: { width: 0, height: 0, top: 0, left: 0, },
        pageRect: { width: 0, height: 0, top: 0, left: 0, },
        pageListener: null,
      }
    },
    computed: {
      dropdownPosition () {
        if (this.openUp) {
          return {
            'position': 'absolute',
            'bottom': '100%',
          }
        }
        return {
          'position': 'absolute',
          'top': '100%',
        }
      },
    },
    watch: {
      forceClose (value) {
        if (value) {
          this.closeDropdown()
        }
      },
    },
    destroyed () {
      window.removeEventListener('resize', this.pageListener)
    },
    mounted () {
      this.$nextTick(() => {
        this.pageListener = window.addEventListener('resize', this.onPageResize)
      })

      if (this.startOpen || this.alwaysOpen) {
        Vue.nextTick(() => {
          Vue.nextTick(() => {
            this.openDropdown()
          })
        })
      }
    },
    methods: {
      onPageResize () {
        this.pageRect = { height: window.innerHeight, width: window.innerWidth, }
      },
      onContentResize (event) {
        this.contentRect = event
      },
      toggleDropdown () {
        if (this.isOpen) {
          this.closeDropdown()
        } else {
          this.openDropdown()
        }
      },
      openDropdown () {
        this.rect = this.$el.getBoundingClientRect()
        this.isOpen = true
        this.$emit('open', true)
      },
      closeDropdown () {
        let wasOpen = this.isOpen
        this.isOpen = false || this.alwaysOpen
        if (wasOpen) {
          this.$emit('open', false)
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

.ingest-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: visible;

  .b-button {
    display: flex;
    flex-grow: 1;
  }
}

.b-button.isOpen {
  z-index: 101;
}

.dropdown-body {
  z-index: 100;
  width: auto;
}

.vertical-slide-enter-active, .vertical-slide-leave-active {
  &.dropdown-body {
    transition: all .3s ease;
  }
}
.vertical-slide-enter, .vertical-slide-leave-to {
  &.dropdown-body {
    transform: scaleY(0);
  }
}
.b-button {
  height: inherit;
  width: inherit;
}
.page-sizer {
  position: fixed;
  height: 100%;
  width: 100%;
}
</style>
