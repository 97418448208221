<template>
  <div class="order-config-editor">
    <b-field>
      <b-switch v-model="orderEnabled"
                type="is-info">
        Use custom sorting
      </b-switch>
    </b-field>

    <div
      v-if="orderEnabled"
      class="benchmark-inputs">
      <OrderEditor v-model="inputs" />
    </div>
  </div>
</template>

<script>

  import ModuleConfigMixin from '@/components/reports/config/modules/common/ModuleConfigMixin'
  import OrderEditor from '@/components/reports/config/order/OrderEditor'

  export default {
    name: 'BenchmarkConfigEditor',
    data () {
      return {
        defaultValue: null,
      }
    },
    mixins: [
      ModuleConfigMixin,
    ],
    components: {
      OrderEditor,
    },
    computed: {
      orderEnabled: {
        get () {
          return ![ null, undefined, ].includes(this.inputs)
        },
        set (enabled) {
          if (enabled) {
            this.inputs = {}
          } else {
            this.inputs = undefined
          }
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.benchmark-inputs {
  margin-top: 30px;
}
</style>
