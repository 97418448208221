<template>
  <div
    :key="tabs.length"
    class="dasboard-tabs">
    <div
      v-for="(tab, i) in tabs"
      :key="i"
      :class="{ active: currentTab === tab.id }"
      class="tab"
      @click="selectTab(tab.id)">
      <div
        v-if="tab.icon"
        class="icon-container">
        <i class="material-icons"> {{ tab.icon }}</i>
      </div>
      <div class="tab-label">
        {{ tab.label }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      tabs: {
        type: Array,
        default: () => [],
      },

      value: {
        type: String,
        default: '',
      },
    },
    data () {
      return {}
    },
    computed: {
      currentTab: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {
      selectTab (tab) {
        this.currentTab = tab
      },
    },
  }
</script>
<style scoped>
.dasboard-tabs {
    display: flex;
}
.tab {
  width: 100%;
  border-radius: 0;
  border-left: 0.5px solid #e0e0e0;
  border-right: 0.5px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 0 !important;

    &:hover {
        background-color: #f5f5f5;
    }
    &.active {
        background-color: #994095;
        color: white;

        .icon-container {
            border-color: #B654B1;
            i {
                color: white;
            }
        }
    }

}

.icon-container {
  display: flex;
  align-items: center;
  height: 48px;
  width: 36px;
  justify-content: center;
  border-right: 1px solid #F5F5F5;
  i {
    font-size: 18px;
    color: #D2D2D4;
  }
}

.tab-label {
  padding-left: 10px;
}
</style>
