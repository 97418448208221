<template>
  <div class="grid">
    <section class="section-steps is-hidden-touch">
      <b-steps
        v-model="sectionInView"
        size="is-small"
        position="is-right"
        label-position="left"
        type="is-primary"
        :has-navigation="false"
        :destroy-on-hide="true"
        vertical
        @input="scrollIntoView">
        <b-step-item
          v-for="(section, sectionIndex) in layout"
          :key="sectionIndex"
          :label="section.name"
          :clickable="true"
          icon="circle"
          :value="`${sectionIndex}`" />
      </b-steps>
    </section>
    <div class="container">
      <div class="grid-controls">
        <b-button
          size="is-small"
          @click="addSection">
          Add Section
        </b-button>
        <div class="edit-mode-switch">
          <b-switch v-model="isEditMode">
            Edit Mode
          </b-switch>
        </div>
      </div>
      <div :class="{ 'edit-mode': isEditMode }">
        <vuedraggable>
          <div
            v-for="(section, sectionIndex) in layout"
            :id="`section-${sectionIndex}`"
            :key="sectionIndex"
            :ref="`section-${sectionIndex}`"
            :data-section="sectionIndex"
            class="grid-section">
            <div>
              <div
                v-if="isEditMode"
                class="card-header collapse-header"
                role="button">
                <b-input
                  v-model="section.name"
                  class="section-name-input transparent-input"
                  placeholder="Section Name" />
                <div class="section-controls">
                  <b-button
                    :disabled="!(sectionIndex > 0 && layout.length > 1)"
                    size="is-small"
                    icon-right="arrow-up"
                    @click="moveSectionUp(sectionIndex)" />
                  <b-button
                    :disabled="
                      !(sectionIndex < layout.length - 1 && layout.length > 1)
                    "
                    size="is-small"
                    icon-right="arrow-down"
                    @click="moveSectionDown(sectionIndex)" />
                  <b-button
                    size="is-small"
                    class="control-item"
                    @click="addColumn(sectionIndex)">
                    Add Column
                  </b-button>
                  <b-button
                    size="is-small"
                    class="control-item"
                    type="is-danger"
                    @click="deleteSection(sectionIndex)">
                    Delete Section
                  </b-button>
                </div>
              </div>
              <b-collapse animation="slide">
                <template #trigger="props">
                  <div
                    v-if="!isEditMode"
                    class="card-header collapse-header"
                    role="button"
                    :aria-expanded="props.open">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
                    <p class="section-name ml-2">
                      {{ section.name }}
                    </p>
                  </div>
                </template>

                <vuedraggable class="columns is-multiline grid-columns">
                  <div
                    v-for="(module, colIndex) in section.columns"
                    :key="colIndex"
                    class="column grid-column"
                    :class="`is-${module.size}`">
                    <div
                      v-if="isEditMode"
                      class="column-controls">
                      <b-dropdown
                        append-to-body
                        aria-role="menu"
                        position="is-bottom-left"
                        trap-focus>
                        <template #trigger>
                          <b-icon icon="dots-horizontal" />
                        </template>

                        <b-dropdown-item
                          custom
                          aria-role="menu-item">
                          <div class="controls-dropdown-container">
                            <div class="control-item">
                              <div class="columns">
                                <div class="column">
                                  <b-button
                                    :disabled="
                                      !(
                                        colIndex > 0 &&
                                        layout[sectionIndex].columns.length > 1
                                      )
                                    "
                                    size="is-small"
                                    expanded
                                    icon-right="arrow-left"
                                    @click="
                                      moveColumnLeft(sectionIndex, colIndex)
                                    " />
                                </div>

                                <div class="column">
                                  <b-button
                                    :disabled="
                                      !(
                                        colIndex <
                                        layout[sectionIndex].columns.length -
                                        1 &&
                                        layout[sectionIndex].columns.length > 1
                                      )
                                    "
                                    size="is-small"
                                    expanded
                                    icon-right="arrow-right"
                                    @click="
                                      moveColumnRight(sectionIndex, colIndex)
                                    " />
                                </div>
                              </div>
                            </div>
                            <div class="control-item">
                              <b-field size="is-small">
                                <b-numberinput
                                  v-model="module.size"
                                  min="1"
                                  max="12"
                                  size="is-small"
                                  controls-alignment="right" />
                              </b-field>
                            </div>

                            <div class="control-item">
                              <b-button
                                size="is-small"
                                class="control-item"
                                type="is-primary"
                                expanded
                                @click="showWidgetPicker(sectionIndex, colIndex)">
                                Add widget
                              </b-button>
                            </div>
                            <div class="control-item">
                              <b-button
                                size="is-small"
                                class="control-item"
                                type="is-danger"
                                expanded
                                @click="deleteColumn(sectionIndex, colIndex)">
                                Delete
                              </b-button>
                            </div>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <div
                      v-for="(component, componentIndex) in module.components"
                      :key="componentIndex"
                      class="component-container">
                      <component
                        :is="component.componentType"
                        class="component" />
                      <div
                        v-if="isEditMode"
                        class="component-controls">
                        <b-dropdown
                          append-to-body
                          aria-role="menu"
                          position="is-bottom-left"
                          trap-focus>
                          <template #trigger>
                            <b-icon icon="dots-horizontal" />
                          </template>

                          <b-dropdown-item
                            custom
                            aria-role="menu-item">
                            <div class="controls-dropdown-container">
                              <div class="control-item columns">
                                <div class="column">
                                  <b-button
                                    :disabled="
                                      !(
                                        componentIndex > 0 &&
                                        layout[sectionIndex].columns[colIndex]
                                          .components.length > 1
                                      )
                                    "
                                    size="is-small"
                                    expanded
                                    icon-right="arrow-up"
                                    @click="
                                      moveWidgetUp(
                                        sectionIndex,
                                        colIndex,
                                        componentIndex
                                      )
                                    " />
                                </div>

                                <div class="column">
                                  <b-button
                                    :disabled="
                                      !(
                                        componentIndex <
                                        layout[sectionIndex].columns[colIndex]
                                          .components.length -
                                        1 &&
                                        layout[sectionIndex].columns[colIndex]
                                          .components.length > 1
                                      )
                                    "
                                    size="is-small"
                                    expanded
                                    icon-right="arrow-down"
                                    @click="
                                      moveWidgetDown(
                                        sectionIndex,
                                        colIndex,
                                        componentIndex
                                      )
                                    " />
                                </div>
                              </div>
                              <div class="control-item">
                                <b-button
                                  size="is-small"
                                  class="control-item"
                                  type="is-danger"
                                  expanded
                                  @click="
                                    deleteWidget(
                                      sectionIndex,
                                      colIndex,
                                      componentIndex
                                    )
                                  ">
                                  Delete
                                </b-button>
                              </div>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>

                    <div
                      v-if="module.components.length === 0"
                      class="module-placeholder" />
                  </div>
                </vuedraggable>
              </b-collapse>
            </div>
          </div>
        </vuedraggable>
      </div>
    </div>
    <b-modal
      v-model="isCardModalActive"
      :width="640"
      scroll="keep">
      <form action="">
        <div
          class="modal-card"
          style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Select Widget
            </p>
          </header>
          <section class="modal-card-body">
            <b-tabs>
              <b-tab-item label="Kpis">
                <div class="widget-item-container">
                  <h2
                    v-for="(widget,i) in widgets.kpis"
                    :key="i"
                    class="mt-4">
                    <b-tooltip
                      position="is-right"
                      type="is-white"
                      class="widget-picker-tooltip"
                      multilined>
                      <b-checkbox
                        v-model="selectedWidgetsToAdd"
                        :native-value="widget">
                        {{ widget.name }}
                      </b-checkbox>
                      <template #content>
                        <img
                          :src="widget.thumbnail"
                          alt="" />
                      </template>
                    </b-tooltip>
                  </h2>
                </div>
              </b-tab-item>

              <b-tab-item label="Tables" />
            </b-tabs>
          </section>
          <footer class="modal-card-foot">
            <b-button
              label="Close"
              @click="closeWidgetPicker" />
            <b-button
              label="Add Widget"
              type="is-primary"
              @click="addWidgets" />
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import Kpis from '@/components/dashboard/Kpis.vue'
  import LargeCoverWins from '@/assets/images/widgets/placeholders/large_cover_wins.svg?inline'
  import fchNetSales from '@/assets/images/widgets/placeholders/foh_net_sales.svg?inline'
  import bohCosts from '@/assets/images/widgets/placeholders/boh_costs.svg?inline'
  import vuedraggable from 'vuedraggable'
  export default {
    name: 'GridBuilder',
    components: {
      vuedraggable,
      LargeCoverWins,
      fchNetSales,
      bohCosts,
    },
    props: {},
    data () {
      return {
        isCardModalActive: false,
        sectionInView: '0',
        selectedWidgetsToAdd:[],
        currentSelectionIndex: 0,
        currentColIndex: 0,

        widgets: {
          kpis: [
            {
              name: 'Net Sales',
              widget: Kpis,
              thumbnail: require('@/assets/images/widgets/thumbnails/covers.png'),
            },
            {
              name: 'Large Cover Wins',
              widget: LargeCoverWins,
              thumbnail: require('@/assets/images/widgets/thumbnails/large_cover_wins.png'),
            },
            {
              name: 'FOH % of Net Sales',
              widget: fchNetSales,
              thumbnail: require('@/assets/images/widgets/thumbnails/foh_net_sales.png'),
            },
            {
              name: 'BOH Costs',
              widget: bohCosts,
              thumbnail: require('@/assets/images/widgets/thumbnails/boh_costs.png'),
            },
          ],
        },
        layout: [
          {
            name: 'Section',
            columns: [
              { size: 6, components: [], },
              { size: 6, components: [], },
            ],
          },
        ],
        isEditMode: true,
      }
    },

    methods: {
      addSection () {
        this.layout.push({
          name: 'Section',
          columns: [
            { size: 6, components: [], },
            { size: 6, components: [], },
          ],
        })
      },
      moveSectionUp (sectionIndex) {
        const tempValue = this.layout[sectionIndex]
        this.$set(this.layout, sectionIndex, this.layout[sectionIndex - 1])
        this.$set(this.layout, sectionIndex - 1, tempValue)
      },
      moveSectionDown (sectionIndex) {
        const tempValue = this.layout[sectionIndex]
        this.$set(this.layout, sectionIndex, this.layout[sectionIndex + 1])
        this.$set(this.layout, sectionIndex + 1, tempValue)
      },
      deleteSection (sectionIndex) {
        this.layout.splice(sectionIndex, 1)
      },
      addColumn (sectionIndex) {
        this.layout[sectionIndex].columns.push({
          order: 1,
          size: 3,
          components: [],
        })
      },
      moveColumnLeft (sectionIndex, colIndex) {
        const tempValue = this.layout[sectionIndex].columns[colIndex]
        this.$set(
          this.layout[sectionIndex].columns,
          colIndex,
          this.layout[sectionIndex].columns[colIndex - 1]
        )
        this.$set(this.layout[sectionIndex].columns, colIndex - 1, tempValue)
      },
      moveColumnRight (sectionIndex, colIndex) {
        const tempValue = this.layout[sectionIndex].columns[colIndex]
        this.$set(
          this.layout[sectionIndex].columns,
          colIndex,
          this.layout[sectionIndex].columns[colIndex + 1]
        )
        this.$set(this.layout[sectionIndex].columns, colIndex + 1, tempValue)
      },
      deleteColumn (sectionIndex, colIndex) {
        this.layout[sectionIndex].columns.splice(colIndex, 1)
      },
      showWidgetPicker (sectionIndex, colIndex) {
        this.selectedWidgetsToAdd = []
        this.currentSelectionIndex = sectionIndex
        this.currentColIndex = colIndex
        this.isCardModalActive = true
      },
      closeWidgetPicker () {
        this.isCardModalActive = false
      },

      addWidgets () {

        this.selectedWidgetsToAdd.forEach(widget => {
          this.addWidget(widget)
        })
        this.isCardModalActive = false
      },
      addWidget (widgetData) {
        this.layout[this.currentSelectionIndex].columns[this.currentColIndex].components.push({
          componentType: widgetData.widget,
          name: widgetData.name,
        })

      },

      moveWidgetUp (sectionIndex, colIndex, widgetIndex) {
        const tempValue =
          this.layout[sectionIndex].columns[colIndex].components[widgetIndex]
        this.$set(
          this.layout[sectionIndex].columns[colIndex].components,
          widgetIndex,
          this.layout[sectionIndex].columns[colIndex].components[widgetIndex - 1]
        )
        this.$set(
          this.layout[sectionIndex].columns[colIndex].components,
          widgetIndex - 1,
          tempValue
        )
      },
      moveWidgetDown (sectionIndex, colIndex, widgetIndex) {
        const tempValue =
          this.layout[sectionIndex].columns[colIndex].components[widgetIndex]
        this.$set(
          this.layout[sectionIndex].columns[colIndex].components,
          widgetIndex,
          this.layout[sectionIndex].columns[colIndex].components[widgetIndex + 1]
        )
        this.$set(
          this.layout[sectionIndex].columns[colIndex].components,
          widgetIndex + 1,
          tempValue
        )
      },
      deleteWidget (sectionIndex, colIndex, widgetIndex) {
        this.layout[sectionIndex].columns[colIndex].components.splice(
          widgetIndex,
          1
        )
      },

      scrollIntoView (sectionIndex) {
        this.$refs[`section-${sectionIndex}`][0].scrollIntoView({
          behavior: 'smooth',
        })
      },

      //Todo: attach hander for scction scoll into view
      sectionScrollIntoViewHandler (sectionIndex) {
        this.sectionInView = `${sectionIndex}`
      },
    },
  }
</script>

<style lang="scss" scoped>
.grid {
  width: 100%;
  overflow-y: auto;
  background: $grayscale-7;
  position: relative;
  padding-bottom: 50px;

  .section-steps {
    border-radius: 8px;
    z-index: 10;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
}

.container {
  padding: 15px;
}

.grid-controls {
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  .edit-mode-switch {
    margin-left: auto;
    margin-right: 0;
  }
}

.section-controls {
  display: flex;
  align-items: center;
  padding: 4px;
  margin-left: auto;

  .control-item {
    margin: 0;
    margin-left: 15px;
  }
}

.component-container,
.module-placeholder {
  margin-bottom: 10px;
  background-color: $grayscale-9;
  box-shadow: $box-shadow-1;
  border: none;
  border-radius: 8px;
  display: flex;
  flex: 1;
  position: relative;
  .component {
    width: 100%;
  }

  .component-controls {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.module-placeholder {
  min-height: 50px;
}

.grid-section {
  margin: 20px 0;

  .collapse-header {
    border: none;
    border-bottom: $border-1;
    box-shadow: none;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
  }

  .section-name {
    font-size: 14px;
    color: $grayscale-2;
    text-transform: capitalize;
  }

  .section-name-input {
    font-size: 16px;
  }

  .card-header {
    box-shadow: none;
  }

  .grid-columns {
    margin-top: 10px;

    .grid-column {
      position: relative;
      display: flex;
      flex-direction: column;
      .column-controls {
        position: absolute;
        width: 20px;
        top: -13px;
        right: 10px;
      }
    }
  }
}

.controls-dropdown-container {
  padding: 10px;
  background: $grayscale-9;
  width: 150px;
  border-radius: 5px;
  border: $border-1;

  .control-item {
    margin-bottom: 5px;
  }
}

.step {
  height: 500px;
  margin: 50px;
}

.widget-item-container {
  padding: 30px;
  margin: 10px;

  h2 {
    font-weight: 500;
    font-size: 13px;
  }
  .widget-image {
    width: 100%;
    height: auto;
  }
}
</style>
