<template>
  <div
    class="dimension-filter-menu"
    :class="{vertical}">
    <template v-if="readOnly">
      <div
        v-for="(filter, idx) in filters"
        :key="idx"
        class="small-summary orange-background">
        <ingest-dropdown
          class="summary-dropdown orange-background"
          type="button">
          <div
            slot="button-content"
            class="flex-row-center-center">
            <v-icon name="filter" />
            <dimension-filter-summary
              :filter="filter"
              :dimension="allDimensions[filter.dimension]" />
          </div>
          <div
            slot="body-content"
            class="summary-container secondary-01-background">
            <dimension-filter-summary
              :filter="filter"
              :dimension="allDimensions[filter.dimension]"
              long />
          </div>
        </ingest-dropdown>
      </div>
    </template>
    <template v-else>
      <dimension-filter
        v-for="(filter, idx) in filters"
        :key="filter.dimension"
        :read-only="readOnly"
        :opens="vertical ? 'left' : 'right'"
        :removable="addRemove && !readOnly"
        :filter="filter"
        :start-edit="addingFilter"
        :other-filters="[
          ...externalFilters, ...filters
        ].filter(otherFilter => otherFilter != filter)"
        @update:filter="updateFilter(idx, $event)"
        @delete="deleteFilter(idx)" />
      <div
        v-if="unfilteredDimensions.length > 0"
        class="new-filter">
        <ingest-dropdown
          v-if="addRemove && !readOnly"
          :force-close="!addingFilter"
          :opens="'down'"
          :align="vertical ? 'center' : 'left'"
          unhover-class="secondary-02-text"
          hover-class="secondary-01-text"
          @open="addingFilter = $event">
          <template slot="button-content">
            <v-icon
              name="plus-circle" />
            Add filter
          </template>
          <div
            slot="body-content"
            class="new-filter-options flex-column secondary-01-background">
            <b-button
              v-for="dimension in unfilteredDimensions"
              :key="dimension.key"
              variant="none"
              hover-class="medium"
              @click="addFilter(dimension)">
              {{ dimension.name }}
            </b-button>
          </div>
        </ingest-dropdown>
      </div>
    </template>
  </div>
</template>

<script>

  import DimensionFilter from '@/components/controls/filter/DimensionFilter'
  import DimensionFilterSummary from '@/components/controls/filter/DimensionFilterSummary'
  import IngestDropdown from '@/components/common/IngestDropdown'

  import Vue from 'vue'

  export default {
    name: 'DimensionFilterMenu',
    components: {
      DimensionFilter,
      DimensionFilterSummary,
      IngestDropdown,
    },
    props: {
      readOnly: { type: Boolean, default: false, },
      addRemove: { type: Boolean, default: true, },
      vertical: { type: Boolean, default: false, },
      filters: { type: Array, default: Array, },
      dimensionOptions: { type: Array, default: Array, },
      externalFilters: { type: Array, default: Array, },
    },
    data () {
      return {
        addingFilter: false,
      }
    },
    computed: {
      allDimensions () {
        return this.$store.getters['metadata/dimensions']
      },
      unfilteredDimensions () {
        let dimensions = [...this.dimensionOptions, ]
        this.filters.forEach(filter => {
          dimensions = dimensions.filter(dimension => dimension.key != filter.dimension)
        })
        return dimensions
      },
    },
    methods: {
      addFilter (dimension) {
        this.$emit('update:filters', [...this.filters, { dimension: dimension.key, }, ])
        Vue.nextTick(() => {
          this.addingFilter = false
        })
      },
      updateFilter (idx, newFilter) {
        if (newFilter == null) {
          this.deleteFilter(idx)
        } else {
          let newFilters = [...this.filters, ]
          newFilters.splice(idx, 1, newFilter)
          this.$emit('update:filters', newFilters)
        }
      },
      cancelAdd () {
        this.addingFilter = false
      },
      deleteFilter (idx) {
        let newFilters = [...this.filters, ]
        newFilters.splice(idx, 1)
        this.$emit('update:filters', newFilters)
      },
    },
  }

</script>

<style lang="scss" scoped>

.dimension-filter-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  &.vertical {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
  }
}

.new-filter-menu {
  position: absolute;
  right: 0;
  width: 100%;
}

.new-filter-options {
  width: 100%;
  overflow: auto;
  height: 300px;

  .card-body {
    display: flex;
    flex-direction: column;
    z-index: 5;
    align-items: flex-start;
    padding: 0;
  }

  button {
    text-align: left;
    padding: 8px;
  }
}

.new-filter {
  position: relative;
  text-align: left;
}

.summary-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  box-shadow: 0px 3px 6px 0px rgba($darkGreen, .5);
  border-top-left-radius: 2 * $remGrid !important;
  border-top-right-radius: 2 * $remGrid !important;
  border-bottom-left-radius: 2 * $remGrid !important;
  border-bottom-right-radius: 2 * $remGrid !important;
  overflow: visible;
  vertical-align: center;
  max-height: 64 * $gridBase;
  overflow: auto;
  padding: 4 * $gridBase;
}

.small-summary {
  position: relative;
  display: flex;
  align-items: flex-start;
  box-shadow: 0px 3px 6px 0px rgba(249, 169, 107, .5);
  border-top-left-radius: 4 * $gridBase !important;
  border-bottom-left-radius: 4 * $gridBase !important;
  border-top-right-radius: 2 * $gridBase !important;
  border-bottom-right-radius: 2 * $gridBase !important;
  margin: 2 * $gridBase;
  border: none;
  overflow: visible;
  vertical-align: center;

  color: $darkGreen;

   * {
    color: $darkGreen;
   }

  .summary-dropdown {
    min-width: 16 * $remGrid;
    margin: $gridBase;
  }

  * {
    color: #4A4D50;
  }
}
.summary-label {
  padding: 2 * $remGrid;
}
.icon { margin: $remGrid; }
</style>
