<template>
  <div class="order-editor">
    <TypeSelector v-model="vModel"
                  object-type="Order"
                  v-bind="subObjectBinds"
                  :type-options="orderTypes" />
  </div>
</template>

<script>

  import MeasureOrderEditor from '@/components/reports/config/order/MeasureOrderEditor'
  import DimensionOrderEditor from '@/components/reports/config/order/DimensionOrderEditor'
  import TypeSelector from '@/components/reports/config/common/TypeSelector'
  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'

  export default {
    name: 'OrderEditor',
    components: {
      TypeSelector,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    data () {
      return {
        orderTypes: [
          {
            key: 'DimensionOrder',
            label: 'by Dimension',
            editor: DimensionOrderEditor,
          },
          {
            key: 'MeasureOrder',
            label: 'by Measure',
            editor: MeasureOrderEditor,
          },
        ],
      }
    },
  }

</script>

<style lang="scss" scoped>

.order-editor {
  display: flex;
  flex-direction: column;
}

</style>
