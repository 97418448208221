<template>
  <div class="error-view">
    <div class="frowny-face">
      <span class="material-icons">sentiment_very_dissatisfied</span>
    </div>
    <div class="whoops">
      Whoops!
    </div>
    <div class="message">
      Data failed to load
    </div>
    <b-button
      class="retry-button"
      @click="$emit('reload')">
      <span class="material-icons">refresh</span> Reload
    </b-button>
  </div>
</template>

<script>

  export default {
    name: 'ErrorView',
    components: {

    },
    props: {
      name: { type: String, default: '', },
    },
  }
</script>

<style lang="scss" scoped>
.error-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 0;
}

.frowny-face {
  font-size: 16 * $gridBase;
  line-height: 16 * $gridBase;
  color: $ui-04;
}

.whoops {
  font-size: $h1;
  font-weight: $emp;
  color: $secondary-06;
}

.message {
  font-size: $h2;
  color: $secondary-05;
}

.retry-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $gridBase 2 * $gridBase;
  margin: 2 * $gridBase;
  color: $ui-07;
  font-size: $h3;
  font-weight: $emp;
  border-radius: 2 * $gridBase;

  background: rgba($ui-warning, 0.75);
  @include shadow(false, rgba(#fd9b66, 0.75));

  &:hover {
    background: rgba($ui-warning, 0.9);
    @include shadow(false, rgba(#fd9b66, 0.9));
  }

}
</style>
