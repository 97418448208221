<template>
  <div
    :key="control.id"
    class="offset-select">
    <div class="input-label">
      {{ control.name }}
    </div>
    <b-field>
      <b-dropdown
        v-model="selection"
        aria-role="list">
        <template #trigger="{ active }">
          <button
            class="button dropdown-button"
            type="is-light">
            <div class="option-selected-label">
              {{ !!selection ? selection.value : "" }}
            </div>

            <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
          </button>
        </template>
        <template v-for="option in control.spec.options">
          <b-dropdown-item
            v-if="isOffsetViable(option)"
            :key="option.key"
            :value="option"
            aria-role="listitem">
            {{ option.value }}
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </b-field>
  </div>
</template>

<script>
  import controlSelectorMixin from '@/components/controls/controlSelectorMixin'

  export default {
    name: 'OffsetSelect',
    components: {},
    mixins: [controlSelectorMixin,],
    data () {
      return {
        watcher: null,
      }
    },
    mounted () {
      this.selection = [
        ...this.control.spec.options.filter(
          (o) => o.is_default && this.isOffsetViable(o)
        ),
        ...this.control.spec.options.filter(this.isOffsetViable),
        ...this.control.spec.options,
      ][0]
      this.watcher = this.$store.watch((state, getters) => {
        let start = new Date(getters['datePicker/appliedEffectiveRangeStart'])
        let end = new Date(getters['datePicker/appliedEffectiveRangeEnd'])
        return (end - start) / (1000 * 60 * 60 * 24) + 1
      }, this.onDateChange)
    },
    beforeDestroy () {
      this.watcher()
    },

    methods: {
      onDateChange (duration) {
        if (!!this.selection && this.selection.max_duration < duration) {
          this.selection = [
            ...this.control.spec.options.filter(
              (o) => o.is_default && this.isOffsetViable(o)
            ),
            ...this.control.spec.options.filter(this.isOffsetViable),
            ...this.control.spec.options,
          ][0]
        }
      },
      isOffsetViable (offsetOption) {
        if (this.$store.getters['datePicker/loading']) {
          return true
        }
        let start = new Date(
          this.$store.getters['datePicker/appliedEffectiveRangeStart']
        )
        let end = new Date(
          this.$store.getters['datePicker/appliedEffectiveRangeEnd']
        )
        let durationDays = (end - start) / (1000 * 60 * 60 * 24) + 1
        return offsetOption.max_duration >= durationDays
      },
    },
  }
</script>

<style lang="scss" scoped>
.offset-select {
  display: flex;
  margin-left: 14px;
}

.input-label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 13px;
}

.dropdown-button {
  width: 250px;
  padding: 0 15px 0 10px !important;
  border: $border-1 !important;

  .option-selected-label {
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    text-align: left;
    font-size: 14px;
  }
}
</style>
