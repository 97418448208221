var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',{staticClass:"column-series"},[_c('defs',[_vm._l((_vm.data),function(datum,i){return [_c('filter',{key:`filter-${i}`,attrs:{"id":_vm.$id('shadow-' + i),"width":"12","height":"12","x":-6,"y":-6}},[_c('feDropShadow',{attrs:{"dy":"0","dx":"0","std-deviation":"2","flood-color":_vm.measureDatumColor(_vm.measure, datum),"flood-opacity":.75}})],1)]})],2),_vm._l((_vm.data),function(datum,i){return [(!isNaN(_vm.getYPosition(datum[_vm.measure.key])))?_c('rect',{key:`bar-${i}`,class:{
        'clickable': _vm.hasClickableDimensions,
      },style:({
        'stroke-width': _vm.highlightEnabled && _vm.highlightedData.includes(datum) ? '2' : '0',
        'fill-opacity': '.75',
        'opacity': _vm.highlightEnabled ? (!_vm.highlightedData.includes(datum) ? '.4' : '1') : '1',
        'filter': _vm.tooltipEnabled && !_vm.highlightedData.includes(datum) ? 'none' : `url(#${_vm.$id('shadow-' + i)})`}),attrs:{"stroke":_vm.measureDatumColor(_vm.measure, datum),"fill":_vm.measureDatumColor(_vm.measure, datum),"x":_vm.columnScale(_vm.getDimensionVal(_vm.primaryDimensionKeys, datum)) + _vm.secondaryScale(_vm.columnIdx),"y":_vm.getYPosition(datum[_vm.measure.key]) > _vm.getYPosition(0) ? _vm.getYPosition(0) + 4 : _vm.getYPosition(datum[_vm.measure.key]),"rx":4,"ry":4,"width":_vm.secondaryScale.bandwidth(),"height":Math.max(_vm.getYPosition(datum[_vm.measure.key]) > _vm.getYPosition(0)
        ? _vm.getYPosition(datum[_vm.measure.key]) - _vm.getYPosition(0) - 4
        : _vm.getYPosition(0) - _vm.getYPosition(datum[_vm.measure.key]) - 4, 0)},on:{"click":function($event){return _vm.$emit('click', datum)}}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }