<template>
  <div class="benchmark-config-editor">
    <b-field class="">
      <b-switch
        v-model="repeatEnabled"
        type="is-info">
        Repeat the time range for this measure.
      </b-switch>
    </b-field>

    <div
      v-if="repeatEnabled"
      class="benchmark-inputs">
      <b-field
        class="input-field"
        label="Count (repeat the time range this many times):">
        <b-numberinput v-model="inputs.count" />
      </b-field>
      <b-field
        class="input-field"
        label="Period (offset each repetition by this many days):">
        <b-numberinput v-model="inputs.period" />
      </b-field>
    </div>
  </div>
</template>

<script>

  import ModuleConfigMixin from '@/components/reports/config/modules/common/ModuleConfigMixin'

  export default {
    name: 'BenchmarkConfigEditor',
    mixins: [
      ModuleConfigMixin,
    ],
    data () {
      return {
        defaultValue: null,
      }
    },
    computed: {
      repeatEnabled: {
        get () {
          return ![ null, undefined, ].includes(this.inputs)
        },
        set (benchmarkEnabled) {
          if (benchmarkEnabled) {
            this.inputs = {
              count: 0,
              period: -7,
            }
          } else {
            this.inputs = null
          }
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.benchmark-inputs {
  margin-top: 30px;
}
</style>
