<template>
  <div
    class="container-fluid">
    <div class="container">
      <div class="header columns">
        <h2>Pdf Reports</h2>
        <div class="control-bar">
          <router-link :to="{name: 'pdf_report_settings', params: {report_id: 'new'}}">
            <b-button
              type="is-success is-light"
              class="action-btn"
              icon-left="plus">
              Create a Report pdf
            </b-button>
          </router-link>
        </div>
      </div>
      <div>
        <div class="columns is-vcentered report-table-filters">
          <div class="column pdf-search-input m-0 p-0">
            <b-input
              v-model="reportSearchInput"
              custom-class="no-border"
              placeholder="Search..."
              type="search"
              icon="magnify" />
          </div>
        </div>
      </div>
      <b-table
        :data="resultsToShow"
        :loading="isLoading"
        :hoverable="true"
        class="pdf-reports-table">
        <b-table-column
          v-slot="props"
          sortable
          field="name"
          label="Name">
          {{ props.row.name }}
        </b-table-column>

        <!-- <b-table-column v-slot="props"
                        field="reports"
                        label="Reports">
          <b-tag
            class="data-tags">
            {{ props.row.moduleIds.length }}
          </b-tag>
        </b-table-column> -->

        <b-table-column
          v-slot="props"
          field="Actions"
          width="50"
          label="Actions">
          <div class="actions">
            <b-button
              class="action-btn"
              icon-right="content-copy"
              @click="confirmDuplicate(props.row)" />
            <router-link :to="{name: 'pdf_report_editor', params: {report_id: props.row.key}}">
              <b-button
                type="is-primary"
                class="action-btn"
                icon-right="lead-pencil" />
            </router-link>
          </div>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered mt-5 mb-5">
            <b-tag
              v-if="!isLoading"
              class="data-tags"
              rounded
              size="is-medium">
              No Reports Found 🥥
            </b-tag>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'
  export default {
    name: 'PdfReportLibraryPage',
    data () {
      return {
        allPdfReports: [],
        isLoading: false,
        reportSearchInput: '',
        reportsFilterByActiveTabs: 0,
      }
    },

    computed : {
      resultsToShow () {
        if (this.reportSearchInput) {
          return this.searcher.search(this.reportSearchInput)
        }
        return this.allPdfReports
      },

      searcher () {
        const searcher = new FuzzySearch(this.allPdfReports, ['name',])
        return searcher
      },

    },

    async mounted () {
      this.loadAllPdfReports()
    },

    methods: {
      async loadAllPdfReports () {
        this.isLoading = true
        const response = await this.$apis.pdfReports.getAllPdfReports()

        this.allPdfReports = response ? response : []

        this.isLoading = false
      },

      async confirmDuplicate (report) {
        this.$buefy.dialog.confirm({
          title: 'Duplicate Report',
          message: 'Are you sure you want to duplicate this report?',
          confirmText: 'Duplicate',
          onConfirm: () => this.duplicateReport(report ),
        })
      },

      async duplicateReport (report) {
        this.isLoading = true

        const response = await this.$apis.pdfReports.duplicatePdfReport(
          report.key, { name:  `${report.name} - Copy`, }
        )

        this.$router.push({ name: 'pdf_report_editor', params: { report_id: response.key, }, })

        this.isLoading = false
      },
    },

  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: white;
  width: 100%;
  padding: 20px 14px;
  overflow: auto;

  .header {

    margin-top: 30px;

    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .pdf-reports-table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
  }

  .report-table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;

  }

  .data-tags {
    font-size: 12px;
    margin: 2px;
  }

  .actions {
    display: flex;

    .action-btn {
      margin: 0 5px;
    }

  }

}
</style>
