import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max, min, email, required } from 'vee-validate/dist/rules'

extend('email', {
  ...email,
  message: 'Enter a valid email',
})
extend('min', min)
extend('max', max)
extend('required', {
  ...required,
  message: 'This field is required',
})

// Add a custorm rule here rule.
// extend('is-value-ingest', {
//   validate: value => value === 'ingest',
//   message: 'This is not the magic word',
// })

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
