
export default {
  props: {
    control: { type: Object, required: true, },
  },
  computed: {
    allSelections: {
      get () {
        return this.$store.getters['controls/controlSelections']
      },
      set (selections) {
        this.$store.dispatch('controls/setSelections', selections)
      },
    },
    selection: {
      get () {
        return this.allSelections[this.control.id]
      },
      set (selection) {
        let newSelections = { ...this.$store.getters['controls/controlSelections'], }
        newSelections[this.control.id] = selection
        this.allSelections = newSelections
      },
    },
  },
  mounted () {
    this.selection = [
      ...this.control.spec.options.filter(o => o.is_default),
      ...this.control.spec.options,
    ][0]
  },
}
