<template>
  <div class="money-filter">
    <div class="filter-label">
      {{ dimension.name }}
    </div>
    <div class="inputs">
      <number-input
        v-model="start"
        class="input"
        min="0"
        step="0.01" />
      &nbsp;-&nbsp;

      <number-input
        v-model="end"
        class="input"
        min="0"
        step="0.01" />
    </div>
  </div>
</template>

<script>

  import NumberInput from '@/components/common/input/NumberInput'

  export default {
    name: 'MoneyFilter',
    components: {
      NumberInput,
    },
    props: {
      dimension: { type: Object, required: true, },
      filter: { type: Object, default: null, },
    },
    computed: {
      start: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.start / 100
          }
          return null
        },
        set (start) {
          this.updateFilter(100 * parseInt(start), 100 * this.end)
        },
      },
      end: {
        get () {
          if (this.filter && this.filter.args) {
            return this.filter.args.end / 100
          }
          return null
        },
        set (end) {
          this.updateFilter(100 * this.start, 100 * parseInt(end))
        },
      },
    },
    methods: {
      updateFilter (start, end) {
        this.$emit('update:filter', {
          type: 'between',
          dimension: this.dimension.key,
          args: { start, end, },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.filter-label {
    font-size: 13px;
    margin-right: auto;
    margin-bottom: 4px;
    color: $ui-03;
    font-weight: 300;
    text-transform: capitalize;
  }

  .inputs {
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    .date-input {
      width: 100%;
    }
  }
</style>
