<template>
  <div class="measure-order-editor">
    <CompanyMeasureRefSelect v-model="measure"
                             v-bind="subObjectBinds" />
    <b-switch
      v-model="ascending"
      type="is-info">
      Ascending
    </b-switch>
    <ValidationErrors :errors="errors" />
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyMeasureRefSelect from '@/components/reports/config/measures/CompanyMeasureRefSelect'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'CompanyMeasureRefEditor',
    mixins: [
      ObjectEditorMixin,
    ],
    components: {
      CompanyMeasureRefSelect,
    },
    computed: {
      ...mapChildren({
        measure: 'measure',
      }),
      ascending: {
        get () {
          return this.vModel.ascending != false
        },
        set (ascending) {
          this.vModel.ascending = ascending
        },
      },
    },
  }

</script>

<style lang="scss" scoped>
.company-measure-ref-editor {
  display: flex;
  flex-direction: column;
}
</style>

