<template>
  <div>
    <div class="flexing-row">
      <div class="entity-attribute-label cells">
        Entity Attribute
      </div>
      <div class="entity-attribute-name cells">
        {{ data.attibuteName }}
      </div>
      <div class="entity-type-label cells">
        Entity type
      </div>
      <div class="entity-type-value cells">
        ({{ data.dataType }}) {{ data.entityType }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EntityAttributeNode',
    components: {},
    props: {
      data: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data () {
      return {}
    },

    async mounted () {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
.flexing-row {
  border: 1px solid $grayscale-6;
  border-radius: 7px;
  background-color: white;
}

.cells {
  padding: 9px 14px;
}
.entity-attribute-label {
  background: #f0eff6;
  border-right: 1px solid $grayscale-6;
  color: #5c5487;
}

.entity-attribute-name {
  border-right: 1px solid $grayscale-6;
}
.entity-type-label {
  background: #f0eff6;
  border-right: 1px solid $grayscale-6;
  color: #5c5487;
}
.entity-type-value {
  text-transform: capitalize;
}
</style>
