<template>
  <div class="progressbar-component">
    <div
      v-if="!isLoading"
      class="progressbar-container"
      :class="{'good': content.isGood === true, 'bad': content.isGood === false}"
      @mouseover="showTooltip = true"
      @mouseleave="showTooltip = false">
      <div
        class="progress-bg">
        <div class="progress-benchmark">
          <div class="progress-tooltip">
            <div />
            <b-tooltip
              position="is-bottom"
              type="is-black"
              class="progressbar-tooltip"
              always
              :active="showTooltip">
              <template #content>
                <div class="tooltip-container">
                  <div class="tooltip-label">
                    Last Week
                  </div>
                  <div class="tooltip-value">
                    $200
                  </div>
                </div>
              </template>
            </b-tooltip>
          </div>
        </div>
        <div class="progress-current">
          <div class="progress-text">
            $1000
          </div>
        </div>
      </div>
    </div>
    <b-skeleton
      size="is-large"
      height="34"
      :active="isLoading"
      :count="1" />
  </div>
</template>

  <script>
  export default {
    name: 'ProgressBar',
    props: {
      content: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        showTooltip: false,
        isLoading: true,
      }
    },
    mounted () {
      setTimeout(() => {this.isLoading = false}, 2000)
    },
  }
  </script>

  <style lang="scss" scoped>
  .progressbar-component{
    width: 100%;
    .progressbar-tooltip{
      width: 100%;
    }
  }
    .progressbar-container {
        border: $border-1;
        border-radius: 8px;
        width: 100%;
        height: 34px;
        padding: 4px;
        &.good {
        .progress-bg {
            background-color: $primary-light-1;
            .progress-benchmark {
                background-color: $primary-light-2;
                border-right-color: #6DD9D3;
            }
            .progress-current {
                background-color: $primary;
            }
            }
        }

        &.bad {
            .progress-bg {
             background-color: $warning-light-1;
             .progress-benchmark {
                background-color: $warning-light-2;
                border-right-color:  #F57FAA;
             }
             .progress-current {
                background-color: $warning;
            }
            }
        }
        .progress-bg {
            border-radius: 4px;
            background-color: $neutral-light-1;
            width: 100%;
            height: 100%;
            position: relative;
            .progress-benchmark {
                position: absolute;
                z-index: 20;
                background-color: $neutral-light-2;
                width: 40%;
                height: 100%;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-right: 2px solid #D09DD5;
                display: flex;

                .progress-tooltip {
                    margin-left: 100%;
                    position: relative;
                    top: 20px;
                    left: 3px;

                    .tooltip-container {
                        padding: 4px;
                        text-align: center;
                    .tooltip-label {
                        font-weight: 500;
                        font-size: 12px;
                        color: $grayscale-5;
                        }
                    .tooltip-value{
                        color: $grayscale-9;
                        font-size: 14px;
                    }
                    }

                }
                }
            .progress-current {
                position: absolute;
                z-index: 10;
                width: 90%;
                height: 100%;
                display: flex;
                border-radius: 4px;
                background-color: $neutral;
                box-shadow: $box-shadow-1;
                color: $grayscale-9;
                align-items: center;
                font-weight: 500;
                font-size: 16px;

                .progress-text {
                    padding: 0 6px;
                    margin-right: 0;
                    margin-left: auto;
                    position: relative;
                    z-index: 30;
                }
            }
        }
    }

  </style>

