<template>
  <div class="beta-spec-editor">
    <label>
      JSON:
      <input
        v-model="specJSON"
        class="input" />
    </label>
    <label>
      variance type:
      <b-select v-model="varianceType">
        <option value="none"> None </option>
        <option value="over_under"> Over Under </option>
        <option value="under_over"> Under Over </option>
      </b-select>
    </label>
    <label>
      secondary_as_columns:
      <input
        v-model="secondaryAsColumns"
        type="checkbox" />
    </label>
  </div>
</template>

<script>

  export default {
    name: 'BetaSpecEditor',
    props: {
      betaSpec: { type: Object, required: true, },
    },
    computed: {
      specJSON: {
        get () {
          return JSON.stringify(this.betaSpec)
        },
        set (specJSON) {
          this.$emit('update:betaSpec', JSON.parse(specJSON))
        },
      },
      varianceType: {
        get () {
          return this.betaSpec.variance_type
        },
        set (varianceType) {
          this.$emit('update:betaSpec', { ...this.betaSpec, variance_type: varianceType, })
        },
      },
      secondaryAsColumns: {
        get () {
          return this.betaSpec.secondary_as_columns || false
        },
        set (secondaryAsColumns) {
          this.$emit('update:betaSpec', { ...this.betaSpec, secondary_as_columns: secondaryAsColumns, })
        },
      },
    },
  }

</script>

<style lang="scss" scoped>

.beta-spec-editor {
  display: flex;
  flex-direction: column;
}

</style>

