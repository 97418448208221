<template>
  <div class="step-section">
    <div class="step-header">
      <div class="step-name">
        <slot name="name" />
      </div>
      <div class="step-description">
        <slot name="description" />
      </div>
    </div>
    <slot name="custom-controls">
      <div class="step-controls">
        <slot name="controls" />
      </div>
    </slot>
  </div>
</template>

<script>

  export default {
    name: 'UploadStep',
  }

</script>

<style lang="scss" scoped>

.step-section {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding-top: 4 * $gridBase;
  padding-bottom: 8 * $gridBase;
  margin-bottom: 4 * $gridBase;
  flex: 1;
  flex-grow: 0;

  &:not(.last) {
    border-bottom: 1px solid $ui-06;
  }
}

.step-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40 * $gridBase;
  min-width: 40 * $gridBase;
  margin-right: 8 * $gridBase;
}

.step-name {
  font-size: $h3;
  font-weight: 700;
  color: $ui-01;
}

.step-description {
  font-size: $h3;
  font-weight: 400;
  color: $ui-03;
}

.step-controls {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

</style>

