<template>
  <div class="container-fluid">
    <div class="container">
      <div class="header columns mx-0 px-0 is-vcentered">
        <router-link
          :to="{ name: 'attributes', query: {
            entity: entityType,
          }, }">
          <b-button
            type="is-light"
            class="back-btn mr-3"
            icon-right="arrow-left" />
        </router-link>
        <h2> Manage {{ entityTypeDetails?.name }} Mapped Entities</h2>
      </div>

      <div class="columns is-vcentered report-table-filters">
        <div class="column pdf-search-input m-0 p-0">
          <b-input
            v-model="reportSearchInput"
            custom-class="no-border"
            placeholder="Search..."
            type="search"
            icon="magnify" />
        </div>
      </div>

      <div class="create-item-section">
        <b-field label="New Mapped Entity">
          <input
            v-model="newValue"
            class="input"
            @keydown.enter="addNewValue" />
          <button
            class="button is-primary ml-3"
            @click="addNewValue">
            Add Entity
          </button>
        </b-field>
      </div>
      <b-table
        :data="resultsToShow"
        :loading="isLoading"
        :hoverable="true"
        class="custom-table">
        <b-table-column
          v-slot="props"
          sortable
          field="name"
          label="Value">
          {{ props.row.label }}
        </b-table-column>

        <template #empty>
          <div class="has-text-centered mt-5 mb-5">
            <b-tag
              v-if="!isLoading"
              class="data-tags"
              rounded
              size="is-medium">
              No Values Found 🥥
            </b-tag>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ManageAttributePage',
    data () {
      return {
        allAttributes: [],
        entityType: '',
        entityTypeDetails: {},
        isLoading: false,
        reportSearchInput: '',
        reportsFilterByActiveTabs: 0,
        newValue: '',
        dates: {
          'type': 'range',
          'start': {
            'key': new Date(new Date() - 30 * 24 * 3600000).toISOString().split('T')[0],
            'value': new Date(new Date() - 30 * 24 * 3600000).toISOString().split('T')[0],
          },
          'end': {
            'key': new Date().toISOString().split('T')[0],
            'value': new Date().toISOString().split('T')[0],
          },
        },
      }
    },

    computed: {

      ...mapGetters({
        entityAttributes: 'entities/entityAttributes',
        dataTypes: 'expressions/dataTypes',
        entityTypes: 'entities/entityTypes',
        consolidatedTags: 'entities/consolidatedTags',
      }),

      consolidatedTagsFormatted () {
        const tags = Object.values(this.consolidatedTags) || []

        const tagsWithLabels = tags.map((item) => {
          return {
            ...item,
            label: this.getConsolidatedValueLabel(item.id),
          }
        })

        return tagsWithLabels.sort((a, b) => {
          return a.label.localeCompare(b.label)
        })
      },
      resultsToShow () {
        if (this.reportSearchInput) {
          return this.searcher.search(this.reportSearchInput)
        }
        return this.consolidatedTagsFormatted
      },

      searcher () {
        const searcher = new FuzzySearch(this.consolidatedTagsFormatted, ['label',])
        return searcher
      },

      consolidatedEntityVersion () {
        return this.entityTypes
          .filter(
            (entityType) =>
              entityType?.key == this.entityTypeDetails?.consolidated_type_key
          )
          .shift()
      },
    },

    async mounted () {
      this.entityType = this.$route.params.entity_type
      this.loadAttributesForEntity()
    },

    methods: {
      async addNewValue () {

        if (!this.newValue) {
          return
        }
        await this.$store.dispatch('entities/createConsolidatedValue', {
          consolidated_type_key: this.entityTypeDetails.consolidated_type_key,
          name: this.newValue,
        })
        this.newValue = ''
        this.$buefy.toast.open({
          message: 'New Entity Created',
          position: 'is-bottom',
          type: 'is-success',
        })
      },
      getConsolidatedValueLabel (consolidated_value_id) {
        return (
          ((this.consolidatedTags[consolidated_value_id] || {}).fields || {})[
            this.consolidatedEntityVersion.fields[0].key
          ] || ''
        )
      },
      async loadAttributesForEntity () {
        this.isLoading = true
        await this.$store.dispatch('entities/initialize', this.dates)
        this.entityTypeDetails = this.entityTypes.find(entityType => entityType.key == this.entityType)
        this.allAttributes = this.entityAttributes.filter(ea => ea.entity_type.key == this.entityType)
        this.isLoading = false
      },

    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: white;
  width: 100%;
  padding: 20px 14px;
  overflow: auto;

  .header {
    margin-top: 30px;
    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .back-btn {
      font-size: 24px;
      border-radius: 100%;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .create-item-section {
    display: flex;
    input {
      width:  300px;
    }
  }

  .custom-table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
  }

  .report-table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .data-tags {
    font-size: 12px;
    margin: 2px;
  }

  .actions {
    display: flex;

    .action-btn {
      margin: 0 5px;
    }
  }
}
</style>
