<template>
  <div class="use-bulma">
    <div class="container">
      <validation-observer
        ref="form"
        v-slot="{ invalid }">
        <div class="header">
          <router-link :to="{ name: 'email_report_library' }">
            <b-button
              type="is-light"
              class="back-btn mr-3"
              icon-right="arrow-left" />
          </router-link>
          <h2>Edit Report</h2>
          <div class="control-bar">
            <b-button
              type="is-primary"
              :disabled="!isDirty || invalid"
              @click="saveEdits">
              Save
            </b-button>
            <b-button
              v-if="isDirty"
              class="ml-5"
              @click="discardChanges">
              Discard changes
            </b-button>
          </div>
        </div>
        <div class="columns m-0 p-0 mt-6">
          <div class="column m-0 p-0">
            <div class="input-form">
              <h2 class="header-with-underline">
                Info
              </h2>
              <validation-provider
                v-slot="{ errors }"
                rules="required">
                <b-field
                  label="Report Name"
                  :type="errors.length ? 'is-danger' : ''"
                  :message="errors[0]">
                  <b-input
                    v-model="nameInput"
                    role="presentation"
                    @input="makeInputsDirty" />
                </b-field>
              </validation-provider>
              <validation-provider v-slot="{ errors }">
                <b-field
                  label="File Name"
                  :type="errors.length ? 'is-danger' : ''"
                  :message="errors[0]"
                  class="mt-5">
                  <b-input
                    v-model="fileNameInput"
                    @input="makeInputsDirty" />

                  <div
                    v-if="fileNameInput"
                    class="file-name-formatted">
                    <span>[YYYY-MM-DD]</span>{{ fileNameInput
                    }}<span>.{{ fileTypeInput.toLowerCase() }}</span>
                  </div>
                </b-field>
              </validation-provider>

              <b-field
                label="Filetype"
                class="mt-5">
                <b-input
                  v-model="fileTypeInput"
                  disabled />
              </b-field>

              <b-field
                label="Version"
                class="mt-5">
                <b-input
                  v-model="versionInput"
                  disabled />
              </b-field>

              <b-field
                label="Internal Name (Internal Only)"
                class="mt-5">
                <b-input
                  v-model="internalNameInput"
                  disabled />
              </b-field>

              <permission-control requires="MANAGE_EMAIL_CONFIG">
                <b-field
                  label="Status"
                  class="mt-6">
                  <b-switch
                    v-model="isActiveInput"
                    :value="true"
                    type="is-success"
                    @input="makeInputsDirty">
                    {{ isActiveInput ? "Active" : "Inactive" }}
                  </b-switch>
                </b-field>
              </permission-control>
            </div>
          </div>
          <permission-control requires="MANAGE_EMAIL_CONFIG">
            <div class="column m-0 p-0">
              <h2 class="header-with-underline">
                Tags
              </h2>
              <div class="columns is-vcentered location-table-filters">
                <div class="column location-search-input m-0 p-0 mr-4">
                  <b-input
                    v-model="locationSearchInput"
                    custom-class="no-border"
                    placeholder="Search..."
                    type="search"
                    icon="magnify" />
                </div>

                <div>
                  <b-button
                    :type="locationTableTabs === 0 ? 'is-light' : 'is-white'"
                    class="mr-2"
                    @click="locationTableTabs = 0">
                    All
                    <b-tag
                      class="data-tags is-white"
                      rounded>
                      {{ locations.length }}
                    </b-tag>
                  </b-button>
                  <b-button
                    :type="locationTableTabs === 1 ? 'is-light' : 'is-white'"
                    @click="locationTableTabs = 1">
                    Selected
                    <b-tag
                      class="data-tags is-white"
                      rounded>
                      {{ locationsSelected.length }}
                    </b-tag>
                  </b-button>
                </div>
              </div>

              <b-table
                scrollable
                :height="570"
                :sticky-header="true"
                :data="resultsToShow"
                class="locations-table"
                hoverable
                @click="toggleLocationRow">
                <b-table-column
                  field="displayName"
                  width="30">
                  <template #header>
                    <b-tooltip append-to-body>
                      <b-checkbox
                        :value="selectAllLocationsValue"
                        @input="selectAll" />
                    </b-tooltip>
                  </template>
                  <template #default="props">
                    <b-checkbox
                      v-model="locationsSelected"
                      :native-value="props.row.id"
                      style="pointer-events: none" />
                  </template>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  sortable
                  label="Locations">
                  {{ props.row.display_name }}
                </b-table-column>

                <template #empty>
                  <div
                    v-if="!isLoading"
                    class="has-text-centered mt-6">
                    <b-tag
                      class="data-tags"
                      rounded
                      size="is-medium">
                      No Locations Found 🥥
                    </b-tag>
                  </div>
                </template>
              </b-table>
              <div class="location-table-footer">
                {{ locationsSelected.length }} Selected
              </div>
            </div>
          </permission-control>
        </div>

        <b-loading
          v-model="isLoading"
          :is-full-page="true"
          :can-cancel="true" />
      </validation-observer>
    </div>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'
  import permissionControl from '@/components/permissions/permissionControl.vue'

  export default {
    name: 'ReportEditor',
    components: {
      permissionControl,
    },
    props: {},
    data () {
      return {
        locations: [],
        reportId: null,
        isLoading: false,

        locationSearchInput: '',
        locationTableTabs: 0,

        nameInput: '',
        fileNameInput: '',
        fileTypeInput: '',
        versionInput: '',
        internalNameInput: '',

        locationsSelected: [],
        isActiveInput: false,

        isDirty: false,
      }
    },
    computed: {
      selectAllLocationsValue () {
        return (
          this.locationsSelected.length > 0 &&
          this.locationsSelected.length === this.resultsToShow.length
        )
      },

      locationTableData () {
        if (this.locationTableTabs === 0) {
          return this.locations
        }
        return this.locations.filter((row) =>
          this.locationsSelected.includes(row.id)
        )
      },

      searcher () {
        const searcher = new FuzzySearch(this.locationTableData, ['display_name',])
        return searcher
      },

      resultsToShow () {
        if (this.locationSearchInput) {
          return this.searcher.search(this.locationSearchInput)
        }
        return this.locationTableData
      },
    },

    async mounted () {
      await this.onLoad()
    },
    methods: {
      selectAll (value) {
        if (value) {
          this.locationsSelected = this.resultsToShow.map((item) => item.id)
        } else {
          this.locationsSelected = []
        }
      },
      async onLoad () {
        this.reportId = this.$route.params.report_id
        if (this.reportId) {
          await this.loadReportData()
        } else {
          this.$router.push({ name: 'email_report_library', })
        }
        await this.getLocations()
      },
      makeInputsDirty () {
        this.isDirty = true
      },
      toggleLocationRow (row) {
        const indexOfItem = this.locationsSelected.indexOf(row.id)
        if (indexOfItem > -1) {
          this.locationsSelected.splice(indexOfItem, 1)
        } else {
          this.locationsSelected.push(row.id)
        }
        this.isDirty = true
      },

      async loadReportData (reportID = this.reportId) {
        this.isLoading = true
        const response = await this.$apis.emailReports.getReport(reportID)

        if (response) {
          this.nameInput = response.displayName
          this.fileNameInput = response.displayName
          this.fileTypeInput = 'PDF'
          this.versionInput = response.version
          this.internalNameInput = response.name
          this.isActiveInput = response.isActive

          this.locationsSelected = response.locationsScope || []
        }
        this.isDirty = false
        this.isLoading = false
      },
      async getLocations () {
        const response = await this.$apis.app.getAllLocations()
        this.locations = response
      },

      async discardChanges () {
        await this.loadReportData()
      },
      async saveEdits () {
        const isFormValidatied = await this.$refs.form.validate()
        if (!isFormValidatied) return

        this.isLoading = true
        const requestBody = {
          displayName: this.nameInput,
          filename: this.fileNameInput,
          isActive: this.isActiveInput,
          locationsScope: this.locationsSelected,
        }
        const response = await this.$apis.emailReports.saveReport(
          this.reportId,
          requestBody
        )
        if (!response) {
          this.$buefy.toast.open({
            message: `Error Saving Data`,
            position: 'is-bottom',
            type: 'is-warning',
          })
        } else {
          this.$buefy.toast.open({
            message: `Saved`,
            position: 'is-bottom',
            type: 'is-success',
          })
          this.isDirty = false
        }

        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.use-bulma {
  width: 100%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 50px;
  padding: 20px;

  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .header {
    display: flex;
    align-items: center;
    border-bottom: $border-1;
    margin-top: 30px;

    padding-bottom: 20px;

    .back-btn {
      font-size: 24px;
      border-radius: 100%;
    }

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .input-form {
    max-width: 400px;

    .file-name-formatted {
      margin-top: 5px;
      font-size: 15px;
      color: $ui-02;

      span {
        color: $ui-04;
      }
    }
  }

  .location-table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .data-tags {
    font-size: 12px;
    margin: 4px;
  }

  .locations-table {
    font-size: 14px;
    border-radius: 4px;
    border: $border-1;
    margin-top: 5px;
  }

  .location-table-footer {
    border: $border-1;
    text-align: center;
    padding: 8px;
    font-size: 13px;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
