<template>
  <div class="view">
    <busy-overlay
      v-if="columns.length > 0"
      class="custom-loading-overlay"
      :enabled="loading || busy || missingDeps.length > 0"
      :loading="loading"
      :message="
        error
          ? 'Failed to load'
          : loading || missingDeps.length > 0
            ? isEdit
              ? 'Calculating'
              : 'Loading'
            : ''
      " />
    <component
      :is="type"
      v-if="!!reportData && !!reportColumns"
      class="view-component"
      v-bind="{
        columns: reportColumns,
        data: reportData,
        comparison: comparison,
        loading,
        draggedField,
        sortBy: [].concat(sortBy, defaultSortBy),
        position,
        betaSpec,
        name,
        isEdit,
        error,
      }"
      @update:name="nameModel = $event"
      @update:sortBy="sortBy = $event"
      @click="onChartClick"
      @reload="reloadData">
      <template #filters>
        <dimension-filter-menu
          v-if="!isEdit"
          :read-only="true"
          :filters="filters"
          :add-remove="false" />
      </template>
      <template #toolbarButtons>
        <ingest-dropdown v-if="hasDrilldown">
          <template #button-content="{ isOpen }">
            <div
              class="drilldown-path-button"
              :class="{ isOpen }">
              Drill down <span class="material-icons">arrow_drop_down</span>
            </div>
          </template>
          <template #body-content>
            <div class="drilldown-path-body">
              <b-button
                class="drilldown-path-item"
                @click="onBreadcrumbClick(-1)">
                {{ reportName }}
              </b-button>
              <template v-for="(leg, idx) in drilldownPath">
                <b-button
                  :key="idx"
                  class="drilldown-path-item"
                  :class="{ last: idx === drilldownPath.length - 1 }"
                  @click="onBreadcrumbClick(idx)">
                  <span class="material-icons">subdirectory_arrow_right</span>
                  {{ leg.valueLabel }}
                </b-button>
              </template>
            </div>
          </template>
        </ingest-dropdown>
        <template v-if="viewTypes.length > 1">
          <button
            v-for="(typeOption, idx) in viewTypes"
            :key="typeOption"
            :class="{ active: selectedType == idx }"
            class="button icon-button"
            @click="selectedType = idx">
            <component
              :is="ingestViews[typeOption].icon.name"
              variant="none" />
          </button>
        </template>
        <button
          v-if="
            columns.length > 0 && viewTypes[selectedType] != 'scorecard-view'
          "
          class="button"
          :disabled="exporting"
          @click="exportCsv()">
          <i
            key="csvExport"
            class="material-icons"> save_alt </i>
        </button>
      </template>
      <template #moduleControls>
        <div class="control-bar">
          <template v-for="(control, idx) in controls">
            <template
              v-if="!!control.id && control.type === 'module-option-toggle'">
              <ingest-toggle
                :key="control.id"
                :left="control.spec.left"
                :right="control.spec.right"
                :selected="localSelections[control.id] || control.spec.left"
                @update:selected="updateLocalSelection($event, control)" />
            </template>
            <template v-if="!!control.id && control.type === 'option-select'">
              <div
                v-if="idx !== 0"
                :key="control.id"
                class="control-spacer" />
              <option-select
                :key="`control-${control.id}`"
                :control="control" />
            </template>
            <template
              v-if="!!control.id && control.type === 'date-offset-select'">
              <div
                v-if="idx !== 0 || controls.length > 0"
                :key="control.id"
                class="control-spacer" />
              <offset-select
                :key="`control-${control.id}`"
                :control="control" />
            </template>
          </template>
        </div>
      </template>
    </component>
    <div
      v-if="
        (hasDrilldown || defaultDrilled) &&
          !!drilldownTarget &&
          drilldownTargetOptions.length > 1
      "
      class="dimension-select">
      <div class="input-label">
        Dimension
      </div>
      <b-field>
        <b-dropdown
          v-model="drilldownTarget"
          position="is-top-right"
          aria-role="list">
          <template #trigger="{ active }">
            <button
              class="button dropdown-button"
              type="is-light">
              <div class="option-selected-label">
                {{ drilldownTarget.name }}
              </div>

              <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
            </button>
          </template>

          <b-dropdown-item
            v-for="(option, i) in drilldownTargetOptions"
            :key="i"
            class="dropdown-item"
            :value="option"
            aria-role="listitem">
            {{ option.name }}
          </b-dropdown-item>
        </b-dropdown>
      </b-field>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import DimensionFilterMenu from '@/components/controls/filter/DimensionFilterMenu'
  import BusyOverlay from '@/components/common/BusyOverlay'
  import IngestDropdown from '@/components/common/IngestDropdown'
  import IngestToggle from '@/components/common/IngestToggle'
  import OffsetSelect from '@/components/controls/OffsetSelect'
  import OptionSelect from '@/components/controls/OptionSelect'
  import { expressionProcessorMixin } from '@/components/reporting/expressionProcessorMixin'
  import { ingestViews } from '@/components/module/view/viewDefs'
  import { getReport, getReportCsv } from '@/service/data/reportService'

  export default {
    name: 'ModuleView',
    components: {
      BusyOverlay,
      IngestDropdown,
      IngestToggle,
      DimensionFilterMenu,
      OptionSelect,
      OffsetSelect,
    },
    mixins: [expressionProcessorMixin,],
    props: {
      dashboardFilters: { type: Array, required: true, },
      viewTypes: { type: Array, required: true, },
      columns: { type: Array, required: true, },
      controls: { type: Array, required: true, },
      limit: { type: Number, default: null, },
      betaSpec: { type: Object, default: null, },
      defaultSortBy: { type: Array, required: true, },
      filters: { type: Array, required: true, },
      isEdit: Boolean,
      useBigquery: { type: Boolean, default: false, },
      busy: Boolean,
      reportName: { type: String, default: '', },
      name: { type: String, default: '', },
      comparison: { type: Object, default: null, },
      position: { type: Object, required: true, },
      dashboardControls: { type: Array, required: true, },
      drilldownPath: { type: Array, default: Array, },
      dashboardId: { type: Number, required: true, },
      moduleId: { type: Number, required: true, },
    },
    data () {
      return {
        localSelections: {},
        height: 0,
        isHover: false,
        loading: true,
        exporting: false,
        error: false,
        selectedType: 0,
        draggedField: undefined,
        drilldownTargetData: null,
        sortBy: [],
        changeToForceReloadHack: {},
        requestCanceler: null,
        reportData: [],
        reportColumns: [],
        unwatchSelection: () => {},
        unwatchDebouncedSelections: () => {},
        unwatchDates: () => {},
        unwatchExpressions: () => {},
      }
    },
    computed: {
      drilldownTarget: {
        get () {
          if (this.drilldownPath.length > 0) {
            return this.drilldownTargetData
          } else if (this.defaultDrilled) {
            return (
              this.drilldownTargetData ||
              this.drilldownTargetOptions.slice(0, 1).shift()
            )
          } else {
            return null
          }
        },
        set (drilldownTarget) {
          this.drilldownTargetData = drilldownTarget
          this.loadReport()
        },
      },
      injectedDimensions () {
        return (this.betaSpec || {}).injectedDimensions || []
      },
      dashboardSelections () {
        return this.$store.getters['controls/debouncedSelections']
      },
      allSelections () {
        return { ...this.dashboardSelections, ...this.localSelections, }
      },
      dateFilters () {
        return this.$store.getters['datePicker/appliedFilters']
      },
      missingDeps () {
        if (
          !this.$store.getters['datePicker/lastDay'] ||
          !this.$store.getters['datePicker/appliedEffectiveDates']
        ) {
          return ['date',]
        }
        if (!this.$store.getters['expressions/initialized']) {
          return ['companyExpressions',]
        }
        return this.dashboardControls
          .filter((control) =>
            [
              'v2-dimension-select',
              'dimension-select',
              'option-select',
              'date-offset-select',
            ].includes(control.type)
          )
          .filter(
            (control) =>
              this.$store.getters['controls/debouncedSelections'][control.id] ==
              null ||
              this.$store.getters['controls/debouncedSelections'][control.id] ==
              undefined ||
              !!this.$store.getters['controls/loadingControls'][control.id]
          )
      },
      selectedCounts () {
        return this.columns
          .filter((column) => column.type == 'count')
          .map((column) => column)
      },
      selectedMeasures () {
        return this.columns
          .filter((column) => column.type == 'sum' || column.type == 'avg')
          .map((column) => column)
      },
      ingestViews: () => ingestViews,
      type () {
        return this.viewTypes[this.selectedType]
      },
      viewDef () {
        return ingestViews[this.type]
      },
      fields () {
        return [].concat(this.effectiveDimensions, this.selectedMeasures)
      },
      nameModel: {
        get () {
          return this.name
        },
        set (name) {
          this.$emit('update:name', name)
        },
      },
      drilldownFilters () {
        let filters = []
        for (let drilldown of this.drilldownPath) {
          let filter = {
            type: 'is',
            left: {
              type: 'const',
              data_type: { key: 'id', },
              value: drilldown.valueKey,
            },
            right: drilldown.option.keyExpression
              ? this.preprocessExpression(drilldown.option.keyExpression)
              : {
                type: 'field',
                field: {
                  key: drilldown.option.keyField,
                },
              },
          }
          filters.push(filter)
        }
        return filters
      },
      defaultDrilled () {
        return !!this.betaSpec && !!this.betaSpec.default_dimension_picker
      },
      hasDrilldown () {
        return this.drilldownPath.length > 0
      },
      drilldownTargetOptions () {
        if (!this.betaSpec || !this.betaSpec.drilldown) {
          return []
        }
        return this.betaSpec.drilldown.options.filter(
          (option) => !this.drilldownPath.map((d) => d.option).includes(option)
        )
      },
    },
    watch: {
      sortBy () {
        this.loadReport()
      },
      columns () {
        this.loadReport()
      },
    },
    mounted () {
      this.unwatchSelections = this.$store.watch(
        (_, getters) => getters['controls/controlSelections'],
        this.startLoading
      )
      this.unwatchDebouncedSelections = this.$store.watch(
        (_, getters) => getters['controls/debouncedSelections'],
        this.loadReport
      )
      this.unwatchDates = this.$store.watch(
        (_, getters) => getters['datePicker/appliedEffectiveDates'],
        this.loadReport
      )
      this.unwatchExpressions = this.$store.watch(
        (_, getters) => getters['expressions/initialized'],
        this.loadReport
      )
      this.loadReport()
    },
    destroyed () {
      this.unwatchSelections()
      this.unwatchDebouncedSelections()
      this.unwatchDates()
      this.unwatchExpressions()
    },
    methods: {
      getBetaColumns () {
        return this.filterControlledColumns(this.columns)
          .map((c) => this.processBetaColumn(c))
          .filter((c) => !!c)
      },
      getReportSpec () {
        return {
          use_bq: this.useBigquery,
          dashboard_id: this.dashboardId,
          module_id: this.moduleId,
          series: this.getBetaColumns(),
          aggregates: [].concat(this.selectedMeasures, this.selectedCounts),
          comparison: this.comparison || undefined,
          limit: this.limit || undefined,
          sort_by: [].concat(this.sortBy, this.defaultSortBy),
        }
      },
      startLoading () {
        this.error = false
        if (this.columns.length === 0) {
          this.loading = false
          this.reportData = []
          this.reportColumns = []
        }
        this.loading = true
      },
      async loadReport () {
        this.startLoading()
        if (this.requestCanceler) {
          this.requestCanceler.cancel('Canceled stale report')
        }
        if (
          this.missingDeps.length > 0 ||
          this.dateFilters === null ||
          this.$store.getters['datePicker/appliedEffectiveDates'].length === 0
        ) {
          return
        }

        try {
          this.requestCanceler = axios.CancelToken.source()
          let report = await getReport(
            this.getReportSpec(),
            this.$route.params.company_id,
            this.requestCanceler.token
          )
          this.setReportColumns(report)
          this.reportData = Object.freeze(report.data)
          this.loading = false
        } catch (e) {
          if (!!e.message && e.message != 'Canceled stale report') {
            this.error = true
          }
        }
      },
      setReportColumns (report) {
        if (!report.series) {
          this.reportColumns = []
        }
        this.reportColumns = Object.freeze(
          report.series.map((column) => {
            this.columns.forEach((specColumn) => {
              if (specColumn.id === column.id) {
                column = {
                  ...specColumn,
                  ...column,
                  name: this.processString(specColumn.name || ''),
                }
              }
            })
            if (this.drilldownTargetOptions.length > 1) {
              column.clickable = true
            }

            (column.behaviors || []).forEach((behavior) => {
              if (
                behavior.type === 'option-value-rename' &&
                !!this.allSelections[behavior.control_id]
              ) {
                column.name = this.allSelections[behavior.control_id].value
              }
              if (behavior.type == 'dimension-select-highlight') {
                column.highlightKey = (
                  this.allSelections[behavior.control_id] || []
                ).map((o) => o.key)
              }
              if (behavior.type === 'dimension-select-on-click') {
                column.clickable = true
              }
            })
            return column
          })
        )
      },
      updateLocalSelection (selection, control) {
        let newSelections = { ...this.localSelections, }
        newSelections[control.id] = selection
        this.localSelections = newSelections
      },
      updateDashboardSelection (selection, control) {
        let newSelections = { ...this.dashboardSelections, }
        newSelections[control.id] = selection
        this.store.dispatch('controls/setSelections', newSelections)
      },
      setDrilldownPath (newPath) {
        this.$emit('update:drilldownPath', newPath)
      },
      selectDrilldownTarget (option) {
        this.drilldownTarget = option
      },
      filterControlledColumns (columns) {
        return columns.filter((column) => {
          if (!column.behaviors) {
            return true
          }
          let applicableBehaviors = column.behaviors.filter(
            (control) => control.type === 'option-select-switch'
          )
          let isEnabled = true
          applicableBehaviors.forEach((behavior) => {
            if (
              !this.allSelections[behavior.control_id] ||
              this.allSelections[behavior.control_id].key !==
              behavior.on_value.key
            ) {
              isEnabled = false
            }
          })
          return isEnabled
        })
      },
      reloadData () {
        this.loadReport()
      },
      customRangeFilters (customDateRange) {
        let last_day = this.$store.getters['datePicker/appliedEffectiveDates']
          .slice(-1)
          .shift()
        if (!last_day) {
          return null
        }
        if (customDateRange.type == 'relative_date_filter_n_days') {
          return [
            {
              type: 'is_less_or_equal',
              left: {
                type: 'field',
                field: {
                  key: 'date',
                },
              },
              right: {
                type: 'const',
                data_type: { key: 'date', },
                value: customDateRange.offset
                  ? new Date(
                    new Date(last_day.date).setDate(
                      new Date(last_day.date).getDate() + customDateRange.offset
                    )
                  )
                    .toISOString()
                    .split('T')[0]
                  : last_day.date,
              },
            },

            {
              type: 'is_more_or_equal',
              left: {
                type: 'field',
                field: {
                  key: 'date',
                },
              },
              right: {
                type: 'const',
                data_type: { key: 'date', },
                value: new Date(
                  new Date(last_day.date) -
                    24 * 60 * 60 * 1000 * (customDateRange.duration - 1) +
                    (customDateRange.offset || 0)
                )
                  .toISOString()
                  .split('T')[0],
              },
            },
          ]
        }
        if (customDateRange.type == 'relative_date_filter_wtd') {
          return [
            {
              type: 'is_less_or_equal',
              left: {
                type: 'field',
                field: {
                  key: 'date',
                },
              },
              right: {
                type: 'const',
                data_type: { key: 'date', },
                value: last_day.date,
              },
            },
            {
              type: 'in',
              left: {
                type: 'field',
                field: { key: 'date', },
              },
              right: {
                type: 'const',
                data_type: { key: 'list', item_type: { key: 'date', }, },
                value: this.$store.getters['datePicker/availableDates']
                  .filter(
                    (d) =>
                      d.year_key === last_day.year_key &&
                      d.week_key === last_day.week_key
                  )
                  .map((d) => d.date_key),
              },
            },
          ]
        }
        if (customDateRange.type == 'relative_date_filter_ptd') {
          return [
            {
              type: 'is_less_or_equal',
              left: {
                type: 'field',
                field: {
                  key: 'date',
                },
              },
              right: {
                type: 'const',
                data_type: { key: 'date', },
                value: last_day.date,
              },
            },
            {
              type: 'in',
              left: {
                type: 'field',
                field: { key: 'date', },
              },
              right: {
                type: 'const',
                data_type: { key: 'list', item_type: { key: 'date', }, },
                value: this.$store.getters['datePicker/availableDates']
                  .filter(
                    (d) =>
                      d.year_key === last_day.year_key &&
                      d.period_key === last_day.period_key
                  )
                  .map((d) => d.date_key),
              },
            },
          ]
        }
        if (customDateRange.type == 'relative_date_filter_qtd') {
          return [
            {
              type: 'is_less_or_equal',
              left: {
                type: 'field',
                field: {
                  key: 'date',
                },
              },
              right: {
                type: 'const',
                data_type: { key: 'date', },
                value: last_day.date,
              },
            },
            {
              type: 'in',
              left: {
                type: 'field',
                field: { key: 'date', },
              },
              right: {
                type: 'const',
                data_type: { key: 'list', item_type: { key: 'date', }, },
                value: this.$store.getters['datePicker/availableDates']
                  .filter(
                    (d) =>
                      d.year_key === last_day.year_key &&
                      d.quarter_key === last_day.quarter_key
                  )
                  .map((d) => d.date_key),
              },
            },
          ]
        }
        if (customDateRange.type == 'relative_date_filter_ytd') {
          return [
            {
              type: 'is_less_or_equal',
              left: {
                type: 'field',
                field: {
                  key: 'date',
                },
              },
              right: {
                type: 'const',
                data_type: { key: 'date', },
                value: last_day.date,
              },
            },
            {
              type: 'in',
              left: {
                type: 'field',
                field: { key: 'date', },
              },
              right: {
                type: 'const',
                data_type: { key: 'list', item_type: { key: 'date', }, },
                value: this.$store.getters['datePicker/availableDates']
                  .filter((d) => d.year_key === last_day.year_key)
                  .map((d) => d.date_key),
              },
            },
          ]
        }
        throw `Unrecognized type for custom date range: ${customDateRange.type}`
      },
      processBetaColumn (column) {
        let newColumn = {
          ...column,
          hidden: [
            'primary_dimension_key',
            'secondary_dimension_key',
            'hidden',
          ].includes(column.role),
          name: this.processString(column.name || ''),
          expression: this.preprocessExpression(column.expression),
        }; // ; is necessary here because the following line gets treated like a funciton call.

        (column.behaviors || []).forEach((behavior) => {
          if (
            behavior.type === 'option-value-rename' &&
            !!this.allSelections[behavior.control_id]
          ) {
            newColumn.name = this.allSelections[behavior.control_id].value
          }
        })

        return newColumn
      },
      async exportCsv () {
        this.exporting = true
        try {
          let csv = await getReportCsv(
            this.getReportSpec(),
            this.$route.params.company_id
          )
          let url = window.URL.createObjectURL(new Blob([csv,]))
          let link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${this.processString(this.name)
              .toLowerCase()
              .replaceAll(' ', '_')}_${new Date().toISOString()}.csv`
          )
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } finally {
          this.exporting = false
        }
      },
      onBreadcrumbClick (idx) {
        this.setDrilldownPath(this.drilldownPath.slice(0, idx + 1))
        this.$nextTick(() => {
          if (idx == -1) {
            this.drilldownTarget = null
          } else {
            this.drilldownTarget = this.drilldownTargetOptions
              .slice(0, 1)
              .shift()
          }
        })
      },
      onChartClick (datum) {
        if (this.loading) {
          return
        }
        let newSelection = { ...this.dashboardSelections, }
        let dirt = false
        let drilled = false
        for (let c of this.reportColumns) {
          if (c.behaviors) {
            c.behaviors
              .filter((behavior) => behavior.type == 'dimension-select-on-click')
              .forEach((behavior) => {
                newSelection[behavior.control_id] = [
                  { key: datum[c.key].toString(), },
                ]
                dirt = true
              })
          }
          if (this.drilldownTargetOptions.length > 1) {
            this.setDrilldownPath([
              ...this.drilldownPath,
              {
                option:
                  this.drilldownTarget ||
                  this.drilldownTargetOptions.slice(0, 1).shift(),
                valueLabel:
                  datum[
                    this.reportColumns
                      .filter(
                        (c) => c.id === this.betaSpec.drilldown.labelSeriesId
                      )
                      .shift().key
                  ],
                valueKey:
                  datum[
                    this.reportColumns
                      .filter((c) => c.id === this.betaSpec.drilldown.keySeriesId)
                      .shift().key
                  ],
              },
            ])
            drilled = true
          }
        }
        if (drilled) {
          this.$nextTick(() => {
            this.drilldownTarget = this.drilldownTargetOptions
              .slice(0, 1)
              .shift()
          })
        }
        if (dirt) {
          this.$store.dispatch('controls/setSelections', newSelection)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.view {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: visible;
  align-items: center;
}

.view-component {
  width: 100%;
  padding: 5 * $gridBase 8 * $gridBase;
  min-height: 0;
  flex-grow: 1;
}

.type-option,
.edit {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}

.breadcrumb {
  justify-content: flex-end;
}

.button {
  color: $secondary-04;
  margin-left: 2 * $gridBase;
  font-size: $h0;

  &:hover {
    color: $secondary-06;
  }

  &.disabled {
    color: $secondary-04;
  }

  &.active {
    color: $secondary-07;
  }
}

.drilldown-path-button {
  margin-right: $gridBase;
  background: $ui-warning;
  @include shadow(false, rgba($ui-warning, 0.75));
  color: $ui-07;
  font-size: $h3;
  font-weight: $emp;
  padding: $gridBase 2 * $gridBase;
  border-radius: 2 * $gridBase;
  display: flex;
  align-items: center;

  .material-icons {
    font-size: 4 * $remGrid;
    transition: transform 0.3s;
    margin-left: 2 * $gridBase;
    height: 1.6rem;
    line-height: 1.6rem;
  }

  &:hover {
    box-shadow: 0px 0px 8px rgba($ui-warning, 0.75);
  }

  &.isOpen {
    color: $ui-02;
    background: $ui-07;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .material-icons {
      transform: rotate(-180deg);
    }
  }
}

.drilldown-path-body {
  color: $ui-02;
  background: $ui-07;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  padding: 2 * $gridBase 0;
  border-radius: 2 * $gridBase;
  border-top-left-radius: 0;
}

.drilldown-path-item {
  padding: $gridBase 2 * $gridBase;
  width: 100%;

  white-space: nowrap;
  font-size: $h3;
  font-weight: $emp;
  color: $ui-02;
  display: flex;
  align-items: center;

  &:hover {
    background: $ui-06;
  }

  .material-icons {
    color: $ui-04;
    font-size: 4 * $remGrid;
    margin-right: 2 * $gridBase;
  }

  &.last {
    color: $ui-07;
    background: $ui-warning;

    .material-icons {
      color: $ui-07;
      font-size: 4 * $remGrid;
    }
  }
}

.drilldown-dimension-select {
  width: 64 * $gridBase;
  margin-bottom: 6 * $gridBase;
  margin-top: -5 * $gridBase;
}

.dimension-select-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $ui-02;
  color: $ui-07;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2 * $gridBase;

  font-size: $h3;
  padding: 2 * $gridBase 3 * $gridBase;
  font-weight: 700;

  &:hover {
    background: $ui-01;
  }

  .material-icons {
    font-size: 4 * $remGrid;
    transition: transform 0.3s;
    margin-left: 2 * $gridBase;
    line-height: 1.6rem;
    height: 1.6rem;
  }

  &.isOpen {
    color: $ui-01;
    background: $ui-07;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .material-icons {
      font-size: 4 * $remGrid;
      transform: rotate(-180deg);
    }
  }
}

.dimension-select-options {
  display: flex;
  flex-direction: column;
  width: 64 * $gridBase;
  border-top-left-radius: 2 * $gridBase;
  border-top-right-radius: 2 * $gridBase;
  background: $ui-07;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  padding: $gridBase 0;
}

.dimension-select-option {
  padding: 2 * $gridBase 3 * $gridBase;
  background: $ui-07;
  color: $ui-02;
  font-size: $h3;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipses;
  text-align: left;

  &:hover {
    color: $ui-01;
    background: $ui-05;
  }

  &.selected {
    color: $ui-07;
    background: $ui-01;
  }
}

.control-bar {
  min-height: 5 * $gridBase;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;

  > * {
    margin: 0 2 * $gridBase;
  }
}

.dimension-select {
  display: flex;
  margin-bottom: 20px;

  .dropdown-item {
    width: 250px !important;
  }

  .input-label {
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 13px;
  }
  .dropdown-button {
    width: 250px;
    padding: 0 15px 0 10px !important;
    border: $border-1 !important;

    .option-selected-label {
      width: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      text-align: left;
      font-size: 14px;
    }
  }
}
.material-icons {
  font-size: 16px;
  color: #4a4a4a;
}

.custom-loading-overlay {
  border-radius: 8px;
}
</style>
