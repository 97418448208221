<template>
  <div class="date-time-input flex-column">
    <form
      class="flex-column"
      @change="updateModel">
      <time-input
        key="time-input"
        v-model="time" />
      <date-input
        key="date-input"
        v-model="dateModel" />
    </form>
  </div>
</template>

<script>

  import inputMixin from '@/components/common/input/inputMixin.js'
  import DateInput from '@/components/common/input/DateInput'
  import TimeInput from '@/components/common/input/TimeInput'

  export default {
    name: 'DateTimeInput',
    components: {
      DateInput,
      TimeInput,
    },
    mixins: [
      inputMixin,
    ],
    data () {
      return {
        date: null,
        time: null,
      }
    },
    computed: {
      dateModel: {
        get () {
          return this.date
        },
        set (value) {
          if (value != 0) {
            this.date = value
          }
        },
      },
    },
    watch: {
      model () {
        this.updateDate()
        this.updateTime()
      },
    },
    mounted () {
      this.updateDate()
      this.updateTime()
    },
    methods: {
      updateDate () {
        if (!this.model) {
          this.date = null
        }
        let date = this.model.toISOString().split('T')[0]
        if (date !== this.date) {
          this.date = date
        }
      },
      updateTime () {
        if (!this.model) {
          return null
        }
        let time = this.model.toTimeString().split(' ')[0].split(':').splice(0,2).join(':')
        if (time !== this.time) {
          this.time = time
        }
      },
      updateModel () {
        let newModel = new Date(this.model)
        newModel.setDate(this.date.split('-')[2])
        newModel.setMonth(this.date.split('-')[1] - 1)
        newModel.setFullYear(this.date.split('-')[0])
        newModel.setHours(this.time.split(':')[0])
        newModel.setMinutes(this.time.split(':')[1])
        this.model = newModel
      },
    },
  }

</script>

<style lang="scss" scoped>

.date-time-input {
}

</style>

