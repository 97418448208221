<script>
/*
* Permission Control
* - Used to show / hide components based on the permissions of the current user
*/
  import { mapGetters } from 'vuex'
  export default {
    props: {
      requires: {
        type: String,
        default: null,
      },
    },
    computed: {
      ...mapGetters({
        userPermissions: 'auth/user/userPermissions',
      }),
    },
    render () {
      if (this.userPermissions.includes(this.requires) && this.$slots.default && this.$slots.default.length > 0) {
        return this.$slots.default[0]
      }
      return null
    },

  }
</script>
