<template>
  <div class="sub-section">
    <slot />

    <b-field
      class="input-field"
      label="Horizontal Alignment">
      <b-select
        v-model="inputs.h_align"
        placeholder=""
        expanded>
        <option
          v-for="option in ['left', 'center', 'right']"
          :key="option"
          :value="option">
          {{ option }}
        </option>
      </b-select>
    </b-field>

    <b-field
      class="input-field"
      label="Vertical Alignment">
      <b-select
        v-model="inputs.v_align"
        placeholder=""
        expanded>
        <option
          v-for="option in ['top', 'center', 'bottom']"
          :key="option"
          :value="option">
          {{ option }}
        </option>
      </b-select>
    </b-field>

    <b-field
      class="input-field"
      label="Text Style">
      <b-input
        v-model="inputs.text_style"
        placeholder=""
        expanded />
    </b-field>

    <b-field
      class="input-field"
      label="Text Color">
      <b-input
        v-model="inputs.text_color"
        placeholder=""
        expanded />
    </b-field>

    <b-field
      class="input-field"
      label="Background Color">
      <b-input
        v-model="inputs.background_color"
        placeholder=""
        expanded />
    </b-field>

    <b-field label="Border Radus">
      <b-numberinput
        v-model="inputs.border_radius"
        min="0"
        controls-alignment="right" />
    </b-field>

    <div>
      <div class="padding-container">
        <div class="top">
          <div class="padding-label">
            top
          </div>

          <b-numberinput
            v-model="inputs.pad_top"
            class="padding-input"
            :controls="false"
            min="0" />
        </div>

        <div class="center">
          <div class="left">
            <div class="padding-label">
              left
            </div>

            <b-numberinput
              v-model="inputs.pad_left"
              class="padding-input"
              :controls="false"
              min="0" />
          </div>

          <div class="middle">
            <div class="padding">
              Padding
            </div>
          </div>

          <div class="right">
            <b-numberinput
              v-model="inputs.pad_right"
              class="padding-input"
              :controls="false"
              min="0" />

            <div class="padding-label">
              right
            </div>
          </div>
        </div>

        <div class="bottom">
          <b-numberinput
            v-model="inputs.pad_bottom"
            :controls="false"
            class="padding-input"
            min="0" />

          <div class="padding-label">
            bottom
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TrackeRowMeasure',
    components: {},
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {}
    },
    computed: {
      inputs: {
        get () {
          return this.value || {}
        },
        set (value) {
          this.$emit('input', value ? value : null)
        },
      },
    },
    mounted () {},

    beforeDestroy () {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
.benchmark-inputs {
  margin-top: 30px;
}

.padding-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .padding-input{
    width: 70px;
    margin: 7px;
  }

  .padding-label {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 500;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .middle {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      height: 45px;
      width: 100px;

      .padding{
        font-size: 12px;
        text-transform: capitalize;
        font-weight: 500;

      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
}
</style>
