<template>
  <div class="dimension-editor">
    <TypeSelector
      v-model="vModel"
      object-type="Dimension"
      v-bind="subObjectBinds"
      :type-options="dimensionTypes" />
  </div>
</template>

<script>

  import CompanyDimensionRefEditor from '@/components/reports/config/dimensions/CompanyDimensionRefEditor'
  import ExpressionDimensionEditor from '@/components/reports/config/dimensions/ExpressionDimensionEditor'
  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import TypeSelector from '@/components/reports/config/common/TypeSelector'

  export default {
    name: 'DimensionEditor',
    components: {
      TypeSelector,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    data () {
      return {
        dimensionTypes: [
          {
            key: 'CompanyDimensionRef',
            label: 'Company Dimension',
            editor: CompanyDimensionRefEditor,
          },
          {
            key: 'ExpressionDimension',
            label: 'Expression Dimension',
            editor: ExpressionDimensionEditor,
          },
        ],
      }
    },
  }

</script>

<style lang="scss" scoped>

.dimension-editor {
  display: flex;
  flex-direction: column;
}

</style>
