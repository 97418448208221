// External dependencies
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ResizeSensor from 'vue-resize-sensor'
import PortalVue from 'portal-vue'
import interact from 'interactjs'
import Buefy from 'buefy'
import Multiselect from 'vue-multiselect'
import VueAnalytics from 'vue-analytics'
import './plugins/validation'
import apis from './plugins/apis'
import permissions from './plugins/permissions'
import tracker from './plugins/tracker'
import { jwtDecode } from 'jwt-decode'
import UniqueId from 'vue-unique-id'

// styles
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/styles/index.scss'
import '@/assets/styles/old.scss'
import 'splitpanes/dist/splitpanes.css'
// Internal dependencies
import { store } from '@/vuex/store'

import VueClipboard from 'vue-clipboard2'

import AButton from '@/components/form/AButton.vue'
// Vue configuration
Vue.config.productionTip = false

// Global components and plugins
Vue.component('ResizeSensor', ResizeSensor)
Vue.component('AButton', AButton)
Vue.use(PortalVue)
Object.defineProperty(Vue.prototype, '$interact', { value: interact, })
Vue.use(Buefy)
Vue.component('Multiselect', Multiselect)
Vue.use(VueAnalytics, { id: process.env.VUE_APP_ANALYTICS_ID, router, })
Vue.use(UniqueId)
// Store setup
store.dispatch('router/register', router)
Vue.use(apis)
Vue.use(permissions)
Vue.use(tracker)
Vue.use(VueClipboard)

window.setInterval(() => {
  if (store.getters['auth/isLoggedIn']) {
    let tillExp =
      (new Date(1000 * jwtDecode(store.getters['auth/authToken']).exp) -
        new Date()) /
      1000
    if (tillExp < 15) {
      store.dispatch('notifications/push', {
        icon: 'info',
        class: 'neutral',
        label: 'Session Expired',
      })
      store.dispatch('auth/logout')
      router.push({
        name: 'login',
        query: { redirect: router.currentRoute.fullPath, },
      })
    }
  }
}, 10000)

// Vue instance
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
