import numbro from 'numbro'

/*================================ Money ==============================*/

export function shortFormatAmount (cents) {
    if (cents === '') {
      return ''
    }
    return numbro(cents / 100).format({ output: 'currency', currencySymbol: '$', thousandSeparated: true, average: true, mantissa: 2, optionalMantissa: true, trimMantissa: false, })
  }

  export function formatAmount (cents) {
    if (cents === '' || isNaN(cents)) {
      return ''
    }
    return numbro(cents / 100).format({ output: 'currency', currencySymbol: '$', thousandSeparated: true, mantissa: 2, optionalMantissa: false, trimMantissa: false, })
  }

  export function parseAmount (amount) {
    return parseInt(amount) * 100
  }

/*================================ Number ==============================*/

export function shortFormatNumber (number) {
    if (number === '' || isNaN(number) || number === null) {
      return ''
    }
   return numbro(number).format({ average: true, mantissa: 2, optionalMantissa: true, trimMantissa: true, thousandSeparated: true, })
  }

  export function formatNumber (number) {
    if (number === '' || isNaN(number) || number === null) {
      return ''
    }
    return numbro(number).format({ mantissa: 2, optionalMantissa: true, trimMantissa: true, thousandSeparated: true, })
  }

