<template>
  <div>
    <div v-if="long">
      {{ minAmount }} &ndash; {{ maxAmount }}
    </div>
    <div v-else>
      {{ dimension.name }} ({{ minAmount }} &ndash; {{ maxAmount }})
    </div>
  </div>
</template>

<script>

  import { formatAmount, shortFormatAmount } from '@/utils/Format'

  export default {
    name: 'MoneyFilterSummary',
    props: {
      filter: { type: Object, required: true, },
      long: { type: Boolean, default: false, },
      customDimension: { type: Object, default: null, },
    },
    computed: {
      dimension () {
        return this.customDimension || this.$store.getters['metadata/dimensions'][this.filter.dimension]
      },
      minAmount () {
        if (this.long) {
          return formatAmount(this.filter.args.start)
        } else {
          return shortFormatAmount(this.filter.args.start)
        }
      },
      maxAmount () {
        if (this.long) {
          return formatAmount(this.filter.args.end)
        } else {
          return shortFormatAmount(this.filter.args.end)
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>
