<template>
  <div class="reports-config">
    <busy-overlay
      v-if="optionsLoading"
      :enabled="optionsLoading"
      :loading="optionsLoading"
      :message="'loading'" />
    <Tabs
      v-model="selectedTab"
      :tabs="tabs" />
    <div class="config-container">
      <div class="sidebar">
        <CompanyObjectSidebar
          v-model="selectedKey"
          :object-type="objectTypes[selectedTab].label"
          :company-objects="objectTypes[selectedTab].getCompanyObjects()"
          @createNew="createNew" />
      </div>
      <div class="editor">
        <busy-overlay
          v-if="objectLoading && !optionsLoading"
          :enabled="objectLoading"
          :loading="objectLoading"
          :message="'loading'" />
        <component
          :is="objectTypes[selectedTab].editor"
          v-if="currentObject"
          v-model="currentObject">
          <template #header-actions>
            <div class="actions">
              <button
                class="button is-primary"
                :disabled="!objectDirty"
                @click="saveChanges">
                Save
              </button>

              <button
                class="button is-light"
                :disabled="!objectDirty"
                @click="discardChanges()">
                Discard
              </button>
            </div>
          </template>
        </component>
      </div>
    </div>
  </div>
</template>

<script>
  // TODO: Migrate tabs to common.
  import BusyOverlay from '@/components/common/BusyOverlay'
  import Tabs from '@/components/pdf_report/Tabs'
  import CompanyObjectSidebar from '@/components/reports/config/common/CompanyObjectSidebar'
  import CompanyExpressionEditor from '@/components/reports/config/expressions/CompanyExpressionEditor'
  import CompanyMeasureEditor from '@/components/reports/config/measures/CompanyMeasureEditor'
  import CompanyDimensionEditor from '@/components/reports/config/dimensions/CompanyDimensionEditor'
  import CompanyFilterEditor from '@/components/reports/config/filters/CompanyFilterEditor'

  export default {
    name: 'ReportsConfigViewPage',
    components: {
      BusyOverlay,
      CompanyObjectSidebar,
      Tabs,
    },
    props: {},
    data () {
      return {
        defaultTab: 'companyMeasure',
        objectTypes: {
          companyMeasure: {
            label: 'Company Measure',
            objectCrud: this.$apis.companyConfigs.companyMeasure,
            getCompanyObjects: () => this.companyMeasures,
            editor: CompanyMeasureEditor,
          },
          companyDimension: {
            label: 'Company Dimension',
            objectCrud: this.$apis.companyConfigs.companyDimension,
            getCompanyObjects: () => this.companyDimensions,
            editor: CompanyDimensionEditor,
          },
          companyFilters: {
            label: 'Company Filter',
            objectCrud: this.$apis.companyConfigs.companyFilter,
            getCompanyObjects: () => this.companyFilters,
            editor: CompanyFilterEditor,
          },
          companyExpression: {
            label: 'Company Expression',
            objectCrud: this.$apis.companyConfigs.companyExpression,
            getCompanyObjects: () => this.companyExpressions,
            editor: CompanyExpressionEditor,
          },
        },
        selectedObject: null,
        optionsLoading: true,
        objectDirty: false,
        objectLoading: false,
      }
    },
    computed: {
      tabs () {
        return Object.keys(this.objectTypes)
      },
      selectedKey: {
        get () {
          return this.$route.query?.key
        },
        set (key) {
          if (this.$route.query.key != key) {
            this.$router.push({ query: { ...this.$route.query, key, }, })
            this.refreshCurrentObject(this.selectedTab, key)
          }
        },
      },
      selectedTab: {
        get () {
          return this.$route.query?.tab || this.defaultTab
        },
        set (tab) {
          this.$router.push({ query: { tab, }, })
          this.currentObject = null
        },
      },
      currentObject: {
        get () {
          return this.selectedObject
        },
        set (currentObject) {
          this.objectDirty = true
          this.selectedObject = currentObject
        },
      },
      companyMeasures () {
        return this.$store.getters['app/reportsConfig/companyMeasures']
      },
      companyDimensions () {
        return this.$store.getters['app/reportsConfig/companyDimensions']
      },
      companyExpressions () {
        return this.$store.getters['app/reportsConfig/companyExpressions']
      },
      companyFilters () {
        return this.$store.getters['app/reportsConfig/companyFilters']
      },
      companyMeasureOptions () {
        const options = {}
        for (const companyMeasure of this.companyMeasures) {
          options[companyMeasure.key] = companyMeasure
        }
        return options
      },
      companyDimensionOptions () {
        const options = {}
        for (const companyDimension of this.companyDimensions) {
          options[companyDimension.key] = companyDimension
        }
        return options
      },
      companyFilterOptions () {
        const options = {}
        for (const companyFilter of this.companyFilters) {
          options[companyFilter.key] = companyFilter
        }
        return options
      },
      companyExpressionOptions () {
        const options = {}
        for (const companyExpression of this.companyExpressions) {
          options[companyExpression.key] = companyExpression
        }
        return options
      },
    },
    mounted () {
      this.refreshOptions()
      this.refreshCurrentObject(this.selectedTab, this.selectedKey)
    },
    methods: {
      async refreshOptions () {
        this.optionsLoading = true
        const futures = [
          this.$store.dispatch('expressions/initialize'),
          this.$store.dispatch('app/reportsConfig/refreshOptions'),
        ]
        for (let future of futures) {
          await future
        }
        this.optionsLoading = false
      },
      discardChanges () {
        this.refreshCurrentObject(this.selectedTab, this.selectedKey)
      },
      async saveChanges () {
        this.optionsLoading = true
        const response = await this.objectTypes[this.selectedTab].objectCrud.set(this.currentObject)
        if (response.success) {
          this.refreshOptions()
          this.selectedKey = response.data.key
        } else {
          this.optionsLoading = false
          this.currentObject = response.errors
        }
      },
      async refreshCurrentObject (tab, key) {
        if (!!tab && !!key) {
          this.objectLoading = true
          const companyObject = await this.objectTypes[tab].objectCrud.get(key)
          if (this.selectedKey === key && this.selectedTab === tab) {
            this.currentObject = companyObject
            this.objectDirty = false
            this.objectLoading = false
          }
          console.log(this.currentObject)
        }
      },
      async createNew () {
        this.selectedKey = undefined
        this.selectedObject = {}
      },
    },
  }
</script>

<style lang="scss" scoped>

.reports-config {
  position: relative;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  min-height: 100%;
}

.config-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: auto;
}

.sidebar {
  width: 320px;
  font-size: 13px;
  min-height: 100%;

  background: white;

  // border-radius: 10px;
  border-right: $border-1;
  border-top: $border-1;

  display: flex;
  flex-direction: column;
}

.editor {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

</style>
