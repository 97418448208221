<template>
  <div class="expressions">
    <div class="sidebar-container">
      <div class="sidebar">
        <button
          class="button is-light create-button"
          @click="createExpression">
          New Expression
        </button>
        <div class="search-input">
          <b-input
            ref="searchInput"
            v-model="searchInput"
            custom-class="no-border"
            placeholder="Search Company Expressions"
            type="search"
            icon="magnify" />
        </div>

        <div
          ref="expressions-list"
          class="expression-search-results">
          <div
            v-for="expression in resultsToShow"
            :key="expression.id">
            <div
              tabindex="0"
              class="sidebar-item"
              :class="{ selected: expression.id === currentExpressionID }"
              @keydown.enter="selectExpression(expression)"
              @click="selectExpression(expression)">
              <b-tag class="id-tag">
                {{ expression.id }}
              </b-tag>
              {{ expression.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="currentExpression"
      class="content-container">
      <div class="header">
        <h2>
          <b-tag class="id-tag">
            {{ currentExpression?.id }}
          </b-tag>
          <b-input
            ref="currentExpressionName"
            v-model="currentExpressionName"
            class="expression-name-input"
            custom-class="no-border" />
        </h2>

        <div class="actions">
          <button
            class="button is-primary"
            :disabled="!edited"
            @click="saveUpdates">
            Save
          </button>

          <button
            class="button is-light"
            @click="getCurrentExpression">
            Discard
          </button>
        </div>
      </div>

      <div
        v-if="currentExpression"
        class="editor-content">
        <div class="actions">
          <button
            class="button"
            @click="isVisualizationOpen = true">
            Visualize
            <b-icon
              icon="graph-outline"
              class="icon" />
          </button>

          <button
            class="button"
            @click="getPreviewValue">
            Preview

            <b-icon
              icon="function"
              class="icon" />
          </button>

          <button
            class="button"
            @click="getExpressionUsage">
            View Usage

            <b-icon
              icon="chart-donut"
              class="icon" />
          </button>
        </div>
        <expression-editor :expression.sync="selectedSpec" />
      </div>
    </div>

    <div
      v-else
      class="placeholder">
      No expression Selected
    </div>

    <b-modal
      v-model="isVisualizationOpen"
      scroll="keep">
      <div
        v-if="currentExpressionID"
        class="card bg-dots">
        <expression-graph :expression-id="currentExpressionID" />
      </div>
    </b-modal>

    <b-modal
      v-model="isUsageOpen"
      width="500px"
      scroll="keep">
      <div class="card usage-modal">
        <div class="header">
          Used By
          <b-tag
            v-if="!modalLoading"
            style="font-size: 14px">
            {{ usageReport?.length || 0 }}
          </b-tag>
        </div>

        <div class="content">
          <div v-if="modalLoading">
            <b-skeleton
              width="100%"
              :count="4"
              height="40" />
          </div>

          <div v-else>
            <div v-if="usageReport">
              <router-link
                v-for="expression in usageReport"
                :key="`user-expression-${expression.id}`"
                target="_blank"
                :to="{
                  name: 'expressions',
                  query: { id: expression.id },
                }"
                class="item">
                <b-tag class="id-tag">
                  {{ expression.id }}
                </b-tag>
                {{ expression.name }}
              </router-link>
            </div>

            <div v-if="usageReport?.length === 0">
              No results
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="isPreviewOpen"
      width="500px"
      scroll="keep">
      <div class="card usage-modal">
        <div class="header">
          Preview
        </div>

        <div class="content">
          <div v-if="modalLoading">
            <b-skeleton
              width="100%"
              :count="1"
              height="40" />
          </div>

          <div v-else>
            <div v-if="previewData">
              <div
                v-for="(data, i) in previewData"
                :key="i"
                class="item">
                {{ data[0] }}
              </div>
            </div>

            <div v-if="previewData?.length === 0 || !previewData">
              No results
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import ExpressionGraph from '@/components/expression_viewer/ExpressionGraphComponent'
  import ExpressionEditor from '@/components/expressions/ExpressionEditor'
  import { expressionProcessorMixin } from '@/components/reporting/expressionProcessorMixin'

  import FuzzySearch from 'fuzzy-search'
  export default {
    name: 'ExpressionViewPage',
    components: {
      ExpressionGraph,
      ExpressionEditor,
    },
    mixins: [expressionProcessorMixin,],
    props: {},
    data () {
      return {
        currentExpressionID: 0,
        currentExpression: null,

        searchInput: '',
        isVisualizationOpen: false,
        isPreviewOpen: false,
        isUsageOpen: false,
        modalLoading: false,

        usageReport: [],
        previewData: [],

        edited: false,
      }
    },
    computed: {
      currentExpressionName: {
        get () {
          return this.currentExpression.name
        },
        set (name) {
          this.currentExpression = { ...this.currentExpression, name, }
          this.edited = true
        },
      },

      selectedSpec: {
        get () {
          return this.currentExpression.spec
        },
        set (spec) {
          this.currentExpression = { ...this.currentExpression, spec, }
          this.edited = true
        },
      },

      searcher () {
        const searcher = new FuzzySearch(Object.values(this.expressions), [
          'name',
          'id',
        ])
        return searcher
      },
      resultsToShow () {
        return this.searcher.search(this.searchInput)
      },
      expressions () {
        return this.$store.getters['expressions/companyExpressions'] || {}
      },
    },

    async mounted () {
      await this.$store.dispatch('expressions/initialize')

      const queryID = Number(this.$route.query.id)
      if (queryID) {
        this.currentExpressionID = queryID
        this.getCurrentExpression()
      }

      window.addEventListener('keydown', this.shortcutHandler)
    },

    beforeDestroy () {
      window.removeEventListener('keydown', this.shortcutHandler)
    },
    methods: {
      shortcutHandler (e) {
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault()
          this.saveUpdates()
        } else if (e.ctrlKey && e.key === 'f') {
          e.preventDefault()
          this.$refs.searchInput.focus()
        } else if (e.ctrlKey && e.key === 'n') {
          e.preventDefault()
          this.createExpression()
        } else if (e.ctrlKey && e.key === 'p') {
          e.preventDefault()
          this.getPreviewValue()
        } else if (e.ctrlKey && e.key === 'u') {
          e.preventDefault()
          this.getExpressionUsage()
        } else if (e.ctrlKey && e.key === 'v') {
          e.preventDefault()
          this.isVisualizationOpen = true
        }
      },

      async createExpression () {
        const newExpression = await this.$store.dispatch(
          'expressions/createExpression'
        )
        this.currentExpressionID = newExpression.id

        await this.$nextTick()
        await this.$nextTick()
        await this.$nextTick()

        this.getCurrentExpression()

        this.$buefy.toast.open({
          message: `New Expression Created`,
          type: 'is-success',
        })
        this.$refs['expressions-list'].scrollTop =
          this.$refs['expressions-list'].scrollHeight
        this.$refs['currentExpressionName'].focus()
      },
      async saveUpdates () {
        await this.$store.dispatch(
          'expressions/updateExpression',
          this.currentExpression
        )

        this.$buefy.toast.open({
          message: `Saved`,
          type: 'is-success',
        })

        this.edited = false
      },
      getCurrentExpression () {
        if (this.currentExpressionID) {
          this.currentExpression = this.expressions[this.currentExpressionID]
        }
      },

      selectExpression (expression) {
        this.currentExpressionID = expression.id
        this.getCurrentExpression()
        if (Number(this.$route.query?.id) === expression.id) return
        this.$router.push({ query: { id: expression.id, }, })
      },

      async getExpressionUsage () {
        this.usageReport = []
        this.isUsageOpen = true
        this.modalLoading = true

        try {
          const response = await this.$apis.expressions.getExpressionUsage(
            this.currentExpressionID
          )
          this.usageReport = response?.expressions || []
        } catch (e) {
          console.log(e)
        }

        this.modalLoading = false
      },

      async getPreviewValue () {
        this.isPreviewOpen = true
        this.modalLoading = true

        try {
          const response = await this.$apis.expressions.getExpressionPreview({
            sort_by: [],
            limit: 50,
            series: [
              {
                id: this.currentExpressionID,
                expression: this.preprocessExpression(
                  this.currentExpression.spec
                ),
              },
            ],
          })

          this.previewData = response.data
        } catch (e) {
          console.log(e)
        }

        this.modalLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.expressions {
  position: relative;
  max-height: 100%;
  display: flex;
  font-size: 16px;
}
.create-button {
  border-bottom: $border-1 !important;
  font-size: 15px !important;
  width: 100%;
}
.content-container {
  // padding: 0 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .header {
    padding: 20px 40px;
    display: flex;
    width: 100%;
    // padding-bottom: 20px;
    align-items: center;
    h2 {
      font-weight: 400;
      font-size: 20px;
      display: flex;
      align-items: center;
      .id-tag {
        font-size: 15px;
      }
    }

    .actions {
      margin-left: auto;

      .button {
        margin-left: 10px;
        min-width: 120px;
      }
    }

    border-bottom: $border-1;
  }

  .expression-name-input {
    min-width: 500px;
  }

  .editor-content {
    padding: 0 40px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .actions {
      text-align: right;
      padding-top: 30px;
      padding-bottom: 10px;

      .button {
        margin-left: 5px;

        .icon {
          margin-left: 5px;
        }
      }
    }
  }
}

.usage-modal {
  max-width: 500px;
  .header {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: $border-1;
    background-color: #fbfbfb;
  }

  .content {
    max-height: 400px;
    overflow: auto;
    padding: 10px 15px;
    font-size: 13px;
    .item {
      border-bottom: $border-1;
      padding: 10px 5px;
      display: block;

      color: #5c5c5c;
      &:hover {
        background-color: #fbfbfb;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .id-tag {
      font-size: 11px;
      margin-right: 5px;
    }
  }
}

.placeholder {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sidebar-container {
  // padding: 20px 0 20px 20px;
}
.sidebar {
  width: 320px;
  height: 100%;
  font-size: 13px;

  background: white;

  // border-radius: 10px;
  border-right: $border-1;
  border-top: $border-1;

  display: flex;
  flex-direction: column;
}
.search-input {
  padding: 3px 8px;
  border-bottom: $border-1;
  margin: 0px;
}

.expression-search-results {
  overflow-y: scroll;
  height: 100%;
}
.sidebar-item {
  padding: 10px 20px;
  &:hover {
    cursor: pointer;
    background-color: $grayscale-8;
  }
  &.selected {
    background-color: $grayscale-7;
  }
  .id-tag {
    font-size: 12px;
    margin-right: 10px;
  }
}

.expression-canvas {
  border: $border-1;
  border-radius: 10px;
  margin: 20px;
}
</style>
