<template>
  <div class="color-spec-editor">
    <label>
      type:
      <select v-model="specType">
        <option value="default">default</option>
        <option value="manual">Manual</option>
        <option value="over_under">Over Under</option>
        <option value="under_over">Under Over</option>
      </select>
    </label>
  </div>
</template>

<script>

  export default {
    name: 'ColorSpecEditor',
    props: {
      colorSpec: { type: Object, required: true, },
    },
    computed: {
      specType: {
        get () {
          return this.colorSpec.type || 'default'
        },
        set (specType) {
          this.$emit('update:colorSpec', { ...this.colorSpec, type: specType, })
        },
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>

