<template>
  <div class="date-time-filter flex-row">
    <date-time-input
      v-model="start" />
    &nbsp;&ndash;&nbsp;
    <date-time-input
      v-model="end" />
  </div>
</template>

<script>

  import { formatDate } from '@/utils/Date'
  import DateTimeInput from '@/components/common/input/DateTimeInput'

  export default {
    name: 'DateTimeFilterVue',
    components: {
      DateTimeInput,
    },
    props: {
      dimension: { type: Object, required: true, },
      filter: { type: Object, default: null, },
      opens: { type: String, default: 'center', },
    },
    data () {
      return {
        checkOpen: false,
      }
    },
    computed: {
      start: {
        get () {
          return this.dateRange.startDate
        },
        set (value) {
          this.dateRange = { ...this.dateRange, startDate: value, }
        },
      },
      end: {
        get () {
          return this.dateRange.endDate
        },
        set (value) {
          this.dateRange = { ...this.dateRange, endDate: value, }
        },
      },
      dateRange: {
        get () {
          if (this.filter == null) {
            return ({ startDate: new Date(), endDate: new Date(), })
          }
          return ({
            startDate: new Date(Date.parse(this.filter.args.start)),
            endDate: new Date(Date.parse(this.filter.args.end)),
          })
        },
        set (dateRange) {
          this.$emit('update:filter', {
            dimension: this.dimension.key,
            type: 'between',
            args: {
              start: dateRange.startDate.toISOString(),
              end: dateRange.endDate.toISOString(),
            },
          })
        },
      },
      formattedRange () {
        return `${formatDate(this.dateRange.startDate)} - ${formatDate(this.dateRange.endDate)}`
      },
    },
  }

</script>

<style lang="scss" scoped>

.date-time-filter {
  align-items: center;
}

</style>

