<template>
  <div class="dashboard-control">
    {{ dashboardControl }}
  </div>
</template>

<script>

  export default {
    name: 'DashboardControl',
    props: {
      dashboardControl: {
        type: Object,
        required: true,
      },
    },
  }

</script>
