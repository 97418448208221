<template>
  <div class="entity-management">
    <div class="sidebar-container">
      <div class="sidebar">
        <h3>Entities</h3>
        <div class="search-input">
          <b-input
            ref="entitySearch"
            v-model="searchInput"
            custom-class="no-border"
            placeholder="Search Entitites"
            type="search"
            icon="magnify" />
        </div>

        <div class="expression-search-results">
          <div
            v-for="entity in resultsToShow"
            :key="entity.key">
            <div
              tabindex="0"
              :class="{ selected: selectedEntityType?.key === entity?.key }"
              class="sidebar-item"
              @keydown.enter="changeSelectedEntityType(entity)"
              @click="changeSelectedEntityType(entity)">
              {{ entity.name }}
            </div>
            <div
              v-if="
                selectedEntityType?.key === entity?.key &&
                  !!selectedEntityType?.consolidated_type_key
              "
              class="sub-entity">
              <div
                class="sub-entity-type"
                :class="{ selected: !isConsolidatedVersion }"
                @click="isConsolidatedVersion = false"
                @keydown.enter="isConsolidatedVersion = false">
                Source
              </div>
              <div
                class="sub-entity-type"
                :class="{ selected: isConsolidatedVersion }"
                @click="isConsolidatedVersion = true"
                @keydown.enter="isConsolidatedVersion = true">
                Mapped
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-container">
      <div class="header">
        <div>
          <div class="title">
            Attributes
          </div>
          <div class="desc">
            Create and manage custom entity fields
          </div>
        </div>

        <div class="controls">
          <div class="top">
            <router-link
              class="mr-3"
              :to="{
                name: 'manage_attributes',
                params: { entity_type: effectiveEntityType?.key },
              }">
              <button class="button is-dark">
                Manage Attributes
              </button>
            </router-link>

            <router-link
              v-if="!!selectedEntityType?.consolidated_type_key"
              :to="{
                name: 'manage_consolidated_values',
                params: { entity_type: effectiveEntityType?.key },
              }">
              <button class="button">
                Manage Mapped Entities
              </button>
            </router-link>
          </div>

          <div class="bottom">
            <div class="left">
              <div class="filter-input-item mr-3">
                <div class="quick-actions">
                  <div class="button-label">
                    Date Range
                  </div>
                </div>

                <b-dropdown
                  v-model="dateSelectionModel"
                  expanded
                  class="mr-3"
                  aria-role="list">
                  <template #trigger="{ active }">
                    <button
                      class="button date-range-button is-fullwidth"
                      type="is-light">
                      <div class="option-selected-label">
                        <div class="date-label">
                          {{ dateSelectionModel.label }}
                        </div>

                        <div class="selection is-flex">
                          <div
                            v-if="dateSelectionModel?.key != 'all_time'"
                            class="ml-1">
                            {{ dateSelectionModel.dates.start?.key }} to
                            {{ dateSelectionModel.dates.end?.key }}
                          </div>
                        </div>
                      </div>

                      <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
                    </button>
                  </template>

                  <b-dropdown-item
                    v-for="option in dateOptions"
                    :key="option.key"
                    :value="option">
                    {{ option.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <div
                v-if="!!selectedEntityType && hideableFields.length > 0"
                class="filter-input-item mr-3">
                <div class="quick-actions">
                  <div class="button-label">
                    show/hide columns
                  </div>
                  <b-button
                    class="quick-action-button"
                    type="is-text"
                    :disabled="loading"
                    @click.stop="columnsToShow = hideableFields">
                    Select All
                  </b-button>
                  <b-button
                    class="quick-action-button"
                    type="is-text"
                    :disabled="loading"
                    @click.stop="columnsToShow = []">
                    Deselect All
                  </b-button>
                </div>

                <filter-multiselect
                  v-model="columnsToShow"
                  track-by="key"
                  label="label"
                  :disabled="loading"
                  :loading="loading"
                  :options="hideableFields" />
              </div>

              <div class="filter-input-item">
                <div class="quick-actions">
                  <div class="button-label">
                    Download & Import Values
                  </div>
                </div>
                <div class="is-flex">
                  <button
                    class="button mr-3"
                    :disabled="exporting"
                    @click="exportCSV">
                    <i class="material-icons mr-2">save_alt</i>
                    <span>Download Table</span>
                  </button>
                  <b-upload
                    :disabled="isFileUploading"
                    class="mr-3"
                    accept=".csv"
                    type="is-light"
                    @input="importCSV">
                    <div class="button">
                      <i class="material-icons mr-2">file_upload</i>
                      <span>Import Values from CSV</span>
                    </div>
                  </b-upload>
                </div>
              </div>
            </div>

            <div class="right">
              <button
                class="button mr-3"
                :disabled="!hasChanges || loading"
                @click="confirmDircardChanges">
                Discard Changes
              </button>
              <button
                class="button is-primary"
                :disabled="!hasChanges || loading || inputsInvalid"
                @click="applyChanges">
                Save Changes
              </button>
            </div>
          </div>
        </div>

        <div class="columns is-vcentered search-bar">
          <div class="column m-0 p-0">
            <b-input
              ref="searchAttributes"
              v-model="searchTerm"
              custom-class="no-border"
              placeholder="Search..."
              type="search"
              icon="magnify" />
          </div>
        </div>
      </div>
      <div
        v-if="!loading && initialized"
        class="table-container">
        <b-table
          class="attributes-table"
          :hoverable="true"
          :data="filteredRows"
          paginated
          :total="filteredRows.length"
          :per-page="15"
          :sticky-header="true"
          :scrollable="true"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          :draggable-column="true"
          aria-current-label="Current page"
          :checkbox-type="'is-primary'">
          <b-table-column
            v-for="field in entityFields"
            :key="field.key"
            v-slot="props"
            width="100%"
            header-class="sticky-header"
            cell-class="sticky-col"
            :sticky="true"
            :field="field.key"
            :label="field.name"
            sortable>
            <div class="cell-container">
              {{ formatValue(props.row[field.key], field.primitive_model) }}
            </div>
          </b-table-column>

          <b-table-column
            v-for="field in consolidationFields"
            :key="field.key"
            v-slot="props"
            header-class="sticky-header"
            width="100%"
            :field="field.key"
            :label="field.label">
            <consolidated-value-input
              :dirty-value="consolidationOverwrites[props.row.id] || null"
              :value="props.row.consolidated_value_id"
              :options="consolidatedTagsFormatted"
              @input="setConsolidatedValue(props.row.id, $event)" />
          </b-table-column>

          <b-table-column
            v-for="field in showAttributeFields"
            :key="field.attribute.key"
            v-slot="props"
            header-class="sticky-header"
            :field="field.key"
            width="100%"
            :label="field.attribute.name"
            sortable>
            <attribute-input
              :field="field"
              :row="props.row"
              :dirty-value="
                editedValues[props.row.id]
                  ? editedValues[props.row.id][field.attribute.key]
                  : null
              "
              @input="setEditedValue(props.row.id, $event)" />
          </b-table-column>
          <template #empty>
            <div class="has-text-centered mt-5 mb-5">
              <b-tag
                v-if="!loading"
                class="data-tags"
                rounded
                size="is-medium">
                No Data Found 🥥
              </b-tag>
            </div>
          </template>
        </b-table>
      </div>

      <div
        v-else
        class="loading-container">
        <b-loading
          :active="loading"
          :can-cancel="false"
          :is-full-page="false" />
      </div>
    </div>
  </div>
</template>

<script>
  import { debounce, orderBy } from 'lodash'
  import FuzzySearch from 'fuzzy-search'
  import { dvPalette } from '@/components/charting/colors'
  import { mapGetters } from 'vuex'
  import AttributeInput from '@/components/inputs/AttributeInput'
  import ConsolidatedValueInput from '@/components/inputs/ConsolidatedValueInput'
  import filterMultiselect from '@/components/ui/multiselect'
  export default {
    name: 'AttributesHome',
    components: {
      AttributeInput,
      ConsolidatedValueInput,
      filterMultiselect,
    },
    beforeRouteLeave (from, to, next) {
      if (this.hasChanges) {
        this.confirmBeforeLeaving(next)
      } else {
        next()
      }
    },
    data () {
      return {
        searchInput: '',
        initialized: false,
        deletingRow: null,
        creatingConsolidatedValue: false,
        creatingForRow: null,
        batchEditOpen: false,
        exporting: false,
        selectedEntityType_: null,
        isConsolidatedVersion_: false,
        editedValues: {},
        consolidationOverwrites: {},
        hiddenFields: [],
        consolidationOptionsSearchTerm: '',
        searchTerm: '',
        rowSelection: {},
        currentPage: 0,
        sort: null,
        isFileUploading: false,
        validations: {},
        dateSelection: {
          label: 'Last 30 Days',
          key: 'last_month',
          dates: {
            type: 'range',
            start: {
              key: new Date(new Date() - 30 * 24 * 3600000)
                .toISOString()
                .split('T')[0],
              value: new Date(new Date() - 30 * 24 * 3600000)
                .toISOString()
                .split('T')[0],
            },
            end: {
              key: new Date().toISOString().split('T')[0],
              value: new Date().toISOString().split('T')[0],
            },
          },
        },
        dateOptions: [
          {
            label: 'Today',
            key: 'today',
            dates: {
              type: 'range',
              start: {
                key: new Date().toISOString().split('T')[0],
                value: new Date().toISOString().split('T')[0],
              },
              end: {
                key: new Date().toISOString().split('T')[0],
                value: new Date().toISOString().split('T')[0],
              },
            },
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
            dates: {
              type: 'range',
              start: {
                key: new Date(new Date() - 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
                value: new Date(new Date() - 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
              },
              end: {
                key: new Date(new Date() - 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
                value: new Date(new Date() - 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
              },
            },
          },
          {
            label: 'Last 7 Days',
            key: 'last_7',
            dates: {
              type: 'range',
              start: {
                key: new Date(new Date() - 7 * 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
                value: new Date(new Date() - 7 * 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
              },
              end: {
                key: new Date().toISOString().split('T')[0],
                value: new Date().toISOString().split('T')[0],
              },
            },
          },
          {
            label: 'Last 30 Days',
            key: 'last_month',
            dates: {
              type: 'range',
              start: {
                key: new Date(new Date() - 30 * 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
                value: new Date(new Date() - 30 * 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
              },
              end: {
                key: new Date().toISOString().split('T')[0],
                value: new Date().toISOString().split('T')[0],
              },
            },
          },
          {
            label: 'Last 90 Days',
            key: 'last_quarter',
            dates: {
              type: 'range',
              start: {
                key: new Date(new Date() - 90 * 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
                value: new Date(new Date() - 90 * 24 * 3600000)
                  .toISOString()
                  .split('T')[0],
              },
              end: {
                key: new Date().toISOString().split('T')[0],
                value: new Date().toISOString().split('T')[0],
              },
            },
          },
          {
            label: 'All Time',
            key: 'all_time',
            dates: {
              type: 'all_time',
            },
          },
        ],

        draggingRow: null,
        draggingRowIndex: null,
        draggingColumn: null,
        draggingColumnIndex: null,
      }
    },
    computed: {
      ...mapGetters({
        entityTypes: 'entities/entityTypes',
        currentTags: 'entities/currentTags',
        consolidatedTags: 'entities/consolidatedTags',
        attributes: 'entities/entityAttributes',
        loading: 'entities/loading',
      }),

      // hasInvalidCells () {
      //   return Object.values(this.editedValues).some((row) =>
      //     Object.values(row).some((value) => value === undefined)
      //   )
      // },

      inputsInvalid () {
        return Object.values(this.validations).length > 0
      },

      searcher () {
        const searcher = new FuzzySearch(this.baseEntityTypes, ['name',])
        return searcher
      },

      resultsToShow () {
        if (this.searchInput) {
          return this.searcher.search(this.searchInput)
        }
        return this.baseEntityTypes
      },

      columnsToShow: {
        get () {
          return this.hideableFields.filter(
            (field) => !this.hiddenFields.includes(field)
          )
        },
        set (newColumns) {
          this.hiddenFields = this.hideableFields.filter(
            (field) => !newColumns.includes(field)
          )
        },
      },
      consolidatedTagsFormatted () {
        const tags = Object.values(this.consolidatedTags) || []

        const tagsWithLabels = tags.map((item) => {
          return {
            ...item,
            label: this.getConsolidatedValueLabel(item.id),
          }
        })

        return tagsWithLabels.sort((a, b) => {
          return a.label.localeCompare(b.label)
        })
      },
      hasChanges () {
        return (
          Object.keys(this.editedValues).length > 0 ||
          Object.values(this.consolidationOverwrites).length > 0
        )
      },
      selectedRows () {
        return orderBy(
          this.rows.filter((row) => !!this.rowSelection[row.id]),
          (this.sort || {}).attribute
            ? [(row) => this.getTagSort(row, this.sort.attribute),]
            : [(this.sort || {}).key,],
          [(this.sort || {}).ascending ? 'asc' : 'desc',]
        )
      },
      colorOptions () {
        return [
          dvPalette.dvCategory01,
          dvPalette.dvCategory02,
          dvPalette.dvCategory03,
          dvPalette.dvCategory04,
          dvPalette.dvCategory05,
          dvPalette.dvCategory06,
          dvPalette.dvCategory07,
          dvPalette.dvCategory08,
          dvPalette.dvCategory09,
          dvPalette.dvCategory10,
        ]
      },
      rows () {
        return this.currentTags.map((row, idx) => {
          let newRow = {
            ...row.fields,
            ...row,
            color: this.colorOptions[idx % this.colorOptions.length],
          }
          this.attributes.forEach((a) => {
            newRow[this.getAttributeRowKey(a)] = row.attributes[a.key]
          })
          if (
            !!this.selectedEntityType?.consolidated_type_key &&
            !this.isConsolidatedVersion
          ) {
            let consolidatedId =
              this.consolidationOverwrites[row.id] ||
              row['consolidated_value_id']
            newRow['consolidated_value'] = this.consolidatedTags[consolidatedId]
            newRow['consolidated_value_label'] =
              this.getConsolidatedValueLabel(consolidatedId)
          }
          return newRow
        })
      },

      filteredRows () {
        return orderBy(
          this.rows.filter((row) => {
            let isMatch = false
            this.displayedFields.forEach((field) => {
              if (
                JSON.stringify((row[field.key] || '') + '')
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase()) ||
                (!!field.attribute &&
                  (JSON.stringify(this.getTagValue(row, field.attribute))
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase()) ||
                    JSON.stringify(
                      row.attributes[field.attribute.key] ||
                        field.attribute.default_value ||
                        ''
                    )
                      .toLowerCase()
                      .includes(this.searchTerm.toLowerCase())))
              ) {
                isMatch = true
              }
            })
            return isMatch
          }),
          (this.sort || {}).attribute
            ? [(row) => this.getTagSort(row, this.sort.attribute),]
            : [(this.sort || {}).key,],
          [(this.sort || {}).ascending ? 'asc' : 'desc',]
        )
      },
      dateSelectionModel: {
        get () {
          return this.dateSelection
        },
        set (dateSelectionModel) {
          this.rowSelection = {}
          this.dateSelection = dateSelectionModel
          this.refreshTags()
        },
      },
      baseEntityTypes () {
        return this.entityTypes
          .filter((entityType) => !entityType.is_consolidated)
          .map((type) => ({ ...type, label: type.name, }))
      },
      hideableFields () {
        return this.tableFields.filter((field) => field.is_hideable)
      },
      entityFields () {
        return this.entityTypes
          .filter((e) => e?.key == this.effectiveEntityType.key)
          .map((e) => e.fields)
          .flat()
          .map((field) => ({
            ...field,
            label: field.name,
            width: '200',
            sortable: true,
            sortDir:
              !!this.sort && this.sort?.key == field.key
                ? this.sort.ascending
                  ? 'asc'
                  : 'desc'
                : undefined,
          }))
      },
      attributeFields () {
        return this.attributes
          .filter((a) => a.entity_type?.key == this.effectiveEntityType.key)
          .map((a) => ({
            attribute: a,
            key: this.getAttributeRowKey(a),
            sortable: true,
            is_hideable: true,
            label: a.name,
            width: '200',
            type: 'input',
            sortDir:
              !!this.sort && this.sort?.key == this.getAttributeRowKey(a)
                ? this.sort.ascending
                  ? 'asc'
                  : 'desc'
                : undefined,
          }))
      },
      consolidationFields () {
        return !!this.selectedEntityType?.consolidated_type_key &&
          !this.isConsolidatedVersion
          ? [
            {
              key: 'consolidated_value_label',
              label: 'Mapped Entity',
              width: '200',
              sortable: true,
              sortDir:
                !!this.sort && this.sort?.key == 'consolidated_value_label'
                  ? this.sort.ascending
                    ? 'asc'
                    : 'desc'
                  : undefined,
            },
          ]
          : []
      },
      tableFields () {
        return [].concat(
          this.entityFields,
          this.consolidationFields,
          this.attributeFields
        )
      },
      showAttributeFields () {
        return this.attributeFields.filter(
          (field) => !this.hiddenFields.includes(field)
        )
      },
      displayedFields () {
        return this.tableFields.filter(
          (field) => !this.hiddenFields.includes(field)
        )
      },
      isConsolidatedVersion: {
        get () {
          return this.isConsolidatedVersion_
        },
        set (isConsolidatedVersion) {
          this.isConsolidatedVersion_ = isConsolidatedVersion
          this.refreshTags()
          this.sort = {
            key: this.effectiveEntityType.fields[0].key,
            ascending: true,
          }
          this.rowSelection = {}
        },
      },
      consolidatedEntityVersion () {
        return this.entityTypes
          .filter(
            (entityType) =>
              entityType?.key == this.selectedEntityType?.consolidated_type_key
          )
          .shift()
      },
      effectiveEntityType () {
        if (this.isConsolidatedVersion) {
          return this.consolidatedEntityVersion
        } else {
          return this.selectedEntityType
        }
      },
      selectedEntityType: {
        get () {
          return this.selectedEntityType_ || this.entityTypes.slice(0).shift()
        },
        set (entityType) {
          this.selectedEntityType_ = entityType
          // Setting consolidatedVersion causes tags to refresh. Bad pattern!
          this.isConsolidatedVersion = false
        },
      },
    },
    watch: {
      selectedEntityType (newVal) {
        if (!this.initialized) return //prevent this from running on loading initial values causing an override
        this.saveOptionToURL({ entity: newVal.key, })
      },
      isConsolidatedVersion (newVal) {
        if (!this.initialized) return //prevent this from running on loading initial values causing an override
        this.saveOptionToURL({ ...this.$route.query, is_consolidated: newVal, })
      },
      dateSelectionModel (newVal) {
        if (!this.initialized) return //prevent this from running on loading initial values causing an override
        this.saveOptionToURL({ ...this.$route.query, date: newVal.key, })
      },
      hiddenFields (newVal) {
        if (!this.initialized) return //prevent this from running on loading initial values causing an override
        this.saveOptionToURL({
          ...this.$route.query,
          hidden_fields: JSON.stringify(newVal.map((item) => item.key)),
        })
      },
    },

    async mounted () {
      await this.initialize()
      await this.loadValuesFromUrl()
      window.addEventListener('keydown', debounce(this.shortcutHandler, 300))
      this.initialized = true
    },
    methods: {
      shortcutHandler (e) {
        try {
          if (e.ctrlKey && e.key === 'f') {
            this.$refs.entitySearch.focus()
          }
          if (e.ctrlKey && e.key === 'F') {
            this.$refs.searchAttributes.focus()
          } else if (e.ctrlKey && e.key === 's') {
            if (!this.hasChanges || this.loading || this.inputsInvalid) {
              return
            }
            this.applyChanges()
          } else if (e.ctrlKey && e.key === 'm') {
            this.$router.push({
              name: 'manage_attributes',
              params: { entity_type: this.effectiveEntityType?.key, },
            })
          } else if (e.ctrlKey && e.key === 'M') {
            if (this.selectedEntityType?.consolidated_type_key) {
              this.$router.push({
                name: 'manage_consolidated_values',
                params: { entity_type: this.effectiveEntityType?.key, },
              })
            }
          }
        } catch (e) {
          console.log('ERROR: Failed to handle shortcut', e)
        }
      },
      changeSelectedEntityType (entity) {
        if (this.hasChanges) {
          this.confirmBeforeLeaving(() => {
            this.dicardChanges()
            this.selectedEntityType = entity
          })
        } else {
          this.selectedEntityType = entity
        }
      },
      confirmBeforeLeaving (handler) {
        this.$buefy.dialog.confirm({
          title: 'Unsaved Changes',
          message: 'Are you sure you want to discard changes and leave page?',
          onConfirm: () => {
            handler()
          },
        })
      },

      formatValue (value) {
        //todo : format date value
        // if (type === 'IngestDate') {
        //   return new Date(value).toLocaleString()
        // }

        return value
      },
      saveOptionToURL (query) {
        try {
          this.$router.push({
            name: 'attributes',
            query: query,
          })
        } catch (e) {
          console.log('ERROR: Failed to save option to URL', e)
        }
      },

      startDeleting (row) {
        this.deletingRow = row
      },
      cancelDeleting () {
        this.deletingRow = null
      },
      async confirmDeleting () {
        let promise = this.$store.dispatch('entities/deleteConsolidatedValue', {
          entity_type: this.effectiveEntityType,
          entity: this.deletingRow,
          date_range: this.dateSelection.dates,
        })

        this.deletingRow = null
        await promise
      },
      setConsolidatedValue (rowId, consolidatedId) {
        let newOverwrites = { ...this.consolidationOverwrites, }
        newOverwrites[rowId] = consolidatedId
        this.consolidationOverwrites = newOverwrites
      },
      getConsolidatedValueLabel (consolidated_value_id) {
        return (
          ((this.consolidatedTags[consolidated_value_id] || {}).fields || {})[
            this.consolidatedEntityVersion.fields[0].key
          ] || ''
        )
      },
      async initialize () {
        for (let initFuture of [
        this.$store.dispatch('entities/initialize', this.dateSelection.dates),
        this.$store.dispatch('expressions/initialize'),
        ]) {
          await initFuture
        }

        this.sort = {
          key: this.effectiveEntityType.fields[0].key,
          ascending: true,
        }
      },

      loadValuesFromUrl () {
        //set values from url query

        try {
          if (this.$route.query.entity) {
            this.selectedEntityType = this.entityTypes.find(
              (entityType) => entityType?.key == this.$route.query.entity
            )
          }
          if (this.$route.query.is_consolidated) {
            this.isConsolidatedVersion = true
          }

          if (this.$route.query.date) {
            this.dateSelectionModel = this.dateOptions.find(
              (option) => option?.key == this.$route.query.date
            )
          }

          if (
            this.$route.query.hidden_fields &&
            this.$route.query.hidden_fields.length > 0
          ) {
            const keys = JSON.parse(this.$route.query.hidden_fields)
            this.hiddenFields = this.hideableFields.filter((field) =>
              keys.includes(field.key)
            )
          }

          if (this.$route.query.sorting) {
            try {
              this.sort = JSON.parse(this.$route.query.sorting)
            } catch (e) {
              console.log('ERROR: Failed to sort columns', e)
            }
          }
        } catch (e) {
          console.log('ERROR: failed to load options from query string', e)
        }
      },
      setConsolidationSearchTerm (searchTerm) {
        this.consolidationOptionsSearchTerm = searchTerm
      },
      setSearchTerm (searchTerm) {
        this.currentPage = 0
        this.searchTerm = searchTerm
      },
      getAttributeRowKey (attribute) {
        return `attribute-${attribute.key}`
      },
      onSort (field) {
        if (this.sort?.key == field.key) {
          this.sort = {
            ...this.sort,
            ascending: !this.sort.ascending,
            attribute: field.attribute,
          }
        } else {
          this.sort = {
            key: field.key,
            ascending: true,
            attribute: field.attribute,
          }
        }

        this.saveOptionToURL({
          ...this.$route.query,
          sorting: JSON.stringify(this.sort),
        })
      },
      // Similar to tag value but we don't look at edits.
      getTagSort (row, attribute) {
        if (row.attributes[attribute.key] != undefined) {
          return row.attributes[attribute.key]
        }
        if (attribute.default_value != undefined) {
          return attribute.default_value
        }
        return ''
      },
      getTagValue (row, attribute) {
        if (
          this.editedValues[row.id] != undefined &&
          this.editedValues[row.id][attribute.key] != undefined &&
          this.editedValues[row.id] != null &&
          this.editedValues[row.id][attribute.key] != null
        ) {
          return this.editedValues[row.id][attribute.key]
        }
        if (
          row.attributes[attribute.key] != undefined &&
          row.attributes[attribute.key] != null
        ) {
          return row.attributes[attribute.key]
        }
        if (
          attribute.default_value != undefined &&
          attribute.default_value != null
        ) {
          return attribute.default_value
        }
        return ''
      },
      setEditedValue (rowId, input) {
        const cellId = `${rowId}-${input.key}`

        if (input.invalid) {
          this.$set(this.validations, cellId, 'invalid')
          return
        }

        this.$delete(this.validations, cellId)

        if (!this.editedValues[rowId]) {
          this.$set(this.editedValues, rowId, {})
        }

        this.$set(this.editedValues[rowId], input.key, input.value)
      },

      openBatchEdit () {
        this.batchEditOpen = true
      },
      exportCSV () {
        this.exporting = true
        let data = [
          [
            'INGEST_ID',
            ...[]
              .concat(this.entityFields, this.consolidationFields)
              .filter((field) => !this.hiddenFields.includes(field))
              .map((field) => `"${field.label.replaceAll('"', '""')}"`),
            ...this.attributeFields
              .filter((field) => !this.hiddenFields.includes(field))
              .map(
                (field) =>
                  `"${field.label.replaceAll(
                    '"',
                    '""'
                  )} [ATTRIBUTE_${field.attribute.key.replaceAll('"', '""')}]"`
              ),
          ],
          ...this.filteredRows.map((row) => [
            row.id,
            ...[]
              .concat(this.entityFields, this.consolidationFields)
              .filter((field) => !this.hiddenFields.includes(field))
              .map((field) => {
                return `"${((row[field.key] || '') + '').replaceAll('"', '""')}"`
              }),
            ...this.attributeFields
              .filter((field) => !this.hiddenFields.includes(field))
              .map((field) => {
                let cell = this.getTagValue(row, field.attribute)
                if (field.attribute.data_type?.key == 'string') {
                  cell = `"${cell.replaceAll('"', '""')}"`
                }
                return cell
              }),
          ]),
        ]

        let csvBlob = new Blob([data.map((r) => r.join(',')).join('\n'),], {
          type: 'text/csv',
        })
        let csvURL = URL.createObjectURL(csvBlob)

        var link = document.createElement('a')

        link.setAttribute('href', csvURL)
        link.setAttribute(
          'download',
          `${this.selectedEntityType.name}_attributes.csv`
        )

        document.body.appendChild(link) // Required for FF
        link.click()
        document.body.removeChild(link)

        this.exporting = false
      },
      async importCSV (files) {
        this.isFileUploading = true

        try {
          this.editedValues = (
            await this.$store.dispatch('entities/importCsv', {
              csvFile: files,
              entity_type: this.effectiveEntityType,
            })
          ).data
        } catch (e) {
          this.$buefy.toast.open({
            message: 'Failed to import CSV',
            type: 'is-danger',
          })
        } finally {
          this.isFileUploading = true
        }
      },
      openNewConsolidatedValue (applyTo) {
        this.creatingConsolidatedValue = true
        this.creatingForRow = applyTo
      },
      async finishCreatingConsolidatedValue (newValue) {
        if (newValue) {
          let newTag = await this.$store.dispatch(
            'entities/createConsolidatedValue',
            {
              consolidated_type_key:
                this.selectedEntityType?.consolidated_type_key,
              name: newValue,
            }
          )
          this.setConsolidatedValue(this.creatingForRow.id, newTag.id)
        }
        this.creatingConsolidatedValue = false
        this.creatingForRow = null
      },

      closeBatchEdit () {
        this.batchEditOpen = false
      },
      async refreshTags () {
        this.currentPage = 0
        await this.$store.dispatch('entities/loadCurrentTags', {
          entity_type: this.effectiveEntityType,
          date_range: this.dateSelection.dates,
        })
      },
      async applyChanges () {
        await this.$store.dispatch('entities/updateTags', {
          attributeUpdates: this.editedValues,
          consolidationUpdates: this.consolidationOverwrites,
          entity_type: this.effectiveEntityType,
          date_range: this.dateSelection.dates,
        })
        this.editedValues = {}
        this.consolidationOverwrites = {}
      },
      confirmDircardChanges () {
        this.cancelling = true
        this.$buefy.dialog.confirm({
          title: 'Discard Changes',
          message:
            '<div class="ml-4"> Are you sure you want to discard all changes? </div>',
          confirmText: 'Discard',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.dicardChanges()
          },
        })
      },
      dicardChanges () {
        this.editedValues = {}
        this.consolidationOverwrites = {}
      },
    },
  }
</script>

<style lang="scss" scoped>
.entity-management {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  font-size: 16px;

  .content-container {
    padding: 25px 50px;
    height: 100%;
    width: 100%;
    overflow: auto;
    .header {
      width: 100%;
      margin-bottom: 30px;
      .title {
        font-size: 24px;
        font-weight: 500;
        color: $grayscale-1;
        margin-bottom: 4px;
      }
      .desc {
        font-size: 14px;
        color: $grayscale-2;
      }

      .controls {
        margin: 20px 0;
        align-items: center;

        .date-range-button {
          height: 47px !important;
          .option-selected-label {
            text-align: left;
            margin-right: auto;

            .date-label {
              font-size: 12px;
              margin-bottom: 0;
              font-weight: 500;
              color: $ui-04;
            }
            .selection {
              font-size: 14px;
              font-weight: 500;
              color: $ui-01;
            }
          }
        }

        .top {
          padding-bottom: 20px;
          border-bottom: $border-1;
          margin-bottom: 20px;
        }

        .bottom {
          display: flex;

          .button {
            height: 47px;
            font-size: 15px;
          }
          .left {
            display: flex;
            align-items: flex-end;

            .filter-input-item {
              min-width: 270px;

              .quick-actions {
                display: flex;
                align-items: center;
                padding-bottom: 3px;
              }
              .button-label {
                font-size: 13px;
                margin-right: auto;
                text-transform: capitalize;
                color: $ui-02;
                font-weight: 500;
              }

              .quick-action-button {
                font-size: 10px;
                padding: 0 6px;
                height: 16px;
                color: $grayscale-3;
              }
            }
          }

          .right {
            padding-top: 20px;
            margin-left: auto;
          }
        }
      }

      .search-bar {
        padding: 8px 8px;
        border: $border-1;
        border-radius: 6px;
        margin: 0px;
      }
    }
  }

  .sidebar {
    width: 320px;
    height: 100%;
    overflow-y: auto;
    font-size: 13px;
    padding: 20px 15px;
    background: #fbfbfb;
    border-right: $border-1;
    border-top: $border-1;
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .search-input {
    border: $border-1;
    border-radius: 4px;
    background-color: white;
    margin-bottom: 10px;
  }

  .sidebar-item {
    padding: 10px 20px;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      background-color: $grayscale-6;
    }
    &.selected {
      background-color: $primary;
      color: white;
    }
    .id-tag {
      font-size: 12px;
      margin-right: 10px;
    }
  }

  .sub-entity {
    padding-left: 35px;
    .sub-entity-type {
      font-size: 14px;
      margin: 5px 0;
      width: 100%;
      display: block;
      text-align: left;
      padding: 10px 20px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: $grayscale-6;
      }

      &.selected {
        background-color: hsl(0, 0%, 21%);
        color: white;
      }
    }
  }
}

.table-container {
  overflow: hidden !important;
  max-height: 100%;
  display: flex;
  width: 100%;
}

.loading-container {
  height: 300px;
  width: 100%;
  position: relative;
  z-index: 1;
}
</style>

<style lang="scss">
.attributes-table {
  font-size: 16px;
  max-width: 100%;
  max-height: 100% !important;
  display: flex !important;
  flex-direction: column !important;

  .table-wrapper {
    display: flex !important;
    border: $border-1 !important;
    border-radius: 6px;
    max-height: 100% !important;
    height: 100% !important;
    overflow-y: auto !important;
  }

  table {
    border-radius: rounded;
    font-size: 14px;

    tr {
      height: 50px !important;
      max-height: 50px !important;
      th,
      td {
        min-width: 250px !important;
        width: 100% !important;
      }
    }
  }

  tbody .sticky-col {
    z-index: 5 !important;
    background-color: #f4f4f4 !important;
  }

  thead {
    position: relative;
    z-index: 6 !important;
  }
}
</style>
