<template>
  <div>
    <b-datepicker
      v-model="model"
      placeholder="Click to select..."
      icon="calendar-today"
      :position="position"
      trap-focus />
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'DateInput',
    props: {
      value: { type: [String, Number, Date,], default: null, },
      position: { type: String, default: 'is-bottom-right', },
    },
    computed: {
      model: {
        get () {
          return moment(this.value, 'YYYY-MM-DD').toDate()
        },
        set (value) {
          this.$emit('input', moment(value).format('YYYY-MM-DD'))
        },
      },
    },

  }

</script>

<style lang="scss" scoped>

.date-input {
  font-size: $h1;
  margin-top: 2 * $gridBase;
  width: 40 * $remGrid;
}

</style>

