<template>
  <div class="container-fluid">
    <div class="container">
      <div class="header">
        <h2>Email Reports</h2>
        <p>
          Manage email reports, recipients, attachments, and manually send
          emails
        </p>
        <div class="control-bar">
          <div class="left">
            <b-tabs
              v-model="currentTab"
              type="is-toggle"
              class="tabs">
              <b-tab-item value="emails">
                <template #header>
                  <!-- <b-icon icon="email-outline" /> -->
                  <span class="tab-button"> Emails</span>
                </template>
              </b-tab-item>
              <b-tab-item value="reports">
                <template #header>
                  <span class="tab-button"> Reports</span>
                </template>
              </b-tab-item>
            </b-tabs>
          </div>
          <div class="right">
            <router-link
              v-if="
                $hasPermission('MANAGE_EMAIL_CONFIG') &&
                  currentTab === 'emails'
              "
              :to="{
                name: 'email_editor',
                params: { report_id: 'new' },
              }">
              <b-button
                type="is-success is-light"
                class="action-btn"
                icon-left="plus">
                Create Email Report
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <div class="columns is-vcentered report-table-filters">
          <div class="column pdf-search-input m-0 p-0 mr-4">
            <b-input
              v-model="reportSearchInput"
              custom-class="no-border"
              placeholder="Search..."
              type="search"
              icon="magnify" />
          </div>

          <div v-if="$hasPermission('MANAGE_EMAIL_CONFIG')">
            <b-button
              :type="reportsFilterByActiveTabs === 0 ? 'is-light' : 'is-white'"
              class="mr-2"
              @click="updateFilterTab(0)">
              Active
              <b-tag
                class="data-tags is-white"
                rounded>
                {{ filteredByActive.length }}
              </b-tag>
            </b-button>
            <b-button
              :type="reportsFilterByActiveTabs === 1 ? 'is-light' : 'is-white'"
              @click="updateFilterTab(1)">
              Inactive<b-tag
                class="data-tags is-white"
                rounded>
                {{ filteredByInActive.length }}
              </b-tag>
            </b-button>
          </div>
        </div>
      </div>

      <b-table
        v-if="currentTab === 'emails'"
        :data="resultsToShow"
        :loading="isLoading"
        :hoverable="true"
        class="email-reports-table">
        <b-table-column
          v-slot="props"
          sortable
          field="displayName"
          label="Name">
          {{ props.row.displayName || props.row.name }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          sortable
          field="subject"
          label="Subject">
          {{ props.row.subject }}
        </b-table-column>
        <!-- <b-table-column v-slot="props"
                        width="180"
                        label="Locations(s)">
          <b-dropdown
            v-if="props.row.reportPdfs && props.row.reportPdfs.locationsScope.length > 1"
            aria-role="list">
            <template #trigger>
              <b-button
                class="location-dropdown-button"
                type="is-light"
                icon-right="menu-down">
                <div style="width: 110px; text-align: left">
                  Multiple
                </div>
              </b-button>
            </template>
            {{ props.row.reportPdfs.locationsScope }}
            <b-dropdown-item
              v-for="(locationId, i) in props.row.reportPdfs.locationsScope"
              :key="i"
              disabled
              :focusable="false"
              class="unclickable"
              aria-role="listitem">
              {{ allLocations[locationId] }}
            </b-dropdown-item>
          </b-dropdown>

          <div v-else-if="props.row.reportPdfs && props.row.reportPdfs.locationsScope.length === 1">
            {{ allLocations[props.row.reportPdfs.locationsScope[0]] }}
          </div>
        </b-table-column> -->
        <b-table-column
          v-slot="props"
          centered
          field="recipients"
          label="Recipients">
          <b-tooltip
            :label="props.row.recipients.join(' ')"
            type="is-light"
            multilined
            :active="!!props.row.recipients"
            position="is-bottom">
            <b-tag class="data-tags">
              {{ props.row.recipients ? props.row.recipients.length : 0 }}
            </b-tag>
          </b-tooltip>
        </b-table-column>
        <b-table-column
          v-slot="props"
          centered
          label="Attachments">
          <b-tooltip
            :label="props.row.reportPdfs.map((pdf) => pdf.name).join(' ')"
            :active="!!props.row.reportPdfs"
            type="is-light"
            multilined
            position="is-bottom">
            <b-tag class="data-tags">
              {{ props.row.reportPdfs ? props.row.reportPdfs.length : 0 }}
            </b-tag>
          </b-tooltip>
        </b-table-column>
        <b-table-column
          v-slot="props"
          centered
          field="version"
          label="Version">
          <b-tag class="data-tags">
            {{ props.row.version }}
          </b-tag>
        </b-table-column>
        <b-table-column
          v-if="$hasPermission('MANAGE_EMAIL_CONFIG')"
          v-slot="props"
          centered
          field="active"
          label="Active">
          <b-field>
            <b-switch
              :key="props.row.id"
              :value="props.row.isActive"
              type="is-success"
              @input="updateActive(props.row.id, !props.row.isActive)" />
          </b-field>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="Actions"
          centered
          width="100"
          label="Actions">
          <div class="actions">
            <b-tooltip
              v-if="$hasPermission('MANAGE_EMAIL_CONFIG')"
              label="Create a Copy"
              position="is-bottom"
              type="is-light">
              <router-link
                :to="{
                  name: 'email_editor',
                  params: { report_id: 'new', copyFromReportID: props.row.id },
                }">
                <b-button
                  type="is-light"
                  class="action-btn"
                  icon-right="content-copy" />
              </router-link>
            </b-tooltip>
            <b-tooltip
              v-if="$hasPermission('EDIT_EMAIL')"
              label="Edit Email"
              position="is-bottom"
              type="is-light">
              <router-link
                :to="{
                  name: 'email_editor',
                  params: { report_id: props.row.id },
                }">
                <b-button
                  type="is-light"
                  class="action-btn"
                  icon-right="lead-pencil" />
              </router-link>
            </b-tooltip>
          </div>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered mt-5 mb-5">
            <b-tag
              v-if="!isLoading"
              class="data-tags"
              rounded
              size="is-medium">
              No Reports Found 🥥
            </b-tag>
          </div>
        </template>
      </b-table>

      <b-table
        v-else
        :data="resultsToShow"
        :loading="isLoading"
        :hoverable="true"
        class="email-reports-table">
        <b-table-column
          v-slot="props"
          sortable
          field="displayName"
          label="Name">
          {{ props.row.displayName || props.row.name }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          width="180"
          label="Locations(s)">
          <b-dropdown
            v-if="props.row.locationsScope && props.row.locationsScope.length > 1"
            :triggers="['hover']"
            aria-role="list">
            <template #trigger>
              <b-button
                class="location-dropdown-button unclickable"
                type="is-light"
                icon-right="menu-down">
                <div style="width: 130px; text-align: left">
                  Multiple
                </div>
              </b-button>
            </template>

            <b-dropdown-item
              v-for="(locationId, i) in props.row.locationsScope"
              :key="i"
              disabled
              :focusable="false"
              class="unclickable"
              aria-role="listitem">
              {{ allLocations[locationId] }}
            </b-dropdown-item>
          </b-dropdown>

          <div v-else-if="props.row.locationsScope && props.row.locationsScope.length === 1">
            {{ allLocations[props.row.locationsScope[0]] }}
          </div>
        </b-table-column>

        <b-table-column
          v-slot="props"
          centered
          width="100"
          field="Filetype"
          label="Filetype">
          <b-tag class="data-tags">
            {{ props.row.fileType || "PDF" }}
          </b-tag>
        </b-table-column>
        <b-table-column
          v-slot="props"
          centered
          width="100"
          field="version"
          label="Version">
          <b-tag class="data-tags">
            {{ props.row.version }}
          </b-tag>
        </b-table-column>

        <b-table-column
          v-if="$hasPermission('MANAGE_EMAIL_CONFIG')"
          v-slot="props"
          width="100"
          centered
          field="active"
          label="Active">
          <b-field>
            <b-switch
              :key="props.row.id"
              :value="props.row.isActive"
              type="is-success"
              @input="updateActive(props.row.id, !props.row.isActive)" />
          </b-field>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="Actions"
          centered
          width="100"
          label="Actions">
          <div class="actions">
            <b-tooltip
              v-if="$hasPermission('EDIT_EMAIL')"
              label="Edit Report"
              position="is-bottom"
              type="is-light">
              <router-link
                :to="{
                  name: 'report_editor',
                  params: { report_id: props.row.id },
                }">
                <b-button
                  type="is-light"
                  class="action-btn"
                  icon-right="lead-pencil" />
              </router-link>
            </b-tooltip>
          </div>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered mt-5 mb-5">
            <b-tag
              v-if="!isLoading"
              class="data-tags"
              rounded
              size="is-medium">
              No Reports Found 🥥
            </b-tag>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'
  export default {
    name: 'EmailReportLibraryPage',
    data () {
      return {
        allEmails: [],
        allReports: [],
        allLocations: {},
        isLoading: false,
        reportSearchInput: '',
        reportsFilterByActiveTabs: 0,
        currentTab: 'emails',
      }
    },

    computed: {
      filteredByActive () {
        if (this.currentTab === 'emails') {
          return this.allEmails.filter((report) => report.isActive)
        }
        return this.allReports.filter((report) => report.isActive)
      },
      filteredByInActive () {
        if (this.currentTab === 'emails') {
          return this.allEmails.filter((report) => !report.isActive)
        }
        return this.allReports.filter((report) => !report.isActive)
      },

      pdfTableData () {
        if (this.reportsFilterByActiveTabs === 0) {
          return this.filteredByActive
        }
        return this.filteredByInActive
      },

      resultsToShow () {
        if (this.reportSearchInput) {
          return this.searcher.search(this.reportSearchInput)
        }
        return this.pdfTableData
      },

      searcher () {
        const searcher = new FuzzySearch(this.pdfTableData, [
          'displayName',
          'subject',
          'name',
        ])
        return searcher
      },
    },

    async mounted () {
      this.loadData()

      const localTabId = localStorage.getItem('email_report_library_tab')
      if (['0', '1',].includes(localTabId)) {
        this.reportsFilterByActiveTabs = parseInt(localTabId)
      }
    },

    methods: {
      updateFilterTab (tabId) {
        this.reportsFilterByActiveTabs = tabId
        localStorage.setItem('email_report_library_tab', tabId)
      },

      async loadEmails () {
        const emailsResponse = await this.$apis.emailReports.getAllEmailReports()
        this.allEmails = emailsResponse ? emailsResponse : []
      },

      async loadReports () {
        const reportsResponse = await this.$apis.emailReports.getReports()
        this.allReports = reportsResponse ? reportsResponse : []
      },
      async loadData () {
        this.isLoading = true

        await this.loadEmails()
        await this.loadReports()

        const locationsResponse = await this.$apis.app.getAllLocations()
        locationsResponse.forEach((location) => {
          this.allLocations[location.id] = location.display_name
        })

        this.isLoading = false
      },
      async updateActive (reportID, value) {
        if (this.currentTab === 'emails') {
          await this.$apis.emailReports.updateEmailReport(reportID, {
            isActive: value,
          })
          this.loadEmails()
        } else {
          await this.$apis.emailReports.saveReport(reportID, {
            isActive: value,
          })
          this.loadReports()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  width: 100%;
  padding: 20px 14px;
  overflow: auto;

  .header {
    margin-top: 30px;
    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    p {
      color: $ui-03;
      font-size: 16px;
      margin-top: 14px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;

      margin-top: 50px;
    }

    .right {
      margin-left: auto;
    }
    .tabs {
      .tab-button {
        width: 100px;
        text-align: center;
      }
      overflow: hidden;
      // border: $border-1;
    }
  }

  .email-reports-table {
    font-size: 14px;
    margin-top: 20px;
    border: $border-1;
    border-radius: 6px;
  }

  .report-table-filters {
    padding: 8px 8px;
    border: $border-1;
    border-radius: 6px;
    margin: 0px;
  }

  .data-tags {
    font-size: 12px;
    margin: 2px;
  }

  // .location-dropdown-button {
  //   width: 140px;
  //   padding: 0px;
  // }

  .actions {
    display: flex;
    justify-content: center;

    .action-btn {
      margin: 0 5px;
    }
  }
}
</style>
