<template>
  <div class="variance-column-view">
    <chart-title-bar
      :name.sync="nameModel"
      :is-edit="isEdit">
      <template #toolbarButtons>
        <slot name="toolbarButtons" />
      </template>
    </chart-title-bar>
    <template v-if="error">
      <error-view
        :name="name"
        @reload="$emit('reload')" />
    </template>
    <template v-else>
      <slot name="moduleControls" />
      <div class="chart-container">
        <ingest-tooltip
          :left="tooltipX"
          :top="tooltipY"
          :enabled="tooltipEnabled">
          <hover-card
            :columns="columns"
            :datum="tooltipDatum"
            :beta-spec="betaSpec" />
        </ingest-tooltip>
        <resize-sensor @resize="onChartResize" />
        <svg
          ref="svg"
          :viewBox="viewBox"
          @mousemove="onHover"
          @mouseleave="onLeave()"
          @scroll="onLeave()">
          <y-axis
            :columns="columns"
            :hidden-columns="hiddenColumns"
            :data="data"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            mirror-y-axis />
          <x-axis
            :columns="columns"
            :data="data"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            :highlighted-data="highlightedData"
            @click="$emit('click', $event)" />
          <column-series
            v-if="!!varianceMeasure"
            :measure="varianceMeasure"
            :data="data"
            :columns="columns"
            :hidden-columns="hiddenColumns"
            :chart-width="viewWidth"
            :chart-height="viewHeight"
            :highlight-enabled="highlightEnabled"
            :highlighted-measures="highlightedMeasures"
            :highlighted-data="highlightedData"
            mirror-y-axis
            @click="$emit('click', $event)" />
        </svg>
      </div>
      <performance-legend :columns="columns">
        <template #aboveLabel>
          Increase in {{ primaryMeasure.name }}
        </template>
        <template #belowLabel>
          Decrease in {{ primaryMeasure.name }}
        </template>
      </performance-legend>
    </template>
  </div>
</template>
<script>

  import ChartTitleBar from '@/components/charting/ChartTitleBar'
  import ColumnSeries from '@/components/charting/ColumnSeries'
  import ErrorView from '@/components/module/view/ErrorView.vue'
  import HoverCard from '@/components/module/view/HoverCard.vue'
  import IngestTooltip from '@/components/common/IngestTooltip.vue'
  import PerformanceLegend from '@/components/charting/PerformanceLegend'
  import XAxis from '@/components/charting/XAxis'
  import YAxis from '@/components/charting/YAxis'

  import chartContainerMixin from '@/components/charting/chart_container_mixin'

  export default {
    name: 'VarianceColumnView',
    components: {
      ChartTitleBar,
      ColumnSeries,
      ErrorView,
      HoverCard,
      IngestTooltip,
      PerformanceLegend,
      YAxis,
      XAxis,
    },
    mixins: [
      chartContainerMixin,
    ],
    computed: {
      mirrorYAxis () {
        return true
      },
      hiddenColumns () {
        return [this.primaryMeasure, this.secondaryMeasure,]
      },
    },
    methods: {
      onLeave () {
        this.clearHoverValues()
      },
    },
  }

</script>

<style lang="scss" scoped>

.variance-column-view {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 8 * $gridBase;
  padding-top: 0;

  > .chart-container {
    display: flex;
    min-height: 0;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: visible;

    margin-top: 6 * $gridBase;
    margin-bottom: 22 * $gridBase;
    margin-left: 12 * $gridBase;

    > svg {
      overflow: visible;
      width: 100%;
      height: 100%;
    }
  }
}

svg {
  overflow: visible
}

</style>
