<template>
  <div class="company-object-editor">
    <div class="header">
      <h2>
        <b-tag class="id-tag">
          {{ key }}
        </b-tag>
        <TextInput
          v-model="name"
          :errors="nameErrors"
          :placeholder="namePlaceholder"
          class="name-input" />
      </h2>
      <slot name="header-actions" />
    </div>
    <ValidationErrors :errors="nameErrors" />
    <div class="editor-content">
      <slot name="object-editor" />
    </div>
  </div>
</template>

<script>

  import TextInput from '@/components/ui/TextInput'
  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'CompanyDimensionEditor',
    components: {
      TextInput,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    props: {
      objectType: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapChildren({
        key: 'key',
        name: 'name',
      }),
      namePlaceholder () {
        return `${this.objectType} name`
      },
    },
  }

</script>

<style lang="scss" scoped>

.company-object-editor {
  // padding: 0 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .header {
    padding: 20px 40px;
    display: flex;
    width: 100%;
    // padding-bottom: 20px;
    align-items: center;
    h2 {
      font-weight: 400;
      font-size: 20px;
      display: flex;
      align-items: center;
      .id-tag {
        font-size: 15px;
      }
    }

    .actions {
      margin-left: auto;

      .button {
        margin-left: 10px;
        min-width: 120px;
      }
    }

    border-bottom: $border-1;
  }
}

.name-input {
  min-width: 500px;
}

</style>
