<template>
  <li>
    <div class="node">
      <filter-node
        v-if="data.node.type === 'filter' && data.node.by.length"
        :data="data.node.by" />

      <arithmetic-node
        v-else-if="data.node.type === 'arithmetic'"
        :data="data.node.values" />

      <comparison-node
        v-else-if="data.node.type === 'comparison'"
        :data="data.node.values" />

      <inline-expression
        v-else-if="data.node.type === 'inline-expression'"
        :data="data.node" />

      <error-node
        v-else-if="data.node.type === 'error'"
        :data="data.node" />
    </div>
    <ul v-if="data.child">
      <tree-node :data="data.child" />
    </ul>
  </li>
</template>

<script>
  import FilterNode from '@/components/expression_viewer/FilterNode'
  import ArithmeticNode from '@/components/expression_viewer/ArithmeticNode'
  import ComparisonNode from '@/components/expression_viewer/ComparisonNode'
  import InlineExpression from '@/components/expression_viewer/InlineExpression'
  import ErrorNode from '@/components/expression_viewer/ErrorNode'

  export default {
    name: 'TreeNode',
    components: {
      FilterNode,
      ArithmeticNode,
      InlineExpression,
      ComparisonNode,
      ErrorNode,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    beforeCreate: function () {
      this.$options.components.treeNode =
        require('@/components/expression_viewer/TreeNode').default
    },
  }
</script>
