import { render, staticRenderFns } from "./ScorecardView.vue?vue&type=template&id=1d5de994&scoped=true"
import script from "./ScorecardView.vue?vue&type=script&lang=js"
export * from "./ScorecardView.vue?vue&type=script&lang=js"
import style0 from "./ScorecardView.vue?vue&type=style&index=0&id=1d5de994&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d5de994",
  null
  
)

export default component.exports