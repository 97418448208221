<template>
  <div
    class="control-bar flex-row"
    :class="{hasIcon: hasLogoIcon}">
    <template v-if="betaSpec.legacy_filters">
      <div
        class="filters"
        style="display:none">
        <dimension-filter-menu
          :read-only="readOnly"
          :filters.sync="filtersModel"
          :dimension-options="dimensionOptions.filter(dimension => dimension.filters.length > 0)" />
      </div>
    </template>
  </div>
</template>

<script>
  import DimensionFilterMenu from '@/components/controls/filter/DimensionFilterMenu'

  export default {
    name: 'ControlBar',
    components: {
      DimensionFilterMenu,
    },
    props: {
      readOnly: { type: Boolean, default: false, },
      filters: { type: Array, required: true, },
      dateFilters: { type: Array, default: null, },
      dimensionOptions: { type: Array, required: true, },
      availableDates: { type: Array, default: Array, },
      hasLogoIcon: { type: Boolean, default: false, },
      betaSpec: { type: Object, required: true, },
    },
    data () {
      return {
        isOpen: false,
      }
    },
    computed: {
      filtersModel: {
        get () {
          return this.filters
        },
        set (filters) {
          this.$emit('update:filters', filters)
        },
      },
    },
  }

</script>

<style lang="scss" scoped>

.control-bar {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  position: relative;
  padding: none;
}

.time-range-filter {
  flex-grow: 1;
}

.filters {
  flex-grow: 1;
}

</style>
