
<template>
  <div class="benchmark-config-editor">
    <b-field class="">
      <b-switch
        v-model="benchmarkEnabled"
        type="is-info">
        Configure this row relative to a benchmark
      </b-switch>
    </b-field>

    <div
      v-if="benchmarkEnabled"
      class="benchmark-inputs">
      <b-field
        class="input-field"
        label="Display this row's vaues as:">
        <b-select
          v-model="inputs.difference_type"
          placeholder=""
          expanded
          style="width: 100%">
          <option
            v-for="option in differenceTypeOptions"
            :key="option.key"
            :value="option.key">
            {{ option.label }}
          </option>
        </b-select>
      </b-field>

      <MeasureConfigEditor
        v-model="inputs.measure_config"
        :context="context" />

      <b-field class="">
        <b-switch
          v-model="conditionalFormatEnabled"
          type="is-info">
          Enable conditional formatting
        </b-switch>
      </b-field>
      <div
        v-if="inputs.conditional_format"
        class="sub-section mt-6">
        <h3>Conditional Formatting</h3>

        <b-field
          class="input-field"
          label="If the measure value is greater than the benchmark value, the displayed value’s color should be: ">
          <b-select
            v-model="inputs.conditional_format.is_greater_better"
            placeholder="Benchmark"
            expanded>
            <option
              v-for="option in isGreaterBetterOptions"
              :key="option.key"
              :value="option.key">
              {{ option.label }}
            </option>
          </b-select>
        </b-field>

        <h4 class="mt-5 mb-1">
          Greater-Than Threshold
        </h4>
        <b-field
          class="input-field"
          label="Apply conditional formatting when the difference or percent-change between the measure value and benchmark value is greater than:">
          <b-numberinput
            v-model="inputs.conditional_format.upper_threshold"
            controls-alignment="right"
            expanded />
        </b-field>

        <h4 class="mt-5 mb-1">
          Less-Than Threshold
        </h4>

        <b-field
          class="input-field"
          label="Apply conditional formatting when the difference or percent-change between the measure value and benchmark value is less than:">
          <b-numberinput
            v-model="inputs.conditional_format.lower_threshold"
            controls-alignment="right"
            expanded />
        </b-field>
      </div>
    </div>

    <!-- {{ inputs }} -->
  </div>
</template>

<script>

  import ModuleConfigMixin from '@/components/reports/config/modules/common/ModuleConfigMixin'
  import MeasureConfigEditor from '@/components/reports/config/modules/common/MeasureConfigEditor'

  export default {
    name: 'BenchmarkConfigEditor',
    components: {
      MeasureConfigEditor,
    },
    mixins: [
      ModuleConfigMixin,
    ],
    data () {
      return {

        defaultValue: null,

        compFilterTypeOptions: [
          {
            key: 'comp',
            label: 'Comparable stores only',
          },
          {
            key: 'non_comp',
            label: 'Non-comparable store only',
          },
        ],

        isGreaterBetterOptions: [
          {
            key: true,
            label: 'Green',
          },
          {
            key: false,
            label: 'Red',
          },
        ],

        differenceTypeOptions: [
          {
            key: 'none',
            label: 'Original measure with conditional formatting',
          },
          {
            key: 'diff',
            label: 'Difference to benchmark',
          },
          {
            key: 'var',
            label: 'Percentage change from benchmark',
          },
        ],
      }
    },
    computed: {
      benchmarkEnabled: {
        get () {
          return ![ null, undefined, ].includes(this.inputs)
        },
        set (benchmarkEnabled) {
          if (benchmarkEnabled) {
            this.inputs = {
              measure_config: {
                measure: this.context.parentMeasure,
                is_pct_total: false,
                pct_total_dimensions: [],
                comp_filter_type: 'none',
              },
              conditional_format: null,
              difference_type: 'none',
            }
          } else {
            this.inputs = null
          }
        },
      },
      conditionalFormatEnabled: {
        get () {
          return this.inputs.conditional_format !== null
        },
        set (enabled) {
          if (enabled) {
            this.inputs.conditional_format = {
              'is_greater_better': true,
              'lower_threshold': 0,
              'upper_threshold': 0,
            }
          } else {
            this.inputs.conditional_format = null
          }
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.benchmark-inputs {
  margin-top: 30px;
}
</style>
