<template>
  <div class="filter-editor">
    <TypeSelector
      v-model="vModel"
      object-type="Filter"
      v-bind="subObjectBinds"
      :type-options="filterTypes" />
  </div>
</template>

<script>

  import CompanyFilterRefEditor from '@/components/reports/config/filters/CompanyFilterRefEditor'
  import DimensionFilterEditor from '@/components/reports/config/filters/DimensionFilterEditor'
  import MeasureFilterEditor from '@/components/reports/config/filters/MeasureFilterEditor'
  import AndFilterEditor from '@/components/reports/config/filters/AndFilterEditor'
  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import TypeSelector from '@/components/reports/config/common/TypeSelector'

  export default {
    name: 'FilterEditor',
    components: {
      TypeSelector,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    data () {
      return {
        filterTypes: [
          {
            key: 'CompanyFilterRef',
            label: 'Company Filter',
            editor: CompanyFilterRefEditor,
          },
          {
            key: 'DimensionFilter',
            label: 'Dimension Filter',
            editor: DimensionFilterEditor,
          },
          {
            key: 'MeasureFilter',
            label: 'Measure Filter',
            editor: MeasureFilterEditor,
          },
          {
            key: 'AndFilter',
            default: () => ({
              type: 'AndFilter',
              filters: [],
            }),
            label: 'And Filter',
            editor: AndFilterEditor,
          },
        ],
      }
    },
  }

</script>

<style lang="scss" scoped>

.filter-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>
