<template>
  <div class="customer-cheque ">
    <div class="buttons">
      <button
        class="download-btn button"
        @click="print">
        <b-icon
          icon="download"
          custom-size="h-6"
          class="icon" /> Download pdf
      </button>
    </div>
    <div
      v-if="cheque"
      class="cheque-container">
      <div
        ref="chequeContent"
        class="cheque-content">
        <div class="restaurant flex-row-center-center">
          <img
            v-if="hasLogo"
            :src="logoUrl"
            width="50%"
            crossorigin="anonymous" />
          <template v-else>
            {{ cheque.restaurant }}
          </template>
          <div
            v-if="false"
            class="rev-center">
            {{ cheque.revenueCenter }}
          </div>
        </div>
        <div class="divider" />
        <div class="summary">
          <div class="summary-column">
            <div class="open-time">
              <div class="summary-item-label secondary-04-text">
                {{ weekDay }}
              </div>
              <div>{{ openDate }}</div>
            </div>
            <div class="server">
              <div class="summary-item-label secondary-04-text">
                Server
              </div>
              <div>{{ cheque.server }}</div>
            </div>
          </div>
          <div class="summary-column right-align">
            <div class="open-time">
              <div class="summary-item-label secondary-04-text">
                Opened at
              </div>
              <div>{{ openTime }}</div>
            </div>
            <div class="cheque-number">
              <div class="summary-item-label secondary-04-text">
                Check no.
              </div>
              <div>{{ cheque.number }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="allItems.length > 0"
          class="divider" />
        <div class="items">
          <div
            v-if="nonVoidItems.length > 0"
            class="section-title secondary-04-text">
            Items Ordered
          </div>
          <div
            v-for="(item, idx) in nonVoidItems"
            :key="idx"
            class="item">
            <div
              v-if="false"
              class="order-time">
              {{ formatTimeFromDateTime(item.orderTime) }}
            </div>
            <div class="item-name">
              {{ item.item }}
            </div>
            <div class="item-spacer" />
            <div class="item-quantity">
              {{ `x${item.quantity}` }}
            </div>
            <div class="item-amount">
              {{ formatAmount(item.amount) }}
            </div>
          </div>
        </div>
        <div
          v-if="false"
          class="items">
          <div
            v-if="voidItems.length > 0"
            class="section-title secondary-04-text">
            Voided items
          </div>
          <div
            v-for="(item, idx) in voidItems"
            :key="idx"
            class="item">
            <div class="order-time">
              {{ formatTimeFromDateTime(item.orderTime) }}
            </div>
            <div class="item-name">
              {{ item.item }}
            </div>
            <div class="item-spacer" />
            <div class="item-quantity">
              {{ `x${item.quantity}` }}
            </div>
            <div class="item-amount">
              {{ formatAmount(-item.amount) }}
            </div>
          </div>
        </div>
        <div class="totals">
          <div class="section-title secondary-04-text">
            Totals
          </div>
          <div class="subtotals-row">
            subtotal
            <div class="item-spacer" />
            <div class="item-amount">
              {{ formatAmount(cheque.subTotal) }}
            </div>
          </div>
          <div
            v-if="cheque.discounts != 0"
            class="subtotals-row">
            discounts
            <div class="item-spacer" />
            <div class="item-amount">
              {{ formatAmount(-cheque.discounts) }}
            </div>
          </div>
          <div class="subtotals-row">
            tax
            <div class="item-spacer" />
            <div class="item-amount">
              {{ formatAmount(cheque.tax) }}
            </div>
          </div>
          <div
            v-if="cheque.tip != 0"
            class="subtotals-row">
            tip
            <div class="item-spacer" />
            <div class="item-amount">
              {{ formatAmount(cheque.tip) }}
            </div>
          </div>
          <div class="subtotals-row">
            total
            <div class="item-spacer" />
            <div class="item-amount">
              {{ formatAmount(cheque.paid) }}
            </div>
          </div>
        </div>
        <div class="divider" />
        <div class="payments">
          <div class="section-title secondary-04-text">
            Payments Made
          </div>
          <div
            v-for="(payment, idx) in cheque.payments"
            :key="idx">
            <div class="payment">
              <div class="payment-method-name">
                {{ payment.method }}
                <template v-if="payment.last4 && payment.last4 != ''">
                  *{{ payment.last4 }}
                </template>
              </div>
              <div class="payment-spacer" />
              <div class="payment-amount">
                {{ formatAmount(payment.amount) }}
              </div>
            </div>
            <div
              v-if="!!payment.comment"
              :key="`comment${idx}`">
              <div class="payment-comment">
                {{ payment.comment }}
              </div>
            </div>
          </div>
        </div>
        <div class="divider" />
        <div class="blurb secondary-04-text">
          Thank you for dining with us at {{ cheque.restaurant }}.
          <template v-if="!!cheque.contact_phone && !!cheque.contact_email">
            If you have any questions about this check, please reach out to
            <nobr>{{ cheque.contact_phone }}</nobr> or email
            {{ cheque.contact_email }}
          </template>
          <template v-if="!!cheque.contact_phone && !cheque.contact_email">
            If you have any questions about this check, please reach out to
            <nobr>{{ cheque.contact_phone }}</nobr>
          </template>
          <template v-if="!cheque.contact_phone && !!cheque.contact_email">
            If you have any questions about this check, please email
            {{ cheque.contact_email }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getWeekDay,
    formatDate,
    formatTimeFromDateTime
  } from '@/utils/Date'
  import { formatAmount } from '@/utils/Format'
  import html2canvas from 'html2canvas'
  import jsPDF from 'jspdf'

  export default {
    name: 'CustomerCheque',
    components: {},
    props: {
      cheque: { type: Object, required: true, },
    },
    data () {
      return {
        saving: false,
        viewType: 'customer',
      }
    },
    computed: {
      hasLogo () {
        return !!this.cheque.logo_fname
      },
      logoUrl () {
        return `${process.env.VUE_APP_S3_BASE_URL}/${this.cheque.s3_prefix}/${this.cheque.logo_fname}`
      },
      allItems () {
        return this.cheque.items.filter((item) => item.amount != 0)
      },
      nonVoidItems () {
        return this.cheque.items.filter(
          (item) => !item.isVoid && item.amount != 0
        )
      },
      voidItems () {
        return this.cheque.items.filter(
          (item) => item.isVoid && item.amount != 0
        )
      },
      weekDay () {
        return getWeekDay(this.cheque.openTime)
      },
      openDate () {
        return formatDate(this.cheque.openTime)
      },
      openTime () {
        return formatTimeFromDateTime(this.cheque.openTime)
      },
    },

    methods: {
      formatAmount,
      formatTimeFromDateTime,
      async print () {
        if (this.saving) {
          return
        }

        this.saving = true
        const filename = `check_${this.$route.params.cheque_id}.pdf`
        let clientRect = this.$refs.chequeContent.getBoundingClientRect()
        let canvas = await html2canvas(this.$refs.chequeContent, {
          useCORS: true,
          scale: 2,
        })

        let pdf = new jsPDF('p', 'px', [clientRect.width, clientRect.height,])

        var width = pdf.internal.pageSize.getWidth()
        var height = pdf.internal.pageSize.getHeight()
        pdf.addImage(
          canvas.toDataURL('image/jpg'),
          'JPG',
          0,
          0,
          width + 2,
          height + 2
        )
        pdf.save(filename)
        this.saving = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  width: 100%;

  .download-btn {
    font-size: 15px;
    margin-left: auto;
    .icon {
      margin-right: 10px !important;
    }
  }
}

.divider {
  margin: 8px 0;
  height: 0px;
  border-bottom: $border-1;
}
.cheque-container {
  padding: 16px;
  background-color: #f5f5f5;
  border: $border-1;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(40, 39, 43, 0.04) inset,
    0px 0px 2px 0px rgba(40, 39, 43, 0.08) inset;

  .cheque-content {
    background-color: white;
    padding: 15px;
    border-radius: 4px;
  }
}
.section-title {
  text-align: center;
  font-size: 18px;
  padding: 8px 16px;
}
.restaurant {
  margin: 0 24px;
  font-size: 24px;
}
.server,
.open-time,
.close-time,
.cheque-number {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px;
}
.server,
.restaurant {
  text-transform: capitalize;
}
.item,
.payment {
  display: flex;
}
.item-spacer,
.payment-spacer {
  flex-grow: 1;
  border-bottom: 1px dashed $darkGreen;
  margin: 8px 4px;
}
.item-amount,
.payment-amount {
  padding-left: 24px;
  min-width: 88px;
  text-align: right;
}
.buttons {
  justify-content: flex-end;
  display: flex;
}
.save {
  position: relative;
}
.view-type-select {
  justify-content: flex-start;
  display: flex;
  flex-grow: 1;
}
.summary {
  display: flex;
  justify-content: space-between;
}
.summary-column {
  display: flex;
  flex-direction: column;
}
.right-align {
  text-align: right;
}
.rev-center {
  font-size: 16px;
}
.order-time {
  width: 64px;
  margin-right: 16px;
  white-space: nowrap;
}
.subtotals-row {
  margin-left: 128px;
  display: flex;
}
.blurb {
  padding: 4 * $gridBase;
  text-align: center;
}
.payment-comment {
  padding-left: 8 * $gridBase;
}
</style>
