<template>
  <div class="graph-module">
    <div class="graph-header">
      {{ dashboardModule.name || 'Untitled' }}
    </div>
    <div class="graph-body">
      <div
        class="graph-container">
        <BarChart
          v-if="type === 'bar'"
          :data="filteredBarChartData" />
        <LineChart
          v-if="type === 'line'"
          :data="filteredLineChartData" />
      </div>
      <div class="graph-controls">
        <div class="search-container">
          <b-field>
            <b-input
              v-model="searchQuery"
              type="search"
              icon="magnify"
              placeholder="Search"
              expanded
              class="no-border-input" />
          </b-field>
        </div>
        <div class="toggle-container">
          <span class="toggle-label">Show:</span>
          <div class="toggle-buttons">
            <button
              class="button is-small toggle-button"
              @click="showAll">
              All
            </button>
            <button
              class=" button is-small toggle-button"
              @click="hideAll">
              None
            </button>
          </div>
        </div>
        <div class="filter-container">
          <template v-if="type === 'bar'">
            <div
              v-for="(bar) in filteredBarItems"
              :key="bar.id"
              class="filter-item"
              :class="{ 'filter-item-active': bar.isActive }"
              @click="toggleBarItem(bar.id)">
              <div class="filter-item-content">
                <span
                  class="color-circle"
                  :style="{ backgroundColor: bar.color }" />
                <span class="filter-label">{{ bar.label }}</span>
              </div>
              <i class="material-icons visibility-icon">
                {{ bar.isActive ? "" : "visibility_off" }}
              </i>
              <span class="filter-value">{{ bar.values }}</span>
            </div>
          </template>
          <template v-else-if="type === 'line'">
            <div
              v-for="(line) in filteredLineItems"
              :key="line.id"
              class="filter-item"
              :class="{ 'filter-item-active': line.isActive }"
              @click="toggleLineItem(line.id)">
              <div class="filter-item-content">
                <span
                  class="color-circle"
                  :style="{ backgroundColor: line.color }" />
                <span class="filter-label">{{ line.label }}</span>
              </div>

              <i class="material-icons visibility-icon">
                {{ line.isActive ? "" : "visibility_off" }}
              </i>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BarChart from './BarChart.vue'
  import LineChart from './LineChart.vue'
  import FuzzySearch from 'fuzzy-search'
  import { reactive } from 'vue'

  const colors = {
    red: '#FF6384',
    blue: '#36A2EB',
    yellow: '#FFCE56',
    green: '#4BC0C0',
    purple: '#9966FF',
    orange: '#FF9F40',
    pink: '#FF66B2',
    teal: '#4DCCB0',
    brown: '#8B4513',
    gray: '#A9A9A9',
  }

  export default {
    name: 'GraphModule',
    components: { BarChart, LineChart, },
    props: {
      type: {
        type: String,
        required: true,
      },
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        searchQuery: '',
        barChartResponse: reactive({
          bars: [],
        }),
        lineChartResponse: reactive({
          lines: [],
        }),
        barSearcher: null,
        lineSearcher: null,
        isLoading: true,
      }
    },
    computed: {
      barChartData () {
        return {
          bars: this.barChartResponse.bars.map((bar, index) => ({
            ...bar,
            color: Object.values(colors)[index % Object.keys(colors).length],
            isActive: bar.isActive !== undefined ? bar.isActive : true,
          })),
        }
      },
      lineChartData () {
        return {
          lines: this.lineChartResponse.lines.map((line, index) => ({
            ...line,
            color: Object.values(colors)[index % Object.keys(colors).length],
            isActive: line.isActive !== undefined ? line.isActive : true,
          })),
        }
      },

      filteredBarChartData () {
        return {
          bars: this.barChartData.bars.filter((bar) => bar.isActive),
        }
      },
      filteredLineChartData () {
        return {
          lines: this.lineChartData.lines.filter((line) => line.isActive),
        }
      },
      filteredBarItems () {
        if (!this.searchQuery) {
          return this.barChartData.bars
        }
        return this.barSearcher.search(this.searchQuery)
      },
      filteredLineItems () {
        if (!this.searchQuery) {
          return this.lineChartData.lines
        }
        return this.lineSearcher.search(this.searchQuery)
      },
    },

    watch: {
      barChartResponse: {
        handler () {
          this.updateBarSearcher()
        },
        deep: true,
      },
      lineChartResponse: {
        handler () {
          this.updateLineSearcher()
        },
        deep: true,
      },
      dashboardDataContext: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },

    mounted () {
      this.loadData()
    },
    methods: {
      async loadData () {
        this.isLoading = true
        this.data = await this.$apis.companyConfigs.getDashboardModuleData(
          this.dashboardKey,
          this.dashboardModule.key,
          this.dashboardDataContext
        )

        if (this.type === 'bar') {
          this.barChartResponse.bars = this.data.bars.map(bar => ({ ...bar, isActive: true, }))
        } else if (this.type === 'line') {
          this.lineChartResponse.lines = this.data.lines.map(line => ({ ...line, isActive: true, }))
        }
        this.isLoading = false
        this.updateBarSearcher()
        this.updateLineSearcher()
      },
      toggleBarItem (id) {
        const index = this.barChartResponse.bars.findIndex(bar => bar.id === id)
        if (index !== -1) {
          this.barChartResponse.bars[index].isActive = !this.barChartResponse.bars[index].isActive
        }
      },
      toggleLineItem (id) {
        const index = this.lineChartResponse.lines.findIndex(line => line.id === id)
        if (index !== -1) {
          this.lineChartResponse.lines[index].isActive = !this.lineChartResponse.lines[index].isActive
        }
      },
      showAll () {
        if (this.type === 'bar') {
          this.barChartResponse.bars.forEach((bar) => bar.isActive = true)
        } else if (this.type === 'line') {
          this.lineChartResponse.lines.forEach((line) => line.isActive = true)
        }
      },
      hideAll () {
        if (this.type === 'bar') {
          this.barChartResponse.bars.forEach((bar) => bar.isActive = false)
        } else if (this.type === 'line') {
          this.lineChartResponse.lines.forEach((line) => line.isActive = false)
        }
      },
      updateBarSearcher () {
        this.barSearcher = new FuzzySearch(this.barChartData.bars, ['label',], {
          caseSensitive: false,
        })
      },
      updateLineSearcher () {
        this.lineSearcher = new FuzzySearch(this.lineChartData.lines, ['label',], {
          caseSensitive: false,
        })
      },
    },
  }
</script>

<style scoped lang="scss">
.graph-module {
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  flex-direction: column;
  background-color: white;

}
.graph-header {
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  color: #333;
  padding: 8px;
}
.graph-body {
  display: flex;
  flex-direction: row;
}
.graph-container {
  height: 100%;
  max-height: 500px;
  width: calc(100% - 360px);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.graph-controls {
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e0e0e0;
  background-color: white;
}

.search-container {
  padding: 4px;
  border-bottom: 1px solid #e0e0e0;
}

.no-border-input {
  ::v-deep .input {
    border: none;
    box-shadow: none;
  }
}

.filter-container {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 500px;
  overflow-y: auto;
}

.filter-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 12px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  &.filter-item-active {
  }
}

.filter-item-content {
  display: flex;
  align-items: center;
}

.color-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.filter-label {
  font-size: 14px;
}

.filter-value {
  color: #666;
  font-size: 14px;
}

.toggle-container {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  border-bottom: 1px solid #e0e0e0;
}

.toggle-label {
  font-size: 14px;
  color: #999;
  margin-right: 8px;

}

.toggle-buttons {
  display: flex;
  gap: 8px;
  border:none;
  margin-left: 12px;
}

.toggle-button {
  font-size: 12px;
  padding: 0px 8px;
  background-color: #fff;
  color: #888 !important;
  transition: all 0.3s ease;
  border: none !important;

  &:hover {
    background-color: #f0f0f0;
  }
}

.visibility-icon {
  margin-left: auto;
  margin-right: 8px;
  color: #666;
  font-size: 16px;
  width: 16px;
  height: 16px;
  opacity: 0.5;
}
</style>
