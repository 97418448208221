<template>
  <canvas
    ref="lineChart"
    class="line-chart-container" />
</template>

<script>
  import { Chart, registerables } from 'chart.js'
  Chart.register(...registerables)

  export default {
    name: 'LineChart',
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        chartInstance: null,
      }
    },
    computed: {
      chartData () {
        const labels = this.data.lines[0]?.data.map((_, index) => `${index + 1}`) || []
        const datasets = this.data.lines.map(line => ({
          label: line.label,
          data: line.data,
          borderColor: line.color,
          backgroundColor: line.color + '33', // Adding 33 for 20% opacity
          tension: 0.4, // Increased tension for wavy lines
          cubicInterpolationMode: 'monotone', // Adds smoothness to the curve
        }))
        return { labels, datasets, }
      },
    },
    watch: {
      data: {
        handler () {
          this.updateChart()
        },
        deep: true,
      },
    },
    mounted () {
      this.createChart()
    },
    beforeUnmount () {
      if (this.chartInstance) {
        this.chartInstance.destroy()
      }
    },
    methods: {
      createChart () {
        const ctx = this.$refs.lineChart.getContext('2d')
        this.chartInstance = new Chart(ctx, {
          type: 'line',
          data: this.chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            elements: {
              line: {
                tension: 0.4, // Increased tension for wavy lines
                cubicInterpolationMode: 'monotone', // Adds smoothness to the curve
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: function (tooltipItems) {
                    return tooltipItems[0].label
                  },
                  label: function (context) {
                    let label = context.dataset.label || ''
                    if (label) {
                      label += ': '
                    }
                    if (context.parsed.y !== null) {
                      label += context.parsed.y
                    }
                    return label
                  },
                },
              },
            },
          },
        })
      },
      updateChart () {
        if (this.chartInstance) {
          this.chartInstance.data = this.chartData
          this.chartInstance.update()
        }
      },
    },
  }
</script>

<style scoped>
.line-chart-container {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
