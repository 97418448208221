<template>
  <div class="header-set">
    <template v-for="(header, headIdx) of headerSet">
      <column-description
        :key="`description-${headIdx}`"
        :column="header" />
      <div
        v-if="headIdx < headerSet.length - 1"
        :key="`or-${headIdx}`"
        class="or">
        OR
      </div>
    </template>
  </div>
</template>

<script>

  import ColumnDescription from '@/components/forecasts/ColumnDescription'

  export default {
    name: 'HeaderSet',
    components: {
      ColumnDescription,
    },
    props: {
      headerSet: { type: Array, required: true, },
    },
    data () {
      return {
      }
    },
  }

</script>

<style lang="scss" scoped>

.header-set {
  margin: 0 1.5 * $gridBase;
  display: flex;
  align-items: stretch;
  padding: 4 * $gridBase;
  overflow: auto;
  max-height: 100%;
  border: $border-1;
  background-color: white;
  border-radius: 5px;

   &.required {
    border-color: $primary
   }

}

.or {
  color: $ui-05;
  font: $h3-lw-sl;
  margin: $gridBase 8 * $gridBase;
}

</style>

