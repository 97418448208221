<template>
  <div class="container">
    <div class="error-code">
      <h1>Error 404</h1>
    </div>
    <p>Page Not Found</p>
    <router-link
      :to="homeRoute"
      class="button is-light redirect-button">
      Take Me Home
    </router-link>
    <hr />
  </div>
</template>

<script>
  export default {
    computed: {
      homeRoute () {
        return this.$store.getters['auth/user/defaultRoute']
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .error-code {
    h1 {
      margin-top: 100px;
      font-size: 50px;
    }
  }

  .redirect-button {
    margin-top: 20px;
    margin-bottom: 150px;
  }
  .spaghetti {
    width: 500px;
    height: auto;
  }
  p {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
  }
}
</style>
