var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',{staticClass:"x-axis"},[_c('path',{staticClass:"axis-line",attrs:{"d":`M0,${_vm.chartHeight} L${_vm.chartWidth},${_vm.chartHeight}`}}),_vm._l((_vm.ticks),function(datum,idx){return _c('foreignObject',{key:`xTick-${idx}`,attrs:{"x":_vm.xScale(_vm.getDimensionVal(_vm.primaryDimensionKeys, datum)) +
        _vm.xScale.bandwidth() / 2 -
        96,"y":_vm.chartHeight + 8,"transform":`rotate(-45 ${
      _vm.xScale(_vm.getDimensionVal(_vm.primaryDimensionKeys, datum)) +
      _vm.xScale.bandwidth() / 2
    } ${_vm.chartHeight + 16})`,"width":"96","height":"24"}},[_c('p',{staticClass:"tick-label",class:{
        highlighted:
          _vm.highlightedData.filter(
            (d) =>
              _vm.getDimensionVal(_vm.primaryDimensionKeys, d) ===
              _vm.getDimensionVal(_vm.primaryDimensionKeys, datum)
          ).length > 0,
        clickable: _vm.hasClickableDimensions,
      },on:{"click":function($event){return _vm.$emit('click', datum)}}},[_vm._v(" "+_vm._s(_vm.getDimensionVal(_vm.primaryDimensions, datum))+" ")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }