import { render, staticRenderFns } from "./ReportsConfig.vue?vue&type=template&id=1375db42&scoped=true"
import script from "./ReportsConfig.vue?vue&type=script&lang=js"
export * from "./ReportsConfig.vue?vue&type=script&lang=js"
import style0 from "./ReportsConfig.vue?vue&type=style&index=0&id=1375db42&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1375db42",
  null
  
)

export default component.exports