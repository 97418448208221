<template>
  <div class="measure-config-options">

    <b-field
      class="input-field"
      label="Offset calculation by (days):">
      <b-numberinput v-model="inputs.offset_days" />
    </b-field>

    <b-field
      class="input-field"
      label="Measure timeframe(optional)">
      <TimeRangeEditor v-model="inputs.time_range" />
    </b-field>

    <RepeatConfigEditor v-model="inputs.repeat_config" />

    <!-- <MeasureTimeRange v></MeasureTimeRange> -->
    <FiltersTab v-model="inputs.filters" />
    <hr />

    <b-field class="input-field">
      <b-switch
        v-model="inputs.is_pct_total"
        class="mt-4"
        type="is-info"
        @input="changePctTotalToggle">
        Calculate this measure as a percentage of a total value
      </b-switch>
    </b-field>

    <div v-if="inputs.is_pct_total">
      <p>Limit Total to</p>
      <b-field
        v-for="(option, i) in pctTotalDimensionOptions"
        :key="i">
        <b-checkbox
          v-model="pctTotalsDimensionsSelections[i]"
          @input="computePctTotals">
          <DimensionEditor :value="option"
                           mode="summary" />
        </b-checkbox>
      </b-field>
    </div>

    <hr />
    <b-switch
      v-model="enableAverage"
      class="mt-5 mb-4"
      type="is-info">
      Calculate this measure as an average.
    </b-switch>
    <b-field class="input-field" v-if="enableAverage">
      Average by:
      <DimensionEditor v-model="inputs.avg_by_dimension" />
    </b-field>

    <hr />
    <b-switch
      v-model="toggleCompFilterType"
      class="mt-5 mb-4"
      type="is-info">
      Filter this measure for comparable stores based on their date of opening
    </b-switch>
    <b-field
      v-if="toggleCompFilterType"
      class="input-field"
      label="Filter this measure for:">
      <b-select
        v-model="inputs.comp_filter_type"
        placeholder=""
        expanded>
        <option
          v-for="option in compFilterTypeOptions"
          :key="option.key"
          :value="option.key">
          {{ option.label }}
        </option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
  import FiltersTab from '@/components/pdf_report/table_module/table_rows/FiltersTab'
  import ModuleConfigMixin from '@/components/reports/config/modules/common/ModuleConfigMixin'
  import RepeatConfigEditor from '@/components/reports/config/modules/common/RepeatConfigEditor'
  import TimeRangeEditor from '@/components/reports/config/modules/common/TimeRangeEditor'
  import DimensionEditor from '@/components/reports/config/dimensions/DimensionEditor'

  export default {
    name: 'MeasureConfigOptions',
    mixins: [
      ModuleConfigMixin,
    ],
    components: {
      FiltersTab,
      DimensionEditor,
      TimeRangeEditor,
      RepeatConfigEditor,
    },
    data () {
      return {
        compFilterTypeOptions: [
          {
            key: 'comp',
            label: 'Comparable stores only',
          },
          {
            key: 'non_comp',
            label: 'Non-comparable store only',
          },
        ],
        pctTotalsDimensionsSelections: {},
      }
    },
    computed: {
      enableAverage: {
        get () {
          return ![ null, undefined, ].includes(this.inputs.avg_by_dimension)
        },
        set (enableAverage) {
          if (enableAverage) {
            this.inputs.avg_by_dimension = {}
          }
          else {
            this.inputs.avg_by_dimension = undefined
          }
        },
      },
      companyDimensionOptions () {
        return this.$store.getters['app/reportsConfig/companyDimensionOptions']
      },
      pctTotalDimensionOptions () {
        return this.context.reportDimensions
      },
      toggleCompFilterType: {
        get () {
          return [
            'comp',
            'non_comp',
          ].includes(
            this.inputs.comp_filter_type
          )
        },
        set (toggle) {
          if (toggle) {
            this.inputs.comp_filter_type = 'comp'
          }
          else {
            this.inputs.comp_filter_type = 'none'
          }
        },
      },
    },
    mounted () {
      this.pctTotalsDimensionsSelections = {}
      this.inputs.pct_total_dimensions?.forEach((item, index) => {
        if (
          this.pctTotalDimensionOptions[index] &&
          this.pctTotalDimensionOptions[index].key === item.key
        ) {
          this.pctTotalsDimensionsSelections[index] = true
        }
      })
    },

    beforeDestroy () {},

    methods: {
      computePctTotals () {
        let pctTotalDims = []

        if (this.pctTotalsDimensionsSelections[0]) {
          pctTotalDims.push(this.pctTotalDimensionOptions[0])
        }

        if (this.pctTotalsDimensionsSelections[1]) {
          pctTotalDims.push(this.pctTotalDimensionOptions[1])
        }

        this.inputs.pct_total_dimensions = pctTotalDims
      },

      changePctTotalToggle () {
        this.inputs.pct_total_dimensions = []

        this.pctTotalsDimensionsSelections = {}
      },
    },
  }
</script>

<style lang="scss" scoped>
.benchmark-inputs {
  margin-top: 30px;
}
</style>
