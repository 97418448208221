<template>
  <div class="company-measure-editor">
    <CompanyObjectEditor
      v-model="vModel"
      object-type="Measure">
      <template #header-actions>
        <b-button
          type="button ml-2"
          class="button is-light"
          :disabled="loadingPreview"
          @click="previewValue">
          Preview Value
        </b-button>
        <b-button
          type="button ml-2"
          class="button is-light"
          :disabled="loadingDimensions"
          @click="showDimensions">
          Show Dimensions
        </b-button>
        <b-button
          type="button ml-2"
          class="button is-light"
          :disabled="loadingJoins"
          @click="explainJoins">
          Explain Joins
        </b-button>
        <slot name="header-actions" />
      </template>
      <template #object-editor>
        <MeasureJoinTree
          v-if="joinTree"
          :node="joinTree" />
        <MeasureEditor
          v-model="measure"
          v-bind="subObjectBinds" />
      </template>
    </CompanyObjectEditor>
  </div>
</template>

<script>
  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyObjectEditor from '@/components/reports/config/common/CompanyObjectEditor'
  import MeasureJoinTree from '@/components/reports/config/measures/MeasureJoinTree'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'CompanyMeasureEditor',
    components: {
      CompanyObjectEditor,
      MeasureJoinTree,
    },
    mixins: [ObjectEditorMixin,],
    data () {
      return {
        loadingPreview: false,
        loadingDimensions: false,
        loadingJoins: false,
        joinTree: null,
      }
    },
    computed: {
      ...mapChildren({
        measure: 'measure',
      }),
    },
    methods: {
      async previewValue () {
        this.loadingPreview = true
        try {
          const res = await this.$apis.companyConfigs.previewMeasureValue(
            this.measure
          )
          window.alert(res)
        } finally {
          this.loadingPreview = false
        }
      },
      async showDimensions () {
        this.loadingDimensions = true
        try {
          const res = await this.$apis.companyConfigs.getMeasureDimensions(
            this.measure
          )
          window.alert(res.map((r) => r.name))
        } finally {
          this.loadingDimensions = false
        }
      },
      async explainJoins () {
        this.loadingJoins = true
        try {
          const res = await this.$apis.companyConfigs.getMeasureJoinTree(
            this.measure
          )
          this.joinTree = res
        } finally {
          this.loadingJoins = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.company-measure-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
