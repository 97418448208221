import { store } from '@/vuex/store'

export function getAuthToken () {
  return store.getters['auth/authToken']
}

export async function authedMiddleware (to, from, next) {
  //Todo: write a better Auth and User plugin
  store.commit('setLoading', true)
  try {
    let companyId = to.params.company_id
    if (companyId) {
      await store.dispatch('initialize', companyId)
      await store.dispatch('updateCompanyId', companyId)
    }
  } catch (e) {
    console.log('Company Init Error')
  }
  store.commit('setLoading', false)
  if (!store.getters['auth/isLoggedIn']) {
    next({
      name: 'login',
      query: { redirect: to.fullPath, },
    })
  } else {
    next()
  }
}

export function redirectToDefaultRoute (to, from, next) {
  next(store.getters['auth/user/defaultRoute'])
}
