import { debounce } from 'lodash'

import { wrapFilters } from '@/models/reporting/expressionHelpers'
import { extractDate, parseDate } from '@/utils/Date.js'

export const controlsModel = {
  namespaced: true,
  state () {
    return {
      loadingControls: {},
      controlSelections: {},
      debouncedSelections: {},
      controlSpecs: {},
    }
  },
  getters: {
    offsetDates (state, getters, rootState, rootGetters) {
      let results = {}
      Object.keys(getters.controlSelections).forEach((control_id) => {
        if (
          !!getters.controlSelections[control_id] &&
          ![null, undefined,].includes(
            getters.controlSelections[control_id] || {}
          ).offset_duration
        ) {
          let offsetDates = rootGetters['datePicker/appliedEffectiveDates'].map(
            (date) =>
              extractDate(
                new Date(
                  parseDate(date.date).setDate(
                    parseDate(date.date).getDate() -
                      ((getters.controlSelections[control_id] || {})
                        .offset_duration || 0)
                  )
                )
              )
          )
          results[control_id] = rootGetters['datePicker/availableDates']
            .filter((d) => offsetDates.includes(d.date))
            .map((d) => d.date_key)
        }
      })
      return results
    },
    controlSelections (state) {
      return state.controlSelections
    },
    debouncedSelections (state) {
      return state.debouncedSelections
    },
    controlSpecs (state) {
      return state.controlSpecs
    },
    loadingControls (state) {
      return state.loadingControls
    },
    availableExpressions (state, getters, rootState, rootGetters) {
      return {
        control_offset_date: {
          args: {
            control_id: 'string',
          },
          getExpression: (args) => {
            return {
              type: 'add_days',
              left: {
                type: 'field',
                field: { key: 'date', },
              },
              right: {
                type: 'const',
                data_type: { key: 'number', },
                value:
                  (getters.debouncedSelections[args.control_id] || {})
                    .offset_duration || 0,
              },
            }
          },
        },
        inject_dimensions_expressions: {
          args: {
            expression: 'expression',
            dimensions: 'expression_list',
          },
          getExpression: (args, preprocessExpression) => {
            return preprocessExpression(
              args.expression,
              args.dimensions.map((dimension) =>
                preprocessExpression(dimension)
              )
            )
          },
        },
        uninjectable_expression: {
          args: {
            expression: 'expression',
          },
          getExpression: (args, preprocessExpression) => {
            return preprocessExpression(args.expression, [])
          },
        },
        option_select_expression: {
          args: {
            control_id: 'string',
          },
          getExpression: (args) => {
            let selection = getters.debouncedSelections[args.control_id]
            if (!!selection && !!selection.expression) {
              return getters.debouncedSelections[args.control_id].expression
            }
            return null
          },
        },
        general_control_expression: {
          args: {
            control_id: 'string',
            field_name: 'string',
          },
          getExpression: (args) => {
            let selection = getters.debouncedSelections[args.control_id]
            if (!!selection && !!selection.expression) {
              return getters.debouncedSelections[args.control_id][
                args.field_name
              ]
            }
            return null
          },
        },
        controlled_variance: {
          args: {
            control_id: 'string',
            primary_expression: 'expression',
            secondary_expression: 'expression',
          },
          getExpression: (args) => {
            let selection = getters.debouncedSelections[args.control_id]
            if (!!selection && !!selection.variance_type) {
              if (selection.variance_type == 'diff') {
                return {
                  'type': 'sub',
                  'left': args.primary_expression,
                  'right': args.secondary_expression,
                }
              }
              if (selection.variance_type == 'var') {
                return {
                  'type': 'cast',
                  'data_type': {
                    'key': 'percent',
                  },
                  'expression': {
                    'type': 'div',
                    'left': {
                      'type': 'sub',
                      'left': args.primary_expression,
                      'right': args.secondary_expression,
                    },
                    'right': args.secondary_expression,
                  },
                }
              }
            }
            return null
          },
        },
        controlled_offset_dates: {
          args: {
            control_id: 'string',
          },
          getExpression: (args) => ({
            type: 'const',
            data_type: { key: 'list', item_type: { key: 'date', }, },
            value: getters.offsetDates[args.control_id],
          }),
        },
        controlled_offset_n_days_filter: {
          args: {
            expression: 'expression',
            control_id: 'string',
            duration: 'string',
          },
          getExpression: (args) => {
            return !rootGetters['datePicker/lastDay']
              ? null
              : wrapFilters(args.expression, [
                  {
                    type: 'is_less_or_equal',
                    left: {
                      type: 'field',
                      field: {
                        key: 'date',
                      },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'date', },
                      value: extractDate(
                        new Date(
                          parseDate(
                            rootGetters['datePicker/lastDay'].date
                          ).setDate(
                            parseDate(
                              rootGetters['datePicker/lastDay'].date
                            ).getDate() -
                              (
                                getters.debouncedSelections[args.control_id] ||
                                {}
                              ).offset_duration || 0
                          )
                        )
                      ),
                    },
                  },

                  {
                    type: 'is_more_or_equal',
                    left: {
                      type: 'field',
                      field: {
                        key: 'date',
                      },
                    },
                    right: {
                      type: 'const',
                      data_type: { key: 'date', },
                      value: extractDate(
                        new Date(
                          parseDate(
                            rootGetters['datePicker/lastDay'].date
                          ).setDate(
                            parseDate(
                              rootGetters['datePicker/lastDay'].date
                            ).getDate() -
                              (args.duration -
                                1 +
                                ((
                                  getters.debouncedSelections[
                                    args.control_id
                                  ] || {}
                                ).offset_duration || 0))
                          )
                        )
                      ),
                    },
                  },
                ])
          },
        },
        controlled_offset_filter: {
          args: {
            expression: 'expression',
            control_id: 'string',
          },
          getExpression: (args) => {
            return wrapFilters(args.expression, [
              {
                type: 'in',
                left: {
                  type: 'field',
                  field: {
                    key: 'date',
                  },
                },
                right: {
                  type: 'const',
                  data_type: { key: 'list', item_type: { key: 'date', }, },
                  value: getters.offsetDates[args.control_id],
                },
              },
            ])
          },
        },
        static_offset_filter: {
          args: {
            expression: 'expression',
            offset: 'string',
          },
          getExpression: (args) => {
            let offsetDates = rootGetters[
              'datePicker/appliedEffectiveDates'
            ].map((date) =>
              extractDate(
                new Date(
                  parseDate(date.date).setDate(
                    parseDate(date.date).getDate() - (args.offset || 0)
                  )
                )
              )
            )
            rootGetters['datePicker/availableDates']
              .filter((d) => offsetDates.includes(d.date))
              .map((d) => d.date_key)
            return wrapFilters(args.expression, [
              {
                type: 'in',
                left: {
                  type: 'field',
                  field: {
                    key: 'date',
                  },
                },
                right: {
                  type: 'const',
                  data_type: { key: 'list', item_type: { key: 'date', }, },
                  value: rootGetters['datePicker/availableDates']
                    .filter((d) => offsetDates.includes(d.date))
                    .map((d) => d.date_key),
                },
              },
            ])
          },
        },
        all_dimension_select_filters: {
          args: {
            expression: 'expression',
            excluded_control_ids: 'list',
          },
          getExpression: (args) =>
            wrapFilters(
              args.expression,
              [].concat(
                Object.values(getters.controlSpecs)
                  .filter((c) => c.type === 'dimension-select')
                  .filter((c) => !args.excluded_control_ids.includes(c.id))
                  .map((c) => ({
                    type: 'in',
                    left: c.spec.default_dimension_expression,
                    right: {
                      type: 'const',
                      data_type: { key: 'list', item_type: { key: 'id', }, },
                      value: (getters.debouncedSelections[c.id] || []).map(
                        (i) => i.key
                      ),
                    },
                  })),
                Object.values(getters.controlSpecs)
                  .filter((c) => c.type === 'v2-dimension-select')
                  .filter((c) => !args.excluded_control_ids.includes(c.id))
                  .map((c) => ({
                    type: 'in',
                    left: c.spec.default_dimension_expression,
                    right: {
                      type: 'const',
                      data_type: { key: 'list', item_type: { key: 'id', }, },
                      value: (getters.debouncedSelections[c.id] || []).map(
                        (i) => i.key
                      ),
                    },
                  }))
              )
            ),
        },
        dimension_select_control_filter: {
          args: {
            expression: 'expression',
            control_id: 'string',
            dimension_expression: 'expression',
          },
          getExpression: (args) =>
            wrapFilters(args.expression, [
              {
                type: 'in',
                left: args.dimension_expression,
                right: {
                  type: 'const',
                  data_type: { key: 'list', item_type: { key: 'id', }, },
                  value: (
                    getters.debouncedSelections[args.control_id] || []
                  ).map((i) => i.key),
                },
              },
            ]),
        },
        dimension_exclude_control_filter: {
          args: {
            expression: 'expression',
            control_id: 'id',
            dimension_expression: 'expression',
          },
          getExpression: (args) =>
            wrapFilters(args.expression, [
              {
                type: 'not',
                expression: {
                  type: 'in',
                  left: args.dimension_expression,
                  right: {
                    type: 'const',
                    data_type: { key: 'list', item_type: { key: 'id', }, },
                    value: (
                      getters.debouncedSelections[args.control_id] || []
                    ).map((i) => i.key),
                  },
                },
              },
            ]),
        },
      }
    },
  },
  mutations: {
    setDebouncedSelections: debounce((state, selections) => {
      state.debouncedSelections = selections
    }, 1000),
    setSelections (state, selections) {
      state.controlSelections = selections
    },
    setSpecs (state, specs) {
      state.controlSpecs = specs
    },
    setLoadingControls (state, loading) {
      state.loadingControls = loading
    },
  },
  actions: {
    setSelections (context, selections) {
      context.commit('setSelections', selections)
      context.commit('setDebouncedSelections', selections)
    },
    startLoading (context, control_id) {
      let newLoading = { ...context.getters.loadingControls, }
      newLoading[control_id] = true
      context.commit('setLoadingControls', newLoading)
    },
    endLoading (context, control_id) {
      let newLoading = { ...context.getters.loadingControls, }
      newLoading[control_id] = false
      context.commit('setLoadingControls', newLoading)
    },
  },
  modules: {},
}
