<template>
  <grid
    class="dashboard-grid"
    :style="{ height: dashboardHeight }">
    <resize-sensor @resize="onGridResize" />
    <grid-item
      v-for="(module, idx) in displayedModules"
      :key="`gridItem${module.id || module.key}`"
      :position="module.position"
      :class="{'selected': isEdit && (editTarget && (editTarget.id || editTarget.key) == (module.id || module.key)) || selectedModuleId == module.id, 'will-be-hidden': !isModuleVisible(module)}"
      @click.native="selectedModuleId = module.id">
      <module
        :id="/* DO NOT REMOVE - id used for selenium snapshotting */ `module-${module.id}`"
        :key="`module-${module.id || module.key}`"
        :is-enabled="isEdit
          && (!dragTarget || (dragTarget.id || dragTarget.key) == (module.id || module.key))
          && (!editTarget || (editTarget.id || editTarget.key) == (module.id || module.key))"
        @start="$emit('start', module)"
        @move="$emit('move', {dragRect: $event, module, idx})"
        @resize="$emit('resize', {dragRect: $event, module, idx})"
        @drop="$emit('drop')">
        <module-edit
          v-if="module.view_types"
          :read-only="!isEdit || (editTarget && (editTarget.id || editTarget.key) != (module.id || module.key))"
          :is-edit="isEdit && editTarget && (editTarget.id || editTarget.key) == (module.id || module.key)"
          :measures="measures"
          :dimensions="dimensions"
          :dashboard-filters="filters"
          :view="module.view"
          :filters="module.filters"
          :columns="module.columns"
          :sort-by="module.order || []"
          :controls="module.controls || []"
          :limit="module.limit"
          :beta-spec="module.beta_spec"
          :position="module.position"
          :comparison="module.comparison"
          :view-types="module.view_types"
          @deleteModule="$emit('deleteModule', module)"
          @update:isEdit="$emit('update:editTarget', $event ? module : undefined)"
          @update:columns="updateColumns(module, idx, $event)"
          @update:sortBy="updateSortBy(module, idx, $event)"
          @update:controls="updateControls(module, idx, $event)"
          @update:limit="updateLimit(module, idx, $event)"
          @update:betaSpec="updateBetaSpec(module, idx, $event)"
          @update:position="updatePosition(module, idx, $event)"
          @update:filters="updateFilters(module, idx, $event)"
          @update:comparison="updateComparison(module, idx, $event)">
          <module-view
            :view-types="module.view_types"
            :filters="module.filters"
            :columns="module.columns"
            :controls="module.controls"
            :default-sort-by="module.order || []"
            :limit="module.limit"
            :beta-spec="module.beta_spec"
            :comparison="module.comparison"
            :report-name="reportName"
            :name="module.name"
            :dashboard-controls="controls"
            :dashboard-id="dashboardId"
            :module-id="module.id"
            :is-edit="isEdit && editTarget && (editTarget.id || editTarget.key) == (module.id || module.key)"
            :use-bigquery="useBigquery"
            :busy="isEdit && (!editTarget || (editTarget.id || editTarget.key) != (module.id || module.key))"
            :dashboard-filters="filters"
            :position="module.position"
            :drilldown-path="drilldowns[module.id]"
            @update:drilldownPath="updateDrilldownPath($event, module.id)"
            @update:name="updateName(module, idx, $event)" />
        </module-edit>
      </module>
    </grid-item>
    <div :style="{'grid-row': 'auto / span 1', 'grid-column': 'auto / span 4'}" />
  </grid>
</template>

<script>

  import Grid from '@/components/grid/Grid'
  import GridItem from '@/components/grid/GridItem'
  import Module from '@/components/module/Module'
  import ModuleView from '@/components/module/ModuleView'
  import ModuleEdit from '@/components/module/ModuleEdit'

  import { sortBy } from 'lodash'

  export default {
    name: 'DashboardGrid',
    components: {
      Grid,
      GridItem,
      Module,
      ModuleView,
      ModuleEdit,
    },
    props: {
      modules: { type: Array, required: true, },
      isEdit: { type: Boolean, required: true, },
      useBigquery: { type: Boolean, default: false, },
      editTarget: { type: Object, default: null, },
      dragTarget: { type: Object, default: null, },
      filters: { type: Array, required: true, },
      measures: { type: Array, required: true, },
      dimensions: { type: Array, required: true, },
      drilldowns: { type: Object, required: true, },
      controls: { type: Array, required: true, },
      dashboardId: { type: Number, required: true, },
      reportName: { type: String, default: '', },
    },
    data () {
      return {
        selectedModuleId: null,
      }
    },
    computed: {
      displayedModules () {
        return this.updatedPositions(this.modules.filter(m => this.isEdit || this.isModuleVisible(m)))
      },
      dashboardHeight () {
        return `${Math.max(...this.displayedModules.map(m => m.position.y + m.position.h)) * this.$store.getters['dashboards/cellHeight'] + 48}px`
      },
    },
    methods:  {
      updatedPositions (modules) {
        if (this.isEdit) {
          return modules
        }
        let newModules = [...modules.map(m => ({ ...m, position: { ...m.position, }, })), ]
        newModules = sortBy(newModules, a => a.position.y)
        let columns = [0, 0, 0, 0, ]
        for (let module of newModules) {
          let newY = -1
          for (let c of Array(module.position.w).keys()) {
            newY = Math.max(newY, columns[c + module.position.x])
          }
          for (let c of Array(module.position.w).keys()) {
            columns[c + module.position.x] = newY + module.position.h
          }
          module.position.y = newY
        }
        return newModules
      },
      isModuleVisible (module) {
        if (!module.beta_spec || !module.beta_spec.behaviors ) {
          return true
        }
        let switchBehaviors = module.beta_spec.behaviors.filter(behavior => behavior.type === 'option-select-switch')
        let isEnabled = true
        switchBehaviors.forEach(behavior => {
          if (!this.$store.getters['controls/debouncedSelections'][behavior.control_id]
            || !behavior.on_values.map(v => v.key).includes(this.$store.getters['controls/debouncedSelections'][behavior.control_id].key)) {
            isEnabled = false
          }
        })
        return isEnabled
      },
      updateDrilldownPath (path, moduleId) {
        var newPaths = { ...this.drilldowns, }
        newPaths[moduleId] = path
        this.$emit('update:drilldowns', newPaths)
      },
      onGridResize (rect) {
        this.$store.commit('dashboards/setGridWidthPx', rect.width)
      },
      updateName (module, idx, name) {
        this.$emit('update:module', { newModule: { ...module, name, }, } )
      },
      updateColumns (module, idx, columns) {
        this.$emit('update:module', { newModule: { ...module, columns, }, } )
      },
      updateSortBy (module, idx, order) {
        this.$emit('update:module', { newModule: { ...module, order, }, } )
      },
      updateControls (module, idx, controls) {
        this.$emit('update:module', { newModule: { ...module, controls, }, } )
      },
      updateLimit (module, idx, limit) {
        this.$emit('update:module', { newModule: { ...module, limit, }, } )
      },
      updateBetaSpec (module, idx, beta_spec) {
        this.$emit('update:module', { newModule: { ...module, beta_spec, }, } )
      },
      updatePosition (module, idx, position) {
        this.$emit('update:module', { newModule: { ...module, position, }, } )
      },
      updateComparison (module, idx, comparison) {
        this.$emit('update:module', { newModule: { ...module, comparison, }, } )
      },
    },
  }

</script>

<style lang="scss" scoped>

.dashboard-grid {
  position: relative;
}
.grid-item {
  z-index: 0;
  &.selected {
    z-index: 1;
  }
}

.will-be-hidden {
  opacity: .8;
}

</style>
