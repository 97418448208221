<template>
  <div class="columns optional-filters is-multiline">
    <div
      v-for="filter in optionalFilters"
      :key="filter.id"
      class="column is-3">
      <CustomFilter
        v-model="localDataContext"
        :filter-meta="filter"
        :dashboard-key="dashboardKey" />
    </div>
    <div class="actions">
      <button
        class="button is-primary"
        @click="updateDataContext">
        Update
      </button>
      <button
        class="button is-light edit-button"
        @click="removeAllFilters">
        Remove All
      </button>
    </div>
  </div>
</template>

<script>
  import CustomFilter from './CustomFilter.vue'
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    name: 'DashboardOptionalFilters',
    components: {
      CustomFilter,
    },
    props: {
      optionalFilters: {
        type: Array,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        localDataContext: cloneDeep(this.value),
      }
    },
    mounted () {

    },
    methods: {
      updateDataContext () {
        console.log('localDataContext', this.localDataContext)
        this.$emit('input', cloneDeep(this.localDataContext))
      },
      removeAllFilters () {
        const emptyFilters = {}
        this.optionalFilters.forEach(filter => {
          emptyFilters[filter.key] = []
        })
        this.$set(this.localDataContext, 'filter_selections', emptyFilters)
        this.updateDataContext()
      },
    },
  }
</script>

<style scoped>
.bottom {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #d2d2d4;
  background-color: #fbfbfb;
}

.optional-filters {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 !important;
}

.actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.actions button {
  margin: 4px 0;
  width: 120px;
}

.edit-button {
  border: none;
  color: #6c757d;
}
</style>
