<template>
  <div class="table-module">
    <div class="table-section">
      <div class="table-header">
        {{ dashboardModule.name }}
      </div>
      <b-skeleton
        v-if="isLoading"
        height="400px"
        style="border-radius: 0"
        width="100%"
        :active="isLoading" />
      <div
        v-else
        class="table-container">
        <table-column
          v-for="(column, i) in data.columns"
          :key="column.id"
          :class="{ 'sticky-column': i % 2 === 0 }"
          :current-depth="0"
          :column="column" />
      </div>
    </div>
  </div>
</template>

<script>
  import TableColumn from './TableColumn.vue'
  export default {
    name: 'TableModule',
    components: {
      TableColumn,
    },

    provide () {
      return {
        maxDepth: this.data.depth,

        getRowCell: this.getRowCell,
      }
    },
    props: {
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        data: {
          type: 'TableModuleData',
          id: '8ce16562-916b-4e1d-82f4-dfc33f52cdc5',
          key: 'test_table_module',

          name: 'Dimension Totals',
          rows: [
            [
              {
                type: 'TableCellData',
                id: 'e0533623-02ea-4fec-ac72-2b9ed82dc897',
                value: 'Ingest - Asheville',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'a0db089d-c2fb-4159-8e92-6ecbee08f38c',
                value: 'Off Prem',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'b0b3985b-00e7-46b3-8113-92e590609ad6',
                value: '$0.00',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '7044266c-fa0b-4ad5-9589-1f1e014c2f08',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '3b1b7cf6-6d2d-4a23-8ff9-c581c5c947b2',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '1f0428cb-d101-4143-bba2-ac32c38f9a16',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '1c0a5d7e-2f46-4c09-8b54-7971df5651b1',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'bcde61b2-bb63-445b-a4aa-9bcda3d067ab',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '06f41b40-be72-4098-a15f-f8fbd86a4df5',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '08310fec-6f7a-44eb-b150-6a2eb9d7714f',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '7d909994-a61c-439f-9cc5-07eb055acdb4',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'bf666058-cf21-4ecd-a5eb-44dc66894778',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '1f6d68e1-dd3c-4ac5-ade5-ce5a0192fa0e',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'eccc7ab2-e874-4125-8a3a-14dca9009b84',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'e8dcf5b4-c8da-452e-a189-f7ee6249ecf4',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
            ],
            [
              {
                type: 'TableCellData',
                id: '58b4e686-89fe-4528-9ea5-7e76a7cd0109',
                value: 'Ingest - Asheville',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '49bb6f95-1213-456e-a0c8-d5cbdf2b4ae6',
                value: 'Revenue Center 0',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'aba850da-f063-4e48-9894-45bf94614876',
                value: '$0.00',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'ba6ca0fc-3b2a-401d-9418-3edcd47cccac',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'e41f02bb-3bb2-4443-8e12-e0abbe4c3fe6',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '5fe38ad0-b4eb-4e0d-adcf-e3efd1256da3',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '404dafb9-6d3a-4281-9ce6-691e8ec2f1f4',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'b14f642c-97c9-44fd-9918-01082389b756',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'f141e0b1-ec85-4f17-af4a-a46ee1aacfe5',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '7e29b587-2f50-487d-9b4a-7256e83ca44a',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '9a17db4b-187b-4dae-a6a1-63e535f4a41b',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'e908f14b-387a-471a-b8c0-e17bd3a4a218',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '723f3f4d-3700-415f-b5c6-4f0badc99d8a',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '6abf18eb-93c2-4fac-9ff1-79a6a5883e13',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '6f4a3449-b205-41a1-be6e-e190e7c67200',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
            ],
            [
              {
                type: 'TableCellData',
                id: '7b438ee6-6f29-496e-a498-2dc0a52001c8',
                value: 'Ingest - Asheville',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '1c3c645c-bf26-4eed-bee9-b9fe3d7fc8c2',
                value: 'Revenue Center 1',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '14ca97b3-ac70-4efe-ae04-9b24b54a3665',
                value: '$0.00',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '91cb37af-ae20-463b-a050-d81745f21e60',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'a0a547e3-7a45-4338-96ca-29f50b11857c',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '8e331a09-b08d-40ce-87b3-131c15e1a28d',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'e777efd3-580f-42aa-8b7f-a86812609fc2',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '7e665871-41d6-423a-9ff6-fde14d6fb5e1',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '9a451c65-839e-4cf2-9788-cb0319ecfe75',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'f09874b8-934c-4371-80f3-4594658bcf9f',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '80bd09bc-82fc-4ae3-a48f-fde8b7615340',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'a42361cf-8dfd-4df8-8fd8-3265a58e21ac',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '7c61d9f9-0c33-49fd-8bfd-783ab64b3d9a',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '1a847c46-83ed-4375-a0fb-d5ece63382ec',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '03625b62-e013-4998-aed3-2e04687f644e',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
            ],
            [
              {
                type: 'TableCellData',
                id: '88455943-62ac-475e-93c1-aa63525bb0c5',
                value: 'Ingest - Asheville',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'd6ef221c-acbe-4650-ad02-0c504e471a41',
                value: 'Revenue Center 10',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '3d371307-bc2c-4efb-8a32-2f9912d2ff2c',
                value: '$0.00',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '423e1f33-e33e-44ec-acc4-832dc894e406',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'f032609f-138d-443f-9a9d-aa793a8c4a34',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'bbb5628f-833d-4ee9-a281-c00242c832d0',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '4caf1782-55da-4780-9cf7-04e06f1b3020',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '817fcbf1-d369-4742-98ec-1e139a22d578',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'ad5590a8-9f1d-4c5f-9405-8600ef9cc88b',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'b1b08cb7-6725-4fa5-a778-57887b4805a6',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'bd37f3ae-5701-4791-a29c-c7458fd0fc8a',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'fbe0e7c3-8f1f-4e8f-93e0-31aa4a6156f4',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '6598dddd-66bc-46d3-8508-95380dc19aa6',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'd8494b22-2e21-4ddc-aac7-9b3a3e62bbce',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '1527f132-7c3f-422d-9a16-479f167430bb',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
            ],
            [
              {
                type: 'TableCellData',
                id: '21e282b6-ed60-4411-b3f4-4d355d74d426',
                value: 'Ingest - Asheville',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '5969e2d6-92c5-43b0-a47b-ff3cc319b104',
                value: 'Revenue Center 100',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'a019fd75-4245-4708-accd-a7d1d58b9e71',
                value: '$0.00',
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '9ee84f5d-3f15-4ddf-a94b-fee03cf60cb7',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '7c55bfcf-917b-4d0a-9e74-c788e7602fec',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '8b503cbf-2af1-4151-978b-fb084f88fefa',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'a0f717b1-749e-48e8-90e9-3fe33bbeb8b7',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'bb93fb89-5fe2-4271-a4d6-ddb0f7c1fb4c',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '6b62e859-ccca-4d3b-830f-282a8170fa3f',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '8022be5f-c41c-45e7-a583-82d1bcee3be9',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'a786af9d-3647-424b-b608-585fff6fdd0a',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'e58b61c9-9b2c-47ef-9d3a-6652902fee13',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '8f969378-463d-44b5-827f-833fd6ab8fe7',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: 'd5ec7bd9-9fe3-4dbc-9d7e-306b068b801f',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
              {
                type: 'TableCellData',
                id: '92a5f458-ae71-44f6-8e31-17bd37bb1d89',
                value: null,
                direction: 'none',
                quality: 'none',
                is_total: false,
              },
            ],
          ],

          columns: [
            {
              type: 'TableColumnData',
              id: '3e1b20f5-b3dc-4eeb-a9e2-d1800746352d',
              label: 'Location',
              sub_columns: [],
              row_idx: 0,
              is_total: false,
              depth: 0,
            },
            {
              type: 'TableColumnData',
              id: 'a56caf2d-f50c-430f-8541-98302128cedc',
              label: 'Rev Center',
              sub_columns: [],
              row_idx: 1,
              is_total: false,
              depth: 0,
            },
            {
              type: 'TableColumnData',
              id: 'f0f84b8a-77fe-4401-b933-8877c641a8a6',
              label: 'Group 0',
              sub_columns: [
                {
                  type: 'TableColumnData',
                  id: 'cf2f5dc0-70a8-4467-9013-6172329609b0',
                  label: 'SubGroup 0',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '7032e3dc-fe96-41d3-9c5d-c964043eddb8',
                      label: 'Sales',
                      sub_columns: [],
                      row_idx: 2,
                      is_total: false,
                      depth: 0,
                    },
                    {
                      type: 'TableColumnData',
                      id: 'ac9b2126-174a-43f6-8fb8-f93e7286d256',
                      label: 'PW Sales',
                      sub_columns: [],
                      row_idx: 3,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
              ],
              row_idx: null,
              is_total: false,
              depth: 2,
            },
            {
              type: 'TableColumnData',
              id: '4c62958d-e0d2-490d-8526-4a02f667b051',
              label: 'Group 1',
              sub_columns: [
                {
                  type: 'TableColumnData',
                  id: '73e4cdc4-1433-40bf-bb29-dd3c07547766',
                  label: 'test',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '2942478a-aaa6-43fd-9a7c-59abb53071ca',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 4,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: 'b36225c7-92a1-403a-99ab-368bfa196358',
                  label: 'happy hour',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: 'fe265980-0901-4b9f-816a-c61681d8a589',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 5,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: 'eb7cb950-eb27-424b-9a3d-37cc3abe0bda',
                  label: 'midnight_snack',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: 'c5ed3e46-9c01-4b63-b3db-67575cb8b6a0',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 6,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: '1fefa72d-9572-40a3-bda3-718b37945960',
                  label: 'breakfast',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '564f27a7-3172-44c1-a55d-6a2f15897266',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 7,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: '2ede16d2-a6db-4f44-810b-08b810778937',
                  label: 'lunch',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: 'f0ae8ea4-f160-41db-9c91-df723b9fba05',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 8,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: '96c8017d-6e54-4f26-982c-a4b7406ce8d6',
                  label: 'dinner',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '063a4024-95f5-409b-a2fe-8614e2b79887',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 9,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: 'f1e34857-868e-463c-8036-1b07eebe3d56',
                  label: 'snack',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '437e077c-fb81-4180-a627-5e5fb0987478',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 10,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: '4872732e-7154-4c8e-82ed-52236e2251ad',
                  label: 'late_night',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '210ebcfb-b327-47e6-9510-dd658ac7ca74',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 11,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: 'ad152a19-e946-4a1b-8f1a-f5d9b5cf9bad',
                  label: 'No Meal Period',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '2d15aaac-ea90-4044-91a7-cb658a2df339',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 12,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: false,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: 'b8900cff-abf3-49b5-833a-d18f2f6bf42d',
                  label: 'Total 1',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '49a999b6-3b06-45a8-b626-0eb3078ebff4',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 13,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: true,
                  depth: 1,
                },
                {
                  type: 'TableColumnData',
                  id: '2182adb9-20f2-4214-a697-6be6e40f4fd5',
                  label: 'Total 2',
                  sub_columns: [
                    {
                      type: 'TableColumnData',
                      id: '5a7bd0ef-9f95-4dbb-b574-4ba4bab5f5c0',
                      label: 'Covers',
                      sub_columns: [],
                      row_idx: 14,
                      is_total: false,
                      depth: 0,
                    },
                  ],
                  row_idx: null,
                  is_total: true,
                  depth: 1,
                },
              ],
              row_idx: null,
              is_total: false,
              depth: 2,
            },
          ],
          depth: 2,
        },
        isLoading: true,
      }
    },
    watch: {
      dashboardDataContext: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },
    mounted () {
      this.loadData()
    },
    methods: {
      getRowCell (row, column) {
        return this.data.rows[row][column]
      },
      async loadData () {
        this.isLoading = true
        this.data = await this.$apis.companyConfigs.getDashboardModuleData(
          this.dashboardKey,
          this.dashboardModule.key,
          this.dashboardDataContext
        )
        this.isLoading = false

        console.log('table', this.data)
      },
    },
  }
</script>

<style scoped>
.table-container {
  display: flex;
  border: 1px solid #e9e9ea;
  overflow-x: auto;
  font-size: 12px;
  display: relative;
}

.table-header {
  font-size: 16px;
  color: #333;
  padding: 8px;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}
.table-module {
  margin: 0 -18px;
}
/* .table-section {
  display: flex;
}
.table-column {
  min-width: 120px;
  border-right: 1px solid #e9e9ea;
}
.header {
  background-color: #f2f2f2;
  padding: 8px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #e9e9ea;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cell {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #e9e9ea;
}
.location-column {
  background-color: #f8f8f8;
  font-weight: bold;
}
.increase {
  color: #008c84;
  background-color: #f2f9f9;
}
.decrease {
  color: #ff0000;
  background-color: #f9f2f2;
}
.arrow-icon {
  font-size: 14px;
  margin-left: 4px;
} */
</style>
