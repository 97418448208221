<template>
  <div class="sub-section">
    <MeasureConfigOptions v-model="vModel" />
  </div>
</template>

<script>
  import MeasureConfigOptions from '@/components/reports/config/modules/common/MeasureConfigOptions'

  export default {
    name: 'TrackeRowMeasure',
    components: {
      MeasureConfigOptions,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      vModel: {
        get () {
          return this.value
        },
        set (vModel) {
          this.$emit('input', vModel)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.benchmark-inputs {
  margin-top: 30px;
}
</style>
