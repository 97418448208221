<template>
  <div
    ref="interactable"
    class="interactable"
    :class="{ 'enabled': enableMove || enableResize, dropZoneType : dropZoneType }"
    :style="style">
    <transition-group name="fade">
      <template v-if="enableMove">
        <slot name="dragHandle" />
      </template>
      <template v-if="enableResize">
        <v-icon
          key="left"
          name="circle"
          class="left handle" />
        <v-icon
          key="right"
          name="circle"
          class="right handle" />
        <v-icon
          key="down"
          name="circle"
          class="down handle" />
      </template>
    </transition-group>
    <slot />
  </div>
</template>

<script>

  export default {
    name: 'InteractableComponent',
    props: {
      enableMove: Boolean,
      enableResize: Boolean,
      dropZoneType: { type: String, default: null, },
    },
    data () {
      return {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        dragging: false,
      }
    },
    computed: {
      style () {
        return {
          'position': this.dragging ? 'fixed' : 'absolute',
          'left': this.dragging ? `${this.x}px` : '0',
          'top': this.dragging ? `${this.y}px` : '0',
          'width': this.dragging ? `${this.w}px` : '100%',
          'height': this.dragging ? `${this.h}px` : '100%',
        }
      },
    },
    mounted () {
      this.setInteractable()
    },
    destroyed () {
      this.unsetInteractable()
    },
    methods: {
      unsetInteractable () {
        if (this.interactable) {
          this.interactable.unset()
          this.interactable = undefined
        }
      },
      setInteractable () {
        this.interactable = this.$interact(this.$el)
          .draggable({
            allowFrom: '.drag.handle',
            autoScroll: true,
            onstart: this.onStart,
            onmove: this.onMove,
            onend: this.onDrop,
          })
          .resizable({
            edges: {
              top: false,
              left: '.left.handle',
              right: '.right.handle',
              bottom: '.down.handle',
            },
            autoScroll: true,
            onstart: this.onStart,
            onmove: this.onResize,
            onend: this.onDrop,
          })
      },
      onStart (event) {
        this.$emit('start')
        this.x = event.client.x - event.page.x + event.rect.left
        this.y = event.client.y - event.page.y + event.rect.top
        this.w = event.rect.width
        this.h = event.rect.height
        this.dragging = true
      },
      onMove (event) {
        this.x = event.pageX
        this.y = event.pageY
        this.$emit('move', this.$el.getBoundingClientRect())
      },
      onResize (event) {
        this.w = event.rect.width
        this.h = event.rect.height
        this.x = event.client.x - event.page.x + event.rect.left
        this.y = event.client.y - event.page.y + event.rect.top
        if (event.edges.left) {
          this.x -= event.deltaRect.width
        }
        if (event.edges.top) {
          this.y -= event.deltaRect.height
        }
        this.$emit('resize', this.$el.getBoundingClientRect())
      },
      onDrop () {
        this.dragging = false
        this.$emit('drop')
        this.x = this.y = this.w = this.h = 0
      },
    },
  }
</script>

<style lang="scss" scoped>

.handle {
  position: absolute;
  width: 16px;
  height: 16px;
  position: absolute;
  opacity: .6;
  z-index: 1;
  fill: $backGround0;

  &.left {
    left: 0;
    bottom: calc(50% + -8px);
  }

  &.right {
    right: 0;
    bottom: calc(50% + -8px);
  }

  &.down {
    right: calc(50% + -8px);
    bottom: 0;
  }
}

</style>
