<template>
  <div class="form">
    <div class="columns">
      <div class="column">
        <ingest-form :fields.sync="json" />
      </div>
      <div class="column">
        <v-jsoneditor
          v-model="json"
          :options="editorOptions"
          :plus="false"
          height="700px"
          @error="onError" />
      </div>
    </div>
  </div>
</template>

<script>
  import VJsoneditor from 'v-jsoneditor'
  import IngestForm from '@/components/form/IngestForm'

  export default {
    name: 'RenderForm',
    components: {
      IngestForm,
      VJsoneditor,
    },
    props: {},
    data () {
      return {
        editorOptions: {
          'mode': 'code',
        },

        json: [
          {
            'id': 'name',
            'label': 'name',
            'component': 'input',
            'type': 'text',
            'value': '',
            'required': true,
          },
          {
            'id': 'number',
            'label': 'number',
            'component': 'input',
            'type': 'number',
            'value': '',
            'required': true,
          },
          {
            'id': 'fruit',
            'label': 'select fruit',
            'component': 'select',
            'value': [
              'apple',
            ],
            'required': true,
            'multiple': true,
            'options': [
              {
                'label': 'apple',
                'value': 'apple',
              },
              {
                'label': 'orange',
                'value': 'orange',
              },
            ],
          },

          {
            'id': 'applename',
            'label': 'apple name ',
            'component': 'input',
            'value': 'apple name',
            'required': true,
            'dependsOn': {
              'id': 'fruit',
              'value': 'apple',
            },
          },
          {
            'id': 'orangename',
            'label': 'orange name ',
            'component': 'input',
            'value': 'orange name',
            'required': true,
            'dependsOn': {
              'id': 'fruit',
              'value': 'orange',
            },
          },
        ]
        ,
      }
    },
    computed: {
    },
    methods: {
      onError (e) {
        console.log('error',e)
      },
      isVisible (input) {
        if (input.dependsOn) {
          if (Array.isArray(input.dependsOn)) {
            for (let dependsOn of input.dependsOn) {
              const parent = this.json.find((val) =>
                val.id === dependsOn.id)
              if (!(Array.isArray(parent.value) ? parent.value.includes(dependsOn.value) : parent.value === dependsOn.value )) {
                return false
              }
            }
          }
          else {
            const parent = this.json.find((val) => {
              return val.id === input.dependsOn.id
            })
            return  (Array.isArray(parent.value) ? parent.value.includes(input.dependsOn.value) : parent.value === input.dependsOn.value )
          }

        }
        return true
      },
    },
  }
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  overflow-y: auto;
  background: $grayscale-7;
  position: relative;
  padding-bottom: 50px;
  padding: 20px;

  .columns{
    height: 100%;
  }

}

.ingest-form {
  max-width: 400px;
}

</style>
