<template>
  <div
    ref="dropZone"
    class="drop-zone"
    :class="{ draggedOver }">
    <slot />
  </div>
</template>

<script>

  export default {
    name: 'DropZone',
    props: {
      color: { type: String, default: null, },
      targetSelector: { type: String, required: true, },
    },
    data () {
      return {
        interactable: undefined,
        draggedOver: false,
      }
    },
    mounted () {
      this.setInteractable()
    },
    destroyed () {
      this.unsetInteractable()
    },
    methods: {
      unsetInteractable () {
        if (this.interactable) {
          this.interactable.unset()
          this.interactable = undefined
        }
      },
      setInteractable () {
        this.interactable = this.$interact(this.$el)
          .dropzone({
            accept: this.targetSelector,
            ondrop: this.onDrop,
            ondragenter: this.onEnter,
            ondragleave: this.onLeave,
          })
      },
      onEnter () {
        this.draggedOver = true
      },
      onLeave () {
        this.draggedOver = false
      },
      onDrop () {
        this.draggedOver = false
        this.$emit('drop')
      },
    },
  }

</script>

<style lang="scss" scoped>

.drop-zone {
}

</style>
