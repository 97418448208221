<template>
  <span class="material-icons">bar_chart</span>
</template>

<script>

  export default {
    name: 'BarIcon',
    props: { },
  }

</script>

<style lang="scss" scoped>

.bar-icon {
}

</style>
