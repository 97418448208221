<template>
  <div class="container-fluid">
    <div class="container">
      <div class="header columns">
        <h2>Modules</h2>
        <div class="control-bar">
          <b-button
            type="is-white"
            class="action-btn"
            icon-left="plus"
            @click="createNewModule">
            New Module
          </b-button>
        </div>
      </div>
      <b-table
        :data="allModules"
        :loading="isLoading"
        class="modules-table">
        <b-table-column
          v-slot="props"
          field="title"
          label="Title">
          {{ props.row.title || '-' }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="type"
          label="Type">
          {{ props.row.type || '-' }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="Actions"
          width="50"
          label="Actions">
          <div class="actions">
            <router-link :to="{name: 'module-editor', params: {module_id: props.row.id}}">
              <b-button
                type="is-primary"
                class="action-btn"
                icon-right="lead-pencil" />
            </router-link>
            <b-button
              type="is-warning"
              icon-right="delete"
              @click="deleteModule(props.row.id)" />
          </div>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModuleLibraryPage',
    data () {
      return {
        allModules: [],
        isLoading: false,
      }
    },

    async mounted () {
      this.loadAllModules()
    },

    methods: {
      async loadAllModules () {
        this.isLoading = true
        this.allModules = await this.$apis.modules.getAllModules()
        this.isLoading = false
      },

      async deleteModule (id) {
        this.$buefy.dialog.confirm({
          title: 'Deleting Module',
          message: 'Are you sure you want to <b>delete</b> this Module.',
          confirmText: 'Delete Module',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true
            await this.$apis.modules.deleteModule(id)
            this.isLoading = false
            this.loadAllModules()
          },
        })
      },

      async createNewModule () {
        this.isLoading = true
        const newModule = await this.$apis.modules.createModule({})
        this.isLoading = false

        if (newModule) {
          this.$router.push({ name: 'module-editor',params: { module_id: newModule.id, }, })
        } else {
          this.$buefy.toast.open({
            message: `Error Creating New Module`,
            type: 'is-warning',
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: #f5f5f5;
  width: 100%;
  padding: 20px 14px;

  .header {
    border-bottom: $border-1;
    margin-top: 30px;

    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 22px;
      margin: 10px;
    }

    .control-bar {
        display: flex;
        margin-left: auto;
        margin-right: 0;
      }
  }

  .modules-table {
    font-size: 13px;
    margin-top: 20px;
  }
  .actions{
    display: flex;

    .action-btn {
        margin: 0 5px;
    }

  }

}
</style>
