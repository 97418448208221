
export default {
  props: {
    value: { type: [String, Number, Date,], default: null, },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
}
