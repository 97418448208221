<template>
  <div
    class="kpi-component"
    :class="{'good': content.isGood === true, 'bad': content.isGood === false}">
    <div
      v-if="!isLoading"
      class="upper-section">
      <div
        class="data-point">
        {{ content.dataPoint }}
      </div>

      <div class="data-point-value">
        {{ content.dataPointValue.main }} <span class="superscript">{{ content.dataPointValue.sup }}</span>
      </div>
    </div>
    <b-skeleton
      height="18"
      width="100"
      :active="isLoading" />
    <b-skeleton
      height="38"
      width="150"
      :active="isLoading" />
    <div
      v-if="content.changes"
      class="changes">
      <template v-if="!isLoading">
        <span class="change-percentage">+ 23% </span> <span class="change-value"> + $19,451.55</span>
      </template>
      <b-skeleton
        height="20"
        width="250"
        :active="isLoading" />
    </div>
    <div
      v-if="content.benchmark"
      class="benchmark">
      <template v-if="!isLoading">
        <div class="benchmark-label">
          {{ content.benchmark.label }}
        </div>
        <div class="benchmark-value">
          {{ content.benchmark.value }}
        </div>
      </template>
      <b-skeleton
        height="18"
        width="100"
        :active="isLoading" />
      <b-skeleton
        height="20"
        width="150"
        :active="isLoading" />
    </div>

    <progressbar
      v-if="content.hasProgressbar"
      class="visualization"
      :content="content" />
  </div>
</template>

<script>
  import progressbar from '@/components/visualization/progressbar'
  export default {
    name: 'ComponentLibrary',
    components: { progressbar, },
    props: {
      content: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        isLoading: true,
      }
    },
    mounted () {
      setTimeout(() => {this.isLoading = false}, 2000)
    },
  }
</script>

<style lang="scss" scoped>
.kpi-component {
  padding: 25px;

  &.good {
    .data-point-value,
    .changes{
      color: $primary;
    }
  }

  &.bad {
    .data-point-value,
    .changes{
      color: $warning;
    }
  }

  .data-point {
    font-size: 14px;
    font-weight: 500;
  }
  .data-point-value {
    margin-top: 12px;
    font-weight: 700;
    font-size: 24px;
    color: $grayscale-1;
  }
  .superscript {
    font-weight: 500;
    font-size: 14px;
    position: relative;
    top: -5px;
  }
  .benchmark {
    margin-top: 14px;
    .benchmark-label {
      color: $grayscale-3;
      font-weight: 400;
      font-size: 14px;
    }
    .benchmark-value {
      color: $grayscale-2;
      font-weight: 500;
      font-size: 16px;
      margin-top: 4px;
    }
  }

  .changes {
    border-top: $border-1;
    padding-top: 10px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;

    .change-percentage {
      margin-right: 8px;
      padding-right: 8px;
      border-right: $border-1;
    }
  }
  .visualization{
    margin-top: 12px;
  }
}
</style>
