import axios from 'axios'

// TODO: use API
var nextSourceId = 0

export const expressionsModel = {
  namespaced: true,
  state () {
    return {
      initialized: false,
      dataTypes: {},
      companyExpressions: {},
      companySources: {},
      companyMeasures: {},
      companyDimensions: {},
      entityAttributes: [],
      argTypes: {
        expression: {},
        expression_list: {},
        field: {},
        string: {},
        number: {},
        type: {},
      },
      expressionTypes: {},
      fieldOptions: [],
      views: [],
    }
  },
  getters: {
    expressionTypes (state) {
      return state.expressionTypes
    },
    dataTypes (state) {
      return state.dataTypes
    },
    argTypes (state) {
      return state.argTypes
    },
    fieldOptions (state) {
      return state.fieldOptions
    },
    views (state) {
      return state.views
    },
    companyExpressions (state) {
      return state.companyExpressions
    },
    companySources (state) {
      return state.companySources
    },
    companyMeasures (state) {
      return state.companyMeasures
    },
    companyDimensions (state) {
      return state.companyDimensions
    },
    entityAttributes (state) {
      return state.entityAttributes
    },
    initialized (state) {
      return state.initialized
    },
  },
  mutations: {
    setInitialized (state, initialized) {
      state.initialized = initialized
    },
    setExpressionTypes (state, types) {
      state.expressionTypes = types
    },
    setCompanyExpressions (state, expressions) {
      state.companyExpressions = expressions
    },
    setCompanyMeasures (state, measures) {
      state.companyMeasures = measures
    },
    setCompanyDimensions (state, dimensions) {
      state.companyDimensions = dimensions
    },
    setEntityAttributes (state, attributes) {
      state.entityAttributes = attributes
    },
    setDataTypes (state, types) {
      state.dataTypes = types
    },
    setFieldOptions (state, options) {
      state.fieldOptions = options
    },
    setViews (state, views) {
      state.views = views
    },
    addNewExpression (state, expression) {
      let newExpressions = { ...state.companyExpressions, }
      newExpressions[expression.id] = expression
      state.companyExpressions = newExpressions
    },
    addNewSource (state, source) {
      let newSources = { ...state.companySources, }
      newSources[source.id] = source
      state.companySources = newSources
    },
    addNewMeasure (state, measure) {
      let newMeasures = { ...state.companyMeasures, }
      newMeasures[measure.id] = measure
      state.companyMeasures = newMeasures
    },
    addNewDimension (state, dimension) {
      let newDimensions = { ...state.companyDimensions, }
      newDimensions[dimension.id] = dimension
      state.companyDimensions = newDimensions
    },
  },
  actions: {
    async loadCompanyExpressions (context) {
      let expressionsFuture = axios.get(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/expressions`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      let companyExpressions = {}
      for (let e of (await expressionsFuture).data) {
        companyExpressions[e.id] = e
      }
      context.commit('setCompanyExpressions', companyExpressions)
    },
    async loadCompanyMeasures (context) {
      let measuresFuture = axios.get(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/company_measures`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      let companyMeasures = {}
      for (let m of (await measuresFuture).data) {
        companyMeasures[m.id] = m
      }
      context.commit('setCompanyMeasures', companyMeasures)
    },
    async loadCompanyDimensions (context) {
      let dimensionsFuture = axios.get(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/company_dimensions`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      let companyDimensions = {}
      for (let d of (await dimensionsFuture).data) {
        companyDimensions[d.id] = d
      }
      context.commit('setCompanyDimensions', companyDimensions)
    },
    async loadEntityAttributes (context) {
      let attributesFuture = axios.get(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/attributes`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('setEntityAttributes', (await attributesFuture).data)
    },
    async loadExpressionTypes (context) {
      let expressionsFuture = axios.get(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/expressions`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('setExpressionTypes', (await expressionsFuture).data)
    },
    async loadDataTypes (context) {
      let typesFuture = axios.get(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/data_types`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('setDataTypes', (await typesFuture).data)
    },
    async loadFieldOptions (context) {
      let optionsFuture = axios.get(
        `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/fields`,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('setFieldOptions', (await optionsFuture).data)
    },
    async loadViews (context) {
      try {
        let viewsFuture = axios.get(
          `${process.env.VUE_APP_API_URL}/company/${context.rootGetters['companyId']}/views`,
          { headers: { authorization: context.rootGetters['auth/authToken'], }, }
        )
        context.commit('setViews', (await viewsFuture).data)
      } catch (e) {
        console.log('API Error', e)
      }
    },
    async createExpression (context) {
      let expressionFuture = axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/expression`,
        {},
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )

      const newExpression = (await expressionFuture).data
      context.commit('addNewExpression', newExpression)
      return newExpression
    },
    async updateExpression (context, expression) {
      let expressionFuture = axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/expression/${expression.id}`,
        expression,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('addNewExpression', (await expressionFuture).data)
    },
    async createSource (context) {
      /* TODO:
      let measureFuture = axios.post(
          `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/company_source`,
        {},
          { headers: { authorization: context.rootGetters['auth/authToken'], }, })
      context.commit('addNewMeasure', (await measureFuture).data)
      */
      context.commit('addNewSource', {
        id: nextSourceId,
      })
      nextSourceId += 1
    },
    async createMeasure (context) {
      let measureFuture = axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/company_measure`,
        {},
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('addNewMeasure', (await measureFuture).data)
    },
    async updateSource (context, source) {
      /* TODO:
      let sourceFuture = axios.post(
          `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/company_source/${source.id}`,
        source,
          { headers: { authorization: context.rootGetters['auth/authToken'], }, })
      context.commit('addNewSource', (await sourceFuture).data)
      */
      context.commit('addNewSource', source)
    },
    async updateMeasure (context, measure) {
      let measureFuture = axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/company_measure/${measure.id}`,
        measure,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('addNewMeasure', (await measureFuture).data)
    },
    async createDimension (context) {
      let dimensionFuture = axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/company_dimension`,
        {},
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('addNewDimension', (await dimensionFuture).data)
    },
    async updateDimension (context, dimension) {
      let dimensionFuture = axios.post(
        `${process.env.VUE_APP_ADMIN_API_URL}/company/${context.rootGetters['companyId']}/company_dimension/${dimension.id}`,
        dimension,
        { headers: { authorization: context.rootGetters['auth/authToken'], }, }
      )
      context.commit('addNewDimension', (await dimensionFuture).data)
    },
    async initialize (context) {
      if (
        context.state.initialized ||
        !context.rootGetters['auth/isLoggedIn']
      ) {
        return
      }
      for (let future of [
        context.dispatch('loadCompanyExpressions'),
        context.dispatch('loadCompanyMeasures'),
        context.dispatch('loadCompanyDimensions'),
        context.dispatch('loadEntityAttributes'),
        context.dispatch('loadExpressionTypes'),
        context.dispatch('loadDataTypes'),
        context.dispatch('loadFieldOptions'),
        context.dispatch('loadViews'),
      ]) {
        await future
      }
      context.commit('setInitialized', true)
    },
  },
  modules: {},
}
