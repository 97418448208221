<template>
  <div class="quick-search">
    <b-modal
      v-model="isActive"
      :width="560"
      @after-enter="focus">
      <article class="panel is-primary">
        <div class="panel-block">
          <p class="control has-icons-left">
            <b-input
              ref="searchbar"
              type="search"
              custom-class="no-border no-shadow"
              placeholder="Search Ingest"
              aria-label="Search in the documentation"
              aria-controls="quickSearchResults"
              :aria-invalid="
                isTermEmpty || groupedResults.length > 0 ? null : 'true'
              "
              :aria-errormessage="
                isTermEmpty || groupedResults.length > 0
                  ? null
                  : 'quickSearchNoresult'
              "
              maxlength="32"
              :has-counter="false"
              @input="search" />
            <span class="icon is-left">
              <b-icon
                icon="magnify"
                size="is-small" />
            </span>
          </p>
        </div>

        <div
          v-if="!isTermEmpty"
          id="quickSearchResults"
          class="panel-block quick-search-results"
          role="region"
          aria-labelledby="Search results"
          aria-live="polite">
          <template v-if="groupedResults.length > 0">
            <div
              v-for="section in groupedResults"
              :key="section.category"
              role="group"
              class="result-category-group"
              :aria-label="`Results in ${section.category}`">
              <h4 class="category-title">
                {{ section.category }}
              </h4>

              <div
                v-for="result in section.results"
                :ref="`result_${result.index}`"
                :key="result.id"
                class="notification result-item"
                :class="{
                  'is-active': result.index === selectedIndex,
                }"
                role="option"
                :aria-selected="result.index === selectedIndex"
                :aria-setsize="results.length"
                :aria-posinset="result.index + 1"
                tabindex="-1"
                @mouseenter="select(result.index)"
                @click="navigateTo">
                <div>
                  <div class="columns p-0 m-0">
                    <p class="is-size-6">
                      {{ result.name }}
                    </p>
                  </div>

                  <p
                    v-if="result.description"
                    class="is-size-7">
                    {{ result.description }}
                  </p>
                </div>
              </div>
            </div>
          </template>

          <p
            v-else
            id="quickSearchNoresult"
            class="is-size-4 has-text-dark quick-search-noresult">
            No results for “<strong class="has-text-primary">{{
              term
            }}</strong>„
          </p>
        </div>

        <ul class="panel-block quick-search-shortcuts is-hidden-touch">
          <li aria-keyshortcuts="Enter">
            <b-tag> Enter </b-tag>
            <span class="ml-2"> to select </span>
          </li>
          <li aria-keyshortcuts="ArrowUp ArrowDown">
            <b-tag class="tag">
              ↓
            </b-tag>

            <b-tag class="ml-1">
              ↑
            </b-tag>
            <span class="ml-2"> to navigate </span>
          </li>
          <li aria-keyshortcuts="Escape">
            <b-tag> Esc </b-tag>
            <span class="ml-2"> to close </span>
          </li>
          <li aria-keyshortcuts="Escape">
            <b-tag> {{ isMacOS ? "⌘" : "Ctrl" }} + K </b-tag>
            <span class="ml-2"> to open search </span>
          </li>
        </ul>
      </article>
    </b-modal>
  </div>
</template>

<script>
  import navigation from '@/mixins/navigation'
  import FuzzySearch from 'fuzzy-search'
  export default {
    mixins: [navigation,],
    props: { value: { type: Boolean, default: false, }, },
    data () {
      return {
        isMacOS: /\(\s*Macintosh\s*;/.test(navigator.userAgent),
        results: [],
        selectedIndex: 0,
        term: '',
      }
    },
    computed: {
      isActive: {
        get () {
          return this.value
        },
        set (value) {
          this.term = ''
          this.$emit('input', value)
        },
      },
      searcher () {
        const searcher = new FuzzySearch(
          this.allOptions,
          ['name', 'description', 'category', 'keywords',]
        )
        return searcher
      },
      allOptions () {
        return [
          ...this.allReports,
          ...this.navlinks.tools.children.map((item) => {
            return {
              category: 'Tools',
              ...item,
            }
          }),
          ...this.navlinks.data.children.map((item) => {
            return {
              category: 'Data',
              ...item,
            }
          }),
          ...this.navlinks.user.children.map((item) => {
            return {
              category: 'User',
              ...item,
            }
          }),

          ...(this.hasMultipleCompanies
            ? this.allCompanies.map((item) => {
              return {
                name: `Switch to ${item.name}`,
                callback: item.callback,
                category: 'Company',
              }
            })
            : {}),
        ]
      },

      isTermEmpty () {
        return /^\s*$/.test(this.term)
      },
      groupedResults () {
        const resultsByCategory = {}
        let index = 0
        this.results.forEach((result) => {
          const category = result.category

          if (typeof resultsByCategory[category] === 'undefined') {
            resultsByCategory[category] = {
              category,
              results: [],
            }
          }
          resultsByCategory[category].results.push({ ...result, index: index++, })
        })
        return Object.values(resultsByCategory)
      },
    },
    mounted () {
      window.addEventListener('keydown', this.shortcutHandler)
    },
    beforeDestroy () {
      window.removeEventListener('keydown', this.shortcutHandler)
    },
    methods: {
      open () {
        this.isActive = true
      },
      close () {
        this.isActive = false
        this.term = ''
        this.results = []
      },
      focus () {
        this.$refs.searchbar.focus()
      },
      select (index) {
        this.selectedIndex = Math.max(
          0,
          Math.min(index, this.results.length - 1)
        )
      },
      search (search) {
        const term = search
        this.term = term

        this.results = this.searcher.search(this.term)
      },
      scrollToSelection () {
        if (this.$refs[`result_${this.selectedIndex}`]) {
          this.$refs[`result_${this.selectedIndex}`][0].scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest',
          })
        }
      },

      shortcutHandler (event) {
        switch (event.key) {
          case 'Escape':
            return this.close()
          case 'k':
          case 'K':
            // NOTE: MetaKey is the command key on 🍏' devices
            if (event.ctrlKey || event.metaKey) {
              event.preventDefault()
              event.stopPropagation()
              this.open()
            }
            break
          case 'ArrowUp':
            if (this.isActive) {
              this.selectedIndex = Math.max(0, this.selectedIndex - 1)
              this.scrollToSelection()
            }
            break
          case 'ArrowDown':
            if (this.isActive) {
              this.selectedIndex = Math.min(
                this.results.length - 1,
                this.selectedIndex + 1
              )
              this.scrollToSelection()
            }
            break
          case 'PageUp':
            if (this.isActive) {
              this.selectedIndex = 0
              this.scrollToSelection()
            }
            break
          case 'PageDown':
            if (this.isActive) {
              this.selectedIndex = Math.max(0, this.results.length - 1)
              this.scrollToSelection()
            }
            break
          case 'Enter':
            if (this.isActive && this.results.length > 0) {
              this.navigateTo()
            }
            break
        }
      },

      navigateTo () {
        this.groupedResults.some((category) =>
          category.results.some((result) => {
            if (result.index === this.selectedIndex) {
              if (result.route) {
                this.$router.push(result.route)
              }
              if (result.callback) {
                result.callback()
              }
              this.close()
              return true
            }
            return false
          })
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
.quick-search {
  .panel {
    background: #fff;
  }
  .quick-search-results {
    flex-direction: column;
    align-items: stretch;
    max-height: 488px;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    overflow: hidden auto;

    .result-category-group {
      width: 100%;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .result-item {
      min-height: 65px;
      width: 100%;
      cursor: pointer;
      margin-bottom: 0.5rem;
      background-color: $grayscale-8;
      transition: all 0.1s;
      display: flex;
      align-items: center;
      &.is-active {
        background: $primary;
        color: #fff;
        em {
          color: inherit !important;
          text-decoration: underline;
        }
      }
      p {
        white-space: pre;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      em {
        font-style: normal;
      }
    }

    .category-title {
      text-transform: capitalize;
      margin-bottom: 10px;
      color: $grayscale-3;
      font-size: 16px;
    }
  }
  .quick-search-noresult {
    margin: 8px auto 12px;
    padding: 0 8px;
    strong {
      display: inline-block;
      max-width: 340px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: bottom;
    }
  }
  .quick-search-shortcuts {
    list-style: none;
    li {
      margin-right: 20px;
      font-size: 12px;
      color: $grayscale-2;

      .tag {
        font-size: 11px;
      }
    }
  }

  .panel-block {
    align-items: flex-start;
  }
  .is-size-6 {
    font-size: 16px !important;
  }
  .is-size-7 {
    font-size: 14px !important;
  }
}
</style>
